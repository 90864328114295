import { useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import tolgee from "services/translation";
import { notify } from "store/app";
import { api } from "services";
import { fileToFileObject } from "helpers";
import CompanyDetails from "pages/Client/LandingPage/CompanyDetails/View";
import CompanyPolicies from "pages/Client/LandingPage/CompanyPolicies/View";
import CertificationsPage from "pages/Client/LandingPage/CertificationPage/View";
import { handleError } from "services/api/error";
import { CustomizeCompanyStyles } from "pages/Client/LandingPage/CustomizeCompanyStyles/View";
import { BusinessFileEnum } from "types/business";

import type { BusinessConfig, Position } from "types/clp";

const defaultColors = {
  primaryColor: "#4B637D",
  secondaryColor: "#FCFDFF",
};

type Type = "logo" | "cookie" | "privacy" | "imageCertificate" | "backgroundImage";

export default function LandingPageSettings() {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const methods = useForm<BusinessConfig>();

  const [customSlug, setCustomSlug] = useState<string>(null);
  const [primaryColorCandidate, setPrimaryColorCandidate] = useState<string>(
    defaultColors.primaryColor
  );
  const [secondaryColorCandidate, setSecondaryColorCandidate] = useState<string>(
    defaultColors.secondaryColor
  );
  const [position, setPosition] = useState<Position>("TOP_LEFT");

  const fetchData = useCallback(async () => {
    try {
      const res = (await api.clp.getConfig(businessId)).data;

      setCustomSlug(res.slug);
      setPosition(res.position);
      if (res?.clp_color_primary) {
        setPrimaryColorCandidate(res?.clp_color_primary);
      }
      if (res?.clp_color_secondary) {
        setSecondaryColorCandidate(res?.clp_color_secondary);
      }
      methods.reset(res);
    } catch (e) {
      handleError(e);
    }
  }, [businessId, methods]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleUpdateSettings = async () => {
    const data = methods.getValues();
    const settings = {
      ...data,
      position,
      clp_color_primary: primaryColorCandidate,
      clp_color_secondary: secondaryColorCandidate,
      clp_text: data.clp_text && data.clp_text.length > 0 ? data.clp_text.trim() : null,
    };

    try {
      await api.clp.updateBusinessConfig(businessId, settings);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "clp.settings_updated",
        }),
        type: "SUCCESS",
      })
    );
    fetchData();
  };

  const handleUploadFile = async (file: File, type: Type) => {
    if (["imageCertificate", "logo"].includes(type) && !file.type.includes("image")) {
      dispatch(
        notify({
          message: tolgee.t({
            key: "generic.only_images_allowed",
          }),
          type: "ERROR",
        })
      );
      return;
    }

    const fileObject = await fileToFileObject(file);

    switch (type) {
      case "logo":
        methods.setValue("logo", fileObject);
        handleUpdateSettings();
        break;
      case "cookie":
        methods.setValue("cookie_policy", fileObject);
        handleUpdateSettings();
        break;
      case "privacy":
        methods.setValue("privacy_policy", fileObject);
        handleUpdateSettings();
        break;
      case "imageCertificate":
        try {
          await api.clp.addCertificateImage(businessId, fileObject);
        } catch (e) {
          handleError(e);
          return;
        }
        fetchData();
        break;
      case "backgroundImage":
        methods.setValue("background_image", fileObject);
        handleUpdateSettings();
        break;
      default:
        break;
    }
  };

  const handleRemoveFile = async (type: BusinessFileEnum, fileName?: string) => {
    try {
      if (type === BusinessFileEnum.CERTIFICATE_IMAGE) {
        await api.clp.deleteCertificateImage(businessId, fileName);
      } else {
        await api.business.deletePublicFile(businessId, type);
      }
    } catch (e) {
      handleError(e);
    }
    fetchData();
  };

  return (
    <>
      <div className="mx-auto max-w-7xl box-outerlayout">
        <h1 className="text-2xl font-bold mb-10">{tolgee.t("clp.manage_clp")}</h1>
        <FormProvider {...methods}>
          <Accordion defaultExpanded sx={{ backgroundColor: "#fafafa" }}>
            <AccordionSummary expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}>
              <div className="text-lg font-bold">
                {tolgee.t({ key: "clp.update_company_details" })}
              </div>
            </AccordionSummary>
            <AccordionDetails className="mb-8">
              <CompanyDetails customSlug={customSlug} handleUpdateSettings={handleUpdateSettings} />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: "#fafafa" }}>
            <AccordionSummary expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}>
              <div className="text-lg font-bold">{tolgee.t("clp.customize_company_styles")}</div>
            </AccordionSummary>
            <AccordionDetails className="mb-8">
              <CustomizeCompanyStyles
                primaryColorCandidate={primaryColorCandidate}
                setPrimaryColorCandidate={setPrimaryColorCandidate}
                secondaryColorCandidate={secondaryColorCandidate}
                setSecondaryColorCandidate={setSecondaryColorCandidate}
                handleUploadFile={handleUploadFile}
                handleRemoveFile={handleRemoveFile}
                position={position}
                setPosition={setPosition}
                handleUpdateSettings={handleUpdateSettings}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: "#fafafa" }}>
            <AccordionSummary expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}>
              <div className="text-lg font-bold">
                {tolgee.t({ key: "clp.cookie_and_privacy_policy" })}
              </div>
            </AccordionSummary>
            <AccordionDetails className="mb-8">
              <CompanyPolicies
                handleUploadFile={handleUploadFile}
                handleRemoveFile={handleRemoveFile}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ backgroundColor: "#fafafa" }}>
            <AccordionSummary expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}>
              <div className="text-lg font-bold">{tolgee.t({ key: "clp.upload_certificate" })}</div>
            </AccordionSummary>
            <AccordionDetails className="mb-8">
              <CertificationsPage
                handleUploadFile={handleUploadFile}
                handleRemoveFile={handleRemoveFile}
              />
            </AccordionDetails>
          </Accordion>
        </FormProvider>
      </div>
    </>
  );
}
