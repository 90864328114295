import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { T } from "@tolgee/react";

import api from "services/api";
import { redirectToFlaskUrl } from "helpers";
import { ProgressBar, BaseButton } from "components";
import RequestFormContainer from "components/PageSpecific/Dsr/RequestFormContainer";
import JurisdictionButton from "pages/Public/Dsr/Business/JurisdictionButton";
import { handleError } from "services/api/error";

import type { DSRJurisdictionType } from "types/dsr/main";
import type { DSRBusiness } from "types/dsr";

export default function DSRBusiness() {
  const { publicId } = useParams();
  const navigate = useNavigate();
  const [businessState, setBusinessState] = useState({} as DSRBusiness);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(true);

  const jurisdictions = useMemo(() => {
    if (businessState?.default_jurisdiction) {
      return [businessState?.default_jurisdiction].concat(
        businessState?.jurisdictions.filter((i) => i !== businessState?.default_jurisdiction)
      );
    }
    return businessState?.jurisdictions;
  }, [businessState]);

  useEffect(() => {
    async function getBusiness() {
      let res;
      try {
        res = await api.dsr.getDsrBusiness(publicId);
      } catch (e) {
        handleError(e, false, true);
        return;
      }

      setBusinessState(res.data);
      setShowAll(res.data.show_all);
      setLoading(false);
    }

    getBusiness();
  }, [publicId]);

  const handleClick = (type: DSRJurisdictionType) => {
    if (type === "dsr_cal") {
      navigate(`/dsr/${publicId}/ccpa`);
      return;
    }
    if (type === "dsr_fadp") {
      navigate(`/dsr/${publicId}/fadp`);
      return;
    }
    redirectToFlaskUrl(`/dsrtool/new/${type}/${publicId}`);
  };

  return (
    <>
      {businessState.public_id && !loading ? (
        <RequestFormContainer headerState={businessState}>
          <h1 className="mt-10 text-4xl text-center text-brand-900">
            <T keyName="generic.applicable_framework" />
          </h1>
          <p className="w-full max-w-lg mx-auto">{businessState.explanatory_text}</p>
          <div className="grid w-full max-w-lg p-6 mx-auto overflow-hidden border rounded-lg">
            <h2 className="mt-0 text-lg">
              <T keyName="generic.i_want_to_make_a" />
              ...
            </h2>
            <div className="grid gap-8">
              {!showAll && businessState.default_jurisdiction && (
                <>
                  <JurisdictionButton
                    handleClick={handleClick}
                    jurisdictionKey={businessState.default_jurisdiction}
                  />
                  <BaseButton
                    variant="outlined"
                    className="self-center w-full"
                    onClick={() => setShowAll(true)}>
                    <T keyName="generic.all_options" />
                  </BaseButton>
                </>
              )}
              {(showAll || !businessState.default_jurisdiction) &&
                jurisdictions?.map((jurisdiction) => (
                  <JurisdictionButton
                    key={jurisdiction}
                    handleClick={handleClick}
                    jurisdictionKey={jurisdiction}
                  />
                ))}
            </div>
          </div>
        </RequestFormContainer>
      ) : (
        <ProgressBar />
      )}
    </>
  );
}
