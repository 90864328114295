import clsx from "clsx";

import type { ReactNode } from "react";

type Props = {
  title: string | ReactNode;
  backgroundColor?: string;
  className?: string;
};

export default function Chip(props: Props) {
  const { title, backgroundColor, className } = props;
  return (
    <div
      className={clsx(
        className,
        backgroundColor || "bg-success-400",
        "p-2 flex items-center text-center rounded-2xl w-fit"
      )}>
      <span className="text-white">{title}</span>
    </div>
  );
}
