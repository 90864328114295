import { useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { useStripe } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { captureException } from "@sentry/react";

import PrighterLogo from "components/Prighter/PrighterLogo";
import { COMPANY_FULL_NAME, COMPANY_FULL_NAME_WITH_COM } from "const/context";
import { notify } from "store/app";

import type { PaymentIntent } from "@stripe/stripe-js";

export default function InvoicePaidBox() {
  const stripe = useStripe();
  const dispatch = useDispatch();

  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const fetchPaymentIntent = () => {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );
      if (!clientSecret) {
        setErrorMessage("Asn error occurred. Please try again later.");
        return;
      }

      stripe.retrievePaymentIntent(clientSecret).then((pi) => {
        if (pi.error) {
          if (pi.error.type === "card_error") {
            setErrorMessage(pi.error.message);
          } else {
            captureException(pi.error);
            setErrorMessage("An error occurred. Please try again later.");
          }
        } else {
          if (pi.paymentIntent.status === "succeeded") {
            dispatch(
              notify({
                message: "You paid the invoice successfully!",
                type: "SUCCESS",
                duration: 7,
              })
            );
          }
          setPaymentIntent(pi.paymentIntent);
        }
      });
    };
    if (stripe) {
      fetchPaymentIntent();
    }
  }, [dispatch, stripe]);

  return !errorMessage ? (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row items-center p-2 pt-0">
        <PrighterLogo className="w-8 h-8 p-1 bg-white rounded-full" />
        <span className="ml-4 text-gray-200 text-lg font-bold w-full">
          {COMPANY_FULL_NAME_WITH_COM}
        </span>
      </div>
      <div className="bg-gray-50 box-outerlayout">
        <div className="flex flex-row justify-between mt-2">
          <div className="flex flex-col mb-4">
            <span className="font-bold text-4xl">
              {paymentIntent?.amount ? `€ ${(paymentIntent.amount / 100).toFixed(2)}` : ""}
            </span>
            <span className="font-bold text-gray-500">{paymentIntent?.description}</span>
          </div>
          <div className="bg-success-400/75 rounded px-2 w-fit self-start">
            <span className="text-success-900">PAID</span>
          </div>
        </div>

        <div className="grid pl-8" style={{ gridTemplateColumns: "100px 300px" }}>
          <span className="font-bold text-gray-600">
            <T keyName="generic.from" />
          </span>
          <span>{COMPANY_FULL_NAME}</span>
        </div>
      </div>
    </div>
  ) : (
    <div>{errorMessage}</div>
  );
}
