import { FormControlLabel, Switch } from "@mui/material";
import { T } from "@tolgee/react";

interface Props {
  isEnabled: boolean;
  handleEnabled: any;
}

export default function EnableDocumentUpload({ isEnabled, handleEnabled }: Props) {
  return (
    <div className="mb-4">
      <h3 className="mb-2 mt-0">
        <T keyName="dsr.settings.ccpa.document_upload.title" />
      </h3>
      <div>
        <T keyName="dsr.settings.ccpa.document_upload.description" />
      </div>
      <hr className="h-px my-4 bg-gray-200 border-0" />
      <div className="mb-4">
        <FormControlLabel
          control={
            <Switch checked={isEnabled} onChange={(event) => handleEnabled(event.target.checked)} />
          }
          label={isEnabled ? <T keyName="general.enabled" /> : <T keyName="general.disabled" />}
        />
      </div>
    </div>
  );
}
