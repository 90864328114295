import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";

import TimeLineDot from "components/PageSpecific/Case/TimeLineDot";
import { getBeautyDateTime } from "helpers";
import tolgee from "services/translation";

import type { RawDateTimeString } from "types/date";
import type { JSX, ReactNode } from "react";

export default function HistoryEntryTemplate(props: {
  timeLineIcon?: JSX.Element;
  title: string | ReactNode;
  date: string;
  children?: ReactNode;
  processedAt: RawDateTimeString;
}) {
  const { timeLineIcon, title, date, children, processedAt } = props;

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimeLineDot>{timeLineIcon}</TimeLineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <div className="py-3 mb-0 transition-all duration-75 border rounded-lg hover:bg-slate-50">
          <Typography
            variant="h6"
            component="span"
            sx={{ fontSize: 18, px: 2, pb: 1, display: "block" }}>
            {title} <span className="text-xs italic font-thin text-gray-600 ">{date}</span>
          </Typography>
          <Divider />
          {children}

          <span className="block w-full pt-3 pl-4 italic border-t text-brand-600">
            {processedAt && (
              <span className="px-2 py-1 -mx-1 -my-1 rounded-md bg-brand-100 ">
                {tolgee.t({
                  key: "dsr.processed_at",
                })}{" "}
                {getBeautyDateTime(processedAt)}.
              </span>
            )}
          </span>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
}
