import "leaflet/dist/leaflet.css";

import DSRFlow from "pages/Client/Case/DSR/ClientView/Components/DSRFlow";
import Tags from "pages/Client/Case/DSR/ClientView/Components/Tags";
import OpenInquiries from "pages/Client/Case/DSR/ClientView/Components/OpenInquiries";
import DSInfo from "pages/Client/Case/DSR/ClientView/Components/DSInfo";
import DSRHistory from "pages/Client/Case/DSR/ClientView/Components/DSRHistory";
import DSRHeader from "pages/Client/Case/DSR/ClientView/Components/DSRHeader";
import DSGeoLocation from "pages/Client/Case/DSR/ClientView/Components/DSGeoLocation";
import InquiryModal from "pages/Client/Case/DSR/ClientView/Components/InquiryModal";
import DSInfoSkeleton from "pages/Client/Case/DSR/ClientView/Components/DSInfoSkeleton";
import { useCase } from "pages/Client/Case/CaseContext";

export default function DSRView() {
  const [state] = useCase();

  return (
    <div className="flex flex-col mx-auto max-w-7xl @container">
      <DSRHeader />

      <Tags />

      <DSRFlow />

      <OpenInquiries />

      {!state.initialLoading ? <DSInfo /> : <DSInfoSkeleton />}

      <DSGeoLocation />

      <DSRHistory />

      <InquiryModal />
    </div>
  );
}
