import { Switch, FormControlLabel, FormGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";

import { BaseButton, BaseSelectField } from "components";
import { api } from "services";
import { notify } from "store/app";
import {
  sanitizeAddresses,
  sanitizeContactPoints,
  stringifyContactNameAndCompany,
} from "helpers/contact";
import tolgee from "services/translation";
import AuthorityContactEdit from "pages/Client/Case/Authority/components/AuthorityContactEdit";
import { handleError } from "services/api/error";
import { serializeContactDirectory } from "helpers/api";

import type { UseState } from "types";
import type { Contact } from "types/contactDirectory";

type Props = {
  senderState: UseState<Contact>;
  onCloseModal: () => void;
  onReload: () => void;
  caseUuid: string;
  businessId: string;
};

export default function ChangeSenderModal({
  onCloseModal,
  onReload,
  senderState,
  caseUuid,
  businessId,
}: Props) {
  const dispatch = useDispatch();
  const [senderData, setSenderData] = senderState;
  const [chooseFromContact, setChooseFromContact] = useState(false);
  const [hasContact, setHasContact] = useState(false);

  const [contactOptions, setContactOptions] = useState({});
  const selectedContact = useState("");

  useEffect(() => {
    async function fetchContacts() {
      let res;
      try {
        res = (
          await api.contactDirectory.getContacts({
            order_by: "label",
            page: 1,
            per_page: 100,
            search_term: "",
          })
        ).data;
      } catch (e) {
        handleError(e);
        return;
      }
      res = res.result.map((contact) => serializeContactDirectory(contact));

      if (res.length > 0) {
        setContactOptions(
          Object.fromEntries(
            Object.values(res).map((contact) => [
              contact.uuid,
              stringifyContactNameAndCompany(
                contact?.name,
                contact?.organization,
                contact?.salutation
              ),
            ])
          )
        );
        selectedContact[1](res[0].uuid);
        setHasContact(true);
      }
    }

    if (Object.keys(contactOptions).length === 0) {
      fetchContacts();
    }
  }, [businessId, contactOptions, selectedContact]);

  const handleChangeSender = async () => {
    if (chooseFromContact) {
      try {
        await api.authCase.updateCaseSenderWithContactUuid(caseUuid, selectedContact[0]);
      } catch (e) {
        handleError(e);
        return;
      }
    } else {
      const sanitizedContact: Contact = {
        ...senderData,
        addresses: sanitizeAddresses(senderData.addresses),
        contact_points: sanitizeContactPoints(senderData.contact_points),
      };
      try {
        await api.authCase.updateCaseSender(caseUuid, sanitizedContact);
      } catch (e) {
        handleError(e);
        return;
      }
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "cases.authority_changed",
        }),
        type: "SUCCESS",
      })
    );
    onCloseModal();
    onReload();
  };

  return (
    <div className="flex flex-col w-[66rem] space-y-4">
      <div className="flex justify-between">
        <h2 className="mt-0">
          <T keyName="cases.edit_authority" />
        </h2>
        {hasContact && (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={chooseFromContact}
                  onChange={(event) => setChooseFromContact(event.target.checked)}
                />
              }
              label={tolgee.t({
                key: "contacts.copy_existing_contacts",
              })}
            />
          </FormGroup>
        )}
      </div>
      {chooseFromContact ? (
        <BaseSelectField state={selectedContact} options={contactOptions} />
      ) : (
        <div className="px-16">
          <AuthorityContactEdit
            contactState={[senderData, setSenderData]}
            companyTitle="authority"
            noCommentField
            noIndividual
          />
        </div>
      )}
      <div className="self-end space-x-4">
        <BaseButton onClick={() => onCloseModal()}>
          <T keyName="generic.cancel" />
        </BaseButton>
        <BaseButton
          disabled={chooseFromContact && selectedContact[0].length === 0}
          onClick={() => handleChangeSender()}>
          {chooseFromContact
            ? tolgee.t({
                key: "contacts.copy_contact",
              })
            : tolgee.t({
                key: "contacts.update",
              })}
        </BaseButton>
      </div>
    </div>
  );
}
