export type CCPASettings = {
  required_inputs?: string[];
  required_inputs_strict?: string[];
  additional_inputs?: string[];
  categories_collected?: string[];
  categories_shared?: object;
  categories_disclosed?: object;
  sell_share?: boolean;
  disclose?: boolean;
  document_upload?: boolean;
  collect?: boolean;
  send_privacy_policy?: boolean;
};

export enum DefaultPointType {
  required_inputs = "required_inputs",
  required_inputs_strict = "required_inputs_strict",
  additional_inputs = "additional_inputs",
}

export interface DefaultPointsInterface {
  selectedItems: Array<DefaultPointItem>;
  options: Array<DefaultPointsOption>;
}

export interface DefaultPointItem {
  id: number;
  value: string;
  key: string;
  type: DefaultPointType;
}

export interface DefaultPointsOption {
  key: string;
  value: string;
  selected: boolean;
}
