import { Button } from "@mui/material";
import { useNavigate } from "react-router";

import tolgee from "services/translation";

export default function DocReviewed() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <h2 className="text-2xl font-bold text-gray-800 mb-10">{tolgee.t("documentReview.title")}</h2>
      <div className="box-outerlayout">
        <div className="flex flex-col items-center gap-6 p-4">
          <h2 className="text-2xl font-bold text-gray-800">
            {tolgee.t("documentReview.explanationTitle")}
          </h2>
          <p className="text-gray-600">{tolgee.t("documentReview.explanation")}</p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
            className="mt-4">
            {tolgee.t("documentReview.returnToDashboard")}
          </Button>
        </div>
      </div>
    </div>
  );
}
