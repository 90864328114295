import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItem, ListItemText } from "@mui/material";

type Props = {
  label: string;
  upload: boolean;
};

export default function OpenInquiriesDataField({ label, upload }: Props) {
  return (
    <ListItem className="flex flex-col" sx={{ px: 0 }} alignItems="flex-start">
      <div className="flex items-start @xl:items-center flex-col @xl:flex-row ">
        <ListItemText secondary={label} />
        {upload && (
          <div className="flex items-baseline gap-2 mb-2 @xl:mb-0 px-2 @xl:ml-2 @xl:-mr-2  py-1 border rounded-lg text-brand-600 bg-brand-100 border-brand-200">
            <span className="text-sm ">Upload Included</span>
            <FontAwesomeIcon icon="upload" size="sm" />
          </div>
        )}
      </div>
    </ListItem>
  );
}
