import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation/tolgee";

import type { CaseExtendDeadlineEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function CaseExtendDeadline(props: {
  entityData: CaseExtendDeadlineEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="calendar-days" color="white" size="lg" />}
      title={entityData.title}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="p-4 space-y-2">
        <span>
          {getBeautyDate(entityData.old)} &#8594; {getBeautyDate(entityData.new)}
        </span>
        {entityData.reason && (
          <div className="flex flex-col">
            <span>
              <b>
                {tolgee.t({
                  key: "dsr.reason",
                })}
              </b>
              : {entityData.reason}
            </span>
          </div>
        )}
      </div>
    </HistoryEntryTemplate>
  );
}
