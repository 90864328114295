import { useState } from "react";

import { SplitButton, VerticalDataValue } from "components";

import type { SplitButtonItems } from "types/components";
import type { ManagedUserDetail } from "types/user";

type Props = { user: ManagedUserDetail };

export default function InvoicesTab({ user }: Props) {
  const [itemIndex, setItemIndex] = useState(0);

  const createItems: SplitButtonItems = [
    {
      name: "Email All Invoices",
      icon: "plus",
      action: () => {},
    },
  ];

  return (
    <div className="flex flex-col space-y-4">
      <div className="box-outerlayout">
        <h2>Quick Actions</h2>
        <div>
          <SplitButton items={createItems} listHeight="424px" state={[itemIndex, setItemIndex]} />
        </div>
      </div>

      <div className="box-outerlayout">
        <h2>Billing</h2>

        <div className="grid grid-cols-[auto_auto_auto] grid-rows-[auto_auto_auto] gap-x-4 gap-y-1">
          <VerticalDataValue title="Billing Address" value={user.full_billing_address || "-"} />
          <div />
          <div />
          <>
            <VerticalDataValue title="Vat Number" value={user.vat?.number || "-"} />

            <VerticalDataValue title="Tax status" value={user.vat?.status || "-"} />
            <VerticalDataValue title="Days until due" value={user.invoice_due_in || "-"} />
            <VerticalDataValue title="Vat Check" value={user.vat?.verified_name || "-"} />
          </>
          <VerticalDataValue
            title="Custom reference on invoice"
            value={user.billing_customclientline || "-"}
          />

          <VerticalDataValue
            title="Do not send invoice reminders until"
            value={user.config.no_invoice_reminder_until || "-"}
          />
        </div>

        <div>
          <h3>Invoice items to be added to next invoice</h3>
          <div>Invoice items</div>
        </div>
      </div>

      <div className="box-outerlayout">
        <h2>Vouchers</h2>
      </div>

      <div className="box-outerlayout">
        <h2>Subscriptions</h2>
      </div>

      <div className="box-outerlayout">
        <h2>Invoices</h2>
      </div>
    </div>
  );
}
