import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation";

import type { SenderChangedEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function SenderChanged(props: {
  entityData: SenderChangedEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="right-left" color="white" size="lg" />}
      title={
        <>
          {tolgee.t({
            key: "dsr.sender_changed",
          })}
          <span>&quot;{entityData.new_sender.name}&quot;</span>
        </>
      }
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="flex flex-col p-4 space-y-2">
        <span>
          <b>
            <T keyName="dsr.new_sender" />:
          </b>
          <span>
            {entityData.new_sender.name}, <i>{entityData.new_sender.email}</i>
          </span>
        </span>
        <span>
          <b>
            <T keyName="dsr.old_sender" />:
          </b>
          <span>
            {entityData.old_sender.name}, <i>{entityData.old_sender.email}</i>
          </span>
        </span>
      </div>
    </HistoryEntryTemplate>
  );
}
