import type { CaseTagDict } from "types/case";

export const caseTagDict: CaseTagDict = {
  IDENTIFICATION_PENDING: {
    icon: "address-card",
    textColor: "text-info-950",
    backgroundColor: "bg-info-50",
    borderColor: "border-info-300",
  },
  PROCESSING: {
    icon: "clock-rotate-left",
    textColor: "text-orange-900",
    backgroundColor: "bg-orange-400",
    borderColor: "border-orange-600",
  },
  CHECK_RESPONSIBILITY: {
    icon: "address-card",
    textColor: "text-orange-900",
    backgroundColor: "bg-orange-400",
    borderColor: "border-orange-600",
  },
  DEADLINE_EXTENDED: {
    icon: "clock-rotate-left",
    textColor: "text-info-950",
    backgroundColor: "bg-info-50",
    borderColor: "border-info-300",
  },
  EMAIL_VERIFIED: {
    icon: undefined,
    textColor: undefined,
    backgroundColor: undefined,
    borderColor: undefined,
  },
  EMAIL_NOT_VERIFIED: {
    icon: undefined,
    textColor: undefined,
    backgroundColor: undefined,
    borderColor: undefined,
  },
};
