import { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { faker } from "faker";
import clsx from "clsx";

import { DEV_ENV } from "const/env";
import { api } from "services";
import { BaseButton, ProgressBar } from "components";
import { jurisdictionDict } from "const";
import CustomizedStepper from "components/Navigation/Stepper";
import RightsForm from "pages/Public/Dsr/Ccpa/Step1/RightsForm";
import AdditionalInfoForm from "pages/Public/Dsr/Ccpa/Step3/AdditionalInfoForm";
import RequestFormContainer from "components/PageSpecific/Dsr/RequestFormContainer";
import IdentificationForm from "pages/Public/Dsr/Ccpa/Step2/IdentificationForm";
import { useAppDispatch, useAppSelector } from "store";
import { setError } from "store/app";
import tolgee from "services/translation";
import {
  ccpaFormEmptyValues,
  ccpaFormInitialValues,
} from "pages/Public/Dsr/Ccpa/ccpaRequestObject";
import { useDebounce } from "helpers/hooks";
import PrighterLogo from "components/Prighter/PrighterLogo";
import { validateEmail } from "helpers/validate";
import { handleError } from "services/api/error";
import { CollectionTypeEnum } from "types/case";

import type { DsrConfigCaseType } from "types/case";
import type { DSRCcpaCase, DSRFormSteps } from "types/dsr/main";
import type { CcpaForm, CcpaFormDsr } from "types/dsr/ccpa";
import type { DSRBusiness } from "types/dsr";

type Props = { step: DSRFormSteps };

const steps: DSRFormSteps[] = ["type", "identification", "send"];

export default function CcpaRequestForm({ step }: Props) {
  const [residentOfCalifornia, setResidentOfCalifornia] = useState(DEV_ENV);
  const { version } = useAppSelector((state) => state.app);
  const { publicId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [uiStep, setUiStep] = useState<DSRFormSteps>("type");
  const [businessState, setBusinessState] = useState({} as DSRBusiness);
  const [loading, setLoading] = useState(true);
  const [storageLoaded, setStorageLoaded] = useState(false);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [dsrCcpaCase, setDsrCcpaCase] = useState({} as DSRCcpaCase);
  const [passwordVisibility, setPasswordVisibility] = useState<{ [key: string]: boolean }>({});
  const [identificationData, setIdentificationData] = useState<DsrConfigCaseType>({
    required_inputs: [],
    document_upload: false,
  });

  const methods = useForm({ values: ccpaFormEmptyValues as CcpaForm });
  const { watch, trigger, getValues, register, reset, setValue } = methods;
  const request_privacy_related = watch("request_privacy_related");
  const form = watch();
  const acting_for = watch("actors.1.acting_for");

  const debouncedValue = useDebounce<string>(
    JSON.stringify({ form: { ...form, files: [] }, businessId: publicId }),
    2000
  );
  const submitted = useRef(false);

  useEffect(() => {
    async function testDsrForm() {
      let res;
      try {
        res = await api.clp.getSettings(publicId);
      } catch (e) {
        handleError(e);
        return;
      }
      if (!res.data.config.dsr_form) {
        dispatch(setError("404"));
      }
    }

    testDsrForm();
  }, [dispatch, publicId]);

  const handleBack = useCallback(() => {
    switch (step) {
      case "identification":
        navigate({ pathname: `/dsr/${publicId}/ccpa`, search: searchParams.toString() });
        break;
      case "send":
        navigate({
          pathname: `/dsr/${publicId}/ccpa/identification`,
          search: searchParams.toString(),
        });
        break;
      default:
        break;
    }
  }, [navigate, publicId, searchParams, step]);

  const checkStep = useCallback(
    async (step_ = step) => {
      switch (step_) {
        case "type":
          return trigger(["dsrs"]);
        case "identification":
          return trigger([
            "actors",
            `${identificationData.document_upload || (acting_for && acting_for !== "MYSELF") ? "files" : null}`,
            "identifiable_info",
          ]);

        case "send":
          return trigger();
        default:
          return true;
      }
    },
    [acting_for, identificationData?.document_upload, step, trigger]
  );

  const shouldButtonDisabled = useCallback(() => {
    const disableRequestType =
      form.dsrs.every((dsr, index) => index === 1 || !dsr.checked) &&
      form.dsrs[1].inquiries.every((dsr) => !dsr.checked);

    if (uiStep === "send" && !request_privacy_related) {
      return true;
    }
    if (uiStep === "type" && disableRequestType) {
      return true;
    }
    if (uiStep === "identification" && !residentOfCalifornia) {
      return true;
    }
    return false;
  }, [form.dsrs, request_privacy_related, residentOfCalifornia, uiStep]);

  const fetchCustomerFields = useCallback(async () => {
    const formValue = getValues();
    const dsrTypes = formValue.dsrs.filter((item) => item.checked).map((item) => item.type);

    let res;
    try {
      res = await api.ccpa.getDsrConfigForCaseType(publicId, dsrTypes);
    } catch (e) {
      handleError(e);
      return;
    }
    res = res.data;

    const sortedInputs = res.required_inputs.sort((a, b) => {
      if (a === "email") {
        return -1;
      }
      if (b === "email") {
        return 1;
      }
      if (a === "name") {
        return -1;
      }
      if (b === "name") {
        return 1;
      }
      return 0;
    });
    const values = getValues();
    sortedInputs.forEach((field) => {
      if (field === "address") {
        register("identifiable_info.address.postal", {
          validate: {
            minLength: (e) => (e && `${e}`.length > 0) || "Can't be empty",
          },
        });
        register("identifiable_info.address.city", {
          validate: {
            minLength: (e) => (e && `${e}`.length > 0) || "Can't be empty",
          },
        });
        register("identifiable_info.address.street", {
          validate: {
            minLength: (e) => (e && `${e}`.length > 0) || "Can't be empty",
          },
        });
        register("identifiable_info.address.country_iso", {
          validate: {
            minLength: (e) => (e && `${e}`.length > 0) || "Can't be empty",
          },
        });

        const initialAddress = {
          street: values.identifiable_info.address?.street || faker.location.street(),
          postal: values.identifiable_info.address?.postal || faker.location.zipCode("#####"),
          city: values.identifiable_info.address?.city || faker.location.city(),
          country_iso: values.identifiable_info.address?.country_iso || "AT",
        };

        setValue("identifiable_info.address", initialAddress);
      } else if (field === "email") {
        register("identifiable_info.email", {
          validate: {
            required: (e) => validateEmail(e) || "Please enter a valid email.",
          },
        });
        setValue(
          "identifiable_info.email",
          values.identifiable_info.email || faker.internet.exampleEmail()
        );
      } else {
        register(`identifiable_info.${field}`, {
          validate: {
            minLength: (e) => (e && `${e}`.length > 0) || "Can't be empty",
          },
        });
        setValue(
          `identifiable_info.${field}`,
          values.identifiable_info[field] || faker.lorem.words(2)
        );
      }
    });

    setIdentificationData({
      document_upload: res.document_upload,
      required_inputs: sortedInputs,
    });
  }, [getValues, publicId, register, setValue]);

  useEffect(() => {
    async function proceedUiStep() {
      const currentStep = steps.indexOf(step);
      const currentUiStep = steps.indexOf(uiStep);

      if (currentUiStep < currentStep) {
        const allowProceeding = (await checkStep(uiStep)) && !shouldButtonDisabled();
        if (allowProceeding) {
          if (uiStep === "type") {
            await fetchCustomerFields();
          }
          setUiStep(steps[currentUiStep + 1]);
        } else {
          handleBack();
        }
      } else if (currentUiStep > currentStep) {
        setUiStep(steps[currentUiStep - 1]);
      }
    }
    if (!loading) {
      proceedUiStep();
    }
  }, [step, uiStep, loading, checkStep, shouldButtonDisabled, handleBack, fetchCustomerFields]);

  useEffect(() => {
    if (
      !submitted.current &&
      !loading &&
      debouncedValue &&
      Object.keys(JSON.parse(debouncedValue).form.identifiable_info).length !== 0
    ) {
      localStorage.setItem("ccpa_form", debouncedValue);
      localStorage.setItem("ccpa_form_date", JSON.stringify(new Date()));
      localStorage.setItem("app_version", version);
    }
  }, [debouncedValue, loading, storageLoaded, getValues, version]);

  useEffect(() => {
    async function getBusiness() {
      let res;
      try {
        res = await api.dsr.getDsrBusiness(publicId);
      } catch (e) {
        handleError(e, false, true);
        return;
      }
      if (!res.data.jurisdictions.includes("dsr_cal")) {
        dispatch(setError("404"));
        return;
      }

      setBusinessState(res.data);
    }

    function loadLocalStorage() {
      const localForm = JSON.parse(localStorage.getItem("ccpa_form") || "{}");
      const date = JSON.parse(localStorage.getItem("ccpa_form_date") || "{}");
      const localAppVersion = localStorage.getItem("app_version");

      if (
        localForm.businessId === publicId &&
        localAppVersion === version &&
        Date.now() - new Date(date).getTime() <= 24 * 60 * 60 * 1000
      ) {
        reset(localForm.form);
      } else {
        const initialValues = ccpaFormInitialValues;
        if (DEV_ENV) {
          initialValues.dsrs[0].checked = true;
          initialValues.dsrs[0].inquiries[0].answer = faker.lorem.words(20);
        }
        reset(ccpaFormInitialValues);
        localStorage.removeItem("ccpa_form");
        localStorage.removeItem("ccpa_form_date");
      }
      setStorageLoaded(true);
    }

    async function processValues() {
      loadLocalStorage();
      await getBusiness();
      setLoading(false);
    }
    processValues();
  }, [publicId, dispatch, reset, register, setValue, getValues, version]);

  const handleContinue = async () => {
    if (await checkStep()) {
      switch (step) {
        case "type":
          await fetchCustomerFields();
          navigate({
            pathname: `/dsr/${publicId}/ccpa/identification`,
            search: searchParams.toString(),
          });
          break;
        case "identification":
          navigate({ pathname: `/dsr/${publicId}/ccpa/send`, search: searchParams.toString() });
          break;
        case "send":
        default:
          break;
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const tempValues = getValues();
    const checkedDSRs = tempValues.dsrs.filter((dsr) => dsr.checked);
    const updatedDsrs: CcpaFormDsr[] = [];
    checkedDSRs.forEach((item) => {
      if (item.type === "DSR_CCPA_ACCESS") {
        return item.inquiries.forEach((i: any) => {
          if (i.checked) {
            updatedDsrs.push({
              type: i.type,
              checked: i.checked,
              inquiries: [
                {
                  answer: i.answer,
                  question_i18n: i.question_i18n,
                },
              ],
              extra: item.extra,
            });
          }
        });
      }
      return updatedDsrs.push(item);
    });

    const addInfoInquiry = {
      question_i18n: {
        key: "dsr.additional_info",
      },
      answer: additionalInfo,
    };
    updatedDsrs.forEach((dsr) => {
      if (additionalInfo) {
        dsr.inquiries.push(addInfoInquiry);
      }
    });

    if (tempValues.actors[1].acting_for === "MYSELF") {
      tempValues.actors[0].name = tempValues.identifiable_info.name;
      tempValues.actors[0].email = tempValues.actors[1].email;
      tempValues.actors.splice(1, 1);
    } else {
      tempValues.actors[0].name = tempValues.identifiable_info.name;
      delete tempValues.actors[0].acting_for;
    }

    const valuesToSubmit: CcpaForm = {
      ...tempValues,
      dsrs: updatedDsrs,
      type: CollectionTypeEnum.DSR_CCPA,
    };
    let res;
    try {
      res = await api.ccpa.createDsrCcpaCase(publicId, valuesToSubmit);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setLoading(false);
    }

    setDsrCcpaCase(res.data);
    setDisplaySuccessMessage(true);
    localStorage.removeItem("ccpa_form");
    localStorage.removeItem("ccpa_form_date");
    submitted.current = true;
  };

  const handleClickShowPassword = (uuid: string) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [uuid]: !prevState[uuid],
    }));
  };

  const handleCopyPassword = async () => {
    await navigator.clipboard.writeText(dsrCcpaCase.ds_password_init);
  };

  const handleCopyCaseUrl = async () => {
    await navigator.clipboard.writeText(dsrCcpaCase.ext_url);
  };

  return (
    <>
      {loading && <ProgressBar />}
      {!loading && displaySuccessMessage && (
        <div className="flex flex-col h-screen p-10">
          <div className="flex items-center justify-end py-2 mb-6">
            <span className="text-4xl font-bold text-prighterblue">Prighter</span>
            <PrighterLogo width={25} className="ml-2" />
          </div>
          <div className="flex items-center justify-center grow">
            <div className="box-outerlayout p-4">
              <div className="flex text-left items-start justify-between">
                <div>
                  <h1 className="text-xl text-gray-700">
                    <T keyName="dsr.thank_you_for_your_request" />
                  </h1>
                  <div className="mt-1 text-sm text-gray-600">
                    <T keyName="dsr.please_verify_email_address" />
                    <br />
                    <T keyName="dsr.ds_request_success_message" />
                  </div>
                </div>
                <img
                  src={jurisdictionDict.dsr_cal.jurisdiction_lock_icon_url}
                  alt="ccpa-logo"
                  className="object-contain"
                  width="24"
                  height="24"
                />
              </div>
              <hr className="h-px text-gray-500 bg-gray-500 border-0 my-4" />
              <div>
                <h2 className="text-base text-left text-gray-700 mt-0">
                  <T keyName="dsr.view_dsr_request" />
                </h2>
                <div className="mt-1 text-sm text-gray-600">
                  <T keyName="dsr.dsr_request_description" />
                </div>
                <div className="flex flex-col items-center justify-between gap-4 mt-4">
                  <div className="flex flex-row items-center justify-between gap-4 w-full">
                    <TextField
                      className="w-full"
                      value={dsrCcpaCase.ext_url}
                      label={tolgee.t("dsr.dsr_request_url_label")}
                      slotProps={{
                        input: {
                          readOnly: true,
                          endAdornment: (
                            <div className="flex flex-row">
                              <InputAdornment position="end">
                                <IconButton onClick={() => handleCopyCaseUrl()} edge="end">
                                  <ContentCopyOutlinedIcon />
                                </IconButton>
                              </InputAdornment>
                              <InputAdornment position="end">
                                <IconButton
                                  href={dsrCcpaCase.ext_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  edge="end">
                                  <OpenInNewIcon />
                                </IconButton>
                              </InputAdornment>
                            </div>
                          ),
                        },
                      }}
                    />
                    <TextField
                      className="w-full"
                      value={dsrCcpaCase.ds_password_init}
                      type={passwordVisibility[dsrCcpaCase.cases[0].uuid] ? "text" : "password"}
                      label={tolgee.t("dsr.dsr_request_password_label")}
                      slotProps={{
                        input: {
                          readOnly: true,
                          endAdornment: (
                            <div className="flex flex-row">
                              {passwordVisibility[dsrCcpaCase.cases[0].uuid] ? (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => handleCopyPassword()} edge="end">
                                    <ContentCopyOutlinedIcon />
                                  </IconButton>
                                </InputAdornment>
                              ) : (
                                ""
                              )}
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => handleClickShowPassword(dsrCcpaCase.cases[0].uuid)}
                                  edge="end">
                                  {passwordVisibility[dsrCcpaCase.cases[0].uuid] ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            </div>
                          ),
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && !displaySuccessMessage && businessState.public_id && (
        <RequestFormContainer headerState={businessState}>
          <div className="flex items-center justify-between gap-2">
            <h1 className="text-lg md:text-2xl ">
              <T
                keyName="ccpa.make_a_privacy_related_request_to"
                params={{ company_name: businessState.company_name }}
              />
            </h1>
            <img
              src={jurisdictionDict.dsr_cal.jurisdiction_lock_icon_url}
              alt="ccpa-logo"
              className="object-contain w-12 h-12 md:w-16 md:h-16"
              width="64"
              height="64"
            />
          </div>
          <CustomizedStepper currentStep={uiStep} />
          <FormProvider {...methods}>
            <>
              <div className={clsx({ block: uiStep === "type", hidden: uiStep !== "type" })}>
                <RightsForm />
              </div>
              <div
                className={clsx({
                  block: uiStep === "identification",
                  hidden: uiStep !== "identification",
                })}>
                <IdentificationForm
                  identificationData={identificationData}
                  checkboxState={[residentOfCalifornia, setResidentOfCalifornia]}
                />
              </div>

              <div className={clsx({ block: uiStep === "send", hidden: uiStep !== "send" })}>
                <AdditionalInfoForm
                  data={getValues()}
                  identificationData={identificationData}
                  additionalInfoState={[additionalInfo, setAdditionalInfo]}
                />
              </div>
            </>
          </FormProvider>
          <div className={`flex ${uiStep === "type" ? "justify-end" : "justify-between"} mt-4`}>
            {uiStep !== "type" && (
              <BaseButton onClick={handleBack}>
                <T keyName="generic.back" />
              </BaseButton>
            )}
            <BaseButton
              disabled={shouldButtonDisabled()}
              onClick={uiStep === "send" ? handleSubmit : handleContinue}>
              {uiStep === "send" ? (
                <T keyName="generic.submit" />
              ) : (
                <T keyName="generic.continue" />
              )}
            </BaseButton>
          </div>
        </RequestFormContainer>
      )}
    </>
  );
}
