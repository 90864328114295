import type { Currency } from "types/billing";

export const availableCurrencies: Currency[] = ["EUR", "GBP", "USD", "CHF", "SGD"];

export const availableCurrenciesDict: { [key in Currency]: Currency } = {
  EUR: "EUR",
  GBP: "GBP",
  USD: "USD",
  CHF: "CHF",
  SGD: "SGD",
};
