import { useCallback, useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, TextField } from "@mui/material";

import { BaseButton, BaseSelectField, BaseTextField, SplitButton } from "components";
import { api } from "services";
import { useAppDispatch } from "store";
import { notify } from "store/app";
import { emptyContact } from "const/emptyObjects";
import {
  sanitizeAddresses,
  sanitizeContactPoints,
  stringifyContactNameAndCompany,
} from "helpers/contact";
import tolgee from "services/translation";
import { PRIGHTER_BLUE } from "const/color";
import ContactPointList from "pages/Client/ContactDirectory/Components/ContactPointList";
import ContactAddress from "pages/Client/ContactDirectory/Components/ContactAddress";
import { objectDeepCopy } from "helpers";
import { serializeContactDirectory } from "helpers/api";
import { handleError } from "services/api/error";

import type { SplitButtonItems } from "types/components";
import type { PermissionV2 } from "types/security";
import type { Contact, ContactPointType } from "types/contactDirectory";

type Props = {
  contactUuid?: string;
  setModal: (val: boolean) => void;
  companyTitle?: string;
  emailRequired?: boolean;
  parentSubmit?: (contact?: Contact) => void;
};

export default function ContactEdit({
  contactUuid,
  setModal,
  companyTitle = "company",
  emailRequired = false,
  parentSubmit,
}: Props) {
  const dispatch = useAppDispatch();
  const [contact, setContact] = useState(emptyContact);
  const [constContact, setConstContact] = useState(emptyContact as Contact);
  const [permissions, setPermissions] = useState<PermissionV2[]>([]);
  const salutationOptions = { mr: "Mr.", mrs: "Mrs." };
  const selectedIndex = useState(0);
  const [comment, setComment] = useState(false);

  const fetchContact = useCallback(async () => {
    let res;
    try {
      res = await api.contactDirectory.getContact(contactUuid);
    } catch (e) {
      handleError(e);
      return;
    }
    res = serializeContactDirectory(res.data);

    if (res) {
      setContact(res);
      setConstContact(objectDeepCopy(res));
      if (res.comment) {
        setComment(true);
      }
    }
  }, [contactUuid]);

  const getPermissions = async () => {
    let res;
    try {
      res = await api.security.getPermissions("BUSINESS", "WRITE");
    } catch (e) {
      handleError(e);
      return;
    }
    res = res.data;

    setPermissions(res);
    if (res?.length === 1) {
      setContact((oldContact) => ({
        ...oldContact,
        business: {
          public_id: res[0]?.target_public_id,
        },
      }));
    }
  };

  useEffect(() => {
    setContact(emptyContact);
    if (contactUuid) {
      fetchContact();
    }
    getPermissions();
    if (emailRequired) {
      handleIncrease("EMAIL");
    }
  }, [contactUuid, emailRequired, fetchContact]);

  const createItems: SplitButtonItems = [
    {
      name: "Address",
      action: () =>
        setContact((prevContact) => ({
          ...prevContact,
          addresses: [
            ...prevContact.addresses,
            { country_iso: "", city: "", street: "", postal: "" },
          ],
        })),
      icon: "plus",
    },
    {
      name: "Phone",
      action: () => handleIncrease("PHONE"),
      icon: "plus",
    },
    {
      name: "Email",
      action: () => handleIncrease("EMAIL"),
      icon: "plus",
    },
    {
      name: "Web",
      action: () => handleIncrease("WEB"),
      icon: "plus",
    },
    {
      name: "Comment",
      action: () => setComment(true),
      icon: "plus",
    },
  ];

  const handleUpsert = async () => {
    let res;
    const businessId: any = contact.business.public_id;
    if (
      emailRequired &&
      (contact.contact_points.findIndex((t) => t.type === "EMAIL") === -1 ||
        contact.contact_points.find((t) => t.type === "EMAIL").value.length < 5)
    ) {
      dispatch(
        notify({
          message: tolgee.t({
            key: "error.email_is_required",
          }),
          type: "ERROR",
        })
      );
      return;
    }

    if (!(contact.name?.length >= 3 || contact.organization?.length >= 3)) {
      dispatch(
        notify({
          message: tolgee.t({
            key: "error.contact_directory.name_or_org_name_should_exist",
          }),
          type: "ERROR",
        })
      );
      return;
    }

    const contactData = Object.entries(contact)
      .filter(([key]) => key !== "business")
      .reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as Record<string, any>
      );

    const sanitizedContact: Contact = {
      ...contactData,
      uuid: null,
      addresses: sanitizeAddresses(contactData.addresses),
      contact_points: sanitizeContactPoints(contactData.contact_points),
    };
    if (contactUuid) {
      try {
        res = await api.contactDirectory.updateContact(businessId, contactUuid, sanitizedContact);
      } catch (e) {
        handleError(e);
        return;
      }
    } else {
      try {
        res = await api.contactDirectory.createContact(businessId, sanitizedContact);
      } catch (e) {
        handleError(e);
        return;
      }
      if (parentSubmit) {
        parentSubmit(res.data);
      }
    }

    dispatch(
      notify({
        message: `${tolgee.t({
          key: "ops.contact_successfully",
        })} ${
          contactUuid
            ? tolgee.t({
                key: "ops.updated",
              })
            : tolgee.t({
                key: "ops.created",
              })
        }`,
        type: "SUCCESS",
      })
    );
    setModal(false);
  };

  const handleDelete = () => {
    try {
      api.contactDirectory.deleteContact(contact.business.public_id, contactUuid).then(() => {
        dispatch(
          notify({
            message: tolgee.t({
              key: "ops.contact_successfully_created",
            }),
            type: "SUCCESS",
          })
        );
        setModal(false);
      });
    } catch (e) {
      handleError(e);
    }
  };

  const handleIncrease = (type: ContactPointType) => {
    setContact((prevContact) => ({
      ...prevContact,
      contact_points: [...prevContact.contact_points, { type, value: "" }],
    }));
  };

  return (
    <>
      <h2 className="mt-0 text-brand-900">
        {contactUuid
          ? `${tolgee.t({
              key: "ops.contact",
            })} ${stringifyContactNameAndCompany(
              constContact?.name,
              constContact?.organization,
              constContact?.salutation
            )}`
          : tolgee.t({
              key: "ops.create_contact",
            })}
      </h2>
      <div className="flex flex-col space-y-4" data-testid="contact-form">
        {permissions?.length > 1 && (
          <div className="flex items-center space-x-4">
            <FontAwesomeIcon icon="building" color={PRIGHTER_BLUE} />
            <BaseSelectField
              fullWidth
              label={tolgee.t({
                key: "contacts.select_business",
              })}
              options={permissions.reduce<any>((acc, item) => {
                acc[item.target_public_id] = `${item.target_name} (${item.target_public_id})`;
                return acc;
              }, {})}
              defaultValue=""
              state={[
                contact.business?.public_id || "",
                (val: any) => {
                  setContact((oldContact) => ({
                    ...oldContact,
                    business: {
                      public_id: val,
                    },
                  }));
                },
              ]}
              required
            />
          </div>
        )}

        <div className="flex items-center space-x-4">
          <FontAwesomeIcon icon="user" color={PRIGHTER_BLUE} className="w-4" />
          <BaseSelectField
            className="w-24"
            fullWidth={false}
            label={tolgee.t({
              key: "contacts.title",
            })}
            options={salutationOptions}
            defaultValue=""
            state={[
              contact.salutation || "",
              (option: string) =>
                setContact((oldContact) => ({
                  ...oldContact,
                  salutation: option,
                })),
            ]}
          />

          <TextField
            className="w-full"
            InputLabelProps={{ shrink: true }}
            value={contact.name}
            onChange={(event) =>
              setContact((oldContact) => ({
                ...oldContact,
                name: event.target.value,
              }))
            }
            label={tolgee.t({
              key: "contacts.name",
            })}
          />
        </div>

        <div className="flex items-center w-full space-x-4">
          <FontAwesomeIcon icon="building" color={PRIGHTER_BLUE} className="w-4" />
          <TextField
            InputLabelProps={{ shrink: true }}
            label={tolgee.t({
              key: `contacts.${companyTitle}`,
            })}
            className="w-full"
            value={contact.organization}
            onChange={(event) =>
              setContact((oldContact) => ({
                ...oldContact,
                organization: event.target.value,
              }))
            }
          />
        </div>

        <ContactPointList
          contactPoints={contact.contact_points}
          onChange={(contactPoints) =>
            setContact((prevContact) => ({
              ...prevContact,
              contact_points: contactPoints,
            }))
          }
        />

        <ContactAddress
          contactAddresses={contact.addresses}
          onChange={(contactAddresses) =>
            setContact((prevContact) => ({
              ...prevContact,
              addresses: contactAddresses,
            }))
          }
        />

        {comment && (
          <div className="flex space-x-4">
            <FontAwesomeIcon icon="comment" color={PRIGHTER_BLUE} />
            <BaseTextField
              containerClassName="w-full"
              multiline
              value={contact.comment}
              onChange={(event) =>
                setContact((oldContact) => ({ ...oldContact, comment: event.target.value }))
              }
              label={tolgee.t({
                key: "generic.comment",
              })}
            />
            <div className="flex py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50">
              <IconButton
                data-testid="remove-address-button"
                onClick={() => {
                  setComment(false);
                  setContact((oldContact) => ({ ...oldContact, comment: null }));
                }}
                size="large">
                <FontAwesomeIcon icon="trash" className=" text-brand-600" size="xs" />{" "}
              </IconButton>
            </div>
          </div>
        )}
      </div>
      <div className="flex self-end w-full mt-4 space-x-2">
        <div className="flex items-center justify-center w-full">
          <SplitButton items={createItems} state={selectedIndex} />
        </div>

        {contactUuid && (
          <BaseButton onClick={handleDelete} color="error" testid="delete-button">
            <T keyName="generic.delete" />
          </BaseButton>
        )}
        <BaseButton
          disabled={!contact?.business?.public_id}
          onClick={handleUpsert}
          testid={contactUuid ? "save-button" : "create-button"}>
          {contactUuid
            ? tolgee.t({
                key: "generic.save",
              })
            : tolgee.t({
                key: "generic.create",
              })}
        </BaseButton>
      </div>
    </>
  );
}
