import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { SplitButton } from "components";
import BusinessRow from "pages/Admin/ManageUser/GeneralTab/BusinessRow";

import type { SplitButtonItems } from "types/components";
import type { ManagedUserDetail } from "types/user";

type Props = { user: ManagedUserDetail };

export default function GeneralTab({ user }: Props) {
  const [itemIndex, setItemIndex] = useState(0);

  const createItems: SplitButtonItems = [
    {
      name: "Email All Invoices",
      icon: "plus",
      action: () => {},
    },
  ];

  return (
    <div className="flex flex-col space-y-4">
      <div className="box-outerlayout">
        <h2>Quick Actions</h2>

        <div className="flex space-x-4">
          <SplitButton items={createItems} listHeight="424px" state={[itemIndex, setItemIndex]} />

          <SplitButton
            items={createItems}
            listHeight="424px"
            color="error"
            state={[itemIndex, setItemIndex]}
            buttonPreName="Danger Zone: "
          />
        </div>
      </div>

      <div className="box-outerlayout">
        <h2>Businesses</h2>

        {user.managed_businesses?.length !== 0 && (
          <DataGrid
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
            }}
            slots={{
              columnHeaders: () => null,
            }}
            rows={user.managed_businesses?.map((b) => ({ ...b, id: b.public_id }))}
            columns={[
              {
                flex: 1,
                field: "",
                renderCell: (params) => {
                  return (
                    <BusinessRow
                      business={params.row}
                      key={params.row.public_id}
                      caseStats={user.stats.cases_count.find(
                        (b) => b.public_id === params.row.public_id
                      )}
                    />
                  );
                },
              },
            ]}
            getRowHeight={() => "auto"}
            hideFooter
          />
        )}
      </div>
    </div>
  );
}
