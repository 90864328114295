import { useMemo } from "react";

import { Chip, VerticalDataValue } from "components";
import { stringifyAddress } from "helpers/general";

import type { ManagedUserDetail } from "types/user";

type Props = { user: ManagedUserDetail };

export default function Header({ user }: Props) {
  const { stats } = user;

  const standingStatus = useMemo(() => {
    if (stats.invoice_open === 0) {
      return ["Good", "bg-success"];
    }
    if (stats.invoice_open === 1) {
      return ["Warning", "bg-warning"];
    }
    return ["Danger", "bg-danger"];
  }, [stats.invoice_open]);

  return (
    <div>
      <div className="flex space-x-8">
        <h1>{user.email}</h1>

        <div className="flex space-x-2">
          <VerticalDataValue
            title="Standing"
            value={<Chip title={standingStatus[0]} backgroundColor={standingStatus[1]} />}
          />
          <VerticalDataValue
            title="Status"
            value={
              <Chip
                title={user.active ? "Active" : "Inactive"}
                backgroundColor={user.active ? "bg-success" : "bg-warning"}
              />
            }
          />
          <VerticalDataValue
            title="Marketing Consent"
            value={
              <Chip
                title={user.marketing_opt_in ? "Given" : "Not Given"}
                backgroundColor={user.marketing_opt_in ? "bg-success" : "bg-warning"}
              />
            }
          />
        </div>
      </div>

      <div className="grid grid-cols-[auto_auto_auto] grid-rows-[auto_auto] gap-x-4 gap-y-1 max-w-[45%] h-min">
        <VerticalDataValue title="Name" value={user.name} />

        <div className="flex space-x-2">
          <VerticalDataValue title="Paid" value={stats.invoice_paid} />
          <VerticalDataValue title="Open" value={stats.invoice_open} />
          <VerticalDataValue title="Void" value={stats.invoice_void} />
          <VerticalDataValue title="Draft" value={stats.invoice_draft} />
          <VerticalDataValue title="Refunded" value={stats.invoice_uncollectible} />
        </div>

        <VerticalDataValue title="Postal Address" value={stringifyAddress(user.address)} />

        <VerticalDataValue title="Role" value={user.company_representative_role} />
        <VerticalDataValue title="ID" value={user.uuid} />
        <VerticalDataValue
          title="Payment Method"
          value={user.config.preferred_payment_method_name || "-"}
        />
      </div>
    </div>
  );
}
