import { Button, MenuItem, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";

import { capitalizeFirstLetter } from "helpers/general";
import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
import { handleError } from "services/api/error";
import { RepLocationType } from "types/general";

export default function Snippet() {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const [locations, setLocations] = useState<string[]>([]);

  const { watch, setValue } = useForm({ defaultValues: { selectedLocation: "" } });

  const selectedLocation = watch("selectedLocation");

  const fetchLocationsData = useCallback(async () => {
    try {
      const res = await api.business.getRepLocations(RepLocationType.DSA);
      const sorted_locations = Object.keys(res.data).sort((a, b) => a.localeCompare(b));
      setLocations(sorted_locations);
    } catch (e) {
      handleError(e);
    }
  }, []);

  const fetchCurrentLocation = useCallback(async () => {
    try {
      const res = await api.business.getRepLocation(businessId, RepLocationType.DSA);
      setValue("selectedLocation", res.data);
    } catch (e) {
      handleError(e);
    }
  }, [businessId, setValue]);

  useEffect(() => {
    fetchLocationsData();
    fetchCurrentLocation();
  }, [fetchCurrentLocation, fetchLocationsData]);

  const handleSetLocation = async () => {
    try {
      await api.business.updateRepLocation(businessId, {
        location: selectedLocation,
        type: RepLocationType.DSA,
      });
    } catch (e) {
      handleError(e);
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "dsa.location_updated",
        }),
        type: "SUCCESS",
      })
    );
  };

  return (
    <div className="box-outerlayout pb-6 pt-10 mt-6">
      <div className="md:p-6 w-full">
        <h2>{tolgee.t("dsa.location_title")}</h2>
        <div>
          <TextField
            className="w-full"
            select
            value={selectedLocation}
            onChange={(event: any) => {
              setValue(`selectedLocation`, event.target?.value);
            }}
            label={tolgee.t({
              key: "dsa.location_label",
            })}>
            {locations.map((location) => (
              <MenuItem value={location} key={location}>
                {capitalizeFirstLetter(location)}
              </MenuItem>
            ))}
          </TextField>
          <div className="flex justify-end mt-6">
            <Button onClick={handleSetLocation}>{tolgee.t("dsa.set_address")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
