import { Checkbox } from "@mui/material";
import { T } from "@tolgee/react";
import { useFormContext } from "react-hook-form";

import { isEmpty } from "helpers";

import type { FadpForm } from "types/dsr/fadp";

export default function AdditionalInfoForm({ data }: any) {
  const { dsrs, actors, identifiable_info, files } = data;
  const { register, watch, setValue } = useFormContext<FadpForm>();
  const privacy_related = watch("request_privacy_related");

  return (
    <div>
      <div>
        <h2>
          <T keyName="fadp.summary" />:
        </h2>
        <div>
          <h4>
            <T keyName="fadp.request_type" />
          </h4>
          {dsrs
            ?.filter((i: any) => i.checked === true)
            ?.map((j: any) => (
              <div key={j.type}>
                - <T keyName={`dsr.type.${j.type.toLowerCase()}`} />
              </div>
            ))}
        </div>
        <div>
          <h4>
            <T keyName="fadp.personal_info" />
          </h4>
          {actors[0]?.acting_for && (
            <div>
              <T keyName="fadp.acting_for" />:
              <T keyName={`fadp.ds_info.acting_for.${actors[0].acting_for.toLowerCase()}`} />
            </div>
          )}
          {actors[0]?.name && (
            <div>
              <T keyName="fadp.name" />: {actors[0]?.name}
            </div>
          )}
          {actors[0]?.email && (
            <div>
              <T keyName="fadp.email" />: {actors[0]?.email}
            </div>
          )}
          {actors[0]?.address?.street && (
            <div>
              <T keyName="fadp.address" />: {actors[0]?.address?.street}
            </div>
          )}
          {(actors[0]?.address?.postal || actors[0]?.address?.city) && (
            <div>
              {actors[0]?.address?.postal} {actors[0]?.address?.city}
            </div>
          )}
          {actors[0]?.address?.country_iso && <div>{actors[0]?.address?.country_iso}</div>}
        </div>
        {!isEmpty(identifiable_info) && (
          <div>
            <h4>
              <T keyName="fadp.customer_info" />
            </h4>
            {identifiable_info?.username && (
              <div>
                <T keyName="fadp.username" />: {identifiable_info?.username}
              </div>
            )}
            {identifiable_info?.email && (
              <div>
                <T keyName="fadp.email" />: {identifiable_info?.email}
              </div>
            )}
            {identifiable_info?.customer_number && (
              <div>
                <T keyName="fadp.customer_number" />: {identifiable_info?.customer_number}
              </div>
            )}
            {identifiable_info?.other && (
              <div>
                <T keyName="fadp.other" />: {identifiable_info?.other}
              </div>
            )}
          </div>
        )}
        {files?.length > 0 && (
          <div>
            <h4>
              <T keyName="fadp.uploads" />
            </h4>
            {files?.map((i: any) => <div key={i.name}>- {i.name}</div>)}
          </div>
        )}
      </div>
      <h2 className="mt-8">
        <T keyName="fadp.additional_data" />:
      </h2>
      <button
        className="mt-2"
        type="button"
        onClick={() => setValue("request_privacy_related", !privacy_related)}>
        <div className="flex items-center text-center">
          <Checkbox {...register("request_privacy_related")} checked={privacy_related} />
          <span className="font-bold">
            <T keyName="fadp.additional_data.request_privacy_related" /> *
          </span>
        </div>
      </button>
    </div>
  );
}
