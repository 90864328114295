import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { BaseModal } from "components/DataDisplay/Modal";

import type { IconName } from "@fortawesome/fontawesome-svg-core";

type Props = { icon?: IconName; url: string; fileName: string; mimeType?: string };

export default function FileDownloadPreview({ icon, url, fileName, mimeType }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <div className="my-4">
      <div className="flex items-center space-x-2">
        <div className="flex items-center justify-center w-6 h-6 rounded-full bg-prighterblue">
          <FontAwesomeIcon size="xs" icon={icon || "file"} color="white" />
        </div>

        <a href={url}>{fileName}</a>
      </div>

      {mimeType?.includes("image") && (
        <button type="button" onClick={() => setOpen(true)} className="ml-8 mt-2 z-100">
          <img src={url} alt="case-file" width={256} />
        </button>
      )}

      <BaseModal modalState={[open, setOpen]}>
        <div className="w-[40rem] max-h-150 overflow-auto">
          <img src={url} alt="case-file" />
        </div>
      </BaseModal>
    </div>
  );
}
