import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControlLabel, IconButton, Popover, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";

import {
  API_BASE_ROUTE,
  DEV_ENV,
  IS_PROD_DEV,
  PRIGHTER_URL,
  REACT_BASE_ROUTE,
  STRIPE_KEY,
  TOLGEE_KEY,
} from "const/env";
import { CollapsibleInnerBox, HorizontalDataValue } from "components";
import api from "services/api";
import { useAppDispatch, useAppSelector } from "store";
import { setDevRedirection } from "store/app";
import { handleError } from "services/api/error";

import type { ApiStatus } from "types/app";

export default function DevelopmentWidget() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [apiStatus, setApiStatus] = useState<ApiStatus>({} as ApiStatus);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useAppDispatch();
  const reduxState = useAppSelector(
    createSelector(
      (state) => state,
      (state) => ({ ...state, app: { ...state.app, countries: [] } })
    )
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRedirect(event.target.checked);
    dispatch(setDevRedirection(event.target.checked));
  };

  useEffect(() => {
    async function fetchApiStatus() {
      let res;
      try {
        res = await api.general.ping();
      } catch (e) {
        handleError(e);
        return;
      }
      setApiStatus(res.data);
    }
    fetchApiStatus();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="absolute z-50 right-5 bottom-5 flex justify-center items-center bg-white border border-gray rounded-full">
        <IconButton className="w-12 h-12" onClick={handleClick}>
          <FontAwesomeIcon icon="code" />
        </IconButton>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}>
        <div className="p-4 space-y-4 h-[48rem] w-[40rem]">
          <FormControlLabel
            control={<Switch checked={redirect} onChange={handleChange} />}
            label="Redirection"
          />

          <div className="box-innerlayout space-y-4">
            <h2 className="mt-0">Environment Variables</h2>
            <HorizontalDataValue title="STRIPE_KEY" value={STRIPE_KEY} />
            <HorizontalDataValue title="API_BASE_ROUTE" value={API_BASE_ROUTE} />
            <HorizontalDataValue title="PRIGHTER_URL" value={PRIGHTER_URL} />
            <HorizontalDataValue title="REACT_BASE_ROUTE" value={REACT_BASE_ROUTE} />
            <HorizontalDataValue title="DEV_ENV" value={`${DEV_ENV}`} />
            <HorizontalDataValue title="IS_PROD_DEV" value={`${IS_PROD_DEV}`} />
            {TOLGEE_KEY && <HorizontalDataValue title="TOLGEE_KEY" value={TOLGEE_KEY} />}
          </div>

          {apiStatus?.user && (
            <CollapsibleInnerBox defaultCollapsed title={<h2 className="m-0">User Information</h2>}>
              <pre className="whitespace-pre-wrap break-words">
                {JSON.stringify(apiStatus.user, null, 2)}
              </pre>
            </CollapsibleInnerBox>
          )}

          {apiStatus && (
            <CollapsibleInnerBox defaultCollapsed title={<h2 className="m-0">API Status</h2>}>
              <pre className="whitespace-pre-wrap break-words">
                {JSON.stringify({ ...apiStatus, user: undefined }, null, 2)}
              </pre>
            </CollapsibleInnerBox>
          )}

          <CollapsibleInnerBox defaultCollapsed title={<h2 className="m-0">Redux State</h2>}>
            <pre className="whitespace-pre-wrap break-words">
              {JSON.stringify(reduxState, null, 2)}
            </pre>
          </CollapsibleInnerBox>
        </div>
      </Popover>
    </>
  );
}
