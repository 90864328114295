import { useState } from "react";
import { T } from "@tolgee/react";

import { BaseButton } from "components/Button";
import { BaseTextField } from "components/Input";
import { api } from "services";
import { handleError } from "services/api/error";

import type { SupportMessageType, SupportContentInterface } from "types/message";

interface Props {
  businessId: string;
  supportMessageType: SupportMessageType;
  initialContent?: string;
  modalTitle: string;
  contentPlaceholder?: string;
  openSupportModal: any;
}

export default function SupportMessage({
  businessId,
  supportMessageType,
  initialContent,
  modalTitle,
  contentPlaceholder,
  openSupportModal,
}: Props) {
  const [supportContent, setSupportContent] = useState<SupportContentInterface>({
    type: supportMessageType,
    content: initialContent || "",
    business_id: businessId,
  });

  const handleSendRequest = async () => {
    try {
      await api.message.postSupportMessage(supportContent);
    } catch (e) {
      handleError(e);
    } finally {
      openSupportModal(false);
    }
  };

  return (
    <div className="w-[40rem] overflow-auto">
      <h2 className="mb-4">{modalTitle}</h2>
      <div className="mb-8">
        <BaseTextField
          containerClassName="w-full"
          value={supportContent.content}
          placeholder={contentPlaceholder}
          onChange={(event) =>
            setSupportContent((prevState) => ({
              ...prevState,
              content: event.target.value,
            }))
          }
        />
      </div>
      <div className="flex flex-row justify-end gap-6">
        <BaseButton
          disabled={!supportContent.content}
          color="success"
          variant="outlined"
          onClick={handleSendRequest}>
          <T keyName="general.send" />
        </BaseButton>
        <BaseButton color="error" onClick={() => openSupportModal(false)}>
          <T keyName="general.discard" />
        </BaseButton>
      </div>
    </div>
  );
}
