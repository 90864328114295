import { createContext, useContext } from "react";

import type { ProviderAction } from "pages/Client/Case/CaseContext/reducer";
import type { ProviderState } from "pages/Client/Case/CaseContext/initialState";
import type { Dispatch } from "react";

export const StateContext = createContext<ProviderState>(null);
export const DispatchContext = createContext<Dispatch<ProviderAction>>(null);

export function useCase(): [ProviderState, Dispatch<ProviderAction>] {
  return [useContext(StateContext), useContext(DispatchContext)];
}
