import { useState } from "react";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NotificationType } from "const/Admin/Notifications";
import tolgee from "services/translation/tolgee";

const channelsMapping: Record<string, NotificationType> = {
  channel_admin: NotificationType.ADMIN,
  channel_all: NotificationType.ALL,
  channel_essential: NotificationType.ALL_ESSENTIAL,
  channel_billing: NotificationType.BILLING,
  channel_compliance: NotificationType.COMPLIANCE_RELATED,
  channel_privacy: NotificationType.PRIVACY_KNOWLEDGE,
  channel_dsr_details: NotificationType.DSR_DETAILS,
};

interface AddNotificationProps {
  onAdd: (email: string, channel: string) => void;
}

export default function AddNotification({ onAdd }: AddNotificationProps) {
  const [email, setEmail] = useState("");
  const [channel, setChannel] = useState("channel_all");
  const [emailError, setEmailError] = useState("");

  const validateEmail = (newEmail: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(newEmail);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (newEmail && !validateEmail(newEmail)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = () => {
    if (email && validateEmail(email)) {
      onAdd(email, channelsMapping[channel]);
      setEmail("");
      setEmailError("");
    } else if (email) {
      setEmailError("Please enter a valid email address");
    }
  };

  return (
    <div className="p-4">
      <h3 className="text-lg font-medium mb-4">{tolgee.t("notifications.add_new_notification")}</h3>
      <div className="flex flex-col gap-4">
        <FormControl fullWidth>
          <InputLabel id="channel-select-label">{tolgee.t("notifications.channel")}</InputLabel>
          <Select
            labelId="channel-select-label"
            value={channel}
            onChange={(e) => setChannel(e.target.value)}
            label={tolgee.t("notifications.channel")}
            displayEmpty>
            {Object.keys(channelsMapping).map((key) => (
              <MenuItem key={key} value={key}>
                {key.replace("channel_", "").replace("_", " ").toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label={tolgee.t("generic.email_address")}
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
          error={!!emailError}
          helperText={emailError}
          fullWidth
        />

        <div className="flex justify-start">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            startIcon={<FontAwesomeIcon icon="plus" />}
            disabled={!email || !!emailError}>
            {tolgee.t("generic.add")}
          </Button>
        </div>
      </div>
    </div>
  );
}
