import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { T } from "@tolgee/react";
import { useState } from "react";
import { useNavigate } from "react-router";

import NavItem from "components/Layout/Navbar/NavItem";
import { changePreference } from "store/app";
import { useAppDispatch } from "store";

import type { NavBusiness } from "components/Layout/Navbar";
import type { NavMenu } from "types/components";

export default function NavMenu(props: {
  navMenu: NavMenu;
  businesses: NavBusiness[];
  selectedBusiness: string;
  setSelectedBusiness: (id: string) => void;
}) {
  const navigate = useNavigate();
  const { navMenu, businesses, selectedBusiness, setSelectedBusiness } = props;
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id?: string) => {
    if (id !== undefined) {
      setSelectedBusiness(id);
      dispatch(changePreference({ selectedBusiness: id }));
      navigate("");
    }
    setAnchorEl(null);
  };

  return !(navMenu.business_id && navMenu.business_id !== selectedBusiness) ? (
    <div>
      {!navMenu.url && navMenu.i18n && (
        <div className="flex space-between w-full">
          <div className="mt-2 font-semibold text-prighterblue">
            <T
              keyName={
                navMenu.i18n.ns ? `${navMenu.i18n.ns}.${navMenu.i18n.key}` : navMenu.i18n.key
              }
              params={navMenu.i18n.params}
            />
          </div>
          {businesses.length > 1 && navMenu.select_field && (
            <>
              <div className="flex items-center space-x-2">
                <IconButton onClick={handleClick}>
                  <FontAwesomeIcon icon="angle-down" color="gray" size="xs" />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => handleClose()}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}>
                  {businesses.map((business) => (
                    <MenuItem
                      key={business.id}
                      selected={business.id === selectedBusiness}
                      onClick={() => handleClose(business.id)}>
                      {business.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </>
          )}
        </div>
      )}

      {navMenu?.sub_menu?.map((menu, ind) => (
        <div key={`sub-${ind}`} className="grid gap-1">
          {navMenu?.sub_menu?.length > 0 && (
            <NavMenu
              navMenu={menu}
              businesses={businesses}
              selectedBusiness={selectedBusiness}
              setSelectedBusiness={setSelectedBusiness}
            />
          )}
        </div>
      ))}

      {navMenu?.i18n && navMenu?.url && (
        <NavItem
          i18n={navMenu.i18n}
          url={navMenu.url}
          icon={navMenu?.icon}
          type={navMenu.type}
          custom_icon={navMenu?.custom_icon}
          notificationCount={navMenu.count}
          todo={navMenu.todo}
        />
      )}
    </div>
  ) : (
    <></>
  );
}
