import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";
import clsx from "clsx";

import { api } from "services";
import { productDict } from "const";
import { getFontColor } from "helpers/general";
import { LEGAL_INFO, PRIGHTER_URL, PRIVACY_POLICY } from "const/env";
import { getBeautyDate, getUrl } from "helpers";
import SkeletonLandingPage from "pages/Public/LandingPage/SkeletonView";
import EmptyLandingPage from "pages/Public/LandingPage/EmptyLandingPage";
import { BaseModal } from "components/DataDisplay/Modal";
import { handleError } from "services/api/error";

import type { ClpSettings, RepLocation, Position } from "types/clp";

const logoPosition: {
  [key in Position]: string;
} = {
  TOP_LEFT: "top-2 left-2",
  TOP_CENTER: "top-2 left-1/2 -translate-x-1/2",
  TOP_RIGHT: "top-2 right-2",
  CENTER_LEFT: "top-1/2 left-2 -translate-y-1/2",
  CENTER_RIGHT: "top-1/2 right-2 -translate-y-1/2",
  BOTTOM_LEFT: "bottom-2 left-2",
  BOTTOM_CENTER: "bottom-2 left-1/2 -translate-x-1/2",
  BOTTOM_RIGHT: "bottom-2 right-2",
};

const textColor: any = {
  light: "black",
  dark: "white",
};

const bgColor: any = {
  light: "rgb(255 255 255 / 50%)",
  dark: "rgb(0 0 0 / 50%)",
};

const adjustColor = (color: string, amount: number) => {
  return `#${color
    .replace(/^#/, "")
    .replace(/../g, (adjusted) =>
      `0${Math.min(255, Math.max(0, parseInt(adjusted, 16) + amount)).toString(16)}`.substr(-2)
    )}`;
};

export default function LandingPage() {
  const navigate = useNavigate();
  const { publicIdOrSlug } = useParams();
  const dispatch = useDispatch();
  const [settings, setSettings] = useState<ClpSettings>();
  const [repLocations, setRepLocations] = useState<RepLocation>({});
  const textStyle = { color: textColor[settings?.config?.theme?.toLowerCase()] };
  const [loading, setLoading] = useState(true);
  const [openModals, setOpenModals] = useState<{ [key: string]: boolean }>({});

  const toggleModal = (locationKey: string) => {
    setOpenModals((prev) => ({
      ...prev,
      [locationKey]: !prev[locationKey],
    }));
  };

  useEffect(() => {
    if (!settings) {
      return;
    }

    if (settings.config.slug && publicIdOrSlug !== settings.config.slug) {
      navigate(`/portal/${settings.config.slug}`, { replace: true });
    }
  }, [settings, publicIdOrSlug, navigate]);

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      let res;
      try {
        res = await api.clp.getSettings(publicIdOrSlug);
      } catch (e) {
        handleError(e, true);
        setLoading(false);
        return;
      }
      res = res.data;

      try {
        api.clp.visitPortal(publicIdOrSlug);
      } catch (e) {
        handleError(e, true);
      }
      setSettings(res);

      let resLocations;
      try {
        resLocations = await api.business.getBusinessRepLocations(res.public_id);
      } catch (e) {
        handleError(e, true);
        return;
      } finally {
        setLoading(false);
      }
      setRepLocations(resLocations.data);
    };

    fetchSettings();
  }, [publicIdOrSlug, dispatch]);

  const getAdjustedPrimaryColorByTheme = () => {
    if (!settings?.config?.clp_color_secondary) {
      return undefined;
    }

    const theme = settings.config.theme.toLowerCase();
    if (theme === "dark") {
      return adjustColor(settings.config.clp_color_secondary, -100); // Darken for light theme
    }
    if (theme === "light") {
      return adjustColor(settings.config.clp_color_secondary, 100); // Lighten for dark theme
    }
    return settings.config.clp_color_secondary; // Default to original color if theme is undefined
  };

  const adjustedPrimaryColorByTheme = getAdjustedPrimaryColorByTheme();
  const companyName = settings?.config?.trade_name || settings?.company_name;

  return !loading ? (
    <>
      {settings?.public_id && Object.keys(repLocations).length > 0 ? (
        <div
          style={{
            backgroundColor: settings.config.clp_color_secondary,
            backgroundImage: settings.config.clp_background_image_url
              ? `url(${settings.config.clp_background_image_url})`
              : "",
            backgroundSize: "cover",
          }}
          className="min-h-screen flex items-center justify-center">
          <div className="relative flex gap-4" style={{ padding: "110px" }}>
            {settings.config.logo_url && (
              <div className={`absolute ${logoPosition[settings.config.position]}`}>
                <img
                  alt="company logo"
                  style={{
                    maxHeight:
                      settings.config.position === "CENTER_RIGHT" ||
                      settings.config.position === "CENTER_LEFT"
                        ? "200px"
                        : "90px",
                    maxWidth:
                      settings.config.position !== "CENTER_RIGHT" &&
                      settings.config.position !== "CENTER_LEFT"
                        ? "200px"
                        : "90px",
                    width: "100%",
                    height: "100%",
                  }}
                  src={settings.config.logo_url}
                />
              </div>
            )}
            <div
              style={{
                backgroundColor: bgColor[settings.config.theme.toLowerCase()],
                borderColor: adjustedPrimaryColorByTheme,
              }}
              className="flex flex-col w-full max-w-screen-lg p-6 shadow-2xl backdrop-blur-sm space-y-6 rounded-lg border pt-0">
              <div className="flex mt-6 space-x-1 text-2xl font-bold md:text-4xl">
                <div style={textStyle}>
                  {companyName} <T keyName="public_clp.compliance_center" />
                </div>
              </div>
              {settings.config.clp_text && <div style={textStyle}>{settings.config.clp_text}</div>}
              {settings.config.dsr_form && (
                <>
                  <div>
                    <Button
                      component={Link}
                      to={`/dsr/${settings.public_id}`}
                      size="large"
                      style={{
                        backgroundColor: settings.config.clp_color_primary,
                      }}
                      className="flex self-start space-x-1">
                      <div
                        className="font-bold"
                        style={{ color: getFontColor(settings.config.clp_color_primary) }}>
                        <T keyName="public_clp.privacy_request" /> {companyName}
                      </div>
                    </Button>
                  </div>
                  <div className="flex space-x-1 text-xs">
                    <div style={textStyle}>
                      {companyName} <T keyName="public_clp.collects_ip" />
                    </div>
                  </div>
                </>
              )}
              <div style={{ ...textStyle, display: "inline" }}>
                <b>
                  <T keyName="public_clp.prighter_group" />
                </b>{" "}
                <T keyName="public_clp.is_representative" /> <b>{companyName}</b>{" "}
                <T keyName="public_clp.following_jusrisdictions" />
              </div>

              <div className="flex flex-wrap gap-8">
                {repLocations &&
                  Object.keys(repLocations).map((i) => {
                    const productKey = i as keyof typeof productDict;
                    return (
                      <React.Fragment key={i}>
                        <button
                          type="button"
                          onClick={() => toggleModal(i)}
                          className="cursor-pointer flex flex-col space-y-14 w-[110px] items-center">
                          <div className="h-14 w-14">
                            <img src={productDict[productKey]?.image} alt={i} />
                          </div>
                          <div className="mt-2 text-xs font-bold" style={textStyle}>
                            {productDict[productKey]?.name}
                          </div>
                        </button>
                        <BaseModal
                          modalState={[openModals[i] || false, () => toggleModal(i)]}
                          className="max-w-[30rem] w-full max-h-150 overflow-auto">
                          <div className="mb-4">
                            <h3 className="mt-0">{productDict[productKey]?.name}</h3>
                            <div className="flex gap-4 pb-4">
                              <div className="h-14 w-14">
                                <img src={productDict[productKey]?.image} alt={i} />
                              </div>
                              <div>
                                {repLocations[i].map((location, index) => {
                                  return (
                                    <>
                                      {index === 0 ? (
                                        <span className="font-bold">{location}</span>
                                      ) : (
                                        <span>
                                          {location}
                                          {index !== repLocations[i].length - 1 && ", "}
                                        </span>
                                      )}
                                      <br />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <Button
                            component={Link}
                            to={getUrl(
                              "FLASK",
                              productDict[productKey]?.verifyUrl(settings.public_id)
                            )}
                            size="large"
                            style={{
                              backgroundColor: settings.config.clp_color_primary,
                            }}
                            className="flex self-start w-full ">
                            <div
                              className="font-bold"
                              style={{ color: getFontColor(settings.config.clp_color_primary) }}>
                              <T keyName="public_clp.verify" />
                            </div>
                          </Button>
                        </BaseModal>
                      </React.Fragment>
                    );
                  })}
              </div>
              {(settings.config.cookie_policy_url || settings.config.privacy_policy_url) && (
                <div style={textStyle} className="flex flex-col space-y-2">
                  <h4 className="m-0">
                    <T keyName="landing_page.privacy_documentation" />:
                  </h4>
                  {settings.config.cookie_policy_url && (
                    <a
                      href={settings.config.cookie_policy_url}
                      style={textStyle}
                      className="underline">
                      <T keyName="landing_page.cookie_policy_name" /> {companyName}
                    </a>
                  )}
                  {settings.config.privacy_policy_url && (
                    <a
                      href={settings.config.privacy_policy_url}
                      style={textStyle}
                      className="underline">
                      <T keyName="landing_page.privacy_policy_name" /> {companyName}
                    </a>
                  )}
                </div>
              )}
              {(settings.config.certificate_image_urls.length !== 0 ||
                settings.certificates.length !== 0) && (
                <div style={textStyle} className="flex flex-col space-y-2">
                  <h4 className="m-0">
                    <T keyName="landing_page.other_certificates" />:
                  </h4>
                  <span>
                    <T
                      keyName="landing_page.other_certificates.disclaimer"
                      params={{ company_name: companyName || "" }}
                    />
                  </span>

                  {settings.certificates.length !== 0 && (
                    <div className="w-full">
                      <div className="hidden md:grid md:grid-cols-[repeat(5,1fr)_auto] font-bold mb-2">
                        <div>Certificate</div>
                        <div>Issuer</div>
                        <div>Date Issued</div>
                        <div>Valid Until</div>
                        <div>Website</div>
                      </div>
                      {settings.certificates.map((certificate) => (
                        <div
                          className={clsx(
                            "px-2 py-1 -mx-2 rounded",
                            settings.config.theme.toLowerCase() === "light"
                              ? "md:even:bg-slate-100"
                              : ["md:odd:bg-slate-100/10", "md:even:bg-white/30"]
                          )}
                          key={certificate.issued_at}>
                          <div className="mb-1 font-bold md:hidden">Certificate Details:</div>
                          <div className="bg-slate-100/50 p-2 rounded-lg md:bg-transparent md:p-0 md:rounded-none md:grid md:grid-cols-[repeat(5,1fr)_auto]">
                            <div className="mb-1 md:mb-0">
                              <span className="font-semibold md:hidden">Certificate: </span>
                              <span className="break-all">{certificate.cert}</span>
                            </div>
                            <div className="mb-1 md:mb-0">
                              <span className="font-semibold md:hidden">Issuer: </span>
                              <span className="break-all">{certificate.issuer}</span>
                            </div>
                            <div className="mb-1 md:mb-0">
                              <span className="font-semibold md:hidden">Date Issued: </span>
                              <span className="break-all">
                                {getBeautyDate(certificate.issued_at)}
                              </span>
                            </div>
                            <div className="mb-1 md:mb-0">
                              <span className="font-semibold md:hidden">Valid Until: </span>
                              <span className="break-all">
                                {getBeautyDate(certificate.valid_until)}
                              </span>
                            </div>
                            <div>
                              <span className="font-semibold md:hidden">Website: </span>
                              {certificate.url?.startsWith("http") ? (
                                <a href={certificate.url} className="break-all" style={textStyle}>
                                  {certificate.url}
                                </a>
                              ) : (
                                <span className="break-all">{certificate.url}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {settings.config.certificate_image_urls && (
                    <div className="flex flex-wrap gap-2 py-2 ">
                      {settings.config.certificate_image_urls.map((ci) => (
                        <img
                          key={ci}
                          width={200}
                          height={200}
                          className="object-contain"
                          src={ci}
                          alt="certificate"
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}

              <div>
                <div className="flex space-x-1">
                  <div style={textStyle}>
                    <T keyName="public_clp.website" />:
                  </div>
                  <a style={textStyle} className="underline" href={PRIGHTER_URL}>
                    {PRIGHTER_URL}
                  </a>
                </div>
                <div className="flex space-x-1">
                  <div style={textStyle}>
                    <T keyName="public_clp.add_following" />:
                  </div>
                  <b style={textStyle}>ID-{settings.public_id}</b>
                </div>
              </div>
              <div className="flex justify-between ">
                <div style={textStyle}>
                  <T keyName="public_clp.powered" />
                  <span className="font-bold">Prighter</span>
                </div>

                <div className="flex space-x-4 font-bold">
                  <a style={textStyle} href={PRIVACY_POLICY}>
                    <T keyName="public_clp.privacy_policy" />
                  </a>

                  <a style={textStyle} href={LEGAL_INFO}>
                    <T keyName="public_clp.legal" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmptyLandingPage />
      )}
    </>
  ) : (
    <SkeletonLandingPage />
  );
}
