import { api } from "services";
import { handleError } from "services/api/error";
import { store } from "store";
import { setCountries } from "store/app";

export async function loadCountries(): Promise<void> {
  let res;
  try {
    res = await api.general.getCountries();
  } catch (e) {
    handleError(e);
    return;
  }
  store.dispatch(setCountries(res.data));
}
