import { IconButton, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";

import { FormFileField } from "components";
import tolgee from "services/translation";
import TextCertificate from "pages/Client/LandingPage/TextCertificate";
import { BusinessFileEnum } from "types/business";

import type { BusinessConfig } from "types/clp";

type Type = "logo" | "cookie" | "privacy" | "imageCertificate" | "backgroundImage";

interface Props {
  handleUploadFile: (file: File, type: Type) => void;
  handleRemoveFile: (type: BusinessFileEnum, fileName?: string) => void;
}

export default function CertificationsPage({ handleUploadFile, handleRemoveFile }: Props) {
  const { watch } = useFormContext<BusinessConfig>();
  const cookie = watch("cookie_policy");
  const certImages = watch("certificate_image_urls", []);

  return (
    <div className="p-2 w-full">
      <div className="mb-8">
        <Stack spacing={2}>
          <div className="font-bold">{tolgee.t("clp.upload_up_to_4_certificates")}</div>
          <div>{tolgee.t("clp.image_recommendations")}</div>
          <div className="flex items-start w-full space-x-2">
            <FormFileField
              label={tolgee.t({ key: "clp.choose_file" })}
              className="mt-2"
              value={cookie?.name && [{ name: cookie.name }]}
              onChange={(files: File[]) => handleUploadFile(files[0], "imageCertificate") as any}
              fullWidth
            />

            <div className="flex gap-2 flex-wrap">
              {certImages.map((certImage: any) => (
                <div className="flex items-start" key={certImage}>
                  <img
                    alt="certificate url"
                    style={{ maxHeight: "300px", maxWidth: "300px" }}
                    src={certImage}
                  />

                  <IconButton
                    onClick={() =>
                      handleRemoveFile(
                        BusinessFileEnum.CERTIFICATE_IMAGE,
                        new URL(certImage).searchParams.get("cdn_path")
                      )
                    }
                    size="small">
                    <FontAwesomeIcon size="lg" icon="xmark" />
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
        </Stack>
      </div>
      <div>
        <TextCertificate />
      </div>
    </div>
  );
}
