import tolgee from "services/translation";

import type { NavMenu } from "types/components";
import type { UserRoleObject } from "types/user";
import type { Address, Country } from "types/general";

export function stringifyAddress(address: Address): string {
  let str = address.postal || "";
  if (address.city) {
    str += str ? ` ${address.city}` : address.city;
  }
  if (address.street) {
    str += str ? `, ${address.street}` : address.street;
  }
  if (address.country_iso) {
    str += str ? `, ${address.country_iso}` : address.country_iso;
  }

  return str || tolgee.t("generic.no_address");
}

export function getCountryName(countries: Country[], countryCode: string): string {
  const country = countries.find((c) => c.iso_3166_1_alpha_2 === countryCode);
  return country ? country.name : countryCode;
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function isManager(roles: UserRoleObject[]): boolean {
  return roles.some((role) => ["ADMIN", "MANAGER_CUSTOMER", "MANAGER_DSR"].includes(role.name));
}

export function getFontColor(hexColor: string): string {
  if (hexColor) {
    // Convert hex to RGB
    const r = parseInt(hexColor.substring(1, 2), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);

    // Calculate brightness
    const brightness = r * 0.299 + g * 0.587 + b * 0.114;

    // Return white for dark colors and black for light colors
    return brightness < 128 ? "#fff" : "#000";
  }
  return "";
}

export function ArrayWithIndex(length: number): number[] {
  return [...Array(length)].map((_, i) => i);
}

export function extractBusinessIdsFromNav(menu: NavMenu[]): string[] {
  const ids = new Set<string>();

  function collectIds(items: NavMenu[]): void {
    items.forEach((item) => {
      if (item.business_id) {
        ids.add(item.business_id);
      }
      if (item.sub_menu && item.sub_menu.length > 0) {
        collectIds(item.sub_menu);
      }
    });
  }

  collectIds(menu);
  return Array.from(ids);
}
