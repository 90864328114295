import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";

import AddControllerField from "pages/Client/Case/DSR/ClientView/Components/AddControllerField";
import { BaseModal } from "components";
import tolgee from "services/translation";
import { api } from "services";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import { isCheckFinished, generateI18nKey } from "helpers/case";
import { handleError } from "services/api/error";

export default function ControllerProcessorDecision() {
  const [state, dispatch] = useCase();
  const caseData = state.case;

  const [open, setOpen] = useState(false);
  const [controllerAdded, setControllerAdded] = useState(false);

  const caseClosed = caseData.state === "CLOSED";
  const isFinished = isCheckFinished(state);

  const handleAddController = async (contactId: string) => {
    try {
      await api.authCase.closeAsProcessor(caseData.uuid, contactId);
    } catch (e) {
      handleError(e);
      return;
    }

    fetchCaseData()(dispatch, caseData.uuid, false);
    setControllerAdded(true);
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="space-x-2">
          <Button disabled={isFinished} onClick={() => setOpen(true)}>
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "we_are_processor"),
            })}
          </Button>
        </div>

        <div className="flex gap-4">
          <Button
            color="success"
            disabled={isFinished}
            onClick={() => finalizeCheck()(dispatch, caseData.uuid, state.ui.check)}
            data-testid="dsr_confirmation_button">
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "we_are_controller"),
            })}
            <FontAwesomeIcon className="ml-2" size="lg" icon="circle-arrow-right" />
          </Button>

          {isFinished && !caseClosed && (
            <div>
              <Button
                onClick={() => reopenCheck()(dispatch, caseData.uuid, state.ui.check)}
                variant="outlined"
                endIcon={<FontAwesomeIcon icon="lock-open" className=" !text-sm" />}>
                {tolgee.t({
                  key: "general.reopen",
                })}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col mb-4 space-y-2">
        <h2>
          {tolgee.t({
            key: generateI18nKey(caseData.type, state.ui.check, "controller_processor_title"),
          })}
        </h2>
        <div className="prose max-w-[100%]">
          <ReactMarkdown>
            {tolgee.t({
              key: generateI18nKey(
                caseData.type,
                state.ui.check,
                "controller_processor_description"
              ),
            })}
          </ReactMarkdown>
        </div>
      </div>
      <BaseModal modalState={[open, setOpen]}>
        <div className="min-w-[60rem] flex flex-col">
          {controllerAdded ? (
            <span className="whitespace-pre-line">
              {tolgee.t({
                key: generateI18nKey(
                  caseData.type,
                  state.ui.check,
                  "controller_added_end_response"
                ),
              })}
            </span>
          ) : (
            <>
              <h2>
                {tolgee.t({
                  key: generateI18nKey(caseData.type, state.ui.check, "add_controller_title"),
                })}
              </h2>
              <AddControllerField handleAddAndClose={handleAddController} locked={false} />
            </>
          )}
        </div>
      </BaseModal>
    </>
  );
}
