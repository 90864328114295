import { useEffect, useState } from "react";
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { T } from "@tolgee/react";

import { getComparator, stableSort } from "components/DataDisplay/Table/EnhancedTable/helpers";
import EnhancedTableHead from "components/DataDisplay/Table/EnhancedTable/TableHead";
import { useDebounce } from "helpers/hooks";
import BaseModal from "components/DataDisplay/Modal/BaseModal";
import TableSearch from "components/Input/TextField/Custom/SearchField";
import { ArrayWithIndex } from "helpers/general";

import type {
  IgnoreFieldTypes,
  TableCell_,
  TableConfig,
  TableRow_,
  TableSortOrder,
} from "types/components/enhancedTable";

export default function EnhancedTable<KeyName extends string>(props: {
  config: TableConfig<KeyName>;
  showPagination?: boolean;
  onPageChange?: any;
  onRowsPerPageChange?: any;
  onOrderChange?: any;
  count?: number;
  hideEmptyRows?: boolean;
  loading?: boolean;
}) {
  const {
    config,
    showPagination,
    onPageChange,
    onRowsPerPageChange,
    onOrderChange,
    count,
    hideEmptyRows,
    loading,
  } = props;
  const { values, keys, settings } = config;
  const [filteredValues, setFilteredValues] = useState<TableRow_<KeyName>[]>([]);

  const navigate = useNavigate();

  const [order, setOrder] = useState<TableSortOrder>("asc"); // TODO check if you can do a customHook with the 5 state below, also less useState means more performance.
  const [orderBy, setOrderBy] = useState<KeyName>("" as KeyName);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce<string>(search, 500);

  const [open, setOpen] = useState(false); // modal state
  const [modalChildren, setModalChildren] = useState<React.ReactNode>(<></>); // modal state

  useEffect(() => {
    if (settings.defaultSort?.key) {
      setOrderBy(settings.defaultSort.key);
      setOrder(settings.defaultSort.order || "desc");
    }
  }, [settings.defaultSort]);

  useEffect(() => {
    setFilteredValues(values);
  }, [values]);

  useEffect(() => {
    if (debouncedValue && debouncedValue !== "") {
      setFilteredValues(
        values.filter((value) =>
          Object.values<TableCell_>(value.cells).some((cellVal) => {
            return cellVal.displayedString?.toLowerCase().includes(debouncedValue?.toLowerCase());
          })
        )
      );
    } else if (debouncedValue === "") {
      setFilteredValues(values);
    }
  }, [values, debouncedValue, setFilteredValues]);

  const handleRequestSort = (event: unknown, property: KeyName) => {
    if (onOrderChange) {
      onOrderChange(property);
      setOrderBy(property);
      setOrder("desc");
    } else {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    onRowsPerPageChange(newRowsPerPage);
  };

  const handleClick = (id: string) => {
    const actionCell = settings?.onClickEvents?.find((act) => act.id === id);

    if (actionCell?.actionUrl) {
      if (actionCell?.actionUrl.includes("http")) {
        window.location.assign(actionCell?.actionUrl);
      } else {
        navigate(actionCell?.actionUrl);
      }
    } else if (settings?.onClickType === "MODAL") {
      setModalChildren(actionCell?.modalElement);
      setOpen(true);
    }
  };

  const sortList = (): TableRow_<KeyName>[] => {
    return !settings.disableSort && orderBy
      ? stableSort(filteredValues, getComparator(order, orderBy))
      : filteredValues;
  };

  const adjustedSortList = () => {
    return showPagination
      ? sortList().slice(!showPagination && page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : sortList();
  };

  const loadingState = () => {
    return (
      <div className="p-6">
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </div>
    );
  };

  let emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredValues.length - page * rowsPerPage);
  emptyRows -= filteredValues.length === 0 && 1;
  if (values.length === 0) {
    emptyRows = 5;
  }
  const columnSize = settings.action?.headTitle ? keys.length : keys.length - 1;

  return (
    <div className="w-full mb-5">
      <TableSearch searchState={[search, setSearch]} />
      <Paper className="w-full -mb-4">
        <TableContainer>
          <Table
            style={{ minWidth: "750px", minHeight: "360px" }}
            aria-labelledby="tableTitle"
            aria-label="enhanced table">
            <EnhancedTableHead
              actionTitle={settings.action?.headTitle}
              keys={keys.filter(
                (k) => !settings?.ignoreFields?.includes(k as IgnoreFieldTypes<KeyName>)
              )}
              shouldSort={!settings?.disableSort}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              noTableHead={settings.noTableHead}
            />
            <TableBody>
              {adjustedSortList().map((row: TableRow_<KeyName>) => {
                const rowValues = Object.entries<TableCell_>(row.cells);
                const actionCell = config.settings?.action?.actionElements?.find(
                  (act) => act.id === row.id
                )?.action;

                return (
                  <TableRow
                    data-testid="table-row"
                    hover={!!settings?.onClickType}
                    tabIndex={0}
                    key={row.id}
                    className={clsx({
                      "cursor-pointer": settings?.onClickType,
                    })}
                    style={{ height: "60px" }}>
                    {rowValues.map(
                      ([key, value]) =>
                        !settings?.ignoreFields?.includes(key as IgnoreFieldTypes<KeyName>) && (
                          <TableCell key={key} onClick={() => handleClick(row.id)}>
                            {value.displayedElement || value.displayedString}
                          </TableCell>
                        )
                    )}
                    {actionCell && (
                      <TableCell
                        align="right"
                        padding="none"
                        style={{ width: settings?.action?.actionColumnWidth }}>
                        {actionCell}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {loading && loadingState()}
              {filteredValues.length === 0 && columnSize !== -1 && (
                <TableRow hover style={{ height: "60px" }}>
                  <TableCell>
                    <T keyName="generic.table.empty_message" />
                  </TableCell>
                  {ArrayWithIndex(columnSize).map((index) => (
                    <TableCell key={index} />
                  ))}
                </TableRow>
              )}
              {!hideEmptyRows && emptyRows > 0 && (
                <TableRow style={{ height: 60 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {showPagination && (
          <TablePagination
            style={{ backgroundColor: "#d9d9d9" }}
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count || filteredValues.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {settings.onClickType === "MODAL" && (
        <BaseModal modalState={[open, setOpen]}>{modalChildren}</BaseModal>
      )}
    </div>
  );
}
