import { useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CaseHistory from "pages/Client/History/CaseHistory";
import { BaseModal } from "components/DataDisplay/Modal";
import { VerticalDataValue, BaseButton, FileField, EditableText, BaseIconButton } from "components";
import { getBeautyDateTime, objectDeepCopy } from "helpers";
import api from "services/api";
import { notify } from "store/app";
import { emptyActor } from "const/emptyObjects";
import ChangeSenderModal from "pages/Client/Case/Authority/components/ChangeSenderModal";
import { getCaseSender } from "helpers/case";
import tolgee from "services/translation";
import MarkdownEditor from "components/Input/MarkdownEditor";
import { handleError } from "services/api/error";
import { BusinessFileEnum } from "types/business";

import type { Contact } from "types/contactDirectory";
import type { CaseContact, CaseDetail, CaseReply } from "types/case";

const defaultValues: CaseReply = {
  text: "<p><br></p>",
  subject: "",
};

type Props = { caseData: CaseDetail; handleRefresh?: () => void };

export default function AuthorityView({ caseData, handleRefresh }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reload, setReload] = useState(0);

  const [senderData, setSenderData] = useState<CaseContact>(emptyActor as CaseContact);
  const [copiedSenderData, setCopiedSenderData] = useState<Contact>(emptyActor.contact);
  const [openFilesModal, setOpenFilesModal] = useState<boolean>(false);
  const [filesLoading, setFilesLoading] = useState<boolean>(false);

  const [closeModal, setCloseModal] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { dirtyFields },
  } = useForm({
    defaultValues,
  });

  const textVal = watch("text");

  const { onChange, onBlur, name, ref } = register("subject");

  useEffect(() => {
    if (closeModal) {
      setCopiedSenderData(objectDeepCopy(senderData.contact));
    }
  }, [closeModal, senderData.contact]);

  useEffect(() => {
    const senderActor = getCaseSender(caseData.case_actors);

    // TODO you are serializing Country to country_iso in here. There is no better way for now, look at it in future.
    const sender = {
      ...senderActor,
      addresses: [
        ...senderActor.contact.addresses.map((address) => ({
          ...address,
          country_iso: address.country?.iso_3166_1_alpha_2,
        })),
      ],
      name: senderActor.contact?.name ? senderActor.contact?.name : emptyActor.contact.name,
    };

    setSenderData(sender);
    setCopiedSenderData(objectDeepCopy(sender.contact));
  }, [reload, caseData.uuid, caseData.case_actors]);

  const onSubmit = async (values: CaseReply) => {
    try {
      await api.authCase.internalComment(caseData.uuid, values);
    } catch (e) {
      handleError(e);
    } finally {
      reset(defaultValues);
      handleRefresh();
    }
  };

  const onEditorStateChange = (editorState: any) => {
    setValue("text", editorState, { shouldDirty: true });
  };

  const handleFileSubmit = async (files: File[]) => {
    setFilesLoading(true);
    try {
      await api.authCase.uploadFileToCase(caseData.uuid, files, BusinessFileEnum.AUTHORITY_UPLOAD);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setFilesLoading(false);
      setOpenFilesModal(false);
      handleRefresh();
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "generic.files_uploaded",
        }),
        type: "SUCCESS",
      })
    );
  };

  const handleCloseCase = async () => {
    try {
      await api.authCase.closeCase(caseData.uuid);
    } catch (e) {
      handleError(e);
      return;
    }
    dispatch(
      notify({
        message: tolgee.t({
          key: "cases.case_closed",
        }),
        type: "SUCCESS",
      })
    );
    navigate(-1);
  };

  const handleReopenCase = async () => {
    try {
      await api.authCase.reopenCase(caseData.uuid);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "cases.case_reopened",
        }),
        type: "SUCCESS",
      })
    );
    handleRefresh();
    setReload((prev) => prev + 1);
  };

  const getSenderEmail = (): string => {
    return senderData.contact.contact_points?.find((cp) => cp.type === "EMAIL")?.value || "";
  };

  const handleCloseModal = () => {
    setCloseModal(true);
    setCopiedSenderData(objectDeepCopy(senderData.contact));
    handleRefresh();
  };

  return (
    <div className="space-y-4 mx-auto max-w-7xl">
      <div className="box-outerlayout space-y-4" id="case-summary">
        <div className="flex space-x-2 items-center">
          <BaseIconButton className="border-full" onClick={() => handleRefresh()}>
            <div className="w-4 h-4 flex items-center justify-center">
              <FontAwesomeIcon icon="refresh" color="gray" />
            </div>
          </BaseIconButton>
          <h1>
            <T keyName="cases.case_summary" />
          </h1>
        </div>
        <div className="flex flex-row justify-between">
          <VerticalDataValue
            title={<T keyName="cases.email_alias" />}
            value={<a href={`mailto:${caseData.email_alias}`}>{caseData.email_alias}</a>}
          />
          <VerticalDataValue title={<T keyName="cases.state" />} value={caseData.state} />
        </div>

        <div className="flex flex-row justify-between">
          <VerticalDataValue
            title={<T keyName="generic.created_at" />}
            value={getBeautyDateTime(caseData?.created_at)}
          />
          <VerticalDataValue
            title={<T keyName="generic.updated_at" />}
            value={getBeautyDateTime(caseData?.updated_at)}
          />
        </div>

        <VerticalDataValue
          title={<T keyName="cases.authority" />}
          value={
            <EditableText
              modalState={[closeModal, setCloseModal]}
              value={
                <>
                  {senderData.contact.organization || <T keyName="generic.no_name" />} (
                  <a href={`mailto:${getSenderEmail()}`}>{getSenderEmail()}</a>)
                </>
              }>
              <ChangeSenderModal
                senderState={[copiedSenderData, setCopiedSenderData]}
                onCloseModal={handleCloseModal}
                onReload={() => setReload((prev) => prev + 1)}
                caseUuid={caseData.uuid}
                businessId={caseData?.business?.public_id}
              />
            </EditableText>
          }
        />
      </div>
      <div className="p-4 box-outerlayout flex space-x-4" id="case-actions">
        <div className="flex space-x-4 mt-4">
          <BaseButton className="self-end" onClick={() => setOpenFilesModal(true)}>
            <T keyName="generic.upload_files" />
          </BaseButton>
          <BaseModal modalState={[openFilesModal, setOpenFilesModal]}>
            <FileField
              label={tolgee.t({
                key: "generic.choose_files",
              })}
              multiple
              onSubmit={handleFileSubmit}
              loading={filesLoading}
            />
          </BaseModal>
          <BaseButton
            key={caseData.state}
            onClick={caseData.state === "CLOSED" ? handleReopenCase : handleCloseCase}>
            {caseData.state === "CLOSED"
              ? tolgee.t({
                  key: "cases.reopen_case",
                })
              : tolgee.t({
                  key: "cases.close_case",
                })}
          </BaseButton>
        </div>
      </div>
      <div className="flex flex-col box-outerlayout" id="case-history">
        <h1>
          <T keyName="cases.case_history" />
        </h1>
        <div className="flex flex-col space-y-4">
          <CaseHistory caseData={caseData} />
        </div>
        <div className="flex flex-col space-y-2 px-32">
          <TextField
            label={tolgee.t({
              key: "cases.subject",
            })}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            ref={ref}
          />
          <MarkdownEditor onChange={onEditorStateChange} value={textVal} />
          <div className="w-fit mt-4 flex self-end">
            <BaseButton onClick={handleSubmit(onSubmit)} disabled={!dirtyFields.text}>
              <T keyName="generic.send_message" />
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
}
