export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateEmailDomainsSame = (email1: string, email2: string): boolean => {
  if (!validateEmail(email1) || !validateEmail(email2)) {
    return false;
  }
  const domainRegex = /^[^\s@]+@([^\s@]+\.[^\s@]+)$/;
  const match1 = email1.match(domainRegex);
  const match2 = email2.match(domainRegex);
  return match1 && match2 && match1[1] === match2[1];
};
