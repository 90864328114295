import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router";
import { TextField } from "@mui/material";
import { T } from "@tolgee/react";

import { BaseButton } from "components";
import { api } from "services";
import { notify } from "store/app";
import PrighterLogo from "components/Prighter/PrighterLogo";
import { handleError } from "services/api/error";

type MarketingURLSearchParams = URLSearchParams & {
  email_address?: string;
};

export default function MarketingUnsubscribe() {
  const dispatch = useDispatch();
  const [searchParams]: [MarketingURLSearchParams, unknown] = useSearchParams();
  const [email, setEmail] = useState("");
  const [displayForm, setDisplayForm] = useState(false);
  const [error, setError] = useState(false);

  const unsubscribe = useCallback(
    async (emailParam: string) => {
      try {
        await api.marketing.unsubscribeMarketingMails(emailParam);
      } catch (e) {
        handleError(e, true);
        setDisplayForm(true);
        setError(true);
        return;
      }
      dispatch(notify({ type: "SUCCESS", message: "You successfully unsubscribed." }));
      setDisplayForm(false);
    },
    [dispatch, setDisplayForm, setError]
  );

  useEffect(() => {
    if (searchParams.has("email_address")) {
      unsubscribe(searchParams.get("email_address"));
    } else {
      setDisplayForm(true);
    }
  }, [searchParams, unsubscribe]);

  const handleSubmit = () => {
    unsubscribe(email);
  };

  return (
    <div className="flex justify-center items-center w-full h-screen bg-gray-50">
      <div className="p-0 box-outerlayout flex flex-col items-center">
        <div className="flex items-center justify-center w-full bg-gray-50 border-0 border-b border-solid py-1 mb-6">
          <PrighterLogo className="p-1 rounded-full" width={36} />
          <span className="text-prighterblue font-bold text-3xl">
            <T keyName="generic.prighter" />
          </span>
        </div>

        <div className="px-16 pb-6 flex flex-col items-center text-center">
          <span className="text-xl text-gray-700">
            <T keyName="marketing.unsubscribe" />
          </span>
          <span className="text-gray-600 text-sm -mt-1 mb-6">
            <T keyName="marketing.sorry_you_go" />
          </span>

          {displayForm && (
            <>
              <span className="mb-4">
                <T keyName="marketing.are_you_sure" /> <br />
                <T keyName="marketing.from_mails" />
              </span>

              <div className="w-full mx-2 mb-2">
                <TextField
                  type="email"
                  autoComplete="on"
                  className={clsx({ "border-danger-400 border-2": error })}
                  required
                  label="Email"
                  value={email}
                  onChange={(text) => setEmail(text.target.value)}
                />
              </div>

              <BaseButton
                variant="outlined"
                className="self-end"
                disabled={!email}
                onClick={handleSubmit}>
                <T keyName="marketing.unsubscribe" />
              </BaseButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
