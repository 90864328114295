import { BaseModal } from "components";
import ContactEdit from "pages/Client/ContactDirectory/Components/ContactEdit";

import type { Contact } from "types/contactDirectory";
import type { UseState } from "types";

type Props = {
  contactUuid?: string;
  modalState: UseState<boolean>;
  companyTitle?: string;
  emailRequired?: boolean;
  parentSubmit?: (contact: Contact) => void;
};

export default function ContactModal({
  contactUuid,
  modalState,
  companyTitle = "company",
  emailRequired,
  parentSubmit,
}: Props) {
  return (
    <BaseModal
      modalState={modalState}
      className="rounded-[1rem] max-h-svh w-full md:max-w-[60rem] max-w-[calc(100vw-2rem)] overflow-auto">
      <div className="flex flex-col w-full min-w-full ">
        <ContactEdit
          contactUuid={contactUuid}
          setModal={modalState[1]}
          companyTitle={companyTitle}
          emailRequired={emailRequired}
          parentSubmit={parentSubmit}
        />
      </div>
    </BaseModal>
  );
}
