import { useEffect, useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router";
import { T } from "@tolgee/react";

import {
  HorizontalDataValue,
  VerticalDataValue,
  CaseState,
  ProgressBar,
  PrighterFeedback,
} from "components";
import { jurisdictionDict } from "const";
import { sanitizeDSRRights } from "helpers/sanitizer";
import api from "services/api";
import { handleError } from "services/api/error";

import type { DsrLight, SanitizedDSRRights, DSRJurisdiction } from "types/dsr";

export default function DsrLight() {
  const { token } = useParams();
  const [data, setData] = useState({} as DsrLight);
  const [dsrRights, setDsrRights] = useState([] as SanitizedDSRRights);
  const [loading, setLoading] = useState(true);
  const [jurisdiction, setJurisdiction] = useState({} as DSRJurisdiction);

  useEffect(() => {
    async function fetchData() {
      let res;
      try {
        res = await api.dsr.getDsrLight(token);
      } catch (e) {
        handleError(e, true);
        return;
      }
      res = res.data;

      setDsrRights(sanitizeDSRRights(res.requests));
      setData(res);
      setJurisdiction(jurisdictionDict[res.dsr_type]);
      setLoading(false);
    }

    if (token) {
      fetchData();
    }
  }, [token, setData]);

  return (
    <div className="font-body min-h-screen relative mx-0 bg-gray-50 flex flex-col items-center">
      {!loading ? (
        <>
          {data?.datasubject_email ? (
            <div className="grid overflow-hidden grid-cols-2 grid-rows-none gap-2 p-8">
              <div className="pb-4">
                <h1 className="text-brand-900 mb-0">
                  <T keyName="dsr.dsr_for" />
                  <span className="text-gray-800">{data.company_name}</span>
                </h1>
                <span className="text-sm text-gray-800">{data.uuid}</span>
              </div>

              <div className="pb-4 flex justify-end items-center">
                <img
                  className="mr-2"
                  alt="gdpr-logo"
                  width={32}
                  src={jurisdiction?.jurisdiction_lock_icon_url}
                />
                <div className="text-center mr-2 text-gray-700">
                  {jurisdiction?.short_name} related <br /> request
                </div>
                <CaseState state={data.dsr_state} />
              </div>

              <div className="box-outerlayout flex flex-col">
                <h2 className="self-center">
                  <T keyName="dsr.who_ds" />
                </h2>
                <HorizontalDataValue
                  title="Data subject name/username"
                  value={data.datasubject_name}
                />
                <HorizontalDataValue title="Email address" value={data.datasubject_email} />
                <HorizontalDataValue
                  title="Data subject is acting for"
                  value={data.datasubject_acting_for}
                />
              </div>

              <div className="box-outerlayout flex flex-col">
                <h2 className="self-center">
                  <T keyName="dsr.additional_data_ds" />
                </h2>
                <VerticalDataValue title="Comment" value={data.dsr_comment} titleBold />
                {data.custom_form_field.map((field) => (
                  <VerticalDataValue
                    key={`${field.value}-${field.label}`}
                    title={field.label}
                    value={Array.isArray(field.value) ? field.value.join(", ") : field.value}
                    titleBold
                  />
                ))}
              </div>

              <div className="box-outerlayout flex flex-col h-fit">
                <h2 className="self-center">Location Information</h2>
                <div className="flex flex-row justify-between">
                  <VerticalDataValue title="IP address" value={data.ds_ip_address} />
                  <VerticalDataValue title="Geolocation" value={data.ds_ip_country_name} />
                </div>
              </div>

              <div className="box-outerlayout flex flex-col">
                <h2 className="self-center">
                  <T keyName="dsr.what_ds" />
                </h2>
                {dsrRights.map((right) => (
                  <div className="mb-4" key={right.title}>
                    <b>{right.title}</b>
                    <div className="mt-1">
                      {right.requests.map((req, ind) => (
                        <div
                          key={req.requestTitle}
                          className={clsx("p-2 flex flex-row justify-between", {
                            "bg-creme": ind % 2 === 0,
                          })}>
                          <div className="m-0">{req.requestTitle}</div>
                          <div className="m-0 capitalize">{`${req.data}`}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center w-full h-screen bg-gray-50">
              <PrighterFeedback>
                <div className="px-16 pb-6 flex flex-col items-center text-center">
                  <span className="text-xl text-gray-700">
                    <T keyName="dsr.no_dsr" />
                  </span>
                  <span className="text-gray-600 text-sm mt-1 mt-6 mb-6 flex flex-col">
                    <div>
                      <T keyName="dsr.link_invalid" />
                    </div>
                    <div>
                      <T keyName="dsr.new_link" />
                    </div>
                  </span>
                </div>
              </PrighterFeedback>
            </div>
          )}
        </>
      ) : (
        <ProgressBar />
      )}
    </div>
  );
}
