import { Button, ListItem, ListItemText, MenuItem, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";

import ProgressBar from "components/Feedback/ProgressBar";
import { getUrl } from "helpers";
import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
import { LinkButton } from "components";
import { handleError } from "services/api/error";

import type { DsrSettings } from "types/case";

const retentionOptions = [
  {
    value: 30,
    name: "30_days",
  },
  {
    value: 90,
    name: "90_days",
  },
  {
    value: 6 * 30,
    name: "6_month",
  },
  {
    value: 366,
    name: "1_year",
  },
  {
    value: 366 * 2,
    name: "2_years",
  },
  {
    value: 366 * 3,
    name: "3_years",
  },
  {
    value: 366 * 7,
    name: "7_years",
  },
  {
    value: 366 * 10,
    name: "10_years",
  },
];

export default function GlobalView() {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>();
  const [dsrSettings, setDsrSettings] = useState<DsrSettings>({} as DsrSettings);
  const [showRetentionUpdate, setShowRetentionUpdate] = useState<boolean>(false);
  const [archivalSubscription, setArchivalSubscription] = useState<boolean>(false);

  const { setValue, watch } = useForm();
  const retentionPeriod = watch("retention");

  const getDsrSettings = useCallback(async () => {
    let res;
    try {
      res = await api.dsr.getSettings(businessId);
    } catch (e) {
      handleError(e);
      return;
    }
    setDsrSettings(res.data);
  }, [businessId]);

  const getCompany = useCallback(async () => {
    let res;
    try {
      res = await api.business.get(businessId);
    } catch (e) {
      handleError(e);
      return;
    }
    const hasArchival = res.data.features.find((i) => i === "dsr-archival");
    setArchivalSubscription(!!hasArchival);
  }, [businessId]);

  const fetchInitialData = useCallback(async () => {
    setLoading(true);
    await getDsrSettings();
    await getCompany();
    setLoading(false);
  }, [getCompany, getDsrSettings]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  const updateRetentionPeriod = async () => {
    try {
      await api.ccpa.updateRetentionPeriod(businessId, retentionPeriod);
    } catch (e) {
      handleError(e);
      return;
    }
    dispatch(
      notify({
        message: tolgee.t({
          key: "dsr.retention_updated",
        }),
        type: "SUCCESS",
      })
    );
  };

  return (
    <>
      {loading ? (
        <ProgressBar />
      ) : (
        <div className="flex flex-col">
          <div className="mt-4 mb-4 box-outerlayout">
            <h1>
              <T keyName="dsr.configure_dsr_tool" />
            </h1>
            <div>
              <T keyName="dsr.dsr_configure_text" />
            </div>
          </div>
          <div className="mt-4 mb-4 box-outerlayout">
            <h2>
              <T keyName="dsr.channeling_ds_title" />
            </h2>
            <div>
              <T keyName="dsr.ds_dsrtool_access" />
            </div>
            <div className="flex flex-col mt-6 mb-6 -mx-4 @2xl:flex-row">
              <ListItem
                className="flex flex-col text-turquoise-500"
                alignItems="flex-start"
                component="a"
                target="_blank"
                href={dsrSettings.url_portal}>
                <ListItemText secondary={<T keyName="dsr.compliance_page" />} />

                <ListItemText
                  sx={{ color: "#0cc0b1 !important", fontWeight: "600" }}
                  disableTypography
                  primary={dsrSettings.url_portal}
                />
              </ListItem>
              <ListItem
                className="flex flex-col"
                alignItems="flex-start"
                component="a"
                target="_blank"
                href={dsrSettings.url_dsr_workflow}>
                <ListItemText secondary={<T keyName="dsr.dsr_workflow" />} />
                <ListItemText
                  sx={{ color: "#0cc0b1 !important", fontWeight: "600" }}
                  disableTypography
                  primary={dsrSettings.url_dsr_workflow}
                />
              </ListItem>
            </div>

            <div>
              <T keyName="dsr.include_the_following" />
            </div>
            <div className="p-4 mt-6 border-2 rounded-lg bg-gray-50">
              <div className="mb-2 font-bold">
                <T keyName="dsr.snippet.title" />
                <div>
                  <T keyName="dsr.snippet.description" />:
                  <a href={dsrSettings.url_portal} target="_blank" rel="noreferrer">
                    {dsrSettings.url_portal}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start mt-4 mb-4 box-outerlayout">
            <h2>
              <T keyName="dsr.customize_workflow" />
            </h2>
            <div className="mb-4">
              <T keyName="dsr.additional_questions" />
            </div>
            <div>
              <T keyName="dsr.customize_questions" />
            </div>
            <div className="mt-4">
              <LinkButton
                className="inline-block"
                href={getUrl(
                  "FLASK",
                  `/dsrtool/business/${businessId}/managedsrworkflow/customizedsrform`
                )}>
                <T keyName="dsr.customize_add_questions" />
              </LinkButton>
            </div>
          </div>
          <div className="mt-4 mb-4 box-outerlayout">
            <h2>
              <T keyName="dsr.dsr_archival" />
            </h2>
            {archivalSubscription ? (
              <>
                <div className="mb-4 italic">
                  <T keyName="dsr.dsrs_archived" />
                </div>
                <div className="flex items-start">
                  <Button
                    onClick={() => setShowRetentionUpdate(!showRetentionUpdate)}
                    variant="contained">
                    <T keyName="dsr.set_retention" />
                  </Button>
                </div>
                {showRetentionUpdate && (
                  <div className="p-4 mt-6 border border-gray-300 rounded-lg">
                    <div className="mt-4 mb-4 font-bold">
                      <T keyName="dsr.retention_period" />
                    </div>
                    <TextField
                      select
                      id="select"
                      className="w-full mt-4"
                      defaultValue={dsrSettings.dsr_retain_for}
                      value={retentionPeriod}
                      onChange={(event: any) => {
                        setValue(`retention`, event.target?.value);
                      }}>
                      {retentionOptions.map((i) => (
                        <MenuItem key={i.name} value={i.value}>
                          <T keyName={`generic.${i.name}`} />
                        </MenuItem>
                      ))}
                    </TextField>
                    <div className="flex items-start mt-6 mb-4">
                      <Button onClick={updateRetentionPeriod} variant="contained">
                        <T keyName="generic.update" />
                      </Button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="mb-4 italic">
                  <T keyName="dsr.dsrs_removed" />
                </div>
                <div className="flex items-start">
                  <LinkButton href={getUrl("FLASK", `/my/account#dsrArchivalSubscription`)}>
                    <T keyName="dsr.purchase_archival" />
                  </LinkButton>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
