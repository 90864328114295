import { Checkbox, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { captureException } from "@sentry/react";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";

import { BaseSelectField, FormFileField } from "components";
import { validateEmail } from "helpers/validate";
import tolgee from "services/translation";
import AddressForm from "components/complex/AddressForm";

import type { DsrConfigCaseType } from "types/case";
import type { DSRActingFor } from "types/dsr/main";
import type { UseState } from "types";
import type { CcpaForm } from "types/dsr/ccpa";

type Props = {
  checkboxState: UseState<boolean>;
  identificationData: DsrConfigCaseType;
};

export default function IdentificationForm({ checkboxState, identificationData }: Props) {
  const [sameEmail, setSameEmail] = useState(false);
  const [legalRepresentativeFile, setLegalRepresentativeFile] = useState("signedPermission");
  const [residentOfCalifornia, setResidentOfCalifornia] = checkboxState;
  const {
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<CcpaForm>();

  const acting_for = watch("actors.1.acting_for");
  const files = watch("files");
  const senderEmail = watch("actors.1.email");

  const identData = {
    name: watch("identifiable_info.name"),
    email: watch("identifiable_info.email"),
    address: watch("identifiable_info.address"),
    phone: watch("identifiable_info.phone"),
    customer_number: watch("identifiable_info.customer_number"),
    purchase_number: watch("identifiable_info.purchase_number"),
    pseudonym: watch("identifiable_info.pseudonym"),
    other: watch("identifiable_info.other"),
  };

  useEffect(() => {
    if (sameEmail) {
      setValue("identifiable_info.email", senderEmail);
    }
  }, [senderEmail, sameEmail, setValue]);

  register("actors.1.acting_for", {
    validate: {
      minLength: (e) => `${e}`.trim().length > 0 || "Please choose something.",
    },
  });

  register("files", {
    validate: {
      required: (e) => e.length > 0 || "Please add at least 1 file.",
    },
  });

  const handleUploadFiles = (fileList: File[]): void | boolean => {
    const fileArray = Array.from(fileList);

    const ccpaFileArray = fileArray.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64String = reader.result;
          resolve(base64String);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    });

    Promise.all(ccpaFileArray)
      .then((resolvedFileArray: any[]) => {
        setValue(
          "files",
          resolvedFileArray.map((f, ind) => ({ name: fileArray[ind].name, content: f }))
        );
      })
      .catch((error) => {
        captureException(error);
      });
    return true;
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSameEmail(event.target.checked);
    if (event.target.checked) {
      setValue("identifiable_info.email", getValues("actors.1.email"));
    }
  };

  return (
    <div>
      <div className="flex space-x-2 mt-6">
        <Checkbox
          checked={residentOfCalifornia}
          style={{ padding: 0, margin: 0, marginRight: 2 }}
          onChange={(event) => setResidentOfCalifornia(event.target.checked)}
        />
        <h2>
          <T keyName="ccpa.i_am_resident" />*
        </h2>
      </div>
      <h2>
        <T keyName="dsr.requester" />:
      </h2>
      <div className="flex flex-col space-y-4 mb-8">
        <BaseSelectField
          error={!!errors?.actors && !!errors?.actors[1]?.acting_for}
          required
          state={[acting_for, (val: DSRActingFor) => setValue("actors.1.acting_for", val)]}
          label={tolgee.t({
            key: "ccpa.ds_info.acting_for",
          })}
          options={{
            MYSELF: tolgee.t({
              key: "ccpa.ds_info.acting_for.myself",
            }),
            AS_PARENT: tolgee.t({
              key: "ccpa.ds_info.acting_for.as_parent",
            }),
            AS_LEGAL_REPRESENTATIVE: tolgee.t({
              key: "ccpa.ds_info.acting_for.as_legal_representative",
            }),
          }}
        />
        {!!errors?.actors && !!errors?.actors[1]?.acting_for && (
          <span className="text-danger pl-4 text-sm">
            <T keyName="ccpa.error_choose_acting_for" />
          </span>
        )}

        <TextField
          label={tolgee.t({
            key: "dsr.email.for_contact",
          })}
          {...register("actors.1.email", {
            validate: {
              minLength: (e) => `${e}`.length >= 4 || "Minimum length of your email should be 4.",
              validEmail: (e) => validateEmail(e) || "You need to add a valid email.",
            },
          })}
          error={!!errors.actors?.[1]?.email}
          helperText={errors.actors?.[1]?.email?.message}
          required
        />

        {acting_for && acting_for !== "MYSELF" && (
          <>
            <TextField
              label={tolgee.t({
                key: "generic.name",
              })}
              {...register("actors.1.name", {
                validate: {
                  minLength: (e) =>
                    `${e}`.length >= 4 || "Minimum length of your name should be 4.",
                },
              })}
              error={!!errors.actors?.[1]?.name}
              helperText={errors.actors?.[1]?.name?.message}
              required
            />

            {acting_for === "AS_LEGAL_REPRESENTATIVE" && (
              <BaseSelectField
                label={tolgee.t({
                  key: "ccpa.ds_info.as_legal_representative.file_type",
                })}
                options={{ signedPermission: "Signed Permission", poa: "Power of Attorney" }}
                state={[legalRepresentativeFile, setLegalRepresentativeFile]}
              />
            )}
          </>
        )}
        {identificationData.document_upload || (acting_for && acting_for !== "MYSELF") ? (
          <div>
            <span className="font-bold">
              {acting_for === "MYSELF" && (
                <div>
                  <T keyName="ccpa.ds_info.file_upload.myself" />
                </div>
              )}
              {acting_for === "AS_PARENT" && <T keyName="ccpa.ds_info.file_upload.as_parent" />}
              {acting_for === "AS_LEGAL_REPRESENTATIVE" &&
                legalRepresentativeFile === "signedPermission" && (
                  <T keyName="ccpa.ds_info.file_upload.as_legal_representative.signed_permission" />
                )}
              {acting_for === "AS_LEGAL_REPRESENTATIVE" && legalRepresentativeFile === "poa" && (
                <T keyName="ccpa.ds_info.file_upload.as_legal_representative.poa" />
              )}
            </span>

            <FormFileField
              label="Upload a file"
              className="mt-2"
              value={files}
              onChange={handleUploadFiles}
              multiple
              fullWidth
              required
            />

            {acting_for === "AS_LEGAL_REPRESENTATIVE" && legalRepresentativeFile === "poa" && (
              <span className="text-gray-500">
                <T keyName="ccpa.ds_info.file_upload.as_legal_representative.poa.text" />
              </span>
            )}

            {!!errors?.files && (
              <span className="text-danger pl-4 text-sm">
                <T keyName="ccpa.error.sender_upload_at_least_one_file" />
              </span>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <h2 className="mt-8">
        <T keyName="dsr.data_subject" />:
      </h2>

      {identificationData.required_inputs.map((item) => (
        <div key={item}>
          {item === "email" && (
            <div className="flex flex-col space-y-4 mb-6">
              <TextField
                value={identData.email}
                onChange={(e) => {
                  setValue(`identifiable_info.${item}`, e.target.value);
                  if (e.target.value === identData.email) {
                    setSameEmail(true);
                  } else {
                    setSameEmail(false);
                  }
                }}
                label={tolgee.t(`dsr.settings.ccpa.default_data.${item}`)}
                error={!!errors.identifiable_info?.[item]}
                helperText={errors.identifiable_info?.[item]?.message}
                required
              />
              <div className="flex space-x-2 mt-6">
                <Checkbox
                  checked={sameEmail}
                  style={{ padding: 0, margin: 0, marginRight: 2 }}
                  onChange={handleCheckboxChange}
                />
                <span>
                  <T keyName="dsr.use_the_same_email" />
                </span>
              </div>
            </div>
          )}
          {item === "address" && (
            <div className="flex flex-col space-y-4 mb-6">
              <AddressForm
                contactAddresses={[identData.address]}
                validationError={!!errors.identifiable_info?.[item]}
                onChange={(val: any) => setValue("identifiable_info.address", val[0])}
                required
                shrink
              />
            </div>
          )}
          {["name", "phone", "customer_number", "purchase_number", "pseudonym", "other"].includes(
            item
          ) && (
            <div className="flex flex-col space-y-4 mb-6">
              <TextField
                value={identData[item]}
                onChange={(e) => {
                  setValue(`identifiable_info.${item}`, e.target.value);
                }}
                label={tolgee.t(`dsr.settings.ccpa.default_data.${item}`)}
                error={!!errors.identifiable_info?.[item]}
                helperText={errors.identifiable_info?.[item]?.message}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
