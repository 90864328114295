import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type { PaginatedResponse, PaginationRequest } from "types/general";
import type {
  DashboardData,
  GetUsersRequest,
  Loa,
  ManagedUser,
  ManagedUserDetail,
  Note,
  Subscription,
  User,
  UserWithInvalidField,
} from "types/user";

export async function get(): Promise<AxiosResponse<User>> {
  return api.get("/auth/user");
}

export async function deleteSpam(userId: string): Promise<AxiosResponse<boolean>> {
  return api.delete("/v1/user/spam", {
    params: { user_uuid: userId },
  });
}

export async function list({
  order_by,
  page,
  per_page,
  desc,
  search_term,
  user_filter,
}: GetUsersRequest): Promise<AxiosResponse<PaginatedResponse<ManagedUser>>> {
  return api.get("/v1/users", {
    params: {
      order_by,
      page,
      per_page,
      desc,
      search_term,
      user_filter,
    },
  });
}

export async function listInvalidNames(): Promise<AxiosResponse<UserWithInvalidField[]>> {
  return api.get("/v1/report/invalid-name");
}

export async function listInvalidAddresses(): Promise<AxiosResponse<UserWithInvalidField[]>> {
  return api.get("/v1/report/invalid-address");
}

export async function listLoas(): Promise<AxiosResponse<Loa[]>> {
  return api.get("/v1/users/loas-provided");
}

export async function listChurned({
  order_by,
  page,
  desc,
  per_page,
  search_term,
}: PaginationRequest): Promise<AxiosResponse<PaginatedResponse<ManagedUser>>> {
  return api.get("/v1/users/churned", {
    params: {
      order_by,
      page,
      per_page,
      desc,
      search_term,
    },
  });
}

export async function listSubscriptions(
  businessId: string
): Promise<AxiosResponse<Subscription[]>> {
  return api.get("/v1/user/subscriptions", { params: { business_id: businessId } });
}

export async function fetchDashboardData(): Promise<AxiosResponse<DashboardData>> {
  return api.get("/v1/business/dashboard");
}

export async function setNote(uuid: string, note: Note): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/user/note", note, {
    params: { user_uuid: uuid },
  });
}

export async function detail(uuid: string): Promise<AxiosResponse<ManagedUserDetail>> {
  return api.get("/v1/user", { params: { user_uuid: uuid } });
}
