import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

import ControllerProcessorTag from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReviewTag/ControllerProcessorTag";
import DSIdentificationTag from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReviewTag/DSIdentificationTag";
import DSLocationTag from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReviewTag/DSLocationTag";
import { getDataSubject } from "helpers/case";

import type { CaseCheck, CaseDetail } from "types/case";

type Props = { caseData: CaseDetail; check: CaseCheck };

export default function PreCheckReviewTag({ caseData, check }: Props) {
  const personalData = getDataSubject(caseData.case_actors);

  return (
    <div className="bg-success-300/50 border-2 border-dotted border-success-500 p-2 flex justify-between">
      <div className="w-full pr-8">
        <b>
          <T keyName={`dsr.step_title.${check.name.toLowerCase()}`} />
        </b>
        <br />

        {check.name === "DS_LOCATION" && <DSLocationTag />}
        {check.name === "DS_IDENTIFICATION" && <DSIdentificationTag personalData={personalData} />}
        {check.name === "CONTROLLER_PROCESSOR" && (
          <ControllerProcessorTag decisions={check.case_decisions} />
        )}
      </div>
      <div className="bg-success-500 w-6 h-6 rounded-full flex self-center   items-center justify-center">
        <FontAwesomeIcon icon="check" color="white" />
      </div>
    </div>
  );
}
