import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

type Props = {
  required?: boolean;
  value?: number;
  onChange?: (event: number) => void;
};

function AmountField({ value, onChange = () => {}, required }: Props) {
  const [main, setMain] = useState(value ? `${Math.floor(value)}` : "0");
  const [cents, setCents] = useState(value ? `${value % 100}` : "00");

  useEffect(() => {
    onChange(Number.parseInt(`${main || 0}${cents}`, 10));
  }, [main, cents, onChange]);

  const handleMainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const mainValue = event.target.value;

    if (!/^[-]?[0-9]*$/.test(mainValue)) {
      return;
    }

    setMain(mainValue);
  };

  const handleCentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const centsValue = event.target.value;

    if (!/^[0-9]*$/.test(centsValue) || centsValue.length > 2) {
      return;
    }

    setCents(event.target.value);
  };

  const handleMainBlur = () => {
    if (!main) {
      setMain("0");
    }
  };

  const handleCentBlur = () => {
    let correctedValue = cents;

    if (correctedValue.length > 2) {
      correctedValue = correctedValue.substring(0, 2);
    } else if (correctedValue.length < 2) {
      correctedValue = correctedValue.padEnd(2, "0");
    }
    setCents(correctedValue);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      <TextField
        label="Amount"
        value={main}
        onChange={handleMainChange}
        onBlur={handleMainBlur}
        required={required}
        data-testid="input-amount"
      />
      <span>,</span>
      <TextField
        label="Cent"
        value={cents}
        onBlur={handleCentBlur}
        onChange={handleCentsChange}
        sx={{ width: "50px" }}
      />
    </Box>
  );
}

export default AmountField;
