import { useFormContext } from "react-hook-form";
import { IconButton, Stack, Select, MenuItem, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ThemeSwitch } from "components/ThemeSwitch/ThemeSwitch";
import ColorPicker from "pages/Client/LandingPage/ColorPicker";
import tolgee from "services/translation";
import { FormFileField } from "components";
import PositionPicker from "pages/Client/LandingPage/PositionPicker";
import { BusinessFileEnum } from "types/business";

import type { Dispatch, SetStateAction } from "react";
import type { Position } from "types/clp";

type Type = "logo" | "cookie" | "privacy" | "imageCertificate" | "backgroundImage";

interface Props {
  primaryColorCandidate: string;
  setPrimaryColorCandidate: Dispatch<SetStateAction<string>>;
  secondaryColorCandidate: string;
  setSecondaryColorCandidate: Dispatch<SetStateAction<string>>;
  handleUploadFile: (file: File, type: Type) => void;
  handleRemoveFile: (type: BusinessFileEnum, fileName?: string) => void;
  position: Position;
  setPosition: Dispatch<SetStateAction<Position>>;
  handleUpdateSettings: () => void;
}

const defaultColors = {
  primaryColor: "#4B637D",
  secondaryColor: "#FCFDFF",
};

export function CustomizeCompanyStyles({
  primaryColorCandidate,
  setPrimaryColorCandidate,
  secondaryColorCandidate,
  setSecondaryColorCandidate,
  handleUploadFile,
  handleRemoveFile,
  position,
  setPosition,
  handleUpdateSettings,
}: Props) {
  const { watch, setValue, handleSubmit } = useFormContext();

  const theme = watch("theme");
  const logo = watch("logo");
  const logoUrl = watch("logo_url");
  const backgroundImage = watch("background_image");
  const backgroundImageUrl = watch("clp_background_image_url");

  return (
    <div className="p-2 w-full">
      <div className="mb-8">
        <Stack
          className=" *:px-4 first:*:pl-0 last:*:pr-0 p-4 space-x-4 border-2 divide-x-2 rounded-lg bg-brand-50"
          direction="row"
          spacing={2}>
          <ColorPicker
            label={tolgee.t({ key: "clp.button_color" })}
            color={primaryColorCandidate}
            onChange={setPrimaryColorCandidate}
            handleResetDefault={() => setPrimaryColorCandidate(defaultColors.primaryColor)}
          />
          <ColorPicker
            label={tolgee.t({ key: "clp.background_color" })}
            color={secondaryColorCandidate}
            onChange={setSecondaryColorCandidate}
            handleResetDefault={() => setSecondaryColorCandidate(defaultColors.secondaryColor)}
          />
          <div className="grid gap-2 font-bold">
            {tolgee.t("clp.theme")}
            <ThemeSwitch
              checked={theme === "DARK"}
              onChange={(e) => setValue("theme", e.target.checked ? "DARK" : "LIGHT")}
            />
          </div>
        </Stack>
      </div>
      <div className="flex flex-row justify-between gap-6 mb-8">
        <Stack spacing={2}>
          <div>
            <div className="font-bold">{tolgee.t("clp.upload_logo")}</div>
          </div>
          <div className="flex flex-col items-start w-full space-x-4">
            <FormFileField
              label={tolgee.t({ key: "clp.choose_logo" })}
              className="mt-2"
              value={logo?.name && [{ name: logo.name }]}
              onChange={(files: any) => handleUploadFile(files[0], "logo") as any}
              fullWidth
            />

            {logoUrl && (
              <div className="py-8">
                <IconButton onClick={() => handleRemoveFile(BusinessFileEnum.LOGO)} size="small">
                  <FontAwesomeIcon size="lg" icon="xmark" />
                </IconButton>
                <img
                  alt="company logo"
                  style={{ maxHeight: "250px", maxWidth: "250px" }}
                  src={logoUrl}
                />
              </div>
            )}
          </div>
        </Stack>
        <Stack spacing={2}>
          <div>
            <div className="font-bold">{tolgee.t("upload_background_image")}</div>
          </div>
          <div className="flex flex-col items-start w-full space-x-4">
            <FormFileField
              label={tolgee.t({ key: "clp.choose_background_image" })}
              className="mt-2"
              value={backgroundImage?.name && [{ name: backgroundImage.name }]}
              onChange={(files: any) => handleUploadFile(files[0], "backgroundImage") as any}
              fullWidth
            />

            {backgroundImageUrl && (
              <div className="py-8">
                <IconButton
                  onClick={() => handleRemoveFile(BusinessFileEnum.CLP_BACKGROUND_IMAGE)}
                  size="small">
                  <FontAwesomeIcon size="lg" icon="xmark" />
                </IconButton>
                <img
                  alt="background"
                  style={{ maxHeight: "250px", maxWidth: "250px" }}
                  src={backgroundImageUrl}
                />
              </div>
            )}
          </div>
        </Stack>
      </div>
      <div className="mb-8">
        <Stack spacing={2}>
          <div className="font-bold">Logo Position:</div>
          <div className="grid gap-4">
            <PositionPicker position={position} setPosition={setPosition} />
            <Select
              value={position}
              onChange={(event) => setPosition(event.target.value as Position)}
              className="w-[300px]">
              <MenuItem value="TOP_LEFT">{tolgee.t("clp.top_left")}</MenuItem>
              <MenuItem value="TOP_RIGHT">{tolgee.t("clp.top_right")}</MenuItem>
              <MenuItem value="BOTTOM_RIGHT">{tolgee.t("clp.bottom_right")}</MenuItem>
              <MenuItem value="BOTTOM_LEFT">{tolgee.t("clp.bottom_left")}</MenuItem>
              <MenuItem value="TOP_CENTER">{tolgee.t("clp.top_center")}</MenuItem>
              <MenuItem value="BOTTOM_CENTER">{tolgee.t("clp.bottom_center")}</MenuItem>
              <MenuItem value="CENTER_LEFT">{tolgee.t("clp.center_left")}</MenuItem>
              <MenuItem value="CENTER_RIGHT">{tolgee.t("clp.center_right")}</MenuItem>
            </Select>
          </div>
        </Stack>
      </div>
      <div className="flex justify-end">
        <Button onClick={handleSubmit(handleUpdateSettings)}>{tolgee.t("generic.save")}</Button>
      </div>
    </div>
  );
}
