import { Link } from "react-router";
import { Fragment } from "react";

import { productDict } from "const";
import DashboardProduct from "pages/Client/Dashboard/Components/DashboardProduct";

import type { ProductSubscriptionStatus } from "types/user";
import type { Product, ProductKey } from "types/company";

type Props = { activeProducts: ProductSubscriptionStatus[]; businessId: string };

export default function ProductList({ activeProducts, businessId }: Props) {
  const getProduct = (productName: ProductKey): Product => {
    return productName in productDict && productDict[productName];
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
      {activeProducts.map((product: ProductSubscriptionStatus) => (
        <Fragment key={product.product}>
          {product.active && getProduct(product.product).productUrl ? (
            <Link
              to={getProduct(product.product).productUrl(businessId)}
              data-testid="active-product-list-item">
              <DashboardProduct product={getProduct(product.product)} productStatus="active" />
            </Link>
          ) : (
            <DashboardProduct
              product={getProduct(product.product)}
              productStatus={product.active ? "active" : product.loa_status}
              productPurchaseLink={product.purchase_link}
            />
          )}
        </Fragment>
      ))}

      <DashboardProduct product={getProduct("chinarep")} productStatus="not_started" />
    </div>
  );
}
