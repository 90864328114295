import { useEffect } from "react";
import { T } from "@tolgee/react";

import { useTable } from "helpers/hooks";
import { Table } from "components";
import tolgee from "services/translation";

import type { Company } from "types/company";

type Props = {
  companyData: Company;
};

export default function CompanySubscriptions({ companyData }: Props) {
  const [tableConfig, setKeys, setValues, setSettings] = useTable();
  const activeProducts = companyData?.features?.length > 0;

  useEffect(() => {
    if (activeProducts) {
      const subscriptions: any[] = [];
      const description = tolgee.t({
        key: "billing.description",
      });

      companyData.features.forEach((i) => {
        subscriptions.push({
          [description]: <T keyName={`product_name.${i}`} />,
          id: i,
        });
      });

      setValues(subscriptions);

      setSettings({
        hideSearch: true,
        hideEmptyRows: true,
      });

      setKeys([description]);
    }
  }, [activeProducts, companyData, setKeys, setValues, setSettings]);

  return (
    <div className="flex flex-col" style={{ overflowX: "auto" }}>
      {activeProducts ? (
        tableConfig && <Table config={tableConfig} />
      ) : (
        <T keyName="generic.none" />
      )}
    </div>
  );
}
