import type { BusinessAddress } from "types/dsr/main";
import type { UserBase } from "types/user";
import type { Company } from "types/company";
import type { Address } from "types/general";

export function stringifyCompanyAddress(company: Company | any): string {
  const { address = {} as Address } = company;
  let str = address.postal || "";
  if (address.city) {
    str += str ? ` ${address.city}` : address.city;
  }
  if (address.street) {
    str += str ? `, ${address.street}` : address.street;
  }
  if (address.country) {
    str += str ? `, ${address.country.flag}` : address.country.flag;
  }

  return str || "No Address";
}

export function stringifyUserAddress(user: UserBase): string {
  let str = user.company_postal_code || "";
  if (user.company_city) {
    str += str ? ` ${user.company_city}` : user.company_city;
  }
  if (user.company_address) {
    str += str ? `, ${user?.company_address}` : user.company_address;
  }
  if (user.company_country) {
    str += str ? `, ${user.company_country}` : user.company_country;
  }

  return str || "No Address";
}

export function stringifyBusinessAddress(address: BusinessAddress): string {
  let str = address.postal || "";
  if (address.city) {
    str += str ? ` ${address.city}` : address.city;
  }
  if (address.street) {
    str += str ? `, ${address.street}` : address.street;
  }
  if (address.country) {
    str += str ? `, ${address.country.flag}` : address.country.flag;
  }

  return str || "No Address";
}
