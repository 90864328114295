import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@mui/material";
import { T } from "@tolgee/react";

import { multilineStyle } from "pages/Client/EmailCases/Pages/styling";

export default function EmailCasesFormAdditional() {
  return (
    <div className="flex flex-col  rounded-[24px] gap-3 border border-prighterblue p-6 ">
      <div className="flex flex-col">
        <div className="flex  justify-between gap-3">
          <h2 className="m-0 text-black font-fire text-[28px] font-normal">
            <T keyName="email_intake.additional_data" />
          </h2>
          <FontAwesomeIcon icon="question-circle" className="text-prighterblue" />
        </div>
        <p className="text-sm my-1 text-gray-500">
          <T keyName="email_intake.additional_data_regarding_request" />
        </p>
      </div>
      <TextField
        label="Multiline"
        multiline
        sx={multilineStyle}
        rows={3}
        // {...register("additionalInformation")}
      />
    </div>
  );
}
