import { Checkbox, IconButton, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";
import { useSearchParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import RightCheckbox from "pages/Public/Dsr/Ccpa/Step1/RightCheckbox";
import tolgee from "services/translation";

import type { CcpaForm } from "types/dsr/ccpa";

export default function RightsForm() {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CcpaForm>();
  const [searchParams] = useSearchParams();
  const rights = searchParams.get("type")?.split(",") || [];
  const correctionData = watch("dsrs.2.extra.correct");
  const values = watch();

  const uncheckAccess = () => {
    setValue(`dsrs.1.inquiries.0.checked`, false);
    setValue(`dsrs.1.inquiries.1.checked`, false);
  };

  const checkValues = (value: boolean, index: number) => {
    if (value === false) {
      setValue(`dsrs.${index}.checked`, false);
      if (index === 1) {
        uncheckAccess();
      }
    } else if (index === 0) {
      setValue("dsrs.0.checked", true);
      setValue("dsrs.1.checked", false);
      uncheckAccess();
      setValue("dsrs.2.checked", false);
      setValue("dsrs.3.checked", false);
      setValue("dsrs.4.checked", false);
    } else if (index === 1 || index === 2) {
      setValue("dsrs.0.checked", false);
      setValue("dsrs.3.checked", false);
      setValue("dsrs.4.checked", false);
      setValue(`dsrs.${index}.checked`, true);
    } else {
      setValue("dsrs.0.checked", false);
      setValue("dsrs.1.checked", false);
      uncheckAccess();
      setValue("dsrs.2.checked", false);
      setValue(`dsrs.${index}.checked`, true);
    }
  };

  const addCorrectionData = () => {
    const newCorrectionData = correctionData
      ? [...correctionData, { data: "", why: "", new: "" }]
      : [{ data: "", why: "", new: "" }];
    setValue("dsrs.2.extra.correct", newCorrectionData);
  };

  const removeCorrectionData = (index: number) => {
    const newCorrectionData = correctionData.filter((_, i) => i !== index);
    setValue("dsrs.2.extra.correct", newCorrectionData);
  };

  return (
    <div>
      <div>
        <h2>
          <T keyName="ccpa.rights_form.i_would_like_to" />
        </h2>
        <hr className="h-px text-gray-500 bg-gray-500 border-0 mb-2" />

        {(rights.length === 0 || rights.includes("deletion")) && (
          <>
            <RightCheckbox
              title={tolgee.t({
                key: "ccpa.rights_form.deletion_request_title",
              })}
              register={register("dsrs.0.checked")}
              checked={values.dsrs[0]?.checked}
              onCheck={() => checkValues(!values.dsrs[0]?.checked, 0)}>
              <TextField
                {...register("dsrs.0.inquiries.0.answer")}
                className="w-full"
                label={tolgee.t({
                  key: "ccpa.rights_form.request_refers_to_following_personal_info",
                })}
                rows={4}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </RightCheckbox>

            <hr className="h-px text-gray-500 bg-gray-500 border-0 my-1" />
          </>
        )}

        {(rights.length === 0 || rights.includes("access")) && (
          <>
            <RightCheckbox
              title={tolgee.t({
                key: "ccpa.rights_form.access_request_title",
              })}
              register={register("dsrs.1.checked")}
              checked={values.dsrs[1]?.checked}
              onCheck={() => checkValues(!values.dsrs[1]?.checked, 1)}>
              <div>
                <Checkbox
                  {...register("dsrs.1.inquiries.0.checked")}
                  checked={values.dsrs[1].inquiries[0]?.checked}
                />
                <T keyName="ccpa.rights_form.categories" />:
                <div className="ml-6">
                  <li>
                    <T keyName="ccpa.rights_form.categories_of_personal_information_consumer" />
                  </li>
                  <li>
                    <T keyName="ccpa.rights_form.categories_of_sources" />
                  </li>
                  <li>
                    <T keyName="ccpa.rights_form.commertial_collection" />
                  </li>
                  <li>
                    <T keyName="ccpa.rights_form.categories_of_third_parties" />
                  </li>
                  <li>
                    <T keyName="ccpa.rights_form.categories_of_my_personal_info_that_sold" />
                  </li>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="flex items-center">
                  <Checkbox
                    {...register("dsrs.1.inquiries.1.checked")}
                    checked={values.dsrs[1].inquiries[1]?.checked}
                  />
                  <T keyName="ccpa.rights_form.my_request_concerns_specific_info" />
                </div>
                {values.dsrs[1].inquiries[1]?.checked && (
                  <div className="mt-4">
                    <TextField
                      {...register("dsrs.1.inquiries.1.answer")}
                      className="w-full"
                      label={tolgee.t({
                        key: "ccpa.rights_form.my_request_concerns_following_information",
                      })}
                      rows={4}
                      multiline
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                )}
              </div>
            </RightCheckbox>

            <hr className="h-px text-gray-500 bg-gray-500 border-0 my-1" />
          </>
        )}

        {(rights.length === 0 || rights.includes("correction")) && (
          <>
            <RightCheckbox
              title={tolgee.t({
                key: "ccpa.rights_form.change_request_title",
              })}
              register={register("dsrs.2.checked")}
              checked={values.dsrs[2]?.checked}
              onCheck={() => checkValues(!values.dsrs[2]?.checked, 2)}>
              <div className="flex flex-col space-y-2">
                <div className="space-y-6">
                  {correctionData?.map((data, index) => (
                    <div className="flex items-center space-x-2" key={index}>
                      <div className="flex-1 min-w-0 transition-all duration-300 focus-within:flex-[3]">
                        <TextField
                          className="w-full"
                          {...register(`dsrs.2.extra.correct.${index}.data`, {
                            required: values.dsrs[2]?.checked && index === 0,
                          })}
                          label={tolgee.t({
                            key: "ccpa.rights_form.request_related_to_following_personal_information",
                          })}
                          rows={2}
                          multiline
                          required={index === 0}
                          error={!!(errors.dsrs && errors.dsrs[2]?.extra?.correct[index]?.data)}
                        />
                      </div>
                      <div className="flex-1 min-w-0 transition-all duration-300 focus-within:flex-[3]">
                        <TextField
                          className="w-full"
                          {...register(`dsrs.2.extra.correct.${index}.why`, {
                            required: values.dsrs[2]?.checked && index === 0,
                          })}
                          label={tolgee.t({
                            key: "ccpa.rights_form.personal_information_is_wrong_or_incomplete_because",
                          })}
                          rows={2}
                          multiline
                          required={index === 0}
                          error={!!(errors.dsrs && errors.dsrs[2]?.extra?.correct[index]?.why)}
                        />
                      </div>
                      <div className="flex-1 min-w-0 transition-all duration-300 focus-within:flex-[3]">
                        <TextField
                          className="w-full"
                          {...register(`dsrs.2.extra.correct.${index}.new`, {
                            required: values.dsrs[2]?.checked && index === 0,
                          })}
                          label={tolgee.t({
                            key: "ccpa.rights_form.amend_the_personal_information_as_follows",
                          })}
                          rows={2}
                          multiline
                          required={index === 0}
                          error={!!(errors.dsrs && errors.dsrs[2]?.extra?.correct[index]?.new)}
                        />
                      </div>
                      <IconButton
                        size="medium"
                        className="flex-shrink-0"
                        onClick={() => removeCorrectionData(index)}
                        disabled={index === 0 && correctionData.length === 1}>
                        <FontAwesomeIcon icon="trash" size="xs" />
                      </IconButton>
                    </div>
                  ))}
                </div>
                <IconButton size="medium" className="self-end" onClick={addCorrectionData}>
                  <FontAwesomeIcon icon="plus" size="sm" />
                </IconButton>
              </div>
            </RightCheckbox>

            <hr className="h-px text-gray-500 bg-gray-500 border-0 my-1" />
          </>
        )}

        {(rights.length === 0 || rights.includes("opt-out")) && (
          <>
            <RightCheckbox
              title={tolgee.t({
                key: "ccpa.rights_form.optout_request_title",
              })}
              register={register("dsrs.3.checked")}
              checked={values.dsrs[3]?.checked}
              onCheck={() => checkValues(!values.dsrs[3]?.checked, 3)}
            />

            <hr className="h-px text-gray-500 bg-gray-500 border-0 my-1" />

            <RightCheckbox
              title={tolgee.t({
                key: "ccpa.rights_form.limit_request_title",
              })}
              register={register("dsrs.4.checked")}
              checked={values.dsrs[4]?.checked}
              onCheck={() => checkValues(!values.dsrs[4]?.checked, 4)}
            />
            <hr className="h-px text-gray-500 bg-gray-500 border-0 my-1" />
          </>
        )}
        {rights.includes("opt-out") || rights.includes("limit") ? (
          <div className="mt-6 mx-2">
            <div className="bg-gray-50 rounded-lg border p-2 mb-2">
              <h4>{tolgee.t("ccpa.rights_form.explanation_optout_title")}</h4>
              <div>{tolgee.t("ccpa.rights_form.explanation_optout_description")}</div>
            </div>
            <div className="mb-2 p-2">
              <h4>{tolgee.t("ccpa.rights_form.explanation_limit_title")}</h4>
              <div>{tolgee.t("ccpa.rights_form.explanation_limit_description")}</div>
            </div>
            <div className="bg-gray-50 rounded-lg border p-2">
              <h4>{tolgee.t("ccpa.rights_form.explanation_generic_title")}</h4>
              <div>{tolgee.t("ccpa.rights_form.explanation_generic_description")}</div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="mt-6 mx-2">
        <div>{tolgee.t("ccpa.rights_form.limitations_description")}</div>
      </div>
    </div>
  );
}
