import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { useCallback } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import { BaseButton, BaseSelectField } from "components";
import { notify } from "store/app";
import { api } from "services";
import tolgee from "services/translation";
import { availableCurrenciesDict } from "const/billing";
import AmountField from "components/Input/TextField/AmountField";
import { handleError } from "services/api/error";

import type { Currency, WisePaymentObject } from "types/billing";

const defaultValues: WisePaymentObject = {
  invoice_number: "",
  amount: null,
  currency: "EUR",
  transfer_number: "",
  reference: "",
  paid_at: moment().subtract(1, "days").startOf("day").format(), // Initially yesterday's date at midnight
};

export default function Transferwise() {
  const dispatch = useDispatch();

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
  });

  const form = watch();
  const amount = watch("amount");
  const currency = watch("currency");
  const paidAt = watch("paid_at");

  const onSubmit = async () => {
    trigger();
    if (isValid) {
      try {
        await api.billing.settleWisePayment(form);
        dispatch(
          notify({
            message: "Invoice Settled!",
            type: "SUCCESS",
          })
        );
      } catch (e) {
        handleError(e);
        dispatch(
          notify({
            message: e.message,
            type: "ERROR",
          })
        );
      }
    }
  };

  const handleAmountChange = useCallback(
    (val: number) => {
      setValue("amount", val);
    },
    [setValue]
  );

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="transferwise-page">
      <div className="box-outerlayout flex flex-col space-y-2 pb-4">
        <h1>Track Transferwise Payment</h1>

        <TextField
          label={<T keyName="billing.invoice_number" />}
          error={!!errors?.invoice_number}
          helperText={errors?.invoice_number?.message}
          data-testid="input-invoice-number"
          required
          {...register("invoice_number", {
            validate: {
              minLength: (e) =>
                `${e}`.length >= 10 || "Invoice number should look like 'XXXXXX-0000'",
            },
          })}
        />

        <div className="flex space-x-2">
          <AmountField
            data-testid="input-amount"
            value={amount}
            onChange={handleAmountChange}
            required
          />

          <div className="w-24">
            <BaseSelectField
              label={tolgee.t({
                key: "billing.currency",
              })}
              state={[currency, (val: string) => setValue("currency", val as Currency)]}
              options={availableCurrenciesDict}
            />
          </div>
        </div>

        <TextField
          label={<T keyName="billing.wise.transfer_number" />}
          error={!!errors?.transfer_number}
          helperText={errors?.transfer_number?.message}
          data-testid="input-transfer-number"
          required
          {...register("transfer_number", {
            required: "Please enter a valid transfer number.",
          })}
        />

        <div data-testid="input-paid-at">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              maxDate={moment()}
              format="DD/MM/YYYY"
              label={tolgee.t({ key: "billing.paid_at" })}
              className="w-full"
              value={moment(paidAt)}
              {...register("paid_at")}
              onChange={(date) => setValue("paid_at", date.startOf("day").format())}
            />
          </LocalizationProvider>
        </div>

        <TextField label={<T keyName="billing.wise.reference" />} {...register("reference")} />

        <div className="w-fit self-end mt-4">
          <BaseButton testid="submit" onClick={() => onSubmit()}>
            <T keyName="generic.submit_payment_details" />
          </BaseButton>
        </div>
      </div>
    </div>
  );
}
