import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button } from "@mui/material";
import { T } from "@tolgee/react";
import { useMemo, useState } from "react";
import { Link } from "react-router";

import { useAppSelector } from "store";
import { TaskPriority } from "types/user";

import type { BusinessToDos, ToDo } from "types/user";

type Props = { userToDos: ToDo[]; businessTodos: BusinessToDos };

export default function ToDoList({ userToDos, businessTodos }: Props) {
  const [showAll, setShowAll] = useState<boolean>(false);
  const todosLeft = useMemo(() => {
    let count = userToDos.filter((i) => !i.closed_at).length;
    Object.values(businessTodos).forEach((businessTodoList) => {
      count += businessTodoList.filter((j) => !j?.closed_at).length;
    });
    return count;
  }, [userToDos, businessTodos]);

  const { managed_businesses } = useAppSelector((state) => state.user);

  const getCompanyName = (businessId: string) => {
    return managed_businesses.find((b) => b.public_id === businessId)?.company_name || businessId;
  };

  return (
    <div className="box-outerlayout" data-testid="todos-section">
      <div className={`p-0 ${!showAll && "max-h-[36rem]"} overflow-y-auto`}>
        <h2 className="pb-4 text-2xl border-0 border-b border-gray-200 border-solid md:px-4 text-brand-900">
          <T keyName="dashboard.complete_onboarding" />
        </h2>
        {userToDos.length > 0 && (
          <div className="flex flex-col pt-0 space-y-1 md:p-4">
            <h4 className="mt-0 mb-4">
              <T keyName="dashboard.account_tasks" />
            </h4>
            {userToDos.map((todo: ToDo) => (
              <Link
                key={todo.id}
                to={todo.url}
                style={todo.closed_at ? { pointerEvents: "none" } : null}>
                <div
                  className="flex flex-row items-center justify-between px-4 py-2 rounded-lg"
                  data-testid="user-todo">
                  <p className="text-md leading-6 text-gray-900">
                    <T keyName={`dashboard.task.${todo.name}_title`} />
                  </p>
                  <div className="flex flex-row items-center gap-4">
                    <p className="text-sm leading-6 text-gray-900">
                      <T keyName={`dashboard.task.${todo.name}_action`} />
                    </p>
                    <FontAwesomeIcon icon="chevron-right" className="text-gray-400" size="lg" />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}

        {Object.entries(businessTodos).map(
          ([businessId, businessTodoList]) =>
            businessTodoList?.length > 0 && (
              <div
                key={businessId}
                className="flex flex-col pt-0 space-y-1 md:p-4"
                data-testid="business-todo">
                <h4 className="mt-0 mb-4">
                  Complete {getCompanyName(businessId)}&apos;s Onboarding
                </h4>
                {businessTodoList
                  .filter((t) => !t.closed_at)
                  .map((todo: ToDo) => (
                    <Link
                      key={todo.id}
                      to={todo.url}
                      style={todo.closed_at ? { pointerEvents: "none" } : null}>
                      {todo.priority === TaskPriority.HIGH ? (
                        <Alert
                          severity="warning"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            borderRadius: "8px",
                            padding: "8px 16px",
                            "& .MuiAlert-message": {
                              flex: 1,
                              padding: 0,
                            },
                          }}>
                          <div className="flex flex-row items-center justify-between">
                            <p className="text-md leading-6 text-gray-900">
                              <T keyName={`dashboard.task.${todo.name}_title`} />
                            </p>
                            <div className="flex flex-row items-center gap-4">
                              <p className="text-sm leading-6 text-gray-900">
                                <T keyName={`dashboard.task.${todo.name}_action`} />
                              </p>
                              <FontAwesomeIcon
                                icon="chevron-right"
                                className="text-gray-400"
                                size="lg"
                              />
                            </div>
                          </div>
                        </Alert>
                      ) : (
                        <div className="flex flex-row items-center justify-between px-4 py-2 rounded-lg">
                          <p className="text-md leading-6 text-gray-900">
                            <T keyName={`dashboard.task.${todo.name}_title`} />
                          </p>
                          <div className="flex flex-row items-center gap-4">
                            <p className="text-sm leading-6 text-gray-900">
                              <T keyName={`dashboard.task.${todo.name}_action`} />
                            </p>
                            <FontAwesomeIcon
                              icon="chevron-right"
                              className="text-gray-400"
                              size="lg"
                            />
                          </div>
                        </div>
                      )}
                    </Link>
                  ))}
              </div>
            )
        )}
      </div>
      {todosLeft > 7 && (
        <Button onClick={() => setShowAll(!showAll)}>
          {showAll ? <T keyName="generic.show_less" /> : <T keyName="generic.show_all" />}
        </Button>
      )}
    </div>
  );
}
