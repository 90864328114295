import { T } from "@tolgee/react";

import Snippet from "pages/Client/RepPages/components/Snippet";
import Info from "pages/Client/RepPages/EURep/Components/Info";
import Location from "pages/Client/RepPages/EURep/Components/Location";
import Certificate from "pages/Client/RepPages/components/Certificate";

export default function EURep() {
  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <h1>
        <T keyName="gdpr.eurep_title" />
      </h1>
      <Info />
      <Snippet type="art27" />
      <Certificate type="art27" />
      <Location />
    </div>
  );
}
