import { T } from "@tolgee/react";
import { useState } from "react";
import { useParams } from "react-router";

import { BaseButton } from "components/Button";
import { BaseModal } from "components/DataDisplay/Modal";
import { SupportMessageType } from "types/message";
import SupportMessage from "components/Support/SupportMessage";
import tolgee from "services/translation";

export default function BetaProgram() {
  const { businessId } = useParams();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="mt-4 mb-4 box-outerlayout">
      <h2 className="mb-4">
        <T keyName="dsr.settings.ccpa.beta_title" />
      </h2>
      <p className="m-0">
        <T keyName="dsr.settings.ccpa.beta_description" />
      </p>
      <div className="box-dotted mt-4 mb-4">
        <p className="m-0 font-bold">
          <T keyName="dsr.settings.ccpa.beta_identify_title" />
        </p>
        <p className="m-0">
          <T keyName="dsr.settings.ccpa.beta_identify_description" />
        </p>
      </div>
      <div className="flex items-start">
        <BaseButton className="w-auto" variant="outlined" onClick={() => setOpen(true)}>
          <T keyName="dsr.settings.ccpa.beta_join_program" />
        </BaseButton>
      </div>
      <BaseModal modalState={[open, setOpen]}>
        <SupportMessage
          businessId={businessId}
          supportMessageType={SupportMessageType.CCPA_CONFIG_LOGIN}
          initialContent="I'm interested in using our own login for data subject verification"
          modalTitle={tolgee.t("dsr.settings.ccpa.beta_title")}
          contentPlaceholder={tolgee.t("general.type_placeholder")}
          openSupportModal={(value: boolean) => setOpen(value)}
        />
      </BaseModal>
    </div>
  );
}
