import { useEffect, useId, useRef } from "react";
import clsx from "clsx";

import type { ReactNode, KeyboardEvent, ChangeEvent } from "react";
import type { TextFieldProps } from "types/components";

import "components/Input/TextField/textFieldStyles.css";

type Props = {
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onClick?: (event: any) => void;
  className?: string;
  containerClassName?: string;
  textareaClassName?: string;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  testid?: string;
  placeholder?: string;
} & TextFieldProps;

export default function BaseTextField(props: Props) {
  const {
    disabled = false,
    fakeDisabled = false,
    shrink = false,
    required = false,
    boldLabel = false,
    multiline = false,
    grow = multiline || false,
    label = "",
    name,
    startAdornment,
    endAdornment,
    onChange,
    onClick,
    value,
    className = "",
    containerClassName = "",
    textareaClassName = className,
    testid = "",
    placeholder = "",
  } = props;

  const id = useId();
  const tr: any = useRef(null);

  useEffect(() => {
    if (grow) {
      tr.current.style.height = "auto";
      tr.current.style.height = `${tr.current.scrollHeight + 1}px`;
    }
  }, [grow, value]);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (!grow && e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div
      data-testid={testid}
      className={clsx(containerClassName, "input-container relative flex flex-col")}>
      <textarea
        id={id}
        ref={tr}
        name={name}
        value={value || ""}
        required={required}
        readOnly={!onChange}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        onChange={onChange}
        rows={multiline ? 4 : 1}
        disabled={disabled}
        className={clsx(
          textareaClassName,
          "rounded border border-solid border-gray-400 text-base px-3 py-4 shadow-none outline-none resize-none",
          { "whitespace-nowrap overflow-x-hidden": !grow, "overflow-hidden": grow },
          { "hover:border-gray-800": !disabled && !fakeDisabled },
          { "text-gray-500 caret-transparent": disabled || fakeDisabled },
          { "pr-10": endAdornment },
          { "cursor-pointer": onClick }
        )}
      />
      <label
        className={clsx(
          { "text-gray-400": disabled || fakeDisabled },
          {
            "filled text-prighterblue bg-white": value || shrink,
          },
          { "font-bold": boldLabel },
          "px-2 py-0 m-0 leading-3 text-gray-700 focus:text-prighterblue absolute pointer-events-none"
        )}
        htmlFor={id}>
        {label} {required && "*"}
      </label>
      {endAdornment && <div className="absolute right-3 top-3">{endAdornment}</div>}
      {startAdornment && <div className="absolute left-3 top-3">{startAdornment}</div>}
    </div>
  );
}
