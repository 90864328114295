import type { RopaEntityType } from "types/ropaTool";

export const infoDictionary = (
  type: RopaEntityType,
  id: string | number,
  businessId: string | number
): string => {
  const dict: { [key in RopaEntityType]: string } = {
    persons: `/businesses/${businessId}/persons/${id}`,
    processes: `/businesses/${businessId}/processes/${id}`,
    "data-categories": `/businesses/${businessId}/datacategories/${id}`,
    "affected-groups": `/businesses/${businessId}/affectedgroups/${id}`,
    controllers: `/businesses/${businessId}/controllers/${id}`,
    "internal-recipients": `/businesses/${businessId}/recipients/internal/${id}`,
    "external-recipients": `/businesses/${businessId}/recipients/external/${id}`,
    "processor-recipients": `/businesses/${businessId}/recipients/processor/${id}`,
  };
  if (type in dict) {
    return dict[type];
  }
  return "";
};

export const entityDictionary = (type: RopaEntityType, businessId: string | number): string => {
  const dict: { [key in RopaEntityType]: string } = {
    persons: `/businesses/${businessId}/persons`,
    processes: `/businesses/${businessId}/processes`,
    "data-categories": `/businesses/${businessId}/datacategories`,
    "affected-groups": `/businesses/${businessId}/affectedgroups`,
    controllers: `/businesses/${businessId}/controllers`,
    "internal-recipients": `/businesses/${businessId}/recipients/internal`,
    "external-recipients": `/businesses/${businessId}/recipients/external`,
    "processor-recipients": `/businesses/${businessId}/recipients/processor`,
  };
  if (type in dict) {
    return dict[type];
  }
  return "";
};
