type Props = {
  currentPage: number;
  rowsPerPage: number;
  count: number;
  pageSizeOptions?: number[];
  disableColumnMenu?: boolean;
  disableColumnFilter?: boolean;
  onRowClick?: () => void;
  rowSelection?: boolean;
};

export const dataGridConfig = (data: Props): any => {
  const {
    currentPage,
    rowsPerPage,
    count,
    pageSizeOptions,
    disableColumnMenu,
    disableColumnFilter,
    onRowClick,
    rowSelection,
  } = data;
  return {
    disableColumnFilter: disableColumnFilter || true,
    disableColumnMenu: disableColumnMenu || true,
    pageSizeOptions: pageSizeOptions || [25, 50, 100],
    paginationModel: { page: currentPage, pageSize: rowsPerPage },
    paginationMode: "server",
    rowCount: count,
    sortingMode: "server",
    onRowClick,
    rowSelection: rowSelection || false,
    sortingOrder: ["asc", "desc"],
    getRowHeight: () => "auto",
  };
};
