import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation/tolgee";

import type { CaseCheckOpenedEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function CaseCheckOpened(props: {
  entityData: CaseCheckOpenedEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="check" color="white" size="lg" />}
      title={entityData.title}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="p-4 ">
        <span>
          <b>
            {tolgee.t({
              key: "dsr.check_name",
            })}
          </b>
          {": "}
          {tolgee.t({
            key: `dsr.step_title.${entityData.name.toLowerCase()}`,
          })}
        </span>
      </div>
    </HistoryEntryTemplate>
  );
}
