import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import ManageBusinessHeader from "pages/Admin/ManageUser/ManageBusiness/Header";
import { api } from "services";
import { BaseTabs } from "components/Tabs/Tabs";
import ConfigTab from "pages/Admin/ManageUser/ManageBusiness/ConfigTab";
import SubscriptionsTab from "pages/Admin/ManageUser/ManageBusiness/SubscriptionsTab";
import RepresentativesTab from "pages/Admin/ManageUser/ManageBusiness/RepresentativesTab";
import RopaTab from "pages/Admin/ManageUser/ManageBusiness/RopaTab";
import LandingPageSettings from "pages/Client/LandingPage/View";
import tolgee from "services/translation";
import { handleError } from "services/api/error";
import FilesTab from "pages/Admin/ManageUser/ManageBusiness/FilesTab";

import type { Company } from "types/company";

export default function ManageBusiness() {
  const { businessId } = useParams();
  const [business, setBusiness] = useState<Company>({} as Company);
  const [reload, increaseReload] = useState(0);

  const handleReload = () => {
    increaseReload((prev) => prev + 1);
  };

  useEffect(() => {
    async function fetchBusiness() {
      let res;
      try {
        res = await api.business.get(businessId);
      } catch (e) {
        handleError(e);
        return;
      }
      setBusiness(res.data);
    }
    fetchBusiness();
  }, [businessId, reload]);

  const tabsData = [
    {
      id: 1,
      label: tolgee.t({ key: "manage_business.config" }),
      content: <ConfigTab />,
    },
    {
      id: 2,
      label: tolgee.t({ key: "manage_business.subscriptions" }),
      content: <SubscriptionsTab business={business} />,
    },
    {
      id: 3,
      label: tolgee.t({ key: "manage_business.representatives" }),
      content: <RepresentativesTab business={business} />,
    },
    {
      id: 4,
      label: tolgee.t({ key: "manage_business.ropas" }),
      content: <RopaTab business={business} />,
    },
    {
      id: 5,
      label: tolgee.t({ key: "manage_business.governance_portal" }),
      content: <LandingPageSettings />,
    },
    {
      id: 6,
      label: tolgee.t({ key: "manage_business.files" }),
      content: <FilesTab business={business} />,
    },
  ];

  return (
    <div>
      {business.public_id && (
        <>
          <ManageBusinessHeader business={business} handleReload={handleReload} />
          <div className="mt-4">
            <BaseTabs tabsData={tabsData} defaultTab={0} />
          </div>
        </>
      )}
    </div>
  );
}
