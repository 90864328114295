import { Button, MenuItem, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { faker } from "faker";

import { notify } from "store/app";
import { api } from "services";
import tolgee from "services/translation";
import ExtendedSelectField from "components/Input/ExtendedSelectField";
import { useAppSelector } from "store";
import { getCountryAsOptions } from "helpers";
import { DEV_ENV } from "const/env";
import { handleError } from "services/api/error";

const typeValues = ["LAW_FIRM", "PRIVACY_CONSULTANT", "IT_CONSULTANT", "MDD"];

const defaultValues = {
  type: faker.helpers.arrayElement(["LAW_FIRM", "PRIVACY_CONSULTANT", "IT_CONSULTANT", "MDD"]),
  country_iso: "",
  email: faker.internet.exampleEmail(),
  name: faker.lorem.words(2),
  account: faker.person.fullName(),
  street: faker.location.street(),
  code: faker.location.zipCode("#####"),
  city: faker.location.city(),
};

export default function NewPartner() {
  const [showBack, setShowBack] = useState(false);
  const [reload, setReload] = useState(0);
  const [countryOptions, setCountryOptions] = useState([]);
  const { countries } = useAppSelector((state) => state.app);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty },
    getValues,
  } = useForm({ values: defaultValues });

  useEffect(() => {
    if (countries) {
      const options = getCountryAsOptions(countries);
      setCountryOptions(options);
      if (countries.length > 0 && DEV_ENV && !getValues("country_iso")) {
        setValue(
          "country_iso",
          countries[
            faker.number.int({
              min: 0,
              max: countries.length - 1,
            })
          ].iso_3166_1_alpha_2,
          { shouldDirty: true }
        );
      }
    }
  }, [countries, getValues, setValue, showBack]);

  const dispatch = useDispatch();

  const type = watch("type");
  const country = watch("country_iso");
  const emailReg = register("email");
  const nameReg = register("name");
  const accountReg = register("account");
  const streetReg = register("street");
  const codeReg = register("code");
  const cityReg = register("city");

  const onSubmit = async (data: any) => {
    const partnerData = {
      email: data?.email,
      company_name: data?.name,
      name: data?.account,
      type: data?.type,
      uuid: "",
      address: {
        street: data?.street,
        city: data?.city,
        postal: data?.code,
        country_iso: data?.country_iso,
      },
    };

    try {
      await api.partner.create(partnerData);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "partner.partner_added",
        }),
        type: "SUCCESS",
      })
    );
    reset(defaultValues);
    setReload((prev) => prev + 1);
    setShowBack(true);
  };

  const handleBack = () => {
    window.location.reload();
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl" key={reload} data-testid="newpartner-page">
      <div className="box-outerlayout pb-4 pt-10 space-y-4">
        <h2>
          <T keyName="partner.add_new_partner" />
        </h2>
        <div className="italic pb-4">
          <T keyName="partner.reset_link" />
        </div>
        {showBack ? (
          <div className="w-full flex justify-end">
            <Button onClick={() => handleBack()}>
              <T keyName="generic.back" />
            </Button>
          </div>
        ) : (
          <>
            <TextField
              data-testid="email"
              label={<T keyName="partner.email" />}
              required
              onChange={emailReg.onChange}
              onBlur={emailReg.onBlur}
              name={emailReg.name}
              ref={emailReg.ref}
            />
            <TextField
              data-testid="company-name"
              label={<T keyName="partner.company_name" />}
              required
              onChange={nameReg.onChange}
              onBlur={nameReg.onBlur}
              name={nameReg.name}
              ref={nameReg.ref}
            />
            <TextField
              data-testid="account"
              label={<T keyName="partner.account" />}
              required
              onChange={accountReg.onChange}
              onBlur={accountReg.onBlur}
              name={accountReg.name}
              ref={accountReg.ref}
            />
            <TextField
              data-testid="street"
              label={<T keyName="partner.street" />}
              required
              onChange={streetReg.onChange}
              onBlur={streetReg.onBlur}
              name={streetReg.name}
              ref={streetReg.ref}
            />
            <TextField
              data-testid="postal"
              label={<T keyName="partner.postal" />}
              required
              onChange={codeReg.onChange}
              onBlur={codeReg.onBlur}
              name={codeReg.name}
              ref={codeReg.ref}
            />
            <TextField
              data-testid="city"
              label={<T keyName="partner.city" />}
              required
              onChange={cityReg.onChange}
              onBlur={cityReg.onBlur}
              name={cityReg.name}
              ref={cityReg.ref}
            />
            <div>
              <ExtendedSelectField
                data-testid="country"
                state={[country, (text: any) => setValue("country_iso", text)]}
                options={countryOptions}
                className="min-w-[12rem]"
                label={tolgee.t({
                  key: "generic.country",
                })}
                required
              />
            </div>
            <TextField
              data-testid="type"
              select
              className="w-full"
              id="select"
              value={type}
              onChange={(event: any) => {
                setValue("type", event.target?.value);
              }}
              label={tolgee.t({
                key: "partner.type",
              })}>
              {typeValues.map((i, idx) => (
                <MenuItem value={i} key={i} data-testid={idx}>
                  <T keyName={`partner.${i.toLowerCase()}`} />
                </MenuItem>
              ))}
            </TextField>

            <div className="w-full flex justify-end">
              <Button onClick={handleSubmit(onSubmit)} disabled={!isDirty} data-testid="submit">
                <T keyName="generic.submit" />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
