import { useCallback, useState } from "react";

import type {
  IgnoreFieldTypes,
  TableCells,
  TableCell_,
  TableConfig,
  TableRow_,
  TableSettings,
} from "types/components/enhancedTable";

function useEnhancedTable<KeyName extends string>(): [
  TableConfig<KeyName>,
  (arg0: KeyName[]) => void,
  (arg1: TableRow_<KeyName>[]) => void,
  (arg2: TableSettings<KeyName>) => void,
] {
  const [tableConfig, setTableConfig] = useState<TableConfig<KeyName>>({
    settings: { disableSort: false },
    keys: [],
    values: [],
  });

  const setSettings = useCallback((value: TableSettings<KeyName>) => {
    let fields = ["id"];
    if (value.ignoreFields) {
      fields = ["id", ...value.ignoreFields];
    }
    const shouldSort = "disableSort" in value ? value.disableSort : false;

    setTableConfig((prevState) => ({
      ...prevState,
      settings: {
        ...value,
        ignoreFields: [...(fields as IgnoreFieldTypes<KeyName>[])],
        shouldSort,
      },
    }));
  }, []);

  const setKeys = useCallback((keys: KeyName[]) => {
    setTableConfig((prevState) => ({ ...prevState, keys }));
  }, []);

  const setValues = useCallback((values: TableRow_<KeyName>[]) => {
    const serializedValues: TableRow_<KeyName>[] = values.map((value) => ({
      ...value,
      cells: Object.fromEntries(
        Object.entries(value.cells).map(([keyName, cell]: [KeyName, TableCell_]) => [
          keyName,
          {
            ...cell,
            value:
              cell.value === null || cell.value === undefined ? cell.displayedString : cell.value,
          },
        ])
      ) as TableCells<KeyName>,
    }));

    setTableConfig((prevState) => ({ ...prevState, values: serializedValues }));
  }, []);

  return [tableConfig, setKeys, setValues, setSettings];
}

export default useEnhancedTable;
