import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type {
  Permission,
  PermissionData,
  PermissionObject,
  PermissionType,
  PermissionV2,
  SecurityInfo,
} from "types/security";

export async function getSecurityInfo(): Promise<AxiosResponse<SecurityInfo>> {
  return api.get("/v1/security");
}

export async function removeDevice(device_id: string): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/security/mfa/revoke", null, {
    params: { device_uuid: device_id },
  });
}

export async function getBusinessPermissions(
  businessId: string
): Promise<AxiosResponse<Permission[]>> {
  return api.get("/v1/security/business-permissions", {
    params: { business_id: businessId },
  });
}

export async function addPermissions(
  businessId: string,
  data: PermissionData
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/security/permission", data, {
    params: { business_id: businessId },
  });
}

export async function deletePermissions(
  businessId: string,
  type_: PermissionType,
  email: string
): Promise<AxiosResponse<unknown>> {
  return api.delete("/v1/security/permission", {
    params: { business_id: businessId, type_, email },
  });
}

export async function getPermissions(
  permissionObject: PermissionObject,
  permissionMin: PermissionType
): Promise<AxiosResponse<PermissionV2[]>> {
  return api.get("/v1/security/permissions", {
    params: { target_entity: permissionObject, type_min: permissionMin },
  });
}
