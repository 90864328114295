import { T } from "@tolgee/react";

import Certificate from "pages/Client/RepPages/components/Certificate";
import Snippet from "pages/Client/RepPages/components/Snippet";

export default function SwissRep() {
  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="swissrep-page">
      <h1>
        <T keyName="swissrep.swissrep_title" />
      </h1>
      <div className="space-y-4">
        <Snippet type="swissrep" />
        <Certificate type="swissrep" />
      </div>
    </div>
  );
}
