import axios from "axios";

import { API_BASE_ROUTE } from "const/env";

const axiosClient = axios.create({
  withCredentials: true,
  baseURL: API_BASE_ROUTE,
  timeout: 30000,
  timeoutErrorMessage: "TIME_OUT",
});

export default axiosClient;
