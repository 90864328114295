import { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";

import type { ReactNode, SyntheticEvent } from "react";

interface TabData {
  id: number;
  label: any;
  content: ReactNode;
  isAvailable?: boolean;
  testId?: string;
}

interface Props {
  tabsData: TabData[];
  defaultTab?: number;
}

export function BaseTabs({ tabsData, defaultTab = 0 }: Props) {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          "& .MuiTab-root": {
            textTransform: "none",
            backgroundColor: "transparent",
            border: "1px solid transparent",
            borderRadius: "8px 8px 0 0",
            padding: "10px 20px",
            transition: "background-color 0.3s ease, border 0.3s ease",
            "&:not(.Mui-selected)": {
              backgroundColor: "transparent",
            },
            "&.Mui-selected": {
              backgroundColor: "white",
              color: "primary.main",
              borderBottom: "none",
              borderLeft: "1px solid #ddd",
              borderRight: "1px solid #ddd",
              borderTop: "1px solid #ddd",
              fontWeight: "bold",
            },
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.2)",
            },
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
        }}>
        {tabsData.map((tab) => (
          <Tab data-testid={tab.testId} key={tab.id} label={tab.label} />
        ))}
      </Tabs>
      {tabsData.map((tab, index) => (
        <TabPanel key={tab.id} value={activeTab} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
}

interface TabPanelProps {
  children?: ReactNode;
  value: number;
  index: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
}
