import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import { TextField } from "@mui/material";
import { Link, useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";

import { api } from "services";
import { handleError } from "services/api/error";

import type { BusinessConfig } from "types/clp";

export default function Info() {
  const { businessId } = useParams();
  const [configData, setConfigData] = useState<BusinessConfig>();

  const fetchConfigData = useCallback(async () => {
    let res;
    try {
      res = await api.clp.getConfig(businessId);
    } catch (e) {
      handleError(e);
      return;
    }
    setConfigData(res.data);
  }, [businessId]);

  useEffect(() => {
    fetchConfigData();
  }, [fetchConfigData]);

  return (
    <div className="box-outerlayout pb-6 pt-10" data-testid="info-section">
      <div className="flex flex-col md:flex-row md:space-x-6 items-center mb-4 md:px-6">
        <div className="mr-4 mb-6 md:mb-0">
          <FontAwesomeIcon size="xl" icon="exclamation-triangle" color="danger" />
        </div>
        <div>
          <div className="font-bold mt-6 mb-2">
            <T keyName="gdpr.eurep_the_fact" />
          </div>

          <div>
            <T keyName="gdpr.eurep_to_comply" />
            <a href="#policysnippets">
              <T keyName="gdpr.eurep_these_snippets" />
            </a>
            <div className="mt-4">
              <T keyName="gdpr.eurep_contact" />
              <Link to="/customer-support">
                {" "}
                <T keyName="gdpr.eurep_contact_us" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="font-bold mt-6">
        <T keyName="gdpr.please_verify" />:
      </div>
      <div className="mt-6 border rounded-lg py-6 px-4 bg-gray-50">
        <TextField
          label={<T keyName="gdpr.eurep_url_privacy" />}
          InputLabelProps={{ shrink: true }}
          className="w-full bg-white"
          value={configData?.privacy_policy_ext_url || ""}
          disabled
        />
        <div className="mt-4">
          <T keyName="gdpr.modify_url" />
          <Link to={`/business/${businessId}/profile`}>
            {" "}
            <T keyName="gdpr.click_here" />
          </Link>
          .
        </div>
      </div>
    </div>
  );
}
