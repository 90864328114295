import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FileDownloadWithIcon } from "components";
import { getBeautyDate, getFileIcon, getFileName } from "helpers";
import { generateUrl } from "helpers/case";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";

import type { FileAddedEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function FileAdded(props: {
  caseUuid: string;
  entityData: FileAddedEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt, caseUuid } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="file" color="white" size="lg" />}
      title={entityData.title}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="flex flex-col p-4 space-y-2">
        <div>
          {entityData.files.map((file: any) => (
            <FileDownloadWithIcon key={file.uuid} icon={getFileIcon(file.mime_type)}>
              <a key={file.uuid} href={generateUrl(caseUuid, file.uuid)}>
                {getFileName(file)}
              </a>
            </FileDownloadWithIcon>
          ))}
        </div>
      </div>
    </HistoryEntryTemplate>
  );
}
