import { useParams } from "react-router";
import { useState, useEffect, useCallback } from "react";
import { T } from "@tolgee/react";

import { Chip } from "components";
import { api } from "services";
import CompanyDetails from "pages/Client/Company/Components/CompanyDetails";
import CompanySubscriptions from "pages/Client/Company/Components/CompanySubscriptions";
import tolgee from "services/translation";
import { handleError } from "services/api/error";

import type { BusinessConfig } from "types/clp";
import type { Company } from "types/company";

export default function CompanyData() {
  const { businessId } = useParams();
  const [data, setData] = useState<Company>();
  const [configData, setConfigData] = useState<BusinessConfig>();

  const fetchData = useCallback(async () => {
    let res;
    try {
      res = await api.business.get(businessId);
    } catch (e) {
      handleError(e);
      return;
    }

    setData(res.data);
  }, [businessId]);

  const fetchConfigData = useCallback(async () => {
    let res;
    try {
      res = await api.clp.getConfig(businessId);
    } catch (e) {
      handleError(e);
      return;
    }

    setConfigData(res.data);
  }, [businessId]);

  useEffect(() => {
    fetchData();
    fetchConfigData();
  }, [fetchConfigData, fetchData]);

  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <div className="box-outerlayout mb-4">
        <div className="flex flex-col md:flex-row align-center">
          <h1>
            <T keyName="billing.company" /> {data?.company_name}
          </h1>
          {data?.demo && (
            <div className="mt-4 sm:ml-4">
              <Chip
                title={tolgee.t({
                  key: "billing.demo_account",
                })}
              />
            </div>
          )}
        </div>
        {data && (
          <CompanyDetails
            onAddressUpdate={() => fetchData()}
            onLinkUpdate={() => fetchConfigData()}
            companyData={data}
            configData={configData}
          />
        )}
      </div>
      <div className="box-outerlayout">
        <h2>
          <T keyName="billing.subscriptions_assigned" />
        </h2>
        {data && <CompanySubscriptions companyData={data} />}
      </div>
    </div>
  );
}
