import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";

import { BaseSelectField } from "components";
import { selectStyle } from "pages/Client/EmailCases/Pages/styling";
import tolgee from "services/translation";

import type { CollectionTypeEnum } from "types/case";
import type { CcpaForm } from "types/dsr/ccpa";

export default function EmailCasesFormWhere() {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CcpaForm>();

  const type = watch("type");

  return (
    <div className="flex flex-col  rounded-[24px] gap-6 border border-prighterblue p-6">
      <div className="flex  justify-between gap-3">
        <h2 className="m-0 text-black font-fire text-[28px] font-normal">
          <T keyName="email_intake.data_subject_requested_from_california" />
        </h2>
        <FontAwesomeIcon icon="question-circle" className="text-prighterblue" />
      </div>
      <FormControl fullWidth className="flex flex-col gap-3">
        <BaseSelectField
          error={!!errors?.type}
          required
          sx={selectStyle}
          state={[type, (val: CollectionTypeEnum) => setValue("type", val)]}
          options={{
            DSR_CCPA: tolgee.t("generic.yes"),
            AUTHORITY: tolgee.t("generic.no"),
          }}
        />
      </FormControl>
    </div>
  );
}
