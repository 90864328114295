import { Button, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { api } from "services";
import { handleError } from "services/api/error";
import { useCase } from "pages/Client/Case/CaseContext";
import { finalizeCheck, reopenCheck } from "store/thunks";
import tolgee from "services/translation";
import { DocumentSignatureCheckNameEnum } from "types/case";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  // @ts-expect-error Must be like this
  import.meta.url
).toString();

export default function DocClientReviewed() {
  const [state, dispatch] = useCase();
  const caseData = state.case;
  const [signedLoaDocument, setSignedLoaDocument] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSignedLoaDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await api.file.getFileDocSigned(caseData.uuid, "NIS_EU_LOA");
      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setSignedLoaDocument(url);
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  }, [caseData.uuid]);

  useEffect(() => {
    fetchSignedLoaDocument();
  }, [fetchSignedLoaDocument]);

  const handleConfirm = async () => {
    await finalizeCheck()(dispatch, state.case.uuid, state.ui.check);
  };

  const handleNewSignature = () => {
    reopenCheck()(dispatch, caseData.uuid, DocumentSignatureCheckNameEnum.DOC_SIGNED);
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <h2 className="text-2xl font-bold text-gray-800 mb-10">{tolgee.t("documentSign.title")}</h2>
      <div className="box-outerlayout">
        <div className="flex flex-col gap-6 p-4">
          <div>{tolgee.t("documentSign.explanation")}</div>
          {isLoading && (
            <div className="flex justify-center items-center h-[900px] border-2 border-gray-300 rounded-md">
              <CircularProgress />
            </div>
          )}

          {!isLoading && (
            <div className="flex justify-center items-center border-2 border-gray-300 rounded-md h-[900px]">
              <Document
                file={signedLoaDocument}
                loading={
                  <div className="flex justify-center items-center h-[900px]">
                    <CircularProgress />
                  </div>
                }>
                <Page pageNumber={1} />
              </Document>
            </div>
          )}

          <div className="flex items-center gap-2 justify-center">
            <Button onClick={() => handleNewSignature()}>
              {tolgee.t("documentSign.tryAgain")}
            </Button>
            <Button onClick={() => handleConfirm()}>{tolgee.t("documentSign.confirm")}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
