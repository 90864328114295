import { Button, Checkbox, IconButton, List, ListItem, ListItemText, Select } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

import tolgee from "services/translation";
import { BaseModal, EditableText } from "components";
import { useCase } from "pages/Client/Case/CaseContext";
import { finalizeCheck, reopenCheck } from "store/thunks";
import { generateI18nKey, isCheckFinished } from "helpers/case";
import { api } from "services";
import DeclineWithPreviewButton from "pages/Client/Case/DSR/ClientView/DeclineWithPreviewButton";
import { notify } from "store/app";
import { ExemptionGroup } from "types/dsr/main";
import { type ExemptionItem } from "types/dsr/main";
import TolgeeWrapper from "pages/Client/Dashboard/Components/TolgeeWrapper";
import { handleError } from "services/api/error";
import { HandlingCheckNameEnum } from "types/case";

import type { DeclineTypeEnum } from "types/case";

export default function ExcludeData() {
  const [state, dispatch] = useCase();
  const appDispatch = useDispatch();

  const caseData = state.case;
  const caseClosed = caseData.state === "CLOSED";
  const isFinished = isCheckFinished(state);

  const [openExemptionsModal, setOpenExemptionsModal] = useState(false);
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
  const [showOptionalExemptions, setShowOptionalExemptions] = useState(false);

  const [exemptionList, setExemptionList] = useState<ExemptionItem[]>([]);
  const [selectedExemptions, setSelectedExemptions] = useState<ExemptionItem[]>([]);

  const [exemptionCategoriesList, setExemptionCategoriesList] = useState<ExemptionItem[]>([]);
  const [selectedExemptionCategories, setSelectedExemptionCategories] = useState<ExemptionItem[]>(
    []
  );

  const loadSelectedExemptions = useCallback(
    (exemptionsList: ExemptionItem[]) => {
      const filteredExemptions = exemptionsList
        .filter((filterItem: ExemptionItem) =>
          caseData.outcome.exemptions.some((selected) => selected.name === filterItem.key)
        )
        .map((mapItem: ExemptionItem) => {
          const matchingExemption = caseData.outcome.exemptions.find(
            (selected) => selected.name === mapItem.key
          );

          return {
            ...mapItem,
            comment: matchingExemption ? matchingExemption.comment : "",
          };
        });

      setSelectedExemptions(filteredExemptions);
    },
    [caseData.outcome.exemptions]
  );

  const loadSelectedExemptionsCategories = useCallback(
    (categoriesList: ExemptionItem[]) => {
      const filteredCategories = categoriesList.filter((filterItem: ExemptionItem) =>
        caseData.outcome.exemption_categories.some((selected) => selected === filterItem.key)
      );
      setSelectedExemptionCategories(filteredCategories);
    },
    [caseData.outcome.exemption_categories]
  );

  const generateExemptionGroups = useCallback(() => {
    let groups = [] as Array<ExemptionGroup>;
    switch (caseData.type) {
      case "DSR_CCPA_DELETE":
        groups = [
          ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_DELETE,
          ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_DELETE,
        ];
        break;
      case "DSR_CCPA_ACCESS":
        groups = [
          ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_ACCESS,
          ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_ACCESS,
        ];
        break;
      case "DSR_CCPA_ACCESS_CATEGORIES":
        groups = [
          ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_ACCESS_CATEGORIES,
          ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_ACCESS_CATEGORIES,
        ];
        break;
      case "DSR_CCPA_CORRECT":
        groups = [
          ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_CORRECT,
          ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_CORRECT,
        ];
        break;
      case "DSR_CCPA_OPTOUT":
        groups = [
          ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_OPTOUT,
          ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_OPTOUT,
        ];
        break;
      case "DSR_CCPA_LIMIT":
        groups = [
          ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_LIMIT,
          ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_LIMIT,
        ];
        break;
      default:
        return [];
    }
    return groups;
  }, [caseData.type]);

  const fetchExemptions = useCallback(async () => {
    try {
      const groups = generateExemptionGroups();
      try {
        const res = await api.dsr.getExemptionsList(groups);
        const list = res.data.map((item: ExemptionItem) => {
          return {
            ...item,
            comment: "",
          };
        });

        setExemptionList(list);
        loadSelectedExemptions(list);
      } catch (e) {
        handleError(e);
      }
    } catch {
      appDispatch(
        notify({
          message: tolgee.t({
            key: "generic.smth_went_wrong",
          }),
          type: "ERROR",
        })
      );
    }
  }, [setExemptionList, loadSelectedExemptions, generateExemptionGroups, appDispatch]);

  const fetchCategoriesOfPersonalInformation = useCallback(async () => {
    try {
      const res = await api.dsr.getExemptionsList([ExemptionGroup.CCPA_PERSONAL_INFORMATION]);
      setExemptionCategoriesList(res.data);
      loadSelectedExemptionsCategories(res.data);
    } catch (e) {
      handleError(e);
    }
  }, [setExemptionCategoriesList, loadSelectedExemptionsCategories]);

  useEffect(() => {
    fetchExemptions();
    fetchCategoriesOfPersonalInformation();
  }, [fetchExemptions, fetchCategoriesOfPersonalInformation]);

  const putExemptionsToBackend = async (exemptionsForBackend: ExemptionItem[]) => {
    const preparedExemptions = exemptionsForBackend.map((checkedItem: ExemptionItem) => {
      return {
        name: checkedItem.key,
        comment: checkedItem.comment,
      };
    });
    try {
      await api.dsr.putExemptions(caseData.uuid, preparedExemptions);
    } catch (e) {
      handleError(e);
    }
  };

  const handleSubmitExemptions = async (shouldFinalize: boolean) => {
    putExemptionsToBackend(selectedExemptions);

    if (shouldFinalize) {
      finalizeCheck()(dispatch, state.case.uuid, state.ui.check);
      if (caseData.type === "DSR_CCPA_ACCESS" || caseData.type === "DSR_CCPA_DELETE") {
        finalizeCheck()(dispatch, state.case.uuid, HandlingCheckNameEnum.EXCLUDE_DATA);
      }

      if (caseData.type === "DSR_CCPA_DELETE") {
        finalizeCheck()(
          dispatch,
          state.case.uuid,
          HandlingCheckNameEnum.DELETE_COMPLY,
          "DELETE_FULLY_COMPLIED"
        );
      } else if (caseData.type === "DSR_CCPA_LIMIT") {
        finalizeCheck()(
          dispatch,
          state.case.uuid,
          HandlingCheckNameEnum.LIMIT_COMPLY,
          "LIMIT_FULLY_COMPLIED"
        );
      } else if (caseData.type === "DSR_CCPA_OPTOUT") {
        finalizeCheck()(
          dispatch,
          state.case.uuid,
          HandlingCheckNameEnum.OPTOUT_COMPLY,
          "OPTOUT_FULLY_COMPLIED"
        );
      } else if (caseData.type === "DSR_CCPA_ACCESS_CATEGORIES") {
        finalizeCheck()(
          dispatch,
          state.case.uuid,
          HandlingCheckNameEnum.ACCESS_CATEGORIES_COMPLY,
          "ACCESS_CATEGORIES_FULLY_COMPLIED"
        );
      } else {
        finalizeCheck()(
          dispatch,
          state.case.uuid,
          HandlingCheckNameEnum.ACCESS_COMPLY,
          "ACCESS_FULLY_COMPLIED"
        );
      }
    }
    setOpenExemptionsModal(false);
  };

  const handleSubmitExemptionCategories = async (sendCategories?: boolean) => {
    if (sendCategories) {
      const prepareCategories = selectedExemptionCategories.map((item) => item.key);
      try {
        await api.dsr.putExemptionCategories(caseData.uuid, prepareCategories);
      } catch (e) {
        handleError(e);
        return;
      }
    }

    finalizeCheck()(dispatch, state.case.uuid, state.ui.check);
    if (caseData.type === "DSR_CCPA_ACCESS" || caseData.type === "DSR_CCPA_DELETE") {
      finalizeCheck()(dispatch, state.case.uuid, HandlingCheckNameEnum.EXCLUDE_DATA);
    }

    if (caseData.type === "DSR_CCPA_DELETE") {
      finalizeCheck()(
        dispatch,
        state.case.uuid,
        HandlingCheckNameEnum.DELETE_COMPLY,
        "DELETE_PARTIALLY_COMPLIED"
      );
    } else if (caseData.type === "DSR_CCPA_LIMIT") {
      finalizeCheck()(
        dispatch,
        state.case.uuid,
        HandlingCheckNameEnum.LIMIT_COMPLY,
        "LIMIT_PARTIALLY_COMPLIED"
      );
    } else if (caseData.type === "DSR_CCPA_OPTOUT") {
      finalizeCheck()(
        dispatch,
        state.case.uuid,
        HandlingCheckNameEnum.OPTOUT_COMPLY,
        "OPTOUT_PARTIALLY_COMPLIED"
      );
    } else if (caseData.type === "DSR_CCPA_ACCESS_CATEGORIES") {
      finalizeCheck()(
        dispatch,
        state.case.uuid,
        HandlingCheckNameEnum.ACCESS_CATEGORIES_COMPLY,
        "ACCESS_CATEGORIES_PARTIALLY_COMPLIED"
      );
    } else {
      finalizeCheck()(
        dispatch,
        state.case.uuid,
        HandlingCheckNameEnum.ACCESS_COMPLY,
        "ACCESS_PARTIALLY_COMPLIED"
      );
    }

    setOpenCategoriesModal(false);
  };

  const generateDeclineType = () => {
    let type = "";
    if (caseData.type.includes("LIMIT")) {
      type = "LIMIT";
    } else if (caseData.type.includes("ACCESS")) {
      type = "ACCESS";
    } else if (caseData.type.includes("OPTOUT")) {
      type = "OPTOUT";
    } else {
      type = "DELETE";
    }

    const value = `${type}_NOT_COMPLIED` as DeclineTypeEnum;
    return value;
  };

  const handleExemptionCheck = (item: ExemptionItem) => async () => {
    const currentIndex = selectedExemptions.findIndex((exemption) => exemption.key === item.key);
    const newChecked = [...selectedExemptions];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    putExemptionsToBackend(newChecked);
    setSelectedExemptions(newChecked);
  };

  const updateExemptionComment = (key: string, value: string) => {
    setSelectedExemptions((prev) =>
      prev.map((selectedExemption) =>
        selectedExemption.key === key ? { ...selectedExemption, comment: value } : selectedExemption
      )
    );
  };

  const handleExemptionCategoryCheck = (item: ExemptionItem) => async () => {
    const currentIndex = selectedExemptionCategories.findIndex(
      (exemption: ExemptionItem) => exemption.key === item.key
    );
    const newChecked = [...selectedExemptionCategories];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedExemptionCategories(newChecked);
  };

  const isSuccessButtonDisabled = () => {
    if (isFinished) {
      return true;
    }
    if (selectedExemptions.length > 0) {
      return true;
    }
    return false;
  };

  const isPartiallyComplyButtonDisabled = () => {
    if (isFinished) {
      return true;
    }
    if (selectedExemptions.length > 0) {
      return false;
    }
    return true;
  };

  const handlePartiallyComply = () => {
    if (caseData.type === "DSR_CCPA_ACCESS_CATEGORIES") {
      handleSubmitExemptionCategories(false);
    } else {
      setOpenCategoriesModal(true);
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="self-end">
        <Button
          data-testid="dsr_confirmation_button"
          color="success"
          onClick={() => {
            setShowOptionalExemptions(false);
            setOpenExemptionsModal(true);
          }}
          disabled={isSuccessButtonDisabled()}>
          {tolgee.t({
            key: generateI18nKey(caseData.type, state.ui.check, "confirm"),
          })}
        </Button>
      </div>
      <div className="mt-4">
        {tolgee.t({
          key: generateI18nKey(caseData.type, state.ui.check, "description"),
        })}
      </div>
      <div>
        <h2 className="m-0">
          {tolgee.t({
            key: generateI18nKey(caseData.type, state.ui.check, "title"),
          })}
        </h2>
        <h3 className="my-1">
          {tolgee.t({
            key: generateI18nKey(caseData.type, state.ui.check, "subtitle"),
          })}
        </h3>
        {tolgee.t({
          key: generateI18nKey(caseData.type, state.ui.check, "explanation"),
        })}
      </div>
      <Select
        disabled={isFinished}
        open={false}
        onOpen={() => {
          setShowOptionalExemptions(true);
          setOpenExemptionsModal(true);
        }}
        displayEmpty
        MenuProps={{
          sx: { width: "fit-content", maxHeight: "50vh" },
        }}
        renderValue={() => (
          <em>
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "select_placeholder"),
            })}
          </em>
        )}
        value=""
      />
      <div className="flex flex-row flex-wrap gap-4">
        {selectedExemptions.map((item) => (
          <div key={item.key}>
            <div className="bg-[#f1f5f9] p-2 rounded-md rounded-bl-none flex items-center justify-between">
              <TolgeeWrapper translateKey={item.key} />
              <IconButton disabled={isFinished} onClick={handleExemptionCheck(item)}>
                <FontAwesomeIcon icon="circle-xmark" size="sm" />
              </IconButton>
            </div>
            <div className="bg-[#f8fafc] mr-2 p-1 pl-2 flex flex-row items-center">
              <b>{tolgee.t("general.comment")}:</b>
              <EditableText
                disabled={isFinished}
                value={item.comment}
                confirmation={false}
                onChange={(value) => updateExemptionComment(item.key, value)}
                parentSubmit={() => handleSubmitExemptions(false)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-between">
        <div />
        <div className="flex flex-row self-end space-x-2">
          {isFinished && !caseClosed && (
            <Button
              onClick={() => {
                reopenCheck()(dispatch, caseData.uuid, state.ui.check);
                if (caseData.type === "DSR_CCPA_ACCESS" || caseData.type === "DSR_CCPA_DELETE") {
                  reopenCheck()(dispatch, state.case.uuid, HandlingCheckNameEnum.EXCLUDE_DATA);
                }
                if (caseData.type === "DSR_CCPA_DELETE") {
                  reopenCheck()(dispatch, caseData.uuid, HandlingCheckNameEnum.DELETE_COMPLY);
                } else if (caseData.type === "DSR_CCPA_LIMIT") {
                  reopenCheck()(dispatch, caseData.uuid, HandlingCheckNameEnum.LIMIT_COMPLY);
                } else if (caseData.type === "DSR_CCPA_OPTOUT") {
                  reopenCheck()(dispatch, caseData.uuid, HandlingCheckNameEnum.OPTOUT_COMPLY);
                } else if (caseData.type === "DSR_CCPA_ACCESS_CATEGORIES") {
                  reopenCheck()(
                    dispatch,
                    caseData.uuid,
                    HandlingCheckNameEnum.ACCESS_CATEGORIES_COMPLY
                  );
                } else {
                  reopenCheck()(dispatch, caseData.uuid, HandlingCheckNameEnum.ACCESS_COMPLY);
                }
              }}
              variant="outlined"
              endIcon={<FontAwesomeIcon icon="lock-open" className="!text-sm" />}>
              {tolgee.t({
                key: "general.reopen",
              })}
            </Button>
          )}

          <div className="space-x-4">
            <DeclineWithPreviewButton
              label={tolgee.t({
                key: generateI18nKey(caseData.type, state.ui.check, "decline_open_exclude_data"),
              })}
              labelSubmit={tolgee.t({
                key: generateI18nKey(caseData.type, state.ui.check, "decline_submit_exclude_data"),
              })}
              disabled={isPartiallyComplyButtonDisabled()}
              declineType={generateDeclineType()}
            />
            <Button
              onClick={() => handlePartiallyComply()}
              disabled={isPartiallyComplyButtonDisabled()}>
              {tolgee.t({
                key: generateI18nKey(caseData.type, state.ui.check, "partially_comply"),
              })}
            </Button>
          </div>
        </div>
      </div>
      <BaseModal modalState={[openExemptionsModal, setOpenExemptionsModal]}>
        <div data-testid="excludeDataModal" className="flex flex-col">
          <h2 className="text-prighterdark">
            {showOptionalExemptions ? (
              <span>
                {tolgee.t({
                  key: generateI18nKey(caseData.type, state.ui.check, "modal_title"),
                })}
              </span>
            ) : (
              <span>
                {tolgee.t({
                  key: generateI18nKey(caseData.type, state.ui.check, "modal_obligatory"),
                })}
              </span>
            )}
          </h2>
          <div>
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "modal_description"),
            })}
          </div>

          <div className="space-y-4 pt-4">
            <div className="bg-danger-400/10 border-danger-700 border-2 rounded-lg">
              <List className={!showOptionalExemptions ? "pointer-events-none" : ""}>
                {exemptionList
                  .filter((item) => item.group.includes("OBLIGATION"))
                  .map((item, index) => (
                    <ListItem key={index} className="flex flex-row gap-4">
                      <ListItemText>
                        <TolgeeWrapper translateKey={item.key} />
                      </ListItemText>
                      {showOptionalExemptions ? (
                        <Checkbox
                          edge="start"
                          checked={selectedExemptions.some(
                            (exemption) => exemption.key === item.key
                          )}
                          onClick={handleExemptionCheck(item)}
                        />
                      ) : (
                        ""
                      )}
                    </ListItem>
                  ))}
              </List>
            </div>
            {showOptionalExemptions ? (
              <div className="bg-gray-400/10 border-gray-700 border-2 rounded-lg">
                <List>
                  {exemptionList
                    .filter((item) => item.group.includes("OPTIONAL"))
                    .map((item, index) => (
                      <ListItem key={index} className="flex flex-row gap-4">
                        <ListItemText>
                          <TolgeeWrapper translateKey={item.key} />
                        </ListItemText>
                        <Checkbox
                          edge="start"
                          checked={selectedExemptions.some(
                            (exemption) => exemption.key === item.key
                          )}
                          onClick={handleExemptionCheck(item)}
                        />
                      </ListItem>
                    ))}
                </List>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="flex mt-4 self-end">
            {showOptionalExemptions ? (
              <Button
                data-testid="excludeDataModal_confirmReasons"
                onClick={() => handleSubmitExemptions(false)}>
                {tolgee.t({
                  key: generateI18nKey(caseData.type, state.ui.check, "confirm_reasons"),
                })}
              </Button>
            ) : (
              <Button
                data-testid="excludeDataModal_confirm"
                onClick={() => handleSubmitExemptions(true)}>
                {tolgee.t({
                  key: generateI18nKey(caseData.type, state.ui.check, "confirm"),
                })}
              </Button>
            )}
          </div>
        </div>
      </BaseModal>

      <BaseModal modalState={[openCategoriesModal, setOpenCategoriesModal]}>
        <div className="flex flex-col w-[64rem]">
          <h2 className="text-prighterdark my-2">
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "exemption_category.modal_title"),
            })}
          </h2>
          <h3 className="text-prighterdark m-0">
            {tolgee.t({
              key: generateI18nKey(
                caseData.type,
                state.ui.check,
                "exemption_category.modal_subtitle"
              ),
            })}
          </h3>
          {tolgee.t({
            key: generateI18nKey(
              caseData.type,
              state.ui.check,
              "exemption_category.modal_description"
            ),
          })}
          <div className="bg-gray-400/10 border-gray-700 border-2 rounded-lg">
            <List>
              {exemptionCategoriesList.map((item, index) => (
                <ListItem key={index} className="flex flex-row gap-4">
                  <ListItemText>
                    <TolgeeWrapper translateKey={item.key} />
                  </ListItemText>
                  <Checkbox
                    edge="start"
                    checked={selectedExemptionCategories.some(
                      (exemption: ExemptionItem) => exemption.key === item.key
                    )}
                    onClick={handleExemptionCategoryCheck(item)}
                  />
                </ListItem>
              ))}
            </List>
          </div>
          <div className="flex mt-4 self-end">
            <Button
              disabled={selectedExemptionCategories.length === 0}
              onClick={() => handleSubmitExemptionCategories(true)}>
              {tolgee.t({
                key: generateI18nKey(caseData.type, state.ui.check, "partially_comply"),
              })}
            </Button>
          </div>
        </div>
      </BaseModal>
    </div>
  );
}
