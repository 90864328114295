import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";

import type { InternalCommentEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function InternalComment(props: {
  entityData: InternalCommentEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="comment" color="white" size="lg" />}
      title={<b>{entityData.title}</b>}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      {entityData?.subject && <div className="p-4">{entityData.subject}</div>}
      <div className="px-4 pb-2 prose">
        <ReactMarkdown>{entityData?.text}</ReactMarkdown>
      </div>
    </HistoryEntryTemplate>
  );
}
