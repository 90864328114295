import { Button, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import { Link, useParams } from "react-router";
import { useState } from "react";
import SaveIcon from "@mui/icons-material/Save";

import tolgee from "services/translation";
import { landingPageUrl } from "const";
import { REACT_BASE_ROUTE } from "const/env";

interface Props {
  customSlug: string;
  handleUpdateSettings: () => void;
}

export default function CustomizeCompanyDetails({ customSlug, handleUpdateSettings }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext();
  const { businessId } = useParams();

  const [showSlugInput, setShowSlugInput] = useState(false);

  return (
    <div className="p-2 w-full">
      <div className="mb-8">
        <Stack spacing={2}>
          <div>{tolgee.t("clp.clp_reachable")}:</div>
          <span>
            <Link to={landingPageUrl(businessId)}>{landingPageUrl(businessId)}</Link>
          </span>
          {customSlug && (
            <span>
              <a href={`/portal/${customSlug}`}>{`${REACT_BASE_ROUTE}/portal/${customSlug}`}</a>{" "}
              (Landing page with slug)
            </span>
          )}
          <div className="self-baseline">
            <Button
              onClick={() => setShowSlugInput(!showSlugInput)}
              startIcon={<FontAwesomeIcon size="lg" icon="edit" />}>
              {tolgee.t("clp.customize_url")}
            </Button>
          </div>
          {showSlugInput && (
            <TextField
              {...register("slug", {
                minLength: 4,
                maxLength: 64,
                validate: {
                  slugRestrictions: (value: string) => {
                    if (!/^[a-zA-Z]/.test(value) || !/^[-_\w]{3,}$/.test(value)) {
                      return false;
                    }
                    if (/[-_]{2,}/.test(value)) {
                      return false;
                    }
                    return !(value.endsWith("-") || value.endsWith("_"));
                  },
                },
              })}
              error={!!errors?.slug}
              helperText={errors?.slug ? tolgee.t({ key: "clp.custom_slug_error_message" }) : ""}
              label={tolgee.t({ key: "clp.custom_slug" })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={async () => {
                        await handleSubmit(handleUpdateSettings)();
                        if (!errors?.slug) {
                          setShowSlugInput(false);
                        }
                      }}
                      size="large">
                      <SaveIcon className="text-prighterblue" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Stack>
      </div>

      <div className="mb-8">
        <Stack spacing={2}>
          <div>
            <div className="font-bold">{tolgee.t("clp.change_name_heading")}:</div>
            {tolgee.t("clp.change_name")}:
          </div>
          <TextField
            {...register("trade_name")}
            InputLabelProps={{ shrink: true }}
            id="standard-basic"
            label={tolgee.t({ key: "generic.name" })}
          />
        </Stack>
      </div>

      <div className="mb-8">
        <Stack spacing={2}>
          <div>
            <div className="font-bold">{tolgee.t("clp.extra_text_heading")}:</div>
            {tolgee.t("clp.extra_text_clp")}:
          </div>
          <TextField
            {...register("clp_text", {
              validate: (e: string) =>
                !e || e.length > 2 || tolgee.t({ key: "clp.clp_text.error" }),
            })}
            error={!!errors?.clp_text}
            helperText={(errors?.clp_text?.message || "") as string}
            className="w-full"
            InputLabelProps={{ shrink: true }}
            label={tolgee.t({ key: "clp.customizable_text" })}
          />
        </Stack>
      </div>

      <div className="flex justify-end">
        <Button onClick={handleSubmit(handleUpdateSettings)}>{tolgee.t("generic.save")}</Button>
      </div>
    </div>
  );
}
