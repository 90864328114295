import type { ReactNode } from "react";
import type { ContactAddress, ContactPoint } from "types/contactDirectory";

export function stringifyContactName(name: string, salutation: string): string {
  if (!name) {
    return "No Name";
  }

  if (salutation) {
    return `${salutation}. ${name}`;
  }

  return name;
}

export function stringifyContactNameAndCompany(
  personName: string,
  orgName: string,
  personSalutation: string
): string {
  if (!personName && !orgName) {
    return "No Information";
  }

  if (!personName) {
    return orgName;
  }

  const contactName = stringifyContactName(personName, personSalutation);
  if (!orgName) {
    return contactName;
  }

  return `${contactName}, ${orgName}`;
}

export function stringifyContactNameAndMail(
  name: string,
  email: string,
  salutation: string
): string {
  if (!name && !email) {
    return "No Information";
  }

  if (!name) {
    return email;
  }
  const contactName = stringifyContactName(name, salutation);
  if (!email) {
    return contactName;
  }

  return `${contactName}, ${email}`;
}

export function sanitizeAddresses(addresses: ContactAddress[]): ContactAddress[] {
  return addresses.filter((item) => item.city || item.street || item.country_iso || item.postal);
}

export function sanitizeContactPoints(contactPoints: ContactPoint[]): ContactPoint[] {
  return contactPoints.filter((cp) => cp.value);
}

export function stringifyBehalfOfContactName(
  senderName: string,
  dSName: string
): string | ReactNode {
  if (!senderName && !dSName) {
    return "No Name";
  }

  if (!senderName || !dSName) {
    return senderName || dSName;
  }

  return (
    <span>
      <b>{senderName}</b> on behalf of <b>{dSName}</b>
    </span>
  );
}
