import Modal from "@mui/material/Modal";

import type { CSSProperties, ReactNode } from "react";
import type { UseState } from "types";

const divStyle: CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fafafa",
  boxShadow: "24px",
  border: "2px solid gray",
  padding: "2rem",
};

type Props = {
  modalState: UseState<boolean>;
  children: ReactNode;
  className?: string;
  closeEvent?: () => unknown;
};

export default function BaseModal(props: Props) {
  const { modalState, children, className, closeEvent = () => {} } = props;
  const [open, setOpen] = modalState;

  const handleClose = () => {
    closeEvent();
    setOpen(false);
  };

  return (
    <Modal open={open} className="backdrop-blur-sm" onClose={handleClose} disableEnforceFocus>
      <div
        className={`${className} rounded-[1rem] overflow-auto max-h-full`}
        style={{ ...divStyle }}>
        {children}
      </div>
    </Modal>
  );
}
