import type { TableRow_ } from "types/components/enhancedTable";

export function descendingComparator<KeyName extends string>(
  a: TableRow_<KeyName>,
  b: TableRow_<KeyName>,
  orderBy: KeyName
): number {
  if (b.cells[orderBy].value < a.cells[orderBy].value) {
    return -1;
  }
  if (b.cells[orderBy].value > a.cells[orderBy].value) {
    return 1;
  }
  return 0;
}

export function getComparator<KeyName extends string>(
  order: "asc" | "desc",
  orderBy: KeyName
): (a: TableRow_<KeyName>, b: TableRow_<KeyName>) => number {
  return order === "desc"
    ? (a: TableRow_<KeyName>, b: TableRow_<KeyName>): number => descendingComparator(a, b, orderBy)
    : (a: TableRow_<KeyName>, b: TableRow_<KeyName>): number =>
        -descendingComparator(a, b, orderBy);
}

export function stableSort<KeyName extends string>(
  array: TableRow_<KeyName>[],
  comparator: (a: TableRow_<KeyName>, b: TableRow_<KeyName>) => number
): TableRow_<KeyName>[] {
  const stabilizedThis: [TableRow_<KeyName>, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
