import { Tooltip, List, ListItem, ListItemText, Divider, Button, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { Link, useParams, useSearchParams } from "react-router";

import { BaseModal, EditableText } from "components";
import { stringifyCompanyAddress } from "helpers/company";
import { PRIGHTER_BLUE } from "const/color";
import CompanyAddressModal from "pages/Client/Company/Components/CompanyAddressModal";
import tolgee from "services/translation";
import PrivacyPolicyModal from "pages/Client/Company/Components/PrivacyPolicyModal";
import { useAppDispatch } from "store";
import { fetchUserData, fetchMenuData } from "store/thunks";

import type { BusinessConfig } from "types/clp";
import type { Company } from "types/company";

type Props = {
  companyData: Company;
  configData: BusinessConfig;
  onAddressUpdate: () => unknown;
  onLinkUpdate: () => unknown;
};

const listItemPadding = "py-1 pl-2 pr-0.5";
const tooltipText = tolgee.t({
  key: "billing.company_profile_tooltip",
});

export default function CompanyDetails({
  companyData,
  configData,
  onAddressUpdate,
  onLinkUpdate,
}: Readonly<Props>) {
  const { businessId } = useParams();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [closeModal, setCloseModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const handleLinkUpdate = () => {
    onLinkUpdate();
    dispatch(fetchUserData());
    dispatch(fetchMenuData());
    setCloseModal(true);
    setShowPrivacyModal(false);
  };

  useEffect(() => {
    if (searchParams.get("privacyPolicy") === "open") {
      setShowPrivacyModal(true);
    }
  }, [searchParams]);

  return (
    <div>
      <div className="hidden sm:block">
        <div className="flex flex-col">
          <List>
            <ListItem>
              <ListItemText sx={{ width: "50%" }}>
                <T keyName="billing.company_name" />
              </ListItemText>
              <ListItemText className={listItemPadding} sx={{ width: "50%" }}>
                {companyData?.company_name}
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText sx={{ width: "50%" }}>
                <T keyName="billing.company_address" />
              </ListItemText>
              <ListItemText sx={{ width: "50%" }}>
                <EditableText
                  value={stringifyCompanyAddress(companyData)}
                  modalState={[closeModal, setCloseModal]}>
                  <CompanyAddressModal
                    onAddressUpdate={onAddressUpdate}
                    address={companyData.address}
                  />
                </EditableText>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText sx={{ width: "50%" }}>
                <T keyName="billing.prighter_id" />
              </ListItemText>
              <ListItemText className={listItemPadding} sx={{ width: "50%" }}>
                {companyData?.public_id}
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText sx={{ width: "50%" }}>
                <T keyName="billing.compliance_landing_page" />
              </ListItemText>
              <ListItemText className={listItemPadding} sx={{ width: "50%" }}>
                <div className="flex space-x-2">
                  <a
                    className="flex self-end space-x-2"
                    href={companyData?.portal_url}
                    rel="noreferrer">
                    {companyData?.portal_url}
                  </a>
                  <Tooltip title={tooltipText} placement="top-end" className="cursor-pointer">
                    <FontAwesomeIcon icon="circle-info" color={PRIGHTER_BLUE} size="lg" />
                  </Tooltip>
                </div>
              </ListItemText>
            </ListItem>

            <Divider />
            <ListItem>
              <ListItemText sx={{ width: "50%" }}>
                {configData?.privacy_policy_ext_url ? (
                  <T keyName="billing.privacy_url" />
                ) : (
                  <div className="flex flex-row items-center gap-2">
                    {tolgee.t("billing.privacy_url")}
                    <span className="items-center justify-center px-2 py-1 text-xs font-bold leading-none text-orange-100 rounded-full bg-danger-400">
                      <Tooltip
                        title={<div>{tolgee.t("billing.privacy_url_tooltip")}</div>}
                        placement="top">
                        <FontAwesomeIcon
                          icon="exclamation-triangle"
                          color="navigation"
                          fixedWidth
                        />
                      </Tooltip>
                    </span>
                  </div>
                )}
              </ListItemText>
              <ListItemText sx={{ width: "50%" }}>
                <>
                  <div className="flex flex-row items-center justify-between gap-2">
                    <div>{configData?.privacy_policy_ext_url}</div>
                    <IconButton size="small" className="" onClick={() => setShowPrivacyModal(true)}>
                      <FontAwesomeIcon icon="pen-to-square" color="gray" size="xs" />
                    </IconButton>
                  </div>
                  {showPrivacyModal && (
                    <BaseModal
                      modalState={[showPrivacyModal, setShowPrivacyModal]}
                      closeEvent={() => setShowPrivacyModal(false)}>
                      <PrivacyPolicyModal onLinkUpdate={handleLinkUpdate} configData={configData} />
                    </BaseModal>
                  )}
                </>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText sx={{ width: "50%" }}>
                <T keyName="dashboard.active_features" />
              </ListItemText>
              <ListItemText sx={{ width: "50%" }}>
                <div>
                  {companyData.features.map((active_feature, ind) => (
                    <Fragment key={active_feature}>
                      <T keyName={`dashboard.active_feature.${active_feature}`} />
                      {ind !== companyData.features.length - 1 && <>, </>}
                    </Fragment>
                  ))}
                </div>
              </ListItemText>
            </ListItem>
            <div className="flex flex-col items-end">
              <Button component={Link} to={`/business/${businessId}/ropa`}>
                <T keyName="ropa.open_ropas_page" />
              </Button>
            </div>
          </List>
          <div className="mb-2" />
        </div>
      </div>
      <div className="block sm:hidden space-y-4 mt-4">
        <div>
          <div className="font-bold">
            <T keyName="billing.company_name" />:
          </div>
          <div>{companyData?.company_name}</div>
        </div>
        <div>
          <div className="font-bold">
            <T keyName="billing.company_address" />:
          </div>
          <div className="-ml-2">
            <EditableText
              value={stringifyCompanyAddress(companyData)}
              modalState={[closeModal, setCloseModal]}>
              <CompanyAddressModal
                onAddressUpdate={onAddressUpdate}
                address={companyData.address}
              />
            </EditableText>
          </div>
        </div>
        <div>
          <div className="font-bold">
            <T keyName="billing.prighter_id" />:
          </div>
          <div>{companyData?.public_id}</div>
        </div>
        <div>
          <div className="font-bold">
            <T keyName="billing.compliance_landing_page" />:
          </div>
          <div>
            <div className="flex space-x-2">
              <a
                className="flex self-end space-x-2"
                href={companyData?.portal_url}
                rel="noreferrer">
                {companyData?.portal_url}
              </a>
              <Tooltip title={tooltipText} placement="top-end" className="cursor-pointer">
                <FontAwesomeIcon icon="circle-info" color={PRIGHTER_BLUE} size="lg" />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
