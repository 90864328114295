import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { T } from "@tolgee/react";
import { useNavigate } from "react-router";

import { api } from "services";
import { useAppDispatch, useAppSelector } from "store";
import ToDoList from "pages/Client/Dashboard/Components/ToDoList";
import StatCard from "pages/Client/Dashboard/Components/StatCard";
import ProductList from "pages/Client/Dashboard/Components/ProductList";
import {
  emptyDashboardData,
  getFinishedToDoCount,
  getTotalToDoCount,
  isThereUnfinishedToDo,
} from "pages/Client/Dashboard/dashboardHelpers";
import { fetchUserData } from "store/thunks";
import { isManager } from "helpers/general";
import { handleError } from "services/api/error";

import type { DashboardData, ProductSubscriptionStatus } from "types/user";

export default function ViewDashboard() {
  const { managed_businesses, roles } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState<DashboardData>(emptyDashboardData);
  const [showDashboard, setShowDashboard] = useState(true);
  const [allToDosDone, setAllToDosDone] = useState(false);

  useEffect(() => {
    if (isManager(roles)) {
      navigate("/manage-users");
    }
  }, [roles, navigate]);

  useEffect(() => {
    async function getDashboardData() {
      let res;
      try {
        res = await api.user.fetchDashboardData();
      } catch (e) {
        handleError(e);
        return;
      }
      res = res.data;

      if (!res.user_todos) {
        return;
      }
      setAllToDosDone(!isThereUnfinishedToDo(res));
      setDashboardData(res);
    }
    getDashboardData();
    dispatch(fetchUserData());
  }, [dispatch]);

  const getBusiness = (businessId: string) => {
    return managed_businesses.find((b) => b.public_id === businessId);
  };

  const getProductStatus = (businessId: string): ProductSubscriptionStatus[] => {
    return dashboardData.product_status[businessId];
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="dashboard-page">
      {!allToDosDone && (
        <>
          <div className="flex items-center justify-between pb-2 mt-2">
            <span className="text-lg text-brand-900">
              <T keyName="dashboard.company_profile_data" />:
              <span
                className={clsx(
                  {
                    "text-success-400":
                      getFinishedToDoCount(dashboardData) === getTotalToDoCount(dashboardData),
                  },
                  "text-orange-400"
                )}>
                {getFinishedToDoCount(dashboardData)}/{getTotalToDoCount(dashboardData)}
              </span>
            </span>

            <Switch
              checked={showDashboard}
              onChange={() => setShowDashboard(!showDashboard)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>

          {showDashboard && (
            <ToDoList
              userToDos={dashboardData?.user_todos?.filter((t) => !t.closed_at)}
              businessTodos={dashboardData?.business_todos}
            />
          )}
        </>
      )}
      {Object.entries(dashboardData?.stats).map(([businessId, statVal]) => (
        <div key={businessId} className="box-outerlayout mb-4 mt-4">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex flex-col items-start">
              <div className="flex flex-col md:flex-row items-center text-center py-4">
                <h2 className="m-0 mr-2 text-2xl">
                  {getBusiness(businessId)?.company_name || businessId}
                </h2>
                <span className="self-center text-gray-600 font-semibold">
                  (<T keyName="generic.id" />: {businessId})
                </span>
              </div>

              <StatCard stats={statVal} />
            </div>

            {getBusiness(businessId)?.config?.logo_url && (
              <figure className="mt-4 image">
                <img
                  alt="company logo"
                  style={{ maxHeight: "96px", maxWidth: "96px" }}
                  src={getBusiness(businessId)?.config?.logo_url as string}
                />
              </figure>
            )}
          </div>

          <ProductList activeProducts={getProductStatus(businessId)} businessId={businessId} />
        </div>
      ))}
    </div>
  );
}
