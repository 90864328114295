import { Checkbox } from "@mui/material";
import clsx from "clsx";

import type { UseFormRegisterReturn } from "react-hook-form";
import type { ReactNode } from "react";

type Props = {
  title?: string;
  children?: ReactNode;
  checked?: boolean;
  register?: UseFormRegisterReturn<any>;
  onCheck?: () => void;
};

export default function RightCheckbox({ title, children, checked, register, onCheck }: Props) {
  return (
    <div>
      <div className="flex items-center text-center">
        <button type="button" onClick={onCheck}>
          <Checkbox {...register} checked={checked} />
          <span className="font-bold">{title}</span>
        </button>
      </div>

      <div className={clsx({ hidden: !checked, "p-4": checked && children })}>{children}</div>
    </div>
  );
}
