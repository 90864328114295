import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, InputAdornment, OutlinedInput, Tooltip } from "@mui/material";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import CaseHistory from "pages/Client/History/CaseHistory";
import { api } from "services";
import { getBeautyDateTime, getUrl } from "helpers";
import { PRIGHTER_URL, PRIVACY_POLICY } from "const/env";
import { handleError } from "services/api/error";
import ProgressBar from "components/Feedback/ProgressBar";
import UnauthorizedAccessModal from "components/General/UnauthorisedAccessModal";

import type { DSRPublic } from "types/dsr";

export default function DSROverview() {
  const { caseUuid, businessId } = useParams();
  const { pathname } = useLocation();
  const currentUrl = useMemo(() => getUrl("FLASK", pathname), [pathname]);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<DSRPublic>();

  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const stateColor = (state: string) => {
    switch (state) {
      case "CLOSED":
        return "success";

      default:
        return "turquoise";
    }
  };

  const fetchCaseData = useCallback(async () => {
    try {
      const res = await api.dsr.getDsrPublic(businessId, caseUuid);
      setData(res.data);
    } catch (e) {
      if (e.response.status === 401) {
        setShowAuthModal(true);
      } else {
        handleError(e);
      }
    } finally {
      setIsLoading(false);
    }
  }, [businessId, caseUuid]);

  useEffect(() => {
    fetchCaseData();
  }, [fetchCaseData]);

  const authorizeCase = async () => {
    try {
      await api.dsr.postCaseAuthorization(businessId, caseUuid, password);
      fetchCaseData();
      setShowAuthModal(false);
    } catch {
      setPasswordError(true);
    }
  };

  const handleCopyCaseUrl = async () => {
    await navigator.clipboard.writeText(currentUrl);
  };

  return (
    <>
      {isLoading && (
        <>
          <ProgressBar />
        </>
      )}

      {showAuthModal && (
        <>
          <UnauthorizedAccessModal
            modalState={[showAuthModal, setShowAuthModal]}
            passwordErrorState={[passwordError, setPasswordError]}
            setPassword={setPassword}
            submit={authorizeCase}
          />
        </>
      )}

      {data && (
        <div className="flex flex-col mx-auto max-w-7xl" data-testid="dsr-overview">
          <div className="box-outerlayout mb-4 mt-4">
            <div className="flex justify-between">
              <div>
                {data.business.config.logo && (
                  <figure className="my-4 image">
                    <img
                      alt="clogo"
                      style={{ maxHeight: "96px", maxWidth: "96px" }}
                      src={data.business.config.logo}
                    />
                  </figure>
                )}
                <div className="flex flex-col">
                  <div className="grid items-baseline text-2xl font-bold text-brand-900">
                    <a
                      className="flex items-center space-x-2 text-brand-900"
                      href={data.business.portal_url}
                      target="_blank"
                      rel="noreferrer">
                      <span>{data.business.company_name}</span>
                      <FontAwesomeIcon icon="external-link-alt" color="gray" size="xs" />
                    </a>
                  </div>
                  <p className="mt-0 text-lg text-gray-800">{`${data.business.address.street}, ${data.business.address.postal}, ${data.business.address.city}, ${data.business.address.country.name}`}</p>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="items-center justify-end text-base md:text-right ">
                  <div className="text-gray-800">
                    <T keyName="public_dsr.ds" />
                  </div>
                  <div className="space-x-2 flex items-baseline">
                    {data.ds_email_verified ? (
                      <Tooltip
                        title="Thank you for verifying your Email!"
                        placement="top-end"
                        className="cursor-pointer">
                        <FontAwesomeIcon
                          icon="circle-check"
                          className="text-success-900"
                          size="sm"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Please verify your email address by clicking on the link sent to this email address."
                        placement="top-end"
                        className="cursor-pointer">
                        <FontAwesomeIcon
                          icon="circle-xmark"
                          className="text-danger-400"
                          size="sm"
                        />
                      </Tooltip>
                    )}
                    <div className="font-bold">
                      {
                        data.case_actors
                          ?.find((i) => i.acting_as === "DATA_SUBJECT")
                          ?.contact?.contact_points?.find((j) => j.type === "EMAIL").value
                      }
                    </div>
                  </div>
                  <div className="flex flex-col text-right mt-4">
                    <div className="text-sm">UUID</div>
                    <div className="font-bold">{data.uuid}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 !w-full">
              <div className="mb-6">
                <OutlinedInput
                  className="w-full"
                  readOnly
                  value={currentUrl}
                  type="text"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => handleCopyCaseUrl()}>
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            {data.inquiries_open.length > 0 && (
              <div className="flex flex-col items-baseline gap-2">
                <div className="text-gray-800">
                  <T keyName="public_dsr.open_inquiries" /> ({data.inquiries_open.length})
                </div>
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/business/${businessId}/case/${caseUuid}/inquiries`)}>
                  <T keyName="public_dsr.view_inquiries" />
                </Button>
              </div>
            )}
          </div>
          <div className="box-outerlayout mb-4 mt-6">
            <div className="text-2xl font-bold mt-2">
              <T keyName="public_dsr.you_ds" /> {data.business.company_name}
            </div>
            <div className="grid gap-16 px-4 pb-4 mt-6">
              <div className="flex flex-col space-y-2 text-gray-800">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <div className="text-sm">
                      <T keyName="public_dsr.received" />
                    </div>
                    <div className="font-bold">{getBeautyDateTime(data.created_at)}</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-sm">
                      <T keyName="cases.deadline" />
                    </div>
                    <div className="font-bold">{getBeautyDateTime(data.deadline)}</div>
                  </div>
                  <div>
                    <span
                      className={`text-center rounded-full py-2 px-4 text-xs whitespace-nowrap bg-${stateColor(
                        data.state
                      )}-400`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-outerlayout mb-4 mt-6">
            <CaseHistory caseData={{ ...data, histories: data.public_histories } as any} />
          </div>
          <div className="w-full pb-12 mt-10">
            <div className="flex flex-row justify-between text-gray-600 text-sm ">
              <div>
                <T keyName="public_dsr.powered_by" />
                <a className="text-gray-600" href={PRIGHTER_URL}>
                  <T keyName="public_dsr.prighter" />
                </a>
              </div>
              <div className="flex flex-row space-x-2">
                <div>
                  <a className="text-gray-600" href="/legal">
                    <T keyName="public_dsr.legal" />
                  </a>
                </div>
                <div>
                  <a className="text-gray-600" href={PRIVACY_POLICY}>
                    <T keyName="public_dsr.privacy" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
