import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import { T } from "@tolgee/react";

import { BaseButton } from "components/Button";
import { SelectField } from "components/Input";
import tolgee from "services/translation";
import { DefaultPointType } from "types/ccpaSettings";

import type { DefaultPointItem, DefaultPointsOption } from "types/ccpaSettings";

interface Props {
  defaultPointsData: DefaultPointItem[];
  defaultPointsOptions: DefaultPointsOption[];
  handleSelection: any;
  handleDeletion: any;
  addAdditionalPoint: any;
}

export default function DefaultDataPoints({
  defaultPointsData,
  defaultPointsOptions,
  handleSelection,
  handleDeletion,
  addAdditionalPoint,
}: Props) {
  const getFilteredItems = (selectedItem: DefaultPointItem) => {
    return defaultPointsOptions.filter(
      (item) => !item.selected || item.value === selectedItem.value
    );
  };

  const getAdditionalFilteredItems = (selectedItem: DefaultPointItem) => {
    const inputStrict = defaultPointsData.find(
      (item) => item.type === DefaultPointType.required_inputs_strict
    );

    // Filter items which are NOT selected + INCLUDE strictInput + INCLUDE currently selected item
    return defaultPointsOptions.filter(
      (item) =>
        !item.selected || item.value === selectedItem.value || inputStrict.value === item.value
    );
  };

  return (
    <>
      <div>
        <div className="mb-4">
          <h3 className="mb-2 mt-0">
            <T keyName="dsr.settings.ccpa.default_data_title" />
          </h3>
          <div>
            <T keyName="dsr.settings.ccpa.default_data_description" />
          </div>
          <hr className="h-px my-4 bg-gray-200 border-0" />
          {defaultPointsData
            .filter((item) => item.type === DefaultPointType.required_inputs)
            .map((item) => (
              <div className="my-4" key={item.id}>
                <SelectField
                  label={item.value || tolgee.t("dsr.settings.label_select")}
                  value={item.value}
                  options={getFilteredItems(item).map((filteredItem) => filteredItem.value)}
                  onChange={(value: string) => handleSelection(item, value)}
                />
              </div>
            ))}
          <hr className="h-px my-4 bg-gray-200 border-0" />
          <div className="mb-4">
            <T keyName="dsr.settings.ccpa.strict_input_description" />
          </div>
          {defaultPointsData
            .filter((item) => item.type === DefaultPointType.required_inputs_strict)
            .map((item) => (
              <div className="my-2" key={item.id}>
                <SelectField
                  label={item.value || tolgee.t("dsr.settings.label_select")}
                  value={item.value}
                  options={getFilteredItems(item).map((filteredItem) => filteredItem.value)}
                  onChange={(value: string) => handleSelection(item, value)}
                />
              </div>
            ))}
        </div>
        <hr className="h-px my-8 bg-gray-200 border-0" />
        <div>
          <h3 className="mb-2 mt-0">
            <T keyName="dsr.settings.ccpa.additional_input_title" />
          </h3>
          <div>
            <T keyName="dsr.settings.ccpa.additional_input_description" />
          </div>
          <hr className="h-px my-4 bg-gray-200 border-0" />
          {defaultPointsData
            .filter((item) => item.type === DefaultPointType.additional_inputs)
            .map((item) => (
              <div className="my-4 flex fle-row items-start justify-between gap-2" key={item.id}>
                <div className="flex grow">
                  <SelectField
                    label={item.value || tolgee.t("dsr.settings.label_select")}
                    value={item.value}
                    options={getAdditionalFilteredItems(item).map(
                      (filteredItem) => filteredItem.value
                    )}
                    onChange={(value: string) => handleSelection(item, value)}
                  />
                </div>
                <div className="flex py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50">
                  <IconButton size="large" onClick={() => handleDeletion(item)}>
                    <FontAwesomeIcon icon="trash" className=" text-brand-600" size="xs" />{" "}
                  </IconButton>
                </div>
              </div>
            ))}
          <div className="w-full flex align-center justify-center">
            <BaseButton className="w-auto" variant="outlined" onClick={addAdditionalPoint}>
              <T keyName="dsr.settings.ccpa.add_additional_data_point" />
            </BaseButton>
          </div>
        </div>
      </div>
    </>
  );
}
