import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FormFileField } from "components";
import tolgee from "services/translation";
import { api } from "services";
import { fileToFileObject } from "helpers";
import { useAppDispatch } from "store";
import { notify } from "store/app";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
import { generateFileUrl } from "helpers/case";
import { handleError } from "services/api/error";

import type { Company } from "types/company";
import type { BusinessFilePrivate } from "types/business";

type Props = { business: Company };

export default function RopaTab({ business }: Props) {
  const [ropaController, setRopaController] = useState<string>();
  const [ropaProcessor, setRopaProcessor] = useState<string>();
  const [reload, setReload] = useState(0);
  const dispatch = useAppDispatch();

  const handleReload = () => setReload((prevRel) => prevRel + 1);

  useEffect(() => {
    async function fetchRopaFiles() {
      let res;
      try {
        res = await api.business.getFile(business.public_id, "ROPA_CONTROLLER");
        setRopaController(res.data);
      } catch (e) {
        handleError(e, true);
      }

      try {
        res = await api.business.getFile(business.public_id, "ROPA_PROCESSOR");
        setRopaProcessor(res.data);
      } catch (e) {
        handleError(e, true);
      }
    }
    fetchRopaFiles();
  }, [business.public_id, reload]);

  const handleUploadFile = async (file: File, type: BusinessFilePrivate) => {
    const fileObject = await fileToFileObject(file);
    try {
      await api.business.uploadFile(business.public_id, fileObject, type);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      handleReload();
    }
    dispatch(
      notify({
        message: tolgee.t({
          key: "ropa.file_uploaded",
        }),
        type: "SUCCESS",
      })
    );
  };

  const handleDeleteFile = async (type: BusinessFilePrivate) => {
    try {
      await api.business.deleteFile(business.public_id, type);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      handleReload();
    }
    dispatch(
      notify({
        message: tolgee.t({
          key: "ropa.file_deleted",
        }),
        type: "SUCCESS",
      })
    );
  };

  return (
    <div className="box-outerlayout">
      <div className="flex flex-row space-x-8">
        <div className="flex flex-col">
          <h3>
            <T keyName="manage_business.ropa_controller" />
          </h3>
          <FormFileField
            label={tolgee.t({
              key: "generic.choose_files",
            })}
            onChange={(files: File[]) => handleUploadFile(files[0], "ROPA_CONTROLLER") as any}
          />

          {ropaController && (
            <div className="flex items-start">
              <FileDownloadPreview
                url={generateFileUrl(business.public_id, "ROPA_CONTROLLER")}
                fileName={tolgee.t({ key: "file.ropa_controller" })}
              />
              <IconButton size="medium" onClick={() => handleDeleteFile("ROPA_CONTROLLER")}>
                <FontAwesomeIcon icon="xmark" className="text-gray-600" size="xs" />
              </IconButton>
            </div>
          )}
        </div>

        <div className="flex flex-col">
          <h3>
            <T keyName="manage_business.ropa_processor" />
          </h3>
          <FormFileField
            label={tolgee.t({
              key: "generic.choose_files",
            })}
            onChange={(files: File[]) => handleUploadFile(files[0], "ROPA_PROCESSOR") as any}
          />

          {ropaProcessor && (
            <div className="flex items-start">
              <FileDownloadPreview
                url={generateFileUrl(business.public_id, "ROPA_PROCESSOR")}
                fileName={tolgee.t({ key: "file.ropa_processor" })}
              />
              <IconButton size="medium" onClick={() => handleDeleteFile("ROPA_PROCESSOR")}>
                <FontAwesomeIcon icon="xmark" className="text-gray-600" size="xs" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
