import { Button, CircularProgress, IconButton } from "@mui/material";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FormFileField } from "components";
import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
import { fileToFileObject } from "helpers";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
import { generateFileUrl } from "helpers/case";
import { handleError } from "services/api/error";
import { CDN, RELEASE } from "const/env";

import type { BusinessFilePrivate } from "types/business";
import type { Company } from "types/company";

export default function RopaPage() {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const [business, setBusiness] = useState<Company>(null);
  const [ropaController, setRopaController] = useState<string>();
  const [ropaProcessor, setRopaProcessor] = useState<string>();
  const [tomFiles, setTomFiles] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchBusinessData = useCallback(async () => {
    try {
      const res = await api.business.get(businessId);
      setBusiness(res.data);
    } catch (e) {
      handleError(e);
    }
  }, [businessId]);

  const fetchRopaControllerFiles = useCallback(async () => {
    try {
      const res = await api.business.getFile(businessId, "ROPA_CONTROLLER");
      setRopaController(res.data);
    } catch (e) {
      handleError(e, true);
      setRopaController(null);
    }
  }, [businessId]);

  const fetchRopaProcessorFiles = useCallback(async () => {
    try {
      const res = await api.business.getFile(businessId, "ROPA_PROCESSOR");
      setRopaProcessor(res.data);
    } catch (e) {
      handleError(e, true);
      setRopaProcessor(null);
    }
  }, [businessId]);

  const fetchTomFiles = useCallback(async () => {
    try {
      const res = await api.business.getFile(businessId, "TOM");
      setTomFiles(res.data);
    } catch (e) {
      handleError(e, true);
      setTomFiles(null);
    }
  }, [businessId]);

  const fetchRopaFiles = useCallback(async () => {
    setIsLoading(true);
    await fetchRopaControllerFiles();
    await fetchRopaProcessorFiles();
    await fetchTomFiles();
    setIsLoading(false);
  }, [fetchRopaControllerFiles, fetchRopaProcessorFiles, fetchTomFiles]);

  const handleDataFetching = useCallback(async () => {
    await fetchBusinessData();
    await fetchRopaFiles();
  }, [fetchBusinessData, fetchRopaFiles]);

  useEffect(() => {
    handleDataFetching();
  }, [businessId, handleDataFetching]);

  const handleUploadFile = async (file: File, type: BusinessFilePrivate) => {
    const fileObject = await fileToFileObject(file);

    try {
      setIsLoading(true);
      await api.business.uploadFile(businessId, fileObject, type);

      dispatch(
        notify({
          message: tolgee.t({
            key: "generic.file_uploaded",
          }),
          type: "SUCCESS",
        })
      );
      if (type === "ROPA_CONTROLLER") {
        fetchRopaControllerFiles();
      } else if (type === "ROPA_PROCESSOR") {
        fetchRopaProcessorFiles();
      } else if (type === "TOM") {
        fetchTomFiles();
      }
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFile = async (type: BusinessFilePrivate) => {
    setIsLoading(true);
    try {
      await api.business.deleteFile(businessId, type);

      dispatch(
        notify({
          message: tolgee.t({
            key: "generic.file_deleted",
          }),
          type: "SUCCESS",
        })
      );

      if (type === "ROPA_CONTROLLER") {
        fetchRopaControllerFiles();
      } else if (type === "ROPA_PROCESSOR") {
        fetchRopaProcessorFiles();
      } else if (type === "TOM") {
        fetchTomFiles();
      }
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <div className="box-outerlayout mb-4">
        <h1>
          <T keyName="ropa.ropa" /> ({business?.company_name})
        </h1>
        <div className="mb-4 mt-2">
          <T keyName="ropa.ropa_description_part1" />
        </div>
        <div>
          <T keyName="ropa.ropa_description_part2" />
        </div>
        <div className="mt-6">
          <Button
            component={Link}
            to={`${CDN}/static/legacy/${RELEASE}/prighter/ropa/ropa_templates.zip`}>
            {tolgee.t({
              key: "ropa.download",
            })}
          </Button>
        </div>
      </div>
      <div className="box-outerlayout mb-4">
        <h1>
          <T keyName="ropa.upload_records" />
        </h1>
        <div className="flex space-x-6">
          <div className="flex flex-1 flex-col">
            <h3>
              <T keyName="ropa.ropa_controller" />
            </h3>
            <div className="flex flex-col gap-8">
              <FormFileField
                label="Upload a file"
                className="self-center"
                onChange={(files: File[]) =>
                  files.length ? (handleUploadFile(files[0], "ROPA_CONTROLLER") as any) : ""
                }
                clearFilesAfterUpload
                fullWidth
              />
              {isLoading && (
                <div className="flex items-center justify-center">
                  <CircularProgress color="primary" />
                </div>
              )}
              {ropaController ? (
                <div className="flex flex-row items-center justify-end gap-4">
                  <FileDownloadPreview
                    url={generateFileUrl(businessId, "ROPA_CONTROLLER")}
                    fileName={tolgee.t({ key: "file.ropa_controller" })}
                  />
                  <IconButton size="medium" onClick={() => handleDeleteFile("ROPA_CONTROLLER")}>
                    <FontAwesomeIcon icon="xmark" className="text-gray-600" size="xs" />
                  </IconButton>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex flex-1 flex-col">
            <h3>
              <T keyName="ropa.ropa_processor" />
            </h3>
            <div className="flex flex-col gap-8">
              <FormFileField
                label="Upload a file"
                className="self-center"
                onChange={(files: File[]) =>
                  files.length ? (handleUploadFile(files[0], "ROPA_PROCESSOR") as any) : ""
                }
                clearFilesAfterUpload
                fullWidth
              />
              {isLoading && (
                <div className="flex items-center justify-center">
                  <CircularProgress color="primary" />
                </div>
              )}
              {ropaProcessor ? (
                <div className="flex flex-row items-center justify-end gap-4">
                  <FileDownloadPreview
                    url={generateFileUrl(businessId, "ROPA_PROCESSOR")}
                    fileName={tolgee.t({ key: "file.ropa_controller" })}
                  />
                  <IconButton size="medium" onClick={() => handleDeleteFile("ROPA_PROCESSOR")}>
                    <FontAwesomeIcon icon="xmark" className="text-gray-600" size="xs" />
                  </IconButton>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="box-outerlayout mb-4">
        <div className="flex space-x-6">
          <div className="flex flex-1 flex-col">
            <h3>
              <T keyName="tom.tom" />
            </h3>
            <div className="flex flex-col gap-8">
              <FormFileField
                label="Upload a file"
                className="self-center"
                onChange={(files: File[]) =>
                  files.length ? (handleUploadFile(files[0], "TOM") as any) : ""
                }
                clearFilesAfterUpload
                fullWidth
              />
              {isLoading && (
                <div className="flex items-center justify-center">
                  <CircularProgress color="primary" />
                </div>
              )}
              {tomFiles ? (
                <div className="flex flex-row items-center justify-end gap-4">
                  <FileDownloadPreview
                    url={generateFileUrl(businessId, "TOM")}
                    fileName={tolgee.t({ key: "tom.tom" })}
                  />
                  <IconButton size="medium" onClick={() => handleDeleteFile("TOM")}>
                    <FontAwesomeIcon icon="xmark" className="text-gray-600" size="xs" />
                  </IconButton>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
