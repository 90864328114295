export default function HorizontalDataValue(props: {
  title: string;
  value: string | boolean;
  bold?: boolean;
}) {
  const { title, value, bold = true } = props;

  return (
    <div className="flex flex-row">
      <span className={bold ? "font-bold" : "font-normal"}>{title}:&nbsp;</span>
      <span>{value}</span>
    </div>
  );
}
