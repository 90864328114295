import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { T } from "@tolgee/react";

import { BaseModal } from "components";
import { GRAY_200, PRIGHTER_DARK_BLUE, PRIGHTER_HIGHLIGHT } from "const/color";
import EmailCasesDetails from "pages/Client/EmailCases/Components/EmailCasesDetails";
import EmailCasesFormAdditional from "pages/Client/EmailCases/Components/ExpertMultistepForm/EmailCasesFormAdditional";
import EmailCasesFormWhat from "pages/Client/EmailCases/Components/ExpertMultistepForm/EmailCasesFormWhat/EmailCasesFormWhat";
import EmailCasesFormWhen from "pages/Client/EmailCases/Components/ExpertMultistepForm/EmailCasesFormWhen";
import EmailCasesFormWhere from "pages/Client/EmailCases/Components/ExpertMultistepForm/EmailCasesFormWhere";
import EmailCasesFormWho from "pages/Client/EmailCases/Components/ExpertMultistepForm/EmailCasesFormWho/EmailCasesFormWho";
import EmailCasesStepper from "pages/Client/EmailCases/Components/Stepper";
import { buttonStyle, multilineStyle } from "pages/Client/EmailCases/Pages/styling";
import { ccpaFormInitialValues } from "pages/Public/Dsr/Ccpa/ccpaRequestObject";
import { api } from "services";
import tolgee from "services/translation";
import { handleError } from "services/api/error";
import { CollectionTypeEnum } from "types/case";
import EmailCasesMenu from "pages/Client/EmailCases/Components/EmailCasesMenu";

import type { CaseDetail } from "types/case";
import type { CcpaForm, CcpaFormDsr } from "types/dsr/ccpa";

const MAX_STEP = 5;
// const MAX_STEP = 6;

function Nav({
  nextStep,
  previousStep,
  currentEmailCase,
  submit,
}: {
  nextStep: () => void;
  previousStep: () => void;
  currentEmailCase?: CaseDetail;
  submit?: boolean;
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex items-center gap-4">
      <Button
        type="button"
        sx={{ ...buttonStyle, backgroundColor: GRAY_200, color: "white" }}
        onClick={previousStep}>
        <T keyName="generic.back" />
      </Button>
      {submit ? (
        <Button
          type="submit"
          sx={{
            ...buttonStyle,
            backgroundColor: PRIGHTER_HIGHLIGHT,
            color: "white",
          }}>
          <T keyName="generic.save_request" />
        </Button>
      ) : (
        <Button type="button" sx={buttonStyle} onClick={nextStep}>
          <T keyName="generic.next_section" />
        </Button>
      )}
      <span className="ml-2 text-[#B6B6B6]">
        <T keyName="generic.or_press_enter" />
      </span>
      <FontAwesomeIcon icon="circle-exclamation" color="orange" size="lg" className="ml-3" />
      <span
        tabIndex={0}
        role="button"
        onKeyDown={() => setOpen(true)}
        className="ml-2 underline decoration-solid text-prighterdark"
        onClick={() => setOpen(true)}>
        <T keyName="generic.missing_information" />
      </span>
      {currentEmailCase && (
        <BaseModal
          modalState={[open, setOpen]}
          className="rounded-3xl p-12 flex justify-between w-[90vw]  gap-20">
          <div className="flex-1">
            <EmailCasesDetails currentEmailCase={currentEmailCase} />
          </div>
          <div className="flex flex-col space-y-6 items-start flex-1">
            <Button
              className="text-prighterdark place-self-end"
              variant="text"
              onClick={() => setOpen(false)}>
              <FontAwesomeIcon icon="times" color="black" size="lg" className="mr-2" />
            </Button>
            <h3 className="text-center  font-normal text-[28px] m-0">
              <FontAwesomeIcon
                icon="circle-exclamation"
                color="orange"
                size="sm"
                className="mr-2"
              />
              <T keyName="generic.missing_information" />:
            </h3>
            <TextField
              multiline
              sx={multilineStyle}
              rows={8}
              className="w-full"
              placeholder={tolgee.t("email_intake.add_notes_for_missing_information")}
            />
            <div className="place-self-end">
              <Button
                type="submit"
                variant="text"
                className="text-prighterdark"
                onClick={() => setOpen(false)}>
                <T keyName="generic.cancel" />
              </Button>
              <Button
                type="submit"
                onClick={() => setOpen(false)}
                sx={{
                  ...buttonStyle,
                  backgroundColor: PRIGHTER_HIGHLIGHT,
                  color: "white",
                }}>
                <T keyName="generic.save" />
              </Button>
            </div>
          </div>
        </BaseModal>
      )}
    </div>
  );
}

function DisabledContainer({ children }: { children: React.ReactNode }) {
  return <div className="opacity-30 pointer-events-none ">{children}</div>;
}

export default function EmailCaseDetails() {
  const { id } = useParams();
  const [currentEmailCase, setCurrentEmailCase] = useState<CaseDetail>(null);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const methods = useForm<CcpaForm>({
    defaultValues: {
      ...ccpaFormInitialValues,
      dsrs: [],
    },
  });

  const { setValue } = methods;

  const onSubmit = async () => {
    const tempValues = methods.getValues();
    const checkedDSRs = tempValues.dsrs.filter((dsr) => dsr.checked);
    const updatedDsrs: CcpaFormDsr[] = [];

    // Process checked DSRs
    checkedDSRs.forEach((item) => {
      if (item.type === "DSR_CCPA_ACCESS") {
        item.inquiries.forEach((i: any) => {
          if (i.checked) {
            updatedDsrs.push({
              type: i.type,
              checked: i.checked,
              inquiries: [
                {
                  answer: i.answer,
                  question_i18n: i.question_i18n,
                },
              ],
            });
          }
        });
      } else {
        updatedDsrs.push(item);
      }
    });

    // Update actors based on acting_for value
    if (tempValues.actors[1].acting_for === "MYSELF") {
      tempValues.actors = tempValues.actors.slice(0, 1);
    } else {
      delete tempValues.actors[0].acting_for;
    }

    // Update identifiable information
    const { username, email, phone, address } = tempValues.identifiable_info;
    Object.assign(tempValues.actors[0], { name: username, email, phone, address });

    // Prepare values to submit
    const valuesToSubmit: CcpaForm = {
      ...tempValues,
      dsrs: updatedDsrs,
      type: CollectionTypeEnum.DSR_CCPA,
      source: "EMAIL",
      parent_id: currentEmailCase.uuid,
    };

    // Submit the form
    try {
      await api.ccpa.createDsrCcpaCase(currentEmailCase.business.public_id, valuesToSubmit);
    } catch (e) {
      handleError(e);
      return;
    }

    navigate("/dsrs");
  };

  const nextStep = async () => {
    if (step === MAX_STEP) {
      return;
    }

    const isValid = await methods.trigger();

    if (!isValid) {
      return;
    }

    if (step === 1) {
      const dsrs = methods.getValues("dsrs");

      if (Array.isArray(dsrs) && dsrs.length === 0) {
        methods.setError("dsrs", {
          type: "manual",
          message: "Please select at least one DSR.",
        });
        return;
      }
      methods.clearErrors("dsrs");
    }
    setStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    setStep((prevStep) => {
      if (prevStep === 0) {
        return 0;
      }
      return prevStep - 1;
    });
  };

  const renderStep = (currenStep: number) => {
    switch (currenStep) {
      case 0:
        return (
          <>
            <EmailCasesFormWhere />
            <Nav nextStep={nextStep} previousStep={previousStep} />
            <DisabledContainer>
              <EmailCasesFormWhat />
            </DisabledContainer>
          </>
        );
      case 1:
        return (
          <>
            <DisabledContainer>
              <EmailCasesFormWhere />
            </DisabledContainer>
            <EmailCasesFormWhat />
            <Nav
              nextStep={nextStep}
              previousStep={previousStep}
              currentEmailCase={currentEmailCase}
            />
          </>
        );
      case 2:
        return (
          <>
            <EmailCasesFormWho setStep={setStep} />
            <Nav nextStep={nextStep} previousStep={previousStep} />
          </>
        );
      case 3:
        return (
          <>
            <EmailCasesFormWhen />
            <Nav nextStep={nextStep} previousStep={previousStep} />
            <DisabledContainer>
              <EmailCasesFormAdditional />
            </DisabledContainer>
          </>
        );
      case 4:
        return (
          <>
            <DisabledContainer>
              <EmailCasesFormWhen />
            </DisabledContainer>
            <EmailCasesFormAdditional />
            <Nav nextStep={nextStep} previousStep={previousStep} submit />
          </>
        );
      default:
        return (
          <p>
            <T keyName="email_intake.something_went_wrong_on_step" />: {currenStep}
          </p>
        );
    }
  };

  useEffect(() => {
    (async (uuid: string) => {
      let res;
      try {
        res = await api.authCase.getCase(uuid);
      } catch (e) {
        handleError(e, false, true);
        return;
      }
      res = res.data;

      setCurrentEmailCase(res);
      setValue("received_at", res.created_at);
      setValue("identifiable_info.username", res.email_inbox.from_name);
      setValue("identifiable_info.email", res.email_inbox.from_address);
    })(id);
  }, [id, setValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex items-center mb-8 gap-12">
          {currentEmailCase && <EmailCasesMenu currentEmailCase={currentEmailCase} />}
        </div>
        <div className="flex gap-12">
          <div className="flex-1">
            {currentEmailCase && (
              <EmailCasesDetails
                currentEmailCase={currentEmailCase}
                stepper={<EmailCasesStepper activeStep={step} />}
              />
            )}
          </div>
          <div className="flex-1 space-y-3">
            <div className="flex justify-between  items-center text-prighterdark">
              <Button variant="text" disabled={step === 0} onClick={previousStep}>
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="rotate-180"
                  width={24}
                  height={24}
                />
              </Button>
              <div className="flex  items-center gap-2   font-bold">
                <T keyName="generic.section" />
                <Chip
                  label={`${step + 1} out of ${MAX_STEP}`}
                  color="primary"
                  sx={{
                    backgroundColor: PRIGHTER_DARK_BLUE,
                    color: "white",
                  }}
                />
              </div>
              <Button variant="text" disabled={step === MAX_STEP - 1} onClick={nextStep}>
                <FontAwesomeIcon icon="chevron-right" width={24} height={24} />
              </Button>
            </div>
            <div className="flex flex-col gap-6">{renderStep(step)}</div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
