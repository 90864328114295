import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { T } from "@tolgee/react";

import customer1 from "assets/customers/angellist.png";
import customer2 from "assets/customers/manscaped.png";
import customer3 from "assets/customers/net-ease-games.png";
import customer4 from "assets/customers/south-china-morning.png";
import customer5 from "assets/customers/vevo.png";
import customer6 from "assets/customers/virgin-pulse.png";
import customer7 from "assets/customers/dmm.png";
import californiaLockImg from "assets/products/california-rep.png";
import PrighterLogo from "components/Prighter/PrighterLogo";
import { api } from "services";
import { BaseButton } from "components";
import { handleError } from "services/api/error";
/*
  This Component used for Swiss Rep Campaign
  Currently, it is not used anywhere, but keeping it here so it may be used for new reps in the future
*/
export default function ProductInterestCampaign() {
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [requestSuccessfullySent, setRequestSuccessfullySent] = useState(false);

  useEffect(() => {
    async function getUser() {
      let res;
      try {
        res = await api.general.ping();
      } catch (e) {
        handleError(e);
        return;
      }
      const { user } = res.data;
      if (user && user?.email) {
        setEmail(user.email);
        setCompanyName(user.company_name);
      }
    }
    getUser();
  }, []);

  const handleSubmit = async () => {
    try {
      await api.marketing.sendProductInterest(email, companyName);
    } catch (e) {
      handleError(e, true);
      return;
    }
    setRequestSuccessfullySent(true);
  };

  const dark = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <div>
      <div className="container flex gap-2 p-4 mx-auto lg:px-8 2xl:px-[4.5rem]">
        <PrighterLogo className="h-8" width={24.4} />
        <span className="flex text-4xl font-bold text-prighterblue">Prighter</span>
      </div>
      <div className="container grid w-full gap-4 mx-auto leading-snug -z-0 lg:grid-cols-2 ">
        <div className="grid gap-2 px-4 pt-4 lg:col-span-2 lg:w-1/2 lg:px-8 2xl:px-[4.5rem]">
          <h1 className="m-0 text-4xl text-brand-900">
            Simplified Management of CCPA Consumer Rights
          </h1>
          <h2 className="m-0 text-2xl font-thin">
            Effortlessly meet CCPA/CPRA requirements for consumer rights handling in an efficient,
            intuitive and scalable way.
          </h2>
        </div>
        <div className="max-w-2xl px-4 h-min place-self-center lg:px-8">
          <p>Your key benefits are:</p>
          <ul className="grid gap-2 pl-4 text-sm list-disc ">
            <li>
              enhanced efficiency through Prighter&apos;s consumer rights manager, streamlining
              processes, freeing up valuable resources and automating the consumer rights lifecycle.
            </li>
            <li>
              reduced legal risk and uncertainties of complying with CCPA compliance with our
              privacy by design approach. Developed and supported by a team of privacy
              professionals, Prighter&rsquo;s consumer rights manager reflects the specific
              regulatory requirements of the CCPA/CPRA.
            </li>
            <li>
              engaging Californian residents in a CCPA-compliant manner, fostering trust and loyalty
              by delivering a seamless customer experience.
            </li>
            <li>
              extensive knowledge at your fingertips: Prighter&rsquo;s consumer rights manager has
              been designed to do the hard work of interpreting legislation for you. Rely on a
              solution created by our team of industry experts and privacy lawyers. Gain access to
              our knowledge hub and
              <a href="#video" className="font-bold text-brand-600">
                watch our latest webinar on US privacy laws.
              </a>
            </li>
          </ul>
        </div>
        <div className="relative grid px-4">
          <div className="relative w-full max-w-lg p-4 pt-2 text-white transform border rounded-lg shadow-2xl lg:-translate-y-36 lg:mt-12 sm:p-6 bg-brand-800 border-slate-100 h-min place-self-center">
            <div className="z-10">
              <div className="grid gap-4">
                <div className="flex items-start gap-4 sm:items-center">
                  <img
                    src={californiaLockImg}
                    width="140"
                    className="object-contain transform lg:scale-[2] lg:-translate-x-10 lg:-translate-y-16 max-w-10 sm:max-h-24 sm:max-w-24"
                    alt=""
                  />
                  <div className="grid gap-2 lg:-ml-5 ">
                    <h2 className="m-0 text-2xl ">Secure your promotional offer:</h2>
                    <p className="relative z-20 hidden m-0 highlighted sm:block">
                      Including a <strong className="z-40">reduced price of USD 120/month</strong> &
                      if you choose yearly payment
                      <strong className="z-40">two months for free</strong>!
                    </p>
                  </div>
                </div>
                <p className="relative z-20 block m-0 highlighted sm:hidden">
                  Including a <strong className="z-40">reduced price of USD 120/month</strong> & if
                  you choose yearly payment <strong className="z-40">two months for free</strong>!
                </p>
                <div className="flex flex-col ">
                  {requestSuccessfullySent && (
                    <>
                      <b>
                        <T keyName="marketing.product_interest.feedback_message_title" />
                      </b>
                      <span className="mt-4">
                        We are happy to support you in managing consumer rights under CCPA. We will
                        follow up with next steps soon. Please look out for updates.
                      </span>
                    </>
                  )}

                  {!requestSuccessfullySent && (
                    <>
                      <div className="flex flex-col mb-2 space-y-2">
                        <ThemeProvider theme={dark}>
                          <TextField
                            label="Company Name"
                            value={companyName}
                            sx={{ outlineColor: "white", color: "white" }}
                            onChange={(text) => setCompanyName(text.target.value)}
                          />
                          <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(text) => setEmail(text.target.value)}
                          />
                        </ThemeProvider>
                      </div>

                      <BaseButton color="warning" className="self-end" onClick={handleSubmit}>
                        <span className="font-bold ">
                          <T keyName="marketing.product_interest.i_am_interested" />
                        </span>
                      </BaseButton>
                    </>
                  )}
                </div>
              </div>
              {/* <div className="absolute top-0 left-0 w-full h-full -z-10 bg-gradient-to-b from-brand-50/50 to-brand-100/40 backdrop-blur-2xl" /> */}
            </div>
          </div>
        </div>
        <div className="my-8 lg:col-span-2">
          <h3 className="text-center highlighted">
            Trusted by hundreds of organizations in <strong>49 countries</strong>, from small to
            large businesses!
          </h3>
          <div className="flex flex-wrap items-center justify-center gap-8 lg:grid lg:grid-flow-col opacity-60 ">
            <img src={customer1} width="120" className="object-contain max-h-20 max-w-36" alt="" />
            <img src={customer2} width="120" className="object-contain max-h-20 max-w-36" alt="" />
            <img src={customer3} width="120" className="object-contain max-h-20 max-w-36" alt="" />
            <img src={customer4} width="120" className="object-contain max-h-20 max-w-36" alt="" />
            <img src={customer5} width="120" className="object-contain max-h-20 max-w-36" alt="" />
            <img src={customer6} width="120" className="object-contain max-h-20 max-w-36" alt="" />
            <img src={customer7} width="120" className="object-contain max-h-20 max-w-36" alt="" />
          </div>
        </div>
      </div>
      <div className="col-span-2 mx-4 my-12" id="video">
        <iframe
          width="100%"
          className="max-w-lg mx-auto border-none rounded-t-lg shadow-2xl outline-none aspect-video "
          src="https://www.youtube.com/embed/2-SYDFCFdaY?si=3PidHwfv5rkPn73s"
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
        <div className="max-w-lg px-4 py-2 mx-auto mb-24 text-sm rounded-b-lg shadow-2xl bg-brand-50">
          The US regulatory landscape is becoming increasingly complex with state and federal laws
          being passed or bills being discussed together with various vertical regulations dealing
          with data protection. One of the landmarks in 2024 is enforcement of the CPRA regulations,
          which following the recent decision by the Third District Court of Appeals have immediate
          effect.
        </div>
      </div>
      <div
        className="fixed top-0 right-0 w-full h-full -z-40"
        style={{
          opacity: 0.3,
          backgroundImage: `radial-gradient(#444cf7 0.5px, transparent 0.5px), radial-gradient(#444cf7 0.5px, transparent 0.5px)`,
          backgroundSize: "20px 20px",
          backgroundPosition: "0 0, 10px 10px",
        }}
      />
    </div>
  );
}
