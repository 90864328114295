// Common keys
declare const REACT_DEV_ENV: boolean;
declare const REACT_BASE_URL: string;
declare const REACT_FLASK_BASE_URL: string;
declare const REACT_API_BASE_URL: string;
declare const STRIPE_PUBLISHABLE_KEY: string;
declare const REACT_TOLGEE_API_KEY: string;
declare const REACT_IS_PROD_DEV: boolean;
declare const CI_COMMIT_SHORT_SHA: string;
declare const SENTRY_DSN_MAIN_APP: string;

export const DEV_ENV = REACT_DEV_ENV;
export const REACT_BASE_ROUTE = REACT_BASE_URL;
export const PRIGHTER_URL = REACT_FLASK_BASE_URL;
export const API_BASE_ROUTE = REACT_API_BASE_URL;
export const STRIPE_KEY = STRIPE_PUBLISHABLE_KEY;
export const TOLGEE_URL = "https://app.tolgee.io";
export const RELEASE = CI_COMMIT_SHORT_SHA;
export const SENTRY_DSN = SENTRY_DSN_MAIN_APP;

// Dev keys
export const TOLGEE_KEY = DEV_ENV && REACT_TOLGEE_API_KEY;

// Dev prod keys
export const IS_PROD_DEV = REACT_IS_PROD_DEV;

// i18n for production
export const TOLGEE_CDN = "https://cdn.tolg.ee/3d43277533467f7a51c66fed54adcc58";

export const CDN = "https://cdn.prighter.com";
export const PRIVACY_POLICY = `${CDN}/legal/Prighter-PrivacyPolicy.pdf`;
export const LEGAL_INFO = `${PRIGHTER_URL}/legal`;
