import { T } from "@tolgee/react";
import ReactMarkdown from "react-markdown";

import { getUrl } from "helpers";
import tolgee from "services/translation";

export default function DoINeedTurkeyRep() {
  return (
    <div className="relative z-40 max-w-xl mx-auto">
      <h2 className="text-2xl text-brand-900">
        <T keyName="dashboard.turkey.title" />
      </h2>
      <div className="mb-4">
        <ReactMarkdown>{tolgee.t("dashboard.turkey.markdownContent")}</ReactMarkdown>
      </div>
      <a
        href={getUrl("FLASK", "/product/turkey-dcr/#FAQ")}
        className="box-border relative z-10 inline-block w-full px-2 py-3 text-sm font-semibold text-center text-white no-underline bg-orange-400 border border-orange-400 border-solid rounded-md shadow cursor-pointer sm:px-4 sm:py-3 lg:text-lg md:self-end hover:bg-white sm:w-full active:bg-white-900 hover:text-orange-400">
        <T keyName="dashboard.turkey.button" />
      </a>
    </div>
  );
}
