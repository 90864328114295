import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { captureMessage } from "@sentry/react";
import clsx from "clsx";

import DSLocation from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/DSLocation";
import DSIdentification from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/DSIdentification";
import ControllerProcessorDecision from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ControllerProcessorDecision";
import AddProcessors from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/AddProcessors";
import ExcludeData from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ExcludeData";
import AccessCategories from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/AccessCategories";
import InformThirdParty from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/InformThirdParty";
import IncorrectSources from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/IncorrectSources";
import CorrectDelete from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/CorrectDelete";
import DSDataUpload from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/DSDataUpload";
import { notify } from "store/app";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { getLatestActiveCheck, getStageChecks, generateI18nKey } from "helpers/case";
import CCPACorrectionExcludeData from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/CCPACorrectionExcludeData";

import type { CheckName } from "types/case";

export default function CheckSteps() {
  const [state, dispatch] = useCase();
  const caseData = state.case;
  const checkStep = state.ui.check;

  const checks = getStageChecks(
    caseData.case_checks,
    state.ui.stage === "HANDLING" ? "HANDLING" : "PRE_CHECK"
  );

  const activeStep = getLatestActiveCheck(checks) || "DS_LOCATION";
  const checkNames: CheckName[] = checks?.map((i) => i.name) || [];

  const completed = (checkName: CheckName): boolean => {
    return !!checks.find((i) => i.name === checkName)?.closed_at;
  };

  const renderActiveStep = () => {
    switch (checkStep) {
      case "DS_LOCATION":
        return <DSLocation />;
      case "DS_IDENTIFICATION":
        return <DSIdentification />;
      case "CONTROLLER_PROCESSOR":
        return <ControllerProcessorDecision />;
      case "PROCESSORS_ADDED":
        return <AddProcessors />;
      case "EXEMPTIONS":
        return caseData.type === "DSR_CCPA_CORRECT" ? (
          <CCPACorrectionExcludeData />
        ) : (
          <ExcludeData />
        );
      case "PRIVACY_POLICY_CATEGORIES":
        return <AccessCategories />;
      case "INFORM_THIRD_PARTY":
        return <InformThirdParty />;
      case "INCORRECT_SOURCES":
        return <IncorrectSources />;
      case "CORRECT_DELETE":
        return <CorrectDelete />;
      case "DS_DATA_UPLOAD":
        return <DSDataUpload />;

      default:
        notify({
          message: tolgee.t({
            key: "generic.smth_went_wrong",
          }),
          type: "ERROR",
        });
        captureMessage(
          `${tolgee.t({
            key: "error.no_check_template",
          })} "${activeStep}"`
        );

        return null;
    }
  };

  return (
    checks && (
      <Box sx={{ width: "100%" }} data-testid="dsr-flow-check-data">
        <Stepper
          nonLinear
          activeStep={checks.findIndex((check) => check.name === checkStep)}
          connector={<></>}
          className="flex justify-between p-1 px-6 border rounded-md shadow-xl border-brand-200 shadow-slate-100 h-fit bg-brand-200/80">
          {checkNames.map((label, ind) => (
            <Step
              key={label}
              completed={completed(label)}
              className={clsx("h-fit py-1 relative z-10 flex items-center rounded-md", {
                "bg-success-100 shadow-lg shadow-success-700/10":
                  checkStep === label && completed(label),
                "bg-white shadow-lg shadow-slate-700/10": checkStep === label && !completed(label),
              })}>
              <div className="relative">
                <div className="hidden md:flex">
                  <StepButton
                    className="!pl-8 !pr-10 !flex"
                    icon={<></>}
                    color="inherit"
                    onClick={() => dispatch({ type: "UPDATE_UI_CHECK", payload: label })}>
                    <div className="flex">
                      <div className={clsx({ "!text-success-700": completed(label) })}>
                        {tolgee.t({
                          key: generateI18nKey(caseData.type, checkNames[ind], "stepTitle"),
                        })}
                      </div>
                      {completed(label) && (
                        <FontAwesomeIcon
                          className="ml-2 text-success"
                          icon="circle-check"
                          size="lg"
                        />
                      )}
                    </div>
                  </StepButton>
                </div>

                {checkStep === label && (
                  <div
                    className={clsx(
                      "h-0 w-0 top-0 right-0 border-y-[12px] translate-x-3 -translate-y-[2px] absolute border-y-transparent border-l-[5px]",
                      completed(label) ? "border-l-success-100" : "border-l-white"
                    )}
                  />
                )}
                <div className="flex md:hidden">
                  <StepButton
                    color="inherit"
                    onClick={() => dispatch({ type: "UPDATE_UI_CHECK", payload: label })}
                  />
                </div>
              </div>
            </Step>
          ))}
        </Stepper>
        <div className="px-6 pt-4">{renderActiveStep()}</div>
      </Box>
    )
  );
}
