import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import ReactMarkdown from "react-markdown";
import { T } from "@tolgee/react";

import PreCheckReviewTag from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReviewTag";
import tolgee from "services/translation";
import { SUCCESS } from "const/color";
import { BaseButton } from "components";
import { getStageChecks } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import { finalizeCheck } from "store/thunks";
import { StageNameEnum } from "types/case";

export default function PreCheckReview() {
  const [state, dispatch] = useCase();
  const caseData = state.case;

  const checks = getStageChecks(caseData.case_checks, "PRE_CHECK");

  return (
    <>
      <div className="grid gap-1 px-6 mb-3">
        <h2 className="my-0 font-bold">
          {tolgee.t({
            key: "dsr.pre_check_review_title",
          })}
        </h2>
        <div className="prose max-w-[100%]">
          <ReactMarkdown>
            {tolgee.t({
              key: "dsr.pre_check_review_description",
            })}
          </ReactMarkdown>
        </div>
      </div>
      <div data-testid="dsr-flow-check-data" className="space-y-2 p-6">
        {checks.map((check) => (
          <PreCheckReviewTag key={check.uuid} check={check} caseData={caseData} />
        ))}
      </div>
      <div className="flex justify-between px-6">
        <BaseButton
          onClick={() => dispatch({ type: "UPDATE_UI_STAGE", payload: StageNameEnum.PRE_CHECK })}>
          <T keyName="generic.back" />
        </BaseButton>

        <Button
          data-testid="dsr_confirmation_preCheckReview"
          disabled={caseData.state === "CLOSED"}
          onClick={() => finalizeCheck()(dispatch, caseData.uuid, "PRE_CHECK_REVIEW")}
          sx={{
            backgroundColor: SUCCESS.DEFAULT,
            "&:hover": { backgroundColor: "#4b904f" },
          }}>
          {tolgee.t({
            key: "dsr.next_step_handling",
          })}
          <FontAwesomeIcon className="ml-2" icon="arrow-right" size="lg" />
        </Button>
      </div>
    </>
  );
}
