import type { SetURLSearchParams } from "react-router";

export const updateUrlParams = (
  params: Record<string, string>,
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams
) => {
  const newParams = new URLSearchParams(searchParams);
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      newParams.set(key, value);
    } else {
      newParams.delete(key);
    }
  });
  setSearchParams(newParams);
};
