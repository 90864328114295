import { T } from "@tolgee/react";

import Snippet from "pages/Client/RepPages/components/Snippet";
import Certificate from "pages/Client/RepPages/components/Certificate";

export default function TurkeyRep() {
  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="turkeyrep-page">
      <h1>
        <T keyName="rep.turkeyrep_title" />
      </h1>
      <Snippet type="turkeyrep" />
      <Certificate type="turkeyrep" />
    </div>
  );
}
