import { Skeleton } from "@mui/material";

import { ArrayWithIndex } from "helpers/general";

export default function DSInfoSkeleton() {
  return (
    <div className="mb-4 box-outerlayout space-y-4">
      <div className="pt-5">
        <Skeleton width="30%" />
      </div>

      <Skeleton width="60%" />

      <Skeleton width="17%" />
      <div className="mb-4">
        <div className="grid gap-x-2 gap-y-2 border border-slate-200 rounded-lg px-3 py-3">
          {ArrayWithIndex(3).map((ind) => (
            <div key={ind}>
              <Skeleton width="50%" />
              <Skeleton width="80%" />
            </div>
          ))}
        </div>
      </div>

      <Skeleton width="24%" />
      <div className="mb-4">
        <div className="grid gap-x-2 gap-y-2 border border-slate-200 rounded-lg px-3 py-3">
          {ArrayWithIndex(3).map((ind) => (
            <div key={ind}>
              <Skeleton width="50%" />
              <Skeleton width="80%" />
            </div>
          ))}
        </div>
      </div>

      <h3 className="mb-1">
        <Skeleton width="30%" />
      </h3>
      <Skeleton variant="rectangular" height={80} />
    </div>
  );
}
