import { jurisdictionDict } from "const";

import type { DSRJurisdictionType } from "types/dsr/main";

type Props = {
  jurisdictionKey: DSRJurisdictionType;
  handleClick: (type: DSRJurisdictionType) => void;
};

export default function JurisdictionButton({ jurisdictionKey, handleClick }: Props) {
  return (
    <button
      onClick={() => handleClick(jurisdictionKey)}
      type="button"
      key={jurisdictionKey}
      className="flex items-center p-3 -m-3 space-x-4 transition duration-150 ease-in-out rounded-lg group hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-400 focus-visible:ring-opacity-50">
      <div className="flex items-center justify-center flex-shrink-0 p-3 text-white duration-150 ease-in-out rounded-lg h-14 w-14 bg-brand-200/10 group-hover:bg-brand-200/20 ">
        <img
          src={jurisdictionDict[jurisdictionKey].jurisdiction_lock_icon_url}
          alt="Jurisdiction logo"
        />
      </div>
      <div className="font-semibold w-fit">{jurisdictionDict[jurisdictionKey].name_of_request}</div>
    </button>
  );
}
