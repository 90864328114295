import { useEffect, useId, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useDispatch } from "react-redux";

import { BaseButton, BaseIconButton } from "components/Button";
import { notify } from "store/app";
import tolgee from "services/translation";

import type { ChangeEvent } from "react";

type Props = {
  className?: string;
  label?: string;
  required?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  value?: { name: string }[];
  onChange?: (files: File[]) => void | boolean;
  disabled?: boolean;
  clearFilesAfterUpload?: boolean;
};

export default function FormFileField(props: Props) {
  const {
    label,
    className,
    required,
    multiple = false,
    fullWidth = false,
    disabled = false,
    value = [],
    onChange = () => {},
    clearFilesAfterUpload = false,
  } = props;
  const dispatch = useDispatch();
  const id = useId();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [files, setFiles] = useState(value as File[]);
  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current) {
      setFiles(value as File[]);
      hasRun.current = true;
    }
  }, [value]);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const validateFiles = (fileArray: File[]) => {
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const ACCEPTED_EXTENSIONS = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/webp",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const unsuitableFileExist = fileArray.findIndex((file) => {
      return file?.size > MAX_FILE_SIZE || !ACCEPTED_EXTENSIONS.includes(file.type);
    });

    if (unsuitableFileExist !== -1) {
      dispatch(
        notify({
          message: tolgee.t({
            key: "generic.file_size_extension_not_suitable",
          }),
          type: "ERROR",
        })
      );
      return false;
    }
    return true;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files);

    if (multiple) {
      const tempFiles = [...files, ...selectedFiles];
      const result = validateFiles(tempFiles);
      if (!result) {
        return;
      }
      const res = onChange(tempFiles);
      if (res === true || res === undefined) {
        setFiles(tempFiles);
      }
    } else {
      const result = validateFiles([selectedFiles[0]]);
      if (!result) {
        return;
      }
      const res = onChange([selectedFiles[0]]);
      if (res === true || res === undefined) {
        setFiles([selectedFiles[0]]);
      }
    }
  };

  const handleRemove = (file: File) => {
    const tempFiles = files.filter((oldFile) => oldFile !== file);
    onChange(tempFiles);
    setFiles(tempFiles);
  };

  const clearFiles = () => {
    if (clearFilesAfterUpload) {
      setFiles([]);
      onChange([]);

      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = "";
      }
    }
  };

  return (
    <div className="flex flex-col items-end space-y-4">
      <div className={clsx({ "w-full": fullWidth }, "flex flex-row items-center space-x-2")}>
        <div
          className={clsx(
            { "w-full": fullWidth },
            className,
            "w-fit border-2 rounded-2xl border-dashed border-prighterblue flex flex-col items-center p-4 min-w-[22rem]"
          )}>
          <BaseButton variant="outlined" onClick={handleClick} disabled={disabled}>
            {label} {required && "*"}
          </BaseButton>

          <input
            type="file"
            ref={hiddenFileInput}
            className="hidden"
            multiple={multiple}
            onChange={(event) => {
              handleChange(event);
              clearFiles();
            }}
          />

          {multiple ? (
            <label
              htmlFor={id}
              className={clsx(
                {
                  "!text-gray-400": disabled,
                },
                "flex flex-col items-start space-y-2 p-2 pl-1 pb-1 text-gray-600"
              )}>
              {files.map((file) => (
                <div key={file.name} className="flex flex-row items-center">
                  <div className="border-2 border-dashed rounded-full border-prighterblue py-2 px-4 mr-1">
                    {file.name}
                  </div>
                  <BaseIconButton
                    disabled={disabled}
                    onClick={() => handleRemove(file)}
                    className="w-5 h-5"
                    size="medium">
                    <FontAwesomeIcon size="xs" icon="xmark" color="danger" />
                  </BaseIconButton>
                </div>
              ))}
              {value.length === 0 && `No file${multiple ? "s" : ""} selected`}
            </label>
          ) : (
            <label
              htmlFor={id}
              className="flex flex-col items-start space-y-2 p-2 pl-1 pb-1 text-gray-600">
              {value.length === 0 ? `No file${multiple ? "s" : ""} selected` : value[0].name}
            </label>
          )}
        </div>
      </div>
    </div>
  );
}
