import { Skeleton } from "@mui/material";

import { ArrayWithIndex } from "helpers/general";

function SkeletonNavBar() {
  return (
    <div className="max-w-sm">
      <div className="flex flex-col space-y-2 mt-1">
        {ArrayWithIndex(4).map((ind) => (
          <div key={ind} className="pt-4">
            <Skeleton variant="rectangular" width={Math.random() * 100 + 80} height={20} />
            <div className="space-y-2 mt-4">
              {ArrayWithIndex(Math.floor(Math.random() * 5 + 5)).map((ind2) => (
                <div key={ind2} className="space-y-2">
                  <Skeleton variant="rectangular" width={Math.random() * 90 + 180} height={20} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SkeletonNavBar;
