import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { landingPageUrl } from "const";

import type { DSRBusiness } from "types/dsr";

export default function BusinessHeader(props: { businessState: DSRBusiness }) {
  const { businessState } = props;

  return (
    <div className="relative z-30 flex flex-row justify-between">
      <div className="md:flex ">
        <div className="flex flex-col">
          <div className="grid items-baseline text-2xl font-bold text-brand-900">
            <a
              className="flex items-center space-x-2 text-brand-900"
              href={landingPageUrl(businessState.public_id)}>
              <span>{businessState.company_name}</span>
              <FontAwesomeIcon
                icon="external-link-alt"
                style={{ width: "14px", height: "14px", opacity: 0.5 }}
              />
            </a>
          </div>
          <span className="mt-0 text-lg text-gray-800 whitespace-pre-line">
            {businessState?.address}
          </span>
        </div>
      </div>
    </div>
  );
}
