import DoINeedChinaRep from "pages/Client/Dashboard/DoINeedModals/DoINeedChinaRep";
import DoINeedGdprRep from "pages/Client/Dashboard/DoINeedModals/DoINeedGdprRep";
import DoINeedUkRep from "pages/Client/Dashboard/DoINeedModals/DoINeedUkRep";
import DoINeedDsaRep from "pages/Client/Dashboard/DoINeedModals/DoINeedDsaRep";
import DoINeedNis from "pages/Client/Dashboard/DoINeedModals/DoINeedNis";
import DoINeedTurkeyRep from "pages/Client/Dashboard/DoINeedModals/DoINeedTurkeyRep";
import DoINeedSwissRep from "pages/Client/Dashboard/DoINeedModals/DoINeedSwissRep";
import { getUrl } from "helpers";
import { REACT_BASE_ROUTE } from "const/env";
import GdprRep from "assets/products/gdpr-rep.png";
import UkRep from "assets/products/uk-rep.png";
import NisEuRep from "assets/products/niseu-rep.png";
import NisUkRep from "assets/products/nisuk-rep.png";
import TurkeyRep from "assets/products/turkey-rep.png";
import ChinaRep from "assets/products/china-rep.png";
import SwissRep from "assets/products/swiss-rep.png";
import PrighterBreach from "assets/products/breach.png";
import DsrArchive from "assets/products/dsr-archive.png";
import CaliforniaRep from "assets/products/california-rep.png";

import type { DsrCustomizationReplyType } from "types/dsr/main";
import type { DSRJurisdictionDict } from "types/dsr";
import type { Product, ProductKey } from "types/company";

export const jurisdictionDict: DSRJurisdictionDict = {
  dsr_uk: {
    name_of_request: "UK related request",
    jurisdiction_lock_icon_url: UkRep,
    dsr_type: "dsr_uk",
    short_name: "UK",
  },
  dsr_cal: {
    name_of_request: "California related request",
    jurisdiction_lock_icon_url: CaliforniaRep,
    dsr_type: "dsr_cal",
    short_name: "California",
  },
  dsr_eu: {
    name_of_request: "EU related request",
    jurisdiction_lock_icon_url: GdprRep,
    dsr_type: "dsr_eu",
    short_name: "EU",
  },
  dsr_fadp: {
    name_of_request: "Switzerland related request",
    jurisdiction_lock_icon_url: SwissRep,
    dsr_type: "dsr_fadp",
    short_name: "CH",
  },
};

export const replyTypeDict = {
  TEXTFIELD: "Text Field",
  TEXTAREA: "Text Area",
  FILEUPLOAD: "File Upload",
  NO_REPLY: "No Reply",
};

export const getReplyType = (key: DsrCustomizationReplyType): string => {
  return key in replyTypeDict ? replyTypeDict[key] : "No Reply";
};

export const getReversedReplyType = (key: string): DsrCustomizationReplyType => {
  const dict: { [key: string]: DsrCustomizationReplyType } = {
    "Text Field": "TEXTFIELD",
    "Text Area": "TEXTAREA",
    "File Upload": "FILEUPLOAD",
    "No Reply": "NO_REPLY",
  };

  return key in dict ? dict[key] : "NO_REPLY";
};

export const productKeys: ProductKey[] = [
  "art27",
  "ukrep",
  "niseu",
  "nisuk",
  "turkeyrep",
  "chinarep",
  "swissrep",
];

export const productDict: { [key in ProductKey]: Product } = {
  art27: {
    name: "EU GDPR Rep",
    image: GdprRep,
    productUrl: (businessId: string) => `/business/${businessId}/eu-rep`,
    doINeedModal: <DoINeedGdprRep />,
    verifyUrl: (businessId: string) => getUrl("FLASK", `/verify/${businessId}`),
    certificateUrl: (businessId: string) =>
      getUrl(
        "API",
        `/v1/business/certificate-of-representation?business_id=${businessId}&certificate_product=ART27`
      ),
  },
  dsa: {
    name: "DSA Rep",
    image: GdprRep,
    productUrl: (businessId: string) => `/business/${businessId}/dsa-rep`,
    doINeedModal: <DoINeedDsaRep />,
    verifyUrl: (businessId: string) => getUrl("FLASK", `/dsa/verify/${businessId}`),
    certificateUrl: (businessId: string) =>
      getUrl(
        "API",
        `/v1/business/certificate-of-representation?business_id=${businessId}&certificate_product=DSA`
      ),
  },
  ukrep: {
    name: "UK GDPR Rep",
    image: UkRep,
    productUrl: (businessId: string) => `/business/${businessId}/uk-rep`,
    doINeedModal: <DoINeedUkRep />,
    verifyUrl: (businessId: string) => getUrl("FLASK", `/ukrep/verify/${businessId}`),
    certificateUrl: (businessId: string) =>
      getUrl(
        "API",
        `/v1/business/certificate-of-representation?business_id=${businessId}&certificate_product=UKREP`
      ),
  },
  niseu: {
    name: "EU NIS Rep",
    image: NisEuRep,
    productUrl: (businessId: string) => `/business/${businessId}/nis`,
    doINeedModal: <DoINeedNis />,
    verifyUrl: (businessId: string) => getUrl("FLASK", `/niseu/verify/${businessId}`),
    certificateUrl: (businessId: string) =>
      getUrl(
        "API",
        `/v1/business/certificate-of-representation?business_id=${businessId}&certificate_product=NISEU`
      ),
  },
  nisuk: {
    name: "UK NIS Rep",
    image: NisUkRep,
    productUrl: (businessId: string) => `/business/${businessId}/nis?open=nisuk`,
    doINeedModal: <DoINeedNis />,
    verifyUrl: (businessId: string) => getUrl("FLASK", `/nisuk/verify/${businessId}`),
    certificateUrl: (businessId: string) =>
      getUrl(
        "API",
        `/v1/business/certificate-of-representation?business_id=${businessId}&certificate_product=NISUK`
      ),
  },
  turkeyrep: {
    name: "Turkey KVKK Rep",
    image: TurkeyRep,
    productUrl: (businessId: string) => `/business/${businessId}/tr-rep`,
    doINeedModal: <DoINeedTurkeyRep />,
    verifyUrl: (businessId: string) => getUrl("FLASK", `/turkey-dcr/verify/${businessId}`),
    certificateUrl: (businessId: string) =>
      getUrl(
        "API",
        `/v1/business/certificate-of-representation?business_id=${businessId}&certificate_product=TURKEYREP`
      ),
  },
  chinarep: {
    name: "China PIPL Rep",
    image: ChinaRep,
    productUrl: () => "",
    doINeedModal: <DoINeedChinaRep />,
    verifyUrl: () => "",
    certificateUrl: (businessId: string) =>
      getUrl(
        "API",
        `/v1/business/certificate-of-representation?business_id=${businessId}&certificate_product=CHINAREP`
      ),
  },
  swissrep: {
    name: "Swiss DSG REP",
    image: SwissRep,
    productUrl: (businessId: string) => `/business/${businessId}/ch-rep`,
    doINeedModal: <DoINeedSwissRep />,
    verifyUrl: (businessId: string) => getUrl("FLASK", `/swiss-rep/verify/${businessId}`),
    certificateUrl: (businessId: string) =>
      getUrl(
        "API",
        `/v1/business/certificate-of-representation?business_id=${businessId}&certificate_product=SWISSREP`
      ),
  },
  prighterbreach: {
    name: "PrighterBreach",
    image: PrighterBreach,
    productUrl: null,
    doINeedModal: null,
    verifyUrl: () => "",
    certificateUrl: () => "",
  },
  "dsr-archival": {
    name: "PrighterDSR Archival",
    image: DsrArchive,
    productUrl: null,
    doINeedModal: null,
    verifyUrl: () => "",
    certificateUrl: () => "",
  },
};

export const allLang = [
  "bg",
  "cs",
  "da",
  "de",
  "el",
  "en",
  "es",
  "et",
  "fi",
  "fr",
  "hu",
  "it",
  "ja",
  "it",
  "lv",
  "nl",
  "pl",
  "pt",
  "ro",
  "ru",
  "sk",
  "sl",
  "sv",
  "zh",
];

export const landingPageUrl = (businessIdOrSlug: string) =>
  `${REACT_BASE_ROUTE}/portal/${businessIdOrSlug}`;
