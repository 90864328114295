import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import type { ReactNode } from "react";

type Props = {
  className?: string;
  title?: ReactNode;
  children: ReactNode;
  defaultCollapsed?: boolean;
};

export default function CollapsibleInnerBox({
  className,
  title,
  children,
  defaultCollapsed = false,
}: Props) {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  return (
    <div className={clsx(className, "box-innerlayout")}>
      <div className="flex flex-row justify-between items-center">
        {title || <></>}
        <IconButton className="w-12 h-12" onClick={() => setCollapsed((prev) => !prev)}>
          <FontAwesomeIcon icon={collapsed ? "arrow-up" : "arrow-down"} />
        </IconButton>
      </div>
      {!collapsed && children}
    </div>
  );
}
