import api from "services/api/base";
import { entityDictionary, infoDictionary } from "const/ropa/dictionaries";

import type { RopaApiEntityType, RopaEntityType } from "types/ropaTool";

const ROPA_URL = "http://127.0.0.1:31231";

const getLinkRequestUrl = (
  targetType: RopaApiEntityType,
  businessId: string,
  processId: string,
  targetId?: string
): string => {
  return `/businesses/${businessId}/processes/${processId}/${targetType}${
    targetId ? `/${targetId}` : ""
  }`;
};

export async function getAll(type: RopaEntityType, businessId: string): Promise<any> {
  const response = await api.get(entityDictionary(type, businessId), {
    baseURL: ROPA_URL,
  });

  if (Array.isArray(response.data)) {
    return response.data;
  }
  return [];
}

export async function get(
  type: RopaEntityType,
  businessId: string | number,
  id: string | number
): Promise<any> {
  const url = infoDictionary(type, id, businessId);
  if (!url) {
    return null;
  }

  const response = await api.get(url, {
    baseURL: ROPA_URL,
  });

  return response.data;
}

export async function create(type: RopaEntityType, businessId: string, body: any): Promise<any> {
  const response = await api.post(entityDictionary(type, businessId), body, {
    baseURL: ROPA_URL,
  });
  return response?.data;
}

export async function update(
  type: RopaEntityType,
  businessId: string,
  targetId: string,
  body: any
): Promise<boolean> {
  const url = infoDictionary(type, targetId, businessId);
  if (!url) {
    return null;
  }

  const res = await api.patch(url, body, {
    baseURL: ROPA_URL,
  });
  return res.status >= 200 && res.status < 300;
}

export async function deleteResource(
  type: RopaEntityType,
  businessId: string,
  targetId: any
): Promise<boolean> {
  const res = await api.delete(`${entityDictionary(type, businessId)}/${targetId}`, {
    baseURL: ROPA_URL,
  });
  return res.status >= 200 && res.status < 300;
}

export async function getUnlinked(
  targetType: RopaApiEntityType,
  businessId: string,
  processId: string
): Promise<any> {
  const res = await api.get(`${getLinkRequestUrl(targetType, businessId, processId)}/notlinked`, {
    baseURL: ROPA_URL,
  });
  if (Array.isArray(res.data)) {
    return res.data;
  }
  return [];
}

async function getLinked(
  targetType: RopaApiEntityType,
  businessId: string,
  processId: string
): Promise<any> {
  const res = await api.get(getLinkRequestUrl(targetType, businessId, processId), {
    baseURL: ROPA_URL,
  });

  if (Array.isArray(res.data)) {
    return res.data;
  }
  return [];
}

export async function getAllLinked(businessId: string, processId: string): Promise<any> {
  const entitiesExceptProcessor: RopaApiEntityType[] = [
    "affectedgroups",
    "datacategories",
    "controllers",
    "recipients/internal",
    "recipients/external",
    "recipients/processor",
  ];

  const response: any = await Promise.all(
    entitiesExceptProcessor.map((type) =>
      getLinked(type, businessId, processId).then((res) => {
        if (res.length > 0) {
          return res.map((element: any) => {
            return { ...element, type }; // should be entityDictionary[type] instead of type
          });
        }
        return [];
      })
    )
  );
  const data = [].concat(...response);
  return data;
}

export async function getAllLinkedProcesses(
  type: RopaEntityType,
  businessId: string | number,
  id: string | number
): Promise<any> {
  const url = infoDictionary(type as any, id, businessId);
  if (!url) {
    return [];
  }

  const res = await api.get(`${url}/processes`, {
    baseURL: ROPA_URL,
  });

  if (Array.isArray(res.data)) {
    return res.data;
  }
  return [];
}

export async function link(
  targetType: RopaApiEntityType,
  businessId: string,
  processId: string,
  targetId: string,
  body: any
): Promise<any> {
  const response = await api.post(
    getLinkRequestUrl(targetType, businessId, processId, targetId),
    {
      business_id: businessId,
      process_id: processId,
      targetType: targetId,
      ...body,
    },
    {
      baseURL: ROPA_URL,
    }
  );
  return response.data;
}

export async function unlink(
  targetType: RopaApiEntityType,
  businessId: string,
  processId: string,
  targetId: string
): Promise<any> {
  const response = await api.delete(
    getLinkRequestUrl(targetType, businessId, processId, targetId),
    {
      baseURL: ROPA_URL,
    }
  );
  return response.data;
}

export async function getDescriptions(): Promise<any> {
  const response = await api.get("/gui/descriptionhelper", {
    baseURL: ROPA_URL,
  });
  return response.data;
}

export async function getBusinessIds(): Promise<any> {
  const response = await api.get("/businesses_with_access", {
    baseURL: ROPA_URL,
  });

  if (Array.isArray(response)) {
    return response.data;
  }
  return [];
}
