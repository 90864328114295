import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

export default function ServerError() {
  return (
    <div
      className="flex items-center  h-screen justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
      data-testid="error-element">
      <div className="max-w-md w-full ">
        <div>
          <div className="text-center">
            <h1>
              <T keyName="generic.unexpected_error" />
            </h1>
            <FontAwesomeIcon icon="spinner" color="gray" className="fa-pulse mb-4" size="2xl" />
            <br />
            <T keyName="generic.click_refresh" />
            <p className="font-italic">
              <T keyName="generic.contact_support" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
