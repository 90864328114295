import { Checkbox, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { captureException } from "@sentry/react";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";

import { BaseSelectField, FormFileField } from "components";
import { validateEmail } from "helpers/validate";
import tolgee from "services/translation";
import AddressForm from "components/complex/AddressForm";

import type { FadpForm } from "types/dsr/fadp";

export default function IdentificationForm() {
  const [sameEmail, setSameEmail] = useState(true);

  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<FadpForm>();

  const acting_for = watch("actors.0.acting_for");
  const files = watch("files");
  const ds_address = watch("actors.0.address");
  const email = watch("actors.0.email");
  const identifiableInfoEmail = watch("identifiable_info.email");

  useEffect(() => {
    if (sameEmail) {
      setValue("identifiable_info.email", email);
    }
  }, [email, sameEmail, setValue]);

  register("actors.0.acting_for", {
    validate: {
      minLength: (e) => `${e}`.trim().length > 0 || "Please choose something.",
    },
  });
  register("actors.0.address.postal", {
    validate: {
      minLength: (e) => `${e}`.trim().length > 0,
    },
  });
  register("actors.0.address.city", {
    validate: {
      minLength: (e) => `${e}`.trim().length > 0,
    },
  });
  register("actors.0.address.street", {
    validate: {
      minLength: (e) => `${e}`.trim().length > 0,
    },
  });
  register("actors.0.address.country_iso", {
    validate: {
      minLength: (e) => !!e && `${e}`.trim().length > 0,
    },
  });

  const handleUploadFiles = (fileList: File[]) => {
    const fileArray = Array.from(fileList);

    const fadpFileArray = fileArray.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64String = reader.result;
          resolve(base64String);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    });

    Promise.all(fadpFileArray)
      .then((resolvedFileArray: any[]) => {
        setValue(
          "files",
          resolvedFileArray.map((f, ind) => ({ name: fileArray[ind].name, content: f }))
        );
      })
      .catch((error) => {
        captureException(error);
      });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSameEmail(event.target.checked);
    if (event.target.checked) {
      setValue("identifiable_info.email", email);
    } else {
      setValue("identifiable_info.email", "");
    }
  };

  return (
    <div>
      <h2>
        <T keyName="fadp.requestor" />:
      </h2>
      <div className="flex flex-col space-y-4 mb-8">
        <BaseSelectField
          error={!!errors?.actors && !!errors?.actors[0]?.acting_for}
          required
          state={[acting_for, (val: string) => setValue("actors.0.acting_for", val)]}
          label={tolgee.t({
            key: "fadp.ds_info.acting_for",
          })}
          options={{
            MYSELF: tolgee.t({
              key: "fadp.ds_info.acting_for.myself",
            }),
            AS_PARENT: tolgee.t({
              key: "fadp.ds_info.acting_for.as_parent",
            }),
            AS_GUARDIAN: tolgee.t({
              key: "fadp.ds_info.acting_for.as_guardian",
            }),
            AS_LEGAL_REPRESENTATIVE: tolgee.t({
              key: "fadp.ds_info.acting_for.as_legal_representative",
            }),
            OTHER: tolgee.t({
              key: "fadp.ds_info.acting_for.other",
            }),
          }}
        />
        {!!errors?.actors && errors?.actors[0]?.acting_for && (
          <span className="text-danger pl-4 text-sm">
            <T keyName="fadp.error_choose_acting_for" />
          </span>
        )}

        {acting_for !== "MYSELF" && (
          <>
            <TextField
              label={tolgee.t({
                key: "generic.name",
              })}
              {...register("actors.0.name", {
                validate: {
                  minLength: (e) =>
                    `${e}`.length >= 4 || "Minimum length of your name should be 4.",
                },
              })}
              error={!!errors?.actors && !!errors?.actors[0]?.name}
              helperText={!!errors?.actors && errors?.actors[0]?.name?.message}
              required
            />
            <TextField
              label={tolgee.t({
                key: "generic.email",
              })}
              {...register("actors.0.email", {
                validate: {
                  minLength: (e) =>
                    `${e}`.length >= 4 || "Minimum length of your email should be 4.",
                  validEmail: (e) => validateEmail(e) || "You need to add a valid email.",
                },
              })}
              error={!!errors?.actors && !!errors?.actors[0]?.email}
              helperText={!!errors?.actors && errors?.actors[0]?.email?.message}
              required
            />

            <div>
              <span className="font-bold">
                <T keyName="ccpa.ds_info.necessary_files_for_identification" />:
              </span>
              <FormFileField
                label="Upload a file"
                className="mt-2"
                value={files}
                onChange={handleUploadFiles}
                multiple
                fullWidth
              />
            </div>
          </>
        )}
      </div>
      <h2 className="mt-8">
        <T keyName="dsr.data_subject" />:
      </h2>
      <div className="flex flex-col space-y-4 mb-8">
        <TextField
          label={tolgee.t({
            key: "generic.name",
          })}
          {...register("identifiable_info.username")}
        />
        <Controller
          name="identifiable_info.email"
          control={control}
          defaultValue={identifiableInfoEmail}
          render={({ field: { value, ref, ...field } }: any) => (
            <TextField
              {...field}
              inputRef={ref}
              value={value}
              onChange={(e) => {
                setValue("identifiable_info.email", e.target.value);
                if (e.target.value === email) {
                  setSameEmail(true);
                } else {
                  setSameEmail(false);
                }
              }}
              label={tolgee.t({
                key: "generic.email",
              })}
              InputProps={
                acting_for !== "MYSELF" && {
                  startAdornment: (
                    <Checkbox
                      checked={sameEmail}
                      style={{ padding: 0, margin: 0, marginRight: 2 }}
                      onChange={handleCheckboxChange}
                    />
                  ),
                }
              }
              error={!!errors?.identifiable_info?.email}
              helperText={errors?.identifiable_info?.email?.message}
            />
          )}
        />
      </div>
      <div className="flex flex-col space-y-3">
        <AddressForm
          contactAddresses={[ds_address]}
          onChange={(val: any) => setValue("actors.0.address", val[0])}
          required
          shrink
        />

        {!!errors?.actors && errors?.actors[0]?.address && (
          <span className="text-danger pl-4 text-sm">
            <T keyName="fadp.error_provide_valid_address" />
          </span>
        )}
      </div>
      {acting_for === "MYSELF" && (
        <div>
          <span className="font-bold">
            <T keyName="ccpa.ds_info.necessary_files_for_identification" />:
          </span>
          <FormFileField
            label="Upload a file"
            className="mt-2"
            value={files}
            onChange={handleUploadFiles}
            multiple
            fullWidth
          />
        </div>
      )}
    </div>
  );
}
