import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";

import CaseHistory from "pages/Client/History/CaseHistory";
import tolgee from "services/translation";

export default function DSRHistory() {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="box-outerlayout">
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{
          backgroundColor: "white",
          border: "none",
          boxShadow: "none",
          borderRadius: 0,
          padding: 0,
        }}
        className="w-full p-2"
        data-testid="case-history">
        <AccordionSummary
          expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}
          aria-controls="panel1a-content"
          sx={{ padding: "0" }}
          id="panel1a-header">
          <h2>
            {tolgee.t({
              key: "dsr.dsr_history",
            })}
          </h2>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <CaseHistory />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
