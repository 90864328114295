import { Step, StepLabel, Stepper } from "@mui/material";

type Props = {
  activeStep: number;
};
const steps = ["Where", "What", "Who", "When", "Info"];

function EmailCasesStepper(props: Props) {
  const { activeStep } = props;
  return (
    <Stepper
      activeStep={activeStep + 1}
      alternativeLabel
      sx={{
        "& .MuiStepConnector-line": {
          borderWidth: "1px",
          borderColor: "#848484",
        },
        "& .Mui-completed .MuiStepConnector-line": {
          borderColor: "white",
        },
      }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            sx={{
              "& .MuiStepIcon-root": {
                cursor: "pointer",
                color: "white",
                opacity: 0.5,
              },

              "& .MuiStepIcon-root:hover": {
                color: "white",
              },

              "& .MuiStepLabel-label": {
                color: "#EBEBEB",
                opacity: 0.5,
              },
              "& .Mui-active": {
                color: "white !important",
              },
              "& .Mui-completed": {
                color: "white !important",
                opacity: 1,
              },
            }}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default EmailCasesStepper;
