import { useCallback, useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { T } from "@tolgee/react";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router";

import { useDebounce } from "helpers/hooks";
import { api } from "services";
import tolgee from "services/translation";
import { dataGridConfig } from "helpers/dataGridConfig";
import { BaseIconButton, LinkButton, SearchField } from "components";
import { getBeautyDate, getUrl } from "helpers";
import { DANGER, PRIGHTER_BLUE, SUCCESS } from "const/color";
import { useAppSelector, useAppDispatch } from "store";
import { getTimeDifferenceString } from "helpers/date";
import { notify, changePreference } from "store/app";
import { DEV_ENV } from "const/env";
import { handleError } from "services/api/error";
import { UserFilter, UserRole, UserTagEnum } from "types/user";
import { updateUrlParams } from "helpers/url";

import type { GridRenderCellParams } from "@mui/x-data-grid";
import type { GetUsersRequest, ManagedUser } from "types/user";

const allowedUserRoles = [UserRole.ADMIN, UserRole.MANAGER_GHOSTLOGIN];
const allowedClientRoles = [UserRole.CUSTOMER, UserRole.PARTNER];

type FetchDataFn = (props: GetUsersRequest) => Promise<void>;
type DeleteSpamUserFn = (id: string) => Promise<void>;
type RenderActionButtonsCellFn = (params: GridRenderCellParams<ManagedUser>) => React.ReactElement;
type GetColumnsFn = () => Array<{
  flex: number;
  field: string;
  headerName: string;
  renderCell?: (params: any) => React.ReactElement;
  sortable?: boolean;
}>;

export default function ManageUsers() {
  const { manageUsersRows = 25 } = useAppSelector((state) => state.app.preferences);
  const dispatch = useAppDispatch();
  const [editableNote, setEditableNote] = useState<string>();
  const { register, getValues } = useForm();
  const { countries } = useAppSelector((state) => state.app);
  const { roles } = useAppSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const debouncedSearchValue = useDebounce<string>(search, 500);
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 0);
  const [orderedBy, setOrderedBy] = useState(searchParams.get("orderBy") || "created_at");
  const [newOrder, setNewOrder] = useState(searchParams.get("order") || "desc");
  const [filter, setFilter] = useState<UserFilter>(
    (searchParams.get("filter") as UserFilter) ||
      (DEV_ENV ? UserFilter.INTERNAL : UserFilter.ACTIVE_CUSTOMER)
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const getColumnsRef = useRef<GetColumnsFn | null>(null);

  const handleUrlUpdate = useCallback(
    (params: Record<string, string>) => {
      updateUrlParams(params, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleFilter = (_: React.MouseEvent<HTMLElement>, newFilter: UserFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
      handleUrlUpdate({ filter: newFilter });
    }
  };

  const addNote = useCallback(
    async (uuid: string) => {
      const note = { note: getValues(`answers.${uuid}.answer`) };

      try {
        await api.user.setNote(uuid, note);
      } catch (e) {
        handleError(e);
        return;
      }

      dispatch(
        notify({
          message: tolgee.t({
            key: "users.note_added",
          }),
          type: "SUCCESS",
        })
      );
      setEditableNote("");
    },
    [dispatch, getValues]
  );

  const handleSendOpenInvoices = useCallback(
    async (uuid: string) => {
      try {
        await api.invoice.getSendOpenInvoices(uuid);
      } catch (e) {
        handleError(e);
        return;
      }

      dispatch(
        notify({
          message: tolgee.t({
            key: "generic.open_invoices_sent",
          }),
          type: "SUCCESS",
        })
      );
    },
    [dispatch]
  );

  const fetchUsersList = (params: GetUsersRequest) => {
    const { order_by, page, per_page, order, search_term, user_filter } = params;
    const desc = order === "desc";
    return api.user.list({
      order_by,
      page: page + 1,
      per_page,
      desc,
      search_term,
      user_filter,
    });
  };

  const renderCreatedAtCell = (params: any) => (
    <Tooltip
      title={<div>{getTimeDifferenceString(params?.row?.created_at)} ago</div>}
      placement="top">
      <div>{getBeautyDate(params?.row?.created_at)}</div>
    </Tooltip>
  );

  const renderCompanyNameCell = useCallback(
    (params: any) => (
      <div className="flex flex-col space-y-2">
        <div className="flex space-x-2 items-center">
          <div>{params?.row?.company_name}</div>
          <IconButton
            data-testid="add-address-button"
            onClick={() => setEditableNote(params?.row?.uuid)}
            size="small">
            <FontAwesomeIcon size="lg" icon="edit" color={PRIGHTER_BLUE} />
          </IconButton>
        </div>
        {params?.row?.note && editableNote !== params?.row?.uuid && (
          <div className="italic">{params?.row?.note}</div>
        )}
        {editableNote === params?.row?.uuid && (
          <div className="pb-4 flex space-x-2">
            <TextField
              className="w-full"
              label={tolgee.t({ key: "users.note" })}
              onKeyDown={(event) => event.stopPropagation()}
              {...register(`answers.${params?.row?.id}.answer` as const)}
            />
            <div className="flex flex-col space-y-2">
              <IconButton data-testid="check" onClick={() => addNote(params?.row?.id)} size="small">
                <FontAwesomeIcon size="lg" icon="check" color={SUCCESS.DEFAULT} />
              </IconButton>
              <IconButton data-testid="xmark" onClick={() => setEditableNote("")} size="small">
                <FontAwesomeIcon size="lg" icon="xmark" color={DANGER.DEFAULT} />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    ),
    [editableNote, addNote, register]
  );

  const renderPublicIdsCell = (params: any) => (
    <div className="flex flex-wrap">
      {params?.row?.public_ids?.map((i: number, ind: number) => (
        <div key={i} className="flex mr-1">
          <Link key={i} to={`/manage/business/${i}`} className="font-bold">
            <div>{i}</div>
          </Link>
          {params?.row?.public_ids?.length > ind + 1 && ","}
        </div>
      ))}
    </div>
  );

  const renderProductsCell = (params: any) => (
    <div className="flex space-x-2">
      {params?.row?.product_icons.map((i: any) => <div key={i}>{i}</div>)}
    </div>
  );

  const renderCountryCell = useCallback(
    (params: any) => {
      const country = countries?.find(
        (i) => i.iso_3166_1_alpha_2 === params?.row?.iso_3166_1_alpha_2
      );
      return (
        <div className="flex space-x-2 items-center">
          <div>{params?.row?.iso_3166_1_alpha_2}</div>
          <div>{country?.flag}</div>
        </div>
      );
    },
    [countries]
  );

  const renderRoleCell = (params: any) => {
    const { light, role_names, internal: internalUser, tags } = params.row;
    let type;
    if (internalUser) {
      type = "";
    } else if (light) {
      type = "L";
    } else if (role_names.includes("PARTNER")) {
      type = "P";
    } else {
      type = "C";
    }
    return (
      <div className="flex flex-row items-center gap-2">
        <div>{type}</div>
        {tags.includes(UserTagEnum.TRUST) && <FontAwesomeIcon icon="handshake" />}
      </div>
    );
  };

  const fetchData: FetchDataFn = useCallback(
    async (props) => {
      setLoading(true);
      let res;
      try {
        res = await fetchUsersList(props);
      } catch (e) {
        handleError(e);
        setLoading(false);
        return;
      }
      const { count, result } = res.data;

      const tableData = {
        ...dataGridConfig({ currentPage, rowsPerPage: manageUsersRows, count }),
        onSortModelChange: (sortVal: any) => {
          if (sortVal?.length) {
            const { field } = sortVal[0];
            const { sort } = sortVal[0];
            setOrderedBy(field);
            setNewOrder(sort);
          }
        },
        onPaginationModelChange: (val: any) => {
          setCurrentPage(val.page);
          dispatch(changePreference({ manageUsersRows: val.pageSize }));
        },
        columns: getColumnsRef.current?.() || [],
        rows: result?.map((i: any) => ({ ...i, id: i?.uuid })) || [],
      };

      setData(tableData);
      setLoading(false);
    },
    [currentPage, manageUsersRows, dispatch]
  );

  const deleteSpamUser: DeleteSpamUserFn = useCallback(
    async (id) => {
      try {
        await api.user.deleteSpam(id);
        await fetchData({
          order_by: orderedBy,
          page: currentPage,
          per_page: manageUsersRows,
          order: newOrder,
          search_term: debouncedSearchValue,
          user_filter: filter,
        });
      } catch (err) {
        handleError(err);
      }
    },
    [fetchData, orderedBy, currentPage, manageUsersRows, newOrder, debouncedSearchValue, filter]
  );

  const renderActionButtonsCell: RenderActionButtonsCellFn = useCallback(
    (params) => (
      <div className="space-x-2 flex">
        {filter === UserFilter.NEW_NO_BUSINESS && (
          <IconButton onClick={() => deleteSpamUser(params.id as string)} size="small">
            <FontAwesomeIcon icon="trash" color={PRIGHTER_BLUE} />
          </IconButton>
        )}
        <LinkButton
          color="NONE"
          href={getUrl("FLASK", `/administration/customer/${params.id}/invoices`)}>
          <div className="w-3 h-3 flex items-center justify-center">
            <FontAwesomeIcon size="lg" icon="file-invoice-dollar" color={PRIGHTER_BLUE} />
          </div>
        </LinkButton>
        {roles.some((role) => allowedUserRoles.includes(role.name)) &&
          (params.row.demo ||
            params.row.role_names.some((role) => allowedClientRoles.includes(role))) && (
            <LinkButton
              color="NONE"
              href={getUrl("FLASK", `/administration/customer/${params.id}/ghostlogin`)}>
              <div className="w-3 h-3 flex items-center justify-center">
                <FontAwesomeIcon size="lg" icon="ghost" color={PRIGHTER_BLUE} />
              </div>
            </LinkButton>
          )}
        <LinkButton
          color="NONE"
          href={getUrl("FLASK", `/administration/manage_customer/${params.id}`)}>
          <div className="w-3 h-3 flex items-center justify-center">
            <FontAwesomeIcon size="lg" icon="user" color={PRIGHTER_BLUE} />
          </div>
        </LinkButton>
        {roles.some((role) =>
          ["ADMIN", "DEV", "MANAGER_FINANCIALREPORTING"].includes(role.name)
        ) && (
          <IconButton onClick={() => handleSendOpenInvoices(params.id as string)} size="small">
            <FontAwesomeIcon icon="envelopes-bulk" color={PRIGHTER_BLUE} />
          </IconButton>
        )}
      </div>
    ),
    [filter, roles, handleSendOpenInvoices, deleteSpamUser]
  );

  const getColumns = useCallback(
    () => [
      {
        flex: 0.75,
        field: "created_at",
        headerName: tolgee.t({ key: "generic.created_at" }),
        renderCell: renderCreatedAtCell,
      },
      {
        flex: 1.5,
        field: "company_name",
        headerName: tolgee.t({ key: "users.company_name" }),
        renderCell: (params: GridRenderCellParams<ManagedUser>) => renderCompanyNameCell(params),
      },
      {
        flex: 1.75,
        field: "email",
        headerName: tolgee.t({ key: "users.email" }),
      },
      {
        flex: 1,
        field: "public_ids",
        headerName: tolgee.t({ key: "users.businesses" }),
        renderCell: renderPublicIdsCell,
      },
      {
        flex: 1,
        field: "products",
        headerName: tolgee.t({ key: "users.products" }),
        sortable: false,
        renderCell: renderProductsCell,
      },
      {
        flex: 0.5,
        field: "iso_3166_1_alpha_2",
        headerName: tolgee.t({ key: "users.country" }),
        renderCell: renderCountryCell,
      },
      {
        flex: 0.5,
        field: "role_names",
        headerName: tolgee.t({ key: "users.type" }),
        renderCell: renderRoleCell,
      },
      {
        flex: 1,
        field: "buttons",
        headerName: "",
        sortable: false,
        renderCell: renderActionButtonsCell,
      },
    ],
    [renderActionButtonsCell, renderCompanyNameCell, renderCountryCell]
  );

  useEffect(() => {
    getColumnsRef.current = getColumns;
  }, [getColumns]);

  useEffect(() => {
    fetchData({
      order_by: orderedBy,
      page: currentPage,
      per_page: manageUsersRows,
      order: newOrder,
      search_term: debouncedSearchValue,
      user_filter: filter,
    });
  }, [
    currentPage,
    manageUsersRows,
    orderedBy,
    newOrder,
    debouncedSearchValue,
    filter,
    editableNote,
    fetchData,
  ]);

  useEffect(() => {
    if (debouncedSearchValue !== searchParams.get("search")) {
      handleUrlUpdate({ search: debouncedSearchValue });
    }
  }, [debouncedSearchValue, handleUrlUpdate, searchParams]);

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="partners">
      {data ? (
        <div className="box-outerlayout">
          <div className="flex space-x-4 items-center">
            <BaseIconButton
              className="border-full"
              onClick={() =>
                fetchData({
                  order_by: orderedBy,
                  page: currentPage,
                  per_page: manageUsersRows,
                  order: newOrder,
                  search_term: debouncedSearchValue,
                  user_filter: filter,
                })
              }>
              <div className="w-4 h-4 flex items-center justify-center">
                <FontAwesomeIcon icon="refresh" color="gray" />
              </div>
            </BaseIconButton>
            <SearchField className="w-full" searchState={[search, setSearch]} />
            <div className="space-x-2 flex items-center">
              <ToggleButtonGroup exclusive className="h-14" value={filter} onChange={handleFilter}>
                <ToggleButton value={UserFilter.ACTIVE_CUSTOMER}>
                  <T keyName="users.active" />
                </ToggleButton>
                <ToggleButton value={UserFilter.NEW_NO_BUSINESS}>
                  <T keyName="users.new_no_business" />
                </ToggleButton>
                <ToggleButton value={UserFilter.DELETED}>
                  <T keyName="users.deleted" />
                </ToggleButton>
                <ToggleButton value={UserFilter.INTERNAL}>
                  <T keyName="users.internal" />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <DataGrid
            {...data}
            loading={loading}
            getRowClassName={(params) => `${params.row.is_main_client && "font-bold"}`}
          />
        </div>
      ) : (
        !loading && <div>{tolgee.t({ key: "billing.no_data" })}</div>
      )}
    </div>
  );
}
