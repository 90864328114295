import { useEffect, useState } from "react";
import { useTranslate } from "@tolgee/react";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import GavelIcon from "@mui/icons-material/Gavel";

import { BaseModal } from "components";
import tolgee from "services/translation";

interface Props {
  translateKey: string;
}

enum InfoType {
  modal = "modal",
  tooltip = "tooltip",
}

function TolgeeWrapper({ translateKey }: Props) {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { t } = useTranslate();

  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [infoType, setInfoType] = useState<InfoType>(null);
  const [iconName, setIconName] = useState<string>(null);
  const [translatedText, setTranslatedText] = useState<string>(null);
  const [IconComponent, setIconComponent] = useState<React.ElementType>(InfoIcon);

  useEffect(() => {
    const iconMap: Record<string, React.ElementType> = {
      WarningIcon,
      HelpCenterIcon,
      GavelIcon,
      InfoIcon,
    };

    const translatedKey = t(translateKey);
    const custom = translatedKey.startsWith("[#");
    if (custom) {
      // Custom translation example: [#type=modal#icon=InfoIcon#] Test Test Test
      setIsCustom(true);
      const content = translatedKey.match(/\[#(.+?)#\]/)?.[1];
      const result = content
        ? content.split("#").reduce((acc: any, pair: any) => {
            const [key, value] = pair.split("=");
            if (key && value) {
              acc[key] = value;
            }
            return acc;
          }, {})
        : {};

      setInfoType(result.type || null);
      setIconName(result.icon || "Info");
      setTranslatedText(translatedKey.split("#]")[1].trim() || null);
      setIconComponent(iconMap[iconName] || InfoIcon);
    } else {
      setIsCustom(false);
    }
  }, [translateKey, iconName, t]);

  const handleInfoClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (infoType === InfoType.modal) {
      setModalOpen(true);
    }
  };

  function renderModalContent() {
    return (
      <>
        <div className="flex flex-row items-center gap-2">
          {translatedText}
          <IconComponent
            className="mr-2 cursor-pointer"
            onClick={(e: React.MouseEvent) => handleInfoClick(e)}
          />
        </div>
        <BaseModal modalState={[isModalOpen, setModalOpen]}>
          <>{tolgee.t(`${translateKey}.infoContent`)}</>
        </BaseModal>
      </>
    );
  }

  function renderTooltipContent() {
    return (
      <Tooltip title={tolgee.t(`${translateKey}.infoContent`)} arrow>
        <span className="tooltip-text">{translatedText}</span>
      </Tooltip>
    );
  }

  return (
    <>
      {isCustom ? (
        <>
          {(() => {
            if (infoType === InfoType.modal) {
              return renderModalContent();
            }
            if (infoType === InfoType.tooltip) {
              return renderTooltipContent();
            }
            return null;
          })()}
        </>
      ) : (
        <>{tolgee.t(translateKey)}</>
      )}
    </>
  );
}

export default TolgeeWrapper;
