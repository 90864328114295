import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";

import { generateUrl } from "helpers/case";
import { FileDownloadWithIcon } from "components";
import { getBeautyDate, getFileIcon, getFileName } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation/tolgee";

import type { CaseCreateInquiriesEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function CaseCreateInquiries(props: {
  entityData: CaseCreateInquiriesEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;
  const { caseUuid } = useParams();
  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="question" color="white" size="lg" />}
      title={entityData.title}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="p-4 space-y-2">
        {entityData?.inquiries?.map((i) => (
          <div key={i.uuid}>
            {i.question_i18n && (
              <div className="flex flex-col">
                <span>
                  <b>
                    {tolgee.t({
                      key: "dsr.question",
                    })}
                  </b>
                  {": "}
                  {tolgee.t({
                    key: `dsr.inquiry.${i.question_i18n.key.toLowerCase()}_title`,
                  })}
                </span>
              </div>
            )}
            {i.question && (
              <div className="flex flex-col">
                <span>
                  <b>
                    {tolgee.t({
                      key: "dsr.question",
                    })}
                  </b>
                  : {i.question}
                </span>
              </div>
            )}
            {i.answer_i18n && (
              <div className="flex flex-col">
                <span>
                  <b>
                    {tolgee.t({
                      key: "dsr.answer",
                    })}
                  </b>
                  {": "}
                  {tolgee.t({
                    key: `dsr.${i.answer_i18n.key.toLowerCase()}`,
                  })}
                </span>
              </div>
            )}
            {i.answer && (
              <div className="flex flex-col">
                <span>
                  <b>
                    {tolgee.t({
                      key: "dsr.answer",
                    })}
                  </b>
                  : {i.answer}
                </span>
              </div>
            )}
            <div className="flex flex-col">
              {i.files?.length > 0 && (
                <>
                  <b>
                    {tolgee.t({
                      key: "dsr.files",
                    })}
                  </b>
                  {i.files.map((file: any) => (
                    <FileDownloadWithIcon key={file.uuid} icon={getFileIcon(file.mime_type)}>
                      <a key={file.uuid} href={generateUrl(caseUuid, file.uuid)}>
                        {getFileName(file)}
                      </a>
                    </FileDownloadWithIcon>
                  ))}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </HistoryEntryTemplate>
  );
}
