import PrighterFooter from "components/PageSpecific/General/PrighterFooter";
import BusinessHeader from "pages/Public/Dsr/Business/BusinessHeader";

import type { DSRBusiness } from "types/dsr";
import type { ReactNode } from "react";

type Props = { children: ReactNode; headerState: DSRBusiness };

export default function RequestFormContainer({ children, headerState }: Props) {
  return (
    <div className="box-border w-full min-h-screen pt-8 mx-0 bg-gray-50 font-body">
      <div className="box-border max-w-screen-md px-4 mx-auto">
        <BusinessHeader businessState={headerState} />
        <div className="flex flex-col w-full my-4 box-outerlayout">{children}</div>
        <PrighterFooter theme="light" />
      </div>
    </div>
  );
}
