import { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BaseButton, BaseIconButton } from "components";
import { getUrl } from "helpers";
import { notify } from "store/app";
import { api } from "services";
import tolgee from "services/translation";
import AddressForm from "components/complex/AddressForm";
import { useAppDispatch } from "store";
import { handleError } from "services/api/error";

import type { ContactAddress } from "types/contactDirectory";
import type { DemoAccountResult } from "types/sales";

const defaultValues = {
  name: "Demo Account",
  country_iso: "AT",
  city: "Vienna",
  postal: "1010",
  street: "Kaerntner Street",
  user_name: "J. Doe",
  user_role: "DPO",
  compliance_landingpage_slug: "",
};

export default function CustomizedAccount() {
  const [showBack, setShowBack] = useState(false);
  const dispatch = useAppDispatch();

  const [result, setResult] = useState<DemoAccountResult>();
  const { register, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues,
  });

  const wholeAddress = watch();
  const accountNameReg = register("name");
  const nameReg = register("user_name");
  const pageReg = register("compliance_landingpage_slug");
  const roleReg = register("user_role");

  const handleAddressChange = (contactAddresses: ContactAddress[]) => {
    const tempAddress = contactAddresses[0];
    setValue("street", tempAddress.street);
    setValue("postal", tempAddress.postal);
    setValue("city", tempAddress.city);
    setValue("country_iso", tempAddress.country_iso);
  };

  const onSubmit = async (data: any) => {
    const accountData = {
      ...data,
      city: data?.city === "none" ? "" : data?.city,
    };
    let res;
    try {
      res = await api.sales.createDemoAccount(accountData);
    } catch (e) {
      handleError(e);
      return;
    }

    setResult(res.data);
    dispatch(
      notify({
        message: tolgee.t({
          key: "settings.created_successfully",
        }),
        type: "SUCCESS",
      })
    );
    reset(defaultValues);
    setShowBack(true);
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <div className="box-outerlayout flex flex-col pb-4 pt-10 space-y-4">
        {!showBack && (
          <div className="flex flex-col pb-4 pt-10 space-y-4">
            <TextField
              label={<T keyName="settings.company_name" />}
              onChange={accountNameReg.onChange}
              onBlur={accountNameReg.onBlur}
              name={accountNameReg.name}
              ref={accountNameReg.ref}
            />

            <AddressForm
              contactAddresses={[
                {
                  street: wholeAddress.street,
                  postal: wholeAddress.postal,
                  city: wholeAddress.city,
                  country_iso: wholeAddress.country_iso,
                },
              ]}
              onChange={handleAddressChange}
            />

            <div className="flex space-x-4">
              <TextField
                label={<T keyName="settings.representative_name" />}
                onChange={nameReg.onChange}
                onBlur={nameReg.onBlur}
                name={nameReg.name}
                ref={nameReg.ref}
                className="w-full"
              />
              <TextField
                label={<T keyName="settings.user_role" />}
                onChange={roleReg.onChange}
                onBlur={roleReg.onBlur}
                name={roleReg.name}
                ref={roleReg.ref}
                className="w-full"
              />
            </div>

            <TextField
              label={<T keyName="settings.compliance_slug" />}
              onChange={pageReg.onChange}
              onBlur={pageReg.onBlur}
              name={pageReg.name}
              ref={pageReg.ref}
            />

            <div className="w-fit flex self-end">
              <BaseButton onClick={handleSubmit(onSubmit)}>
                <T keyName="generic.create" />
              </BaseButton>
            </div>
          </div>
        )}

        {showBack && result && result.user && (
          <>
            <div className="flex flex-col space-y-2 pb-4">
              <div className="flex space-x-2 items-center">
                <div className="flex space-x-2">
                  <div className="font-semibold">
                    <T keyName="generic.email" />
                  </div>
                  <div>{result.user.email}</div>
                </div>
                <BaseIconButton
                  size="small"
                  className="border-full"
                  onClick={() => navigator?.clipboard?.writeText(result.user.email)}>
                  <div className="w-4 h-4 flex items-center justify-center">
                    <FontAwesomeIcon icon="copy" color="gray" />
                  </div>
                </BaseIconButton>
              </div>
              <div className="flex space-x-2 items-center">
                <div className="flex space-x-2">
                  <div className="font-semibold">
                    <T keyName="generic.password" />
                  </div>
                  <div>{result.password}</div>
                </div>
                <BaseIconButton
                  size="small"
                  className="border-full"
                  onClick={() => navigator?.clipboard?.writeText(result.password)}>
                  <div className="w-4 h-4 flex items-center justify-center">
                    <FontAwesomeIcon icon="copy" color="gray" />
                  </div>
                </BaseIconButton>
              </div>
              <div className="flex flex-col pb-4 space-y-2">
                {result?.user.managed_businesses.map((business) => (
                  <>
                    <span>
                      <a href={`/portal/${business.public_id}`}>
                        {tolgee.t("settings.portal")} ({business.public_id})
                      </a>
                    </span>
                  </>
                ))}
              </div>
              <a href={getUrl("FLASK", `/administration/manage_customer/${result.user.uuid}`)}>
                <T keyName="settings.manage_user" />
              </a>
              <a href={getUrl("FLASK", `/administration/customer/${result.user.uuid}/ghostlogin`)}>
                <T keyName="settings.ghost_login" />
              </a>
            </div>
            <div className="w-full flex justify-end">
              <Button onClick={() => setShowBack(false)}>
                <T keyName="generic.back" />
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
