import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ReactMarkdown from "react-markdown";

import { BaseModal } from "components";
import { api } from "services";
import { useCase } from "pages/Client/Case/CaseContext";
import { handleError } from "services/api/error";

import type { CaseEmailInterface } from "types/case";
import type { UseState } from "types";

type Props = {
  modalState: UseState<boolean>;
};

export default function EmailsOverviewModal({ modalState }: Props) {
  const [state] = useCase();
  const [open, setOpen] = modalState;
  const [caseEmails, setCaseEmails] = useState<CaseEmailInterface[]>(null);

  useEffect(() => {
    const fetchEmails = async () => {
      let res;
      try {
        res = await api.authCase.getCaseEmails(state.case.uuid);
      } catch (e) {
        handleError(e);
        return;
      }

      setCaseEmails(res.data);
    };

    if (open) {
      fetchEmails();
    }
  }, [open, state.case.uuid]);

  return (
    <BaseModal modalState={[open, setOpen]} className="w-3/4 overflow-auto">
      <div>
        {caseEmails?.length > 0 ? (
          <div>
            {caseEmails.map((item, index) => (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}>
                  <div className="font-medium">{item.subject}</div>
                </AccordionSummary>
                <AccordionDetails className="bg-slate-100">
                  <div className="flex flex-row flex-wrap gap-2 mb-2">
                    <div className="font-bold mb-2">Subject:</div>
                    <div>{item.subject}</div>
                  </div>
                  <div className="flex flex-row flex-wrap gap-2 mb-2">
                    <div className="font-bold mb-2">Recipients:</div>
                    <div>{item.to_email?.map((toEmailItem) => toEmailItem).join(", ")}</div>
                  </div>
                  <div className="flex flex-row flex-wrap gap-2 mb-2">
                    <div className="font-bold mb-2">Content Type:</div>
                    <div>{item.content_type}</div>
                  </div>
                  <div className="flex flex-row flex-wrap gap-2 mb-2">
                    <div className="font-bold mb-2">Sent at:</div>
                    <div>{item.sent_at}</div>
                  </div>
                  <div className="flex flex-row flex-wrap gap-2 mb-2">
                    <div className="font-bold mb-2">Created at:</div>
                    <div>{item.created_at}</div>
                  </div>
                  <div>
                    <div className="font-bold mb-2">Body:</div>
                    <div className="break-words">
                      <ReactMarkdown>{item.content}</ReactMarkdown>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        ) : (
          <div>There is no emails at the moment</div>
        )}
      </div>
    </BaseModal>
  );
}
