import { IconButton, Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";

import { FormFileField } from "components";
import tolgee from "services/translation";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
import { BusinessFileEnum } from "types/business";

import type { BusinessConfig } from "types/clp";

type Type = "logo" | "cookie" | "privacy" | "imageCertificate" | "backgroundImage";

interface Props {
  handleUploadFile: (file: File, type: Type) => void;
  handleRemoveFile: (type: BusinessFileEnum, fileName?: string) => void;
}

export default function CompanyPolicies({ handleUploadFile, handleRemoveFile }: Props) {
  const { watch } = useFormContext<BusinessConfig>();
  const privacyPolicy = watch("privacy_policy");
  const privacyPolicyUrl = watch("privacy_policy_url");
  const cookie = watch("cookie_policy");
  const cookieUrl = watch("cookie_policy_url");

  return (
    <div className="p-2 w-full">
      <div className="flex flex-row items-center justify-between gap-6">
        <Stack spacing={2}>
          <div>
            <div className="font-bold">{tolgee.t("clp.privacy_policy_heading")}:</div>
            {tolgee.t("clp.privacy_policy")}
          </div>
          <div className="flex items-start w-full space-x-2">
            <FormFileField
              label={tolgee.t({ key: "clp.choose_file" })}
              className="mt-2"
              value={privacyPolicy?.name && [{ name: privacyPolicy.name }]}
              onChange={(files: File[]) => handleUploadFile(files[0], "privacy") as any}
              fullWidth
            />
            {privacyPolicyUrl && (
              <>
                <FileDownloadPreview icon="file" url={privacyPolicyUrl} fileName="Privacy Policy" />
                <IconButton
                  onClick={() => handleRemoveFile(BusinessFileEnum.PRIVACY_POLICY)}
                  size="small">
                  <FontAwesomeIcon size="lg" icon="xmark" />
                </IconButton>
              </>
            )}
          </div>
        </Stack>
        <Stack spacing={2}>
          <div>
            <div className="font-bold">{tolgee.t("clp.cookie_policy_heading")}:</div>
            {tolgee.t("clp.cookies_policy")}
          </div>
          <div className="flex items-start w-full space-x-2">
            <FormFileField
              label={tolgee.t({ key: "clp.choose_file" })}
              className="mt-2"
              value={cookie?.name && [{ name: cookie.name }]}
              onChange={(files: File[]) => handleUploadFile(files[0], "cookie") as any}
              fullWidth
            />
            {cookieUrl && (
              <>
                <FileDownloadPreview icon="file" url={cookieUrl} fileName="Cookie Policy" />
                <IconButton
                  onClick={() => handleRemoveFile(BusinessFileEnum.COOKIE_POLICY)}
                  size="small">
                  <FontAwesomeIcon size="lg" icon="xmark" />
                </IconButton>
              </>
            )}
          </div>
        </Stack>
      </div>
    </div>
  );
}
