import { useEffect, useState, useCallback } from "react";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

import { generateI18nKey, generateUrl, isCheckFinished } from "helpers/case";
import { api } from "services";
import tolgee from "services/translation";
import PrivacyPolicyDsr from "components/DSRSettings/CCPA/PrivacyPolicyDsr";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import { BaseIconButton, FormFileField } from "components";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
import { notify } from "store/app";
import { getFileIcon, getFileName } from "helpers";
import { handleError } from "services/api/error";
import { HandlingCheckNameEnum } from "types/case";
import { BusinessFileEnum } from "types/business";

import type { FileObject } from "types/general";

export default function AccessCategories() {
  const appDispatch = useDispatch();

  const [state, dispatch] = useCase();
  const isFinished = isCheckFinished(state);
  const caseData = state.case;
  const [privacyPolicyDsrEnabled, setPrivacyPolicyDsrEnabled] = useState<boolean>(
    caseData.business.config.privacy_policy_ext_url ? caseData.outcome.send_privacy_policy : false
  );
  const [uploading, setUploading] = useState(false);

  const handleUploadFiles = async (filesToUpload: File[]) => {
    setUploading(true);

    try {
      await api.authCase.uploadFileToCase(
        state.case.uuid,
        filesToUpload,
        BusinessFileEnum.ACCESS_CATEGORIES
      );
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setUploading(false);
    }

    appDispatch(notify({ message: "Files uploaded successfully", type: "SUCCESS" }));
    fetchCaseData()(dispatch, state.case.uuid, false);
  };

  const handleFileChange = (files: File[]): void => {
    if (uploading) {
      return;
    } // Prevent double upload

    if (files.length > 0) {
      handleUploadFiles(files);
    }
  };

  const handleRemoveFile = async (file: FileObject) => {
    try {
      await api.authCase.removeFileFromCase(state.case.uuid, file.uuid);
    } catch (e) {
      handleError(e);
      return;
    }
    appDispatch(notify({ message: "File removed successfully", type: "SUCCESS" }));
    fetchCaseData()(dispatch, state.case.uuid, false);
  };

  const handleSubmit = async () => {
    const decisionName = privacyPolicyDsrEnabled ? "SEND_PRIVACY_POLICY" : "SEND_CATEGORIES";
    finalizeCheck()(
      dispatch,
      state.case.uuid,
      HandlingCheckNameEnum.PRIVACY_POLICY_CATEGORIES,
      decisionName
    );
    finalizeCheck()(dispatch, state.case.uuid, HandlingCheckNameEnum.ACCESS_CATEGORIES_UPLOAD);
  };

  const downloadForm = () => {
    const link = document.createElement("a");
    link.href = "https://cdn.prighter.com/legal/ccpa/20241206_CCPA_Right_to_Know_Template.xlsx";
    link.download = "CCPA_Right_to_Know_Template.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isProceedButtonDisabled = useCallback(() => {
    if (
      privacyPolicyDsrEnabled ||
      state.case.files.filter((file) => file.type === BusinessFileEnum.ACCESS_CATEGORIES).length > 0
    ) {
      return false;
    }
    return true;
  }, [privacyPolicyDsrEnabled, state.case.files]);

  useEffect(() => {
    isProceedButtonDisabled();
  }, [isProceedButtonDisabled]);

  return (
    <div>
      <div className="flex justify-end gap-4 mb-8">
        {isFinished && (
          <Button
            onClick={() => {
              reopenCheck()(dispatch, caseData.uuid, state.ui.check);
              reopenCheck()(
                dispatch,
                caseData.uuid,
                HandlingCheckNameEnum.ACCESS_CATEGORIES_UPLOAD
              );
            }}
            variant="outlined"
            endIcon={<FontAwesomeIcon icon="lock-open" className="!text-sm" />}>
            {tolgee.t({
              key: "general.reopen",
            })}
          </Button>
        )}
        <Button
          data-testid="accessForCategories_confirm"
          color="success"
          disabled={isProceedButtonDisabled()}
          onClick={handleSubmit}>
          {tolgee.t({
            key: generateI18nKey(caseData.type, state.ui.check, "confirm"),
          })}
        </Button>
      </div>
      <div className={isFinished ? "pointer-events-none" : "pointer-events-auto"}>
        <div className="mb-8">
          {tolgee.t({
            key: generateI18nKey(caseData.type, state.ui.check, "description"),
          })}
        </div>
        <div className="box-dotted mb-4">
          <PrivacyPolicyDsr
            isErrorLabel
            isDisabled={!caseData.business.config.privacy_policy_ext_url}
            businessId={caseData.business.public_id}
            isChecked={privacyPolicyDsrEnabled}
            handleEnabled={(value: boolean) => {
              setPrivacyPolicyDsrEnabled(value);
            }}
          />
        </div>
        <div className="box-dotted flex flex-col items-start gap-8">
          {tolgee.t({
            key: generateI18nKey(state.case.type, state.ui.check, "uploaded_file_description"),
          })}
          <div className="flex flex-col items-start gap-8">
            <FormFileField
              disabled={isFinished}
              label="Upload a file"
              className="self-center"
              onChange={handleFileChange}
              clearFilesAfterUpload
              multiple
              required
            />
            <div>
              <Button color="info" onClick={downloadForm}>
                {tolgee.t({
                  key: generateI18nKey(caseData.type, state.ui.check, "download_form"),
                })}
              </Button>
            </div>
          </div>
          <div>
            {state.case.files.filter((file) => file.type === BusinessFileEnum.ACCESS_CATEGORIES)
              .length > 0 && (
              <div>
                {tolgee.t({
                  key: generateI18nKey(state.case.type, state.ui.check, "uploaded_files"),
                })}
              </div>
            )}
            {state.case.files
              .filter((file) => file.type === BusinessFileEnum.ACCESS_CATEGORIES)
              .map((file) => (
                <div className="flex flex-row space-x-2" key={file.uuid}>
                  <div className="pt-2.5">
                    <BaseIconButton
                      disabled={isFinished}
                      onClick={() => handleRemoveFile(file)}
                      className="w-5 h-5"
                      size="medium">
                      <FontAwesomeIcon size="xs" icon="xmark" color="danger" />
                    </BaseIconButton>
                  </div>
                  <FileDownloadPreview
                    icon={getFileIcon(file.mime_type)}
                    url={generateUrl(state.case.uuid, file.uuid)}
                    fileName={getFileName(file)}
                    mimeType={file.mime_type}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
