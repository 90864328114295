import PrighterLogo from "components/Prighter/PrighterLogo";

import type { ReactNode } from "react";

type Props = { children: ReactNode };

export default function PrighterFeedback({ children }: Props) {
  return (
    <div className="!p-0 box-outerlayout  max-w-[calc(100vw-2rem)]  flex flex-col items-center">
      <div className="flex items-center justify-center w-full py-2 mb-6 border-0 border-b border-solid md:p-4 bg-gray-50">
        <PrighterLogo width={20} className="w-3 mr-1 md:w-6" />
        <span className="text-xl font-bold md:text-4xl text-prighterblue">Prighter</span>
      </div>

      {children}
    </div>
  );
}
