import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type { DemoAccountDetails, DemoAccountResult } from "types/sales";

export async function createDemoAccount(
  data: DemoAccountDetails
): Promise<AxiosResponse<DemoAccountResult>> {
  return api.post("/v1/marketing/demo-account", data, null);
}
