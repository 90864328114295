import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation/tolgee";

import type { CaseFinishedEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function CaseFinished(props: {
  entityData: CaseFinishedEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="check" color="white" size="lg" />}
      title={entityData.title}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="p-4 space-y-2">
        <div className="flex flex-col">
          <span>
            <b>
              {tolgee.t({
                key: "dsr.name",
              })}
            </b>
            {tolgee.t({
              key: `dsr.${entityData.name.toLowerCase()}`,
            })}
          </span>
        </div>
      </div>
    </HistoryEntryTemplate>
  );
}
