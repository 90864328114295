import { Button, MenuItem, Select } from "@mui/material";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";

import { api } from "services";
import { notify } from "store/app";
import tolgee from "services/translation";
import { useAppDispatch } from "store";
import { HorizontalDataValue } from "components";
import { handleError } from "services/api/error";

import type { SelectChangeEvent } from "@mui/material";
import type { Subscription } from "types/user";
import type { Company } from "types/company";

type Props = { business: Company };

export default function SubscriptionsTab({ business }: Props) {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [chosenSubscriptions, setChosenSubscriptions] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      let res;
      try {
        res = await api.user.listSubscriptions(business.public_id);
      } catch (e) {
        handleError(e);
        return;
      }
      setSubscriptions(res.data);
    };
    fetchSubscriptions();
    setChosenSubscriptions(business.products.map((product) => product.subscription_public_id));
  }, [business.public_id, business.products]);

  const handleSubscriptionChange = (event: SelectChangeEvent<string[]>) => {
    setChosenSubscriptions(event.target.value as string[]);
  };

  const handleSubmit = async () => {
    try {
      await api.business.linkSubscriptionsToBusiness(business.public_id, chosenSubscriptions);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "subscription_linked.successfully",
        }),
        type: "SUCCESS",
      })
    );
  };

  return (
    <div className="box-outerlayout space-y-2">
      <h3>
        <T keyName="manage_business.business_linked_to_following_subscriptions" />:
      </h3>
      <Select
        multiple
        value={chosenSubscriptions}
        onChange={handleSubscriptionChange}
        displayEmpty
        disabled={subscriptions.length === 0}
        renderValue={(selected) =>
          subscriptions.length === 0 ? "No subscriptions to link" : selected.join(", ")
        }>
        {subscriptions.length > 0 ? (
          subscriptions.map((s) => (
            <MenuItem key={s.public_id} value={s.public_id}>
              {s.description} ({s.public_id})
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No item to add</MenuItem>
        )}
      </Select>

      <HorizontalDataValue
        title={tolgee.t({
          key: "active_products",
        })}
        value={business.features.join(", ")}
      />

      <div className="flex flex-col items-end pt-2">
        <Button onClick={handleSubmit} disabled={subscriptions.length === 0}>
          <T keyName="generic.save" />
        </Button>
      </div>
    </div>
  );
}
