import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Skeleton } from "@mui/material";
import { Link } from "react-router";

import { PRIGHTER_BLUE } from "const/color";
import { getUrl } from "helpers";
import { useAppDispatch, useAppSelector } from "store";
import { toggleNavbar } from "store/app";
import PrighterLogo from "components/Prighter/PrighterLogo";
import { LinkButton } from "components/Button";
import { isManager } from "helpers/general";

function Header() {
  const { email, name, roles, userLoaded = false } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  return (
    <header className="fixed z-40 w-screen px-4 border-b border-gray-300 bg-gray-50">
      <div className="box-border grid items-center grid-cols-2 py-3 mx-auto">
        <div className="flex space-x-4">
          <IconButton
            size="medium"
            onClick={() => {
              dispatch(toggleNavbar());
            }}>
            <div className="flex items-center justify-center w-3 h-3">
              <FontAwesomeIcon size="lg" icon="bars" color={PRIGHTER_BLUE} />
            </div>
          </IconButton>
          <Link
            className="flex items-baseline space-x-4"
            to={isManager(roles) ? "/manage-users" : ""}>
            <PrighterLogo className="h-8" width={24.4} />
            <span className="hidden text-4xl font-bold text-prighterblue md:flex">Prighter</span>
          </Link>
        </div>
        <nav className="items-center justify-end text-base text-right ">
          <div className="flex flex-row items-center justify-end space-x-2">
            {userLoaded ? (
              <div className="mr-2 md:mr-0 ">
                <div className="text-gray-800">{name || "None"}</div>
                <div className="w-48 overflow-hidden font-bold truncate text-prighterblue md:w-auto">
                  {email}
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-end space-y-2">
                <Skeleton variant="rectangular" width={120} height={20} />
                <Skeleton variant="rectangular" width={160} height={20} />
              </div>
            )}
            <LinkButton href={getUrl("FLASK", "/logout")} className="px-4">
              Sign out
            </LinkButton>

            <a href={getUrl("FLASK", "/logout")} size="medium" className="md:hidden">
              <FontAwesomeIcon icon="arrow-right-from-bracket" size="xl" color={PRIGHTER_BLUE} />
            </a>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
