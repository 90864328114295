import { VerticalDataValue } from "components";
import {
  generateConsumerInfo,
  generateContactData,
  generateI18nKey,
  getSender,
} from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import ConfirmInquiry from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ConfirmInquiry";
import tolgee from "services/translation";
import { DeclineTypeEnum } from "types/case";

export default function DSIdentification() {
  const [state, dispatch] = useCase();
  const caseData = state.case;
  const checkStep = state.ui.check;

  const sender = getSender(caseData.case_actors);
  const senderInfo = generateContactData(sender);
  const consumerInfo = generateConsumerInfo(caseData);

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 ">
          <ConfirmInquiry
            handleSendInquiry={() =>
              dispatch({
                type: "SEND_INQUIRY",
                payload: {
                  questions: ["identification_1"],
                },
              })
            }
            declinePreview={
              caseData.type === "DSR_CCPA_LIMIT" || caseData.type === "DSR_CCPA_OPTOUT"
                ? DeclineTypeEnum.FRAUDULENT
                : DeclineTypeEnum.IDENTIFICATION
            }
          />
          <div className="mt-4">
            {tolgee.t({
              key: generateI18nKey(caseData.type, checkStep, "description"),
            })}
          </div>
        </div>
      </div>
      {caseData.extra.acting_for !== "MYSELF" && (
        <>
          <h3 className="my-0 mb-1">
            {tolgee.t({
              key: generateI18nKey(
                caseData.type,
                checkStep,
                `requester.${caseData.extra.acting_for.toLowerCase()}`
              ),
            })}
          </h3>
          <div className="mb-4">
            <div className="grid @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3">
              {senderInfo.map((i: any) => (
                <VerticalDataValue
                  key={i.key}
                  title={tolgee.t({
                    key: `generic.${i.key}`,
                  })}
                  value={i.value}
                />
              ))}

              <VerticalDataValue
                title={tolgee.t({
                  key: generateI18nKey(caseData.type, checkStep, "acting_for"),
                })}
                value={tolgee.t({
                  key: generateI18nKey(
                    caseData.type,
                    checkStep,
                    `acting_for.${caseData.extra.acting_for.toLowerCase()}`
                  ),
                })}
              />
            </div>
          </div>
        </>
      )}
      <>
        <h3 className="my-0 mb-1">
          {tolgee.t({
            key: generateI18nKey(caseData.type, checkStep, "data_subject"),
          })}
        </h3>
        <div className="mb-4">
          <div className="grid  @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3 ">
            {consumerInfo.map((i: any) => (
              <VerticalDataValue
                key={i.key}
                title={tolgee.t({
                  key: `generic.${i.key}`,
                })}
                value={i.value}
              />
            ))}
          </div>
        </div>
      </>
    </>
  );
}
