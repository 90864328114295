import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams, useNavigate } from "react-router";

import { api } from "services";
import tolgee from "services/translation";
import { dataGridConfig } from "helpers/dataGridConfig";
import { BaseIconButton, SearchField } from "components";
import { useDebounce } from "helpers/hooks";
import { handleError } from "services/api/error";
import { useAppDispatch, useAppSelector } from "store";
import { changePreference } from "store/app";
import { updateUrlParams } from "helpers/url";

import type { PaginationRequest } from "types/general";

export default function PrighterCases() {
  const navigate = useNavigate();
  const { prighterCasesRows = 25 } = useAppSelector((state) => state.app.preferences);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const debouncedSearchValue = useDebounce<string>(search, 500);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 0);
  const [orderedBy, setOrderedBy] = useState(searchParams.get("orderBy") || "created_at");
  const [newOrder, setNewOrder] = useState(searchParams.get("order") || "desc");

  const handleUrlUpdate = useCallback(
    (params: Record<string, string>) => {
      updateUrlParams(params, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchData = useCallback(
    async (props: PaginationRequest) => {
      setLoading(true);
      const { order_by, page, per_page, order, search_term } = props;
      const desc = order === "desc";
      let res;
      try {
        res = await api.authCase.getCases(
          "PRIGHTER",
          true,
          order_by,
          page + 1,
          per_page,
          desc,
          search_term
        );
      } catch (e) {
        handleError(e);
        setLoading(false);
        return;
      }
      res = res.data;

      const tableData = {
        ...dataGridConfig({ currentPage, rowsPerPage: prighterCasesRows, count: res.count }),
        onSortModelChange: (sortVal: any) => {
          if (sortVal?.length === 0) {
            return;
          }
          const { field } = sortVal[0];
          const { sort } = sortVal[0];
          setOrderedBy(field);
          setNewOrder(sort);
        },
        onPaginationModelChange: (val: any) => {
          setCurrentPage(val.page);
          dispatch(changePreference({ prighterCasesRows: val.pageSize }));
        },
        columns: [
          {
            flex: 1.75,
            field: "company_name",
            headerName: tolgee.t({
              key: "generic.company_name",
            }),
          },
          {
            flex: 1.75,
            field: "title",
            headerName: tolgee.t({
              key: "generic.title",
            }),
          },
          {
            flex: 1.75,
            field: "stage",
            headerName: tolgee.t({
              key: "generic.stage",
            }),
          },
          {
            flex: 1,
            field: "state",
            headerName: tolgee.t({
              key: "generic.state",
            }),
          },
          {
            flex: 1,
            field: "assignee",
            headerName: tolgee.t({
              key: "generic.assignee",
            }),
          },
        ],
        rows: res?.result?.map((i: any) => {
          return { ...i, id: i?.uuid };
        }),
      };

      setData(tableData);
      setLoading(false);
    },
    [currentPage, prighterCasesRows, dispatch]
  );

  useEffect(() => {
    fetchData({
      order_by: orderedBy,
      page: currentPage,
      per_page: prighterCasesRows,
      order: newOrder,
      search_term: debouncedSearchValue,
    });
  }, [currentPage, prighterCasesRows, orderedBy, newOrder, debouncedSearchValue, fetchData]);

  useEffect(() => {
    if (debouncedSearchValue !== searchParams.get("search")) {
      handleUrlUpdate({ search: debouncedSearchValue });
    }
  }, [debouncedSearchValue, handleUrlUpdate, searchParams]);

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="partners">
      {data ? (
        <div className="box-outerlayout">
          <div className="flex space-x-4 items-center">
            <BaseIconButton
              className="border-full"
              onClick={() =>
                fetchData({
                  order_by: orderedBy,
                  page: currentPage,
                  per_page: prighterCasesRows,
                  order: newOrder,
                  search_term: debouncedSearchValue,
                })
              }>
              <div className="w-4 h-4 flex items-center justify-center">
                <FontAwesomeIcon icon="refresh" color="gray" />
              </div>
            </BaseIconButton>
            <SearchField className="w-full" searchState={[search, setSearch]} />
          </div>

          <DataGrid
            {...data}
            loading={loading}
            getRowClassName={(params) => `${params.row.is_main_client && "font-bold"}`}
            onRowClick={(params) => navigate(`/case/${params.row.uuid}`)}
            sx={{
              "& .MuiDataGrid-row": {
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              },
            }}
          />
        </div>
      ) : (
        !loading && (
          <div>
            {tolgee.t({
              key: "generic.no_data",
            })}
          </div>
        )
      )}
    </div>
  );
}
