import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Tooltip, styled, tooltipClasses } from "@mui/material";
import { useMemo } from "react";
import clsx from "clsx";

import DSRMenu from "pages/Client/Case/DSR/ClientView/Components/DSRMenu";
import DeadlineMenu from "pages/Client/Case/DSR/ClientView/Components/DSRHeader/DeadlineMenu";
import DeclineRequestButton from "pages/Client/Case/DSR/ClientView/Components/DeclineRequestButton";
import { generateI18nKeyByCaseType, getCurrentCaseStage } from "helpers/case";
import { stageNames } from "const/dsr";
import { BaseButton } from "components";
import { useCase } from "pages/Client/Case/CaseContext";
import tolgee from "services/translation";
import { StageNameEnum } from "types/case";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { TooltipProps } from "@mui/material";

const stageIcons: IconProp[] = ["clipboard", "clipboard-check", "edit", "paper-plane"];

export default function DSRHeader() {
  const [state, dispatch] = useCase();
  const caseData = state.case;

  const currentStage = useMemo(() => getCurrentCaseStage(caseData), [caseData]);

  const requestClosed = caseData.state === "CLOSED";

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }: any) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const getStageColor = (stage: StageNameEnum) => {
    if (
      requestClosed &&
      currentStage !== "DONE" &&
      stageNames.indexOf(stage) >= stageNames.indexOf(currentStage)
    ) {
      return "bg-white text-gray-500";
    }
    if (currentStage === "DONE" || stageNames.indexOf(stage) < stageNames.indexOf(currentStage)) {
      return "bg-success text-white";
    }
    if (stage === currentStage) {
      return "bg-warning text-white";
    }
    return "bg-white text-gray-500";
  };

  const changeUIStage = (newStage: StageNameEnum) => {
    if (
      (requestClosed && newStage === StageNameEnum.HANDLING_REVIEW) ||
      currentStage === StageNameEnum.DONE ||
      stageNames.indexOf(newStage) <= stageNames.indexOf(currentStage)
    ) {
      dispatch({ type: "UPDATE_UI_STAGE", payload: newStage });
    }
  };

  return (
    <div
      className="flex flex-row items-center justify-between w-full pb-4"
      data-testid="dsr-header">
      <div className="border rounded-md h-[38px] w-fit flex cursor-default font-bold">
        {stageNames.map((stage, index) => (
          <LightTooltip
            key={stage}
            title={tolgee.t({
              key: generateI18nKeyByCaseType(caseData.type, `${stage}.tooltip`),
            })}
            onClick={() => changeUIStage(stage)}>
            <div
              className={clsx("border-r flex items-center px-3 space-x-2", getStageColor(stage), {
                "!bg-danger text-white": requestClosed,
                "rounded-s-md": index === 0,
                "rounded-e-md": index === stageNames.length - 1,
              })}>
              <FontAwesomeIcon icon={stageIcons[index]} size="1x" />
            </div>
          </LightTooltip>
        ))}
      </div>
      <div className="flex @xl:w-none w-fit @xl:justify-normal space-x-2 items-center">
        {state.loading && (
          <div className="mr-2 text-gray-400 text-center flex justify-center">
            <CircularProgress color="inherit" size={32} />
          </div>
        )}

        {caseData.state !== "CLOSED" && <DeclineRequestButton />}
        {!requestClosed && <DeadlineMenu />}

        <BaseButton onClick={() => dispatch({ type: "SEND_INQUIRY" })} disabled={requestClosed}>
          {tolgee.t({
            key: generateI18nKeyByCaseType(caseData.type, "send_generic_inquiry"),
          })}
        </BaseButton>
        <DSRMenu isRequestClosed={requestClosed} />
      </div>
    </div>
  );
}
