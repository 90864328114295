import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { notify } from "store/app";
import { useDebounce } from "helpers/hooks";
import { api } from "services";
import tolgee from "services/translation";
import ContactModal from "pages/Client/ContactDirectory/Components/ContactModal";
import { useCase } from "pages/Client/Case/CaseContext";
import { generateI18nKey } from "helpers/case";
import { handleError } from "services/api/error";

import type { ProcessorContact } from "types/dsr/ccpa";
import type { PaginationRequest } from "types/general";
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from "@mui/material";
import type { CaseContact } from "types/case";
import type { Contact, ContactEmail } from "types/contactDirectory";
import type { DsrContactType } from "types/dsr/main";

type Props = {
  caseActors?: CaseContact[];
  handleReload?: () => void;
  locked: boolean;
  contactType: DsrContactType;
};

export default function AddProcessorField(data: Readonly<Props>) {
  const [state] = useCase();
  const dispatch = useDispatch();
  const { caseActors, handleReload, locked, contactType } = data;

  const [contactData, setContactData] = useState<ContactEmail[]>([]);
  const [contactModal, setContactModal] = useState(false);

  const selectedData = useMemo(() => {
    return caseActors
      .filter((j) => j.acting_as === contactType)
      .map((i) => ({
        created_at: i.contact.created_at,
        email: i.contact.contact_points?.find((t) => t.type === "EMAIL")?.value,
        label: i.contact.label,
        updated_at: i.contact.updated_at,
        uuid: i.uuid,
      }));
  }, [caseActors, contactType]);

  const [search, setSearch] = useState("");
  const debouncedSearchValue = useDebounce<string>(search, 500);

  async function fetchContactEmailData(props: PaginationRequest) {
    const { order_by, page, per_page, search_term } = props;
    try {
      const res = await api.contactDirectory.getContactEmail({
        order_by,
        page,
        per_page,
        search_term,
      });

      setContactData([{ uuid: "create_person" } as any, ...res.data.result]);
    } catch (e) {
      handleError(e);
    }
  }

  useEffect(() => {
    fetchContactEmailData({
      order_by: "label",
      page: 1,
      per_page: 100,
      search_term: debouncedSearchValue,
    });
  }, [debouncedSearchValue]);

  const generateLabel = (option: ContactEmail) => {
    if (option.uuid === "create_person") {
      return "Create new person";
    }

    return `${option.label} ${option.email}`;
  };

  const handleAddProcessor = async (newContact: ProcessorContact) => {
    try {
      await api.contactDirectory.dsrAddActor(state.case.uuid, newContact);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: generateI18nKey(state.case.type, state.ui.check, "processor_added"),
        }),
        type: "SUCCESS",
      })
    );
    handleReload();
  };

  const handleDeleteProcessor = async (deletedContact: ContactEmail) => {
    const contactUUID = caseActors.find((j) => j.contact?.label === deletedContact.label).uuid;

    try {
      await api.contactDirectory.dsrDeleteContact(state.case.uuid, contactUUID);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: generateI18nKey(state.case.type, state.ui.check, "processor_deleted"),
        }),
        type: "SUCCESS",
      })
    );
    handleReload();
  };

  const handleChangeProcessor = (
    _: any,
    __: any,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<ContactEmail>
  ) => {
    if (details.option?.uuid === "create_person") {
      setContactModal(true);
      setSearch("");
      return;
    }

    if (reason === "selectOption") {
      handleAddProcessor({
        name: details.option.label,
        email: details.option.email,
        acting_as: contactType,
      });
    } else if (reason === "removeOption") {
      handleDeleteProcessor(details.option);
    }
    setSearch("");
  };

  const handleCloseModal = () => {
    fetchContactEmailData({
      order_by: "label",
      page: 1,
      per_page: 100,
      search_term: debouncedSearchValue,
    });
    setContactModal(false);
  };

  const filteredContactData = contactData.filter(
    (contact) => !selectedData.some((selected) => selected.email === contact.email)
  );

  return (
    <>
      <Autocomplete
        disabled={locked}
        disableClearable
        value={selectedData}
        multiple
        filterSelectedOptions
        options={filteredContactData}
        style={{ marginTop: "10px" }}
        getOptionLabel={(option) => generateLabel(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              contactType === "PROCESSOR"
                ? tolgee.t({
                    key: generateI18nKey(
                      state.case.type,
                      state.ui.check,
                      "add_processor_contractor"
                    ),
                  })
                : tolgee.t({
                    key: generateI18nKey(
                      state.case.type,
                      state.ui.check,
                      "add_processor_third_party"
                    ),
                  })
            }
            variant="outlined"
          />
        )}
        onChange={handleChangeProcessor}
        inputValue={search}
        onInputChange={(_: any, newSearch: string, reason: AutocompleteInputChangeReason): any => {
          if (reason === "input") {
            setSearch(newSearch);
          }
        }}
      />
      <ContactModal
        modalState={[contactModal, handleCloseModal]}
        emailRequired
        parentSubmit={(contact: Contact) => {
          handleAddProcessor({
            name: contact.label,
            email: contact.contact_points?.find((t) => t.type === "EMAIL")?.value,
            acting_as: contactType,
          });
        }}
      />
    </>
  );
}
