import { IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

import { BaseButton } from "components";
import { validateEmail } from "helpers/validate";
import { api } from "services";
import tolgee from "services/translation";
import { handleError } from "services/api/error";

import type { ForwardEmailCaseInterface, ForwardEmailCasePayloadInterface } from "types/email";

interface Props {
  caseUuid: string;
  setShowForwardCasesModal: (value: boolean) => void;
}

export default function ForwardEmailCasesModal({ caseUuid, setShowForwardCasesModal }: Props) {
  const [forwardContent, setForwardContent] = useState<ForwardEmailCaseInterface>({
    emails: [
      {
        value: "",
        isValid: true,
      },
    ],
    comment: "",
  });
  const [isSendButtonActive, setIsSendButtonActive] = useState<boolean>(false);

  useEffect(() => {
    const filteredEmails = forwardContent.emails.filter(
      (email) => email.value.trim() !== "" && email.isValid
    );

    if (filteredEmails.length === forwardContent.emails.length) {
      setIsSendButtonActive(true);
    } else {
      setIsSendButtonActive(false);
    }
  }, [forwardContent]);

  const handleChange = (index: number, value: string) => {
    const newEmails = [...forwardContent.emails];
    // Handle value
    newEmails[index].value = value;

    // Handle validation
    if (validateEmail(value)) {
      newEmails[index].isValid = true;
    } else {
      newEmails[index].isValid = false;
    }

    setForwardContent((prevState) => ({
      ...prevState,
      emails: newEmails,
    }));
  };

  const addNewEmailField = () => {
    const newEmails = [...forwardContent.emails];
    newEmails.push({ value: "", isValid: true });
    setForwardContent((prevState) => ({
      ...prevState,
      emails: newEmails,
    }));
  };

  const handleDeletion = (index: number) => {
    if (forwardContent.emails.length === 1) {
      return;
    }
    const newEmails = [...forwardContent.emails];
    newEmails.splice(index, 1);

    setForwardContent((prevState) => ({
      ...prevState,
      emails: newEmails,
    }));
  };

  const handleCommentChange = (value: string) => {
    setForwardContent((prevState) => ({
      ...prevState,
      comment: value,
    }));
  };

  const handleForwardCase = async () => {
    const filteredEmails = forwardContent.emails.filter((email) => email.value.trim() !== "");

    const dataToSend = {
      comment: forwardContent.comment,
      recipients: filteredEmails.map((item) => [item.value, null]),
    } as ForwardEmailCasePayloadInterface;

    try {
      await api.email.postEmailCaseForward(caseUuid, dataToSend);
    } catch (e) {
      handleError(e);
      return;
    }

    setShowForwardCasesModal(false);
  };

  return (
    <div className="w-[40rem] overflow-auto">
      <h2 className="mb-4">
        <T keyName="email_intake.forward_email_modal.title" />
      </h2>
      <div className="mb-4">
        <div className="mb-4">
          <T keyName="email_intake.forward_email_modal.forward_to" />
        </div>
        {forwardContent.emails.map((item, index) => (
          <div className="my-4 flex fle-row items-start justify-between gap-2" key={index}>
            <div className="flex grow">
              <TextField
                className="w-full"
                label="Email"
                value={item.value}
                onChange={(val: any) => handleChange(index, val.target.value)}
                error={!item.isValid && item.value !== ""}
                helperText={
                  !item.isValid && item.value !== "" ? tolgee.t("generic.enter_valid_email") : ""
                }
                required
              />
            </div>
            <div className="flex py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50">
              <IconButton size="large" onClick={() => handleDeletion(index)}>
                <FontAwesomeIcon icon="trash" className=" text-brand-600" size="xs" />{" "}
              </IconButton>
            </div>
          </div>
        ))}
        <div className="w-full flex align-center justify-start">
          <BaseButton className="w-auto" variant="outlined" onClick={addNewEmailField}>
            <T keyName="email_intake.forward_email_modal.add_email_button" />
          </BaseButton>
        </div>
      </div>
      <div className="mb-8">
        <TextField
          className="w-full"
          label="Comment"
          value={forwardContent.comment}
          multiline
          rows={4}
          onChange={(val: any) => handleCommentChange(val.target.value)}
        />
      </div>
      <div className="flex flex-row justify-end gap-6">
        <BaseButton color="primary" disabled={!isSendButtonActive} onClick={handleForwardCase}>
          <T keyName="general.send" />
        </BaseButton>
        <BaseButton
          color="warning"
          variant="outlined"
          onClick={() => setShowForwardCasesModal(false)}>
          <T keyName="general.discard" />
        </BaseButton>
      </div>
    </div>
  );
}
