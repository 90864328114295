import tolgee from "services/translation";

import type { RawDateTimeString } from "types/date";
/*
  Returns a number of days left until the deadline.
  Returns a negative number if the deadline has passed.
*/
export const getDaysLeft = (date: RawDateTimeString): number => {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;
  const currentDate = new Date() as any;
  const deadlineDate = new Date(date) as any;

  // Calculate the difference in milliseconds
  const differenceMs = deadlineDate - currentDate;

  return Math.floor(differenceMs / ONE_DAY);
};

export const getTimeDifferenceString = (date: RawDateTimeString): string => {
  const currentDate = new Date() as any;
  const pastDate = new Date(date) as any;
  const timeDifference = Math.abs(currentDate - pastDate);

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30.44); // Approximate months as 30.44 days
  const years = Math.floor(days / 365.25); // Approximate years as 365.25 days

  if (years > 0) {
    return `${years}y`;
  }
  if (months > 0) {
    return `${months}mo`;
  }
  if (weeks > 0) {
    return `${weeks}w`;
  }
  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }

  return `${minutes}m`;
};

export const getDaysLeftString = (date: RawDateTimeString): string => {
  const daysLeft = getDaysLeft(date);
  if (daysLeft > 0) {
    return daysLeft === 1
      ? `1 ${tolgee.t("generic.day")}`
      : `${daysLeft} ${tolgee.t("generic.days")}`;
  }
  return "0 Days";
};
