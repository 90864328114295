import { BaseButton } from "components/Button";
import BaseModal from "components/DataDisplay/Modal/BaseModal";

import type { UseState } from "types";

type Props = {
  onYes: () => unknown;
  onNo?: () => unknown;
  modalState: UseState<boolean>;
  titleText?: string;
};

export default function ConfirmationModal(props: Props) {
  const { onYes, onNo = () => {}, modalState, titleText } = props;

  const handleClose = () => {
    onNo();
    modalState[1](false);
  };

  const handleYes = () => {
    onYes();
    modalState[1](false);
  };

  return (
    <BaseModal modalState={modalState} className="rounded-md">
      <div className="flex flex-col">
        <h3 className="mt-0">{titleText || <>Are you sure you want to do this?</>}</h3>
        <p className="mt-0">Please confirm this action</p>
        <div className="grid grid-flow-col gap-4">
          <BaseButton className="w-full" color="success" variant="outlined" onClick={handleYes}>
            Yes
          </BaseButton>
          <BaseButton className="w-full" color="error" onClick={handleClose}>
            No
          </BaseButton>
        </div>
      </div>
    </BaseModal>
  );
}
