import { useCallback, useState } from "react";

import type { TableConfig, TableSettings, TableValueConfig } from "types/components";

const useTable = (): [
  TableConfig,
  (arg0: string[]) => void,
  (arg1: TableValueConfig[]) => void,
  (arg2: TableSettings) => void,
] => {
  const [tableConfig, setTableConfig] = useState<TableConfig>({
    settings: {},
    keys: [],
    values: [],
  });

  const setSettings = useCallback(
    (value: TableSettings) => {
      let fields = ["id"];
      if (value.ignoreFields) {
        fields = ["id", ...value.ignoreFields];
      }
      const shouldSort = "shouldSort" in value ? value.shouldSort : true;

      setTableConfig((prevState) => ({
        ...prevState,
        settings: { ...value, ignoreFields: [...fields], shouldSort },
      }));
    },
    [setTableConfig]
  );

  const setKeys = useCallback(
    (value: string[]) => {
      setTableConfig((prevState) => ({ ...prevState, keys: value }));
    },
    [setTableConfig]
  );

  const setValues = useCallback(
    (value: TableValueConfig[]) => {
      setTableConfig((prevState) => ({ ...prevState, values: value }));
    },
    [setTableConfig]
  );

  return [tableConfig, setKeys, setValues, setSettings];
};

export default useTable;
