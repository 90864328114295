import { TextField } from "@mui/material";
import { useState } from "react";

import { BaseButton, BaseModal } from "components";
import MarkdownEditor from "components/Input/MarkdownEditor";
import { useCase } from "pages/Client/Case/CaseContext";
import { getBeautyDateTime } from "helpers";
import tolgee from "services/translation";
import TolgeeWrapper from "pages/Client/Dashboard/Components/TolgeeWrapper";

export default function HandlingReview() {
  const [state] = useCase();
  const caseData = state.case;
  const [openCaseEmail, setOpenCaseEmail] = useState<boolean>();
  const caseExemptions = caseData.outcome.exemptions;

  return (
    <>
      <div className="grid gap-1 px-6 mb-3">
        <h2 className="mb-4">{tolgee.t("dsr.closedCase.summary")}</h2>
        <span className="mb-2">
          {tolgee.t("dsr.closedCase.closedAs")}{" "}
          <strong>{tolgee.t(`dsr.closedCase.${caseData.closed_as}`)}</strong>
        </span>
        <div className="flex flex-row gap-4">
          <span className="mb-2">
            <strong>{tolgee.t("generic.received")}:</strong>{" "}
            {getBeautyDateTime(caseData.created_at)}{" "}
          </span>
          <span>
            <strong>{tolgee.t("generic.closed")}:</strong>
            {getBeautyDateTime(caseData.closed_at)}
          </span>
        </div>
        {caseExemptions.length > 0 ? (
          <div className="mb-4">
            <div className="mb-2">{tolgee.t("dsr.closedCase.exemptions_applied")}</div>
            <div className="flex flex-col gap-2">
              {caseExemptions.map((item) => (
                <div className="bg-brand-100 p-2 rounded-lg">
                  <TolgeeWrapper translateKey={item.name} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {caseData.outcome.email_content ? (
        <div className="flex justify-between px-6">
          <div>
            <BaseButton onClick={() => setOpenCaseEmail(true)}>
              {tolgee.t("dsr.closedCase.view_closing_email")}
            </BaseButton>
          </div>
        </div>
      ) : (
        ""
      )}

      <BaseModal modalState={[openCaseEmail, setOpenCaseEmail]}>
        <div className="flex flex-col gap-4">
          <TextField
            disabled
            value={caseData.outcome.email_subject}
            label="Subject"
            InputLabelProps={{ shrink: true }}
          />
          <MarkdownEditor readOnly value={caseData.outcome.email_content} />
          <div className="flex justify-end">
            <BaseButton onClick={() => setOpenCaseEmail(false)}>
              {tolgee.t("generic.close")}
            </BaseButton>
          </div>
        </div>
      </BaseModal>
    </>
  );
}
