import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Button, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router";

import { getUrl } from "helpers";
import tolgee from "services/translation";
import { notify } from "store/app";
import { api } from "services";
import { handleError } from "services/api/error";

export default function CustomerSupport() {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm();

  const { onChange, onBlur, name, ref } = register("message");

  const onSubmit = async (data: any) => {
    try {
      await api.business.sendSupportTicket(data?.message);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "generic.message_send",
        }),
        type: "SUCCESS",
      })
    );
    setValue("message", "");
    reset();
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="support-page">
      <div className="box-outerlayout mb-4">
        <h1>
          <T keyName="support.contact_support" />
        </h1>
        <div>
          <h2>
            <T keyName="support.faq" />:
          </h2>
          <div>
            <Accordion sx={{ backgroundColor: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div>
                  <T keyName="support.question_transfer" />
                </div>
              </AccordionSummary>
              <AccordionDetails className="mb-4 flex flex-col items-start">
                <div className="mb-6">
                  <T keyName="support.answer_transfer" />
                </div>
                <Button href={getUrl("FLASK", "/my/account#dangerzone")}>
                  <T keyName="support.account_settings" />
                </Button>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ backgroundColor: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div>
                  <T keyName="support.question_send_invoices" />
                </div>
              </AccordionSummary>
              <AccordionDetails className="mb-4 flex flex-col items-start">
                <div className="mb-6">
                  <T keyName="support.answer_send_invoices" />
                </div>
                <Button component={Link} to="/notifications">
                  <T keyName="support.communication_section" />
                </Button>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ backgroundColor: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div>
                  <T keyName="support.question_pdf_invoice" />
                </div>
              </AccordionSummary>
              <AccordionDetails className="mb-4 flex flex-col items-start">
                <div className="mb-6">
                  <T keyName="support.answer_send_invoices" />
                </div>
                <Button component={Link} to="/billing">
                  <T keyName="support.invoices_section" />
                </Button>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ backgroundColor: "#fafafa" }}>
              <AccordionSummary
                expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <div>
                  <T keyName="support.question_all_invoices" />
                </div>
              </AccordionSummary>
              <AccordionDetails className="mb-4 flex flex-col items-start">
                <div className="mb-6">
                  <T keyName="support.answer_all_invoices" />
                </div>
                <Button component={Link} to="/billing">
                  <T keyName="support.invoices_section" />
                </Button>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="mt-8">
            <T keyName="support.get_in_touch" />:
            <a href="mailto:support@prighter.com">support@prighter.com</a>
          </div>
        </div>
        <div className="mt-8">
          <h4>
            <T keyName="support.your_message" />: *
          </h4>
          <div className="mb-6">
            <TextField
              className="w-full"
              multiline
              minRows={5}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              ref={ref}
            />
          </div>
          <Button onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
            <T keyName="generic.submit" />
          </Button>
        </div>
      </div>
    </div>
  );
}
