import { getStageChecks } from "helpers/case";

import type { ProviderState } from "pages/Client/Case/CaseContext/initialState";
import type { CaseDetail, CheckName, StageNameEnum } from "types/case";

export type ProviderAction =
  | { type: "LOADING" }
  | { type: "FETCH_SUCCESS"; payload: CaseDetail }
  | { type: "FETCH_FAIL" }
  | { type: "UPDATE_UI_STAGE"; payload: StageNameEnum }
  | { type: "UPDATE_UI_CHECK"; payload: CheckName }
  | { type: "SEND_INQUIRY"; payload?: { description?: string; questions?: string[] } }
  | { type: "CLOSE_INQUIRY_MODAL" };

export default function providerReducer(
  state: ProviderState,
  action: ProviderAction
): ProviderState {
  switch (action.type) {
    case "LOADING": {
      return { ...state, loading: true };
    }
    case "FETCH_SUCCESS": {
      return { ...state, initialLoading: false, loading: false, case: action.payload };
    }
    case "FETCH_FAIL": {
      return { ...state, initialLoading: false, loading: false, case: null };
    }
    case "UPDATE_UI_STAGE": {
      if (["PRE_CHECK", "HANDLING"].includes(action.payload)) {
        return {
          ...state,
          ui: {
            ...state.ui,
            stage: action.payload,
            check: getStageChecks(state.case.case_checks, action.payload as any)[0].name,
          },
        };
      }
      return {
        ...state,
        ui: {
          ...state.ui,
          stage: action.payload,
        },
      };
    }
    case "UPDATE_UI_CHECK": {
      return { ...state, ui: { ...state.ui, check: action.payload } };
    }
    case "SEND_INQUIRY": {
      return {
        ...state,
        inquiry: {
          open: true,
          description: action.payload?.description || "",
          questions: action.payload?.questions || [],
        },
      };
    }
    case "CLOSE_INQUIRY_MODAL": {
      return { ...state, inquiry: { open: false, description: "", questions: [] } };
    }
    default: {
      return state;
    }
  }
}
