import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { T } from "@tolgee/react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect } from "react";

import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { BaseIconButton, BaseModal } from "components";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData } from "store/thunks";
import { handleError } from "services/api/error";

type Question = {
  question: string;
  question_i18n: {
    key: string;
  };
  upload_required: boolean;
  translate: boolean;
};

export default function InquiryModal() {
  const [state, dispatch] = useCase();
  const inquiryQuestions = state.inquiry.questions;

  const appDispatch = useDispatch();

  const { handleSubmit, register, reset, watch, control, setValue } = useForm({
    defaultValues: {
      comment: "",
      questions: [
        {
          question: "",
          question_i18n: { key: "" },
          upload_required: false,
          translate: false,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  const questionsData: Question[] = watch("questions");

  const getAvailablePredefinedQuestions = useCallback(
    (index: number) => {
      const selectedQuestions = questionsData
        .slice(0, index)
        .filter((q) => q.translate && q.question_i18n.key)
        .map((q) => q.question_i18n.key);

      return inquiryQuestions.filter((q) => !selectedQuestions.includes(q));
    },
    [inquiryQuestions, questionsData]
  );

  useEffect(() => {
    if (inquiryQuestions.length > 0) {
      reset({
        questions: [
          {
            question: "",
            question_i18n: { key: inquiryQuestions[0] },
            upload_required: false,
            translate: true,
          },
        ],
      });
    } else {
      reset({
        questions: [
          {
            question: "",
            question_i18n: { key: "" },
            upload_required: false,
            translate: false,
          },
        ],
      });
    }
  }, [inquiryQuestions, reset]);

  const getFilteredData = (data: Question[]) => {
    return data
      .filter((i: any) => i.question_i18n.key || i.question)
      .map((i: any) =>
        i.translate && inquiryQuestions.length > 0
          ? {
              question_i18n: i.question_i18n,
              upload_required: i.upload_required,
            }
          : { question: i.question, upload_required: i.upload_required }
      );
  };

  const onSubmit = async (data: { questions: Question[] }) => {
    const filteredData = getFilteredData(data.questions);

    if (filteredData.length === 0) {
      appDispatch(
        notify({
          message: tolgee.t({
            key: "dsr.enter_inquiry",
          }),
          type: "ERROR",
        })
      );
      return;
    }

    try {
      await api.authCase.createInquiries(state.case.uuid, filteredData);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      fetchCaseData()(dispatch, state.case.uuid);
    }

    appDispatch(
      notify({
        message: tolgee.t({
          key: "dsr.inquiry_successfull",
        }),
        type: "SUCCESS",
      })
    );
    reset();
    dispatch({ type: "CLOSE_INQUIRY_MODAL" });
  };

  const addNewQuestion = () => {
    append({
      question: "",
      question_i18n: { key: "" },
      upload_required: false,
      translate: false,
    });
  };

  return (
    <BaseModal
      modalState={[state.inquiry.open, () => dispatch({ type: "CLOSE_INQUIRY_MODAL" })]}
      className="overflow-auto"
      closeEvent={reset}>
      <div className="w-110 space-y-4">
        <h3 className="text-center">
          <T keyName="dsr.make_inquiry" />
        </h3>
        <div className="text-sm text-center">{state.inquiry.description}</div>
        <FormControl fullWidth>
          <div className="border border-black rounded-lg">
            <ul className="max-h-150 overflow-y-auto pl-0 mb-0 mt-0">
              {fields?.map((item, index) => {
                const availableQuestions = getAvailablePredefinedQuestions(index);

                return (
                  <li key={item.id} className="p-4 border-b border-black">
                    <div>
                      <div>
                        {availableQuestions.length > 0 && inquiryQuestions.length !== 1 && (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={questionsData[index].translate}
                                {...register(`questions.${index}.translate`)}
                              />
                            }
                            label={tolgee.t({
                              key: "dsr.choose_question",
                            })}
                          />
                        )}

                        <div className="flex items-center space-x-4">
                          {questionsData[index].translate && availableQuestions.length > 0 ? (
                            <TextField
                              select
                              id="select"
                              className="w-100 mt-4"
                              value={questionsData[index].question_i18n.key}
                              onChange={(event: any) => {
                                setValue(
                                  `questions.${index}.question_i18n.key`,
                                  event.target?.value
                                );
                              }}
                              label="Question">
                              {availableQuestions.map((i) => (
                                <MenuItem value={i} key={i}>
                                  <T keyName={`dsr.inquiry.${i}_title`} />
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <TextField
                              className="w-100"
                              label={tolgee.t({
                                key: "dsr.question",
                              })}
                              multiline
                              rows={3}
                              {...register(`questions.${index}.question`)}
                            />
                          )}
                          {index !== 0 && (
                            <BaseIconButton
                              onClick={() => remove(index)}
                              className="w-5 h-5"
                              size="medium">
                              <FontAwesomeIcon size="xs" icon="trash" color="danger" />
                            </BaseIconButton>
                          )}
                        </div>

                        {questionsData[index].translate &&
                          questionsData[index].question_i18n.key && (
                            <div className="border-dotted border-gray-400 border-2 p-2 mt-2">
                              <T
                                keyName={`dsr.inquiry.${questionsData[index].question_i18n.key}`}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                    <Controller
                      control={control}
                      name={`questions.${index}.upload_required`}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={<Checkbox checked={value} onChange={onChange} />}
                          label={tolgee.t({
                            key: "dsr.upload_required",
                          })}
                        />
                      )}
                    />
                  </li>
                );
              })}
            </ul>
            <Button fullWidth type="button" onClick={addNewQuestion}>
              <FontAwesomeIcon className="mr-4" icon="plus" />
              <T keyName="dsr.add_question" />
            </Button>
          </div>
        </FormControl>

        <div className="flex justify-end pt-2">
          <Button onClick={handleSubmit(onSubmit)}>
            <T keyName="dsr.send_inquiry" />
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}
