import { useEffect, useReducer, useRef } from "react";
import { useParams } from "react-router";

import Nis2Loa from "pages/Client/Case/Nis2/View";
import NotFound from "pages/General/Error/NotFound";
import { fetchCaseData } from "store/thunks";
import AuthorityView from "pages/Client/Case/Authority/View";
import DSRView from "pages/Client/Case/DSR/ClientView/View";
import providerState from "pages/Client/Case/CaseContext/initialState";
import providerReducer from "pages/Client/Case/CaseContext/reducer";
import { DispatchContext, StateContext } from "pages/Client/Case/CaseContext";
import { api } from "services";
import { handleError } from "services/api/error";

export default function CaseView() {
  const { caseUuid } = useParams();
  const [state, dispatch] = useReducer(providerReducer, {
    ...providerState,
    case: { ...providerState.case, uuid: caseUuid },
  });
  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current) {
      fetchCaseData()(dispatch, caseUuid);
      hasRun.current = true;
    }
  }, [caseUuid]);

  useEffect(() => {
    async function markCaseAsRead() {
      try {
        await api.authCase.markCaseAsRead(caseUuid);
      } catch (e) {
        handleError(e);
      } finally {
        fetchCaseData()(dispatch, caseUuid);
      }
    }

    if (state?.case?.state === "NEW") {
      markCaseAsRead();
    }
  }, [caseUuid, state?.case?.state]);

  const getCaseComponent = () => {
    if (state.initialLoading) {
      return <DSRView />;
    }
    if (!state.initialLoading && (!state.case || !state.case.type)) {
      return <NotFound />;
    }

    if (state.case.type === "AUTHORITY_INBOX") {
      return (
        <AuthorityView
          caseData={state.case}
          handleRefresh={() => fetchCaseData()(dispatch, caseUuid)}
        />
      );
    }
    if (state.case.type === "REP_DOC_EU_NIS2_LOA") {
      return <Nis2Loa />;
    }
    if (state.case.type.includes("FADP") || state.case.type.includes("CCPA")) {
      return <DSRView />;
    }

    return <NotFound />;
  };

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{getCaseComponent()}</DispatchContext.Provider>

      {state.loading && <div className="absolute top-0 left-0 w-full h-full min-h-screen z-10" />}
    </StateContext.Provider>
  );
}
