import { Fragment, useMemo } from "react";
import { T } from "@tolgee/react";

import tolgee from "services/translation";
import { VerticalDataValue } from "components";
import { getBeautyDateTime, getFileIcon, getFileName } from "helpers";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
import {
  generateConsumerInfo,
  generateContactData,
  generateUrl,
  getClosedInquiries,
  getSender,
} from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";

import type { FileObject } from "types/general";

export default function DSInfo() {
  const [state] = useCase();
  const caseData = state.case;

  const { files } = caseData;
  const closedInquiries = getClosedInquiries(caseData.inquiries);
  const sender = getSender(caseData.case_actors);
  const consumerInfo = generateConsumerInfo(caseData);
  const senderInfo = generateContactData(sender);
  const otherInfo = useMemo(() => {
    const data = closedInquiries
      .filter((i) => !["ip_address", "email", "acting_for"].includes(i?.question_i18n?.key))
      .map((i) => ({
        key: i?.question_i18n ? i.question_i18n.key : i.question,
        value: i?.answer_i18n
          ? tolgee.t({
              key: i.answer_i18n.key,
            })
          : i.answer,
        answeredAt: i?.answered_at,
      }));
    return data.filter((i) => !!i.value);
  }, [closedInquiries]);

  return (
    <div className="pt-5 mb-4 box-outerlayout @container" data-testid="dsr-info">
      <div>
        <div className="flex flex-col">
          <div className="flex items-center">
            <h2>
              {tolgee.t({
                key: "dsr.ds_information",
              })}
            </h2>
          </div>
          <div className="my-4">
            {tolgee.t({
              key: "dsr.ds_information_description",
            })}
          </div>
        </div>
      </div>
      {caseData.extra.acting_for !== "MYSELF" && (
        <>
          <h3 className="my-0 mb-1">
            {tolgee.t({
              key: `dsr.requester.${caseData.extra.acting_for.toLowerCase()}`,
            })}
          </h3>
          <div className="mb-4">
            <div className="grid @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3">
              {senderInfo.map((i: any) => (
                <VerticalDataValue
                  key={i.key}
                  title={tolgee.t({
                    key: `dsr.${i.key}`,
                  })}
                  value={i.value}
                />
              ))}

              <VerticalDataValue
                title={tolgee.t({
                  key: "dsr.acting_for",
                })}
                value={tolgee.t({
                  key: `ccpa.ds_info.acting_for.${caseData.extra.acting_for.toLowerCase()}`,
                })}
              />
              <br />
              <span className="text-gray-500">
                <T keyName="ccpa.ds_info.requester_proof.info" />
              </span>
            </div>
          </div>
        </>
      )}
      <>
        <h3 className="my-0 mb-1">
          {tolgee.t({
            key: "dsr.data_subject",
          })}
        </h3>
        <div className="mb-4">
          <div className="grid  @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3 ">
            {consumerInfo.map((i: any) => (
              <VerticalDataValue
                key={i.key}
                title={tolgee.t({
                  key: `dsr.${i.key}`,
                })}
                value={i.value}
              />
            ))}

            {otherInfo.map((i) => (
              <Fragment key={i.key}>
                <VerticalDataValue
                  key={i.key}
                  title={tolgee.t({
                    key: i.key,
                  })}
                  value={i.value}
                />
                {i?.answeredAt && (
                  <div className="text-xs italic">{getBeautyDateTime(i.answeredAt)}</div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </>
      <>
        <h3 className="mb-1">
          {tolgee.t({
            key: "dsr.files",
          })}
        </h3>
        <div>
          {files?.map((file: FileObject) => (
            <FileDownloadPreview
              key={file.uuid}
              icon={getFileIcon(file.mime_type)}
              url={generateUrl(caseData.uuid, file.uuid)}
              fileName={getFileName(file)}
              mimeType={file.mime_type}
            />
          ))}
        </div>
      </>
    </div>
  );
}
