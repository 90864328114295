import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";

import type { CaseClosedEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function CaseClosed(props: {
  entityData: CaseClosedEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="ban" color="white" size="lg" />}
      title={<b>{entityData.title}</b>}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      {entityData.name && (
        <div className="p-4">
          <b>
            <T keyName="dsr.check_name" />
          </b>
          {": "}
          <T keyName={`dsr.step_title.${entityData.name.toLowerCase()}`} />
        </div>
      )}
    </HistoryEntryTemplate>
  );
}
