import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { T } from "@tolgee/react";
import { useParams } from "react-router";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

import { BaseTextField } from "components/Input";
import { ccpaPrivacyPolicySnippet, optOutLink } from "const/ccpa";

export default function PrivacyPolicySnippet() {
  const { businessId } = useParams();
  const htmlSnippet = ccpaPrivacyPolicySnippet(optOutLink(businessId));

  const sanitizedHtml = DOMPurify.sanitize(htmlSnippet);

  return (
    <div>
      <h2>
        <T keyName="ccpa.policy_snippet.title" />
      </h2>
      <div className="mb-4">
        <T keyName="ccpa.policy_snippet.description" />
      </div>
      <hr className="h-px my-4 bg-gray-200 border-0" />
      <div>
        <h4 className="mb-4">
          <T keyName="ccpa.policy_snippet.preview" />
        </h4>
        <div>{parse(sanitizedHtml)}</div>
      </div>
      <div className="border rounded-lg border-gray-300 bg-gray-50 p-4 my-4 !w-full">
        <div className="mb-6">
          <BaseTextField containerClassName="w-full" multiline disabled value={htmlSnippet} />
        </div>
        <div className="flex justify-end mt-4">
          <Button onClick={() => navigator?.clipboard?.writeText(htmlSnippet)}>
            <FontAwesomeIcon className="mr-2" icon="copy" color="gray" size="lg" />
            <T keyName="ccpa.copy" />
          </Button>
        </div>
      </div>
    </div>
  );
}
