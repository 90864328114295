import Timeline from "@mui/lab/Timeline";
import { Fragment, useMemo } from "react";
import { captureMessage } from "@sentry/react";

import {
  DefaultEntry,
  CaseCreated,
  CaseFinished,
  CaseAdvanced,
  CaseDeclined,
  CaseCreateInquiries,
  CaseExtendDeadline,
  CaseAnswerInquiries,
  FileAdded,
  EmailVerified,
  EmailReceived,
  SenderChanged,
  StageChanged,
  NewEmail,
  InternalComment,
  CaseClosed,
  NewState,
} from "pages/Client/History/HistoryTemplates";
import tolgee from "services/translation";
import CaseFinalizePreview from "pages/Client/History/HistoryTemplates/CaseFinalizePreview";
import { useCase } from "pages/Client/Case/CaseContext";
import CaseCheckOpened from "pages/Client/History/HistoryTemplates/CaseCheckOpened";

import type { CaseDetail } from "types/case";
import type {
  EmailCommunicationEntity,
  StageChangedEntity,
  SenderChangedEntity,
  InternalCommentEntity,
  NewEmailEntity,
  CaseClosedEntity,
  StateChangedEntity,
  CaseHistory,
  CaseCreatedEntity,
  CaseFinishedEntity,
  CaseAdvancedEntity,
  CaseDeclinedEntity,
  CaseCreateInquiriesEntity,
  CaseExtendDeadlineEntity,
  CaseAnswerInquiriesEntity,
  CaseEmailVerifiedEntity,
  FileAddedEntity,
  DefaultEntity,
  CaseFinalizePreviewEntity,
  CaseCheckOpenedEntity,
} from "types/case/history";

type Props = {
  caseData?: CaseDetail;
};

export default function CaseHistory(props: Props) {
  const { caseData: propsCase } = props;
  const [state] = useCase();

  const caseData = propsCase || state.case;

  const orderedHistories = useMemo(() => {
    if (!caseData?.histories) {
      return [];
    }

    return caseData.histories.sort((a, b) => {
      return (
        new Date(b.content.processed_at).getTime() - new Date(a.content.processed_at).getTime()
      );
    });
  }, [caseData.histories]);

  const getHistoryTemplate = (historyItem: CaseHistory) => {
    switch (historyItem.template.name) {
      case "case.new_ccpa_dsr":
        return (
          <CaseCreated
            caseUuid={caseData.uuid}
            entityData={historyItem.content as CaseCreatedEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.check_finished":
        return (
          <CaseFinished
            entityData={historyItem.content as CaseFinishedEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.stage_advanced":
        return (
          <CaseAdvanced
            entityData={historyItem.content as CaseAdvancedEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.declined":
        return (
          <CaseDeclined
            entityData={historyItem.content as CaseDeclinedEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.create_inquiries":
        return (
          <CaseCreateInquiries
            entityData={historyItem.content as CaseCreateInquiriesEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.extend_deadline":
        return (
          <CaseExtendDeadline
            entityData={historyItem.content as CaseExtendDeadlineEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.answer_inquiries":
        return (
          <CaseAnswerInquiries
            entityData={historyItem.content as CaseAnswerInquiriesEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "authority.files_added":
        return (
          <FileAdded
            caseUuid={caseData.uuid}
            entityData={historyItem.content as FileAddedEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.email_verified":
        return (
          <EmailVerified
            entityData={historyItem.content as CaseEmailVerifiedEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "authority.email_communication":
        return (
          <EmailReceived
            caseUuid={caseData.uuid}
            entityData={historyItem.content as EmailCommunicationEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "authority.stage_changed":
        return (
          <StageChanged
            entityData={historyItem.content as StageChangedEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "authority.change_sender":
        return (
          <SenderChanged
            entityData={historyItem.content as SenderChangedEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "authority.internal_comment":
        return (
          <InternalComment
            entityData={historyItem.content as InternalCommentEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.new_email":
        return (
          <NewEmail
            caseUuid={caseData.uuid}
            entityData={historyItem.content as NewEmailEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.new_state":
        return (
          <NewState
            entityData={historyItem.content as StateChangedEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.authority.closed":
      case "case.check_closed":
        return (
          <CaseClosed
            entityData={historyItem.content as CaseClosedEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.finalize_preview":
        return (
          <CaseFinalizePreview
            entityData={historyItem.content as CaseFinalizePreviewEntity}
            createdAt={historyItem.created_at}
          />
        );
      case "case.check_opened":
        return (
          <CaseCheckOpened
            entityData={historyItem.content as CaseCheckOpenedEntity}
            createdAt={historyItem.created_at}
          />
        );
      default:
        captureMessage(
          `${caseData.type}: ${tolgee.t({
            key: "cases.no_casebox_template",
          })} "${historyItem.template.name}"`
        );

        return (
          <DefaultEntry
            entityData={historyItem.content as DefaultEntity}
            createdAt={historyItem.created_at}
          />
        );
    }
  };

  return (
    <div className="w-full">
      <Timeline position="right">
        {orderedHistories.map((historyItem) => (
          <Fragment key={historyItem.created_at + historyItem.template.name}>
            {getHistoryTemplate(historyItem)}
          </Fragment>
        ))}
      </Timeline>
    </div>
  );
}
