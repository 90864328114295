import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment, TextField } from "@mui/material";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

import { BaseIconButton } from "components/Button";

import type { ChangeEvent } from "react";

export default function SearchField(props: { searchState: [string, any]; className?: string }) {
  const { searchState, className = "" } = props;
  const [search, setSearch] = searchState;

  const handleChange = useCallback(
    (text: ChangeEvent<HTMLInputElement>) => {
      setSearch(text.target.value);
    },
    [setSearch]
  );

  const emptySearch = useCallback(() => setSearch(""), [setSearch]);

  return (
    <div className={clsx(className, "my-2")}>
      <TextField
        data-testid="search-field"
        value={search}
        onChange={handleChange}
        type="search"
        label="Search for a value..."
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <BaseIconButton className="border-full" onClick={emptySearch}>
                <div className="w-4 h-4 flex items-center justify-center">
                  <FontAwesomeIcon icon={faXmark} color="gray" />
                </div>
              </BaseIconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
