import type { CaseContact } from "types/case";
import type { Contact } from "types/contactDirectory";

export const emptyContact = {
  organization: null,
  contact_points: [],
  addresses: [],
  name: null,
  label: null,
  salutation: null,
  comment: null,
} as Contact;

export const emptyActor = {
  acting_as: null,
  contact: emptyContact,
} as CaseContact;
