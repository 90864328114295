import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popover, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch } from "react-redux";
import { useState } from "react";
import moment from "moment";

import { getBeautyDate } from "helpers";
import { getDaysLeftString } from "helpers/date";
import tolgee from "services/translation";
import { notify } from "store/app";
import { api } from "services";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData } from "store/thunks";
import { CaseTagNameEnum } from "types/case";
import { generateI18nKeyByCaseType } from "helpers/case";
import { handleError } from "services/api/error";

import type { Moment } from "moment";
import type { Tag } from "types/case";

export default function DeadlineMenu() {
  const [state, dispatch] = useCase();

  const appDispatch = useDispatch();

  const [deadlineValue, setDeadlineValue] = useState<Moment>();
  const [disableDeadline, setDisableDeadline] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reason, setReason] = useState<string>("");

  const caseData = state.case;
  const { deadline, type } = caseData;

  const requestClosed = caseData.state === "CLOSED";
  const FADPDisabled = requestClosed || !deadlineValue || disableDeadline;
  const extended = !!caseData.tags?.find((i: Tag) => i?.name === CaseTagNameEnum.DEADLINE_EXTENDED);
  const isFADP = type?.includes("FADP");

  const open = Boolean(anchorEl);
  const id = open ? "deadline-popover" : undefined;

  const handleExtendDeadline = async () => {
    try {
      await api.authCase.extendDeadline(caseData.uuid, {
        deadline: isFADP ? deadlineValue.toISOString() : null,
        reason,
      });
    } catch (e) {
      handleError(e);
      return;
    }

    appDispatch(
      notify({
        message: tolgee.t({
          key: generateI18nKeyByCaseType(caseData.type, "deadline_extended"),
        }),
        type: "SUCCESS",
      })
    );
    setReason("");
    setAnchorEl(null);
    fetchCaseData()(dispatch, caseData.uuid);
  };

  const checkDeadlineValue = (val: Moment) => {
    if (val?.isValid() && val.isAfter(deadline)) {
      setDeadlineValue(val);
      setDisableDeadline(false);
    } else {
      setDisableDeadline(true);
    }
  };

  return (
    deadline && (
      <>
        <div className="flex items-center">
          <Button aria-describedby={id} onClick={(event) => setAnchorEl(event.currentTarget)}>
            <span className="hidden @xl:block">
              {getDaysLeftString(deadline)}{" "}
              {tolgee.t({
                key: generateI18nKeyByCaseType(caseData.type, "deadline_left"),
              })}
            </span>
            <span className="block @xl:hidden">
              <FontAwesomeIcon icon="calendar-xmark" size="1x" />
            </span>
            <FontAwesomeIcon className="ml-2" icon={open ? "angle-up" : "angle-down"} />
          </Button>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}>
          <div className="flex flex-col p-4 space-y-4 w-96">
            <h3>
              {tolgee.t({
                key: generateI18nKeyByCaseType(caseData.type, "extend_deadline_title"),
              })}
            </h3>
            <div>
              {tolgee.t({
                key: generateI18nKeyByCaseType(caseData.type, "extend_deadline_description"),
              })}
            </div>
            {!isFADP && (
              <div className="flex space-x-2">
                <b>
                  {tolgee.t({
                    key: generateI18nKeyByCaseType(caseData.type, "current_deadline"),
                  })}
                  :
                </b>
                <div>{getBeautyDate(deadline, true)}</div>
              </div>
            )}
            {isFADP && !extended && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  minDate={moment.utc(deadline).add(1, "day")}
                  format="DD/MM/YYYY"
                  className="w-full"
                  label="Deadline"
                  value={deadlineValue}
                  onChange={(newValue) => checkDeadlineValue(newValue)}
                />
              </LocalizationProvider>
            )}
            {!extended &&
              caseData.type !== "DSR_CCPA_OPTOUT" &&
              caseData.type !== "DSR_CCPA_LIMIT" && (
                <div className="flex flex-col space-y-4">
                  <TextField
                    label={tolgee.t({
                      key: generateI18nKeyByCaseType(caseData.type, "extend_deadline_reason"),
                    })}
                    value={reason}
                    onChange={(event) => setReason(event.target.value)}
                    required
                    disabled={isFADP ? FADPDisabled : requestClosed}
                  />
                  <Button
                    onClick={handleExtendDeadline}
                    disabled={!reason || isFADP ? FADPDisabled : requestClosed}>
                    {tolgee.t({
                      key: generateI18nKeyByCaseType(caseData.type, "extend_deadline_button"),
                    })}
                  </Button>
                </div>
              )}
          </div>
        </Popover>
      </>
    )
  );
}
