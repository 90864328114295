import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router";

import { api } from "services";
import tolgee from "services/translation";
import { dataGridConfig } from "helpers/dataGridConfig";
import { LinkButton, SearchField } from "components";
import { useAppSelector, useAppDispatch } from "store";
import { useDebounce } from "helpers/hooks";
import { getUrl } from "helpers";
import { PRIGHTER_BLUE } from "const/color";
import { handleError } from "services/api/error";
import { UserFilter } from "types/user";
import { changePreference } from "store/app";
import { updateUrlParams } from "helpers/url";

import type { PaginationRequest } from "types/general";

export default function Partners() {
  const { partnersRows = 25 } = useAppSelector((state) => state.app.preferences);
  const dispatch = useAppDispatch();
  const { countries } = useAppSelector((state) => state.app);
  const { roles } = useAppSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 0);
  const [orderedBy, setOrderedBy] = useState(searchParams.get("orderBy") || "name");
  const [newOrder, setNewOrder] = useState(searchParams.get("order") || "asc");
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const debouncedSearchValue = useDebounce<string>(search, 500);

  const handleUrlUpdate = useCallback(
    (params: Record<string, string>) => {
      updateUrlParams(params, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchData = useCallback(
    async (props: PaginationRequest) => {
      setLoading(true);
      const { order_by, page, per_page, order, search_term } = props;
      const desc = order === "desc";

      let res;
      try {
        res = await api.user.list({
          order_by,
          page: page + 1,
          per_page,
          desc,
          search_term,
          user_filter: UserFilter.PARTNER,
        });
      } catch (e) {
        handleError(e);
        setLoading(false);
        return;
      }
      res = res.data;

      const tableData = {
        ...dataGridConfig({ currentPage, rowsPerPage: partnersRows, count: res.count }),
        onSortModelChange: (sortVal: any) => {
          if (sortVal?.length === 0) {
            return;
          }
          const { field } = sortVal[0];
          const { sort } = sortVal[0];
          setOrderedBy(field);
          setNewOrder(sort);
        },
        onPaginationModelChange: (val: any) => {
          setCurrentPage(val.page);
          dispatch(changePreference({ partnersRows: val.pageSize }));
        },
        columns: [
          {
            field: "name",
            headerName: tolgee.t({
              key: "partners.name",
            }),
            flex: 1,
          },
          {
            field: "company_name",
            headerName: tolgee.t({
              key: "partners.company",
            }),
            flex: 1,
          },
          {
            field: "email",
            headerName: tolgee.t({
              key: "partners.email",
            }),
            flex: 1,
          },
          {
            field: "street",
            headerName: tolgee.t({
              key: "partners.address",
            }),
            flex: 2,
            valueGetter: (_: any, params: any) => {
              let address = params.street || "";
              if (params.postal) {
                address += address ? `, ${params.postal}` : params.postal;
              }
              if (params.city) {
                address += address ? `, ${params.city}` : params.city;
              }
              if (params.iso_3166_1_alpha_2) {
                const country = countries?.find(
                  (i) => i.iso_3166_1_alpha_2 === params.iso_3166_1_alpha_2
                );
                if (country) {
                  address += address
                    ? `, ${country?.flag} ${country?.name}`
                    : `${country?.flag} ${country?.name}`;
                }
              }
              return address;
            },
          },
          {
            field: "stripe_customer_id",
            headerName: tolgee.t({
              key: "partners.stripe",
            }),
            flex: 1,
            renderCell: (val: any) => {
              if (val?.value) {
                return (
                  <a href={`https://dashboard.stripe.com/customers/${val?.value}`}>{val?.value}</a>
                );
              }
              return "";
            },
          },
          {
            flex: 1,
            sortable: false,
            renderCell: (params: any) => {
              return (
                <div className="space-x-2 flex">
                  <LinkButton
                    color="NONE"
                    href={getUrl("FLASK", `/administration/customer/${params.id}/invoices`)}>
                    <div className="w-3 h-3 flex items-center justify-center">
                      <FontAwesomeIcon size="lg" icon="file-invoice-dollar" color={PRIGHTER_BLUE} />
                    </div>
                  </LinkButton>
                  <LinkButton
                    color="NONE"
                    href={getUrl("FLASK", `/administration/manage_customer/${params.id}`)}>
                    <div className="w-3 h-3 flex items-center justify-center">
                      <FontAwesomeIcon size="lg" icon="user" color={PRIGHTER_BLUE} />
                    </div>
                  </LinkButton>
                  {roles.some((role) => ["ADMIN", "MANAGER-GHOSTLOGIN"].includes(role.name)) && (
                    <LinkButton
                      color="NONE"
                      href={getUrl("FLASK", `/administration/customer/${params.id}/ghostlogin`)}>
                      <div className="w-3 h-3 flex items-center justify-center">
                        <FontAwesomeIcon size="lg" icon="ghost" color={PRIGHTER_BLUE} />
                      </div>
                    </LinkButton>
                  )}
                </div>
              );
            },
          },
        ],
        rows: res?.result?.map((i) => {
          return { ...i, id: i?.uuid };
        }),
      };

      setData(tableData);
      setLoading(false);
    },
    [countries, currentPage, roles, partnersRows, dispatch]
  );

  useEffect(() => {
    fetchData({
      order_by: orderedBy,
      page: currentPage,
      per_page: partnersRows,
      order: newOrder,
      search_term: debouncedSearchValue,
    });
  }, [currentPage, partnersRows, orderedBy, newOrder, debouncedSearchValue, fetchData]);

  useEffect(() => {
    if (debouncedSearchValue !== searchParams.get("search")) {
      handleUrlUpdate({ search: debouncedSearchValue });
    }
  }, [debouncedSearchValue, handleUrlUpdate, searchParams]);

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="partners">
      {data ? (
        <div className="box-outerlayout">
          <SearchField className="w-full" searchState={[search, setSearch]} />
          <DataGrid
            {...data}
            loading={loading}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
            }}
          />
        </div>
      ) : (
        !loading && (
          <div>
            {tolgee.t({
              key: "billing.no_data",
            })}
          </div>
        )
      )}
    </div>
  );
}
