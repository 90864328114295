import { useNavigate, useParams } from "react-router";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { EditableText, SplitButton, VerticalDataValue } from "components";
import { stringifyAddress } from "helpers/general";
import { REACT_BASE_ROUTE } from "const/env";
import { getUrl } from "helpers";
import CompanyAddressModal from "pages/Client/Company/Components/CompanyAddressModal";
import BusinessUpdateModal from "pages/Admin/ManageUser/ManageBusiness/Components/BusinessUpdateModal";
import tolgee from "services/translation";

import type { SplitButtonItems } from "types/components";
import type { Company } from "types/company";

type Props = { business: Company; handleReload: () => void };

export default function ManageBusinessHeader({ business, handleReload }: Props) {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const [addressModal, setAddressModal] = useState(true);
  const [companyNameModal, setCompanyNameModal] = useState(true);
  const [companySignModal, setCompanySignModal] = useState(true);

  const splitItems: SplitButtonItems = [
    {
      name: "Create DSR (EU)",
      icon: "plus",
      action: () =>
        window.location.assign(getUrl("FLASK", `/dsrtool/manuallyadddsr/${businessId}/dsr_eu`)),
    },
    {
      name: "Create DSR (UK)",
      icon: "plus",
      action: () =>
        window.location.assign(getUrl("FLASK", `/dsrtool/manuallyadddsr/${businessId}/dsr_uk`)),
    },
    {
      name: "Manage Permissions",
      icon: "plus",
      action: () => navigate(`/business/${businessId}/permissions`),
    },
    {
      name: "View Eventlog",
      icon: "plus",
      action: () =>
        window.location.assign(
          getUrl("FLASK", `/administration/eventlog/individual/business/${businessId}`)
        ),
    },
  ];

  const dangerousSplitItems: SplitButtonItems = [
    {
      name: "Delete This Business",
      icon: "trash",
      action: () =>
        window.location.assign(getUrl("FLASK", `/administration/business/${businessId}/delete`)),
    },
  ];

  return (
    <>
      <div className="flex space-x-2">
        <IconButton onClick={() => window.location.assign(business.account_url)}>
          <FontAwesomeIcon icon="chevron-left" />
        </IconButton>
        <h1>
          {business.company_name} ({business.public_id})
        </h1>
      </div>

      <div className="flex flex-row flex-wrap space-x-8 h-min">
        {business.company_name && (
          <VerticalDataValue
            title={tolgee.t({ key: "company_name" })}
            value={
              <EditableText
                value={business.company_name}
                modalState={[companyNameModal, setCompanyNameModal]}>
                <BusinessUpdateModal
                  business={business}
                  onUpdate={() => {
                    handleReload();
                    setCompanyNameModal(true);
                  }}
                  field="company_name"
                />
              </EditableText>
            }
          />
        )}

        {business.config.slug && <VerticalDataValue title="Slug" value={business.config.slug} />}

        <VerticalDataValue title="Public ID" value={business.public_id} />

        {business.company_authorized_to_sign && (
          <VerticalDataValue
            title={tolgee.t({ key: "company_authorized_to_sign" })}
            value={
              <EditableText
                value={business.company_authorized_to_sign}
                modalState={[companySignModal, setCompanySignModal]}>
                <BusinessUpdateModal
                  business={business}
                  onUpdate={() => {
                    handleReload();
                    setCompanySignModal(true);
                  }}
                  field="company_authorized_to_sign"
                />
              </EditableText>
            }
          />
        )}
        <VerticalDataValue
          title="Landing Page"
          value={<a href={`/portal/${businessId}`}>{`${REACT_BASE_ROUTE}/portal/${businessId}`}</a>}
        />

        <VerticalDataValue
          title="Address"
          value={
            <EditableText
              value={stringifyAddress(business.address)}
              modalState={[addressModal, setAddressModal]}>
              <CompanyAddressModal
                onAddressUpdate={() => {
                  handleReload();
                  setAddressModal(true);
                }}
                address={business.address}
              />
            </EditableText>
          }
        />
      </div>

      <div className="space-x-4 my-4">
        <SplitButton items={splitItems} listHeight="424px" state={[0, () => {}]} />
        <SplitButton
          items={dangerousSplitItems}
          listHeight="424px"
          state={[0, () => {}]}
          color="error"
        />
      </div>
    </>
  );
}
