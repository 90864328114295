import type { RawDateTimeString } from "types/date";

export type PaymentInformationResponse = {
  client_secret: string;
  invoice: Invoice;
};

export enum InvoiceStatus {
  OPEN = "OPEN",
  PAID = "PAID",
  VOID = "VOID",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
}

export interface InvoiceList {
  uuid: string;
  created_at: RawDateTimeString;
  updated_at: RawDateTimeString | null;
  user_uuid: string;
  number: string;
  status: InvoiceStatus;
  amount: number;
  currency: Currency;
  fx_amount: number;
  fx_currency: Currency;
  due_date: RawDateTimeString | null;
  paid_at: RawDateTimeString | null;
  voided_at: RawDateTimeString | null;
  void_reason: string;
  uncollectible_at: RawDateTimeString | null;
  voucher_percent_off: number;
  voucher_amount_off: number;
  voucher_description: string;
  referral_payout: boolean;
  email: string;
  company_name: string;
  stripe_customer_id: string;
}

export type Invoice = {
  status: InvoiceStatus;
  finalized_at: RawDateTimeString;
  number: string;
  amount: number;
  currency: Currency;
  user_company_name: string;
  amount_remaining: number;
};

export type PaymentMethod = {
  description: string;
  external_id: string;
};

export type WisePaymentObject = {
  invoice_number: string;
  amount: number;
  currency: Currency;
  transfer_number: string;
  reference: string;
  paid_at: string;
};

export type Currency = "EUR" | "GBP" | "USD" | "CHF" | "SGD";
