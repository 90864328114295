import api from "services/api/base";

import type { SupportContentInterface } from "types/message";
import type { AxiosResponse } from "axios";

export async function postSupportMessage(
  body: SupportContentInterface
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/message/support", body);
}
