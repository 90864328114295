import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputLabel } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";

import { inputStyle } from "pages/Client/EmailCases/Pages/styling";

import type { CcpaForm } from "types/dsr/ccpa";

export default function EmailCasesFormWhen() {
  const { watch, setValue } = useFormContext<CcpaForm>();

  const dateReceived = watch("received_at");

  return (
    <div className="flex flex-col  rounded-[24px] gap-3 border border-prighterblue p-6 ">
      <div className="flex flex-col">
        <div className="flex  justify-between gap-3">
          <h2 className="m-0 text-black font-fire text-[28px] font-normal">
            <T keyName="generic.when" />
          </h2>
          <FontAwesomeIcon icon="question-circle" className="text-prighterblue" />
        </div>
        <p className="text-sm my-1 text-gray-500">
          <T keyName="email_intake.enter_or_select_information" />
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <InputLabel id="demo-simple-select-label">
          <T keyName="generic.date_received" />
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            format="DD/MM/YYYY"
            className="w-full"
            sx={inputStyle}
            value={moment(dateReceived)}
            onChange={(newValue) => setValue("received_at", newValue.toISOString())}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
