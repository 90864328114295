import api from "services/api/base";

import type { NotificationType } from "const/Admin/Notifications";
import type { AxiosResponse } from "axios";
import type { Notification } from "types/notifications";

export async function getNotifications(uuid: string): Promise<AxiosResponse<Notification[]>> {
  return api.get("/v1/notifications", {
    params: { user_uuid: uuid },
  });
}

export async function addNotification(
  uuid: string,
  data: { address: string; type: NotificationType }
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/notifications", data, {
    params: { user_uuid: uuid },
  });
}

export async function deleteNotification(channelUuid: string): Promise<AxiosResponse<unknown>> {
  return api.delete("/v1/notifications", {
    params: { channel_uuid: channelUuid },
  });
}

export async function resendNotification(channelUuid: string): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/notifications/activation-link", null, {
    params: { channel_uuid: channelUuid },
  });
}

export async function testNotification(channelUuid: string): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/notifications/test", null, {
    params: { channel_uuid: channelUuid },
  });
}
