import { T } from "@tolgee/react";
import ReactMarkdown from "react-markdown";

import { LinkButton } from "components";
import { getUrl } from "helpers";
import tolgee from "services/translation";

export default function DoINeedSwissRep() {
  return (
    <div className="relative z-40 max-w-xl mx-auto">
      <h2 className="text-2xl text-brand-900">
        <T keyName="dashboard.swiss.title" />
      </h2>
      <div className="mb-4">
        <ReactMarkdown>{tolgee.t("dashboard.swiss.markdownContent")}</ReactMarkdown>
      </div>
      <LinkButton href={getUrl("FLASK", "/product/swiss-rep/#FAQ")} color="WARNING" fullWidth>
        <T keyName="dashboard.swiss.button" />
      </LinkButton>
    </div>
  );
}
