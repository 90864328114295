import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";

import BaseModal from "components/DataDisplay/Modal/BaseModal";
import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { fetchCaseData } from "store/thunks";
import { useCase } from "pages/Client/Case/CaseContext";
import MarkdownEditor from "components/Input/MarkdownEditor";
import { type Comment, type DeclineTypeEnum, CommentTypeEnum } from "types/case";
import { handleError } from "services/api/error";
import { generateClosedAsTypeBuDeclineType } from "helpers/case";

const defaultValues = {
  subject: "",
  content: "",
};

type Props = {
  buttonTestId?: string;
  modalButtonTestId?: string;
  label: string;
  labelSubmit: string;
  disabled?: boolean;
  declineType: DeclineTypeEnum;
};

export default function DeclineFraudulentButton(props: Props) {
  const { buttonTestId, modalButtonTestId, label, labelSubmit, disabled, declineType } = props;
  const [state, dispatch] = useCase();

  const appDispatch = useDispatch();
  const [comment, setComment] = useState<string>("");
  const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const { register, reset, watch, setValue, getValues } = useForm({
    defaultValues,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.ccpa.getDeclinePreview(state.case.uuid, declineType);
        reset({
          subject: res.data.subject,
          content: res.data.content,
        });
      } catch (e) {
        handleError(e);
      }
    }
    if (openPreviewModal) {
      fetchData();
    }
  }, [declineType, openPreviewModal, reset, state.case.uuid]);

  const handleSubmit = async () => {
    try {
      await api.authCase.comment(state.case.uuid, {
        text: comment,
        type: CommentTypeEnum.FRAUDULENT_EXPLANATION,
      } as Comment);
      reset(defaultValues);
      setOpenCommentModal(false);
      setOpenPreviewModal(true);
    } catch (e) {
      handleError(e);
    }
  };

  const handleDecline = async () => {
    const closedAs = generateClosedAsTypeBuDeclineType(declineType);

    try {
      await api.authCase.declineCase(state.case.uuid, {
        ...getValues(),
        files: [],
        closed_as: closedAs,
      });
    } catch (e) {
      handleError(e);
      return;
    }

    appDispatch(
      notify({
        message: tolgee.t({
          key: "dsr.decline_success",
        }),
        type: "SUCCESS",
      })
    );
    reset(defaultValues);
    fetchCaseData()(dispatch, state.case.uuid);
    setOpenPreviewModal(false);
  };

  return (
    <>
      <Button
        data-testid={buttonTestId}
        onClick={() => setOpenCommentModal(true)}
        sx={{
          backgroundColor: "#C7102E",
          "&:hover": { backgroundColor: "#980c23" },
        }}
        disabled={disabled}>
        {tolgee.t({
          key: `${label}`,
        })}
      </Button>

      <BaseModal modalState={[openCommentModal, setOpenCommentModal]}>
        <div className="w-[64rem] space-y-4">
          <h3 className="text-center m-0">
            <T keyName="dsr.ccpa.decline_as_fraudulent" />
          </h3>
          <p>
            <T keyName="dsr.ccpa.decline_as_fraudulent_description" />
          </p>
          <div className="flex flex-col space-y-2">
            <MarkdownEditor onChange={(value: any) => setComment(value)} value={comment} />
          </div>

          <div className="flex justify-end pt-6">
            <Button
              data-testid={modalButtonTestId}
              sx={{
                backgroundColor: "#C7102E",
                "&:hover": { backgroundColor: "#980c23" },
              }}
              disabled={disabled || comment === ""}
              onClick={handleSubmit}>
              {tolgee.t({
                key: `${labelSubmit}`,
              })}
            </Button>
          </div>
        </div>
      </BaseModal>

      <BaseModal modalState={[openPreviewModal, setOpenPreviewModal]}>
        <div className="w-[64rem] space-y-4">
          <h3 className="text-center m-0">
            <T keyName="dsr.decline_request" />
          </h3>
          <div className="flex flex-col space-y-2">
            <TextField
              InputLabelProps={{ shrink: true }}
              label={tolgee.t({
                key: "generic.subject",
              })}
              {...register("subject")}
            />

            <MarkdownEditor
              onChange={(editorState: any) =>
                setValue("content", editorState, { shouldDirty: true })
              }
              value={watch("content")}
            />
          </div>

          <div className="flex justify-end pt-6">
            <Button
              data-testid={modalButtonTestId}
              sx={{
                backgroundColor: "#C7102E",
                "&:hover": { backgroundColor: "#980c23" },
              }}
              disabled={disabled}
              onClick={handleDecline}>
              {tolgee.t({
                key: `${labelSubmit}`,
              })}
            </Button>
          </div>
        </div>
      </BaseModal>
    </>
  );
}
