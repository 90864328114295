import { Fragment, useCallback, useEffect, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { ExtendedSelectField } from "components/Input";
import { getCountryAsOptions } from "helpers";
import { useAppSelector } from "store";
import { PRIGHTER_BLUE } from "const/color";
import tolgee from "services/translation";

import type { ContactAddress } from "types/contactDirectory";

type Props = {
  addressIcon?: boolean;
  multiple?: boolean;
  contactAddresses: ContactAddress[];
  validationError?: boolean;
  onChange: (contactAddresses: ContactAddress[]) => void;
  shrink?: boolean;
  required?: boolean;
};

export default function AddressForm({
  contactAddresses = [],
  validationError,
  onChange,
  multiple,
  addressIcon,
  shrink,
  required,
}: Props) {
  const [countryOptions, setCountryOptions] = useState([]);
  const { countries } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (countries) {
      const options = getCountryAsOptions(countries);
      setCountryOptions(options);
    }
  }, [countries]);

  const handleIncrease = useCallback(() => {
    onChange([...contactAddresses, { country_iso: "", city: "", street: "", postal: "" }]);
  }, [contactAddresses, onChange]);

  useEffect(() => {
    if (contactAddresses.length === 0) {
      handleIncrease();
    }
  }, [contactAddresses, handleIncrease]);

  const handleChange = (
    index: number,
    objKey: "city" | "street" | "postal" | "country_iso",
    value: any
  ) => {
    const tempPrev = [...contactAddresses];
    tempPrev[index][objKey] = value;
    onChange(tempPrev);
  };

  const handleRemove = (index: number) => {
    const tempContactAddresses = [...contactAddresses];
    tempContactAddresses.splice(index, 1);
    onChange([...tempContactAddresses]);
  };

  const getEndingIcon = (index: number, isLastItem: boolean) => {
    if (isLastItem) {
      return (
        <IconButton data-testid="add-address-button" onClick={handleIncrease} size="medium">
          <FontAwesomeIcon icon="plus" size="sm" />
        </IconButton>
      );
    }

    return (
      <IconButton
        data-testid="remove-address-button"
        onClick={() => handleRemove(index)}
        size="medium">
        <FontAwesomeIcon icon="trash" color="red" size="sm" />
      </IconButton>
    );
  };

  return (
    <>
      {contactAddresses.map((contactAddress, ind) => (
        <Fragment key={contactAddress.uuid || ind}>
          {addressIcon &&
            (ind === 0 ? (
              <FontAwesomeIcon className="self-center" icon="location-dot" color={PRIGHTER_BLUE} />
            ) : (
              <div />
            ))}
          <div
            className={clsx({ "xl:flex-col": shrink }, "flex flex-col xl:flex-row")}
            data-testid="address-block">
            <div className={clsx({ "xl:max-w-md": !shrink }, "flex space-x-4")}>
              <TextField
                className="w-48"
                value={contactAddress.postal}
                onChange={(text: any) => handleChange(ind, "postal", text.target.value)}
                label={tolgee.t({
                  key: "generic.postal",
                })}
                required={required}
                error={!contactAddress.postal ? validationError : false}
              />
              <TextField
                className="w-full"
                value={contactAddress.city}
                onChange={(text: any) => handleChange(ind, "city", text.target.value)}
                label={tolgee.t({
                  key: "generic.city",
                })}
                required={required}
                error={!contactAddress.city ? validationError : false}
              />
            </div>
            <div className="w-4 h-4" />
            <div
              className={clsx(
                { "xl:flex-col xl:space-y-4 xl:space-x-0": shrink, "xl:space-x-4": !shrink },
                "flex flex-col xl:flex-row space-y-4 xl:space-y-0 w-full"
              )}>
              <TextField
                className="w-full grow"
                value={contactAddress.street}
                onChange={(text: any) => handleChange(ind, "street", text.target.value)}
                label={tolgee.t({
                  key: "generic.street",
                })}
                multiline
                maxRows={2}
                required={required}
                error={!contactAddress.street ? validationError : false}
              />

              <div>
                <ExtendedSelectField
                  state={[
                    contactAddress.country_iso,
                    (text: any) => handleChange(ind, "country_iso", text),
                  ]}
                  options={countryOptions}
                  className="min-w-[12rem]"
                  label={tolgee.t({
                    key: "generic.country",
                  })}
                  error={!contactAddress.country_iso ? validationError : false}
                  required={required}
                />
              </div>
            </div>
          </div>
          {multiple && getEndingIcon(ind, ind + 1 === contactAddresses.length)}
        </Fragment>
      ))}
    </>
  );
}
