import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { TolgeeProvider } from "@tolgee/react";
import { useEffect } from "react";

import AppRoutes from "routes";
import { theme, NotificationService } from "services";
import { ProgressBar } from "components";
import tolgee from "services/translation";
import { loadCountries } from "helpers/loadInitialApp";
import DevelopmentWidget from "services/development/DevelopmentWidget";
import { useAppSelector } from "store";
import { DEV_ENV, IS_PROD_DEV } from "const/env";
import { apiInstance } from "services/api";
import LocalStorageService from "services/LocalStorageService";

function App() {
  const { demo, role } = useAppSelector((state) => state.user);
  const { redirect } = useAppSelector((state) => state.app.development);

  useEffect(() => {
    loadCountries();
  }, []);

  useEffect(() => {
    apiInstance.defaults.redirect = redirect;
  }, [redirect]);

  return (
    <>
      <CssBaseline />
      <NotificationService />
      <LocalStorageService />
      {(demo || ["DEV", "ADMIN"].includes(role) || DEV_ENV || IS_PROD_DEV) && <DevelopmentWidget />}
      <ThemeProvider theme={theme}>
        <TolgeeProvider tolgee={tolgee} loadingFallback={<ProgressBar />}>
          <AppRoutes />
        </TolgeeProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
