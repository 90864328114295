import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BaseTextField, ExtendedSelectField } from "components/Input";
import { getCountryAsOptions } from "helpers";
import { useAppSelector } from "store";
import { PRIGHTER_BLUE } from "const/color";
import tolgee from "services/translation";

import type { ContactAddress } from "types/contactDirectory";

type Props = {
  contactAddresses: ContactAddress[];
  onChange: (contactAddresses: ContactAddress[]) => void;
};

export default function ContactAddress({ contactAddresses = [], onChange }: Props) {
  const [countryOptions, setCountryOptions] = useState([]);
  const { countries } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (countries) {
      const options = getCountryAsOptions(countries);
      setCountryOptions(options);
    }
  }, [countries]);

  const handleChange = (
    index: number,
    objKey: "city" | "street" | "postal" | "country_iso",
    value: any
  ) => {
    const tempPrev = [...contactAddresses];
    tempPrev[index][objKey] = value;
    onChange(tempPrev);
  };

  const handleRemove = (index: number) => {
    const tempContactAddresses = [...contactAddresses];
    tempContactAddresses.splice(index, 1);
    onChange([...tempContactAddresses]);
  };

  return (
    <div className="flex flex-col space-y-4">
      {contactAddresses.map((contactAddress, ind) => (
        <div key={contactAddress.uuid || ind} className="flex items-center space-x-4">
          <FontAwesomeIcon icon="location-dot" color={PRIGHTER_BLUE} className="w-14" />

          <BaseTextField
            value={contactAddress.postal}
            onChange={(text: any) => handleChange(ind, "postal", text.target.value)}
            label={tolgee.t({
              key: "generic.postal",
            })}
            containerClassName="w-24"
          />

          <BaseTextField
            value={contactAddress.city}
            onChange={(text: any) => handleChange(ind, "city", text.target.value)}
            label={tolgee.t({
              key: "generic.city",
            })}
            containerClassName="w-full"
          />

          <BaseTextField
            value={contactAddress.street}
            onChange={(text: any) => handleChange(ind, "street", text.target.value)}
            label={tolgee.t({
              key: "generic.street",
            })}
            containerClassName="w-full"
            grow
          />

          <ExtendedSelectField
            state={[
              contactAddress.country_iso,
              (text: any) => handleChange(ind, "country_iso", text),
            ]}
            options={countryOptions}
            className="min-w-[12rem]"
            label={tolgee.t({
              key: "generic.country",
            })}
          />
          <div className="py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50">
            <IconButton
              data-testid="remove-address-button"
              onClick={() => handleRemove(ind)}
              size="large">
              <FontAwesomeIcon icon="trash" className=" text-brand-600" size="xs" />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  );
}
