import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import { BaseModal } from "components/DataDisplay/Modal";

import type { IconName } from "@fortawesome/fontawesome-svg-core";
import type { FileObject } from "types/general";

type Props = { icon: IconName; url: string; fileName: string; file: FileObject };

export default function EmailAttachment({ icon, url, fileName, file }: Props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col justify-start items-center space-y-3 ">
        <FontAwesomeIcon size="2xl" icon={icon} color="white" />
        <div
          className={`flex  space-x-1 max-w-16 ${
            file.mime_type.includes("image") ? " cursor-pointer max-w-20" : ""
          }`}>
          <a
            href={url}
            className="text-white text-sm break-all  line-clamp-3 overflow-hidden text-ellipsis ">
            {fileName}
          </a>
          {file.mime_type.includes("image") && (
            <button type="button" onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon="eye" color="white" />
            </button>
          )}
        </div>
      </div>

      <BaseModal modalState={[open, setOpen]}>
        <div className="w-[40rem] max-h-150 overflow-auto">
          <img src={url} alt="case-file" />
        </div>
      </BaseModal>
    </>
  );
}
