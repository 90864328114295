import { Paper, Slide, Typography, IconButton, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAppSelector } from "store";
import { SUCCESS, DANGER } from "const/color";

export default function NotificationService() {
  const { notification } = useAppSelector((state) => state.app);
  const [visible, setVisible] = useState([]);

  useEffect(() => {
    if (notification.id > 0) {
      setVisible((v) => [{ ...notification, in: true }, ...v]);

      setTimeout(() => {
        setVisible((prevState) => {
          const index = prevState.findIndex((n) => n.id === notification.id);
          return [
            ...prevState.slice(0, index),
            { ...prevState[index], in: false },
            ...prevState.slice(index + 1),
          ];
        });
      }, notification.duration * 1000);
    }
  }, [notification]);

  const handleCloseNotification = (id: any) => {
    setVisible((prevState) => prevState.filter((n) => n.id !== id));
  };

  return (
    <div>
      {visible &&
        visible.map((item, index) => (
          <Slide key={item.id} direction="left" in={item.in} mountOnEnter unmountOnExit>
            <Paper
              elevation={4}
              className="flex items-center py-4 px-6 fixed right-4 group"
              style={{
                zIndex: 9999,
                bottom: 32 + index * 48,
                borderRadius: "8px",
                border: `2px solid ${item.type === "ERROR" ? DANGER.DEFAULT : "#e4e5e6"}`,
              }}>
              {item.type === "SUCCESS" && (
                <FontAwesomeIcon
                  size="lg"
                  className="mr-4"
                  icon="circle-check"
                  color={SUCCESS.DEFAULT}
                />
              )}
              {item.type === "ERROR" && (
                <FontAwesomeIcon
                  size="lg"
                  className="mr-4"
                  icon="circle-xmark"
                  color={DANGER.DEFAULT}
                />
              )}
              {item.type === "PENDING" && <CircularProgress size="1.5rem" className="mr-4" />}
              <div className="invisible group-hover:visible">
                <IconButton
                  style={{
                    height: "30px",
                    width: "30px",
                    border: "2px solid #e4e5e6",
                    color: "black",
                    backgroundColor: "white",
                    position: "absolute",
                    top: "-15px",
                    right: "-16px",
                    paddingLeft: "5px",
                  }}
                  onClick={() => handleCloseNotification(item.id)}
                  size="small">
                  <FontAwesomeIcon icon="xmark" />
                </IconButton>
              </div>

              <Typography color="black" variant="body1" className="pt-1">
                {item.message}
              </Typography>
              {item.details && (
                <pre className="whitespace-pre-wrap">{JSON.stringify(item.details)}</pre>
              )}
            </Paper>
          </Slide>
        ))}
    </div>
  );
}
