import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type { FadpForm } from "types/dsr/fadp";

export async function createDsrFadpCase(
  publicId: string,
  ccpaForm: FadpForm
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/case/dsr", ccpaForm, {
    params: { business_id: publicId },
  });
}
