import { useId, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import LoadingButton from "@mui/lab/LoadingButton";

import { BaseButton, BaseIconButton } from "components/Button";

import type { ChangeEvent } from "react";

type Props = {
  className?: string;
  label?: string;
  required?: boolean;
  multiple?: boolean;
  onSubmit?: (files: File[]) => void;
  loading?: boolean;
};

export default function FileField(props: Props) {
  const {
    label,
    className,
    required,
    multiple = false,
    onSubmit = () => {},
    loading = false,
  } = props;
  const id = useId();
  const hiddenFileInput = useRef(null);
  const [fileList, setFileList] = useState<File[]>([]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleSubmit = () => {
    onSubmit(fileList);
    handleClear();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (multiple) {
      setFileList((oldFileList) => [...oldFileList, ...Array.from(event.target.files)]);
    } else {
      setFileList([event.target.files[0]]);
    }
  };

  const handleClear = () => {
    setFileList([]);
    hiddenFileInput.current.value = "";
    if (hiddenFileInput.current.value) {
      hiddenFileInput.current.type = "text";
      hiddenFileInput.current.type = "file";
    }
  };

  const handleRemove = (file: File) => {
    setFileList((oldFileList) => oldFileList.filter((oldFile) => oldFile !== file));
  };

  return (
    <div className="flex flex-col items-end space-y-4">
      <div className="flex flex-row items-center space-x-2">
        <div
          className={clsx(
            className,
            "border-2 rounded-2xl border-dashed border-prighterblue flex flex-col items-center w-fit p-4"
          )}>
          <BaseButton variant="outlined" onClick={handleClick}>
            {label} {required && "*"}
          </BaseButton>

          <input
            type="file"
            ref={hiddenFileInput}
            className="hidden"
            multiple={multiple}
            onChange={handleChange}
          />

          {multiple ? (
            <label
              htmlFor={id}
              className="flex flex-col items-start space-y-2 p-2 pl-1 pb-1 text-gray-600">
              {fileList.map((file) => (
                <div key={file.name} className="flex flex-row items-center">
                  <div className="border-2 border-dashed rounded-full border-prighterblue py-2 px-4 mr-1">
                    {file.name}
                  </div>
                  <BaseIconButton
                    onClick={() => handleRemove(file)}
                    className="w-5 h-5"
                    size="medium">
                    <FontAwesomeIcon size="xs" icon="xmark" color="danger" />
                  </BaseIconButton>
                </div>
              ))}
              {fileList.length === 0 && `No file${multiple ? "s" : ""} selected`}
            </label>
          ) : (
            <label
              htmlFor={id}
              className="flex flex-col items-start space-y-2 p-2 pl-1 pb-1 text-gray-600">
              {fileList.length === 0 ? `No file${multiple ? "s" : ""} selected` : fileList[0].name}
            </label>
          )}
        </div>
      </div>

      <div className="flex space-x-2">
        {fileList.length > 0 && <BaseButton onClick={handleClear}>Cancel</BaseButton>}
        <LoadingButton
          color="primary"
          variant="contained"
          loading={loading}
          disabled={fileList.length === 0}
          onClick={handleSubmit}>
          Upload Files
        </LoadingButton>
      </div>
    </div>
  );
}
