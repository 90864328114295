import { BrowserRouter } from "react-router";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faAddressCard,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRotateRight,
  faArrowUp,
  faArrowUpFromBracket,
  faBalanceScale,
  faBan,
  faBars,
  faBell,
  faBroadcastTower,
  faBuilding,
  faCalendarDays,
  faCalendarXmark,
  faCaretDown,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleArrowRight,
  faCircleCheck,
  faCircleInfo,
  faCircleNotch,
  faCircleQuestion,
  faCircleXmark,
  faClipboard,
  faClipboardCheck,
  faClock,
  faClockRotateLeft,
  faCode,
  faCog,
  faComment,
  faCopy,
  faCreditCard,
  faDownload,
  faEdit,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeSquare,
  faEnvelopesBulk,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFile,
  faFileExcel,
  faFileImage,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFileSignature,
  faFileText,
  faFileWord,
  faFileZipper,
  faFillDrip,
  faGhost,
  faGift,
  faGlobeAmericas,
  faGlobeEurope,
  faHandSpock,
  faHandshake,
  faHandshakeSlash,
  faHome,
  faHourglass,
  faInbox,
  faInfo,
  faKey,
  faLifeRing,
  faLink,
  faLocationDot,
  faLock,
  faLockOpen,
  faMoneyBillWave,
  faNewspaper,
  faP,
  faPaperPlane,
  faPaperclip,
  faPenFancy,
  faPenToSquare,
  faPencil,
  faPercent,
  faPhone,
  faPlaneArrival,
  faPlus,
  faQuestion,
  faReceipt,
  faRedo,
  faRefresh,
  faRightLeft,
  faSection,
  faShieldAlt,
  faShoppingCart,
  faSignOutAlt,
  faSliders,
  faSpinner,
  faStamp,
  faThList,
  faTimes,
  faTrash,
  faTriangleExclamation,
  faUpload,
  faUser,
  faUserPlus,
  faUsers,
  faWifi,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import { store } from "store";
import { DEV_ENV, IS_PROD_DEV, RELEASE, SENTRY_DSN } from "const/env";
import App from "App";

import "index.css";

if (!DEV_ENV && !IS_PROD_DEV) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.3,
    release: RELEASE,
  });
}

library.add(
  faAddressBook,
  faAddressCard,
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faArrowLeft,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRightFromBracket,
  faArrowRotateRight,
  faArrowUp,
  faArrowUpFromBracket,
  faBalanceScale,
  faBan,
  faBars,
  faBell,
  faBroadcastTower,
  faBuilding,
  faCalendarDays,
  faCalendarXmark,
  faCaretDown,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleArrowRight,
  faCircleCheck,
  faCircleInfo,
  faCircleNotch,
  faCircleQuestion,
  faCircleXmark,
  faClipboard,
  faClipboardCheck,
  faClock,
  faClockRotateLeft,
  faCode,
  faCog,
  faComment,
  faComment,
  faCopy,
  faCopy,
  faCreditCard,
  faDownload,
  faEdit,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeSquare,
  faEnvelopesBulk,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faFile,
  faFileExcel,
  faFileImage,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFileSignature,
  faFileText,
  faFileWord,
  faFileZipper,
  faFillDrip,
  faGhost,
  faGift,
  faGlobeAmericas,
  faGlobeEurope,
  faHandSpock,
  faHandshake,
  faHandshakeSlash,
  faHome,
  faHourglass,
  faInbox,
  faInfo,
  faKey,
  faLifeRing,
  faLink,
  faLocationDot,
  faLock,
  faLockOpen,
  faMoneyBillWave,
  faNewspaper,
  faNewspaper,
  faP,
  faPaperPlane,
  faPaperclip,
  faPenFancy,
  faPenToSquare,
  faPencil,
  faPercent,
  faPhone,
  faPlaneArrival,
  faPlus,
  faQuestion,
  faReceipt,
  faRedo,
  faRefresh,
  faRightLeft,
  faSection,
  faShieldAlt,
  faShoppingCart,
  faSignOutAlt,
  faSliders,
  faSpinner,
  faStamp,
  faThList,
  faTimes,
  faTrash,
  faTriangleExclamation,
  faUpload,
  faUpload,
  faUser,
  faUserPlus,
  faUsers,
  faWifi,
  faXmark
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
