import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import AddLinkIcon from "@mui/icons-material/AddLink";

import tolgee from "services/translation";
import { BaseButton, BaseModal, EditableText } from "components";
import ContactModal from "pages/Client/ContactDirectory/Components/ContactModal";
import { api } from "services";
import { useCase } from "pages/Client/Case/CaseContext";
import { finalizeCheck, reopenCheck } from "store/thunks";
import { generateI18nKey, isCheckFinished } from "helpers/case";
import { handleError } from "services/api/error";

import type { IncorrectSource } from "types/dsr/ccpa";
import type { AutocompleteChangeDetails, AutocompleteChangeReason } from "@mui/material";
import type { Contact, ContactIn } from "types/contactDirectory";

type ISItem = { id: string } & IncorrectSource;

export default function IncorrectSources() {
  const [state, dispatch] = useCase();
  const caseData = state.case;
  const isFinished = isCheckFinished(state);

  const requestClosed = caseData.state === "CLOSED";

  const [incorrectSourceValues, setIncorrectSourceValues] = useState<ISItem[]>([]);
  const [incorrectSourceOptions, setIncorrectSourceOptions] = useState<ISItem[]>([
    { id: "create_contact" } as any,
  ]);
  const [contactData, setContactData] = useState<ContactIn[]>([]);

  const [contactModal, setContactModal] = useState(false);
  const [urlModal, setUrlModal] = useState(false);
  const [search, setSearch] = useState("");
  const [urlContact, setUrlContact] = useState({ url: "", comment: "" });

  const syncFetchedData = useCallback(async () => {
    try {
      const res = await api.contactDirectory.getContacts({
        order_by: "label",
        page: 1,
        per_page: 100,
        search_term: "",
      });

      setContactData(res.data.result);
      setIncorrectSourceOptions([
        { id: "create_contact" } as any,
        ...res.data.result.map((cont: ContactIn) => ({
          id: cont.uuid,
          contact_id: cont.uuid,
          url: "",
          comment: "",
        })),
      ]);
    } catch (e) {
      handleError(e);
    }
  }, []);

  useEffect(() => {
    async function sync() {
      await syncFetchedData();
      setIncorrectSourceValues(
        caseData.outcome.incorrect_sources.map((source) => ({
          id: source.contact_id || `url_contact_${source.url}`,
          ...source,
        }))
      );
    }
    sync();
  }, [syncFetchedData, caseData.outcome.incorrect_sources, state]);

  const generateLabel = (option: string) => {
    if (option === "create_contact") {
      return tolgee.t({
        key: generateI18nKey(caseData.type, state.ui.check, "create_contact"),
      });
    }
    if (option.startsWith("url_contact")) {
      return option.replace("url_contact_", "");
    }
    const contact = contactData.find((c) => c.uuid === option);
    const email = contact?.emails.length ? `(${contact?.emails[0]})` : "";
    return `${contact.label} ${email}`;
  };

  const handleChangeController = (
    _: any,
    __: any,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<ISItem>
  ) => {
    if (details.option.id === ("create_contact" as any)) {
      setContactModal(true);
      setSearch("");
      return;
    }
    if (reason === "selectOption") {
      setIncorrectSourceValues((prevSources) => [...prevSources, details.option]);
    } else if (reason === "removeOption") {
      handleRemoveIncorrectSource(details.option.id);
    }
    setSearch("");
  };

  const handleUpdateIncorrectSource = (id: string, type: "comment" | "url", value: string) => {
    setIncorrectSourceValues((prevSources) =>
      prevSources.map((source) => {
        if (source.id === id) {
          return { ...source, [type]: value };
        }
        return source;
      })
    );
  };

  const handleRemoveIncorrectSource = (id: string) => {
    setIncorrectSourceValues((prevSources) => prevSources.filter((source) => source.id !== id));
  };

  const handleCloseModal = async () => {
    setSearch("");
    syncFetchedData();
    setContactModal(false);
  };

  const handleCompleteIncorrectSources = async () => {
    try {
      await api.ccpa.updateIncorrectSources(caseData.uuid, incorrectSourceValues);
    } catch (e) {
      handleError(e);
      return;
    }
    finalizeCheck()(dispatch, caseData.uuid, state.ui.check);
  };

  const handleAddUrlContact = () => {
    const tempContact = {
      ...urlContact,
      id: `url_contact_${urlContact.url}`,
      contact_id: null as any,
    };
    setIncorrectSourceValues((prevSources) => [...prevSources, tempContact]);
    setUrlContact({ url: "", comment: "" });
    setUrlModal(false);
  };

  const handleParentSubmit = async (contact: Contact) => {
    await syncFetchedData();
    setIncorrectSourceValues((prevSources) => [
      ...prevSources,
      {
        id: contact.uuid,
        contact_id: contact.uuid,
        url: "",
        comment: "",
      },
    ]);
  };

  return (
    <>
      <h3 className="mt-0">
        {tolgee.t({
          key: generateI18nKey(caseData.type, state.ui.check, "title"),
        })}
      </h3>
      {tolgee.t({
        key: generateI18nKey(caseData.type, state.ui.check, "description"),
      })}
      <Autocomplete
        value={incorrectSourceValues}
        options={incorrectSourceOptions}
        disabled={isFinished && !requestClosed}
        getOptionLabel={(option) => generateLabel(option.id)}
        renderTags={() => <></>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "create_controller"),
            })}
            variant="outlined"
          />
        )}
        onChange={handleChangeController}
        inputValue={search}
        onInputChange={(_: any, newSearch: string): any => {
          setSearch(newSearch);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disableClearable
        multiple
      />
      <div className="bg-[#f1f5f9] p-2 rounded-md rounded-tr-none rounded-tl-none">
        <Button
          variant="contained"
          startIcon={<AddLinkIcon />}
          onClick={() => setUrlModal(true)}
          disabled={isFinished && !requestClosed}>
          {tolgee.t({
            key: generateI18nKey(caseData.type, state.ui.check, "add_url_without_contact"),
          })}
        </Button>
      </div>
      <div className="flex flex-row flex-wrap gap-4 mt-4">
        {incorrectSourceValues.map((incorrectSource) => (
          <div>
            <div className="bg-[#f1f5f9] p-2 rounded-md rounded-bl-none flex items-center justify-between">
              {generateLabel(incorrectSource.id)}
              <IconButton
                disabled={isFinished && !requestClosed}
                onClick={() => handleRemoveIncorrectSource(incorrectSource.id)}>
                <FontAwesomeIcon icon="circle-xmark" size="sm" />
              </IconButton>
            </div>

            {!incorrectSource.id.startsWith("url_contact_") && (
              <div className="bg-[#f8fafc] mr-2 p-1 pl-2 flex flex-row items-center">
                <b>
                  {tolgee.t({
                    key: generateI18nKey(caseData.type, state.ui.check, "url"),
                  })}
                  :{" "}
                </b>
                <EditableText
                  disabled={isFinished && !requestClosed}
                  value={incorrectSource.url}
                  confirmation={false}
                  onChange={(text: string) =>
                    handleUpdateIncorrectSource(incorrectSource.id, "url", text)
                  }
                />
              </div>
            )}

            <div className="bg-[#f8fafc] mr-2 p-1 pl-2 flex flex-row items-center">
              <b>
                {tolgee.t({
                  key: "generic.comment",
                })}
                :{" "}
              </b>
              <EditableText
                disabled={isFinished && !requestClosed}
                value={incorrectSource.comment}
                confirmation={false}
                onChange={(text: string) =>
                  handleUpdateIncorrectSource(incorrectSource.id, "comment", text)
                }
              />
            </div>
          </div>
        ))}
      </div>
      <div className="space-x-2 mt-4 flex justify-end">
        {isFinished && !requestClosed && (
          <Button
            onClick={() => reopenCheck()(dispatch, caseData.uuid, state.ui.check)}
            variant="outlined"
            endIcon={<FontAwesomeIcon icon="lock-open" className="!text-sm" />}>
            {tolgee.t({
              key: "general.reopen",
            })}
          </Button>
        )}

        <BaseButton
          disabled={isFinished}
          onClick={handleCompleteIncorrectSources}
          color="success"
          testid="incorrectSources_confirm">
          {tolgee.t({
            key: generateI18nKey(
              caseData.type,
              state.ui.check,
              incorrectSourceValues.length > 0 ? "confirm" : "confirm_without_contact"
            ),
          })}
        </BaseButton>
      </div>
      <ContactModal
        modalState={[contactModal, handleCloseModal]}
        emailRequired
        parentSubmit={handleParentSubmit}
      />
      <BaseModal modalState={[urlModal, setUrlModal]}>
        <div className="flex flex-col space-y-2 min-w-[40rem]">
          <h3 className="m-0">
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "url_modal.title"),
            })}
          </h3>

          <TextField
            label={tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "url"),
            })}
            value={urlContact.url}
            onChange={(event) =>
              setUrlContact((p) => ({
                url: event.target.value,
                comment: p.comment,
              }))
            }
          />
          <TextField
            label={tolgee.t({
              key: "generic.comment",
            })}
            value={urlContact.comment}
            onChange={(event) =>
              setUrlContact((p) => ({
                comment: event.target.value,
                url: p.url,
              }))
            }
          />
          <BaseButton className="self-end" onClick={handleAddUrlContact}>
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "url_modal.submit"),
            })}
          </BaseButton>
        </div>
      </BaseModal>
    </>
  );
}
