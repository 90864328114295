import { T } from "@tolgee/react";

import Snippet from "pages/Client/RepPages/components/Snippet";
import Certificate from "pages/Client/RepPages/components/Certificate";
import Info from "pages/Client/RepPages/UKRep/Components/Info";

export default function UKRep() {
  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="ukrep-page">
      <h1>
        <T keyName="gdpr.ukrep_title" />
      </h1>
      <Info />
      <Snippet type="ukrep" />
      <Certificate type="ukrep" />
    </div>
  );
}
