import { Autocomplete, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import { useCase } from "pages/Client/Case/CaseContext";
import { useDebounce } from "helpers/hooks";
import { api } from "services";
import tolgee from "services/translation";
import ContactModal from "pages/Client/ContactDirectory/Components/ContactModal";
import { generateI18nKey } from "helpers/case";
import { handleError } from "services/api/error";

import type { PaginationRequest } from "types/general";
import type { AutocompleteChangeDetails, AutocompleteChangeReason } from "@mui/material";
import type { ContactIn } from "types/contactDirectory";

type Props = {
  handleAddAndClose: (contactId: string) => void;
  locked: boolean;
};

export default function AddControllerField(data: Readonly<Props>) {
  const { handleAddAndClose, locked } = data;
  const [state] = useCase();
  const caseData = state.case;
  const checkStep = state.ui.check;

  const [contactData, setContactData] = useState<ContactIn[]>(["create_contact" as any]);

  const [search, setSearch] = useState("");
  const debouncedSearchValue = useDebounce<string>(search, 500);
  const [chosenContact, setChosenContact] = useState<ContactIn>(null);
  const [contactModal, setContactModal] = useState(false);

  async function fetchContactEmailData(props: PaginationRequest) {
    const { order_by, page, per_page, search_term } = props;

    try {
      const res = await api.contactDirectory.getContacts({
        order_by,
        page,
        per_page,
        search_term,
      });
      setContactData(["create_contact" as any, ...res.data.result]);
    } catch (e) {
      handleError(e);
    }
  }

  useEffect(() => {
    fetchContactEmailData({
      order_by: "label",
      page: 1,
      per_page: 100,
      search_term: debouncedSearchValue,
    });
  }, [debouncedSearchValue]);

  const generateLabel = (option: ContactIn) => {
    if (option === ("create_contact" as any)) {
      return tolgee.t({
        key: generateI18nKey(caseData.type, checkStep, "create_contact"),
      });
    }
    const email = option.emails.length ? `(${option.emails[0]})` : "";
    return `${option.label} ${email}`;
  };

  const handleAddController = async (newContact: ContactIn) => {
    if (newContact === ("create_contact" as any)) {
      setContactModal(true);
    } else {
      setChosenContact(newContact);
    }
  };

  const handleChangeController = (
    _: any,
    __: any,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<ContactIn>
  ) => {
    if (reason === "selectOption") {
      handleAddController(details.option);
    }
  };

  const handleCloseModal = async () => {
    setSearch("");
    await fetchContactEmailData({
      order_by: "label",
      page: 1,
      per_page: 100,
      search_term: "",
    });
    setContactModal(false);
  };

  return (
    <>
      <span className="whitespace-pre-line">
        {tolgee.t({
          key: generateI18nKey(caseData.type, checkStep, "add_controller_and_close_title"),
        })}
      </span>
      <Autocomplete
        disabled={locked}
        disableClearable
        options={contactData}
        style={{ marginTop: "10px" }}
        getOptionLabel={(option) => generateLabel(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={tolgee.t({
              key: generateI18nKey(caseData.type, checkStep, "create_controller"),
            })}
            required
            variant="outlined"
          />
        )}
        onChange={handleChangeController}
        inputValue={search}
        onInputChange={(_: any, newSearch: string): any => {
          setSearch(newSearch);
        }}
      />
      <div className="self-end mt-4">
        <Button onClick={() => handleAddAndClose(chosenContact.uuid)}>
          {tolgee.t({
            key: generateI18nKey(caseData.type, checkStep, "add_controller_and_handover"),
          })}
        </Button>
      </div>
      <ContactModal modalState={[contactModal, handleCloseModal]} emailRequired />
    </>
  );
}
