import { useState, useEffect } from "react";
import { List, ListItem, ListItemText, Divider, IconButton, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

import { PRIGHTER_BLUE } from "const/color";
import { api } from "services";
import { getBeautyDateTime, getUrl } from "helpers";
import { handleError } from "services/api/error";

import type { SecurityInfo, MFADevice } from "types/security";

export default function Security() {
  const [data, setData] = useState<SecurityInfo>();
  const [reload, setReload] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      let res;
      try {
        res = await api.security.getSecurityInfo();
      } catch (e) {
        handleError(e);
        return;
      }

      setData(res.data);
    };

    fetchData();
  }, [reload]);

  const handleRemoveDevice = async (id: string) => {
    try {
      await api.security.removeDevice(id);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setReload((prev) => prev + 1);
    }
  };

  const renderPermissions = () => {
    if (data?.permissions.length > 0) {
      return data.permissions.map((permission: string) => (
        <div className="flex items-center pt-2 pb-2" key={permission} data-testid="permission">
          <FontAwesomeIcon icon="check" color={PRIGHTER_BLUE} size="lg" />
          <div className="pl-2">{permission}</div>
        </div>
      ));
    }
    return (
      <div data-testid="no-permissions">
        <T keyName="billing.no_active_permissions" />
      </div>
    );
  };

  const renderDevices = () => {
    return (
      <List data-testid="devices-list">
        <ListItem sx={{}} divider key="device_list_header">
          <ListItemText sx={{ width: "45%" }}>
            <T keyName="generic.id" />
          </ListItemText>
          <ListItemText sx={{ width: "25%" }}>
            <T keyName="generic.validated" />
          </ListItemText>
          <ListItemText sx={{ width: "25%" }}>
            <T keyName="generic.last_used" />
          </ListItemText>
          <ListItemText sx={{ width: "5%" }} />
        </ListItem>
        {data.mfa_devices.map((device: MFADevice) => (
          <ListItem key={device.device_id}>
            <ListItemText sx={{ width: "45%" }}>{device.device_id}</ListItemText>
            <ListItemText sx={{ width: "25%" }}>
              {device.validated_at ? (
                getBeautyDateTime(device.validated_at)
              ) : (
                <T keyName="generic.no" />
              )}
            </ListItemText>
            <ListItemText sx={{ width: "25%" }}>
              {device.last_used_at ? (
                getBeautyDateTime(device.last_used_at)
              ) : (
                <T keyName="generic.never" />
              )}
            </ListItemText>
            <ListItemText sx={{ width: "5%" }}>
              <IconButton size="small" onClick={() => handleRemoveDevice(device.device_id)}>
                <FontAwesomeIcon icon="trash" />
              </IconButton>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="security-page">
      <div className="box-outerlayout mb-4">
        <div className="flex align-center flex-col">
          <h1>
            <T keyName="generic.security" />
          </h1>
          <div className="flex justify-end space-x-2 mt-8">
            <Button href={getUrl("FLASK", "/psecurity/changepassword")}>
              <T keyName="generic.change_password" />
            </Button>
          </div>
        </div>
      </div>
      <div className="box-outerlayout mb-4">
        <div className="flex align-center flex-col">
          <h1>
            <T keyName="generic.tfa" />
          </h1>
          <>{data?.mfa_devices.length > 0 && renderDevices()}</>
          <div className="flex justify-end space-x-2 mt-8">
            <Button href={getUrl("FLASK", "/my/security/mfa/add")}>
              <T keyName="generic.add_device" />
            </Button>
          </div>
        </div>
      </div>
      <div className="box-outerlayout mb-4">
        <div className="flex align-center flex-col">
          <h1>
            <T keyName="generic.security_info" />
          </h1>
          <List>
            <ListItem>
              <ListItemText sx={{ width: "50%" }}>
                <T keyName="generic.last_login" />
              </ListItemText>
              <ListItemText sx={{ width: "50%" }}>
                {getBeautyDateTime(data?.last_login_at)}
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText sx={{ width: "50%" }}>
                <T keyName="generic.current_login" />
              </ListItemText>
              <ListItemText sx={{ width: "50%" }}>
                {getBeautyDateTime(data?.current_login_at)}
              </ListItemText>
            </ListItem>
          </List>
          <h2>
            <T keyName="generic.permissions" />
          </h2>
          <div className="pl-4">{renderPermissions()}</div>
        </div>
      </div>
    </div>
  );
}
