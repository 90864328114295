import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

import useEnhancedTable from "helpers/hooks/useEnhancedTable";
import { api } from "services";
import { EnhancedTable } from "components";
import { handleError } from "services/api/error";

import type { TableOnClickEvent, TableRow_ } from "types/components/enhancedTable";

type TableKeys = "Company" | "Name" | "Email" | "Country" | "Actions";

export default function InvalidName() {
  const [tableConfig, setKeys, setValues, setSettings] = useEnhancedTable<TableKeys>();

  useEffect(() => {
    async function getUsersWithInvalidName() {
      let res;
      try {
        res = await api.user.listInvalidNames();
      } catch (e) {
        handleError(e);
        return;
      }

      const tempValues: TableRow_<TableKeys>[] = [];
      const clickables: TableOnClickEvent[] = [];

      res.data.forEach((user) => {
        tempValues.push({
          cells: {
            Company: { displayedString: user.company_name },
            Name: { displayedString: user.name },
            Email: { displayedString: user.email },
            Country: { displayedString: user.company_country },
            Actions: {
              displayedElement: (
                <a
                  href={user.admin_link}
                  className="button-primary rounded-full w-8 h-8 flex justify-center items-center">
                  <FontAwesomeIcon icon="info" size="sm" />
                </a>
              ),
              displayedString: user.admin_link,
            },
          },
          id: user.uuid,
        });
        clickables.push({
          id: user.uuid,
          actionUrl: user.admin_link,
        });
      });

      setValues(tempValues);
      setKeys(["Company", "Name", "Email", "Country", "Actions"]);
      setSettings({
        onClickType: "REDIRECT_FLASK",
        onClickEvents: clickables,
      });
    }

    getUsersWithInvalidName();
  }, [setKeys, setSettings, setValues]);

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="invalid-name-page">
      <h1>
        <T keyName="generic.invalid_name" />
      </h1>
      {tableConfig && <EnhancedTable config={tableConfig} />}
    </div>
  );
}
