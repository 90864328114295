import { T } from "@tolgee/react";

import PrighterLogo from "components/Prighter/PrighterLogo";

export default function EmailCasesDefault() {
  return (
    <div className="h-full 2xl:max-h-[80vh] flex flex-col justify-between items-center bg-prighterdark rounded-3xl py-11 text-white">
      <div className="flex items-center gap-2">
        <div className="p-6 bg-white">
          <PrighterLogo className="h-30" width={60} />
        </div>
        <h4 className="text-2xl font-bold font-tt">
          <T keyName="generic.prighter" />
        </h4>
      </div>
      <div className="text-center">
        <h1 className="font-inter font-bold text-[50px]">
          <T keyName="generic.welcome" />
        </h1>
        <p className="max-w-lg text-xl font-medium font-inter">
          <T keyName="generic.welcome_intro" />
        </p>
      </div>
      <div className="invisible">-</div>
    </div>
  );
}
