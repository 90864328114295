import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { IconName } from "@fortawesome/fontawesome-svg-core";
import type { ReactNode } from "react";

type Props = { icon: IconName; children: ReactNode };

export default function FileDownloadWithIcon({ icon, children }: Props) {
  return (
    <div className="flex mt-4 mb-4 space-x-2 w-fit">
      <div className="flex items-center justify-center w-6 h-6 rounded-full bg-prighterblue">
        <FontAwesomeIcon size="xs" icon={icon} color="white" />
      </div>
      {children}
    </div>
  );
}
