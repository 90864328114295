import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useState } from "react";

import GeoLocation from "pages/Client/Case/DSR/ClientView/Components/GeoLocation";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";

export default function DSGeoLocation() {
  const [state] = useCase();

  const [expanded, setExpanded] = useState(false);

  return (
    state.case.extra.ip_geo_location && (
      <div className="mb-4 box-outerlayout">
        <Accordion
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          sx={{
            backgroundColor: "white",
            border: "none",
            boxShadow: "none",
            borderRadius: 0,
            padding: 0,
          }}
          className="w-full p-2 "
          data-testid="ds-location">
          <AccordionSummary
            expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}
            aria-controls="panel1a-content"
            sx={{ padding: "0" }}
            id="panel1a-header">
            <h2>
              {tolgee.t({
                key: "dsr.ds_location_info",
              })}
            </h2>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <GeoLocation
              geoLocation={state.case.extra.ip_geo_location}
              ipAddress={state.case.extra.ip_address}
              physicalAddress={state.case.extra.address}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    )
  );
}
