import { Skeleton } from "@mui/material";

import PreCheck from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheck";
import PreCheckReview from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/PreCheckReview";
import Handling from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/Handling";
import HandlingReview from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/HandlingReview";
import { useCase } from "pages/Client/Case/CaseContext";
import CheckSteps from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps";

export default function DSRFlow() {
  const [state] = useCase();

  return (
    <div
      className={
        ["PRE_CHECK", "PRE_CHECK_REVIEW", "HANDLING", "HANDLING_REVIEW"].includes(state.ui.stage) &&
        "mb-4 !p-0 !py-4 box-outerlayout"
      }>
      {!state.initialLoading ? (
        <>
          {state.ui.stage === "PRE_CHECK" && <PreCheck />}
          {state.ui.stage === "PRE_CHECK_REVIEW" && <PreCheckReview />}
          {state.ui.stage === "HANDLING" && <Handling />}
          {state.ui.stage === "HANDLING_REVIEW" && <HandlingReview />}{" "}
        </>
      ) : (
        <div className="grid gap-1 px-6 mb-3">
          <Skeleton width="25%" height={28} />
          <Skeleton width="55%" height={28} />
        </div>
      )}

      {!state.initialLoading ? (
        ["PRE_CHECK", "HANDLING"].includes(state.ui.stage) && <CheckSteps />
      ) : (
        <div className="space-y-2">
          <Skeleton variant="rectangular" height={36} />
          <div className="px-8 space-y-2">
            <Skeleton width="20%" height={32} />

            <Skeleton variant="rectangular" height={120} width="90%" />

            <div className="pt-4" />
            <Skeleton variant="rectangular" height={28} />
          </div>

          <div className="flex justify-end space-x-2 pt-4 pr-8">
            <Skeleton variant="rectangular" height={40} width="13%" />
            <Skeleton variant="rectangular" height={40} width="11%" />
          </div>
        </div>
      )}
    </div>
  );
}
