import { Checkbox, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useFormContext } from "react-hook-form";

import tolgee from "services/translation";

import type { Address } from "types/general";
import type { UseState } from "types";
import type { CcpaForm, CcpaFormDsrInquiry } from "types/dsr/ccpa";
import type { DsrConfigCaseType } from "types/case";

type Props = {
  data: CcpaForm;
  additionalInfoState: UseState<string>;
  identificationData: DsrConfigCaseType;
};

export default function AdditionalInfoForm({
  data,
  additionalInfoState,
  identificationData,
}: Props) {
  const { dsrs, actors } = data;
  const [additionalInfo, setAdditionalInfo] = additionalInfoState;
  const { register, watch, setValue } = useFormContext<CcpaForm>();
  const privacy_related = watch("request_privacy_related");
  const identifiable_info = watch("identifiable_info");
  const files = watch("files");

  const getTypesList = () => {
    const dsrsTypes: string[] = [];
    dsrs
      ?.filter((i: any) => i.checked === true)
      .forEach((i: any) => {
        if (i.type === "DSR_CCPA_ACCESS") {
          return i.inquiries.forEach((j: CcpaFormDsrInquiry) => {
            if (j.checked) {
              dsrsTypes.push(j.type);
            }
          });
        }
        return dsrsTypes.push(i.type);
      });
    return dsrsTypes?.map((j: any) => (
      <div key={j}>
        - <T keyName={`dsr.type.${j.toLowerCase()}`} />
      </div>
    ));
  };

  const formatAddressValue = (address: Address) => {
    const value = `${address.postal} ${address.city}, ${address.street}, ${address.country_iso}`;
    return value;
  };

  return (
    <>
      <h2>
        <T keyName="ccpa.summary" />:
      </h2>
      <>
        <h4>
          <T keyName="ccpa.request_type" />
        </h4>
        {getTypesList()}
      </>
      <>
        <h4>
          <T keyName="ccpa.data_subject_info" />
        </h4>
        {Object.entries(identifiable_info).map(([key, value]) => (
          <div>
            {identificationData.required_inputs.find((item) => item === key) ? (
              <option key={key} value={key}>
                {key === "address" && (
                  <>
                    <T keyName={`dsr.settings.ccpa.default_data.${key}`} />:{" "}
                    {formatAddressValue(value as Address)}
                  </>
                )}
                {key !== "address" && (
                  <>
                    <T keyName={`dsr.settings.ccpa.default_data.${key}`} />: {value as string}
                  </>
                )}
              </option>
            ) : (
              ""
            )}
          </div>
        ))}
      </>
      {actors[1]?.acting_for !== "MYSELF" && (
        <>
          <h4>
            <T keyName="ccpa.requester_info" />
          </h4>
          {actors[1]?.acting_for && (
            <div>
              <T keyName="ccpa.acting_for" />:{" "}
              <T keyName={`ccpa.ds_info.acting_for.${actors[1].acting_for.toLowerCase()}`} />
            </div>
          )}
          <div>
            <T keyName="ccpa.name" />: {actors[1].name}
          </div>
          <div>
            <T keyName="ccpa.email" />: {actors[1].email}
          </div>
        </>
      )}
      {files.length > 0 && (
        <div>
          <h4>
            <T keyName="ccpa.uploads" />
          </h4>
          {files.map((i: any) => (
            <div key={i.name}>- {i.name}</div>
          ))}
        </div>
      )}
      <div className="mt-8">
        <b>
          <T keyName="dsr.email.for_contact" />:
        </b>{" "}
        {actors[1].email}
      </div>
      <>
        <h2 className="mt-8">
          <T keyName="ccpa.additional_data" />:
        </h2>

        <TextField
          onChange={(e) => setAdditionalInfo(e.target.value)}
          value={additionalInfo}
          className="w-full"
          label={tolgee.t({
            key: "ccpa.additional_data.additional_info",
          })}
          rows={5}
          multiline
          InputLabelProps={{
            shrink: true,
          }}
        />
      </>
      <button
        className="mt-2"
        type="button"
        onClick={() => setValue("request_privacy_related", !privacy_related)}>
        <div className="flex items-center text-center">
          <Checkbox {...register("request_privacy_related")} checked={privacy_related} />
          <span className="font-bold">
            <T keyName="ccpa.additional_data.request_privacy_related" /> *
          </span>
        </div>
      </button>
    </>
  );
}
