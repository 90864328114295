import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { useSearchParams } from "react-router";

import { api } from "services";
import tolgee from "services/translation";
import { dataGridConfig } from "helpers/dataGridConfig";
import { BaseIconButton, SearchField } from "components";
import { useDebounce } from "helpers/hooks";
import { getBeautyDate } from "helpers";
import { getTimeDifferenceString } from "helpers/date";
import { handleError } from "services/api/error";
import { useAppDispatch, useAppSelector } from "store";
import { changePreference } from "store/app";
import { updateUrlParams } from "helpers/url";

import type { PaginationRequest } from "types/general";

export default function ChurnedClients() {
  const { churnedClientsRows = 25 } = useAppSelector((state) => state.app.preferences);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const debouncedSearchValue = useDebounce<string>(search, 500);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 0);
  const [orderedBy, setOrderedBy] = useState(searchParams.get("orderBy") || "created_at");
  const [newOrder, setNewOrder] = useState(searchParams.get("order") || "desc");

  const handleUrlUpdate = useCallback(
    (params: Record<string, string>) => {
      updateUrlParams(params, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchData = useCallback(
    async (props: PaginationRequest) => {
      setLoading(true);
      const { order_by, page, per_page, order, search_term } = props;
      const desc = order === "desc";
      let res;
      try {
        res = await api.user.listChurned({
          order_by,
          page: page + 1,
          per_page,
          desc,
          search_term,
        });
      } catch (e) {
        handleError(e);
        setLoading(false);
        return;
      }
      res = res.data;

      const tableData = {
        ...dataGridConfig({ currentPage, rowsPerPage: churnedClientsRows, count: res.count }),
        onSortModelChange: (sortVal: any) => {
          if (sortVal?.length === 0) {
            return;
          }
          const { field } = sortVal[0];
          const { sort } = sortVal[0];
          setOrderedBy(field);
          setNewOrder(sort);
        },
        onPaginationModelChange: (val: any) => {
          setCurrentPage(val.page);
          dispatch(changePreference({ churnedClientsRows: val.pageSize }));
        },
        columns: [
          {
            flex: 2,
            field: "company_name",
            headerName: tolgee.t({
              key: "churned.company_name",
            }),
          },
          {
            flex: 2,
            field: "espocrm_id",
            headerName: tolgee.t({
              key: "churned.espo",
            }),
          },
          {
            flex: 2,
            field: "type",
            headerName: tolgee.t({
              key: "churned.type",
            }),
          },
          {
            flex: 2,
            field: "comment",
            headerName: tolgee.t({
              key: "churned.comment",
            }),
          },
          {
            flex: 1,
            field: "signup_date",
            headerName: tolgee.t({
              key: "churned.signup",
            }),
            renderCell: (params: any) => {
              return (
                <Tooltip
                  title={<div>{getTimeDifferenceString(params?.row?.signup_date)} ago</div>}
                  placement="top">
                  <div>{getBeautyDate(params?.row?.signup_date)}</div>
                </Tooltip>
              );
            },
          },
          {
            flex: 1,
            field: "churn_date",
            headerName: tolgee.t({
              key: "churned.churn_date",
            }),
            renderCell: (params: any) => {
              return (
                <Tooltip
                  title={<div>{getTimeDifferenceString(params?.row?.churn_date)} ago</div>}
                  placement="top">
                  <div>{getBeautyDate(params?.row?.churn_date)}</div>
                </Tooltip>
              );
            },
          },
        ],
        rows: res?.result?.map((i: any) => {
          return { ...i, id: i?.uuid };
        }),
      };

      setData(tableData);
      setLoading(false);
    },
    [currentPage, churnedClientsRows, dispatch]
  );

  useEffect(() => {
    fetchData({
      order_by: orderedBy,
      page: currentPage,
      per_page: churnedClientsRows,
      order: newOrder,
      search_term: debouncedSearchValue,
    });
  }, [currentPage, churnedClientsRows, orderedBy, newOrder, debouncedSearchValue, fetchData]);

  useEffect(() => {
    if (debouncedSearchValue !== searchParams.get("search")) {
      handleUrlUpdate({ search: debouncedSearchValue });
    }
  }, [debouncedSearchValue, handleUrlUpdate, searchParams]);

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="partners">
      {data ? (
        <div className="box-outerlayout">
          <div className="flex space-x-4 items-center">
            <BaseIconButton
              className="border-full"
              onClick={() =>
                fetchData({
                  order_by: orderedBy,
                  page: currentPage,
                  per_page: churnedClientsRows,
                  order: newOrder,
                  search_term: debouncedSearchValue,
                })
              }>
              <div className="w-4 h-4 flex items-center justify-center">
                <FontAwesomeIcon icon="refresh" color="gray" />
              </div>
            </BaseIconButton>
            <SearchField className="w-full" searchState={[search, setSearch]} />
          </div>

          <DataGrid
            {...data}
            loading={loading}
            getRowClassName={(params) => `${params.row.is_main_client && "font-bold"}`}
          />
        </div>
      ) : (
        !loading && (
          <div>
            {tolgee.t({
              key: "generic.no_data",
            })}
          </div>
        )
      )}
    </div>
  );
}
