import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

import InternalCommentModal from "pages/Client/Case/DSR/ClientView/Components/InternalCommentModal";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData } from "store/thunks";
import EmailsOverviewModal from "pages/Client/Case/DSR/ClientView/Components/EmailsOverviewModal";
import { generateI18nKeyByCaseType } from "helpers/case";

interface Props {
  isRequestClosed: boolean;
}

export default function DSRMenu({ isRequestClosed }: Props) {
  const [state, dispatch] = useCase();

  const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
  const [openEmailsModal, setOpenEmailsModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onReload = () => {
    fetchCaseData()(dispatch, state.case.uuid);
    handleClose();
  };

  return (
    <div>
      <IconButton className="w-12 h-12" onClick={handleClick}>
        <FontAwesomeIcon icon="ellipsis-v" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <MenuItem onClick={() => setOpenEmailsModal(true)}>
          {tolgee.t({
            key: generateI18nKeyByCaseType(state.case.type, "emails_overview"),
          })}
        </MenuItem>
        {!isRequestClosed ? (
          <MenuItem onClick={() => setOpenCommentModal(true)}>
            {tolgee.t({
              key: generateI18nKeyByCaseType(state.case.type, "internal_comment"),
            })}
          </MenuItem>
        ) : (
          ""
        )}
        <MenuItem
          onClick={() => {
            fetchCaseData()(dispatch, state.case.uuid);
            handleClose();
          }}>
          {tolgee.t({
            key: generateI18nKeyByCaseType(state.case.type, "reload"),
          })}
        </MenuItem>
        <MenuItem component="a" href={state.case.ext_url} target="_blank" rel="noopener noreferrer">
          {tolgee.t({
            key: generateI18nKeyByCaseType(state.case.type, "public_url"),
          })}
        </MenuItem>
      </Menu>
      {!isRequestClosed ? (
        <InternalCommentModal
          internalComment
          handleReload={onReload}
          modalState={[openCommentModal, setOpenCommentModal]}
        />
      ) : (
        ""
      )}
      <EmailsOverviewModal modalState={[openEmailsModal, setOpenEmailsModal]} />
    </div>
  );
}
