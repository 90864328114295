import { T } from "@tolgee/react";

export default function LegalAndCompliance() {
  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <h1>
        <T keyName="legal.legal_and_compliance" />
      </h1>
      <div className="box-outerlayout mb-4">
        <h2>
          <T keyName="legal.prighter_group" />
        </h2>
        <div className="mb-4">
          <div className="font-bold">
            <T keyName="legal.name" />:
          </div>
          <div>
            <T keyName="legal.maetzler_full" />
          </div>
        </div>
        <div className="mb-4">
          <div className="font-bold">
            <T keyName="legal.address" />:
          </div>
          <div>
            <T keyName="legal.address_vienna" />
          </div>
        </div>
        <div>
          <div className="font-bold">
            <T keyName="legal.company_representative" />:
          </div>
          <div>
            <T keyName="legal.representative_data" />
          </div>
        </div>
      </div>
      <div className="box-outerlayout mb-4">
        <h2>
          <T keyName="legal.prighter_services" />
        </h2>
        <div className="mb-4">
          <h3>
            <T keyName="legal.eu_gdpr" />
          </h3>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.provider" />:
            </div>
            <div>
              <T keyName="legal.maetzler_short" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.address" />:
            </div>
            <div>
              <T keyName="legal.address_vienna" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.processing" />:
            </div>
            <div>
              <T keyName="legal.processing_data_eu" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.role" />:
            </div>
            <div>
              <T keyName="legal.role_eu" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.description" />:
            </div>
            <div>
              <T keyName="legal.description_eu" />
            </div>
          </div>
        </div>
        <div className="mb-4">
          <h3>
            <T keyName="legal.uk_gdpr" />
          </h3>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.provider" />:
            </div>
            <div>
              <T keyName="legal.provider_uk" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.address" />:
            </div>
            <div>
              <T keyName="legal.address_uk" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.processing" />:
            </div>
            <div>
              <T keyName="legal.processing_uk" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.role" />:
            </div>
            <div>
              <T keyName="legal.role_uk" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.description" />:
            </div>
            <div>
              <T keyName="legal.description_uk" />
            </div>
          </div>
        </div>

        <div className="mb-4">
          <h3>
            <T keyName="legal.turkey_data" />
          </h3>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.provider" />:
            </div>
            <div>
              <T keyName="legal.provider_turkey" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.address" />:
            </div>
            <div>
              <T keyName="legal.address_turkey" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.processing" />:
            </div>
            <div>
              <T keyName="legal.processing_turkey" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.role" />:
            </div>
            <div>
              <T keyName="legal.role_turkey" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.description" />:
            </div>
            <div>
              <T keyName="legal.description_turkey" />
            </div>
          </div>
        </div>

        <div className="mb-4">
          <h3>
            <T keyName="legal.dsr_tool" />
          </h3>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.provider" />:
            </div>
            <div>
              <T keyName="legal.maetzler_short" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.address" />:
            </div>
            <div>
              <T keyName="legal.address_vienna" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.processing" />:
            </div>
            <div>
              <T keyName="legal.processing_dsr" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.role" />:
            </div>
            <div>
              <T keyName="legal.role_dsr" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.description" />:
            </div>
            <div>
              <T keyName="legal.description_dsr" />
            </div>
          </div>
        </div>

        <div className="mb-4">
          <h3>
            <T keyName="legal.data_breach_tool" />
          </h3>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.provider" />:
            </div>
            <div>
              <T keyName="legal.maetzler_short" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.address" />:
            </div>
            <div>
              <T keyName="legal.address_vienna" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.processing" />:
            </div>
            <div>
              <T keyName="legal.processing_tool" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.role" />:
            </div>
            <div>
              <T keyName="legal.role_dsr" />
            </div>
          </div>
          <div className="mb-4">
            <div className="font-bold">
              <T keyName="legal.description" />:
            </div>
            <div>
              <T keyName="legal.description_tool" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
