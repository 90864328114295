import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type { PaginatedResponse, PaginationRequest } from "types/general";
import type { ProcessorContact } from "types/dsr/ccpa";
import type { Contact, ContactEmail, ContactIn } from "types/contactDirectory";

export async function createContact(
  businessId: string,
  replyData: Contact
): Promise<AxiosResponse<Contact>> {
  return api.post("/v1/contact-directory/contact", replyData, {
    params: { business_id: businessId },
  });
}

export async function getContacts({
  order_by,
  page,
  per_page,
  desc,
  search_term,
}: PaginationRequest): Promise<AxiosResponse<PaginatedResponse<ContactIn>>> {
  return api.get(`/v1/contact-directory/contacts`, {
    params: {
      order_by,
      page,
      per_page,
      desc,
      search_term,
    },
  });
}

export async function getContact(contactUuid: string): Promise<AxiosResponse<Contact>> {
  return api.get("/v1/contact-directory/contact", {
    params: { contact_uuid: contactUuid },
  });
}

export async function updateContact(
  businessId: string,
  contactUuid: string,
  replyData: Contact
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/contact-directory/contact", replyData, {
    params: { business_id: businessId, contact_uuid: contactUuid },
  });
}

export async function deleteContact(
  businessId: string,
  contactUuid: string
): Promise<AxiosResponse<unknown>> {
  return api.delete("/v1/contact-directory/contact", {
    params: { business_id: businessId, contact_uuid: contactUuid },
  });
}

export async function getContactEmail({
  order_by,
  page,
  per_page,
  desc,
  search_term,
}: PaginationRequest): Promise<AxiosResponse<PaginatedResponse<ContactEmail>>> {
  return api.get("/v1/contact-directory/contact-email", {
    params: {
      order_by,
      page,
      per_page,
      desc,
      search_term,
    },
  });
}

export async function dsrAddController(
  caseUUID: string,
  contactUuid: string
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/dsr/controller", null, {
    params: { case_uuid: caseUUID, contact_uuid: contactUuid },
  });
}

export async function dsrAddActor(
  caseUUID: string,
  contactData: ProcessorContact
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/dsr/actor", contactData, {
    params: { case_uuid: caseUUID },
  });
}

export async function dsrAddIncorrectSource(
  caseUUID: string,
  contactData: ProcessorContact
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/dsr/incorrect-sources", contactData, {
    params: { case_uuid: caseUUID },
  });
}

export async function dsrDeleteContact(
  caseUUID: string,
  contactUuid: string
): Promise<AxiosResponse<unknown>> {
  return api.delete("/v1/dsr/actor", {
    params: { case_uuid: caseUUID, case_actor_uuid: contactUuid },
  });
}
