import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type { PaginatedResponse, PaginationRequest } from "types/general";
import type { Voucher, VoucherResult, VoucherData } from "types/vouchers";

export async function getVouchers({
  order_by,
  page,
  per_page,
  desc,
  search_term,
}: PaginationRequest): Promise<AxiosResponse<PaginatedResponse<VoucherData>>> {
  return api.get("/v1/vouchers", {
    params: {
      order_by,
      page,
      per_page,
      desc,
      search_term,
    },
  });
}

export async function createVoucher(voucher: Voucher): Promise<AxiosResponse<VoucherResult>> {
  return api.post("/v1/vouchers", voucher);
}
