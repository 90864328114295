import { faker } from "faker";

import { DEV_ENV } from "const/env";
import { CollectionTypeEnum } from "types/case";

import type { CcpaForm, CcpaFormDsr } from "types/dsr/ccpa";

const initialCcpaObject: CcpaFormDsr[] = [
  {
    checked: false,
    type: "DSR_CCPA_DELETE",
    inquiries: [
      {
        question_i18n: {
          key: "ccpa.rights_form.request_refers_to_following_personal_info",
          lang: "en",
        },
        answer: "",
      },
    ],
    extra: {},
  },
  {
    checked: false,
    type: "DSR_CCPA_ACCESS",
    inquiries: [
      {
        checked: false,
        type: "DSR_CCPA_ACCESS_CATEGORIES",
        question_i18n: {
          key: "ccpa.rights_form.access_request_title",
          lang: "en",
        },
        answer_i18n: {
          key: "ccpa.rights_form.business_sells_info",
        },
      },
      {
        checked: false,
        type: "DSR_CCPA_ACCESS",
        question_i18n: {
          key: "ccpa.rights_form.my_request_concerns_specific_info",
          lang: "en",
        },
        answer: "",
      },
    ],
    extra: {},
  },
  {
    checked: false,
    type: "DSR_CCPA_CORRECT",
    inquiries: [],
    extra: {
      correct: [{ data: "", why: "", new: "" }],
    },
  },
  { checked: false, type: "DSR_CCPA_OPTOUT", inquiries: [], extra: {} },
  { checked: false, type: "DSR_CCPA_LIMIT", inquiries: [], extra: {} },
];

export const ccpaFormInitialValues: CcpaForm = {
  type: CollectionTypeEnum.DSR_CCPA,
  dsrs: initialCcpaObject,
  actors: [
    {
      name: null, // This is identifiable_info.name
      email: null, // This is actors[1].email if acting_for = "MYSELF", null otherwise
      acting_for: "MYSELF", // This is "MYSELF" if acting_for = "MYSELF", otherwise deleted
      acting_as: "DATA_SUBJECT", // This is always "DATA_SUBJECT"
    },
    {
      // This is removed if acting_for = "MYSELF"
      acting_as: "SENDER",
      acting_for: DEV_ENV ? "MYSELF" : null,
      name: DEV_ENV ? faker.person.fullName() : null,
      email: DEV_ENV ? faker.internet.exampleEmail() : null,
    },
  ],
  identifiable_info: {
    // This is the consumer part from UI
  },
  files: [],
  request_privacy_related: DEV_ENV,
};

export const ccpaFormEmptyValues: CcpaForm = {
  type: CollectionTypeEnum.DSR_CCPA,
  dsrs: initialCcpaObject,
  actors: [
    {
      name: null,
      email: null,
      acting_for: "MYSELF",
      acting_as: "DATA_SUBJECT",
    },
    {
      acting_as: "SENDER",
      acting_for: null,
      name: null,
      email: null,
    },
  ],
  identifiable_info: {},
  files: [],
  request_privacy_related: false,
};
