import { useEffect } from "react";

import { EnhancedTable } from "components";
import { api } from "services";
import tolgee from "services/translation";
import useEnhancedTable from "helpers/hooks/useEnhancedTable";
import { handleError } from "services/api/error";

export default function ProductRevenue() {
  const [tableConfig, setKeys, setValues, setSettings] = useEnhancedTable<any>();

  useEffect(() => {
    const fetchData = async () => {
      const monthHeader = tolgee.t({
        key: "report.month",
      });
      const total = tolgee.t({
        key: "report.total",
      });
      const repEU = tolgee.t({
        key: "report.rep_eu",
      });
      const repUK = tolgee.t({
        key: "report.rep_uk",
      });
      const repT = tolgee.t({
        key: "report.report_turkey",
      });
      const nisEU = tolgee.t({
        key: "report.nis_eu",
      });
      const nisUK = tolgee.t({
        key: "report.nis_uk",
      });
      const breach = tolgee.t({
        key: "report.breach",
      });
      const drsArchival = tolgee.t({
        key: "report.dsr_archival",
      });

      let res;
      try {
        res = await api.report.getProductRevenue();
      } catch (e) {
        handleError(e);
        return;
      }

      setKeys([monthHeader, total, repEU, repUK, repT, nisEU, nisUK, breach, drsArchival]);
      const tempValues: any[] = [];
      res.data.forEach((reportItem, idx) => {
        const [year, month] = reportItem.month.split("-");
        const monthIndex = parseInt(month, 10) - 1;
        tempValues.push({
          cells: {
            [monthHeader]: {
              displayedString: reportItem.month.toString(),
              value: new Date(parseInt(year, 10), monthIndex),
            },
            [total]: { displayedString: Number(reportItem.sum).toFixed(2) },
            [repEU]: { displayedString: Number(reportItem.rep_eu).toFixed(2) },
            [repUK]: { displayedString: Number(reportItem.rep_uk).toFixed(2) },
            [repT]: { displayedString: Number(reportItem.rep_turkey).toFixed(2) },
            [nisEU]: { displayedString: Number(reportItem.nis_eu).toFixed(2) },
            [nisUK]: { displayedString: Number(reportItem.nis_uk).toFixed(2) },
            [breach]: { displayedString: Number(reportItem.prighterbreach).toFixed(2) },
            [drsArchival]: { displayedString: Number(reportItem.dsr_archival).toFixed(2) },
          },
          id: idx,
        });
      });

      setValues(tempValues);
      setSettings({
        defaultSort: { key: monthHeader },
      });
    };
    fetchData();
  }, [setKeys, setSettings, setValues]);

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="product-revenue">
      <div className="box-outerlayout">{tableConfig && <EnhancedTable config={tableConfig} />}</div>
    </div>
  );
}
