import { useCallback, useEffect, useId, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, TextField } from "@mui/material";

import { ConfirmationModal, BaseModal } from "components/DataDisplay/Modal";

import type { UseState } from "types";
import type { ReactNode } from "react";

export default function EditableText(props: {
  value: string | ReactNode;
  disabled?: boolean;
  onChange?: (text: string) => void;
  className?: string;
  confirmation?: boolean;
  parentSubmit?: () => void;
  children?: ReactNode;
  modalState?: UseState<boolean>;
}) {
  const {
    value,
    disabled,
    onChange = () => {},
    className,
    parentSubmit = () => {},
    modalState = [],
    confirmation = true,
    children,
  } = props;
  const [clicked, setClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const [initialVal, setInitialVal] = useState(value);
  const [closeModal, setCloseModal] = modalState;
  const id = useId();
  const tr: any = useRef(null);

  useEffect(() => {
    if (clicked && !children && typeof value === "string") {
      tr.current.focus();
      tr.current.selectionStart = value.length;
    }
    if (clicked && children) {
      setOpen(true);
    }
  }, [clicked, children, value]);

  const handleClose = useCallback(() => {
    setCloseModal(false);
    setClicked(false);
    setOpen(false);
  }, [setCloseModal]);

  useEffect(() => {
    if (closeModal === true) {
      handleClose();
    }
  }, [closeModal, handleClose]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof value === "string") {
      onChange(event.target.value);
    }
  };

  const handleClick = () => {
    setClicked(true);
  };

  const handleSubmit = () => {
    if (confirmation) {
      setOpen(true);
    } else {
      handleConfirm();
    }
  };

  const handleConfirm = () => {
    parentSubmit();
    setClicked(false);
    setInitialVal(value);
  };

  const handleNo = () => {
    if (typeof initialVal === "string") {
      onChange(initialVal);
    }
    setClicked(false);
  };

  return (
    <div className={className}>
      {(!clicked || children) && (
        <div className="flex flex-row">
          <div className="w-full min-h-[32px] break-all py-1 pl-2 pr-0.5">{value}</div>
          <IconButton
            size="small"
            className="w-4 h-4 self-start"
            onClick={handleClick}
            disabled={disabled}>
            <FontAwesomeIcon icon="pen-to-square" color="gray" size="xs" />
          </IconButton>
        </div>
      )}

      {clicked && !children && (
        <>
          <TextField
            disabled={disabled}
            className="w-full"
            id={id}
            ref={tr}
            autoFocus
            value={value}
            onChange={handleChange}
            onBlur={handleSubmit}
          />
          {confirmation && (
            <ConfirmationModal
              modalState={[open, setOpen]}
              onYes={handleConfirm}
              onNo={handleNo}
              titleText="Are you sure you want to edit this value?"
            />
          )}
        </>
      )}

      {clicked && children && (
        <BaseModal
          modalState={[open, setOpen]}
          closeEvent={() => {
            setCloseModal(true);
          }}>
          {children}
        </BaseModal>
      )}
    </div>
  );
}
