import { useEffect, useState } from "react";

import { productDict } from "const";
import { BaseModal, FormFileField, SplitButton } from "components";
import { fileToFileObject, getUrl } from "helpers";
import tolgee from "services/translation";
import { api } from "services";
import { useAppDispatch } from "store";
import { notify } from "store/app";
import { handleError } from "services/api/error";

import type { BusinessFilePrivate, LoaFileObject } from "types/business";
import type { SplitButtonItems } from "types/components";
import type { Company, Feature, ProductKey } from "types/company";

type Props = { business: Company };

const filePrivateTypeDict: { [key in Feature]: BusinessFilePrivate } = {
  art27: "EU_LOA",
  ukrep: "UK_LOA",
  niseu: "NIS_EU_LOA",
  nisuk: "NIS_UK_LOA",
  turkeyrep: "TURKEY_REP_LOA",
  swissrep: "SWISS_REP_LOA",
  ccpa: "CCPA_LOA",
  dsr: null,
  prighterbreach: null,
  "dsr-archival": null,
};

export default function RepresentativesTab({ business }: Props) {
  const [open, setOpen] = useState(false);
  const [uploadFileType, setUploadFileType] = useState<BusinessFilePrivate>();
  const [loaFileUrls, setLoaFileUrls] = useState<LoaFileObject>({} as LoaFileObject);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchLoaFiles = async () => {
      try {
        const res = await api.business.getLoaFileUrls(business.public_id);
        setLoaFileUrls(res.data);
      } catch (e) {
        handleError(e);
      }
    };

    fetchLoaFiles();
  }, [business.public_id]);

  const splitItems: (_: string, __: BusinessFilePrivate, ___?: string) => SplitButtonItems = (
    emptyLoaUrl: string,
    fileType: BusinessFilePrivate,
    loaUrl?: string
  ) => {
    const items = [
      loaUrl
        ? {
            name: tolgee.t({ key: "representatives.download_loa" }),
            icon: "download",
            action: () => window.open(loaUrl),
          }
        : null,
      {
        name: tolgee.t({ key: "representatives.upload_loa" }),
        icon: "upload",
        action: () => {
          setUploadFileType(fileType);
          setOpen(true);
        },
      },
      {
        name: tolgee.t({ key: "representatives.download_blank_loa" }),
        icon: "download",
        action: () => window.open(emptyLoaUrl),
      },
    ];

    return items.filter((i) => i) as SplitButtonItems;
  };

  const genericSplitItems: SplitButtonItems = [
    {
      name: "Download blank LOA (combined EU-GDPR-Rep, UK-GDPR-Rep)",
      icon: "download",
      action: () =>
        window.open(
          getUrl("FLASK", `/my/company/${business.public_id}/create-loa-template/pdf/ukrep+art27`)
        ),
    },
    {
      name: "Download blank LOA (combined NISUK, NISEU)",
      icon: "upload",
      action: () =>
        window.open(
          getUrl("FLASK", `/my/company/${business.public_id}/create-loa-template/pdf/nisuk+niseu`)
        ),
    },
    {
      name: "Download blank LOA EU-GDPR-Rep",
      icon: "upload",
      action: () =>
        window.open(
          getUrl("FLASK", `/my/company/${business.public_id}/create-loa-template/pdf/art27`)
        ),
    },
    {
      name: "Download blank LOA UK-Rep",
      icon: "upload",
      action: () =>
        window.open(
          getUrl("FLASK", `/my/company/${business.public_id}/create-loa-template/pdf/ukrep`)
        ),
    },
    {
      name: "Download blank LOA SwissRep",
      icon: "upload",
      action: () =>
        window.open(
          getUrl("FLASK", `/my/company/${business.public_id}/create-loa-template/pdf/swissrep`)
        ),
    },
    {
      name: "Download blank LOA TurkeyRep",
      icon: "upload",
      action: () =>
        window.open(
          getUrl("FLASK", `/my/company/${business.public_id}/create-loa-template/pdf/turkeyrep`)
        ),
    },
  ];

  const handleUploadFile = async (file: File, type: BusinessFilePrivate) => {
    const fileObject = await fileToFileObject(file);

    try {
      await api.business.uploadFile(business.public_id, fileObject, type);
      dispatch(
        notify({
          message: tolgee.t({
            key: "file_uploaded",
          }),
          type: "SUCCESS",
        })
      );
    } catch (e) {
      handleError(e);
    } finally {
      setOpen(false);
      setUploadFileType(null);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col space-y-4 ">
        {business.features
          .filter(
            (i) =>
              Object.keys(productDict).includes(i) &&
              !["prighterbreach", "dsr", "dsr-archival"].includes(i)
          )
          .map((feature) => {
            const product = productDict[feature as ProductKey];
            const emptyLoaUrl = getUrl(
              "FLASK",
              `/my/company/${business.public_id}/create-loa-template/pdf/${feature}`
            );
            const fileKey = filePrivateTypeDict[feature as Feature];

            const loaUrl = Object.keys(loaFileUrls).includes(feature)
              ? loaFileUrls[feature as keyof LoaFileObject]
              : null;

            return (
              <div className="flex items-center text-center justify-between space-x-4 border-b-2 border-gray-400 pb-1">
                <div className="flex space-x-4">
                  <img
                    src={product.certificateUrl(business.public_id)}
                    alt="certificate"
                    width={92}
                  />
                  <span className="self-center">{product.name}</span>
                </div>
                <div>
                  <SplitButton
                    items={splitItems(emptyLoaUrl, fileKey, loaUrl)}
                    listHeight="424px"
                    state={[0, () => {}]}
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className="self-end mt-6">
        <SplitButton items={genericSplitItems} listHeight="424px" state={[0, () => {}]} />
      </div>
      <BaseModal modalState={[open, setOpen]}>
        <h3>Upload {uploadFileType && uploadFileType.replaceAll("_", " ")}</h3>
        <FormFileField
          label={tolgee.t({
            key: "generic.choose_files",
          })}
          onChange={(files: File[]) => handleUploadFile(files[0], uploadFileType) as any}
        />
      </BaseModal>
    </div>
  );
}
