import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { captureException } from "@sentry/react";
import { Button, FormControl, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useFieldArray, useForm } from "react-hook-form";

import { notify } from "store/app";
import tolgee from "services/translation";
import { api } from "services";
import { FormFileField, ProgressBar } from "components";
import { handleError } from "services/api/error";

import type { Inquiry } from "types/case";

export default function OpenInquiries() {
  const dispatch = useDispatch();
  const [data, setData] = useState<Inquiry[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reload, setReload] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [rightAnswers, setRightAnswers] = useState([]);
  const { handleSubmit, register, control, watch, setValue } = useForm();

  useFieldArray({
    control,
    name: "answers",
  });

  const answersData = watch("answers");

  const { caseUuid, businessId } = useParams();

  const fetchData = useCallback(async () => {
    let res;
    try {
      res = await api.authCase.openInquiries(caseUuid, businessId);
    } catch (e) {
      handleError(e);
      return;
    }

    setData(res.data);
    setIsLoading(false);
  }, [businessId, caseUuid]);

  useEffect(() => {
    fetchData();
  }, [fetchData, reload]);

  const handleUploadFiles = (files: File[], idx: number) => {
    const fileArray = Array.from(files);

    const ccpaFileArray = fileArray?.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64String = reader.result;
          resolve(base64String);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    });

    Promise.all(ccpaFileArray)
      .then((resolvedFileArray: any[]) => {
        setValue(
          `answers.${idx}.files`,
          resolvedFileArray?.map((f, ind) => ({
            name: fileArray[ind].name,
            mime_type: fileArray[ind].type,
            content: f,
          }))
        );
      })
      .catch((error) => {
        captureException(error);
      });
  };

  const getQuestion = (inquiry: Inquiry, idx: number) => {
    const lastItem = data.length - 1 === idx;
    return (
      <div
        className={`flex flex-col space-y-4 p-6 ${
          wrongAnswers.includes(inquiry.uuid) ? "bg-danger-100" : ""
        } ${rightAnswers.includes(inquiry.uuid) ? "bg-success-100" : ""} ${
          !lastItem && "border-b border-black"
        }`}
        key={idx}>
        {inquiry.question && <div>{inquiry.question}</div>}
        {inquiry.question_i18n && (
          <div>
            {tolgee.t({
              key: `dsr.inquiry.${inquiry.question_i18n.key}`,
            })}
          </div>
        )}
        <TextField
          label={tolgee.t({
            key: "dsr.answer",
          })}
          {...register(`answers.${idx}.answer` as const)}
        />
        {inquiry.upload_required && (
          <FormFileField
            label="Upload a file"
            className="mt-2"
            value={answersData ? answersData[idx]?.files : ""}
            onChange={(files: any) => handleUploadFiles(files, idx)}
            multiple
            fullWidth
          />
        )}
      </div>
    );
  };

  const getFormattedData = (userResponse: any) => {
    const formattedData = userResponse?.answers?.map((item: any, idx: number) => {
      const uploadRequired = data[idx].upload_required;
      if (uploadRequired && (item.answer === "" || !item.files || item.files?.length === 0)) {
        setWrongAnswers([...wrongAnswers, data[idx].uuid]);
        return null;
      }
      if (!uploadRequired && item.answer === "") {
        return null;
      }
      setRightAnswers([...rightAnswers, data[idx].uuid]);
      return {
        answer: item.answer,
        files: item.files || [],
        uuid: data[idx].uuid,
      };
    });
    return formattedData?.filter((i: any) => i !== null);
  };

  const resetAnswers = () => {
    setTimeout(() => {
      setWrongAnswers([]);
      setRightAnswers([]);
    }, 1500);
    setReload((prev) => prev + 1);
  };

  const onSubmit = async (answers: any) => {
    const userResponse = getFormattedData(answers);
    if (userResponse?.length > 0) {
      try {
        await api.authCase.answerInquiries(caseUuid, userResponse);
      } catch (e) {
        handleError(e);
        return;
      }

      resetAnswers();
      dispatch(
        notify({
          message: tolgee.t({
            key: "dsr.answers_saved",
          }),
          type: "SUCCESS",
        })
      );
    } else {
      resetAnswers();
      dispatch(
        notify({
          message: tolgee.t({
            key: "dsr.no_data",
          }),
          type: "ERROR",
        })
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <ProgressBar />
      ) : (
        <>
          {data?.length > 0 ? (
            <FormControl fullWidth>
              <div className="border border-black rounded-lg">
                {data?.map((i: Inquiry, idx: number) => getQuestion(i, idx))}
              </div>
              <div className="flex justify-end pt-6">
                <Button onClick={handleSubmit(onSubmit)}>
                  <T keyName="generic.save" />
                </Button>
              </div>
            </FormControl>
          ) : (
            <div>
              {tolgee.t({
                key: "dsr.no_open_inquiries",
              })}
            </div>
          )}
        </>
      )}
    </>
  );
}
