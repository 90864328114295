import { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { T } from "@tolgee/react";

import { useDebounce } from "helpers/hooks";
import BaseModal from "components/DataDisplay/Modal/BaseModal";
import TableSearch from "components/Input/TextField/Custom/SearchField";
import EnhancedTableHead from "components/DataDisplay/Table/TableHead";
import { getComparator, stableSort } from "components/DataDisplay/Table/helpers";
import { ArrayWithIndex } from "helpers/general";

import type { TableConfig, TableValueConfig } from "types/components";

export default function EnhancedTable(props: { config: TableConfig }) {
  const { config } = props;
  const { values, keys, settings } = config;
  const [filteredValues, setFilteredValues] = useState([]);

  const navigate = useNavigate();

  const [order, setOrder] = useState<"asc" | "desc">("asc"); // TODO check if you can do a customHook with the 5 state below, also less useState means more performance.
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const debouncedValue = useDebounce<string>(search, 500);

  const [open, setOpen] = useState(false); // modal state
  const [modalChildren, setModalChildren] = useState<React.ReactNode>(<></>); // modal state

  useEffect(() => {
    setFilteredValues(values);
  }, [values]);

  useEffect(() => {
    if (debouncedValue && debouncedValue !== "") {
      setFilteredValues(
        values.filter((value) =>
          Object.values(value).some((val) =>
            val.toLowerCase().includes(debouncedValue.toLowerCase())
          )
        )
      );
    } else if (debouncedValue === "") {
      setFilteredValues(values);
    }
  }, [values, debouncedValue, setFilteredValues]);

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick: any = (id: string) => {
    const actionCell = settings?.onClickEvents?.find((act) => act.id === id);

    if (settings?.onClickType === "REDIRECT") {
      navigate(actionCell?.actionUrl);
    } else if (settings?.onClickType === "MODAL") {
      setModalChildren(actionCell?.modalElement);
      setOpen(true);
    } else if (settings?.onClickType === "REDIRECT_FLASK") {
      window.location.assign(actionCell?.actionUrl);
    }
  };

  const sortList = (): any[] => {
    return settings.shouldSort
      ? stableSort(filteredValues, getComparator(order, orderBy))
      : filteredValues;
  };

  let emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredValues.length - page * rowsPerPage);
  emptyRows -= filteredValues.length === 0 && 1;
  const columnSize = settings.action?.headTitle ? keys.length : keys.length - 1;

  return (
    <div className="w-full mb-5">
      {!settings?.hideSearch && <TableSearch searchState={[search, setSearch]} />}
      <Paper className="w-full -mb-4">
        <TableContainer>
          <Table
            style={{ minWidth: "750px", minHeight: !settings?.hidePagination && "360px" }}
            aria-labelledby="tableTitle"
            aria-label="enhanced table">
            <EnhancedTableHead
              actionTitle={settings.action?.headTitle}
              keys={keys}
              shouldSort={settings?.shouldSort}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {sortList()
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: TableValueConfig) => {
                  const rowValues = Object.entries(row);
                  const actionCell = config.settings?.action?.actionElements?.find(
                    (act) => act.id === row.id
                  ).action;

                  return (
                    <TableRow
                      hover={!!settings?.onClickType}
                      tabIndex={0}
                      key={row.id}
                      className={clsx({
                        "cursor-pointer": settings?.onClickType,
                      })}
                      style={{ height: "60px" }}>
                      {rowValues.map(
                        ([key, value]) =>
                          !settings?.ignoreFields?.includes(key) && (
                            <TableCell key={key} onClick={() => handleClick(row.id)}>
                              {value}
                            </TableCell>
                          )
                      )}
                      {actionCell && (
                        <TableCell
                          align="right"
                          padding="none"
                          style={{ width: settings?.action?.actionColumnWidth }}>
                          {actionCell}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {filteredValues.length === 0 && columnSize !== -1 && (
                <TableRow hover style={{ height: "60px" }}>
                  <TableCell>
                    <T keyName="generic.table.empty_message" />
                  </TableCell>
                  {ArrayWithIndex(columnSize).map((index) => (
                    <TableCell key={index} />
                  ))}
                </TableRow>
              )}
              {!settings?.hideEmptyRows && emptyRows > 0 && (
                <TableRow style={{ height: 60 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!settings?.hidePagination && (
          <TablePagination
            style={{ backgroundColor: "#d9d9d9" }}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredValues.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      {settings.onClickType === "MODAL" && (
        <BaseModal modalState={[open, setOpen]}>{modalChildren}</BaseModal>
      )}
    </div>
  );
}
