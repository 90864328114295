import { useCallback, useEffect, useState } from "react";

import NotificationItem from "components/Admin/ManageUser/Notifications/NotificationItem";
import AddNotification from "components/Admin/ManageUser/Notifications/AddNotification";
import api from "services/api";
import { handleError } from "services/api/error";
import tolgee from "services/translation/tolgee";

import type { NotificationType } from "const/Admin/Notifications";
import type { Notification } from "types/notifications";
import type { User } from "types/user";

interface Props {
  user: User;
}

export default function NotificationChannelsTab(props: Props) {
  const [notifications, setNotifications] = useState<Notification[]>();
  const { user } = props;

  const getNotifications = useCallback(async () => {
    try {
      const res = await api.notifications.getNotifications(user?.uuid);
      setNotifications(res.data);
    } catch (e) {
      handleError(e);
    }
  }, [user?.uuid]);

  const deleteNotification = useCallback(
    async (uuid: string) => {
      try {
        await api.notifications.deleteNotification(uuid);
        setNotifications(notifications?.filter((notification) => notification.uuid !== uuid));
      } catch (e) {
        handleError(e);
      }
    },
    [notifications]
  );

  const addNotification = useCallback(
    async (address: string, type: NotificationType) => {
      try {
        await api.notifications.addNotification(user?.uuid, { address, type });
        getNotifications();
      } catch (e) {
        handleError(e);
      }
    },
    [user?.uuid, getNotifications]
  );

  useEffect(() => {
    getNotifications();
  }, [user, getNotifications]);

  return (
    <div className="max-w-7xl">
      <div className="box-outerlayout">
        <h2>{tolgee.t("manageUser.notification_channels")}</h2>
        <div className="flex flex-col gap-2 mb-4">
          {notifications?.map((notification) => (
            <NotificationItem
              key={notification.uuid}
              notification={notification}
              deleteNotification={deleteNotification}
            />
          ))}
        </div>
        <div>
          <AddNotification onAdd={addNotification} />
        </div>
      </div>
    </div>
  );
}
