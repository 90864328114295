import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { faker } from "faker";

import { api } from "services";
import { BaseButton, PrighterFeedback, ProgressBar } from "components";
import { jurisdictionDict } from "const";
import CustomizedStepper from "components/Navigation/Stepper";
import RightsForm from "pages/Public/Dsr/Fadp/Step1/RightsForm";
import AdditionalInfoForm from "pages/Public/Dsr/Fadp/Step3/AdditionalInfoForm";
import RequestFormContainer from "components/PageSpecific/Dsr/RequestFormContainer";
import IdentificationForm from "pages/Public/Dsr/Fadp/Step2/IdentificationForm";
import { useAppDispatch, useAppSelector } from "store";
import { setError } from "store/app";
import { DEV_ENV } from "const/env";
import { fadpFormInitialValues } from "pages/Public/Dsr/Fadp/fadpRequestObject";
import { useDebounce } from "helpers/hooks";
import { handleError } from "services/api/error";
import { CollectionTypeEnum } from "types/case";

import type { FadpForm } from "types/dsr/fadp";
import type { DSRBusiness } from "types/dsr";

type Props = { step: "type" | "identification" | "send" };

export default function FadpRequestForm({ step }: Props) {
  const { publicId } = useParams();
  const dispatch = useAppDispatch();
  const { countries } = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  const [businessState, setBusinessState] = useState({} as DSRBusiness);
  const [loading, setLoading] = useState(true);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const [isFormSet, setIsFormSet] = useState(false);

  const methods = useForm({ values: fadpFormInitialValues as FadpForm });
  const { watch, trigger, getValues, setValue, reset } = methods;
  const request_privacy_related = watch("request_privacy_related");
  const form = watch();

  const debouncedValue = useDebounce<string>(JSON.stringify(form), 2000);
  const hasRenderedOnce = useRef(false);

  useEffect(() => {
    if (isFormSet && hasRenderedOnce.current) {
      localStorage.setItem("fadp_form", debouncedValue);
    } else if (isFormSet && !hasRenderedOnce.current) {
      hasRenderedOnce.current = true;
    }
  }, [debouncedValue, isFormSet]);

  useEffect(() => {
    async function getBusiness() {
      let res;
      try {
        res = await api.dsr.getDsrBusiness(publicId);
      } catch (e) {
        handleError(e, false, true);
        return;
      }
      res = res.data;

      if (!res.jurisdictions.includes("dsr_fadp")) {
        dispatch(setError("404"));
        return;
      }

      if (countries.length > 0 && DEV_ENV && !getValues("actors.0.address.country_iso")) {
        setValue(
          "actors.0.address.country_iso",
          countries[
            faker.number.int({
              min: 0,
              max: countries.length - 1,
            })
          ].iso_3166_1_alpha_2
        );
      }

      setBusinessState(res);
      setLoading(false);
    }

    reset(JSON.parse(localStorage.getItem("fadp_form") || "{}"));
    setIsFormSet(true);
    getBusiness();
  }, [countries, dispatch, getValues, publicId, reset, setValue]);

  const checkStep = () => {
    switch (step) {
      case "identification":
        return trigger(["actors", "files", "identifiable_info"]);
      case "send":
        return trigger();
      default:
        return true;
    }
  };

  const handleContinue = async () => {
    if (await checkStep()) {
      switch (step) {
        case "type":
          navigate(`/dsr/${publicId}/fadp/identification`);
          break;
        case "identification":
          navigate(`/dsr/${publicId}/fadp/send`);
          break;
        case "send":
        default:
          break;
      }
    }
  };

  const handleBack = () => {
    switch (step) {
      case "identification":
        navigate(`/dsr/${publicId}/fadp`);
        break;
      case "send":
        navigate(`/dsr/${publicId}/fadp/identification`);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async () => {
    const tempValues = getValues();
    const valuesToSubmit: FadpForm = {
      ...tempValues,
      dsrs: tempValues.dsrs.filter((dsr) => dsr.checked),
      type: CollectionTypeEnum.DSR_FADP,
    };

    try {
      await api.fadp.createDsrFadpCase(publicId, valuesToSubmit);
    } catch (e) {
      handleError(e);
      return;
    }

    setDisplaySuccessMessage(true);
    localStorage.removeItem("fadp_form");
  };

  const shouldButtonDisabled = () => {
    if (step === "send" && !request_privacy_related) {
      return true;
    }
    if (step === "type" && form.dsrs.filter((dsr) => dsr.checked).length === 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      {loading && <ProgressBar />}
      {!loading && displaySuccessMessage && (
        <div className="flex justify-center items-center w-full h-screen bg-gray-50">
          <PrighterFeedback>
            <div className="px-16 pb-6 flex flex-col items-center text-center">
              <span className="text-xl text-gray-700">
                <T keyName="dsr.thank_you_for_your_request" />
              </span>
              <span className="text-gray-600 text-sm mt-1 mb-6">
                <T keyName="dsr.please_verify_email_address" />
                <br />
                <T keyName="dsr.ds_request_success_message" />
              </span>
            </div>
          </PrighterFeedback>
        </div>
      )}
      {!loading && !displaySuccessMessage && businessState.public_id && (
        <RequestFormContainer headerState={businessState}>
          <div className="flex justify-between">
            <h1>
              <T
                keyName="dsr.make_a_privacy_related_request_to"
                params={{ company_name: businessState.company_name }}
              />
            </h1>
            <img
              src={jurisdictionDict.dsr_fadp.jurisdiction_lock_icon_url}
              alt="switzerland-logo"
              width="64"
            />
          </div>
          <CustomizedStepper currentStep={step} />

          <FormProvider {...methods}>
            {step === "type" && <RightsForm />}
            {step === "identification" && <IdentificationForm />}
            {step === "send" && <AdditionalInfoForm data={getValues()} />}
          </FormProvider>

          <div className={`flex ${step === "type" ? "justify-end" : "justify-between"} mt-4`}>
            {step !== "type" && (
              <BaseButton onClick={handleBack}>
                <T keyName="generic.back" />
              </BaseButton>
            )}
            <BaseButton
              disabled={shouldButtonDisabled()}
              onClick={step === "send" ? handleSubmit : handleContinue}>
              {step === "send" ? <T keyName="generic.submit" /> : <T keyName="generic.continue" />}
            </BaseButton>
          </div>
        </RequestFormContainer>
      )}
    </>
  );
}
