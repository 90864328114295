import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useDispatch } from "react-redux";

import BaseModal from "components/DataDisplay/Modal/BaseModal";
import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { fetchCaseData } from "store/thunks";
import { useCase } from "pages/Client/Case/CaseContext";
import { generateI18nKey } from "helpers/case";
import { handleError } from "services/api/error";
import { ClosedAsEnum } from "types/dsr/main";

const defaultValues = {
  reason: "",
  comment: "",
};

const defaultReasons = [
  {
    key: "decline_reason_1",
    closed_as: ClosedAsEnum.GENERIC_SPAM,
  },
  {
    key: "decline_reason_2",
    closed_as: ClosedAsEnum.DSR_NOT_PRIVACY_RELATED,
  },
  {
    key: "decline_reason_3",
    closed_as: ClosedAsEnum.GENERIC_SILENTLY,
  },
  {
    key: "decline_reason_4",
    closed_as: ClosedAsEnum.GENERIC_DUPLICATE,
  },
  {
    key: "decline_reason_5",
    closed_as: ClosedAsEnum.GENERIC_TEST,
  },
];

type Props = {
  declineReasons?: { key: string; closed_as: ClosedAsEnum }[];
};

export default function DeclineRequestButton(props: Props) {
  const { declineReasons = defaultReasons } = props;
  const [state, dispatch] = useCase();

  const appDispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const { handleSubmit, register, reset, setValue, watch } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (declineReasons.length === 1) {
      reset({ reason: declineReasons[0].key });
    }
  }, [declineReasons, reset]);

  const reasonData = watch("reason");

  const handleDecline = () => {
    reset();
    setOpen(true);
  };

  const onSubmit = async (data: any) => {
    const declineData = {
      reason: {
        key: `dsr.${data.reason}`,
      },
      comment: data.comment,
      closed_as: defaultReasons.find((item) => item.key === data.reason).closed_as,
    };
    try {
      await api.authCase.declineQuickCase(state.case.uuid, declineData);
    } catch (e) {
      handleError(e);
      return;
    }

    appDispatch(
      notify({
        message: tolgee.t({
          key: "dsr.decline_success",
        }),
        type: "SUCCESS",
      })
    );
    reset(defaultValues);
    setOpen(false);
    fetchCaseData()(dispatch, state.case.uuid);
  };

  return (
    <>
      <Button
        onClick={handleDecline}
        sx={{
          backgroundColor: "#C7102E",
          "&:hover": { backgroundColor: "#980c23" },
        }}>
        {tolgee.t({
          key: generateI18nKey(state.case.type, state.ui.check, "decline_request"),
        })}
      </Button>
      <BaseModal modalState={[open, setOpen]}>
        <div className="w-110 space-y-4">
          <h3 className="text-center m-0">
            {tolgee.t({
              key: generateI18nKey(state.case.type, state.ui.check, "decline_request_title"),
            })}
          </h3>
          <p>
            {tolgee.t({
              key: generateI18nKey(state.case.type, state.ui.check, "decline_request_description"),
            })}
          </p>
          <div className="flex flex-col space-y-2">
            <FormControl>
              <InputLabel>
                {tolgee.t({
                  key: generateI18nKey(state.case.type, state.ui.check, "select_reason"),
                })}
              </InputLabel>
              <Select value={reasonData} onChange={(e) => setValue("reason", e.target.value)}>
                {declineReasons.map((i) => (
                  <MenuItem value={i.key} key={i.key}>
                    {tolgee.t({
                      key: generateI18nKey(state.case.type, state.ui.check, `${i.key}`),
                    })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label={tolgee.t({
                key: "generic.comment",
              })}
              {...register(`comment` as const)}
            />
          </div>

          <p>
            {tolgee.t({
              key: generateI18nKey(state.case.type, state.ui.check, "disclaimer_quick_decline"),
            })}
          </p>

          <div className="flex justify-end pt-6">
            <Button
              sx={{
                backgroundColor: "#C7102E",
                "&:hover": { backgroundColor: "#980c23" },
              }}
              disabled={!reasonData}
              onClick={handleSubmit(onSubmit)}>
              {tolgee.t({
                key: generateI18nKey(state.case.type, state.ui.check, "decline_request_submit"),
              })}
            </Button>
          </div>
        </div>
      </BaseModal>
    </>
  );
}
