import tolgee from "services/translation";
import Info from "pages/Client/RepPages/DSA/Components/Info";
import PublicInformation from "pages/Client/RepPages/DSA/Components/PublicInformation";
import Certificate from "pages/Client/RepPages/DSA/Components/Certificate";
import Location from "pages/Client/RepPages/DSA/Components/Location";

export default function DsaRepresentation() {
  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <h1>{tolgee.t("dsa.title")}</h1>
      <Info />
      <PublicInformation />
      <Certificate />
      <Location />
    </div>
  );
}
