import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import { Button } from "@mui/material";

import api from "services/api";
import { BaseIconButton, EnhancedTable } from "components";
import { getBeautyDate, getUrl } from "helpers";
import { useAppDispatch } from "store";
import { notify } from "store/app";
import { PRIGHTER_BLUE } from "const/color";
import useEnhancedTable from "helpers/hooks/useEnhancedTable";
import { getInvoiceStatusElement } from "helpers/billing";
import { handleError } from "services/api/error";

import type { PaymentMethod } from "types/billing";
import type { TableOnClickEvent, TableRow_ } from "types/components/enhancedTable";

type TableKeys = "Status" | "Date" | "Invoice Number" | "Company Name";

export default function Billing() {
  const dispatch = useAppDispatch();
  const [reload, setReload] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [tableConfig, setKeys, setValues, setSettings] = useEnhancedTable<TableKeys>();

  useEffect(() => {
    async function getPaymentMethods() {
      let res;
      try {
        res = await api.billing.getPaymentMethods();
      } catch (e) {
        handleError(e);
        return;
      }
      setPaymentMethods(res.data);
    }

    async function getInvoices() {
      let res;
      try {
        res = await api.billing.getInvoices();
      } catch (e) {
        handleError(e);
        return;
      }

      const tempValues: TableRow_<TableKeys>[] = [];
      const clickables: TableOnClickEvent[] = [];

      res.data.forEach((invoice) => {
        tempValues.push({
          cells: {
            "Invoice Number": { displayedString: invoice.number },
            "Company Name": { displayedString: invoice.user_company_name },
            Date: {
              displayedString: getBeautyDate(invoice.finalized_at),
              value: invoice.finalized_at,
            },
            Status: {
              displayedElement: getInvoiceStatusElement(invoice.status),
              displayedString: invoice.status,
            },
          },
          id: invoice.number,
        });
        clickables.push({
          id: invoice.number,
          actionUrl: getUrl("FLASK", `/my/invoice/${invoice.number}`),
        });
      });

      setValues(tempValues);
      setKeys(["Invoice Number", "Company Name", "Date", "Status"]);
      setSettings({
        defaultSort: { key: "Date" },
        onClickType: "REDIRECT_FLASK",
        onClickEvents: clickables,
      });
    }

    getPaymentMethods();
    getInvoices();
  }, [reload, setKeys, setSettings, setValues]);

  const handleRemoveCreditCard = async (externalId: string) => {
    let res;
    try {
      res = await api.billing.deletePaymentMethod(externalId);
    } catch (e) {
      handleError(e, true);
      return;
    }

    if (res.data.detail) {
      dispatch(notify({ message: res.data.detail, type: "ERROR" }));
    } else {
      dispatch(notify({ message: "Card removed successfully", type: "SUCCESS" }));
      setReload((prevReload) => prevReload + 1);
    }
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl space-y-4">
      <div className="box-outerlayout">
        <h1>
          <T keyName="generic.payment_methods" />
        </h1>
        <div className="space-y-2 mb-2">
          {paymentMethods.map((paymentMethod) => (
            <div className="box-innerlayout flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <FontAwesomeIcon icon="credit-card" color={PRIGHTER_BLUE} size="sm" />
                <span>{paymentMethod.description}</span>
              </div>

              <BaseIconButton
                confirmPopup
                className="self-center"
                onClick={() => handleRemoveCreditCard(paymentMethod.external_id)}
                size="medium">
                <FontAwesomeIcon icon="trash" color="red" size="sm" />
              </BaseIconButton>
            </div>
          ))}
        </div>
        <Button className="w-fit" href={getUrl("FLASK", "/my/payment/add_source")}>
          <T keyName="generic.new_card" />
        </Button>
      </div>
      <div className="box-outerlayout">
        <h1>
          <T keyName="generic.invoices" />
        </h1>
        {tableConfig && <EnhancedTable config={tableConfig} />}
      </div>
    </div>
  );
}
