import clsx from "clsx";

export default function VerticalDataValue(props: {
  title: string | any;
  value: string | any;
  bold?: boolean;
  titleBold?: boolean;
}) {
  const { title, value, bold = false, titleBold = false } = props;

  return (
    <div className="flex flex-row space-x-2">
      <div className="flex flex-col">
        <div className="mb-1 text-xs font-light">
          <span className={titleBold ? "font-bold text-base" : "font-normal opacity-75"}>
            {title}
          </span>
        </div>
        <div>
          <span className={clsx("whitespace-pre-line", { "font-bold": bold })}>{value}</span>
        </div>
      </div>
    </div>
  );
}
