import type { RawDateString, RawDateTimeString } from "types/date";

export type Address = {
  uuid?: string;
  street?: string;
  postal?: string;
  city?: string;
  country_iso?: string;
};

export type Country = {
  uuid?: string;
  name?: string;
  flag?: string;
  tld?: string;
  iso_3166_1_alpha_2?: string;
};

export enum RepLocationType {
  DSA = "DSA",
  GDPR = "GDPR",
  UK_GDPR = "UK_GDPR",
}
export interface RepLocations {
  austria: string[];
  germany: string[];
  ireland: string[];
  spain: string[];
  portugal: string[];
  bulgaria: string[];
  netherlands: string[];
  sweden: string[];
  romania: string[];
  france: string[];
  lithuania: string[];
}

export type FileObject = {
  uuid?: string;
  name: string;
  name_original?: string;
  type?: string;
  url?: string;
  cdn_path?: string;
  mime_type?: "application/pdf" | string;
  size?: number;
  content?: string;
  created_at?: RawDateTimeString | RawDateString;
  updated_at?: RawDateTimeString | RawDateString;
};

export type PaginatedResponse<T> = {
  count: number;
  per_page: number;
  page: number;
  result: T[];
};

export type PaginationRequest = {
  order?: string;
  order_by: string;
  page?: number;
  per_page?: number;
  desc?: boolean;
  search_term: string;
  status?: string;
};
