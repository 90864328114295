import { captureException } from "@sentry/react";

import { getUrl } from "helpers";
import { jurisdictionDict } from "const";
import {
  nis2LoaSignStepsOrder,
  handlingStepsOrder,
  preCheckStepsOrder,
  nis2LoaReviewStepsOrder,
  nis2LoaClientReviewStepsOrder,
} from "const/dsr";
import { stringifyAddress } from "helpers/general";
import tolgee from "services/translation";
import { ClosedAsEnum, type DsrCollectionItem, type DSRJurisdictionType } from "types/dsr/main";
import { DeclineTypeEnum } from "types/case";

import type { BusinessFilePrivate } from "types/business";
import type { ProviderState } from "pages/Client/Case/CaseContext/initialState";
import type {
  BusinessWithCase,
  CaseCheck,
  CaseContact,
  CaseDecisionEnum,
  CaseDetail,
  CaseType,
  CheckName,
  Inquiry,
  StageNameEnum,
} from "types/case";

export function getCaseSender(caseContacts: CaseContact[]): CaseContact {
  return caseContacts.find((ca) => ca.acting_as === "SENDER");
}

export function getCaseRecipient(caseContacts: CaseContact[]): CaseContact {
  return caseContacts.find((ca) => ca.acting_as === "RECIPIENT");
}

export function getCaseControllerProcessorDecision(
  caseChecks: CaseCheck[]
): "CONTROLLER" | "PROCESSOR" {
  return caseChecks.find((check) => check.name === "CONTROLLER_PROCESSOR")?.case_decisions[0]
    ?.decision.name as CaseDecisionEnum.CONTROLLER | CaseDecisionEnum.PROCESSOR;
}

export function beautifyDSRequestName(case_: BusinessWithCase): string {
  if (case_.type.startsWith("DSR_LEGACY")) {
    if (!Array.isArray(case_.rights_concerned) || case_.rights_concerned.length === 0) {
      return "General";
    }
    return case_.rights_concerned.join(", ");
  }

  const requestNameDict: { [key in CaseType]: string } = {
    REP_DOC_EU_NIS2_LOA: "REP_DOC_EU_NIS2_LOA",
    AUTHORITY_INBOX: "Authority",
    PRIGHTER: "Prighter",
    DSR_CCPA_DELETE: "Deletion",
    DSR_CCPA_ACCESS: "Access",
    DSR_CCPA_ACCESS_CATEGORIES: "Access categories",
    DSR_CCPA_CORRECT: "Correction",
    DSR_CCPA_OPTOUT: "Opt-out",
    DSR_CCPA_LIMIT: "Limit",
    DSR_FADP_DELETE: "Deletion",
    DSR_FADP_ACCESS: "Access",
    DSR_FADP_CORRECT: "Correction",
    DSR_FADP_AUTOMATED_DECISION: "Automated Decision",
    DSR_FADP_PROHIBIT_DATA_PROCESSING: "Prohibit Data Processing",
    DSR_LEGACY_EU: "EU GDPR",
    DSR_LEGACY_UK: "UK GDPR",
    DSR_LEGACY: null,
    DSR: null,
    DSR_EMAIL: null,
  };

  return case_.type in requestNameDict ? requestNameDict[case_.type] : "General";
}

export function beautifyCaseType(case_: BusinessWithCase | DsrCollectionItem): string {
  const requestNameDict: { [key in CaseType]: string } = {
    REP_DOC_EU_NIS2_LOA: "REP_DOC_EU_NIS2_LOA",
    AUTHORITY_INBOX: "Authority",
    PRIGHTER: "Prighter",
    DSR_CCPA_DELETE: "CCPA",
    DSR_CCPA_ACCESS: "CCPA",
    DSR_CCPA_ACCESS_CATEGORIES: "CCPA",
    DSR_CCPA_CORRECT: "CCPA",
    DSR_CCPA_OPTOUT: "CCPA",
    DSR_CCPA_LIMIT: "CCPA",
    DSR_FADP_DELETE: "FADP",
    DSR_FADP_ACCESS: "FADP",
    DSR_FADP_CORRECT: "FADP",
    DSR_FADP_AUTOMATED_DECISION: "FADP",
    DSR_FADP_PROHIBIT_DATA_PROCESSING: "FADP",
    DSR_LEGACY_EU: "EU GDPR",
    DSR_LEGACY_UK: "UK GDPR",
    DSR_LEGACY: null,
    DSR: null,
    DSR_EMAIL: null,
  };

  return case_.type in requestNameDict ? requestNameDict[case_.type] : "DSR";
}

export const generateUrl = (caseUuid: string, fileUuid: string): string => {
  const params = new URLSearchParams({
    file_uuid: fileUuid,
    case_uuid: caseUuid,
  });

  return getUrl("API", "/v1/file/case", params);
};

export const generateFileUrl = (businessId: string, type: BusinessFilePrivate): string => {
  const params = new URLSearchParams({
    business_id: businessId,
    type_: type,
  });

  return getUrl("API", "/v1/file/business", params);
};

export const getDSRUrl = (dsrType: string, publicId: string, DsrUuid: string): string => {
  if (dsrType.includes("CCPA") || dsrType.includes("FADP")) {
    return `/case/${DsrUuid}`;
  }
  return getUrl("FLASK", `/dsrtool/case/${DsrUuid}`);
};

export function getDSRRequestFlag(type: CaseType): string {
  const requestNameDict: { [key in CaseType]: DSRJurisdictionType } = {
    REP_DOC_EU_NIS2_LOA: null,
    AUTHORITY_INBOX: null,
    PRIGHTER: null,
    DSR_CCPA_DELETE: "dsr_cal",
    DSR_CCPA_ACCESS: "dsr_cal",
    DSR_CCPA_ACCESS_CATEGORIES: "dsr_cal",
    DSR_CCPA_CORRECT: "dsr_cal",
    DSR_CCPA_OPTOUT: "dsr_cal",
    DSR_CCPA_LIMIT: "dsr_cal",
    DSR_FADP_DELETE: "dsr_fadp",
    DSR_FADP_ACCESS: "dsr_fadp",
    DSR_FADP_CORRECT: "dsr_fadp",
    DSR_FADP_AUTOMATED_DECISION: "dsr_fadp",
    DSR_FADP_PROHIBIT_DATA_PROCESSING: "dsr_fadp",
    DSR_LEGACY_EU: "dsr_eu",
    DSR_LEGACY_UK: "dsr_uk",
    DSR_LEGACY: null,
    DSR: null,
    DSR_EMAIL: null,
  };

  return type in requestNameDict
    ? jurisdictionDict[requestNameDict[type]].jurisdiction_lock_icon_url
    : "";
}

export function getJurisdictionByType(type: CaseType): string {
  const requestNameDict: { [key in CaseType]: string } = {
    REP_DOC_EU_NIS2_LOA: "REP_DOC_EU_NIS2_LOA",
    AUTHORITY_INBOX: "Authority",
    PRIGHTER: "Prighter",
    DSR_CCPA_DELETE: "CCPA",
    DSR_CCPA_ACCESS: "CCPA",
    DSR_CCPA_ACCESS_CATEGORIES: "CCPA",
    DSR_CCPA_CORRECT: "CCPA",
    DSR_CCPA_OPTOUT: "CCPA",
    DSR_CCPA_LIMIT: "CCPA",
    DSR_FADP_DELETE: "FADP",
    DSR_FADP_ACCESS: "FADP",
    DSR_FADP_CORRECT: "FADP",
    DSR_FADP_AUTOMATED_DECISION: "FADP",
    DSR_FADP_PROHIBIT_DATA_PROCESSING: "FADP",
    DSR_LEGACY_EU: "EU GDPR",
    DSR_LEGACY_UK: "UK GDPR",
    DSR_LEGACY: null,
    DSR: null,
    DSR_EMAIL: null,
  };
  return type in requestNameDict ? requestNameDict[type] : "DSR";
}

export function getStageChecks(
  caseChecks: CaseCheck[],
  step: "PRE_CHECK" | "HANDLING" | "DOC_SIGNATURE" | "DOC_REVIEW" | "DOC_CLIENT_REVIEW"
): CaseCheck[] {
  const filteredChecks = caseChecks.filter((check) => check.stage_name === step);
  let stepsOrder = null;

  switch (step) {
    case "PRE_CHECK":
      stepsOrder = preCheckStepsOrder;
      break;
    case "HANDLING":
      stepsOrder = handlingStepsOrder;
      break;
    case "DOC_SIGNATURE":
      stepsOrder = nis2LoaSignStepsOrder;
      break;
    case "DOC_CLIENT_REVIEW":
      stepsOrder = nis2LoaClientReviewStepsOrder;
      break;
    case "DOC_REVIEW":
      stepsOrder = nis2LoaReviewStepsOrder;
      break;
    default:
      stepsOrder = null;
  }

  return stepsOrder
    .map((name) => filteredChecks.find((item) => item.name === name))
    .filter(Boolean);
}

export function getCurrentCaseStage(data: CaseDetail): StageNameEnum {
  return data.case_stages?.find((i) => i.finished_at === null)?.stage.name;
}

export function getOpenInquiries(inquiries: Inquiry[]): Inquiry[] {
  return inquiries?.filter((i) => i.status === "OPEN") || [];
}

export function getClosedInquiries(inquiries: Inquiry[]): Inquiry[] {
  return inquiries?.filter((i) => i.status === "CLOSED") || [];
}

export function getDataSubject(caseActors: CaseContact[]): CaseContact {
  return caseActors?.find((i) => i.acting_as === "DATA_SUBJECT");
}

export function getSender(caseActors: CaseContact[]): CaseContact {
  return caseActors?.find((i) => i.acting_as === "SENDER");
}

export const isCheckFinished = (state: ProviderState): boolean => {
  const uiStage = state.ui.stage === "HANDLING" ? "HANDLING" : "PRE_CHECK";
  const checks = getStageChecks(state.case.case_checks, uiStage);

  return (
    !!checks?.find((i) => i.name === state.ui.check)?.closed_at || state.case.state === "CLOSED"
  );
};

export const getLatestActiveCheck = (checks: CaseCheck[]): CheckName => {
  return checks.find((check) => !check.closed_at)?.name || checks[checks.length - 1].name;
};

export const generateContactData = (contact: CaseContact): any => {
  const data = [
    {
      key: "name",
      value: contact?.contact?.name,
    },
    {
      key: "email_address",
      value: contact?.contact?.contact_points?.find((i) => i.type === "EMAIL")?.value,
    },
    {
      key: "phone_number",
      value: contact?.contact?.contact_points?.find((i) => i.type === "PHONE")?.value,
    },
  ];

  contact?.contact?.addresses?.forEach((i) =>
    data.push({
      key: "address",
      value: stringifyAddress(i),
    })
  );

  return data.filter((i) => !!i.value);
};

export const generateConsumerInfo = (caseDetails: CaseDetail): any => {
  const data = [
    {
      key: "name",
      value: caseDetails?.extra?.username,
    },
    {
      key: "email",
      value: caseDetails?.extra?.email,
    },
    {
      key: "address",
      value: caseDetails?.extra?.address || tolgee.t("generic.no_address"),
    },
    {
      key: "phone",
      value: caseDetails?.extra?.phone,
    },
    {
      key: "customer_number",
      value: caseDetails?.extra?.customer_number,
    },
    {
      key: "purchase_number",
      value: caseDetails?.extra?.purchase_number,
    },
    {
      key: "pseudonym",
      value: caseDetails?.extra?.pseudonym,
    },
    {
      key: "other_data",
      value: caseDetails?.extra?.other,
    },
  ];

  return data.filter((i) => !!i.value);
};

export const generateI18nKey = (type: CaseType, check: CheckName, key: string): string => {
  return `dsr.${type}.${check}.${key}`;
};

export const generateI18nKeyByCaseType = (type: CaseType, key: string): string => {
  return `dsr.${type}.${key}`;
};

export const generateClosedAsTypeBuDeclineType = (declineType: DeclineTypeEnum): ClosedAsEnum => {
  switch (declineType) {
    case DeclineTypeEnum.IDENTIFICATION:
      return ClosedAsEnum.DSR_IDENTIFICATION_FAILED;
    case DeclineTypeEnum.RESIDENCY:
      return ClosedAsEnum.DSR_LOCATION_FAILED;
    case DeclineTypeEnum.CORRECT_DELETED:
      return ClosedAsEnum.DSR_CORRECT_DELETE;
    case DeclineTypeEnum.DELETE_NOT_COMPLIED:
      return ClosedAsEnum.DSR_HANDLED_DECLINED;
    case DeclineTypeEnum.OPTOUT_NOT_COMPLIED:
      return ClosedAsEnum.DSR_HANDLED_DECLINED;
    case DeclineTypeEnum.ACCESS_NOT_COMPLIED:
      return ClosedAsEnum.DSR_HANDLED_DECLINED;
    case DeclineTypeEnum.LIMIT_NOT_COMPLIED:
      return ClosedAsEnum.DSR_HANDLED_DECLINED;
    case DeclineTypeEnum.FRAUDULENT:
      return ClosedAsEnum.GENERIC_FRAUD;
    default:
      captureException(new Error(`Unknown decline type: ${declineType}`), {
        tags: {
          location: window.location.pathname,
          declineType,
        },
        level: "error",
      });
      return ClosedAsEnum.GENERIC_FRAUD;
  }
};
