import api from "services/api/base";

import type { AxiosResponse } from "axios";

export async function unsubscribeMarketingMails(
  email_address: string
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/marketing/unsubscribe", null, {
    params: { email_address },
  });
}

export async function sendProductInterest(
  email: string,
  companyName: string
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/marketing/product-interest", { email, company_name: companyName });
}
