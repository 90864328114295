import { NavLink } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import clsx from "clsx";
import { Tooltip } from "@mui/material";

import EU from "assets/flags/eu.svg";
import AT from "assets/flags/at.svg";
import Cali from "assets/flags/cali.svg";
import CH from "assets/flags/ch.svg";
import TR from "assets/flags/tr.svg";
import UK from "assets/flags/uk.svg";
import Zh from "assets/flags/zh.svg";
import { REACT_BASE_ROUTE } from "const/env";
import tolgee from "services/translation";

import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import type { i18nLabel, PathType } from "types/components";

const flagMap = {
  eu: EU,
  at: AT,
  cali: Cali,
  ch: CH,
  tr: TR,
  uk: UK,
  zh: Zh,
};

export default function NavItem(props: {
  i18n: i18nLabel;
  url: string;
  notificationCount?: number;
  todo?: boolean;
  icon?: IconDefinition;
  custom_icon?: string;
  type: PathType;
}) {
  const { i18n, custom_icon, url, notificationCount = 0, todo, icon, type } = props;

  const renderCustomIcon = () => {
    if (custom_icon) {
      const flagSrc = flagMap[custom_icon.toLowerCase() as keyof typeof flagMap];
      return flagSrc ? <img src={flagSrc} width="17.5" alt=" " /> : <div className="w-[17.5px]" />;
    }
    return <div className="w-[17.5px]" />;
  };

  return (
    <NavLink
      to={type === "REACT" ? url.replace(REACT_BASE_ROUTE, "") : url}
      className={clsx({ "text-prighterblue": icon, "text-gray-500": !icon })}>
      {({ isActive }) => (
        <div
          className={clsx("navbar-item", {
            "bg-gray-200 font-bold": isActive,
          })}>
          {icon ? (
            <FontAwesomeIcon icon={icon} color="gray" fixedWidth />
          ) : (
            <>{renderCustomIcon()}</>
          )}
          <div>
            <T keyName={i18n.ns ? `${i18n.ns}.${i18n.key}` : i18n.key} params={i18n.params} />
          </div>
          {todo && (
            <span>
              <span className="items-center justify-center px-2 py-1 text-xs font-bold leading-none text-orange-100 rounded-full bg-danger-400">
                <Tooltip
                  title={tolgee.t({ key: `${i18n.key}.todo_tooltip` })}
                  placement="top-start">
                  <FontAwesomeIcon icon="exclamation-triangle" color="navigation" fixedWidth />
                </Tooltip>
              </span>
            </span>
          )}
          {notificationCount > 0 && (
            <span>
              <span className="items-center justify-center px-2 py-1 text-xs font-bold leading-none text-orange-100 bg-orange-400 rounded-full">
                {notificationCount}
              </span>
            </span>
          )}
        </div>
      )}
    </NavLink>
  );
}
