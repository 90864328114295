import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import tolgee from "services/translation";
import Certificate from "pages/Client/RepPages/components/Certificate";
import { BaseTabs } from "components/Tabs/Tabs";

export default function NisRepresentation() {
  const [defaultTab, setDefaultTab] = useState<number>(0);
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const openParam = query.get("open");
    if (openParam === "nisuk") {
      setDefaultTab(1);
    }
  }, [search]);

  const tabsData = [
    {
      id: 1,
      label: `🇪🇺 ${tolgee.t({ key: "nis.nis_eu" })}`,
      content: <Certificate type="niseu" />,
      testId: "niseu",
    },
    {
      id: 2,
      label: `🇬🇧 ${tolgee.t({ key: "nis.nis_uk" })}`,
      content: <Certificate type="nisuk" />,
      testId: "nisuk",
    },
  ];

  return (
    <div className="mx-auto max-w-7xl box-outerlayout" data-testid="nis-page">
      <BaseTabs tabsData={tabsData} defaultTab={defaultTab} />
    </div>
  );
}
