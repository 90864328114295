import { REACT_BASE_ROUTE } from "const/env";
import tolgee from "services/translation";

import type { CcpaInputFieldsEnum } from "types/case/index";

export const defaultDataPoints = [
  "name",
  "email",
  "address",
  "phone",
  "customer_number",
  "purchase_number",
  "pseudonym",
  "other",
] as Array<CcpaInputFieldsEnum>;

export const ccpaPrivacyPolicySnippet = (url: string): string =>
  `<a href="${url}" class="flex flex-col gap-2">
    <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 14" xml:space="preserve" height="16" width="43">
      <path d="M7.4 12.8h6.8l3.1-11.6H7.4C4.2 1.2 1.6 3.8 1.6 7s2.6 5.8 5.8 5.8z"
        style="fill-rule:evenodd;clip-rule:evenodd;fill:#fff"></path>
      <path
        d="M22.6 0H7.4c-3.9 0-7 3.1-7 7s3.1 7 7 7h15.2c3.9 0 7-3.1 7-7s-3.2-7-7-7zm-21 7c0-3.2 2.6-5.8 5.8-5.8h9.9l-3.1 11.6H7.4c-3.2 0-5.8-2.6-5.8-5.8z"
        style="fill-rule:evenodd;clip-rule:evenodd;fill:#06f"></path>
      <path
        d="M24.6 4c.2.2.2.6 0 .8L22.5 7l2.2 2.2c.2.2.2.6 0 .8-.2.2-.6.2-.8 0l-2.2-2.2-2.2 2.2c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8L20.8 7l-2.2-2.2c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0l2.2 2.2L23.8 4c.2-.2.6-.2.8 0z"
        style="fill:#fff"></path>
      <path
        d="M12.7 4.1c.2.2.3.6.1.8L8.6 9.8c-.1.1-.2.2-.3.2-.2.1-.5.1-.7-.1L5.4 7.7c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0L8 8.6l3.8-4.5c.2-.2.6-.2.9 0z"
        style="fill:#06f"></path>
    </svg>
    <span>${tolgee.t({ key: "ccpa.your_privacy_options" })}</span>
  </a>`;

export const optOutLink = (businessId: string): string =>
  `${REACT_BASE_ROUTE}/dsr/${businessId}/ccpa?type=opt-out`;
