import type { ReactNode, CSSProperties } from "react";

export default function TimeLineDot(props: {
  children?: ReactNode;
  additionalStyle?: CSSProperties;
}) {
  const { children, additionalStyle = {} } = props;
  return (
    <div
      className="flex items-center justify-center m-2 w-10 h-10 bg-prighterblue rounded-full"
      style={additionalStyle}>
      {children}
    </div>
  );
}
