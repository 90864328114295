import { Tooltip } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import tolgee from "services/translation";
import { Chip, PrighterFooter } from "components";
import { api } from "services";
import { stringifyBehalfOfContactName, stringifyContactName } from "helpers/contact";
import { getDSRRequestFlag, beautifyCaseType } from "helpers/case";
import { getBeautyDateTime } from "helpers";
import { getTimeDifferenceString } from "helpers/date";
import ProgressBar from "components/Feedback/ProgressBar";
import PrighterLogo from "components/Prighter/PrighterLogo";
import { stringifyBusinessAddress } from "helpers/company";
import { handleError } from "services/api/error";
import UnauthorizedAccessModal from "components/General/UnauthorisedAccessModal";

import type { DsrCollectionItem } from "types/dsr/main";

export default function DSRCollection() {
  const { businessId, collectionId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [collectionData, setCollectionData] = useState<DsrCollectionItem[]>([]);
  const emailVerified = collectionData[0]?.tags.findIndex((t) => t === "EMAIL_VERIFIED") !== -1;

  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const fetchCollectionData = useCallback(async () => {
    try {
      const list = await api.dsr.getDsrCollection(businessId, collectionId);
      if (list.length === 1) {
        window.open(list[0].ext_url, "_self");
      } else {
        setCollectionData(list);
      }
    } catch (e) {
      if (e.response?.status === 401) {
        setShowAuthModal(true);
      } else {
        handleError(e);
      }
    } finally {
      setIsLoading(false);
    }
  }, [businessId, collectionId]);

  useEffect(() => {
    fetchCollectionData();
  }, [fetchCollectionData]);

  const authorizeCase = async () => {
    try {
      await api.dsr.postCaseAuthorization(businessId, collectionId, password);
      fetchCollectionData();
      setShowAuthModal(false);
    } catch {
      setPasswordError(true);
    }
  };

  const generateDsrType = (collectionItem: DsrCollectionItem) => {
    return tolgee.t(`dsrCollection.${collectionItem.type}`);
  };

  return (
    <>
      {isLoading && (
        <>
          <ProgressBar />
        </>
      )}

      {showAuthModal && (
        <>
          <UnauthorizedAccessModal
            modalState={[showAuthModal, setShowAuthModal]}
            passwordErrorState={[passwordError, setPasswordError]}
            setPassword={setPassword}
            submit={authorizeCase}
          />
        </>
      )}

      {collectionData.length > 0 && (
        <div className="flex flex-col bg-gray-50 p-8 min-h-screen">
          <div className="flex items-center justify-end py-2 mb-6">
            <span className="text-4xl font-bold text-prighterblue">Prighter</span>
            <PrighterLogo width={25} className="ml-2" />
          </div>
          <div>
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl mx-auto">
              <div className="mb-4">
                <h1 className="text-3xl font-bold text-gray-800">
                  {collectionData[0]?.company_name}
                </h1>
                <p className="text-gray-500 text-sm">
                  {collectionData.length > 0
                    ? stringifyBusinessAddress(collectionData[0].business_address)
                    : ""}
                </p>
              </div>

              <p className="text-gray-600 text-sm mb-4">
                {collectionData[0]?.company_name} {tolgee.t("dsrCollection.description")}
              </p>

              <div className="bg-gray-50 border-2 rounded-lg mb-6">
                <div className="flex flex-row items-center justify-between gap-4 p-4 border-b-2">
                  <div className="flex flex-row items-center gap-2">
                    <div className="flex flex-row gap-1">
                      {collectionData[0]?.acting_for === "MYSELF" ? (
                        <b>
                          {stringifyContactName(
                            collectionData[0]?.ds_name,
                            collectionData[0]?.ds_salutation
                          )}
                        </b>
                      ) : (
                        stringifyBehalfOfContactName(
                          collectionData[0]?.sender_name,
                          collectionData[0]?.ds_name
                        )
                      )}
                    </div>
                    <div>
                      {tolgee.t("generic.collection")}{" "}
                      {getBeautyDateTime(collectionData[0].collection_created_at)}
                    </div>
                    <div className="rounded-full bg-[#E2E8F0] px-2 py-0.5 flex items-center space-x-2">
                      <span className="text-darkgray">{collectionData[0]?.ds_email}</span>
                      <FontAwesomeIcon
                        icon={emailVerified ? "check" : "hourglass"}
                        color={emailVerified ? "green" : "darkgray"}
                        size="sm"
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <img
                      className="self-end mr-2"
                      alt="gdpr-logo"
                      width={24}
                      src={getDSRRequestFlag(collectionData[0]?.type)}
                    />
                    <span className="w-full text-center">
                      California <br /> ({beautifyCaseType(collectionData[0])})
                    </span>
                  </div>
                </div>
                {collectionData.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-center justify-between gap-4 p-4 border-b-2 last:border-b-0 cursor-pointer hover:bg-gray-100"
                    onClick={() => window.open(item.ext_url)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        window.open(item.ext_url);
                      }
                    }}
                    role="button"
                    tabIndex={0}>
                    <div className="flex flex-col gap-1">
                      <div className="flex flex-row items-center gap-1">
                        <b>{generateDsrType(item)}</b>
                        <p className="text-gray-500 text-sm m-0">
                          {tolgee.t("generic.uuid")}: {item.uuid}
                        </p>
                      </div>
                      <div>
                        <div className="flex flex-row items-center gap-2">
                          <FontAwesomeIcon icon="clock" color="gray" size="sm" />
                          <Tooltip
                            title={
                              <div className="text-center">
                                {tolgee.t("generic.created_at")} <br />{" "}
                                {getBeautyDateTime(item.created_at)}
                              </div>
                            }
                            placement="top">
                            <div>{getTimeDifferenceString(item.created_at)} old</div>
                          </Tooltip>
                          <div>
                            {item.inquiries_total > 0 && (
                              <Tooltip
                                title={`${item.inquiries_closed} of ${item.inquiries_total} inquiries answered`}
                                placement="top">
                                <div>
                                  <Chip
                                    title={
                                      <>
                                        <FontAwesomeIcon
                                          icon="clock-rotate-left"
                                          size="sm"
                                          className="mr-1"
                                        />
                                        {item.inquiries_closed} / {item.inquiries_total}
                                      </>
                                    }
                                    backgroundColor={
                                      item.inquiries_closed === item.inquiries_total
                                        ? "bg-success-400"
                                        : "bg-gray-500"
                                    }
                                    className="py-0.5"
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row items-center gap-2">
                      {item.inquiries_closed < item.inquiries_total ? (
                        <div>
                          <Chip
                            title={
                              <div className="flex flex-row items-center gap-1">
                                <FontAwesomeIcon icon="circle-exclamation" />
                                <span>{tolgee.t("generic.action_required")}</span>
                              </div>
                            }
                            backgroundColor="bg-danger-400"
                            className="py-0.5"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div>
                        <FontAwesomeIcon icon="arrow-right" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="text-gray-500 text-sm mb-8">
                <p>
                  <strong>{tolgee.t("generic.prighter_group")}</strong>{" "}
                  {tolgee.t("dsrCollection.is_representative")}{" "}
                  <strong>{collectionData[0]?.company_name}</strong>{" "}
                  {tolgee.t("dsrCollection.following_jurisdictions")}
                </p>
              </div>
              <div>
                <PrighterFooter />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
