import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router";

import { landingPageUrl } from "const";
import { BaseTextField } from "components";
import tolgee from "services/translation";
import { useAppSelector } from "store";

export default function Info() {
  const { businessId } = useParams();
  const { managed_businesses } = useAppSelector((state) => state.user);
  const currentBusiness = managed_businesses?.find((item) => item.public_id === businessId);

  const html = `
    <div>
      <span>${currentBusiness.company_name}</span>
      <span>${tolgee.t("dsa.website_wording_1")}</span>
      <a href=${landingPageUrl(businessId)}>${tolgee.t("dsa.digital_governance_portal")}</a>
      <span>${tolgee.t("dsa.website_wording_2")}</span>
    </div>
  `;

  const copyHTML = () => {
    navigator?.clipboard?.writeText(html);
  };

  return (
    <div className="box-outerlayout pb-6 pt-10 mb-6" data-testid="info-section">
      <div className="md:p-6 w-full">
        <div className="mb-6">
          <h2>{tolgee.t("dsa.public_information_title")}</h2>
          <div>{tolgee.t("dsa.public_information_description")}</div>
        </div>
        <div className="p-4 mb-8 border rounded-lg border-gray-300 bg-gray-50">
          <h3>{tolgee.t("dsa.website_wording_title")}</h3>
          <div>
            <span>{currentBusiness.company_name}</span>{" "}
            <span>{tolgee.t("dsa.website_wording_1")}</span>{" "}
            <a href={landingPageUrl(businessId)}>{tolgee.t("dsa.digital_governance_portal")}</a>{" "}
            <span>{tolgee.t("dsa.website_wording_2")}</span>
          </div>
        </div>
        <div>
          <h3>{tolgee.t("dsa.website_wording_title_html")}</h3>
          <div className="border rounded-lg border-gray-300 bg-gray-50 p-4 my-4 !w-full">
            <div className="mb-6">
              <BaseTextField containerClassName="w-full" multiline disabled value={html} />
            </div>
            <div className="flex justify-end mt-4">
              <Button onClick={() => copyHTML()}>
                <FontAwesomeIcon className="mr-2" icon="copy" color="gray" size="lg" />
                {tolgee.t("generic.copy")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
