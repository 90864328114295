import React, { useEffect } from "react";
import {
  BoldItalicUnderlineToggles,
  MDXEditor,
  UndoRedo,
  frontmatterPlugin,
  headingsPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  tablePlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";

import type { MDXEditorMethods } from "@mdxeditor/editor";

// eslint-disable-next-line import/no-unresolved
import "@mdxeditor/editor/style.css";

type Props = { value: string; onChange?: (value: string) => void; readOnly?: boolean };

function ToolbarContents() {
  return (
    <>
      <UndoRedo />
      <BoldItalicUnderlineToggles />
    </>
  );
}

export default function MarkdownEditor(props: Props) {
  const { value, onChange, readOnly } = props;
  const ref = React.useRef<MDXEditorMethods>(null);
  const hasRun = React.useRef(false);

  useEffect(() => {
    if (value && !hasRun.current) {
      ref.current.setMarkdown(value);
      hasRun.current = true;
    }
  }, [value]);

  return (
    <MDXEditor
      readOnly={readOnly}
      ref={ref}
      markdown={value}
      onChange={onChange}
      plugins={[
        frontmatterPlugin(),
        listsPlugin(),
        linkPlugin(),
        tablePlugin(),
        quotePlugin(),
        headingsPlugin(),
        toolbarPlugin({
          toolbarContents: ToolbarContents,
        }),
        markdownShortcutPlugin(),
      ]}
      contentEditableClassName="prose"
      className="min-h-[6rem] border-x-2 border-b-2 rounded-lg border-x-gray-200"
    />
  );
}
