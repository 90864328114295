import { InvoiceStatus } from "types/billing";

import type { ReactNode } from "react";

export function getInvoiceStatusElement(status: InvoiceStatus): ReactNode {
  const generateElement = (bgColor: string, textColor: string) => (
    <div className={`${bgColor} rounded px-2 w-fit self-start`}>
      <span className={textColor}>{status[0].toUpperCase() + status.substring(1)}</span>
    </div>
  );

  switch (status) {
    case InvoiceStatus.PAID:
      return generateElement("bg-success-400/75", "text-success-900");
    case InvoiceStatus.VOID:
    case InvoiceStatus.UNCOLLECTIBLE:
      return generateElement("bg-prighterblue/75", "text-brand-900");
    default:
      return generateElement("bg-orange-400/75", "text-orange-900");
  }
}
