import { Button, FormControl, MenuItem, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useForm } from "react-hook-form";

import { validateEmailDomainsSame } from "helpers/validate";
import { api } from "services";
import tolgee from "services/translation";
import { useAppDispatch, useAppSelector } from "store";
import { notify } from "store/app";
import { handleError } from "services/api/error";

const channels = [
  "channel_admin",
  "channel_all",
  "channel_essential",
  "channel_billing",
  "channel_compliance",
  "channel_privacy",
  "channel_dsr_details",
];

const channelsMapping: any = {
  channel_admin: "ADMIN",
  channel_all: "ALL",
  channel_essential: "ALL_ESSENTIAL",
  channel_billing: "BILLING",
  channel_compliance: "COMPLIANCE_RELATED",
  channel_privacy: "PRIVACY_KNOWLEDGE",
  channel_dsr_details: "DSR_DETAILS",
};

type Props = {
  userUuid: string;
  handleReload: any;
};

export default function AddNotification({ userUuid, handleReload }: Props) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const strictDomain = user.config.email_channel_strict_domain;

  const { register, getValues, handleSubmit, reset } = useForm({
    values: { channel: "", email: "" },
  });

  const onSubmit = async (data: any) => {
    if (strictDomain && !validateEmailDomainsSame(data?.email, user.email)) {
      dispatch(
        notify({
          message: tolgee.t({
            key: "notifications.description.strict_domain",
          }),
          type: "ERROR",
          duration: 10,
        })
      );
      return;
    }

    const channelData = getValues().channel;

    if (data?.email && channelData) {
      const userData = {
        address: data?.email,
        type: channelsMapping[channelData as keyof any],
      };
      try {
        await api.notifications.addNotification(userUuid, userData);
      } catch (e) {
        handleError(e);
        return;
      } finally {
        handleReload();
      }

      dispatch(
        notify({
          message: tolgee.t({
            key: "notifications.notification_added",
          }),
          type: "SUCCESS",
        })
      );
      reset();
    } else {
      dispatch(
        notify({
          message: tolgee.t({
            key: "generic.smth_went_wrong",
          }),
          type: "ERROR",
        })
      );
    }
  };

  return (
    <div>
      <h3 className="mt-8 font-bold">
        <T keyName="notifications.add_notification" />
      </h3>
      <FormControl fullWidth>
        <div className="flex w-full">
          <div className="flex-1 mr-4">
            <TextField
              className="w-full"
              id="select"
              label="Select Channel"
              select
              defaultValue={channels[0]}
              {...register("channel")}>
              {channels.map((i) => (
                <MenuItem value={i} key={i}>
                  <T keyName={`notifications.${channelsMapping[i].toLowerCase()}`} />
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="flex-1 mr-4">
            <TextField
              className="w-full"
              label={tolgee.t({
                key: "generic.email",
              })}
              {...register("email" as const)}
            />
          </div>
        </div>
      </FormControl>
      <div className="mt-8">
        <Button onClick={handleSubmit(onSubmit)}>
          <T keyName="generic.add" />
        </Button>
      </div>
    </div>
  );
}
