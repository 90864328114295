import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";

import { useAppDispatch, useAppSelector } from "store";
import NavMenu from "components/Layout/Navbar/NavMenu";
import ProgressBar from "components/Feedback/ProgressBar";
import { extractBusinessIdsFromNav } from "helpers/general";
import { changePreference } from "store/app";
import { fetchMenuData } from "store/thunks";

export type NavBusiness = { name: string; id: string };

function NavBar() {
  const { businessId } = useParams();
  const { managed_businesses } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { selectedBusiness: previouslySelectedBusiness } = useAppSelector(
    (state) => state.app.preferences
  );
  const menu = useAppSelector((state) => state.menu);

  const [loading, setLoading] = useState(true);
  const hasRun = useRef(false);

  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [businesses, setBusinesses] = useState([] as NavBusiness[]);

  useEffect(() => {
    if (!hasRun.current && menu.length > 0) {
      let ids = [];
      if (managed_businesses.length === 0) {
        const businessIds = extractBusinessIdsFromNav(menu);
        ids = businessIds.map((business) => ({
          name: business,
          id: business,
        }));
      } else {
        ids = managed_businesses.map((business) => ({
          name: business.company_name,
          id: business.public_id,
        }));
      }
      setBusinesses(ids);
      if (previouslySelectedBusiness && ids.find((id) => id.id === previouslySelectedBusiness)) {
        setSelectedBusiness(previouslySelectedBusiness);
      } else {
        setSelectedBusiness(ids[0]?.id);
        dispatch(changePreference({ selectedBusiness: ids[0]?.id }));
      }

      hasRun.current = true;
    }
  }, [dispatch, managed_businesses, menu, previouslySelectedBusiness]);

  useEffect(() => {
    if (businessId) {
      setSelectedBusiness(businessId);
      dispatch(changePreference({ selectedBusiness: businessId }));
    }
  }, [businessId, dispatch]);

  useEffect(() => {
    let retryCount = 0;

    async function getRoutes() {
      setLoading(true);

      if (menu.length > 0) {
        setLoading(false);
        retryCount = 0;
      } else {
        let retryDelay;
        if (retryCount <= 2) {
          retryDelay = 1000 * 2;
        } else {
          retryDelay = 1000 * 60 * 3;
        }
        retryCount += 1;

        setTimeout(() => {
          getRoutes();
        }, retryDelay);
      }
    }

    getRoutes();
    const interval = setInterval(
      () => {
        getRoutes();
      },
      1000 * 60 * 2
    );

    return () => {
      clearInterval(interval);
    };
  }, [menu]);

  useEffect(() => {
    dispatch(fetchMenuData());

    const interval = setInterval(() => {
      dispatch(fetchMenuData());
    }, 120000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <div className="max-w-sm pt-1">
      {menu.map((men, ind) => (
        <NavMenu
          key={ind}
          navMenu={men}
          businesses={businesses}
          selectedBusiness={selectedBusiness}
          setSelectedBusiness={setSelectedBusiness}
        />
      ))}
      {menu.length === 0 && loading && <ProgressBar />}
    </div>
  );
}

export default NavBar;
