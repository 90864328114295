import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";

import type { DefaultEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function DefaultEntry(props: {
  entityData: DefaultEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  const beautifyTitle = (title: string) => {
    const stringifiedTitle = `${title}`;

    return stringifiedTitle
      .split("_")
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(" ");
  };

  return (
    <HistoryEntryTemplate
      title={entityData?.title}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="p-4 flex flex-col space-y-2">
        {Object.entries(entityData)
          .filter((e) => !["processed_at", "title"].includes(e[0]))
          .map(([entityKey, entityVal]) => (
            <span key={entityKey}>
              <b>{beautifyTitle(entityKey)}:</b>
              {typeof entityVal !== "object" ? entityVal : "[Object]"}
            </span>
          ))}
      </div>
    </HistoryEntryTemplate>
  );
}
