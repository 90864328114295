import { Fragment, useCallback, useEffect } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BaseTextField } from "components/Input";
import { PRIGHTER_BLUE } from "const/color";
import tolgee from "services/translation";

import type { ContactPoint, ContactPointType } from "types/contactDirectory";

type Props = { contactPoints: ContactPoint[]; onChange: (contactPoints: ContactPoint[]) => void };

export default function AuthorityContactPointList({ contactPoints = [], onChange }: Props) {
  const handleIncrease = useCallback(
    (type: ContactPointType) => {
      onChange([...contactPoints, { type, value: "" }]);
    },
    [contactPoints, onChange]
  );

  // TODO: Find a way to separate lists, so you can handle the state more performant (and you can use index)
  useEffect(() => {
    const emailList = contactPoints.filter((cp) => cp.type === "EMAIL");
    const phoneList = contactPoints.filter((cp) => cp.type === "PHONE");
    const webList = contactPoints.filter((cp) => cp.type === "WEB");

    if (!emailList.length) {
      handleIncrease("EMAIL");
    }
    if (!phoneList.length) {
      handleIncrease("PHONE");
    }
    if (!webList.length) {
      handleIncrease("WEB");
    }
  }, [contactPoints, handleIncrease]);

  const handleChange = (contactObj: ContactPoint, newValue: string) => {
    const objInd = contactPoints.findIndex((cp) => cp === contactObj);
    const tempList = [...contactPoints];
    tempList[objInd].value = newValue;
    onChange(tempList);
  };

  const handleRemove = (contactObj: ContactPoint) => {
    const objInd = contactPoints.findIndex((cp) => cp === contactObj);
    const tempList = [...contactPoints];
    tempList.splice(objInd, 1);
    onChange([...tempList]);
  };

  const getEndingIcon = (contactObj: ContactPoint, isLastItem: boolean) => {
    let testId;
    if (contactObj.type === "EMAIL") {
      testId = "add-email-button";
    } else if (contactObj.type === "PHONE") {
      testId = "add-phone-button";
    } else {
      testId = "add-web-button";
    }

    if (isLastItem) {
      return (
        <IconButton
          data-testid={testId}
          onClick={() => handleIncrease(contactObj.type)}
          size="medium">
          <FontAwesomeIcon icon="plus" size="sm" />
        </IconButton>
      );
    }

    return (
      <IconButton
        data-testid="remove-contacts-button"
        onClick={() => handleRemove(contactObj)}
        size="medium">
        <FontAwesomeIcon icon="trash" color="red" size="sm" />
      </IconButton>
    );
  };

  return (
    <>
      {contactPoints
        .filter((cp) => cp.type === "PHONE")
        .map((contactPoint, ind) => (
          <Fragment key={`${contactPoint.uuid || ind}-phone`}>
            {ind === 0 ? <FontAwesomeIcon icon="phone" color={PRIGHTER_BLUE} /> : <div />}
            <BaseTextField
              value={contactPoint.value}
              onChange={(event) => handleChange(contactPoint, event.target.value)}
              label={
                ind === 0 &&
                tolgee.t({
                  key: "contacts.phone",
                })
              }
              containerClassName="w-full"
            />
            {getEndingIcon(
              contactPoint,
              ind + 1 === contactPoints.filter((cp) => cp.type === "PHONE").length
            )}
          </Fragment>
        ))}
      {contactPoints
        .filter((cp) => cp.type === "EMAIL")
        .map((contactPoint, ind) => (
          <Fragment key={`${contactPoint.uuid || ind}-email`}>
            {ind === 0 ? <FontAwesomeIcon icon="envelope" color={PRIGHTER_BLUE} /> : <div />}

            <BaseTextField
              testid="email-block"
              value={contactPoint.value}
              onChange={(event) => handleChange(contactPoint, event.target.value)}
              label={
                ind === 0 &&
                tolgee.t({
                  key: "contacts.email",
                })
              }
              containerClassName="w-full"
            />
            {getEndingIcon(
              contactPoint,
              ind + 1 === contactPoints.filter((cp) => cp.type === "EMAIL").length
            )}
          </Fragment>
        ))}
      {contactPoints
        .filter((cp) => cp.type === "WEB")
        .map((contactPoint, ind) => (
          <Fragment key={`${contactPoint.uuid || ind}-web`}>
            {ind === 0 ? <FontAwesomeIcon icon="wifi" color={PRIGHTER_BLUE} /> : <div />}
            <BaseTextField
              value={contactPoint.value}
              onChange={(event) => handleChange(contactPoint, event.target.value)}
              label={
                ind === 0 &&
                tolgee.t({
                  key: "contacts.web",
                })
              }
              containerClassName="w-full"
            />
            {getEndingIcon(
              contactPoint,
              ind + 1 === contactPoints.filter((cp) => cp.type === "WEB").length
            )}
          </Fragment>
        ))}
    </>
  );
}
