import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, IconButton } from "@mui/material";
import { T } from "@tolgee/react";

import { PRIGHTER_BLUE } from "const/color";
import api from "services/api";
import { handleError } from "services/api/error";
import tolgee from "services/translation";
import { useAppDispatch } from "store";
import { notify } from "store/app";

import type { Notification } from "types/notifications";

type Props = {
  notifications: Notification[];
  fetchNotifications: () => void;
};

export default function AddNotification({ notifications, fetchNotifications }: Readonly<Props>) {
  const dispatch = useAppDispatch();

  const noNotifications = () => {
    return (
      <div className="italic">
        <T keyName="notifications.no_active" />
      </div>
    );
  };

  const handleDelete = async (uuid: string) => {
    try {
      await api.notifications.deleteNotification(uuid);
    } catch (e) {
      handleError(e);
      return;
    }
    dispatch(
      notify({
        message: tolgee.t({
          key: "notifications.notifications_deleted",
        }),
        type: "SUCCESS",
      })
    );
    fetchNotifications();
  };

  const handleResendLink = async (uuid: string) => {
    try {
      await api.notifications.resendNotification(uuid);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "notifications.resend_link_success",
        }),
        type: "SUCCESS",
      })
    );
    fetchNotifications();
  };

  const testNotification = async (uuid: string) => {
    try {
      await api.notifications.testNotification(uuid);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "notifications.test_success",
        }),
        type: "SUCCESS",
      })
    );
    fetchNotifications();
  };

  return (
    <div className="border rounded px-4 py-6 space-y-2 mt-4">
      {notifications?.length > 0
        ? notifications?.map((i: Notification) => (
            <div className="flex justify-between" key={i.uuid}>
              <div className="flex space-x-4 items-center">
                <div className="font-bold">{i.address}</div>
                <div>
                  <Chip
                    label={tolgee.t({
                      key: `notifications.${i.type.toLowerCase()}`,
                    })}
                  />
                </div>
                {i.validated ? (
                  <FontAwesomeIcon icon="check" />
                ) : (
                  <div className="italic">
                    {tolgee.t({
                      key: "notifications.waiting",
                    })}
                  </div>
                )}
              </div>
              <div className="flex space-x-4">
                {!i.validated && (
                  <div>
                    <IconButton size="medium" onClick={() => handleResendLink(i.uuid)}>
                      <div className="w-3 h-3 flex items-center justify-center">
                        <FontAwesomeIcon icon="redo" color={PRIGHTER_BLUE} />
                      </div>
                    </IconButton>
                  </div>
                )}
                {i.validated && (
                  <div>
                    <IconButton size="medium" onClick={() => testNotification(i.uuid)}>
                      <div className="w-3 h-3 flex items-center justify-center">
                        <FontAwesomeIcon icon="paper-plane" color={PRIGHTER_BLUE} />
                      </div>
                    </IconButton>
                  </div>
                )}
                {i.deletable && (
                  <div key={i.uuid}>
                    <IconButton size="medium" onClick={() => handleDelete(i.uuid)}>
                      <div className="w-3 h-3 flex items-center justify-center">
                        <FontAwesomeIcon icon="trash" color={PRIGHTER_BLUE} />
                      </div>
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
          ))
        : noNotifications()}
    </div>
  );
}
