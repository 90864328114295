import { Button, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router";

import tolgee from "services/translation";
import { BaseButton, BaseIconButton, BaseModal, SearchField } from "components";
import { notify, changePreference } from "store/app";
import { api } from "services";
import { dataGridConfig } from "helpers/dataGridConfig";
import { useDebounce } from "helpers/hooks";
import UserSearch from "pages/Admin/Vouchers/Components/UserSearch";
import { handleError } from "services/api/error";
import { useAppDispatch, useAppSelector } from "store";
import { updateUrlParams } from "helpers/url";

import type { PaginationRequest } from "types/general";
import type { Moment } from "moment";

export default function Vouchers() {
  const { vouchersRows = 25 } = useAppSelector((state) => state.app.preferences);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [notValidAfter, setNotValidAfter] = useState<Moment>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 0);
  const [orderedBy, setOrderedBy] = useState(searchParams.get("orderBy") || "public_id");
  const [newOrder, setNewOrder] = useState(searchParams.get("order") || "desc");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, errors },
  } = useForm();

  const durationValue = watch("duration");
  const user = watch("referral_user_uuid");

  const handleUrlUpdate = useCallback(
    (params: Record<string, string>) => {
      updateUrlParams(params, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchData = useCallback(
    async (props: PaginationRequest) => {
      setLoading(true);
      const { order_by, page, per_page, order, search_term } = props;
      const desc = order === "desc";

      let res;
      try {
        res = await api.vouchers.getVouchers({
          order_by,
          page: page + 1,
          per_page,
          desc,
          search_term,
        });
      } catch (e) {
        handleError(e);
        setLoading(false);
        return;
      }
      res = res.data;

      const tableData = {
        ...dataGridConfig({ currentPage, rowsPerPage: vouchersRows, count: res.count }),
        onSortModelChange: (sortVal: any) => {
          if (sortVal?.length === 0) {
            return;
          }
          const { field } = sortVal[0];
          const { sort } = sortVal[0];
          setOrderedBy(field);
          setNewOrder(sort);
        },
        onPaginationModelChange: (val: any) => {
          setCurrentPage(val.page);
          dispatch(changePreference({ vouchersRows: val.pageSize }));
        },
        columns: [
          {
            flex: 2,
            field: "public_id",
            headerName: "Voucher ID",
          },
          {
            flex: 1,
            field: "percentage_off",
            headerName: "Percentage off",
            renderCell: (params: any) => {
              return (
                <div className="flex flex-col -ml-2 p-2">
                  {(params.row.percentage_off / 100).toFixed(2)} %
                </div>
              );
            },
          },
          { flex: 1, field: "duration_in_months", headerName: "Duration in month" },
        ],
        rows: res?.result?.map((i: any) => {
          return { ...i, id: i?.uuid };
        }),
      };
      if (tableData) {
        setData(tableData);
      }
      setLoading(false);
    },
    [currentPage, vouchersRows, dispatch]
  );

  const onSubmit = async (fields: any) => {
    const voucherData = {
      ...fields,
      duration: fields.duration || "FOREVER",
      duration_in_months: durationValue === "REPEATING" ? fields.duration_in_months : undefined,
      referral_share: user ? fields.referral_share : undefined,
      not_valid_after: notValidAfter ? notValidAfter.toISOString() : undefined,
    };

    let res;
    try {
      res = await api.vouchers.createVoucher(voucherData);
    } catch (e) {
      handleError(e);
      return;
    }

    if (res.data?.detail && res.data.detail[0].msg) {
      notify({ message: res.data.detail[0].msg, type: "ERROR" });
      return;
    }

    setOpen(false);
    reset();
    setNotValidAfter(null);
    fetchData({
      order_by: orderedBy,
      page: currentPage,
      per_page: vouchersRows,
      order: newOrder,
      search_term: search,
    });
    dispatch(
      notify({
        message: tolgee.t({
          key: "vouchers.voucher_created",
        }),
        type: "SUCCESS",
      })
    );
  };

  const checkDeadlineValue = (val: Moment) => {
    if (val?.isValid()) {
      setNotValidAfter(val);
    }
  };

  const debouncedSearchValue = useDebounce<string>(search, 500);

  useEffect(() => {
    fetchData({
      order_by: orderedBy,
      page: currentPage,
      per_page: vouchersRows,
      order: newOrder,
      search_term: debouncedSearchValue,
    });
  }, [currentPage, vouchersRows, orderedBy, newOrder, debouncedSearchValue, fetchData]);

  useEffect(() => {
    if (debouncedSearchValue !== searchParams.get("search")) {
      handleUrlUpdate({ search: debouncedSearchValue });
    }
  }, [debouncedSearchValue, handleUrlUpdate, searchParams]);

  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <BaseModal
        modalState={[open, setOpen]}
        closeEvent={() => {
          setNotValidAfter(null);
          reset();
        }}>
        <div className="flex flex-col space-y-4 w-[600px]">
          <TextField
            required
            {...register("public_id", {
              validate: {
                minLength: (e) => `${e}`.length > 0,
              },
            })}
            error={!!errors?.public_id}
            label={<T keyName="vouchers.voucher_code" />}
            style={{ backgroundColor: "white" }}
          />
          <TextField
            required
            id="select"
            defaultValue="FOREVER"
            value={durationValue}
            onChange={(event: any) => {
              setValue("duration", event.target?.value);
            }}
            select
            style={{ backgroundColor: "white" }}
            label="Duration">
            <MenuItem value="REPEATING">
              <T keyName="vouchers.repeating" />
            </MenuItem>
            <MenuItem value="FOREVER">
              <T keyName="vouchers.forever" />
            </MenuItem>
          </TextField>
          {durationValue === "REPEATING" && (
            <TextField
              required
              {...register("duration_in_months", {
                validate: {
                  minLength: (e) => `${e}`.length > 0,
                },
              })}
              error={!!errors?.duration_in_months}
              style={{ backgroundColor: "white" }}
              label={<T keyName="vouchers.duration_month" />}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
            />
          )}
          <TextField
            required
            {...register("percentage_off", {
              min: 0,
              max: 100,
              validate: {
                minLength: (e) => `${e}`.length > 0,
              },
            })}
            error={!!errors?.percentage_off}
            style={{ backgroundColor: "white" }}
            label={<T keyName="vouchers.percentage_off" />}
            type="number"
            InputProps={{ inputProps: { min: 0, max: 100 } }}
          />
          <UserSearch
            onChange={(_, value) => setValue("referral_user_uuid", value ? value.value : null)}
          />
          {user && (
            <TextField
              required
              {...register("referral_share", {
                min: 0,
                max: 100,
              })}
              error={!!errors?.referral_share}
              style={{ backgroundColor: "white" }}
              label={<T keyName="vouchers.referral_share" />}
            />
          )}
          <div className="flex">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disablePast
                format="DD/MM/YYYY"
                className="w-full bg-white"
                label={<T keyName="vouchers.not_valid_after" />}
                value={notValidAfter}
                onChange={(newValue) => checkDeadlineValue(newValue)}
              />
            </LocalizationProvider>
          </div>
          <div className="w-fit mt-4 flex self-end">
            <BaseButton onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
              <T keyName="generic.create" />
            </BaseButton>
          </div>
        </div>
      </BaseModal>
      <div className="my-4 flex justify-end">
        <Button onClick={() => setOpen(true)}>
          <T keyName="vouchers.create_voucher" />
        </Button>
      </div>
      <div className="box-outerlayout">
        <h2>
          <T keyName="vouchers.all_vouchers" />
        </h2>
        {data ? (
          <div className="space-y-6">
            <div className="flex space-x-4 items-center">
              <BaseIconButton
                className="border-full"
                onClick={() =>
                  fetchData({
                    order_by: orderedBy,
                    page: currentPage,
                    per_page: vouchersRows,
                    order: newOrder,
                    search_term: search,
                  })
                }>
                <div className="w-4 h-4 flex items-center justify-center">
                  <FontAwesomeIcon icon="refresh" color="gray" />
                </div>
              </BaseIconButton>
              <SearchField className="w-full" searchState={[search, setSearch]} />
            </div>

            <DataGrid {...data} loading={loading} />
          </div>
        ) : (
          <div>
            <T keyName="generic.no_data" />
          </div>
        )}
      </div>
    </div>
  );
}
