import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";
import { Checkbox, TextField } from "@mui/material";

import { notify } from "store/app";
import { BaseButton } from "components";
import { api } from "services";
import tolgee from "services/translation";
import { handleError } from "services/api/error";

import type { BusinessConfig } from "types/clp";

type Props = {
  configData: BusinessConfig;
  onLinkUpdate: () => unknown;
};

export default function PrivacyPolicyModal({ configData, onLinkUpdate }: Readonly<Props>) {
  const { businessId } = useParams();
  const dispatch = useDispatch();

  const defaultValues = useMemo(
    () => ({
      privacy_policy_ext_url: configData?.privacy_policy_ext_url,
      added: configData?.prighter_in_privacy_policy,
    }),
    [configData]
  );

  const {
    reset,
    handleSubmit,
    register,
    control,
    watch,
    formState: { isValid },
  } = useForm({
    defaultValues,
  });

  const added = watch("added");
  const linkReg = register("privacy_policy_ext_url");

  useEffect(() => {
    reset(defaultValues);
  }, [configData, reset, defaultValues]);

  const onSubmit = async (values: any) => {
    const settings = {
      ...configData,
      privacy_policy_ext_url: values.privacy_policy_ext_url,
      prighter_in_privacy_policy: added,
    };

    try {
      await api.clp.updateBusinessConfig(businessId, settings);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "billing.link_updated_successfully",
        }),
        type: "SUCCESS",
      })
    );
    onLinkUpdate();
    handleFormReset();
  };

  const handleFormReset = () => {
    reset();
  };

  return (
    <>
      <h1 className="flex w-[400px] mbs-8">
        <T keyName="billing.privacy_url" />
      </h1>
      <div className="flex w-full flex-col mb-2">
        <form>
          <TextField
            className="w-full"
            onChange={linkReg.onChange}
            onBlur={linkReg.onBlur}
            name={linkReg.name}
            ref={linkReg.ref}
            data-testid="link"
          />
          <div className="flex items-center font-bold mb-4 mt-4 -ml-2">
            <Controller
              name="added"
              control={control}
              defaultValue={false}
              render={({ field: { value, ref, ...field } }: any) => (
                <Checkbox {...field} inputRef={ref} checked={!!value} />
              )}
            />
            <div className="mt-1">
              <T keyName="gdpr.eurep_info" />
            </div>
          </div>
          <div className="flex justify-end space-x-2 mt-8">
            <BaseButton onClick={handleSubmit(onSubmit)} disabled={!isValid}>
              <T keyName="generic.save" />
            </BaseButton>
          </div>
        </form>
      </div>
    </>
  );
}
