import clsx from "clsx";

import { PRIGHTER_URL, PRIVACY_POLICY } from "const/env";
import { getUrl } from "helpers";

type Props = { theme?: "light" | "dark" };

export default function PrighterFooter({ theme = "light" }: Props) {
  const textStyle = clsx(theme === "light" && "text-gray-600", theme === "dark" && "text-gray-200");

  return (
    <div className={clsx("flex flex-row justify-between", textStyle)}>
      <div>
        Powered by{" "}
        <a className={textStyle} href={PRIGHTER_URL}>
          Prighter.com
        </a>
      </div>
      <div className="flex flex-row space-x-2">
        <div>
          <a className={textStyle} href={getUrl("FLASK", "/legal")}>
            Legal
          </a>
        </div>
        <div>
          <a className={textStyle} href={PRIVACY_POLICY}>
            Privacy
          </a>
        </div>
      </div>
    </div>
  );
}
