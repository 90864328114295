import { Autocomplete, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useState, useEffect } from "react";

import { useDebounce } from "helpers/hooks";
import { api } from "services";
import { handleError } from "services/api/error";
import { UserFilter, type GetUsersRequest } from "types/user";

type Props = { onChange: (e: any, v: any) => void };

function UserSearch({ onChange }: Readonly<Props>) {
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");
  const debouncedSearchValue = useDebounce<string>(search, 500);

  useEffect(() => {
    fetchData({
      order_by: "email",
      order: "asc",
      search_term: debouncedSearchValue,
      user_filter: UserFilter.PARTNER,
    });
  }, [debouncedSearchValue]);

  const fetchData = async (props: GetUsersRequest) => {
    const { order_by, order, search_term } = props;
    const desc = order === "desc";

    let res;
    try {
      res = await api.user.list({
        order_by,
        page: 1,
        per_page: search_term ? 1000 : 10,
        desc,
        search_term,
        user_filter: UserFilter.PARTNER,
      });
    } catch (e) {
      handleError(e);
      return;
    }

    const newOptions = res.data.result.map((item) => ({
      label: `${item.email}: ${item.company_name}`,
      value: item.uuid,
    }));
    setOptions(newOptions);
  };

  const filterOptions = (_: any, state: any): any => {
    setSearch(state.inputValue.toLowerCase());
    return options;
  };

  return (
    <div>
      <Autocomplete
        filterSelectedOptions
        options={options}
        filterOptions={filterOptions}
        style={{ marginTop: "10px", backgroundColor: "white" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={<T keyName="vouchers.referral_user" />}
            variant="outlined"
          />
        )}
        onChange={onChange}
      />
    </div>
  );
}

export default UserSearch;
