import { T } from "@tolgee/react";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation";

import type { StageChangedEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function StageChanged(props: {
  entityData: StageChangedEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      title={
        <>
          {tolgee.t({
            key: "dsr.case_on",
          })}
          <span className="capitalize">&quot;{entityData.new}&quot;</span>
        </>
      }
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="flex flex-col p-4 space-y-2">
        <span>
          <b>
            <T keyName="dsr.new_stage" />
          </b>{" "}
          <span className="capitalize">{entityData.new}</span>
        </span>
        <span>
          <b>
            <T keyName="dsr.old_stage" />
          </b>{" "}
          <span className="capitalize">{entityData.old}</span>
        </span>
      </div>
    </HistoryEntryTemplate>
  );
}
