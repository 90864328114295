import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { BaseSelectField, BaseTextField } from "components";
import AddressForm from "components/complex/AddressForm";
import { PRIGHTER_BLUE } from "const/color";
import tolgee from "services/translation";
import { api } from "services";
import AuthorityContactPointList from "pages/Client/Case/Authority/components/AuthorityContactPointList";
import { handleError } from "services/api/error";

import type { UseState } from "types";
import type { Contact } from "types/contactDirectory";

type Props = {
  contactState: UseState<Contact>;
  noCommentField?: boolean;
  companyTitle?: string;
  noIndividual?: boolean;
};

export default function AuthorityContactEdit({
  contactState,
  noCommentField = false,
  companyTitle = "company",
  noIndividual = false,
}: Props) {
  const [contact, setContact] = contactState;
  const [permissions, setPermissions] = useState<any>();
  const salutationOptions = { mr: "Mr.", mrs: "Mrs." };

  const getPermissions = useCallback(async () => {
    let res;
    try {
      res = await api.security.getPermissions("BUSINESS", "WRITE");
    } catch (e) {
      handleError(e);
      return;
    }

    setPermissions(res.data);
    if (res.data.length === 1) {
      setContact((oldContact) => ({
        ...oldContact,
        business: {
          public_id: res.data[0]?.target_public_id,
        },
      }));
    }
  }, [setContact]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  return (
    <div className="grid grid-cols-[20px_1fr_20px] gap-4 items-center" data-testid="contact-form">
      {permissions?.length > 1 && (
        <>
          <FontAwesomeIcon icon="building" color={PRIGHTER_BLUE} />
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-name-label">
              {tolgee.t({
                key: "contacts.select_business",
              })}
            </InputLabel>
            <Select
              required
              label={tolgee.t({
                key: "contacts.select_business",
              })}
              id="select"
              className="w-full"
              value={contact?.business?.public_id || ""}
              onChange={(event: any) => {
                setContact((oldContact) => ({
                  ...oldContact,
                  business: {
                    public_id: event.target?.value,
                  },
                }));
              }}>
              {permissions?.map((i: any) => (
                <MenuItem key={i.target_name} value={i.target_public_id}>
                  {`${i.target_name} (${i.target_public_id})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div />
        </>
      )}
      {!noIndividual && (
        <>
          <FontAwesomeIcon icon="user" color={PRIGHTER_BLUE} />
          <div className="flex space-x-4">
            <div className="w-20">
              <BaseSelectField
                label={tolgee.t({
                  key: "contacts.title",
                })}
                options={salutationOptions}
                defaultValue=""
                state={[
                  contact.salutation || "",
                  (option: string) =>
                    setContact((oldContact) => ({
                      ...oldContact,
                      salutation: option,
                    })),
                ]}
              />
            </div>

            <BaseTextField
              testid="name"
              containerClassName="w-full"
              value={contact.name}
              onChange={(event) =>
                setContact((oldContact) => ({
                  ...oldContact,
                  name: event.target.value,
                }))
              }
              label={tolgee.t({
                key: "contacts.name",
              })}
            />
          </div>

          <div />
        </>
      )}
      <>
        <FontAwesomeIcon icon="building" color={PRIGHTER_BLUE} />
        <BaseTextField
          label={tolgee.t({
            key: `contacts.${companyTitle}`,
          })}
          containerClassName="w-full"
          value={contact.organization}
          onChange={(event) =>
            setContact((oldContact) => ({
              ...oldContact,
              organization: event.target.value,
            }))
          }
        />
        <div />
      </>
      <AuthorityContactPointList
        contactPoints={contact.contact_points}
        onChange={(contactPoints) =>
          setContact((prevContact) => ({
            ...prevContact,
            contact_points: contactPoints,
          }))
        }
      />
      <AddressForm
        multiple
        addressIcon
        contactAddresses={contact.addresses}
        onChange={(contactAddresses) =>
          setContact((prevContact) => ({
            ...prevContact,
            addresses: contactAddresses,
          }))
        }
      />
      {!noCommentField && (
        <>
          <FontAwesomeIcon className="self-start" icon="comment" color={PRIGHTER_BLUE} />
          <BaseTextField
            containerClassName="w-full"
            multiline
            value={contact.comment}
            onChange={(event) =>
              setContact((oldContact) => ({ ...oldContact, comment: event.target.value }))
            }
            label={tolgee.t({
              key: "generic.comment",
            })}
          />
          <div />
        </>
      )}
    </div>
  );
}
