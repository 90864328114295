import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

type Props = {
  order: "asc" | "desc";
  orderBy: string;
  shouldSort?: boolean;
  onRequestSort: (event: any, property: any) => unknown;
  keys: string[];
  actionTitle?: string;
  noTableHead?: boolean;
};

export default function EnhancedTableHead(props: Props) {
  const {
    order,
    orderBy,
    shouldSort = true,
    onRequestSort,
    keys,
    actionTitle,
    noTableHead = false,
  } = props;

  const createSortHandler = (property: any) => (event: any) => {
    if (shouldSort) {
      onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow style={{ height: noTableHead ? "0px" : "60px" }}>
        {keys.map((headCell) => (
          <TableCell
            style={{ backgroundColor: "#d9d9d9" }}
            key={headCell}
            sortDirection={orderBy === headCell ? order : false}>
            {shouldSort ? (
              <TableSortLabel
                className="font-lg font-bold"
                active={orderBy === headCell}
                direction={orderBy === headCell ? order : "asc"}
                onClick={createSortHandler(headCell)}>
                {headCell}
                {orderBy === headCell ? (
                  <span
                    style={{
                      border: 0,
                      clip: "rect(0 0 0 0)",
                      height: 1,
                      margin: -1,
                      overflow: "hidden",
                      padding: 0,
                      position: "absolute",
                      top: 20,
                      width: 1,
                    }}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              <span className="font-lg font-bold">{headCell}</span>
            )}
          </TableCell>
        ))}
        {actionTitle && (
          <TableCell
            style={{
              backgroundColor: "#d9d9d9",
            }}
            className="font-lg font-bold"
            align="right">
            {actionTitle}
          </TableCell>
        )}
        {keys.length === 0 && !noTableHead && <TableCell>Empty</TableCell>}
      </TableRow>
    </TableHead>
  );
}
