import { createSlice } from "@reduxjs/toolkit";

import type { NavMenu as Menu } from "types/components";
import type { PayloadAction } from "@reduxjs/toolkit";

export const initialState = [] as Array<Menu>;

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu: (previous_state, action: PayloadAction<Menu[]>) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMenu } = menuSlice.actions;

export default menuSlice.reducer;
