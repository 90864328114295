import { T } from "@tolgee/react";

export default function NotFound() {
  return (
    <div
      className="flex items-center w-full h-screen justify-center bg-gray-50"
      data-testid="error-element">
      <div className="text-center">
        <h1>404</h1>
        <h2>
          <T keyName="generic.not_found" />
        </h2>
        <p>...</p>
      </div>
    </div>
  );
}
