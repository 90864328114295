import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import tolgee from "services/translation/tolgee";

import type { CaseCreateInquiriesEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function CaseCreateInquiries(props: {
  entityData: CaseCreateInquiriesEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="question" color="white" size="lg" />}
      title={entityData.title}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="p-4 space-y-2">
        {entityData?.inquiries?.map((i) => (
          <div key={i.uuid}>
            {i.question_i18n && (
              <div className="flex flex-col">
                <span>
                  <b>
                    {tolgee.t({
                      key: "dsr.question",
                    })}
                  </b>
                  {": "}
                  {tolgee.t({
                    key: `dsr.inquiry.${i.question_i18n.key.toLowerCase()}_title`,
                  })}
                </span>
              </div>
            )}
            {i.question && (
              <div className="flex flex-col">
                <span>
                  <b>
                    {tolgee.t({
                      key: "dsr.question",
                    })}
                  </b>
                  : {i.question}
                </span>
              </div>
            )}
            <div className="flex flex-col">
              <span>
                <b>
                  {tolgee.t({
                    key: "dsr.files_required",
                  })}
                </b>
                {": "}
                {i.upload_required
                  ? tolgee.t({
                      key: "generic.yes",
                    })
                  : tolgee.t({
                      key: "generic.no",
                    })}
              </span>
            </div>
          </div>
        ))}
      </div>
    </HistoryEntryTemplate>
  );
}
