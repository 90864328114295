import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import tolgee from "services/translation";

function Row(props: { row: any; columns: Column[] }) {
  const { row, columns } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <FontAwesomeIcon icon={open ? "arrow-up" : "arrow-down"} />
          </IconButton>
        </TableCell>
        {columns.map((column: Column) => (
          <TableCell component="th" scope="row">
            {row[column.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="pt-4">
              {row.collapsible}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

type Column = {
  name: string;
  field: string;
};

type Props = {
  data: any;
  columns: Column[];
};

export default function CollapsibleTable(props: Props) {
  const { data, columns } = props;
  return (
    <div className="mx-auto max-w-7xl">
      {data?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map((column: Column) => (
                  <TableCell>{column.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row: any, idx: number) => <Row key={idx} row={row} columns={columns} />)}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div>
          {tolgee.t({
            key: "billing.no_data",
          })}
        </div>
      )}
    </div>
  );
}
