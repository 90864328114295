import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Tooltip,
  Typography,
  ListItemText,
} from "@mui/material";

import type { SelectChangeEvent } from "@mui/material";

interface SelectFieldProps {
  options: Array<string>;
  value: string;
  onChange: (value: string) => void;
  label: string;
  isReadOnly?: boolean;
  testId?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectField({
  options,
  value,
  onChange,
  label,
  isReadOnly,
  testId,
}: SelectFieldProps) {
  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    onChange(event.target.value as string);
  };

  const isValueLong = (text: string): boolean => {
    return text.length > 40;
  };

  const getStyles = () => ({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px",
  });

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="select-field-label">{label}</InputLabel>
      <Select
        labelId="select-field-label"
        id="select-field"
        data-testId={testId}
        value={value}
        onChange={handleChange}
        readOnly={isReadOnly}
        input={<OutlinedInput label={label} />}
        MenuProps={MenuProps}
        renderValue={(selected) => {
          return isValueLong(selected) ? (
            <Tooltip title={selected}>
              <Typography style={getStyles()}>{selected}</Typography>
            </Tooltip>
          ) : (
            <Typography>{selected}</Typography>
          );
        }}>
        {options.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              <ListItemText primary={option} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
