import { useEffect } from "react";

import { ProgressBar } from "components";
import { redirectToFlaskUrl } from "helpers";

export default function Redirect() {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      redirectToFlaskUrl("/logout");
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div>
      <ProgressBar />
    </div>
  );
}
