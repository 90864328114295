import { API_BASE_ROUTE, PRIGHTER_URL } from "const/env";

import type { IconName } from "@fortawesome/fontawesome-svg-core";
import type { Country, FileObject } from "types/general";
import type { RawDateString, RawDateTimeString } from "types/date";

export function getUrl(type: "API" | "FLASK", path: string, params?: URLSearchParams): string {
  let finalPath = path;
  if (type === "API" && API_BASE_ROUTE.endsWith("/api")) {
    finalPath = `/api${path}`;
  }

  const route = new URL(finalPath, type === "API" ? API_BASE_ROUTE : PRIGHTER_URL);
  if (params) {
    route.search = params.toString();
  }
  return route.toString();
}

export function getFileIcon(type: string): IconName | any {
  const fileType = type.toLowerCase();

  switch (true) {
    case fileType.startsWith("image"):
      return "file-image";
    case fileType.startsWith("video"):
      return "file-video";
    case fileType.startsWith("text"):
      return "file-text";
    case fileType.includes("pdf"):
      return "file-pdf";
    case fileType.includes("word"):
      return "file-word";
    case ["zip", "tar", "rar"].some((el: string) => fileType.includes(el)):
      return "file-zipper";
    case fileType.includes("excel"):
      return "file-excel";
    default:
      return "file";
  }
}

export function getBeautyDateTime(dateTimeRaw?: RawDateTimeString): string {
  if (!dateTimeRaw) {
    return "";
  }

  return new Date(dateTimeRaw).toLocaleString();
}

export function getBeautyDate(
  dateTimeRaw?: RawDateTimeString | RawDateString,
  long?: boolean
): string {
  if (!dateTimeRaw) {
    return "";
  }
  if (long) {
    return new Date(dateTimeRaw).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return new Date(dateTimeRaw).toLocaleDateString();
}

export function reorderArrayElements(from: number, to: number, array: any): void {
  array.splice(to, 0, array.splice(from, 1)[0]);
}

export function getCountryAsOptions(countries: Country[]): any {
  return countries.map((country) => ({
    key: country.iso_3166_1_alpha_2,
    label: country.flag ? `${country.flag} ${country.name}` : country.name,
  }));
}

export function objectDeepCopy(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export function snakeCaseToSentenceCase(word: string): string {
  return word
    .split("_")
    .map((state) => state.charAt(0).toUpperCase() + state.slice(1))
    .join(" ");
}

export function getFileName(file: FileObject): string {
  return file?.name_original?.length > 0 ? `${file.name} (${file.name_original})` : file.name;
}

export function isEmpty(object: object): boolean {
  return Object.values(object).every((x) => x === null || x === "");
}

export function redirectToFlaskUrl(url: string): void {
  window.location.assign(getUrl("FLASK", url));
}

export const getInputValue = (dict: any, key: any): any => {
  return dict && dict[key] ? dict[key] : null;
};

export const isString = (value: any): boolean => {
  return typeof value === "string" || value instanceof String;
};

export const isStringEmpty = (value: any): boolean => {
  return !value || (isString(value) && value && value.trim() === "");
};

export const convertBase64ToFileObject = (
  base64: string,
  fileName: string,
  mimeType: string
): File => {
  const base64Data = base64.split(",")[1];
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimeType });
  const file = new File([blob], fileName, { type: mimeType });

  return file;
};

export const fileToFileObject = async (file: File): Promise<FileObject> => {
  if (!(file instanceof File)) {
    throw new Error("Invalid file parameter: must be a File object.");
  }

  return new Promise<FileObject>((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = (): void => {
      const base64String = reader.result as string;
      resolve({
        name: file.name,
        content: base64String,
        mime_type: file.type,
        name_original: file.name,
      });
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};
