import { T } from "@tolgee/react";
import clsx from "clsx";
import { useEffect, useState } from "react";

import type { DashboardStats } from "types/user";

type Props = { stats: DashboardStats };

export default function StatCard({ stats }: Props) {
  const [landingPageVisitChange, setLandingPageVisitChange] = useState(0);

  useEffect(() => {
    const visits = stats.landing_page_visits;

    if (visits.last_31_60_days !== 0 && visits.last_30_days !== 0) {
      const changes =
        ((visits.last_30_days - visits.last_31_60_days) / visits.last_31_60_days) * 100;
      setLandingPageVisitChange(Math.round(((changes + Number.EPSILON) * 100) / 100));
    }
  }, [stats]);

  return (
    <div className="flex w-full md:w-6/12" data-testid="stats-section">
      <div className="w-full md:w-auto flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4">
        <div className="box-innerlayout p-4 w-full md:w-[12rem] flex flex-col items-center text-center">
          <div className="text-4xl font-bold text-gray-900 flex">
            {stats.landing_page_visits.all_time}
            {landingPageVisitChange !== 0 && (
              <span
                className={clsx("text-sm", {
                  "text-success-400": landingPageVisitChange > 0,
                  "text-danger-400": landingPageVisitChange < 0,
                })}>
                {landingPageVisitChange}%
              </span>
            )}
          </div>
          <div className="text-sm font-medium text-gray-500">
            <T keyName="dashboard.clp_visitors" />
          </div>
        </div>
        <div className="box-innerlayout p-4 w-full md:w-[12rem] flex flex-col items-center text-center">
          <div className="text-4xl font-bold text-gray-900">{stats.open_dsrs}</div>
          <div className="text-sm font-medium text-gray-500">
            <T keyName="dashboard.open_requests" />
          </div>
        </div>
      </div>
    </div>
  );
}
