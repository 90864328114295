import { Button, MenuItem, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { faker } from "faker";

import { notify } from "store/app";
import { api } from "services";
import tolgee from "services/translation";
import ExtendedSelectField from "components/Input/ExtendedSelectField";
import { useAppSelector } from "store";
import { getCountryAsOptions, getUrl } from "helpers";
import { handleError } from "services/api/error";
import { DEV_ENV } from "const/env";

const businessSizeValues = ["GROWTH", "SMALL", "MEDIUM", "LARGE"];

const defaultValues = {
  street: DEV_ENV ? faker.lorem.words(2) : "",
  postal: "",
  city: DEV_ENV ? faker.lorem.words(1) : "",
  country_iso: DEV_ENV ? "AT" : "",
  role: DEV_ENV ? faker.lorem.words(2) : "",
  name: DEV_ENV ? faker.lorem.words(2) : "",
  email: DEV_ENV ? `${faker.lorem.words(1)}@${faker.internet.domainName()}` : "",
  position: "",
  password: "",
  company: DEV_ENV ? faker.lorem.words(2) : "",
  authorised_to_sign: DEV_ENV ? "..." : "",
  size: DEV_ENV ? businessSizeValues[0] : "",
};

export default function ManualOnboarding() {
  const [countryOptions, setCountryOptions] = useState([]);
  const { countries } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (countries) {
      const options = getCountryAsOptions(countries);
      setCountryOptions(options);
    }
  }, [countries]);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues,
  });

  const companySize = watch("size");
  const countryISO = watch("country_iso");

  const emailReg = register("email");
  const nameReg = register("name");
  const roleReg = register("role");
  const companyReg = register("company");
  const authReg = register("authorised_to_sign");
  const streetReg = register("street");
  const cityReg = register("city");
  const codeReg = register("postal");

  const onSubmit = async (data: any) => {
    const newBusiness = {
      user: {
        name: data.name,
        email: data.email,
        position: data.role,
        password: "",
      },
      address: {
        street: data.street,
        postal: data.postal,
        city: data.city,
        country_iso: countryISO,
      },
      company_name: data.company,
      authorised_to_sign: data.authorised_to_sign,
      size: companySize?.toUpperCase(),
    };

    try {
      const res = await api.business.createBusiness(newBusiness);
      const link = getUrl("FLASK", `/administration/customer/${res.data.uuid}/ghostlogin`);
      window.location.href = link;
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "onboarding.success",
        }),
        type: "SUCCESS",
      })
    );
    reset();
    setValue("country_iso", "");
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="onboarding">
      <div className="box-outerlayout pb-4 pt-10 space-y-4">
        <TextField
          label={<T keyName="onboarding.email" />}
          required
          onChange={emailReg.onChange}
          onBlur={emailReg.onBlur}
          name={emailReg.name}
          ref={emailReg.ref}
          data-testid="email"
        />
        <TextField
          label={<T keyName="onboarding.name" />}
          required
          onChange={nameReg.onChange}
          onBlur={nameReg.onBlur}
          name={nameReg.name}
          ref={nameReg.ref}
          data-testid="name"
        />
        <TextField
          label={<T keyName="onboarding.role" />}
          required
          onChange={roleReg.onChange}
          onBlur={roleReg.onBlur}
          name={roleReg.name}
          ref={roleReg.ref}
          data-testid="role"
        />
        <TextField
          label={<T keyName="onboarding.company" />}
          required
          onChange={companyReg.onChange}
          onBlur={companyReg.onBlur}
          name={companyReg.name}
          ref={companyReg.ref}
          data-testid="company"
        />
        <TextField
          label={<T keyName="onboarding.authorise_sign" />}
          required
          onChange={authReg.onChange}
          onBlur={authReg.onBlur}
          name={authReg.name}
          ref={authReg.ref}
          data-testid="authorise"
        />
        <TextField
          select
          className="w-full"
          id="select"
          required
          value={companySize}
          onChange={(event: any) => {
            setValue("size", event.target?.value);
          }}
          label={tolgee.t({
            key: "onboarding.size",
          })}>
          {businessSizeValues.map((i) => (
            <MenuItem value={i} key={i}>
              <T keyName={`onboarding.${i}`} />
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={<T keyName="onboarding.street" />}
          required
          onChange={streetReg.onChange}
          onBlur={streetReg.onBlur}
          name={streetReg.name}
          ref={streetReg.ref}
          data-testid="street"
        />
        <TextField
          label={<T keyName="onboarding.city" />}
          required
          onChange={cityReg.onChange}
          onBlur={cityReg.onBlur}
          name={cityReg.name}
          ref={cityReg.ref}
          data-testid="city"
        />
        <TextField
          label={<T keyName="onboarding.code" />}
          onChange={codeReg.onChange}
          onBlur={codeReg.onBlur}
          name={codeReg.name}
          ref={codeReg.ref}
          data-testid="code"
        />
        <div>
          <ExtendedSelectField
            key={countryISO}
            state={[countryISO, (text: string) => setValue("country_iso", text)]}
            options={countryOptions}
            className="min-w-[12rem]"
            label={tolgee.t({
              key: "generic.country",
            })}
            required
          />
        </div>
        <div className="w-full flex justify-end">
          <Button
            data-testid="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty && !isValid}>
            <T keyName="generic.submit" />
          </Button>
        </div>
      </div>
    </div>
  );
}
