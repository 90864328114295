import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown";
import { T } from "@tolgee/react";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";

import type { CaseFinalizePreviewEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function CaseFinalizePreview(props: {
  entityData: CaseFinalizePreviewEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="envelope" color="white" size="lg" />}
      title={entityData.title}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="p-2 flex flex-col space-y-4" style={{ paddingLeft: "20px" }}>
        <span>
          <b className="capitalize">
            <T keyName="generic.subject" />:
          </b>
          {entityData.subject}
        </span>
        <div className="bg-gray-200 p-2 secondarybox-outerlayout" style={{ maxWidth: "50rem" }}>
          <div className="prose max-w-[100%]">
            <ReactMarkdown>{entityData.content}</ReactMarkdown>
          </div>
        </div>
      </div>
    </HistoryEntryTemplate>
  );
}
