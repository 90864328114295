import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type { ForwardEmailCasePayloadInterface } from "types/email";

export async function postEmailCaseForward(
  caseUuid: string,
  forwardContent: ForwardEmailCasePayloadInterface
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/email/case/forward", forwardContent, {
    params: { case_uuid: caseUuid },
  });
}
