import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { getOpenInquiries } from "helpers/case";
import OpenInquiriesDataField from "pages/Client/Case/DSR/ClientView/Components/OpenInquiries/DataField";

export default function OpenInquiries() {
  const [state] = useCase();

  const openInquiries = getOpenInquiries(state.case.inquiries);
  const otherInfo = openInquiries?.map((i) => ({
    key: i?.question_i18n
      ? tolgee.t({
          key: `dsr.inquiry.${i?.question_i18n.key}_title`,
        })
      : i.question,
    upload: i?.upload_required,
  }));

  return (
    openInquiries.length > 0 && (
      <div className="pt-5 mb-4 box-outerlayout" data-testid="dsr-info">
        <div className="flex items-center">
          <h2>
            {tolgee.t({
              key: "dsr.open_inq",
            })}{" "}
            ({openInquiries?.length})
          </h2>
        </div>
        <div className="grid px-2 -mx-2 divide-y divide-gray-200 rounded-lg ">
          <div className="@xl:flex grid @xl:flex-wrap  gap-4">
            {otherInfo?.map((i) => (
              <div className="px-4 border rounded-lg " key={i.key}>
                <OpenInquiriesDataField key={i.key} label={i.key} upload={i.upload} />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
}
