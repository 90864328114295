import * as Sentry from "@sentry/react";

import { api } from "services";
import { setUser } from "store/user";
import { getCurrentCaseStage, getLatestActiveCheck, getStageChecks } from "helpers/case";
import { store, type AppDispatch } from "store";
import { notify } from "store/app";
import tolgee from "services/translation";
import { setMenu } from "store/menu";
import { handleError } from "services/api/error";
import { DocumentSignatureCheckNameEnum, StageNameEnum } from "types/case";

import type { ProviderAction } from "pages/Client/Case/CaseContext/reducer";
import type { Dispatch } from "react";
import type { CheckName } from "types/case";

export const fetchUserData =
  () =>
  async (dispatch: AppDispatch): Promise<boolean> => {
    let res;
    try {
      res = await api.user.get();
    } catch (e) {
      handleError(e, true);
      return false;
    }
    dispatch(setUser(res.data));
    Sentry.setUser({ id: res.data.uuid, email: res.data.email });
    return true;
  };

export const fetchMenuData =
  () =>
  async (dispatch: AppDispatch): Promise<boolean> => {
    let res;
    try {
      res = await api.general.menu();
    } catch (e) {
      handleError(e, true);
      return false;
    }
    dispatch(setMenu(res.data));
    return true;
  };

export const fetchCaseData = () => {
  return async (
    dispatch: Dispatch<ProviderAction>,
    uuid: string,
    reloadUI = true
  ): Promise<void> => {
    dispatch({ type: "LOADING" });
    let res;
    try {
      res = await api.authCase.getCase(uuid);
    } catch (e) {
      handleError(e, false, true);
      dispatch({ type: "FETCH_FAIL" });
      return;
    }
    res = res.data;

    dispatch({ type: "FETCH_SUCCESS", payload: res });

    if (reloadUI) {
      if (!["CLOSED", "DONE"].includes(res.state)) {
        const currentStage = getCurrentCaseStage(res);
        dispatch({ type: "UPDATE_UI_STAGE", payload: currentStage });

        if (
          [
            StageNameEnum.DOC_SIGNATURE,
            StageNameEnum.DOC_CLIENT_REVIEW,
            StageNameEnum.DOC_REVIEW,
            StageNameEnum.PRE_CHECK,
            StageNameEnum.HANDLING,
          ].includes(currentStage)
        ) {
          const checks = getStageChecks(
            res.case_checks,
            currentStage as
              | StageNameEnum.DOC_SIGNATURE
              | StageNameEnum.DOC_CLIENT_REVIEW
              | StageNameEnum.DOC_REVIEW
              | StageNameEnum.PRE_CHECK
              | StageNameEnum.HANDLING
          );
          const activeCheck = getLatestActiveCheck(checks);
          dispatch({ type: "UPDATE_UI_CHECK", payload: activeCheck });
        }
      } else {
        dispatch({ type: "UPDATE_UI_STAGE", payload: StageNameEnum.HANDLING_REVIEW });
      }
    }
  };
};

export const finalizeCheck = () => {
  return async (
    dispatch: Dispatch<ProviderAction>,
    caseUuid: string,
    completedCheck: CheckName,
    decisionName?: string
  ): Promise<void> => {
    dispatch({ type: "LOADING" });
    try {
      await api.authCase.closeCheck(caseUuid, {
        check_name: completedCheck,
        decision_name: decisionName || null,
      });
    } catch (e) {
      handleError(e);
      return;
    } finally {
      fetchCaseData()(dispatch, caseUuid);
    }

    store.dispatch(
      notify({
        message: tolgee.t({
          key: "dsr.check_finished_successfully",
        }),
        type: "SUCCESS",
      })
    );
  };
};

export const reopenCheck = () => {
  return async (
    dispatch: Dispatch<ProviderAction>,
    caseUuid: string,
    checkName: CheckName
  ): Promise<void> => {
    dispatch({ type: "LOADING" });
    try {
      await api.authCase.reopenCheck(caseUuid, checkName);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      if (checkName === DocumentSignatureCheckNameEnum.DOC_SIGNED) {
        fetchCaseData()(dispatch, caseUuid);
      } else {
        fetchCaseData()(dispatch, caseUuid, false);
      }
    }

    store.dispatch(
      notify({
        message: tolgee.t({
          key: "dsr.check_reopened_successfully",
        }),
        type: "SUCCESS",
      })
    );
  };
};
