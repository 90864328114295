import { useCallback, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { api } from "services";
import tolgee from "services/translation";
import { handleError } from "services/api/error";
import { PRIGHTER_BLUE } from "const/color";
import { LinkButton } from "components";
import { getUrl } from "helpers";
import { dataGridConfig } from "helpers/dataGridConfig";
import { useAppDispatch, useAppSelector } from "store";
import { changePreference } from "store/app";

export default function SpecialDealMix() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const { specialDealMixRows = 25 } = useAppSelector((state) => state.app.preferences);

  const renderActionButtonsCell = useCallback(
    (params: any) => (
      <LinkButton
        color="NONE"
        href={getUrl("FLASK", `/administration/manage_customer/${params.id}`)}>
        <div className="w-3 h-3 flex items-center justify-center">
          <FontAwesomeIcon size="lg" icon="user" color={PRIGHTER_BLUE} />
        </div>
      </LinkButton>
    ),
    []
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    let res;
    try {
      res = await api.report.getSpecialDealMixed();
    } catch (e) {
      handleError(e);
      setLoading(false);
      return;
    }
    res = res.data;

    const tableData = {
      ...dataGridConfig({ currentPage, rowsPerPage: specialDealMixRows, count: res.length }),
      onPaginationModelChange: (val: any) => {
        setCurrentPage(val.page);
        dispatch(changePreference({ specialDealMixRows: val.pageSize }));
      },
      columns: [
        {
          flex: 2,
          field: "name",
          headerName: tolgee.t({
            key: "special_deal_mix.name",
          }),
        },
        {
          flex: 2,
          field: "email",
          headerName: tolgee.t({
            key: "special_deal_mix.email",
          }),
        },
        {
          flex: 2,
          field: "company_name",
          headerName: tolgee.t({
            key: "special_deal_mix.company_name",
          }),
        },
        {
          flex: 0.25,
          field: "buttons",
          headerName: "",
          sortable: false,
          renderCell: renderActionButtonsCell,
        },
      ],
      rows: res?.map((i: any) => {
        return { ...i, id: i?.uuid };
      }),
    };

    setData(tableData);
    setLoading(false);
  }, [currentPage, specialDealMixRows, dispatch, renderActionButtonsCell]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="flex flex-col mx-auto max-w-7xl" data-testid="partners">
      {data ? (
        <div className="box-outerlayout">
          <DataGrid {...data} loading={loading} />
        </div>
      ) : (
        !loading && (
          <div>
            {tolgee.t({
              key: "generic.no_data",
            })}
          </div>
        )
      )}
    </div>
  );
}
