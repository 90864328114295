import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { multilineStyle } from "pages/Client/EmailCases/Pages/styling";
import tolgee from "services/translation";
import BoldTranslation from "components/General/TranslationsFormatting/BoldTranslation";

import type { JSX } from "react";
import type { CcpaDsrType, CcpaForm } from "types/dsr/ccpa";

export type CaseRequest = {
  key: CcpaDsrType;
  question: string;
  value: JSX.Element;
};

export const caseRequests: CaseRequest[] = [
  {
    key: "DSR_CCPA_DELETE",
    question: "The Deletion of my personal information",
    value: (
      <p className="font-normal">
        <BoldTranslation
          translationSource="email_intake"
          translationKey="delete_personal_information"
          boldedParts={["Deletion"]}
        />
      </p>
    ),
  },
  {
    key: "DSR_CCPA_ACCESS",
    question: "Know and Access specific pieces of personal information",
    value: (
      <BoldTranslation
        translationSource="email_intake"
        translationKey="access_specific_personal_information"
        boldedParts={["Know", "Access"]}
      />
    ),
  },
  {
    key: "DSR_CCPA_ACCESS_CATEGORIES",
    question: "Know and Access shared personal information",
    value: (
      <BoldTranslation
        translationSource="email_intake"
        translationKey="access_categories_of_personal_information_sold_shared_disclosed"
        boldedParts={["Know", "Access"]}
      />
    ),
  },
  {
    key: "DSR_CCPA_CORRECT",
    question: "Rectify personal information",
    value: (
      <BoldTranslation
        translationSource="email_intake"
        translationKey="request_rectify_personal_information"
        boldedParts={["Rectify"]}
      />
    ),
  },
  {
    key: "DSR_CCPA_OPTOUT",
    question: "Opt-out of the sale of personal information",
    value: (
      <BoldTranslation
        translationSource="email_intake"
        translationKey="do_not_sell_or_share_personal_information"
        boldedParts={["Sell", "Share"]}
      />
    ),
  },
  {
    key: "DSR_CCPA_LIMIT",
    question: "Limit the use of sensitive personal information",
    value: (
      <BoldTranslation
        translationSource="email_intake"
        translationKey="do_not_sell_or_share_personal_information"
        boldedParts={["Limit", "use"]}
      />
    ),
  },
];

type RequestProps = {
  request: CaseRequest;
  selectedIndex: number;
};

function Request(props: RequestProps) {
  const { request, selectedIndex } = props;
  const { watch, register, setValue, getValues } = useFormContext<CcpaForm>();

  const [open, setOpen] = useState(false);

  const dsrsValue = watch("dsrs");
  const dsrs = useMemo(() => dsrsValue || [], [dsrsValue]);
  const selected = selectedIndex !== -1 ? dsrs[selectedIndex] : null;
  const [correctDsrIndex, setCorrectDsrIndex] = useState<number>();
  const correctionData = watch(`dsrs.${correctDsrIndex}.extra.correct`);

  useEffect(() => {
    const correctionIndex = dsrs.findIndex((dsr) => dsr.type === "DSR_CCPA_CORRECT");
    if (correctionIndex !== -1) {
      setCorrectDsrIndex(correctionIndex);
    }
  }, [dsrs, watch]);

  useEffect(() => {
    if (selectedIndex !== -1) {
      setOpen(true);
    }
  }, [selectedIndex, request.key]);

  const openButtonClicked = () => {
    const requests = [...(getValues("dsrs") || [])];

    if (selectedIndex === -1) {
      requests.push({
        checked: true,
        type: request.key,
        inquiries:
          request.key === "DSR_CCPA_CORRECT" ? [] : [{ question: request.question, answer: "" }],
        tags: ["SUFFICIENT_INFORMATION"],
        extra:
          request.key === "DSR_CCPA_CORRECT"
            ? {
                correct: [
                  {
                    data: "",
                    why: "",
                    new: "",
                  },
                ],
              }
            : {},
      });
    } else {
      requests.splice(selectedIndex, 1);
    }
    setValue("dsrs", requests);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const requests = [...(getValues("dsrs") || [])];

    if (selectedIndex === -1) {
      requests.push({
        checked: true,
        type: request.key,
        inquiries: [{ question: request.question, answer: e.target.value }],
        tags: ["SUFFICIENT_INFORMATION"],
        extra: {},
      });
    } else {
      requests[selectedIndex].inquiries[0].answer = e.target.value;
    }
    setValue("dsrs", requests);
  };

  const addCorrectionData = () => {
    const newCorrectionData = correctionData
      ? [...correctionData, { data: "", why: "", new: "" }]
      : [{ data: "", why: "", new: "" }];
    setValue(`dsrs.${correctDsrIndex}.extra.correct`, newCorrectionData);
  };

  const removeCorrectionData = (index: number) => {
    const newCorrectionData = correctionData.filter((_, i) => i !== index);
    setValue(`dsrs.${correctDsrIndex}.extra.correct`, newCorrectionData);
  };

  return (
    <Accordion
      expanded={open}
      onChange={() => setOpen((prev) => !prev)}
      disableGutters
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        "&::before": {
          height: 0,
        },
      }}>
      <AccordionSummary
        onClick={openButtonClicked}
        sx={{
          // backgroundColor: active ? "#4b637d1a" : "#F7F7F7",
          borderRadius: "12px",
          "& .MuiAccordionSummary-content": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "12px",
          },
        }}
        className="w-full h-12 p-3 ">
        {selected ? (
          <FontAwesomeIcon className="w-5 h-5 text-success-600" icon="check-circle" />
        ) : (
          <FontAwesomeIcon className="w-5 h-5" icon="plus" color="gray" />
        )}
        {request.value}
      </AccordionSummary>
      <AccordionDetails>
        {request.key === "DSR_CCPA_CORRECT" ? (
          <div className="flex flex-col space-y-2">
            <div className="space-y-6">
              {correctionData?.map((data, index) => (
                <div className="flex items-center space-x-2" key={index}>
                  <div className="flex-1 min-w-0 transition-all duration-300 focus-within:flex-[3]">
                    <TextField
                      className="w-full"
                      {...register(`dsrs.${correctDsrIndex}.extra.correct.${index}.data`, {
                        required: selected?.checked,
                      })}
                      label={tolgee.t({
                        key: "ccpa.rights_form.request_related_to_following_personal_information",
                      })}
                      rows={2}
                      multiline
                      required={index === 0}
                    />
                  </div>
                  <div className="flex-1 min-w-0 transition-all duration-300 focus-within:flex-[3]">
                    <TextField
                      className="w-full"
                      {...register(`dsrs.${correctDsrIndex}.extra.correct.${index}.why`, {
                        required: selected?.checked && index === 0,
                      })}
                      label={tolgee.t({
                        key: "ccpa.rights_form.personal_information_is_wrong_or_incomplete_because",
                      })}
                      rows={2}
                      multiline
                      required={index === 0}
                    />
                  </div>
                  <div className="flex-1 min-w-0 transition-all duration-300 focus-within:flex-[3]">
                    <TextField
                      className="w-full"
                      {...register(`dsrs.${correctDsrIndex}.extra.correct.${index}.new`, {
                        required: selected?.checked && index === 0,
                      })}
                      label={tolgee.t({
                        key: "ccpa.rights_form.amend_the_personal_information_as_follows",
                      })}
                      rows={2}
                      multiline
                      required={index === 0}
                    />
                  </div>
                  <IconButton
                    size="medium"
                    className="flex-shrink-0"
                    onClick={() => removeCorrectionData(index)}
                    disabled={index === 0 && correctionData.length === 1}>
                    <FontAwesomeIcon icon="trash" size="xs" />
                  </IconButton>
                </div>
              ))}
            </div>
            <IconButton size="medium" className="self-end" onClick={addCorrectionData}>
              <FontAwesomeIcon icon="plus" size="sm" />
            </IconButton>
          </div>
        ) : (
          <TextField
            className="w-full"
            label={tolgee.t("email_intake.data_subject_reason_for_request")}
            sx={multilineStyle}
            value={selected?.inquiries?.[0].answer || ""}
            onChange={handleTextChange}
            multiline
            rows={3}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
function RequestSelection() {
  const { watch } = useFormContext<CcpaForm>();
  const selected = watch("dsrs") || [];

  return (
    <div className="flex flex-col gap-5 font-normal text-prighterblue">
      {caseRequests.map((request) => (
        <Request
          key={request.key}
          request={request}
          selectedIndex={selected.findIndex((r) => r.type === request.key)}
        />
      ))}
    </div>
  );
}
export default RequestSelection;
