import type { Contact, ContactAddress } from "types/contactDirectory";

export function serializeContactDirectory(contact: Contact): Contact {
  return {
    ...contact,
    addresses: contact.addresses?.map((address: ContactAddress) => ({
      ...address,
      country: null as any,
      // TODO you are serializing Country to country_iso in here. There is no better way for now, look at it in future.
      country_iso: address.country?.iso_3166_1_alpha_2,
    })),
  };
}
