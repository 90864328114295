import DocSigned from "pages/Client/Case/Nis2/Components/DocSigned";
import { useCase } from "pages/Client/Case/CaseContext";
import DocReviewed from "pages/Client/Case/Nis2/Components/DocReviewed";
import DocClientReviewed from "pages/Client/Case/Nis2/Components/DocClientReviewed";

export default function Nis2Loa() {
  const [state] = useCase();
  const checkStep = state.ui.check;

  const renderActiveStep = () => {
    switch (checkStep) {
      case "DOC_SIGNED":
        return <DocSigned />;
      case "DOC_CLIENT_REVIEWED":
        return <DocClientReviewed />;
      case "DOC_REVIEWED":
        return <DocReviewed />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="px-6 pt-4">{renderActiveStep()}</div>
    </>
  );
}
