import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";

import type { StateChangedEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function NewState(props: {
  entityData: StateChangedEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="check" color="white" size="lg" />}
      title={entityData?.title}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="flex flex-col p-4 space-y-2">
        <span>
          <b>
            <T keyName="dsr.new_state" />
          </b>{" "}
          <span className="capitalize">{entityData.new_state}</span>
        </span>
      </div>
    </HistoryEntryTemplate>
  );
}
