import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { T } from "@tolgee/react";

import { BaseTextField } from "components";
import { api } from "services";
import { handleError } from "services/api/error";

import type { Certificate } from "types/business";

const products = ["art27", "ukrep", "swissrep", "turkeyrep"];

type Props = {
  type: string;
};

export default function Certificate({ type }: Props) {
  const { businessId } = useParams();
  const [data, setData] = useState<Certificate>();

  const fetchData = useCallback(async () => {
    let res;
    try {
      res = await api.business.getCertificates(businessId);
    } catch (e) {
      handleError(e);
      return;
    }
    setData(res.data.filter((i) => i.product === type)[0]);
  }, [businessId, type]);

  useEffect(() => {
    fetchData();
  }, [businessId, fetchData]);

  const getHTML = () => {
    navigator?.clipboard?.writeText(data?.html);
  };

  return (
    <div
      className={`${products.includes(type) ? "box-outerlayout" : ""} pb-6 pt-10 mt-6`}
      data-testid={`${type}-certificate`}>
      <div className="md:p-6 w-full">
        <h2>
          <T keyName={`rep.${type}_certificate`} />
        </h2>
        <div className="flex justify-center mb-12">
          <img src={data?.image_url} className="mt-2 w-48" alt={data?.image_alt} />
        </div>
        <div>
          <T keyName={`rep.${type}_you_may_use`} />
        </div>
        <div className="font-bold text-lg mt-8">
          <T keyName="rep.embed_html" />
        </div>
        <div className="mt-4">
          <T keyName="rep.you_may_include" />:
        </div>
        <div className="border rounded-lg border-gray-300 bg-gray-50 p-4 my-4">
          <div className="mb-6">
            <BaseTextField containerClassName="w-full" multiline disabled value={data?.html} />
          </div>
          <div className="flex justify-end mt-4">
            <Button onClick={() => getHTML()} data-testid="copy-btn">
              <FontAwesomeIcon className="mr-2" icon="copy" color="gray" size="lg" />
              <T keyName="generic.copy" />
            </Button>
          </div>
        </div>
        <div className="italic">
          <T keyName="rep.feel_free" />
        </div>
      </div>
    </div>
  );
}
