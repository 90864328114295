import type { BusinessFileEnum } from "types/business";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { CaseHistory } from "types/case/history";
import type { Contact } from "types/contactDirectory";
import type { RawDateTimeString } from "types/date";
import type { CcpaDsrType } from "types/dsr/ccpa";
import type { FadpDsrType } from "types/dsr/fadp";
import type { DSRActingFor, CaseExemptionItem, PublicDSRTypes, ClosedAsEnum } from "types/dsr/main";
import type { Address, FileObject, PaginatedResponse } from "types/general";

// Interfaces
export interface BusinessWithCase extends CaseExtra {
  business_public_id: string;
  uuid: string;
  type: CaseType;
  state: CaseStateEnum;
  rights_concerned: DSRRightEnum[];
  tags: CaseTagNameEnum[];
  ds_name: string;
  ds_email: string;
  ds_salutation?: string;
  country: string;
  deadline: RawDateTimeString;
  deadline_warning: boolean;
  inquiries_closed: number;
  inquiries_total: number;
  created_at: RawDateTimeString;
  updated_at: RawDateTimeString;
  company_name: string;
  ds_country_iso?: string;
  ds_country_flag?: string;
  ds_country_name?: string;
  title: string;
  stage: StageNameEnum;
  from_address?: string;
  from_name?: string;
  received_at?: RawDateTimeString;
  subject?: string;
  sender_name?: string;
  sender_salutation?: string;
}

export interface Translation {
  key: string;
  params: string;
  lang: string;
}

export interface Inquiry {
  answer: string;
  answer_i18n: Translation;
  created_at: RawDateTimeString;
  question: string;
  question_i18n: Translation;
  status: string;
  updated_at: RawDateTimeString;
  uuid: string;
  files: FileObject[];
  upload_required: boolean;
  answered_at: RawDateTimeString | null;
}
export interface Stage {
  name: StageNameEnum;
  uuid: string;
}

export interface CaseStage {
  created_at: RawDateTimeString;
  finished_at: RawDateTimeString;
  updated_at: RawDateTimeString;
  uuid: string;
  stage: Stage;
}

export interface CaseDecision {
  decision: {
    name: CaseDecisionEnum;
  };
}

export interface CaseCheck {
  closed_at: RawDateTimeString;
  uuid: string;
  name: CheckName;
  case_decisions?: CaseDecision[];
  stage_name: StageNameEnum;
}

export interface Schedule {
  expire_at: RawDateTimeString;
  text: string;
  type: ScheduleTypeEnum;
  uuid: string;
}

export interface Tag {
  name: CaseTagNameEnum;
  system: boolean;
}

export interface CaseDetail {
  created_at: RawDateTimeString;
  updated_at?: RawDateTimeString;
  title: string;
  type: CaseType;
  state: CaseStateEnum;
  uuid: string;
  histories: CaseHistory[];
  case_actors: CaseContact[];
  case_stages: CaseStage[];
  case_checks: CaseCheck[];
  business: CaseBusiness;
  email_alias: string;
  inquiries: Inquiry[];
  status?: CaseDetailStatusEnum;
  deadline?: RawDateTimeString;
  detail?: string;
  schedules: Schedule[];
  tags: Tag[];
  files: CaseFile[];
  outcome?: CaseOutcome;
  ds_data: CaseDSData[];
  ds_email_verified?: boolean;
  email_inbox?: CaseEmail;
  rights_concerned: string[];
  extra: CaseExtra;
  ext_url?: string;
  closed_as?: ClosedAsEnum;
  closed_at?: RawDateTimeString;
}

export interface CaseFile extends FileObject {
  type: BusinessFileEnum;
}

export interface CaseExtra {
  acting_for: DSRActingFor;
  ip_address?: string;
  ip_geo_location?: [number, number];
  purchase_number?: string;
  pseudonym?: string;
  username: string;
  email: string;
  customer_number: string;
  other?: string;
  phone?: string;
  address?: Address;
}

export interface CaseOutcome {
  exemptions: CaseExemptionItem[];
  exemption_categories: string[];
  categories_collected: string[];
  categories_shared: [];
  categories_disclosed: [];
  email_subject: string;
  email_content: string;
  email_sent_at: RawDateTimeString;
  incorrect_sources: { contact_id: string; url: string; comment: string }[];
  correct_exemptions: CorrectExemptionsInterface[];
  send_privacy_policy: boolean;
}

export interface CorrectExemptionsInterface {
  data: string;
  why: string;
  new: string;
  result: CorrectExemptionResultEnum;
  exemptions: string[];
}

export interface CaseContact {
  acting_as: ActingAsEnum;
  uuid?: string;
  contact: Contact;
}

export interface CaseReply {
  subject: string;
  text: string;
}

export interface CaseFileRequest {
  files: FileList;
}

export interface Reason {
  reason: {
    key: string;
  };
  comment?: string;
  closed_as: ClosedAsEnum;
}

export interface FinalizeCase {
  subject: string;
  content: string;
  files: FileObject[];
  closed_as?: ClosedAsEnum;
}

export interface ExtendDeadline {
  deadline: string;
  reason: string;
}

export interface InternalComment {
  subject: string;
  text: string;
}

export interface Comment {
  text: string;
  type: CommentTypeEnum;
}

export interface DsrAdditionalFieldsValues {
  label: string;
  selected: boolean;
  value: string;
}

export interface DsrAdditionalFields {
  label: string;
  name: string;
  required: boolean;
  type: string;
  values: DsrAdditionalFieldsValues[];
}

export interface DsrSettings {
  url_portal: string;
  url_dsr_workflow: string;
  dsr_additional_fields: DsrAdditionalFields[];
  dsr_retain_for: number;
}

export interface DsrConfigCaseType {
  required_inputs: Array<CcpaInputFieldsEnum>;
  document_upload: boolean;
}

export interface CaseTagDict {
  [key: string]: CaseTagValue;
}

export interface CaseTagValue {
  icon: IconProp;
  textColor: string;
  borderColor: string;
  backgroundColor: string;
}

export interface CheckData {
  check_name: CheckName;
  decision_name: string;
}

export interface CaseBusiness {
  public_id: string;
  company_name?: string;
  portal_url?: string;
  config?: CaseBusinessConfig;
}

export interface CaseBusinessConfig {
  trade_name?: string;
  clp_color_primary?: string;
  clp_color_secondary?: string;
  clp_text?: string;
  fadp_media_company?: boolean;
  logo_url?: string;
  position?: CaseConfigPositionEnum;
  prighter_in_privacy_policy?: boolean;
  privacy_policy_ext_url?: string;
  size?: CaseConfigSizeEnum;
  slug?: string;
  theme?: CaseConfigThemeEnum;
}

export interface CaseEmail {
  bcc_parsed?: CaseParticipant[];
  cc_parsed?: CaseParticipant[];
  content?: string;
  from_address?: string;
  subject?: string;
  from_name?: string;
  to_parsed?: CaseParticipant[];
}

export interface CaseParticipant {
  name?: string;
  address?: string;
}

export interface CaseDSData {
  uuid?: string;
  name?: string;
  purpose?: string;
  retention?: string;
  automated_decision?: boolean;
  automated_decision_logic?: string;
  recipients?: string;
  additional_information?: string;
}

export interface CaseEmailInterface {
  content: string;
  content_type: string;
  created_at: string;
  from_email: string;
  sent_at: string;
  subject: string;
  to_email: Array<string>;
  uuid: string;
}

// Types
export type CasesResult = PaginatedResponse<BusinessWithCase>;

export type CheckName =
  | PreCheckCheckNameEnum
  | HandlingCheckNameEnum
  | DocumentSignatureCheckNameEnum
  | "PRE_CHECK_REVIEW"
  | "HANDLING_REVIEW";

export type CaseType =
  | "REP_DOC_EU_NIS2_LOA"
  | "PRIGHTER"
  | "AUTHORITY_INBOX"
  | "DSR"
  | "DSR_LEGACY_EU"
  | "DSR_LEGACY_UK"
  | "DSR_EMAIL"
  | CcpaDsrType
  | PublicDSRTypes
  | FadpDsrType;

// Enums
export enum CaseStateEnum {
  NEW = "NEW",
  OPEN = "OPEN",
  REOPENED = "REOPENED",
  CLOSED = "CLOSED",
}

export enum DSRRightEnum {
  Art15 = "Art 15",
  Art16 = "Art 16",
  Art7 = "Art 7",
  Art21 = "Art 21",
  Art20 = "Art 20",
  Art18 = "Art 18",
  Art17 = "Art 17",
  Art22 = "Art 22",
}

export enum CollectionTypeEnum {
  DSR_EU = "DSR_EU",
  DSR_UK = "DSR_UK",
  DSR_FADP = "DSR_FADP",
  DSR_CCPA = "DSR_CCPA",
  AUTHORITY = "AUTHORITY",
}

export enum StageNameEnum {
  VERIFY_EMAIL = "VERIFY_EMAIL",
  CHECK_DATA = "CHECK_DATA",
  PROCESS_REQUEST = "PROCESS_REQUEST",
  PRE_CHECK = "PRE_CHECK",
  PRE_CHECK_REVIEW = "PRE_CHECK_REVIEW",
  HANDLING = "HANDLING",
  HANDLING_REVIEW = "HANDLING_REVIEW",
  DONE = "DONE",
  DOC_SIGNATURE = "DOC_SIGNATURE",
  DOC_CLIENT_REVIEW = "DOC_CLIENT_REVIEW",
  DOC_REVIEW = "DOC_REVIEW",
}

export enum HandlingCheckNameEnum {
  PROCESSORS_ADDED = "PROCESSORS_ADDED",
  CONTROLLER_ADDED = "CONTROLLER_ADDED",
  DELETE_COMPLY = "DELETE_COMPLY",
  LIMIT_COMPLY = "LIMIT_COMPLY",
  EXEMPTIONS = "EXEMPTIONS",
  EXCLUDE_DATA = "EXCLUDE_DATA",
  PRIVACY_POLICY_CATEGORIES = "PRIVACY_POLICY_CATEGORIES",
  ACCESS_CATEGORIES_UPLOAD = "ACCESS_CATEGORIES_UPLOAD",
  ACCESS_CATEGORIES_COMPLY = "ACCESS_CATEGORIES_COMPLY",
  OPTOUT_COMPLY = "OPTOUT_COMPLY",
  CORRECT_DELETE = "CORRECT_DELETE",
  INFORM_THIRD_PARTY = "INFORM_THIRD_PARTY",
  INCORRECT_SOURCES = "INCORRECT_SOURCES",
  ACCESS_COMPLY = "ACCESS_COMPLY",
  DS_DATA_UPLOAD = "DS_DATA_UPLOAD",
}

export enum DocumentSignatureCheckNameEnum {
  DOC_SIGNED = "DOC_SIGNED",
  DOC_CLIENT_REVIEWED = "DOC_CLIENT_REVIEWED",
  DOC_REVIEWED = "DOC_REVIEWED",
}

export enum PreCheckCheckNameEnum {
  DS_LOCATION = "DS_LOCATION",
  DS_IDENTIFICATION = "DS_IDENTIFICATION",
  CONTROLLER_PROCESSOR = "CONTROLLER_PROCESSOR",
}

export enum CaseDecisionEnum {
  CONTROLLER = "CONTROLLER",
  PROCESSOR = "PROCESSOR",
}

export enum ScheduleTypeEnum {
  REMINDER = "REMINDER",
  WARNING = "WARNING",
  CLOSE = "CLOSE",
}

export enum CaseDetailStatusEnum {
  NEW = "NEW",
}

export enum CorrectExemptionResultEnum {
  PENDING = "PENDING",
  CONFIRM = "CONFIRM",
  DENY = "DENY",
}

export enum ActingAsEnum {
  SENDER = "SENDER",
  RECIPIENT = "RECIPIENT",
  DATA_SUBJECT = "DATA_SUBJECT",
  PROCESSOR = "PROCESSOR",
  CONTROLLER = "CONTROLLER",
  THIRD_PARTY = "THIRD_PARTY",
}

export enum DeclineTypeEnum {
  IDENTIFICATION = "IDENTIFICATION",
  RESIDENCY = "RESIDENCY",
  DELETE_NOT_COMPLIED = "DELETE_NOT_COMPLIED",
  CORRECT_DELETED = "CORRECT_DELETED",
  ACCESS_NOT_COMPLIED = "ACCESS_NOT_COMPLIED",
  OPTOUT_NOT_COMPLIED = "OPTOUT_NOT_COMPLIED",
  LIMIT_NOT_COMPLIED = "LIMIT_NOT_COMPLIED",
  FRAUDULENT = "FRAUDULENT",
}

export enum CommentTypeEnum {
  THIRD_PARTY_EXPLANATION = "THIRD_PARTY_EXPLANATION",
  FRAUDULENT_EXPLANATION = "FRAUDULENT_EXPLANATION",
}

export enum CcpaInputFieldsEnum {
  NAME = "name",
  EMAIL = "email",
  ADDRESS = "address",
  PHONE = "phone",
  CUSTOMER_NUMBER = "customer_number",
  PURCHASE_NUMBER = "purchase_number",
  PSEUDONYM = "pseudonym",
  OTHER = "other",
}

export enum CaseTagNameEnum {
  EMAIL_VERIFIED = "EMAIL_VERIFIED",
  IDENTIFICATION_PENDING = "IDENTIFICATION_PENDING",
  PROCESSING = "PROCESSING",
  EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED",
  CHECK_RESPONSIBILITY = "CHECK_RESPONSIBILITY",
  DEADLINE_EXTENDED = "DEADLINE_EXTENDED",
}

export enum CaseConfigPositionEnum {
  TOP_LEFT = "TOP_LEFT",
  TOP_CENTER = "TOP_CENTER",
  TOP_RIGHT = "TOP_RIGHT",
  CENTER_LEFT = "CENTER_LEFT",
  CENTER_RIGHT = "CENTER_RIGHT",
  BOTTOM_LEFT = "BOTTOM_LEFT",
  BOTTOM_CENTER = "BOTTOM_CENTER",
  BOTTOM_RIGHT = "BOTTOM_RIGHT",
}

export enum CaseConfigSizeEnum {
  GROWTH = "GROWTH",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum CaseConfigThemeEnum {
  LIGHT = "LIGHT",
  DARK = "DARK",
}
