import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";

import RequestSelection from "pages/Client/EmailCases/Components/ExpertMultistepForm/EmailCasesFormWhat/RequestSelection";

import type { CcpaForm } from "types/dsr/ccpa";

export default function EmailCasesFormWhat() {
  const {
    formState: { errors },
  } = useFormContext<CcpaForm>();

  return (
    <div
      className={clsx(
        "flex flex-col  rounded-[24px] gap-6 border  p-6",
        errors.dsrs ? " border-[#d32f2f]" : "border-prighterblue"
      )}>
      <div className="flex flex-col">
        <div className="flex  justify-between gap-3">
          <h2 className="m-0 text-black font-fire text-[28px] font-normal">
            <T keyName="generic.what" />
          </h2>
          <FontAwesomeIcon icon="question-circle" className="text-prighterblue" />
        </div>
        <p className="text-sm my-1 text-gray-500">
          <T keyName="email_intake.select_reason_for_data_subject_request" />
        </p>
      </div>
      <RequestSelection />
    </div>
  );
}
