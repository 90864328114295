import { Route, Routes } from "react-router";

import ComplianceDocuments from "pages/Client/Ropa/View";
import SpecialDealMix from "pages/Admin/SpecialDealMix/View";
import LandingPage from "pages/Public/LandingPage/View";
import Partners from "pages/Admin/Partners/View";
import DSROverview from "pages/Public/DSROverview/View";
import DSRCollection from "pages/Public/DSRCollection/View";
import UKRep from "pages/Client/RepPages/UKRep/View";
import DSRSettings from "pages/Client/DSRSettings/View";
import CaseFile from "pages/Client/CaseFile/View";
import CcpaRequestForm from "pages/Public/Dsr/Ccpa/CcpaRequestForm";
import DsrLight from "pages/Public/Dsr/DsrLight";
import LoggedInLayout from "components/Layout";
import DSRBusiness from "pages/Public/Dsr/Business";
import AuthService from "services/auth";
import NotFound from "pages/General/Error/NotFound";
import PublicAuthService from "services/auth/PublicAuthService";
import PayInvoice from "pages/Client/Billing/PayInvoice/PayInvoice";
import MarketingUnsubscribe from "pages/Public/Marketing/MarketingUnsubscribe";
import ContactDirectoryList from "pages/Client/ContactDirectory/ContactDirectoryList";
import Security from "pages/Client/Security/View";
import CompanyData from "pages/Client/Company/View";
import DsrList from "pages/General/Dsr/DsrList";
import Transferwise from "pages/Admin/Transferwise/View";
import BillingInvoices from "pages/Admin/BillingInvoices/View";
import CustomizedAccount from "pages/Admin/CustomizedAccount/View";
import Notifications from "pages/Client/Notifications/View";
import DsrVerifyEmail from "pages/Public/Dsr/DsrVerifyEmail";
import CCPAInquiries from "pages/Client/CCPAInquiries/View";
import Permissions from "pages/Client/Permissions/View";
import ProductRevenue from "pages/Admin/ProductRevenue/View";
import Dashboard from "pages/Client/Dashboard/View";
import PrighterCases from "pages/Client/PrighterCases/View";
import InvalidName from "pages/Admin/DataQuality/InvalidName";
import InvalidAddress from "pages/Admin/DataQuality/InvalidAddress";
import CustomerSupport from "pages/Client/CustomerSupport/View";
import LegalAndCompliance from "pages/Client/LegalAndCompliance/View";
import FadpRequestForm from "pages/Public/Dsr/Fadp/FadpRequestForm";
import NisRepresentation from "pages/Client/RepPages/NIS/View";
import Referrals from "pages/Client/Referrals/View";
import ManualOnboarding from "pages/Admin/ManualOnboarding/View";
import SwissRep from "pages/Client/RepPages/SwissRep/View";
import EURep from "pages/Client/RepPages/EURep/View";
import TurkeyRep from "pages/Client/RepPages/TurkeyRep/View";
import NewPartner from "pages/Admin/NewPartner/View";
import ProtectedElement from "routes/ProtectedElement";
import ManageUsers from "pages/Admin/ManageUsers/View";
import ChurnedClients from "pages/Admin/ChurnedClients/View";
import LOA from "pages/Client/LOA/View";
import Vouchers from "pages/Admin/Vouchers/View";
import ProductInterestCampaign from "pages/Public/Marketing/ProductInterestCampaign";
import EmailCases from "pages/Client/EmailCases/View";
import AuthorityList from "pages/Client/Case/Authority/List";
import CaseView from "pages/Client/Case/View";
import LandingPageSettings from "pages/Client/LandingPage/View";
import Billing from "pages/Client/Billing/View";
import InvoicePaid from "pages/Client/Billing/PayInvoice/InvoicePaid";
import EmailCaseDetails from "pages/Client/EmailCases/Pages/EmailCaseDetails";
import ManageBusiness from "pages/Admin/ManageUser/ManageBusiness/View";
import ManageUser from "pages/Admin/ManageUser/View";
import DsaRepresentation from "pages/Client/RepPages/DSA/View";
import PageTitle from "routes/PageTitle";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PublicAuthService />}>
        <Route
          path="marketing/unsubscribe"
          element={
            <>
              <PageTitle title="MarketingUnsubscribe" />
              <MarketingUnsubscribe />
            </>
          }
        />
        <Route
          path="new-product/ccpa"
          element={
            <>
              <PageTitle title="CCPA Product" />
              <ProductInterestCampaign />
            </>
          }
        />
        <Route
          path="billing/pay/:invoiceId"
          element={
            <>
              <PageTitle title="Pay Invoice" />
              <PayInvoice />
            </>
          }
        />
        <Route
          path="billing/pay/:invoiceId/invoice-paid"
          element={
            <>
              <PageTitle title="Invoice paid" />
              <InvoicePaid />
            </>
          }
        />
        <Route
          path="business/:businessId/case/:caseUuid/inquiries"
          element={
            <>
              <PageTitle title="CCPA inquiries" />
              <CCPAInquiries />
            </>
          }
        />
        <Route
          path="case/:caseUuid/file/:fileUuid"
          element={
            <>
              <PageTitle title="Case file" />
              <CaseFile />
            </>
          }
        />
        <Route
          path="dsr/:publicId"
          element={
            <>
              <PageTitle title="Select Jurisdiction" />
              <DSRBusiness />
            </>
          }
        />
        <Route
          path="dsr/:token/light"
          element={
            <>
              <PageTitle title="Dsr light" />
              <DsrLight />
            </>
          }
        />
        <Route
          path="portal/:publicIdOrSlug"
          element={
            <>
              <PageTitle title="Compliance Center" />
              <LandingPage />
            </>
          }
        />

        <Route path="dsr/:publicId/ccpa">
          <Route
            index
            element={
              <>
                <PageTitle title="CCPA Request" />
                <CcpaRequestForm step="type" />
              </>
            }
          />
          <Route
            path="identification"
            element={
              <>
                <PageTitle title="CCPA Identification" />
                <CcpaRequestForm step="identification" />
              </>
            }
          />
          <Route
            path="send"
            element={
              <>
                <PageTitle title="CCPA Send" />
                <CcpaRequestForm step="send" />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <PageTitle title="Not Found" />
                <NotFound />
              </>
            }
          />
        </Route>

        <Route path="dsr/:publicId/fadp">
          <Route
            index
            element={
              <>
                <PageTitle title="FADP Request" />
                <FadpRequestForm step="type" />
              </>
            }
          />
          <Route
            path="identification"
            element={
              <>
                <PageTitle title="FADP Identification" />
                <FadpRequestForm step="identification" />
              </>
            }
          />
          <Route
            path="send"
            element={
              <>
                <PageTitle title="FADP Send" />
                <FadpRequestForm step="send" />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <PageTitle title="Not Found" />
                <NotFound />
              </>
            }
          />
        </Route>

        <Route
          path="dsr/verify-email/:token"
          element={
            <>
              <PageTitle title="Verify Email" />
              <DsrVerifyEmail />
            </>
          }
        />
        <Route
          path="business/:businessId/case/:caseUuid"
          element={
            <>
              <PageTitle title="Dsr Cases" />
              <DSROverview />
            </>
          }
        />
        <Route
          path="business/:businessId/collection/:collectionId"
          element={
            <>
              <PageTitle title="Dsr Collection" />
              <DSRCollection />
            </>
          }
        />
      </Route>

      <Route
        path="/"
        element={
          <AuthService>
            <LoggedInLayout />
          </AuthService>
        }>
        {/* Common Routes */}
        <Route
          index
          element={
            <>
              <PageTitle title="Dashboard" />
              <Dashboard />
            </>
          }
        />
        <Route
          path="prighter-cases"
          element={
            <>
              <PageTitle title="Prighter Cases" />
              <PrighterCases />
            </>
          }
        />
        <Route
          path="account/security"
          element={
            <>
              <PageTitle title="Account Security" />
              <Security />
            </>
          }
        />
        {/* Admin Routes */}
        <Route path="report">
          <Route
            index
            element={
              <>
                <PageTitle title="Not Found" />
                <NotFound />
              </>
            }
          />
          <Route
            path="product-revenue"
            element={
              <>
                <PageTitle title="Product revenue" />
                <ProtectedElement
                  allowedRoles={["ADMIN", "MANAGER_FINANCIALREPORTING"]}
                  Component={ProductRevenue}
                />
              </>
            }
          />
          <Route
            path="invalid-name"
            element={
              <>
                <PageTitle title="Invalid name" />
                <ProtectedElement
                  allowedRoles={["ADMIN", "DEV", "MANAGER_CUSTOMER"]}
                  Component={InvalidName}
                />
              </>
            }
          />
          <Route
            path="invalid-address"
            element={
              <>
                <PageTitle title="Invalid address" />
                <ProtectedElement
                  allowedRoles={["ADMIN", "DEV", "MANAGER_CUSTOMER"]}
                  Component={InvalidAddress}
                />
              </>
            }
          />
          <Route
            path="special-deal-mix"
            element={
              <>
                <PageTitle title="Special deal mix" />
                <ProtectedElement
                  allowedRoles={["ADMIN", "MANAGER_CUSTOMER", "DEV"]}
                  Component={SpecialDealMix}
                />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <PageTitle title="Not Found" />
                <NotFound />
              </>
            }
          />
        </Route>
        <Route
          path="billing/transferwise"
          element={
            <>
              <PageTitle title="Transferwise" />
              <ProtectedElement allowedRoles={["ADMIN", "MANAGER_FIN"]} Component={Transferwise} />
            </>
          }
        />
        <Route
          path="billing/invoices"
          element={
            <>
              <PageTitle title="Invoices" />
              <ProtectedElement
                allowedRoles={["ADMIN", "MANAGER_FIN"]}
                Component={BillingInvoices}
              />
            </>
          }
        />
        <Route
          path="demo-account"
          element={
            <>
              <PageTitle title="Demo account" />
              <ProtectedElement
                allowedRoles={["MANAGER_CUSTOMER", "ADMIN", "DEV"]}
                Component={CustomizedAccount}
              />
            </>
          }
        />
        <Route
          path="manual-onboarding"
          element={
            <>
              <PageTitle title="Manual onboarding" />
              <ProtectedElement
                allowedRoles={["ADMIN", "MANAGER_CUSTOMER"]}
                Component={ManualOnboarding}
              />
            </>
          }
        />
        <Route
          path="partner/partners"
          element={
            <>
              <PageTitle title="Partners" />
              <ProtectedElement
                allowedRoles={["ADMIN", "MANAGER_CUSTOMER", "DEV"]}
                Component={Partners}
              />
            </>
          }
        />
        <Route
          path="manage-users"
          element={
            <>
              <PageTitle title="Manage users" />
              <ProtectedElement
                allowedRoles={["ADMIN", "MANAGER_CUSTOMER", "DEV"]}
                Component={ManageUsers}
              />
            </>
          }
        />
        <Route
          path="manage/business/:businessId"
          element={
            <>
              <PageTitle title="Manage business" />
              <ProtectedElement
                allowedRoles={["ADMIN", "MANAGER_CUSTOMER", "DEV"]}
                Component={ManageBusiness}
              />
            </>
          }
        />
        <Route
          path="manage/user/:userId"
          element={
            <>
              <PageTitle title="Manage user" />
              <ProtectedElement
                allowedRoles={["ADMIN", "MANAGER_CUSTOMER", "DEV"]}
                Component={ManageUser}
              />
            </>
          }
        />
        <Route
          path="partner/partners/new"
          element={
            <>
              <PageTitle title="New partner" />
              <NewPartner />
            </>
          }
        />
        <Route
          path="client/churned"
          element={
            <>
              <PageTitle title="Churned clients" />
              <ProtectedElement
                allowedRoles={["ADMIN", "MANAGER_CUSTOMER"]}
                Component={ChurnedClients}
              />
            </>
          }
        />
        {/* Client Routes */}
        <Route
          path="customer-support"
          element={
            <>
              <PageTitle title="Customer support" />
              <CustomerSupport />
            </>
          }
        />
        <Route
          path="notifications"
          element={
            <>
              <PageTitle title="Notifications" />
              <Notifications />
            </>
          }
        />
        <Route
          path="loas-provided"
          element={
            <>
              <PageTitle title="LOAs Provided" />
              <LOA />
            </>
          }
        />
        <Route
          path="partners"
          element={
            <>
              <PageTitle title="referrals.referral_program" />
              <Referrals />
            </>
          }
        />
        <Route
          path="legal-and-compliance"
          element={
            <>
              <PageTitle title="Legal and Compliance" />
              <LegalAndCompliance />
            </>
          }
        />
        <Route
          path="dsrs"
          element={
            <>
              <PageTitle title="DSR Inbox" />
              <DsrList />
            </>
          }
        />
        <Route
          path="billing"
          element={
            <>
              <PageTitle title="Billing" />
              <Billing />
            </>
          }
        />
        <Route
          path="billing/manage-vouchers"
          element={
            <>
              <PageTitle title="Manage internal vouchers" />
              <Vouchers />
            </>
          }
        />
        <Route
          path="cases/authority"
          element={
            <>
              <PageTitle title="Authority Cases" />
              <AuthorityList />
            </>
          }
        />
        <Route
          path="case/:caseUuid"
          element={
            <>
              <PageTitle title="Case Details" />
              <CaseView />
            </>
          }
        />
        <Route
          path="contact-directory"
          element={
            <>
              <PageTitle title="Contact Directory" />
              <ContactDirectoryList />
            </>
          }
        />
        <Route
          path="email/cases"
          element={
            <>
              <PageTitle title="Email Cases" />
              <EmailCases />
            </>
          }
        />
        <Route path="email/case">
          <Route
            index
            element={
              <>
                <PageTitle title="Email Cases" />
                <EmailCases />
              </>
            }
          />
          <Route
            path=":id"
            element={
              <>
                <PageTitle title="Email Case Details" />
                <EmailCaseDetails />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <PageTitle title="Not Found" />
                <NotFound />
              </>
            }
          />
        </Route>
        <Route path="business/:businessId">
          <Route
            index
            element={
              <>
                <PageTitle title="Not Found" />
                <NotFound />
              </>
            }
          />
          <Route
            path="profile"
            element={
              <>
                <PageTitle title="Company profile" />
                <CompanyData />
              </>
            }
          />
          <Route
            path="compliance-documents"
            element={
              <>
                <PageTitle title="Records of Processing Activities" />
                <ComplianceDocuments />
              </>
            }
          />
          <Route
            path="permissions"
            element={
              <>
                <PageTitle title="Manage Permissions" />
                <Permissions />
              </>
            }
          />
          <Route
            path="ch-rep"
            element={
              <>
                <PageTitle title="SwissRep" />
                <SwissRep />
              </>
            }
          />
          <Route
            path="eu-rep"
            element={
              <>
                <PageTitle title="GDPR representation" />
                <EURep />
              </>
            }
          />
          <Route
            path="tr-rep"
            element={
              <>
                <PageTitle title="Turkey-DCR" />
                <TurkeyRep />
              </>
            }
          />
          <Route
            path="uk-rep"
            element={
              <>
                <PageTitle title="UK representation" />
                <UKRep />
              </>
            }
          />
          <Route
            path="dsa-rep"
            element={
              <>
                <PageTitle title="DSA Representation" />
                <DsaRepresentation />
              </>
            }
          />
          <Route
            path="nis"
            element={
              <>
                <PageTitle title="NIS representation" />
                <NisRepresentation />
              </>
            }
          />
          <Route
            path="portal"
            element={
              <>
                <PageTitle title="Digital Governance Portal" />
                <LandingPageSettings />
              </>
            }
          />

          <Route
            path="dsrs"
            element={
              <>
                <PageTitle title="DSR Inbox" />
                <DsrList />
              </>
            }
          />
          <Route
            path="dsr/config"
            element={
              <>
                <PageTitle title="DSR Settings" />
                <DSRSettings />
              </>
            }
          />

          <Route
            path="cases/authority"
            element={
              <>
                <PageTitle title="Authority Cases" />
                <AuthorityList />
              </>
            }
          />

          {/* <Route path="ropa">
            <Route index element={<Navigate to="persons" replace />} />

            <Route path="persons" element={<RopaPerson />} />
            <Route path="persons/create" element={<RopaPersonAdd />} />
            <Route path="persons/:entityId" element={<RopaPersonDetail />} />

            <Route path="processes" element={<RopaProcess />} />
            <Route path="processes/create" element={<RopaProcessAdd />} />
            <Route path="processes/:entityId" element={<RopaProcessDetail />} />

            <Route path="data-categories" element={<RopaDataCategory />} />
            <Route path="data-categories/create" element={<RopaDataCategoryAdd />} />
            <Route path="data-categories/:entityId" element={<RopaDataCategoryDetail />} />

            <Route path="affected-groups" element={<RopaAffectedGroup />} />
            <Route path="affected-groups/create" element={<RopaAffectedGroupAdd />} />
            <Route path="affected-groups/:entityId" element={<RopaAffectedGroupDetail />} />

            <Route path="controllers" element={<RopaController />} />
            <Route path="controllers/create" element={<RopaControllerAdd />} />
            <Route path="controllers/:entityId" element={<RopaControllerDetail />} />

            <Route path="internal-recipients" element={<RopaInternalRecipient />} />
            <Route path="internal-recipients/create" element={<RopaInternalRecipientAdd />} />
            <Route path="internal-recipients/:entityId" element={<RopaInternalRecipientDetail />} />

            <Route path="external-recipients" element={<RopaExternalRecipient />} />
            <Route path="external-recipients/create" element={<RopaExternalRecipientAdd />} />
            <Route path="external-recipients/:entityId" element={<RopaExternalRecipientDetail />} />

            <Route path="processor-recipients" element={<RopaProcessorRecipient />} />
            <Route path="processor-recipients/create" element={<RopaProcessorRecipientAdd />} />
            <Route
              path="processor-recipients/:entityId"
              element={<RopaProcessorRecipientDetail />}
            />

            <Route path="*" element={<NotFound />} />
          </Route> */}

          <Route
            path="*"
            element={
              <>
                <PageTitle title="Not Found" />
                <NotFound />
              </>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <>
              <PageTitle title="Not Found" />
              <NotFound />
            </>
          }
        />
      </Route>
    </Routes>
  );
}
