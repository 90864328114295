import { useState, useRef } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";

import type { UseState } from "types";
import type { SplitButtonItems } from "types/components";

type Props = {
  items: SplitButtonItems;
  listHeight?: string;
  disabled?: boolean;
  loading?: boolean;
  buttonPreName?: string;
  state: UseState<number>;
  color?: string;
};

export default function SplitButton({
  items,
  listHeight = "190px",
  state,
  disabled = false,
  loading = false,
  buttonPreName,
  color,
}: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = state;

  const handleClick = () => {
    items[selectedIndex].action();
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    items[index].action();
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
        <Button disabled={disabled} onClick={handleClick} color={color as any}>
          {items[selectedIndex]?.icon && (
            <FontAwesomeIcon
              className="mr-3"
              size="lg"
              icon={items[selectedIndex]?.icon}
              color="white"
            />
          )}
          {buttonPreName}
          {items[selectedIndex]?.name}
        </Button>

        <Button
          color={color as any}
          disabled={disabled}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}>
          {loading ? <CircularProgress size="1.5rem" /> : <FontAwesomeIcon icon="caret-down" />}
        </Button>
      </ButtonGroup>
      <Popper
        color={color as any}
        sx={{
          zIndex: 99,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement.includes("bottom") ? "center top" : "center bottom",
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  style={{
                    maxHeight: listHeight, // Adjust the height as needed
                    overflowY: "auto", // Add vertical scrollbar
                  }}>
                  {items.map((item, index) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}>
                      <div
                        className={`flex items-center ${index !== selectedIndex && "ml-6 pl-1"}`}>
                        {index === selectedIndex && (
                          <FontAwesomeIcon className="mr-3" size="lg" icon="check" color="gray" />
                        )}
                        <div className={`flex flex-col ${item?.details && "mb-2"}`}>
                          <div>{item.name}</div>
                          {item?.details && <div className="opacity-50">{item.details}</div>}
                        </div>
                      </div>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
