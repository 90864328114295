import api from "services/api/base";

import type { ObjectAnyKeysString } from "types";
import type { AxiosResponse } from "axios";
import type {
  CaseExemptionItem,
  ExemptionGroup,
  ExemptionItem,
  DsrCollectionItem,
} from "types/dsr/main";
import type { DsrLight, DSRBusiness, DSRCustomizationSettings, DSRPublic } from "types/dsr";
import type { DsrSettings } from "types/case";

export async function getDsrLight(token: string): Promise<AxiosResponse<DsrLight>> {
  return api.get("/v1/dsr/light", {
    params: { token },
  });
}

export async function getDsrBusiness(
  businessPublicId: string
): Promise<AxiosResponse<DSRBusiness>> {
  return api.get("/v1/dsr/business", {
    params: { business_id: businessPublicId },
  });
}

export async function getSettings(businessId: string): Promise<AxiosResponse<DsrSettings>> {
  return api.get("/v1/dsr/settings", {
    params: { business_id: businessId },
  });
}

export async function setDSRCustomizableFields(
  business_id: string | number,
  replyData: { settings: DSRCustomizationSettings }
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/dsr/customize/fields", replyData, {
    params: { business_id },
  });
}

export async function getCustomizableFieldPreviews(
  business_id: string | number
): Promise<AxiosResponse<ObjectAnyKeysString>> {
  return api.get("/v1/dsr/customize/previews", {
    params: { business_id },
  });
}

export async function verifyDataSubjectEmail(token: string): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/case/verify-email", null, {
    params: { token },
  });
}

export async function getDsrPublic(
  businessId: string | number,
  caseUuid: string
): Promise<AxiosResponse<DSRPublic>> {
  return api.get("/v1/dsr/case", {
    params: { case_uuid: caseUuid, business_id: businessId },
  });
}

export async function getDsrCollection(
  businessId: string | number,
  collectionId: string
): Promise<DsrCollectionItem[]> {
  const res = await api.get("/v1/cases/collection", {
    params: { business_id: businessId, collection_uuid: collectionId },
  });

  return res.data;
}

export async function postCaseAuthorization(
  businessId: string,
  colelctionId: string,
  password: string
): Promise<boolean> {
  return api.post(
    "/v1/security/collection-auth",
    { password },
    {
      params: { business_id: businessId, collection_uuid: colelctionId },
    }
  );
}

export async function getExemptionsList(
  exemptionGroups: ExemptionGroup[]
): Promise<AxiosResponse<ExemptionItem[]>> {
  return api.get("/v1/generic/ui-resources", {
    params: { groups: exemptionGroups },
    paramsSerializer: (params) => {
      const queryParams = new URLSearchParams();
      params.groups.forEach((group: string) => {
        queryParams.append("groups", group);
      });
      return queryParams.toString();
    },
  });
}

export async function putExemptions(
  caseUuid: string,
  exemptions: CaseExemptionItem[]
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/dsr/exemptions", exemptions, {
    params: { case_uuid: caseUuid },
  });
}

export async function putExemptionCategories(
  caseUuid: string,
  exemptionCategories: string[]
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/dsr/exemption-categories", exemptionCategories, {
    params: { case_uuid: caseUuid },
  });
}
