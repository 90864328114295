import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import type { SelectChangeEvent, SxProps, Theme } from "@mui/material";
import type { UseState } from "types";

type Props = {
  state?: UseState<string>;
  defaultValue?: string;
  className?: string;
  label?: string;
  options: object;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
};

export default function BaseSelectField({
  state = ["", () => {}],
  className,
  defaultValue,
  label = "",
  options,
  required = false,
  error = false,
  disabled = false,
  fullWidth = true,
  sx = {},
}: Props) {
  const [value, setValue] = state;

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };

  return (
    <FormControl required={required} fullWidth={fullWidth} error={error}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        disabled={disabled}
        required={required}
        sx={sx}
        className={className}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={handleChange}
        defaultValue={defaultValue}>
        {Object.entries(options).map(([key, val]) => (
          <MenuItem key={key} value={key}>
            {val}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
