import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import moment from "moment";
import { useState } from "react";
import { T } from "@tolgee/react";
import ReactMarkdown from "react-markdown";

import { getFileIcon, getFileName } from "helpers";
import { generateUrl } from "helpers/case";
import EmailAttachment from "pages/Client/EmailCases/Components/Attachment";

import type { CaseDetail } from "types/case";

type Props = {
  currentEmailCase: CaseDetail;
  stepper?: React.ReactNode;
};

export default function EmailCasesSelected(props: Props) {
  const { currentEmailCase, stepper } = props;

  const [showDocuments, setShowDocuments] = useState(false);
  return (
    <div className="h-full 2xl:max-h-[80vh] rounded-3xl border-[1px] border-prighterdark overflow-hidden flex flex-col">
      <div className="py-4 bg-prighterblue ">
        {stepper}
        <div className="flex flex-col justify-between px-8 text-white">
          <div className="flex items-center justify-between">
            <h2 className="font-normal font-fire">
              <T keyName="generic.email_request" />: {currentEmailCase?.uuid}
            </h2>
            <button
              type="button"
              disabled={!currentEmailCase?.files?.length}
              onClick={() => setShowDocuments((show) => !show)}
              className={clsx(
                "flex items-center gap-3 rounded-[12px] text-prighterdark bg-white h-fit py-2 px-3",
                !currentEmailCase.files?.length && "opacity-50"
              )}>
              <div className="flex items-center justify-center w-4 h-4">
                <FontAwesomeIcon icon="paperclip" color="gray" />
              </div>
              <h4 className="m-0 text-base font-semibold font-inter">
                {showDocuments ? "Hide " : "Show "}
                <T keyName="generic.documents" /> ({currentEmailCase?.files?.length})
              </h4>
            </button>
          </div>
          <h5 className="font-normal font-tt">
            <T keyName="generic.received_on" />:
            <span className="font-bold">
              {moment(currentEmailCase?.created_at).format("MMMM Do YYYY, h:mm:ssA")} (
              {moment(currentEmailCase?.created_at).fromNow()})
            </span>
          </h5>
          {showDocuments && (
            <div className="flex mt-4  mb-2 space-x-10 p-6 border border-[#CBCCCC] rounded-[12px]">
              {currentEmailCase?.files?.map((file) => (
                <EmailAttachment
                  key={file.uuid}
                  icon={getFileIcon(file.mime_type)}
                  url={generateUrl(currentEmailCase?.uuid, file.uuid)}
                  fileName={getFileName(file)}
                  file={file}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-8 p-8 overflow-auto ">
        <div className="flex flex-col gap-2 text-prighterdark">
          <h1 className="m-0 font-normal font-fire">
            <T keyName="dsr.from" />: {currentEmailCase?.email_inbox?.from_address}
          </h1>
          {/* <h2 className="m-0 font-medium font-tt">Subject: {currentEmailCase?.email?.content}</h2>
           <h2 className="m-0 font-medium font-tt">
            To: {currentEmailCase?.email?.bcc_parsed.map((c) => c.address)}
          </h2>
          <h2 className="m-0 font-medium font-tt">
            cc: {currentEmailCase?.email?.cc_parsed.map((c) => c.address)}
          </h2> */}
          <h2 className="m-0 font-medium font-tt">
            <T keyName="cases.subject" />: {currentEmailCase?.email_inbox?.subject || "No subject"}
          </h2>
          <h3 className="m-0 font-medium font-tt">
            <T keyName="dsr.to" />:
            {currentEmailCase?.email_inbox?.to_parsed?.map((c) => c.address).join(", ") ||
              "No recipient"}
          </h3>
          <h3 className="m-0 font-medium font-tt">
            <T keyName="dsr.cc" />:{" "}
            {currentEmailCase?.email_inbox?.cc_parsed?.map((c) => c.address).join(", ") || "No cc"}
          </h3>
        </div>
        <div>
          <ReactMarkdown>{currentEmailCase?.email_inbox?.content}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
}
