import { Chip, Skeleton, Stack, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getDSRRequestFlag, getJurisdictionByType } from "helpers/case";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";

export default function Tags() {
  const [state] = useCase();
  const { type, tags = [] } = state.case;

  const title = tolgee.t({
    key: `dsr.type.${type?.toLowerCase()}`,
  });

  return (
    <Stack direction="row" spacing={1} className="mb-6">
      <Chip
        label={!state.initialLoading ? getJurisdictionByType(type) : <Skeleton width={80} />}
        icon={
          !state.initialLoading ? (
            <img
              className="pl-[2px]"
              alt={`${getJurisdictionByType(type)} Prighter Logo`}
              width={18}
              height={18}
              src={getDSRRequestFlag(type)}
            />
          ) : (
            <Skeleton width={18} height={18} />
          )
        }
      />
      <div className="hidden @2xl:block">
        <Chip
          sx={{ cursor: "pointer" }}
          label={!state.initialLoading ? title : <Skeleton width={80} />}
          icon={<FontAwesomeIcon icon="circle-info" size="lg" />}
        />
      </div>
      <div className="flex items-center @2xl:hidden">
        <Tooltip title={title} placement="top-start">
          <FontAwesomeIcon className="text-gray-600" icon="circle-info" size="xl" />
        </Tooltip>
      </div>
      {tags.map((i: any) => (
        <Chip
          key={i.name}
          label={tolgee.t({
            key: `cases.tag.${i.name.toLowerCase()}`,
          })}
          variant="outlined"
        />
      ))}
    </Stack>
  );
}
