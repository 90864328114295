import { Box } from "@mui/material";
import { Link } from "react-router";

import { Chip } from "components";

import type { CaseStats, ManagedBusiness } from "types/user";

type Props = { business: ManagedBusiness; caseStats: CaseStats };

export default function BusinessRow({ business, caseStats }: Props) {
  return (
    <Box
      component={Link}
      to={`/manage/business/${business.public_id}`}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        cursor: "pointer",
        p: 0,
        textDecoration: "none",
      }}>
      <div className="flex flex-col">
        <div className="flex items-center space-x-2 p-0">
          <span>
            <b>{business.company_name}</b>
          </span>

          <Chip title={business.public_id} backgroundColor="bg-gray-500" className="py-0 h-6" />
          <Chip title={business.config.size} backgroundColor="bg-gray-500" className="py-0 h-6" />
        </div>

        <div>
          <Chip
            title={`${caseStats.AUTHORITY.active + caseStats.DSR.active + caseStats.EMAIL.active} Open Cases`}
            backgroundColor="bg-gray-500"
            className="py-0 h-6"
          />
        </div>
      </div>
    </Box>
  );
}
