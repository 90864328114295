import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, MenuItem, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { T } from "@tolgee/react";
import { useForm } from "react-hook-form";

import { BaseTextField } from "components";
import { api } from "services";
import tolgee from "services/translation";
import { allLang } from "const";
import { handleError } from "services/api/error";

import type { Feature } from "types/company";

const products = ["art27", "ukrep", "swissrep", "turkeyrep"];

const allLangRecords = allLang.map((i) => ({
  language: i,
}));

type Props = {
  type: string;
};

export default function Snippet({ type }: Props) {
  const { businessId } = useParams();
  const [data, setData] = useState<Feature[]>([]);
  const [url, setUrl] = useState<string>();

  const { setValue, watch } = useForm();

  const selectedLanguage = watch("language");
  const language = selectedLanguage || "en";

  const fetchData = useCallback(async () => {
    let res;
    try {
      res = await api.business.get(businessId);
    } catch (e) {
      handleError(e);
      return;
    }
    setData(res.data.features.filter((i) => products.includes(i)));
    setUrl(res.data.portal_url);
  }, [businessId]);

  useEffect(() => {
    tolgee.loadRecords(allLangRecords);
  }, []);

  useEffect(() => {
    fetchData();
  }, [businessId, fetchData]);

  const html = `<h2>${tolgee.t({
    key: "all_lang.rep_policy_snippet_title",
    language,
  })}</h2>

${tolgee.t({
  key: "all_lang.rep_policy_snippet_intro",
  language,
})}
<ul>
  ${data
    ?.map(
      (i) => `<li>
      ${tolgee.t({
        key: `all_lang.${i}`,
        language,
      })}
  </li>`
    )
    .join("")}
</ul>

${tolgee.t({
  key: "all_lang.rep_policy_snippet_cta",
  language,
})} <a href=${url}>${url}</a>`;

  const copyHTML = () => {
    navigator?.clipboard?.writeText(html);
  };

  return (
    <div className="box-outerlayout pt-10 mt-6" id="policysnippets" data-testid={`${type}-snippet`}>
      <div className="md:p-6 w-full">
        <h2>
          <T keyName="rep.snippets_to_include" />
        </h2>
        <div>
          <T keyName={`rep.${type}_snippet`} />
        </div>
        <div className="font-bold mt-8">
          <T keyName="nis.select_lang" />:
        </div>
        <TextField
          select
          id="select"
          className="w-full md:w-100 mt-4"
          defaultValue="en"
          value={selectedLanguage}
          onChange={(event: any) => {
            setValue(`language`, event.target?.value);
          }}>
          {allLang.map((i) => (
            <MenuItem key={i} value={i}>
              {i.toUpperCase()}
            </MenuItem>
          ))}
        </TextField>
        <div className="font-bold mt-8">
          <T keyName="nis.text_snippet" />:
        </div>
        <div className="border rounded-lg border-gray-300 bg-gray-50 p-4 my-4 !w-full">
          <h2>
            {tolgee.t({
              key: "all_lang.rep_policy_snippet_title",
              language,
            })}
          </h2>
          <div>
            {tolgee.t({
              key: "all_lang.rep_policy_snippet_intro",
              language,
            })}
          </div>
          <ul>
            {data?.map((i) => (
              <li className="list-disc" key={i}>
                {tolgee.t({
                  key: `all_lang.${i}`,
                  language,
                })}
              </li>
            ))}
          </ul>
          <div>
            {tolgee.t({
              key: "all_lang.rep_policy_snippet_cta",
              language,
            })}

            <a className="ml-2" href={url} rel="noreferrer">
              {url}
            </a>
          </div>
        </div>
        <div className="font-bold mt-8">
          <T keyName="nis.html_snippet" />:
        </div>
        <div className="border rounded-lg border-gray-300 bg-gray-50 p-4 my-4 !w-full">
          <div className="mb-6">
            <BaseTextField containerClassName="w-full" multiline disabled value={html} />
          </div>
          <div className="flex justify-end mt-4">
            <Button onClick={() => copyHTML()}>
              <FontAwesomeIcon className="mr-2" icon="copy" color="gray" size="lg" />
              <T keyName="nis.copy" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
