import { useCallback, useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";
import { useSearchParams } from "react-router";

import { useDebounce } from "helpers/hooks";
import { BaseButton, BaseIconButton, SearchField } from "components";
import { api } from "services";
import tolgee from "services/translation/tolgee";
import { stringifyAddress } from "helpers/general";
import { dataGridConfig } from "helpers/dataGridConfig";
import ContactModal from "pages/Client/ContactDirectory/Components/ContactModal";
import { handleError } from "services/api/error";
import { useAppDispatch, useAppSelector } from "store";
import { changePreference } from "store/app";
import { updateUrlParams } from "helpers/url";

import type { PaginationRequest } from "types/general";
import type { GridRowParams } from "@mui/x-data-grid";
import type { ContactAddress } from "types/contactDirectory";

function getAddressesString(addresses: ContactAddress[]): string {
  switch (addresses.length) {
    case 0:
      return `(${tolgee.t({
        key: "generic.none",
      })})`;
    case 1:
      return stringifyAddress(addresses[0]);
    default:
      return `${stringifyAddress(addresses[0])} (+${addresses.length - 1} ${tolgee.t({
        key: "generic.more",
      })})`;
  }
}

export default function ContactDirectoryList() {
  const { contactDirectoryRows = 25 } = useAppSelector((state) => state.app.preferences);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 0);
  const [orderedBy, setOrderedBy] = useState(searchParams.get("orderBy") || "created_at");
  const [newOrder, setNewOrder] = useState(searchParams.get("order") || "desc");
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();

  const [contactUuid, setContactUuid] = useState<string>(null);
  const [contactModal, setContactModal] = useState<boolean>(false);

  const handleUrlUpdate = useCallback(
    (params: Record<string, string>) => {
      updateUrlParams(params, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const fetchData = useCallback(
    async (props: PaginationRequest) => {
      setLoading(true);
      const { order_by, page, per_page, order, search_term } = props;
      const desc = order === "desc";
      let res;
      try {
        res = await api.contactDirectory.getContacts({
          order_by,
          page: page + 1,
          per_page,
          desc,
          search_term,
        });
      } catch (e) {
        handleError(e);
        setLoading(false);
        return;
      }
      res = res.data;

      const tableData = {
        ...dataGridConfig({ currentPage, rowsPerPage: contactDirectoryRows, count: res.count }),
        onSortModelChange: (sortVal: any) => {
          if (sortVal?.length === 0) {
            return;
          }
          const { field } = sortVal[0];
          const { sort } = sortVal[0];
          setOrderedBy(field);
          setNewOrder(sort);
        },
        onPaginationModelChange: (val: any) => {
          setCurrentPage(val.page);
          dispatch(changePreference({ contactDirectoryRows: val.pageSize }));
        },
        columns: [
          {
            headerName: tolgee.t({
              key: "ops.name",
            }),
            flex: 1,
            field: "label",
            valueGetter: (_: any, params: any) => {
              return params.label;
            },
          },
          {
            headerName: tolgee.t({
              key: "ops.addresses",
            }),
            flex: 1,
            field: "addresses",
            sortable: false,
            valueGetter: (_: any, params: any) => {
              return getAddressesString(params.addresses);
            },
          },
          {
            headerName: tolgee.t({
              key: "ops.contact_points",
            }),
            flex: 1,
            field: "emails",
            sortable: false,
            valueGetter: (_: any, params: any) => {
              const contacts = params.emails.concat(params.phones);
              return `${
                contacts.length > 0
                  ? contacts.join(", ")
                  : `(${tolgee.t({
                      key: "generic.none",
                    })})`
              }`;
            },
          },
        ],
        rows: res?.result?.map((i) => {
          return { ...i, id: i?.uuid };
        }),
        onRowClick: (params: GridRowParams) => {
          setContactUuid(params.row.uuid);
          setContactModal(true);
        },
      };

      setData(tableData);
      setLoading(false);
    },
    [currentPage, contactDirectoryRows, dispatch]
  );

  const debouncedSearchValue = useDebounce<string>(search, 500);

  useEffect(() => {
    fetchData({
      order_by: orderedBy,
      page: currentPage,
      per_page: contactDirectoryRows,
      order: newOrder,
      search_term: debouncedSearchValue,
    });
  }, [newOrder, orderedBy, debouncedSearchValue, fetchData, currentPage, contactDirectoryRows]);

  useEffect(() => {
    if (debouncedSearchValue !== searchParams.get("search")) {
      handleUrlUpdate({ search: debouncedSearchValue });
    }
  }, [debouncedSearchValue, handleUrlUpdate, searchParams]);

  const handleCloseModal = (modalState: boolean) => {
    fetchData({
      order_by: orderedBy,
      page: currentPage,
      per_page: contactDirectoryRows,
      order: newOrder,
      search_term: debouncedSearchValue,
    });
    setContactModal(modalState);
  };

  return (
    <div className="box-outerlayout mx-auto max-w-7xl">
      <div className="flex flex-col">
        <div className="flex space-x-2 items-center">
          <BaseIconButton
            className="border-full"
            onClick={() =>
              fetchData({
                order_by: orderedBy,
                page: currentPage,
                per_page: contactDirectoryRows,
                order: newOrder,
                search_term: debouncedSearchValue,
              })
            }>
            <div className="w-4 h-4 flex items-center justify-center">
              <FontAwesomeIcon icon="refresh" color="gray" />
            </div>
          </BaseIconButton>
          <h1>
            <T keyName="ops.contact_directory" />
          </h1>
        </div>

        {data ? (
          <>
            <SearchField className="w-full" searchState={[search, setSearch]} />
            <DataGrid
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
              }}
              {...data}
              loading={loading}
            />
          </>
        ) : (
          !loading && (
            <div>
              {tolgee.t({
                key: "billing.no_data",
              })}
            </div>
          )
        )}

        <div className="self-end mt-2">
          <BaseButton
            onClick={() => {
              setContactUuid(null);
              setContactModal(true);
            }}>
            <T keyName="ops.create_contact" />
          </BaseButton>
        </div>

        <ContactModal contactUuid={contactUuid} modalState={[contactModal, handleCloseModal]} />
      </div>
    </div>
  );
}
