import { TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";

import { BaseSelectField } from "components";
import RightCheckbox from "pages/Public/Dsr/Fadp/Step1/RightCheckbox";
import tolgee from "services/translation";

import type { FadpForm } from "types/dsr/fadp";

export default function RightsForm() {
  const { register, watch, setValue } = useFormContext<FadpForm>();
  const values = watch();

  return (
    <div>
      <h2>
        <T keyName="fadp.rights_form.i_would_like_to" />
      </h2>
      <hr className="h-px text-gray-500 bg-gray-500 border-0 mb-2" />
      <RightCheckbox
        title={tolgee.t({
          key: "fadp.rights_form.deletion_request_title",
        })}
        register={register("dsrs.0.checked")}
        checked={values.dsrs[0].checked}
        onCheck={() => setValue("dsrs.0.checked", !values.dsrs[0].checked)}>
        <TextField
          {...register("dsrs.0.inquiries.0.answer")}
          className="w-full"
          label={tolgee.t({
            key: "fadp.rights_form.request_refers_to_following_personal_info",
          })}
          rows={4}
          multiline
          InputLabelProps={{
            shrink: true,
          }}
        />
      </RightCheckbox>
      <hr className="h-px text-gray-500 bg-gray-500 border-0 my-1" />
      <RightCheckbox
        title={tolgee.t({
          key: "fadp.rights_form.access_request_title",
        })}
        register={register("dsrs.1.checked")}
        checked={values.dsrs[1].checked}
        onCheck={() => setValue("dsrs.1.checked", !values.dsrs[1].checked)}>
        <BaseSelectField
          state={[
            values.dsrs[1].inquiries[0]?.answer_i18n?.key,
            (val: string) => {
              setValue("dsrs.1.inquiries.0.answer_i18n.key", val);
            },
          ]}
          options={{
            "rights_form.categories_of_personal_information": tolgee.t({
              key: "fadp.rights_form.categories_of_personal_information",
            }),
            "rights_form.specific_pieces_of_personal_information": tolgee.t({
              key: "fadp.rights_form.specific_pieces_of_personal_information",
            }),
            "rights_form.categories_of_personal_information_sold_shared_or_disclosed": tolgee.t({
              key: "fadp.rights_form.categories_of_personal_information_sold_shared_or_disclosed",
            }),
          }}
        />

        {values.dsrs[1].inquiries[0].answer_i18n?.key ===
          "rights_form.specific_pieces_of_personal_information" && (
          <div className="mt-4">
            <TextField
              {...register("dsrs.1.inquiries.1.answer")}
              className="w-full"
              label={tolgee.t({
                key: "fadp.rights_form.my_request_concerns_following_information",
              })}
              rows={4}
              multiline
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        )}
      </RightCheckbox>
      <hr className="h-px text-gray-500 bg-gray-500 border-0 my-1" />
      <RightCheckbox
        title={tolgee.t({
          key: "fadp.rights_form.change_request_title",
        })}
        register={register("dsrs.2.checked")}
        checked={values.dsrs[2].checked}
        onCheck={() => setValue("dsrs.2.checked", !values.dsrs[2].checked)}>
        <div className="mt-4 space-y-6">
          <TextField
            {...register("dsrs.2.inquiries.0.answer")}
            className="w-full"
            label={tolgee.t({
              key: "fadp.rights_form.request_related_to_following_personal_information",
            })}
            rows={4}
            multiline
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            {...register("dsrs.2.inquiries.1.answer")}
            className="w-full"
            label={tolgee.t({
              key: "fadp.rights_form.personal_information_is_wrong_or_incomplete_because",
            })}
            rows={4}
            multiline
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            {...register("dsrs.2.inquiries.2.answer")}
            className="w-full"
            label={tolgee.t({
              key: "fadp.rights_form.amend_the_personal_information_as_follows",
            })}
            rows={4}
            multiline
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </RightCheckbox>
      <hr className="h-px text-gray-500 bg-gray-500 border-0 my-1" />
      <RightCheckbox
        title={tolgee.t({
          key: "fadp.rights_form.automated_decision",
        })}
        register={register("dsrs.3.checked")}
        checked={values.dsrs[3].checked}
        onCheck={() => setValue("dsrs.3.checked", !values.dsrs[3].checked)}
      />
      <hr className="h-px text-gray-500 bg-gray-500 border-0 my-1" />
      <RightCheckbox
        title={tolgee.t({
          key: "fadp.rights_form.prohibit_data_processing",
        })}
        register={register("dsrs.4.checked")}
        checked={values.dsrs[4].checked}
        onCheck={() => setValue("dsrs.4.checked", !values.dsrs[4].checked)}
      />
      <hr className="h-px text-gray-500 bg-gray-500 border-0 my-1" />
    </div>
  );
}
