import { Button } from "@mui/material";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useState } from "react";

import tolgee from "services/translation";
import { api } from "services";
import AddNotification from "pages/Client/Notifications/Components/AddNotification";
import NotificationsList from "pages/Client/Notifications/Components/NotificationsList";
import { useAppDispatch } from "store";
import { notify } from "store/app";
import { handleError } from "services/api/error";

import type { Notification } from "types/notifications";
import type { User } from "types/user";

export default function Notifications() {
  const [notifications, setNotifications] = useState<Notification[]>();
  const [user, setUser] = useState<User>();
  const dispatch = useAppDispatch();

  const getNotifications = useCallback(async () => {
    let res;
    try {
      res = await api.notifications.getNotifications(user?.uuid);
    } catch (e) {
      handleError(e);
      return;
    }
    setNotifications(res.data);
  }, [user?.uuid]);

  useEffect(() => {
    async function getUser() {
      let res;
      try {
        res = await api.general.ping();
      } catch (e) {
        handleError(e);
        return;
      }

      setUser(res.data.user);
    }
    getUser();
    getNotifications();
  }, [getNotifications]);

  const handleResendLink = async () => {
    const { uuid } = notifications.find((i) => i.address === user.email);
    try {
      await api.notifications.resendNotification(uuid);
    } catch (e) {
      handleError(e);
      return;
    }
    dispatch(
      notify({
        message: tolgee.t({
          key: "notifications.resend_link_success",
        }),
        type: "SUCCESS",
      })
    );
    getNotifications();
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <div className="box-outerlayout mb-4">
        <h2>
          <T keyName="notifications.account_email" />:
        </h2>
        <div className="flex justify-between items-center">
          <div>{user?.email}</div>
          <div>
            <Button onClick={() => handleResendLink()}>
              <T keyName="notifications.resend_email" />
            </Button>
          </div>
        </div>
      </div>
      <div className="box-outerlayout mb-4">
        <h2>
          <T keyName="notifications.notifications" />:
        </h2>
        <div>
          <div>
            <T keyName="notifications.you_can_sprecify" />
          </div>
          <h3>
            <T keyName="notifications.channels" />
          </h3>
          <div>
            <div className="mb-4">
              <div className="font-bold">
                <T keyName="notifications.all" />:
              </div>
              <div>
                <T keyName="notifications.descr_all" />
              </div>
            </div>
            <div className="mb-4">
              <div className="font-bold">
                <T keyName="notifications.all_essential" />:
              </div>
              <div>
                <T keyName="notifications.descr_essential" />
              </div>
            </div>
            <div className="mb-4">
              <div className="font-bold">
                <T keyName="notifications.compliance_related" />:
              </div>
              <div>
                <T keyName="notifications.descr_compliance" />
              </div>
            </div>
            <div className="mb-4">
              <div className="font-bold">
                <T keyName="notifications.billing" />:
              </div>
              <div>
                <T keyName="notifications.descr_billing" />
              </div>
            </div>
            <div className="mb-4">
              <div className="font-bold">
                <T keyName="notifications.admin" />:
              </div>
              <div>
                <T keyName="notifications.descr_admin" />
              </div>
            </div>
            <div className="mb-4">
              <div className="font-bold">
                <T keyName="notifications.privacy_knowledge" />:
              </div>
              <div>
                <T keyName="notifications.descr_privacy" />
              </div>
            </div>
            <div className="mb-4">
              <div className="font-bold">
                <T keyName="notifications.dsr_details" />:
              </div>
              <div>
                <T keyName="notifications.descr_dsr_details" />
              </div>
            </div>
          </div>
        </div>
        <NotificationsList notifications={notifications} fetchNotifications={getNotifications} />
        <AddNotification userUuid={user?.uuid} handleReload={getNotifications} />
      </div>
      <div className="box-outerlayout mb-4">
        <h2>
          <T keyName="notifications.general_info" />:
        </h2>
        <div>
          <T keyName="notifications.note" />
        </div>
      </div>
    </div>
  );
}
