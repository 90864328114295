// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container textarea {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: transparent;
}

.input-container textarea:focus {
  border-color: rgb(var(--color-prighterblue));
  box-shadow: 0 0 3px 1px rgb(var(--color-prighterblue));
}

.input-container .filled {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform: translate(0, -5px) scale(0.8);
}

.input-container:focus-within label {
  transform: translate(0, -5px) scale(0.8);
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: white;
  color: rgb(var(--color-prighterblue));
}

.input-container label {
  transform: translate(4px, 22px) scale(1);
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}
`, "",{"version":3,"sources":["webpack://./src/components/Input/TextField/textFieldStyles.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,6BAA6B;AAC/B;;AAEA;EACE,4CAA4C;EAC5C,sDAAsD;AACxD;;AAEA;EACE,gDAAgD;EAChD,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;EACxC,gDAAgD;EAChD,uBAAuB;EACvB,qCAAqC;AACvC;;AAEA;EACE,wCAAwC;EACxC,gDAAgD;AAClD","sourcesContent":[".input-container textarea {\n  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;\n  background-color: transparent;\n}\n\n.input-container textarea:focus {\n  border-color: rgb(var(--color-prighterblue));\n  box-shadow: 0 0 3px 1px rgb(var(--color-prighterblue));\n}\n\n.input-container .filled {\n  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;\n  transform: translate(0, -5px) scale(0.8);\n}\n\n.input-container:focus-within label {\n  transform: translate(0, -5px) scale(0.8);\n  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;\n  background-color: white;\n  color: rgb(var(--color-prighterblue));\n}\n\n.input-container label {\n  transform: translate(4px, 22px) scale(1);\n  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
