import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type { FileObject } from "types/general";
import type { CertificateText } from "types/business";
import type { BusinessConfig, ClpSettings } from "types/clp";

export async function getSettings(businessIdOrSlug: string): Promise<AxiosResponse<ClpSettings>> {
  return api.get("/v1/config/dgp", {
    params: { business_id_or_slug: businessIdOrSlug },
  });
}

export async function getConfig(businessId: string): Promise<AxiosResponse<BusinessConfig>> {
  return api.get("/v1/config", {
    params: { business_id: businessId },
  });
}

export async function updateBusinessConfig(
  businessId: string,
  settings: BusinessConfig
): Promise<unknown> {
  return api.put("/v1/config", settings, {
    params: { business_id: businessId },
  });
}

export async function addCertificateImage(
  businessId: string,
  imageCertificate: FileObject
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/config/image-certificate", imageCertificate, {
    params: { business_id: businessId },
  });
}

export async function deleteCertificateImage(
  businessId: string,
  fileName: string
): Promise<AxiosResponse<unknown>> {
  return api.delete("/v1/config/image-certificate", {
    params: { business_id: businessId, cdn_path: fileName },
  });
}

export async function getCertificateTexts(
  businessId: string
): Promise<AxiosResponse<CertificateText[]>> {
  return api.get("/v1/config/text-certificates", {
    params: { business_id: businessId },
  });
}

export async function addCertificateText(
  businessId: string,
  certificate: CertificateText
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/config/text-certificate", certificate, {
    params: { business_id: businessId },
  });
}

export async function deleteCertificateText(
  businessId: string,
  certificateId: string
): Promise<AxiosResponse<unknown>> {
  return api.delete("/v1/config/text-certificate", {
    params: { business_id: businessId, certificate_uuid: certificateId },
  });
}

export function visitPortal(publicIdOrSlug: string): void {
  api.post("/v1/config/dgp/visit", null, {
    params: { business_id_or_slug: publicIdOrSlug },
  });
}
