import api from "services/api/base";

import type { DSRCcpaCase } from "types/dsr/main";
import type { CaseType, DeclineTypeEnum, DsrConfigCaseType } from "types/case";
import type { AxiosResponse } from "axios";
import type { CcpaForm, CcpaPreviewIn, IncorrectSource } from "types/dsr/ccpa";
import type { CCPASettings } from "types/ccpaSettings";

export async function createDsrCcpaCase(
  publicId: string,
  ccpaForm: CcpaForm
): Promise<AxiosResponse<DSRCcpaCase>> {
  return api.post("/v1/case/dsr", ccpaForm, {
    params: { business_id: publicId },
  });
}

export async function getDsrConfigForCaseType(
  businessId: string,
  caseTypes: CaseType[]
): Promise<AxiosResponse<DsrConfigCaseType>> {
  return api.get("/v1/dsr/config-for-case-type", {
    params: {
      case_types: caseTypes,
      business_id: businessId,
    },
    paramsSerializer: (params) => {
      const searchParams = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => searchParams.append(key, v));
        } else {
          searchParams.append(key, value);
        }
      });
      return searchParams.toString();
    },
  });
}

export async function updateRetentionPeriod(
  businessId: string,
  newDays: number
): Promise<AxiosResponse<unknown>> {
  return api.put(
    "/v1/dsr/retention",
    { days: newDays },
    {
      params: { business_id: businessId },
    }
  );
}

export async function getCcpaPreview(caseUuid: string): Promise<AxiosResponse<CcpaPreviewIn>> {
  return api.get("/v1/case/finalize-preview", {
    params: { case_uuid: caseUuid },
  });
}

export async function getDeclinePreview(
  caseUuid: string,
  declineType: DeclineTypeEnum
): Promise<AxiosResponse<CcpaPreviewIn>> {
  return api.get("/v1/case/decline-preview", {
    params: { case_uuid: caseUuid, decline_type: declineType },
  });
}

export async function updateIncorrectSources(
  caseUuid: string,
  incorrectSources: IncorrectSource[]
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/dsr/incorrect-sources", incorrectSources, {
    params: { case_uuid: caseUuid },
  });
}

export async function getSettings(businessId: string): Promise<AxiosResponse<CCPASettings>> {
  return api.get("/v1/config/ccpa", {
    params: { business_id: businessId },
  });
}

export async function updateSettings(
  businessId: string,
  ccpaFormSettings: CCPASettings
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/config/ccpa", ccpaFormSettings, {
    params: { business_id: businessId },
  });
}

export async function updateDsrCaseCategories(
  caseId: string,
  body: CCPASettings
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/dsr/categories", body, {
    params: { case_uuid: caseId },
  });
}
