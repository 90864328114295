import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

import WhoForm from "pages/Client/EmailCases/Components/ExpertMultistepForm/EmailCasesFormWho/WhoForm";

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

export default function EmailCasesFormWho(props: Props) {
  const { setStep } = props;
  return (
    <div className="flex flex-col  rounded-[24px] gap-3 border border-prighterblue p-6 ">
      <div className="flex flex-col">
        <div className="flex  justify-between gap-3">
          <h2 className="m-0 text-black font-fire text-[28px] font-normal">
            <T keyName="generic.who" />
          </h2>
          <FontAwesomeIcon icon="question-circle" className="text-prighterblue" />
        </div>
        <p className="text-sm my-1 text-gray-500">
          <T keyName="email_intake.enter_or_select_information" />
        </p>
      </div>
      <WhoForm setStep={setStep} />
    </div>
  );
}
