import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { T } from "@tolgee/react";
import clsx from "clsx";

import { Chip } from "components";
import { caseTagDict } from "const/case";
import { getBeautyDateTime, snakeCaseToSentenceCase } from "helpers";
import { beautifyCaseType, beautifyDSRequestName, getDSRRequestFlag } from "helpers/case";
import { stringifyBehalfOfContactName, stringifyContactName } from "helpers/contact";
import { getDaysLeft, getDaysLeftString, getTimeDifferenceString } from "helpers/date";

import type { BusinessWithCase } from "types/case";

type Props = { case_: BusinessWithCase };

export default function CaseRow({ case_ }: Props) {
  const notClosed = !(case_.state === "CLOSED");
  const emailVerified = case_.tags.findIndex((t) => t === "EMAIL_VERIFIED") !== -1;
  const getDeadlineColor = () => {
    if (getDaysLeft(case_.deadline) <= 0) {
      return { text: "text-danger", bg: "bg-danger-300" };
    }
    if (case_.deadline_warning) {
      return { text: "text-orange-900", bg: "bg-orange-400" };
    }
    return { text: "text-success-900", bg: "bg-success-100" };
  };

  const showWarning = notClosed && (case_.deadline_warning || getDaysLeft(case_.deadline) <= 0);
  return (
    <div
      className="flex justify-between p-4 -m-4 w-full cursor-pointer"
      data-testid={`dsrCase_item_${case_.uuid}`}>
      {showWarning && (
        <div className="absolute flex items-center self-center justify-center w-6 h-6 border-4 rounded-full -left-3 border-danger-300">
          <div className="flex items-center justify-center w-full h-full bg-white border-2 rounded-full border-danger">
            <FontAwesomeIcon icon="exclamation" color="danger" size="xs" />
          </div>
        </div>
      )}
      {case_.state === "NEW" && !showWarning && (
        <div className="absolute -left-2 self-center bg-İnfo-400 border-4 border-info-200 rounded-full w-4 h-4" />
      )}
      <div className="flex flex-col">
        <div className="flex items-center space-x-2">
          <div className="flex space-x-2">
            {case_.acting_for === "MYSELF" ? (
              <b>{stringifyContactName(case_?.ds_name, case_?.ds_salutation)}</b>
            ) : (
              stringifyBehalfOfContactName(case_?.sender_name, case_?.ds_name)
            )}
            <Tooltip title={case_.ds_country_name} placement="top">
              <div>{case_.ds_country_flag || "🌍"}</div>
            </Tooltip>
          </div>
          <div className="rounded-full bg-[#E2E8F0] px-2 py-0.5 flex items-center space-x-2">
            <span className="text-darkgray">{case_.ds_email}</span>
            <FontAwesomeIcon
              icon={emailVerified ? "check" : "hourglass"}
              color={emailVerified ? "green" : "darkgray"}
              size="sm"
            />
          </div>
        </div>
        <div className="flex items-center mt-1 space-x-2">
          {case_.state !== "CLOSED" &&
            case_.tags
              .filter((t) => !["EMAIL_VERIFIED", "EMAIL_NOT_VERIFIED"].includes(t))
              .map((t) => (
                <Chip
                  key={t}
                  title={
                    <>
                      <FontAwesomeIcon
                        icon={caseTagDict[t]?.icon}
                        size="sm"
                        className={clsx("mr-1", caseTagDict[t]?.textColor)}
                      />
                      <span className={caseTagDict[t]?.textColor}>
                        <T keyName={`cases.tag.${t.toLowerCase()}`} />
                      </span>
                    </>
                  }
                  backgroundColor={caseTagDict[t]?.backgroundColor}
                  className={clsx("py-0.5 border-2", caseTagDict[t]?.borderColor)}
                />
              ))}

          {!["OPEN", "CLOSED", "NEW"].includes(case_.state) && (
            <Chip
              title={
                <>
                  <FontAwesomeIcon icon="address-card" size="sm" className="mr-1 text-info-950" />
                  <span className="text-info-950">{snakeCaseToSentenceCase(case_.state)}</span>
                </>
              }
              backgroundColor="bg-info-50"
              className="py-0.5 border-2 border-info-300"
            />
          )}

          {case_.stage && (
            <Chip
              title={<span className="text-info-950">{snakeCaseToSentenceCase(case_.stage)}</span>}
              backgroundColor="bg-info-50"
              className="py-0.5 border-2 border-info-300"
            />
          )}

          <div className="flex items-center space-x-1">
            <FontAwesomeIcon icon="clock" color="gray" size="sm" />
            <Tooltip
              title={
                <div className="text-center">
                  Updated at <br /> {getBeautyDateTime(case_.updated_at)}
                </div>
              }
              placement="top">
              <div>{getTimeDifferenceString(case_.updated_at)} ago</div>
            </Tooltip>
            <span>|</span>
            <Tooltip
              title={
                <div className="text-center">
                  Created at <br /> {getBeautyDateTime(case_.created_at)}
                </div>
              }
              placement="top">
              <div>{getTimeDifferenceString(case_.created_at)} old</div>
            </Tooltip>
          </div>
          {notClosed && (
            <Tooltip title={getBeautyDateTime(case_.deadline)} placement="top">
              <div>
                <Chip
                  title={
                    <>
                      <FontAwesomeIcon
                        icon="clock-rotate-left"
                        size="sm"
                        className={clsx("mr-1", getDeadlineColor().text)}
                      />
                      <span className={getDeadlineColor().text}>
                        {getDaysLeftString(case_.deadline)}
                      </span>
                    </>
                  }
                  backgroundColor={getDeadlineColor().bg}
                  className="py-0.5"
                />
              </div>
            </Tooltip>
          )}

          <span>{case_.company_name}</span>
          {case_.inquiries_total > 0 && (
            <Tooltip
              title={`${case_.inquiries_closed} of ${case_.inquiries_total} inquiries answered`}
              placement="top">
              <div>
                <Chip
                  title={
                    <>
                      <FontAwesomeIcon icon="clock-rotate-left" size="sm" className="mr-1" />
                      {case_.inquiries_closed} / {case_.inquiries_total}
                    </>
                  }
                  backgroundColor={
                    case_.inquiries_closed === case_.inquiries_total
                      ? "bg-success-400"
                      : "bg-gray-500"
                  }
                  className="py-0.5"
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between w-48 text-center h-fit">
        <img
          className="self-end mr-2"
          alt="gdpr-logo"
          width={32}
          src={getDSRRequestFlag(case_.type)}
        />
        <span className="w-full text-center">
          {beautifyCaseType(case_)} <br /> ({beautifyDSRequestName(case_)})
        </span>
      </div>
    </div>
  );
}
