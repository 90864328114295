import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

import { BaseTextField } from "components";
import { api } from "services";
import { handleError } from "services/api/error";
import tolgee from "services/translation";

import type { Certificate } from "types/business";

export default function Certificate() {
  const { businessId } = useParams();
  const [data, setData] = useState<Certificate>();

  const fetchData = useCallback(async () => {
    let res;
    try {
      res = await api.business.getCertificates(businessId);
    } catch (e) {
      handleError(e);
      return;
    }
    setData(res.data.filter((i) => i.product === "dsa")[0]);
  }, [businessId]);

  useEffect(() => {
    fetchData();
  }, [businessId, fetchData]);

  const getHTML = () => {
    navigator?.clipboard?.writeText(data?.html);
  };

  return (
    <div className="box-outerlayout" data-testid="dsa-certificate">
      <div className="md:p-6 w-full">
        <h2>{tolgee.t("dsa.certificate_title")}</h2>
        <div className="flex justify-center mb-12">
          <img src={data?.image_url} className="mt-2 w-48" alt={data?.image_alt} />
        </div>
        <div>{tolgee.t("dsa.certificate_description")}</div>
        <div className="font-bold text-lg mt-8">{tolgee.t("dsa.certificate_html")}</div>
        <div className="border rounded-lg border-gray-300 bg-gray-50 p-4 my-4">
          <div className="mb-6">
            <BaseTextField containerClassName="w-full" multiline disabled value={data?.html} />
          </div>
          <div className="flex justify-end mt-4">
            <Button onClick={() => getHTML()} data-testid="copy-btn">
              <FontAwesomeIcon className="mr-2" icon="copy" color="gray" size="lg" />
              {tolgee.t("generic.copy")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
