import { useNavigate, useParams, useSearchParams } from "react-router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { T } from "@tolgee/react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";

import { useDebounce } from "helpers/hooks";
import ProgressBar from "components/Feedback/ProgressBar";
import { BaseIconButton, SearchField, SplitButton } from "components";
import { api } from "services";
import { getDSRUrl } from "helpers/case";
import CaseRow from "pages/General/Dsr/CaseRow";
import { useAppDispatch, useAppSelector } from "store";
import { dataGridConfig } from "helpers/dataGridConfig";
import tolgee from "services/translation";
import { getDaysLeft } from "helpers/date";
import { changePreference } from "store/app";
import { handleError } from "services/api/error";
import { updateUrlParams } from "helpers/url";

import type { PaginationRequest } from "types/general";
import type { Feature } from "types/company";
import type { GridRowParams } from "@mui/x-data-grid";
import type { BusinessWithCase, CaseType } from "types/case";
import type { SplitButtonItems } from "types/components";

export default function DsrList() {
  const {
    createTestCaseIndex = 0,
    dsrListRows = 25,
    caseSortIndex = 0,
  } = useAppSelector((state) => state.app.preferences);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const debouncedSearchValue = useDebounce<string>(search, 500);
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 0);
  const [sortBy, setSortBy] = useState(searchParams.get("sortBy") || "created_at");
  const [orderedBy, setOrderedBy] = useState(searchParams.get("order") || "desc");
  const [caseActive, setCaseActive] = useState(searchParams.get("active") !== "false");

  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dsrLoading, setDsrLoading] = useState<boolean>(false);
  const { businessId } = useParams();
  const managedBusinesses = useAppSelector((state) => state.user.managed_businesses);
  const [items, setItems] = useState<SplitButtonItems>([]);

  const handleUrlUpdate = useCallback(
    (params: Record<string, string>) => {
      updateUrlParams(params, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const sortBtnItems: SplitButtonItems = useMemo(
    () => [
      {
        name: "First Created",
        details: "DS request with closest creation date",
        action: () => {
          setSortBy("created_at");
          setOrderedBy("desc");
          handleUrlUpdate({ sortBy: "created_at", order: "desc" });
        },
        icon: "arrow-down-short-wide",
      },
      {
        name: "Last Created",
        details: "DS request with latest creation date",
        action: () => {
          setSortBy("created_at");
          setOrderedBy("asc");
          handleUrlUpdate({ sortBy: "created_at", order: "asc" });
        },
        icon: "arrow-down-wide-short",
      },
      {
        name: "Last Updated",
        details: "DS request with oldest updates",
        action: () => {
          setSortBy("updated_at");
          setOrderedBy("asc");
          handleUrlUpdate({ sortBy: "updated_at", order: "asc" });
        },
        icon: "arrow-down-wide-short",
      },
      {
        name: "Nearest Deadline",
        details: "DS nearest with closest deadline",
        action: () => {
          setSortBy("deadline");
          setOrderedBy("asc");
          handleUrlUpdate({ sortBy: "deadline", order: "asc" });
        },
        icon: "arrow-down-wide-short",
      },
      {
        name: "Furthest Deadline",
        details: "DS furthest with closest deadline",
        action: () => {
          setSortBy("deadline");
          setOrderedBy("desc");
          handleUrlUpdate({ sortBy: "deadline", order: "desc" });
        },
        icon: "arrow-down-short-wide",
      },
    ],
    [handleUrlUpdate]
  );

  useEffect(() => {
    sortBtnItems[caseSortIndex].action();
  }, [caseSortIndex, sortBtnItems]);

  const createTestCase = useCallback(
    async (caseType: CaseType, article: number) => {
      if (businessId) {
        setDsrLoading(true);

        let res;
        try {
          res = await api.authCase.createTestCase(businessId, caseType, article);
        } catch (e) {
          handleError(e);
          return;
        }
        res = res.data;

        setDsrLoading(false);
        const url = getDSRUrl(res.type, res.business.public_id, res.uuid);
        if (url.startsWith("http")) {
          window.location.assign(url);
        } else {
          navigate(url);
        }
      }
    },
    [businessId, navigate]
  );

  useEffect(() => {
    function setDSRButton(activeProducts: Feature[]) {
      const tempItems: SplitButtonItems = [];

      if (activeProducts.some((p) => p === "art27")) {
        tempItems.push(
          {
            name: tolgee.t({
              key: "dsr.create.gdpr_15",
            }),
            action: () => createTestCase("DSR_LEGACY_EU", 15),
          },
          {
            name: tolgee.t({
              key: "dsr.create.gdpr_17",
            }),
            action: () => createTestCase("DSR_LEGACY_EU", 17),
          }
        );
      }
      if (activeProducts.some((p) => p === "ukrep")) {
        tempItems.push(
          {
            name: tolgee.t({
              key: "dsr.create.uk_gdpr_15",
            }),
            action: () => createTestCase("DSR_LEGACY_UK", 15),
          },
          {
            name: tolgee.t({
              key: "dsr.create.uk_gdpr_17",
            }),
            action: () => createTestCase("DSR_LEGACY_UK", 17),
          }
        );
      }
      if (activeProducts.some((p) => p === "swissrep")) {
        tempItems.push(
          {
            name: tolgee.t({
              key: "dsr.create.fadp_deletion",
            }),
            action: () => createTestCase("DSR_FADP_DELETE", null),
          },
          {
            name: tolgee.t({
              key: "dsr.create.fadp_access",
            }),
            action: () => createTestCase("DSR_FADP_ACCESS", null),
          },
          {
            name: tolgee.t({
              key: "dsr.create.fadp_correction",
            }),
            action: () => createTestCase("DSR_FADP_CORRECT", null),
          },
          {
            name: tolgee.t({
              key: "dsr.create.fadp_automated_decision",
            }),
            action: () => createTestCase("DSR_FADP_AUTOMATED_DECISION", null),
          },
          {
            name: tolgee.t({
              key: "dsr.create.fadp_prohibit_data_processing",
            }),
            action: () => createTestCase("DSR_FADP_PROHIBIT_DATA_PROCESSING", null),
          }
        );
      }
      if (activeProducts.some((p) => p === "ccpa")) {
        tempItems.push(
          {
            name: tolgee.t({
              key: "dsr.create.ccpa_deletion",
            }),
            action: () => createTestCase("DSR_CCPA_DELETE", null),
          },
          {
            name: tolgee.t({
              key: "dsr.create.ccpa_access",
            }),
            action: () => createTestCase("DSR_CCPA_ACCESS", null),
          },
          {
            name: tolgee.t({
              key: "dsr.create.ccpa_access_categories",
            }),
            action: () => createTestCase("DSR_CCPA_ACCESS_CATEGORIES", null),
          },
          {
            name: tolgee.t({
              key: "dsr.create.ccpa_correction",
            }),
            action: () => createTestCase("DSR_CCPA_CORRECT", null),
          },
          {
            name: tolgee.t({
              key: "dsr.create.ccpa_opt_out",
            }),
            action: () => createTestCase("DSR_CCPA_OPTOUT", null),
          },
          {
            name: tolgee.t({
              key: "dsr.create.ccpa_limit",
            }),
            action: () => createTestCase("DSR_CCPA_LIMIT", null),
          }
        );
      }
      setItems(tempItems);
    }

    if (businessId) {
      const managedBusiness = managedBusinesses.find((mb) => mb.public_id === businessId);
      if (managedBusiness && Array.isArray(managedBusiness.features)) {
        setDSRButton(managedBusiness.features);
      }
    }
  }, [businessId, createTestCase, managedBusinesses]);

  const fetchData = useCallback(
    async (props: PaginationRequest) => {
      setLoading(true);
      const { order_by, page, per_page, order, search_term } = props;
      const desc = order === "desc";

      let res;
      try {
        res = await api.authCase.getCases(
          "DSR",
          caseActive,
          order_by,
          page + 1,
          per_page,
          desc,
          search_term,
          businessId
        );
      } catch (e) {
        handleError(e);
        setLoading(false);
        return;
      }
      res = res.data;

      const tableData = {
        ...dataGridConfig({ currentPage, rowsPerPage: dsrListRows, count: res.count }),
        onPaginationModelChange: (val: any) => {
          setCurrentPage(val.page);
          dispatch(changePreference({ dsrListRows: val.pageSize }));
        },
        columns: [
          {
            flex: 1,
            field: "",
            renderCell: (params: any, idx: string) => {
              return <CaseRow case_={params.row} key={idx} />;
            },
          },
        ],
        rows: res?.result?.map((i) => {
          return { ...i, id: i?.uuid };
        }),
        onRowClick: (params: GridRowParams<BusinessWithCase>) => {
          const url = getDSRUrl(params.row.type, params.row.business_public_id, params.row.uuid);
          if (url.startsWith("http")) {
            window.location.assign(url);
          } else {
            navigate(url);
          }
        },
      };

      setData(tableData);
      setLoading(false);
    },
    [businessId, caseActive, dsrListRows, currentPage, dispatch, navigate]
  );

  useEffect(() => {
    fetchData({
      order_by: sortBy,
      page: currentPage,
      per_page: dsrListRows,
      order: orderedBy,
      search_term: debouncedSearchValue,
    });
  }, [
    businessId,
    currentPage,
    dsrListRows,
    sortBy,
    orderedBy,
    debouncedSearchValue,
    caseActive,
    fetchData,
  ]);

  useEffect(() => {
    if (debouncedSearchValue !== searchParams.get("search")) {
      handleUrlUpdate({ search: debouncedSearchValue });
    }
  }, [debouncedSearchValue, handleUrlUpdate, searchParams]);

  const handleSelectedRequestIndex = (index: number) => {
    dispatch(changePreference({ createTestCaseIndex: index }));
  };

  const handleSelectedSortIndex = (index: number) => {
    dispatch(changePreference({ caseSortIndex: index }));
  };

  return (
    <div className="box-outerlayout !block mx-auto max-w-7xl">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <BaseIconButton
            className="border-full"
            onClick={() =>
              fetchData({
                order_by: sortBy,
                page: currentPage,
                per_page: dsrListRows,
                order: orderedBy,
                search_term: debouncedSearchValue,
              })
            }>
            <div className="flex items-center justify-center w-4 h-4">
              <FontAwesomeIcon icon="refresh" color="gray" />
            </div>
          </BaseIconButton>
          <h1>
            <T keyName="billing.data_subject_requests" />
          </h1>
        </div>

        <div className="flex space-x-2">
          <SplitButton
            items={sortBtnItems}
            listHeight="424px"
            state={[caseSortIndex, handleSelectedSortIndex]}
          />
          {businessId && items.length > 0 && (
            <SplitButton
              listHeight="500px"
              items={items}
              state={[createTestCaseIndex, handleSelectedRequestIndex]}
              disabled={dsrLoading}
              loading={dsrLoading}
              buttonPreName="Create Test Request: "
            />
          )}

          <ToggleButtonGroup
            className="h-10"
            value={caseActive}
            exclusive
            onChange={(_: React.MouseEvent<HTMLElement>, newState: boolean) => {
              if (newState !== null && newState !== undefined) {
                setCaseActive(newState);
                handleUrlUpdate({ active: newState.toString() });
              }
            }}>
            <ToggleButton value>
              <T keyName="cases.active" />
            </ToggleButton>
            <ToggleButton value={false}>
              <T keyName="cases.closed" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div>
        {/* Loading state: Data is being fetched */}
        {loading && !data && <ProgressBar />}
        {/* No data state: Data has been fetched but is empty */}
        {!loading && !data && (
          <div>
            {tolgee.t({
              key: "billing.no_data",
            })}
          </div>
        )}
        {/* Data available state: Data has been fetched and is not empty */}
        {data && (
          <>
            <SearchField className="w-full" searchState={[search, setSearch]} />
            <DataGrid
              getRowClassName={(params) => {
                const showDanger =
                  !(params.row.state === "CLOSED") &&
                  (params.row.deadline_warning || getDaysLeft(params.row.deadline) <= 0);
                return showDanger ? "bg-danger/10" : "";
              }}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
              }}
              columnHeaderHeight={0}
              {...data}
              loading={loading}
            />
          </>
        )}
      </div>
    </div>
  );
}
