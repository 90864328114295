import api from "services/api/base";

import type { User } from "types/user";
import type { AxiosResponse } from "axios";
import type { Company, CompanyEditOut } from "types/company";
import type { Address, FileObject, RepLocationType } from "types/general";
import type {
  Certificate,
  Business,
  BusinessFileEnum,
  BusinessFilePrivate,
  LoaFileObject,
} from "types/business";

export async function get(businessId: string): Promise<AxiosResponse<Company>> {
  return api.get("/v1/business", {
    params: { business_id: businessId },
  });
}

export async function update(
  businessId: string,
  newData: CompanyEditOut
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/business", newData, {
    params: { business_id: businessId },
  });
}

export async function updateAddress(
  businessId: string,
  data: Address
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/business/address", data, {
    params: { business_id: businessId },
  });
}

export async function getCertificates(businessId: string): Promise<AxiosResponse<Certificate[]>> {
  return api.get("/v1/business/certificates", {
    params: { business_id: businessId },
  });
}

export async function sendSupportTicket(message: string): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/business/support-ticket", {
    message,
  });
}

export async function createBusiness(data: Business): Promise<AxiosResponse<User>> {
  return api.post("/v1/business", data);
}

export async function getRepLocation(
  businessId: string,
  type: RepLocationType
): Promise<AxiosResponse<string>> {
  return api.get("/v1/business/rep-location", {
    params: { business_id: businessId, type_: type },
  });
}

export async function updateRepLocation(
  businessId: string,
  data: {
    location: string;
    type: RepLocationType;
  }
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/business/rep-location", data, {
    params: { business_id: businessId },
  });
}

export async function getRepLocations(type: RepLocationType): Promise<AxiosResponse<any>> {
  return api.get("/v1/generic/rep-locations", {
    params: { type_: type },
  });
}

export async function getBusinessRepLocations(businessId: string): Promise<AxiosResponse<any>> {
  return api.get("/v1/business/rep-locations", {
    params: { business_id: businessId },
  });
}

export async function getPolicies(businessId: string): Promise<AxiosResponse<unknown>> {
  return api.get("/v1/business/rep/policies", {
    params: { business_id: businessId },
  });
}

export async function addPolicy(businessId: string, policy: any): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/business/rep/policy", policy.newPolicy, {
    params: { business_id: businessId },
  });
}

export async function deletePublicFile(
  businessId: string,
  type: BusinessFileEnum
): Promise<AxiosResponse<unknown>> {
  return api.delete("/v1/file/business", {
    params: { business_id: businessId, type_: type },
  });
}

export async function linkSubscriptionsToBusiness(
  businessId: string,
  public_ids: string[]
): Promise<AxiosResponse<unknown>> {
  return api.put(
    "/v1/business/subscriptions/link",
    { public_ids },
    {
      params: { business_id: businessId },
    }
  );
}

export async function getFile(
  businessId: string,
  fileType: BusinessFilePrivate
): Promise<AxiosResponse<string>> {
  return api.get("/v1/file/business", {
    params: { business_id: businessId, type_: fileType },
  });
}

export async function getFiles(businessId: string): Promise<AxiosResponse<FileObject[]>> {
  return api.get("/v1/file/business/list", {
    params: { business_id: businessId },
  });
}

export async function uploadFile(
  businessId: string,
  file: FileObject,
  fileType: BusinessFilePrivate
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/file/business", file, {
    params: { business_id: businessId, type_: fileType },
  });
}

export async function deleteFile(
  businessId: string,
  fileType: BusinessFilePrivate
): Promise<AxiosResponse<unknown>> {
  return api.delete("/v1/file/business", {
    params: { business_id: businessId, type_: fileType },
  });
}

export async function getLoaFileUrls(businessId: string): Promise<AxiosResponse<LoaFileObject>> {
  return api.get("/v1/business/loas", {
    params: { business_id: businessId },
  });
}
