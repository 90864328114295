import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { type Stripe, type StripeElementsOptions } from "@stripe/stripe-js";

import { STRIPE_KEY } from "const/env";
import InvoicePaidBox from "pages/Client/Billing/PayInvoice/InvoicePaidBox";

export default function InvoicePaid() {
  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe>>();

  useEffect(() => {
    const sp = loadStripe(STRIPE_KEY);
    if (sp) {
      setStripePromise(sp);
    }
  }, []);

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    const tempOptions: StripeElementsOptions = {
      clientSecret,
    };
    setStripeOptions(tempOptions);
  }, []);

  return (
    <div className="bg-prighterblue w-full h-screen overflow-auto flex items-center justify-center">
      {stripePromise && stripeOptions?.clientSecret && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <InvoicePaidBox />
        </Elements>
      )}
    </div>
  );
}
