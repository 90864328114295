import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";

import DeclineFraudulentButton from "pages/Client/Case/DSR/ClientView/DeclineFraudulentButton";
import { generateI18nKey, isCheckFinished } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import DeclineWithPreviewButton from "pages/Client/Case/DSR/ClientView/DeclineWithPreviewButton";
import tolgee from "services/translation";
import { finalizeCheck, reopenCheck } from "store/thunks";
import { DeclineTypeEnum } from "types/case";

type Props = {
  handleSendInquiry: () => void;
  handleComplete?: () => void;
  confirmButtonText?: string;
  declinePreview?: DeclineTypeEnum;
  inquiry?: boolean;
};

export default function ConfirmInquiry({
  handleSendInquiry,
  handleComplete,
  confirmButtonText,
  declinePreview,
  inquiry = true,
}: Props) {
  const [state, dispatch] = useCase();
  const caseData = state.case;
  const caseClosed = caseData.state === "CLOSED";

  const completeCheck =
    handleComplete || (() => finalizeCheck()(dispatch, caseData.uuid, state.ui.check));

  const isFinished = isCheckFinished(state);

  return (
    <div className="flex flex-row justify-between">
      <div className="space-x-2">
        {inquiry && caseData.type !== "DSR_CCPA_LIMIT" && caseData.type !== "DSR_CCPA_OPTOUT" && (
          <Button onClick={handleSendInquiry} disabled={isFinished}>
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "send_inquiry"),
            })}
          </Button>
        )}
        {!caseClosed &&
          !isFinished &&
          declinePreview &&
          (declinePreview === DeclineTypeEnum.FRAUDULENT ? (
            <DeclineFraudulentButton
              label={generateI18nKey(caseData.type, state.ui.check, "decline_open")}
              labelSubmit={generateI18nKey(caseData.type, state.ui.check, "decline_submit")}
              disabled={isFinished}
              declineType={declinePreview}
            />
          ) : (
            <DeclineWithPreviewButton
              label={generateI18nKey(caseData.type, state.ui.check, "decline_open")}
              labelSubmit={generateI18nKey(caseData.type, state.ui.check, "decline_submit")}
              disabled={isFinished}
              declineType={declinePreview}
            />
          ))}
      </div>
      <div>
        <Button
          onClick={completeCheck}
          disabled={isFinished}
          data-testid="dsr_confirmation_button"
          sx={{
            backgroundColor: "#4ad158",
            "&:hover": { backgroundColor: "#4b904f" },
          }}>
          {confirmButtonText ||
            tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "confirm"),
            })}
        </Button>
        {isFinished && !caseClosed && (
          <Button
            onClick={() => reopenCheck()(dispatch, caseData.uuid, state.ui.check)}
            variant="outlined"
            endIcon={<FontAwesomeIcon icon="lock-open" className=" !text-sm" />}
            sx={{
              ml: 2,
            }}>
            {tolgee.t({
              key: "general.reopen",
            })}
          </Button>
        )}
      </div>
    </div>
  );
}
