import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";
import { TextField } from "@mui/material";

import { notify } from "store/app";
import { BaseButton } from "components";
import { api } from "services";
import tolgee from "services/translation";
import { handleError } from "services/api/error";

import type { Company, CompanyEditOut } from "types/company";

type Props = {
  business: Company;
  onUpdate: () => unknown;
  field: "company_name" | "company_authorized_to_sign";
};

export default function BusinessUpdateModal({ business, onUpdate, field }: Readonly<Props>) {
  const { businessId } = useParams();
  const dispatch = useDispatch();

  const companyData = {
    company_name: business.company_name,
    company_authorized_to_sign: business.company_authorized_to_sign,
    street: business.address.street,
    postal: business.address.postal,
    city: business.address.city,
    country_iso: business.address.country_iso,
  };

  const {
    reset,
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<CompanyEditOut>({ defaultValues: companyData });

  const onSubmit = async (values: CompanyEditOut) => {
    const newValues = {
      ...values,
      company_name: values.company_name === business.company_name ? null : values.company_name,
      company_authorized_to_sign:
        values.company_authorized_to_sign === business.company_authorized_to_sign
          ? null
          : values.company_authorized_to_sign,
    };
    try {
      await api.business.update(businessId, newValues);
    } catch (e) {
      handleError(e);
      return;
    }
    dispatch(notify({ message: "Company Data updated successfully", type: "SUCCESS" }));
    onUpdate();
    handleFormReset();
  };

  const handleFormReset = () => {
    reset(companyData);
  };

  return (
    <>
      <h1 className="flex w-full mbs-8">
        <T keyName="billing.company_address" />
      </h1>
      <div className="flex w-full flex-col mb-2">
        <TextField label={tolgee.t({ key: field })} {...register(field)} />

        <div className="flex justify-end space-x-2 mt-8">
          <BaseButton onClick={handleSubmit(onSubmit)} disabled={!isValid}>
            <T keyName="generic.save" />
          </BaseButton>
        </div>
      </div>
    </>
  );
}
