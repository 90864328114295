import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import userReducer from "store/user";
import appReducer from "store/app";
import menuReducer from "store/menu";

import type { TypedUseSelectorHook } from "react-redux";

export const store = configureStore({
  reducer: { user: userReducer, menu: menuReducer, app: appReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {},
      },
    }),
});

export function setupStore(preloadedState?: RootState): any {
  return configureStore({
    reducer: { user: userReducer, menu: menuReducer, app: appReducer },
    preloadedState,
  });
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
