import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { Notification } from "types/notifications";

interface Props {
  notification: Notification;
  deleteNotification: (uuid: string) => void;
}

export default function NotificationItem(props: Props) {
  const { notification, deleteNotification } = props;

  return (
    <div className="flex flex-row justify-between p-4 rounded-lg hover:bg-gray-50 transition-colors duration-200 cursor-pointer">
      <div className="flex flex-row gap-4">
        <div className="text-md">{notification.type}</div>
        <div className="text-md">{notification.address}</div>
        <div>
          {notification.validated ? (
            <FontAwesomeIcon icon="check" />
          ) : (
            <FontAwesomeIcon icon="times" />
          )}
        </div>
      </div>
      <div
        className="opacity-50 hover:opacity-100 transition-opacity duration-200 cursor-pointer"
        onClick={() => deleteNotification(notification.uuid)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            deleteNotification(notification.uuid);
          }
        }}
        role="button"
        tabIndex={0}>
        <FontAwesomeIcon icon="trash" />
      </div>
    </div>
  );
}
