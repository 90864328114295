import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  MenuItem,
  TextField,
} from "@mui/material";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Controller, useForm } from "react-hook-form";

import { capitalizeFirstLetter } from "helpers/general";
import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
import { handleError } from "services/api/error";
import { RepLocationType } from "types/general";

export default function Snippet() {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const [locations, setLocations] = useState<string[]>([]);

  const { watch, setValue, control } = useForm({ defaultValues: { selectedLocation: "" } });

  const selectedLocation = watch("selectedLocation");

  const fetchLocationsData = useCallback(async () => {
    try {
      const res = await api.business.getRepLocations(RepLocationType.GDPR);
      const sorted_locations = Object.keys(res.data).sort((a, b) => a.localeCompare(b));
      setLocations(sorted_locations);
    } catch (e) {
      handleError(e);
    }
  }, []);

  const fetchCurrentLocation = useCallback(async () => {
    try {
      const res = await api.business.getRepLocation(businessId, RepLocationType.GDPR);
      setValue("selectedLocation", res.data);
    } catch (e) {
      handleError(e);
    }
  }, [businessId, setValue]);

  useEffect(() => {
    fetchLocationsData();
    fetchCurrentLocation();
  }, [fetchCurrentLocation, fetchLocationsData]);

  const handleSetLocation = async () => {
    try {
      await api.business.updateRepLocation(businessId, {
        location: selectedLocation,
        type: RepLocationType.GDPR,
      });
    } catch (e) {
      handleError(e);
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "gdpr.location_updated",
        }),
        type: "SUCCESS",
      })
    );
  };

  return (
    <div className="box-outerlayout pb-6 pt-10 mt-6" data-testid="location-section">
      <div className="md:p-6 w-full">
        <h2>
          <T keyName="gdpr.eurep_location_title" />
        </h2>
        <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8">
          <div>
            <T keyName="gdpr.eurep_when_choosing" />
            <Accordion className="p-2 mt-6">
              <AccordionSummary
                expandIcon={<FontAwesomeIcon size="lg" icon="angle-down" />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                {tolgee.t({
                  key: "gdpr.legal_implication",
                })}
              </AccordionSummary>
              <AccordionDetails>
                {tolgee.t({
                  key: "gdpr.legal_implication_full",
                })}
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            {locations.length > 0 && (
              <Controller
                name="selectedLocation"
                control={control}
                defaultValue=""
                render={({ field: { value, ref, ...field } }: any) => (
                  <TextField
                    {...field}
                    inputRef={ref}
                    select
                    value={value}
                    defaultValue={value}
                    onChange={(event: any) => {
                      setValue(`selectedLocation`, event.target?.value);
                    }}
                    label={tolgee.t({
                      key: "gdpr.address",
                    })}
                    className="w-full md:w-100 mt-4">
                    {locations.map((location) => (
                      <MenuItem value={location} key={location}>
                        {capitalizeFirstLetter(location)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            )}
            <div className="flex justify-end mt-6">
              <Button onClick={handleSetLocation}>
                <T keyName="gdpr.set_address" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
