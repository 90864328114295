import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import BaseModal from "components/DataDisplay/Modal/BaseModal";
import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { fetchCaseData } from "store/thunks";
import { useCase } from "pages/Client/Case/CaseContext";
import MarkdownEditor from "components/Input/MarkdownEditor";
import { handleError } from "services/api/error";
import { generateClosedAsTypeBuDeclineType, getDSRUrl } from "helpers/case";
import { DeclineTypeEnum } from "types/case";

import type { CaseDetail } from "types/case";

const defaultValues = {
  subject: "",
  content: "",
};

type Props = {
  buttonTestId?: string;
  modalButtonTestId?: string;
  label: string;
  labelSubmit: string;
  disabled?: boolean;
  declineType: DeclineTypeEnum;
};

export default function DeclineWithPreviewButton(props: Props) {
  const { buttonTestId, modalButtonTestId, label, labelSubmit, disabled, declineType } = props;
  const [state, dispatch] = useCase();
  const navigate = useNavigate();

  const appDispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const { register, reset, watch, setValue, getValues } = useForm({
    defaultValues,
  });

  useEffect(() => {
    async function fetchData() {
      let res;
      try {
        res = await api.ccpa.getDeclinePreview(state.case.uuid, declineType);
      } catch (e) {
        handleError(e);
        return;
      }
      reset({
        content: res.data.content,
        subject: res.data.subject,
      });
    }
    if (open) {
      fetchData();
    }
  }, [state.case.uuid, reset, declineType, open]);

  const handleDecline = async () => {
    const closedAs = generateClosedAsTypeBuDeclineType(declineType);
    try {
      const res = (
        await api.authCase.declineCase(state.case.uuid, {
          ...getValues(),
          files: [],
          closed_as: closedAs,
        })
      ).data as CaseDetail;

      appDispatch(
        notify({
          message: tolgee.t({
            key: "dsr.decline_success",
          }),
          type: "SUCCESS",
        })
      );

      reset(defaultValues);
      setOpen(false);
      if (declineType === DeclineTypeEnum.CORRECT_DELETED) {
        const url = getDSRUrl(res.type, res.business.public_id, res.uuid);
        navigate(url);
        window.location.reload();
      } else {
        fetchCaseData()(dispatch, state.case.uuid);
      }
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <>
      <Button
        data-testid={buttonTestId}
        onClick={() => setOpen(true)}
        sx={{
          backgroundColor: "#C7102E",
          "&:hover": { backgroundColor: "#980c23" },
        }}
        disabled={disabled}>
        {tolgee.t({
          key: `${label}`,
        })}
      </Button>
      <BaseModal modalState={[open, setOpen]}>
        <div className="w-[64rem] space-y-4">
          <h3 className="text-center m-0">
            <T keyName="dsr.decline_request" />
          </h3>
          <div className="flex flex-col space-y-2">
            <TextField
              InputLabelProps={{ shrink: true }}
              label={tolgee.t({
                key: "generic.subject",
              })}
              {...register("subject")}
            />

            <MarkdownEditor
              onChange={(editorState: any) =>
                setValue("content", editorState, { shouldDirty: true })
              }
              value={watch("content")}
            />
          </div>

          <div className="flex justify-end pt-6">
            <Button
              data-testid={modalButtonTestId}
              sx={{
                backgroundColor: "#C7102E",
                "&:hover": { backgroundColor: "#980c23" },
              }}
              disabled={disabled}
              onClick={handleDecline}>
              {tolgee.t({
                key: `${labelSubmit}`,
              })}
            </Button>
          </div>
        </div>
      </BaseModal>
    </>
  );
}
