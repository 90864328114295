import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { T } from "@tolgee/react";

import { api } from "services";
import { ProgressBar } from "components";
import PrighterFeedback from "components/Prighter/PrighterFeedback";
import { handleError } from "services/api/error";

export default function DsrVerifyEmail() {
  const { token } = useParams();
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function verifyEmail() {
      setLoading(true);
      try {
        await api.dsr.verifyDataSubjectEmail(token);
      } catch (e) {
        handleError(e);
        return;
      } finally {
        setLoading(false);
      }
      setVerified(true);
    }
    verifyEmail();
  }, [token]);

  return (
    <div className="flex justify-center items-center w-full h-screen bg-gray-50">
      {loading ? (
        <ProgressBar />
      ) : (
        <PrighterFeedback>
          <div className="px-16 pb-6 flex flex-col items-center text-center">
            <span className="text-xl text-gray-700">
              <T keyName="dsr.verify_email" />
            </span>
            <span className="text-gray-600 text-sm mt-1 mb-6">
              {verified ? (
                <T keyName="dsr.email_is_verified" />
              ) : (
                <T keyName="generic.smth_went_wrong" />
              )}
            </span>
          </div>
        </PrighterFeedback>
      )}
    </div>
  );
}
