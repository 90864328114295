import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router";
import { ErrorBoundary } from "@sentry/react";

import NotFound from "pages/General/Error/NotFound";
import Redirect from "pages/General/Error/Redirect";
import ServerError from "pages/General/Error/ServerError";
import { useAppSelector } from "store";
import { setError } from "store/app";
import Header from "components/Layout/Header";
import Main from "components/Layout/Main";
import ErrorFallback from "services/errorHandling/ErrorFallback";
import Forbidden from "pages/General/Error/Forbidden";

import type { ReactNode } from "react";

export default function LoggedInLayout(props: { noLayout?: boolean }) {
  const { noLayout = false } = props;
  const errorCode = useAppSelector((state) => state.app.error);
  const location = useLocation();
  const dispatch = useDispatch();
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      dispatch(setError(null));
    } else {
      didMountRef.current = true;
    }
  }, [dispatch, location]);

  const renderErrorSwitch = (): ReactNode => {
    switch (errorCode) {
      case "404":
        return <NotFound />;
      case "500":
        return <ServerError />;
      case "401":
        return <Redirect />;
      case "403":
        return <Forbidden />;
      default:
        return <Outlet />;
    }
  };

  const fallback = ({ resetError }: any): any => {
    return <ErrorFallback resetError={resetError} />;
  };

  return (
    <div
      className="font-body flex flex-col min-h-screen relative mx-0 bg-gray-50"
      data-testid="logged-in-layout-element">
      {noLayout ? (
        renderErrorSwitch()
      ) : (
        <>
          <Header />
          <Main>
            <ErrorBoundary
              fallback={fallback}
              showDialog
              dialogOptions={{
                title: "Report Server Error",
                subtitle: "Please tell us what happened",
                subtitle2: "Your feedback helps us improve",
              }}>
              {renderErrorSwitch()}
            </ErrorBoundary>
          </Main>
        </>
      )}
    </div>
  );
}
