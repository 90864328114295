export const faker = {
  number: {
    int: (_ = {}): number => 0,
  },
  lorem: {
    words: (_ = 0): string => "",
  },
  person: {
    fullName: (): string => "",
  },
  internet: {
    email: (): string => "",
    exampleEmail: (): string => "",
    userName: (): string => "",
  },
  location: {
    street: (): string => "",
    zipCode: (_ = ""): string => "",
    city: (): string => "",
  },
  helpers: {
    arrayElement: (_: any = []): string => "",
  },
};
