import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";

import { BaseButton } from "components/Button";
import { BaseModal } from "components/DataDisplay/Modal";
import tolgee from "services/translation";

import type { UseState } from "types";

type Props = {
  modalState: UseState<boolean>;
  passwordErrorState?: UseState<boolean>;
  setPassword?: (password: string) => void;
  submit?: () => void;
};

export default function UnauthorizedAccessModal(props: Props) {
  const { modalState, setPassword, passwordErrorState, submit = () => {} } = props;
  const [open, setOpen] = modalState;
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [passwordError, setPasswordError] = passwordErrorState;

  return (
    <BaseModal modalState={[open, setOpen]}>
      <h2>{tolgee.t("unauthorizedAccessModal.title")}</h2>
      <p>{tolgee.t("unauthorizedAccessModal.description")}</p>
      <div className="mb-4">
        <TextField
          error={!!passwordError}
          className="w-full"
          type={isPasswordVisible ? "text" : "password"}
          label={tolgee.t("unauthorizedAccessModal.inputLabel")}
          onChange={(event: any) => [setPassword(event.target.value), setPasswordError(false)]}
          onKeyDown={submit}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    edge="end">
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      </div>
      <div className="flex justify-center">
        <BaseButton onClick={submit}>{tolgee.t("unauthorizedAccessModal.button")}</BaseButton>
      </div>
    </BaseModal>
  );
}
