import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type { Country } from "types/general";
import type { ApiStatus } from "types/app";
import type { NavMenu } from "types/components";

export async function ping(): Promise<AxiosResponse<ApiStatus>> {
  return api.get("/ping");
}

export async function menu(): Promise<AxiosResponse<NavMenu[]>> {
  return api.get("/v1/user/menu");
}

export async function getCountries(): Promise<AxiosResponse<Country[]>> {
  return api.get("/v1/generic/countries");
}
