import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import clsx from "clsx";

import ConfirmationModal from "components/DataDisplay/Modal/ConfirmationModal";

import type { JSX } from "react";

type Props = {
  size?: "small" | "medium" | "large";
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  children?: JSX.Element;
  confirmPopup?: boolean;
  testid?: string;
  disabled?: boolean;
  tooltip?: {
    title?: string;
    placement?:
      | "bottom-end"
      | "bottom-start"
      | "bottom"
      | "left-end"
      | "left-start"
      | "left"
      | "right-end"
      | "right-start"
      | "right"
      | "top-end"
      | "top-start"
      | "top";
  };
};

export default function BaseIconButton(props: Props) {
  const {
    size = "medium",
    className,
    style,
    onClick = () => {},
    children,
    confirmPopup,
    tooltip,
    disabled = false,
    testid = "",
  } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (confirmPopup) {
      setOpen(true);
    } else {
      onClick();
    }
  };

  const buttonElement: JSX.Element = (
    <IconButton
      disabled={disabled}
      data-testid={testid}
      size={size}
      style={style}
      onClick={handleClick}
      className={clsx(className, "h-fit w-fit")}>
      {children}
    </IconButton>
  );

  return (
    <>
      {!tooltip ? (
        buttonElement
      ) : (
        <Tooltip title={tooltip?.title} placement={tooltip?.placement}>
          {buttonElement}
        </Tooltip>
      )}
      {confirmPopup && <ConfirmationModal modalState={[open, setOpen]} onYes={onClick} />}
    </>
  );
}
