import { InputLabel, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";

import { BaseSelectField, ExtendedSelectField } from "components";
import { getCountryAsOptions } from "helpers";
import IdentificationCheck from "pages/Client/EmailCases/Components/ExpertMultistepForm/EmailCasesFormWho/IdentificationCheck";
import { inputStyle, selectStyle } from "pages/Client/EmailCases/Pages/styling";
import { useAppSelector } from "store";
import tolgee from "services/translation";

import type { CcpaForm } from "types/dsr/ccpa";
import type { DSRActingFor } from "types/dsr/main";

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

function WhoForm(props: Props) {
  const { setStep } = props;
  const {
    watch,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = useFormContext<CcpaForm>();

  const [countryOptions, setCountryOptions] = useState([]);
  const { countries } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (countries) {
      const options = getCountryAsOptions(countries);
      setCountryOptions(options);
    }
  }, [countries]);

  const countryIso = watch("identifiable_info.address.country_iso");

  const acting_for = watch("actors.1.acting_for");
  return (
    <div className="flex flex-col gap-4  items-start text-darkgray ">
      <div className="flex flex-col gap-2 w-full">
        <InputLabel>
          <T keyName="email_intake.data_subject_is_for" />
        </InputLabel>

        <BaseSelectField
          error={!!errors?.actors?.at(0)?.acting_for}
          required
          sx={selectStyle}
          state={[acting_for, (val: DSRActingFor) => setValue("actors.1.acting_for", val)]}
          options={{
            MYSELF: tolgee.t("email_intake.option_for_himself"),
            AS_PARENT: tolgee.t("email_intake.option_as_parent"),
            AS_LEGAL_REPRESENTATIVE: tolgee.t("email_intake.option_as_legal_representative"),
          }}
        />
      </div>
      {!!acting_for && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4  items-start text-darkgray w-full">
            <div className="flex flex-col gap-2">
              <InputLabel>
                <T keyName="generic.name" />
              </InputLabel>
              <TextField
                size="small"
                sx={inputStyle}
                error={!!errors?.identifiable_info?.username}
                {...register("identifiable_info.username", {
                  required: tolgee.t("email_intake.name_is_required"),
                })}
              />
            </div>
            <div className="flex flex-col gap-2">
              <InputLabel>
                <T keyName="generic.email" />
              </InputLabel>
              <TextField
                size="small"
                sx={inputStyle}
                error={!!errors?.identifiable_info?.email}
                {...register("identifiable_info.email", {
                  validate: (value) => {
                    const actingFor = getValues("actors.1.acting_for");
                    if (actingFor === "MYSELF" && !value) {
                      return tolgee.t("email_intake.email_is_required");
                    }
                    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
                      return tolgee.t("email_intake.invalid_email_format");
                    }
                    return true;
                  },
                })}
              />
            </div>

            <div className="flex flex-col gap-2">
              <InputLabel>
                <T keyName="generic.customer_id" />
              </InputLabel>
              <TextField
                size="small"
                sx={inputStyle}
                {...register("identifiable_info.customer_number")}
              />
            </div>

            <div className="flex flex-col gap-2">
              <InputLabel>
                <T keyName="generic.phone_number" />
              </InputLabel>
              <TextField size="small" sx={inputStyle} {...register("identifiable_info.phone")} />
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4  items-start text-darkgray ">
            <div className="flex flex-col gap-2 col-span-2">
              <InputLabel>
                <T keyName="generic.city" />
              </InputLabel>
              <TextField
                size="small"
                sx={inputStyle}
                {...register("identifiable_info.address.city")}
              />
            </div>
            <div className="flex flex-col gap-2 col-span-2">
              <InputLabel>
                <T keyName="generic.street" />
              </InputLabel>
              <TextField
                size="small"
                sx={inputStyle}
                {...register("identifiable_info.address.street")}
              />
            </div>
            <div className="flex flex-col gap-2 ">
              <InputLabel>
                <T keyName="generic.postal" />
              </InputLabel>
              <TextField
                size="small"
                sx={inputStyle}
                {...register("identifiable_info.address.postal")}
              />
            </div>
            <div className="flex flex-col gap-2 col-span-2">
              <InputLabel>
                <T keyName="generic.country" />
              </InputLabel>
              <ExtendedSelectField
                sx={inputStyle}
                state={[
                  countryIso,
                  (text: any) => setValue("identifiable_info.address.country_iso", text),
                ]}
                options={countryOptions}
                label=""
              />
            </div>
          </div>

          {acting_for !== "MYSELF" && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4  items-start text-darkgray w-full mt-6">
              <div className="flex flex-col gap-2">
                <InputLabel>
                  <T keyName="generic.name" />
                </InputLabel>
                <TextField
                  size="small"
                  sx={inputStyle}
                  error={!!errors?.actors?.[1]?.name}
                  {...register("actors.1.name", {
                    validate: (value) => {
                      const actingFor = getValues("actors.1.acting_for");
                      if (actingFor !== "MYSELF" && !value) {
                        return tolgee.t("email_intake.name_is_required");
                      }
                      return true;
                    },
                  })}
                />
              </div>
              <div className="flex flex-col gap-2">
                <InputLabel>
                  <T keyName="generic.email" />
                </InputLabel>
                <TextField
                  size="small"
                  sx={inputStyle}
                  error={!!errors?.actors?.[1]?.email}
                  {...register("actors.1.email", {
                    validate: (value) => {
                      const actingFor = getValues("actors.1.acting_for");
                      if (actingFor !== "MYSELF" && !value) {
                        return tolgee.t("email_intake.email_is_required");
                      }
                      return true;
                    },
                  })}
                />
              </div>
              <div className="flex flex-col gap-2">
                <InputLabel>
                  <T keyName="generic.phone_number" />
                </InputLabel>
                <TextField size="small" sx={inputStyle} {...register("actors.1.phone")} />
              </div>
            </div>
          )}
          <IdentificationCheck setStep={() => setStep(1)} />
        </>
      )}
    </div>
  );
}

export default WhoForm;
