import ClosedCase from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/HandlingReview/ClosedCase";
import OpenCase from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/Stages/HandlingReview/OpenCase";
import { useCase } from "pages/Client/Case/CaseContext";

export default function HandlingReview() {
  const [state] = useCase();
  const caseData = state.case;
  const requestClosed = caseData.state === "CLOSED";

  return <>{requestClosed ? <ClosedCase /> : <OpenCase />}</>;
}
