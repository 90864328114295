import { useEffect, useState } from "react";
import { isNaN } from "lodash";
import { HexColorPicker } from "react-colorful";
import { Button, IconButton, TextField } from "@mui/material";
import { T } from "@tolgee/react";

import BaseModal from "components/DataDisplay/Modal/BaseModal";

type ColorPickerProps = {
  label: string;
  color: string;
  onChange: (val: string) => void;
  handleResetDefault: () => void;
};

type Colors = {
  r: number;
  g: number;
  b: number;
};

const format = (number: number) => {
  const hex = number.toString(16);
  return hex.length < 2 ? `0${hex}` : hex;
};

const hexToRgb = (hexColor: string) => {
  let hex = hexColor;

  if (hex[0] === "#") {
    hex = hex.substr(1);
  }

  if (hex.length < 6) {
    return {
      r: parseInt(hex[0] + hex[0], 16),
      g: parseInt(hex[1] + hex[1], 16),
      b: parseInt(hex[2] + hex[2], 16),
    };
  }

  return {
    r: parseInt(hex.substr(0, 2), 16),
    g: parseInt(hex.substr(2, 2), 16),
    b: parseInt(hex.substr(4, 2), 16),
  };
};

const rgbToHex = ({ r, g, b }: Colors) => `#${format(r)}${format(g)}${format(b)}`;

const onlyNumbers = (num: string) => num.replace(/([^0-9]+)/gi, "").substr(0, 3);

function ColorPicker({ label, color, onChange, handleResetDefault }: ColorPickerProps) {
  const [rgb, setRgb] = useState({ r: 0, g: 0, b: 0 });
  const [pickerOpen, setPickerOpen] = useState(false);

  useEffect(() => {
    setRgb(hexToRgb(color));
  }, [color]);

  const handleRgbChange = (prop: string, colorNum: string) => {
    const shade = onlyNumbers(colorNum);
    const parsedColor = parseInt(shade, 10);
    if (isNaN(parsedColor)) {
      return;
    }

    const newRgb = { ...rgb, [prop]: parsedColor };

    if (parsedColor > 0 && parsedColor <= 255) {
      onChange(rgbToHex(newRgb));
    }

    setRgb(newRgb);
  };

  const handleRgbBlur = () => {
    setRgb(hexToRgb(color));
  };

  return (
    <div className="grid gap-2 ">
      <div className="font-bold">{label}</div>
      <div className="flex items-center gap-2">
        <IconButton
          size="large"
          aria-label="pick color"
          sx={{ backgroundColor: color, border: "1px solid #cdcdcd" }}
          onClick={(evt) => {
            evt.stopPropagation();
            setPickerOpen(!pickerOpen);
          }}
        />
        <Button variant="text" onClick={handleResetDefault}>
          <T keyName="generic.reset_default" />
        </Button>
        <BaseModal
          className="flex flex-col items-center space-y-4"
          modalState={[pickerOpen, setPickerOpen]}
          closeEvent={() => setPickerOpen(false)}>
          <HexColorPicker color={color} onChange={onChange} />
          <div className="space-x-2">
            <TextField
              label="R"
              className="w-16"
              value={rgb.r}
              spellCheck={false}
              onBlur={handleRgbBlur}
              onChange={(evt) => handleRgbChange("r", evt.target.value)}
              inputProps={{ "aria-label": "red shade" }}
            />
            <TextField
              label="G"
              className="w-16"
              value={rgb.g}
              spellCheck={false}
              onBlur={handleRgbBlur}
              onChange={(evt) => handleRgbChange("g", evt.target.value)}
              inputProps={{ "aria-label": "green shade" }}
            />
            <TextField
              label="B"
              className="w-16"
              value={rgb.b}
              spellCheck={false}
              onBlur={handleRgbBlur}
              onChange={(evt) => handleRgbChange("b", evt.target.value)}
              inputProps={{ "aria-label": "blue shade" }}
            />
          </div>
          <div>
            <TextField
              label="Hex"
              value={color}
              className="w-full"
              spellCheck={false}
              onChange={(evt) => onChange(evt.target.value)}
              inputProps={{ "aria-label": "hex color" }}
            />
          </div>
        </BaseModal>
      </div>
    </div>
  );
}

export default ColorPicker;
