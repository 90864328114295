import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { api } from "services";
import { handleError } from "services/api/error";
import tolgee from "services/translation";
import { notify } from "store/app";
import { ClosedAsEnum } from "types/dsr/main";
import BaseModal from "components/DataDisplay/Modal/BaseModal";
import ForwardEmailCasesModal from "pages/Client/EmailCases/Components/Modals/ForwardEmailCasesModal";

import type { CaseDetail } from "types/case";

type Props = {
  currentEmailCase: CaseDetail;
};
export default function EmailCasesMenu(props: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentEmailCase } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [showForwardCasesModal, setShowForwardCasesModal] = useState<boolean>(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClarifyAction = async () => {
    try {
      await api.authCase.notUnderstood(currentEmailCase.uuid);
    } catch (e) {
      handleError(e);
      return;
    }

    navigate("/email/cases");
  };

  const handleSpamClose = async () => {
    const declineData = {
      reason: {
        key: "dsr.decline_reason_1",
      },
      comment: "",
      closed_as: ClosedAsEnum.GENERIC_SPAM,
    };
    try {
      await api.authCase.declineQuickCase(currentEmailCase.uuid, declineData);

      dispatch(
        notify({
          message: tolgee.t({
            key: "dsr.decline_success",
          }),
          type: "SUCCESS",
        })
      );
      navigate("/email/cases");
    } catch (e) {
      handleError(e);
    }
  };

  const handleResidenceRequest = async () => {
    const data = [
      {
        question_i18n: {
          key: "identification_1",
        },
        upload_required: true,
      },
    ];

    try {
      await api.authCase.createInquiries(currentEmailCase.uuid, data);
    } catch (e) {
      handleError(e);
      return;
    }
    dispatch(
      notify({
        message: tolgee.t({
          key: "dsr.inquiry_successfull",
        }),
        type: "SUCCESS",
      })
    );
  };

  const handleForwardCasesAction = () => {
    setShowForwardCasesModal(true);
  };

  return (
    <div>
      <IconButton className="w-12 h-12" onClick={handleClick}>
        <FontAwesomeIcon icon="ellipsis-v" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <MenuItem onClick={() => handleClarifyAction()}>
          {tolgee.t("email_cases_overview.clarify_title")}
        </MenuItem>
        <MenuItem onClick={() => handleSpamClose()}>
          {tolgee.t("email_cases_overview.spam_title")}
        </MenuItem>
        <MenuItem onClick={() => handleResidenceRequest()}>
          {tolgee.t("email_cases_overview.residence_title")}
        </MenuItem>
        <MenuItem onClick={() => handleForwardCasesAction()}>
          {tolgee.t("email_cases_overview.wrong_email_title")}
        </MenuItem>
      </Menu>

      <BaseModal modalState={[showForwardCasesModal, setShowForwardCasesModal]}>
        <ForwardEmailCasesModal
          caseUuid={currentEmailCase.uuid}
          setShowForwardCasesModal={setShowForwardCasesModal}
        />
      </BaseModal>
    </div>
  );
}
