import api from "services/api/base";
import { fileToFileObject } from "helpers";

import type { BusinessFileEnum } from "types/business";
import type { AxiosResponse } from "axios";
import type { Contact } from "types/contactDirectory";
import type {
  CaseDetail,
  CaseType,
  Reason,
  Inquiry,
  FinalizeCase,
  InternalComment,
  ExtendDeadline,
  CasesResult,
  CheckData,
  CheckName,
  Comment,
  CaseEmailInterface,
} from "types/case";

export async function getCases(
  caseType: CaseType,
  active: boolean,
  orderBy: string,
  page: number,
  perPage: number,
  desc: boolean,
  searchTerm: string,
  businessId?: string
): Promise<AxiosResponse<CasesResult>> {
  return api.get(`/v1/cases`, {
    params: {
      case_type: caseType,
      business_id: businessId,
      order_by: orderBy,
      page,
      per_page: perPage,
      desc,
      search_term: searchTerm,
      active,
    },
  });
}

export async function getCase(caseUuid: string): Promise<AxiosResponse<CaseDetail>> {
  return api.get("/v1/case", {
    params: { case_uuid: caseUuid },
  });
}

export async function createTestCase(
  businessId: string,
  caseType: CaseType,
  article: number
): Promise<AxiosResponse<CaseDetail>> {
  return api.post(
    "/v1/case/test",
    { case_type: caseType, article },
    {
      params: { business_id: businessId },
    }
  );
}

export async function uploadFileToCase(
  caseUuid: string,
  files: File[],
  type_: BusinessFileEnum
): Promise<AxiosResponse<unknown>> {
  const fileObjects = await Promise.all(files.map((file) => fileToFileObject(file)));
  return api.post("/v1/file/case", fileObjects, {
    params: { case_uuid: caseUuid, type_ },
  });
}

export async function removeFileFromCase(
  caseUuid: string,
  fileUuid: string
): Promise<AxiosResponse<unknown>> {
  return api.delete("/v1/file/case", {
    params: { case_uuid: caseUuid, file_uuid: fileUuid },
  });
}

export async function downloadUploadedCaseFile(
  caseUuid: string,
  fileUuid: string
): Promise<AxiosResponse<string>> {
  return api.get("/v1/file/case", {
    params: { case_uuid: caseUuid, file_uuid: fileUuid },
  });
}

export async function updateCaseSender(
  caseUuid: string,
  replyData: Contact
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/authority/case/change-sender", replyData, {
    params: { case_uuid: caseUuid },
  });
}

export async function updateCaseSenderWithContactUuid(
  caseUuid: string,
  contactUuid: string
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/authority/case/change-sender", null, {
    params: { case_uuid: caseUuid, contact_uuid: contactUuid },
  });
}

export async function closeCase(caseUuid: string): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/case/close", null, {
    params: { case_uuid: caseUuid },
  });
}

export async function reopenCase(caseUuid: string): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/case/reopen", null, {
    params: { case_uuid: caseUuid },
  });
}

export async function closeCheck(
  caseUuid: string,
  data: CheckData
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/case/close-check", data, {
    params: { case_uuid: caseUuid },
  });
}

export async function reopenCheck(
  caseUuid: string,
  check_name: CheckName
): Promise<AxiosResponse<unknown>> {
  return api.put(
    "/v1/case/open-check",
    { check_name },
    {
      params: { case_uuid: caseUuid },
    }
  );
}

export async function createInquiries(
  caseUuid: string,
  data: any
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/case/inquiries", data, {
    params: { case_uuid: caseUuid },
  });
}

export async function declineQuickCase(
  caseUuid: string,
  reason: Reason
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/case/quick-decline", reason, {
    params: { case_uuid: caseUuid },
  });
}

export async function declineCase(
  caseUuid: string,
  data: FinalizeCase
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/case/decline", data, {
    params: { case_uuid: caseUuid },
  });
}

export async function openInquiries(
  caseUuid: string,
  businessId: string | number
): Promise<AxiosResponse<Inquiry[]>> {
  return api.get("/v1/case/inquiries/open", {
    params: { case_uuid: caseUuid, business_id: businessId },
  });
}

export async function answerInquiries(
  caseUuid: string,
  replyData: Inquiry[]
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/case/inquiries", replyData, {
    params: { case_uuid: caseUuid },
  });
}

export async function extendDeadline(
  caseUuid: string,
  replyData: ExtendDeadline
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/case/extend/deadline", replyData, {
    params: { case_uuid: caseUuid },
  });
}

export async function finalizeCase(
  caseUuid: string,
  replyData: FinalizeCase
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/case/finalize", replyData, {
    params: { case_uuid: caseUuid },
  });
}

export async function internalComment(
  caseUuid: string,
  data: InternalComment
): Promise<AxiosResponse<unknown>> {
  return api.post("/v1/case/internal-comment", data, {
    params: { case_uuid: caseUuid },
  });
}

export async function comment(caseUuid: string, data: Comment): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/dsr/comment", data, {
    params: { case_uuid: caseUuid },
  });
}

export async function notUnderstood(caseUuid: string): Promise<AxiosResponse<unknown>> {
  return api.put(
    "/v1/dsr/not-understood",
    {},
    {
      params: { case_uuid: caseUuid },
    }
  );
}

export async function closeAsProcessor(
  caseUuid: string,
  contactId: string
): Promise<AxiosResponse<unknown>> {
  return api.put(
    "/v1/dsr/close-as-processor",
    { contact_id: contactId },
    {
      params: { case_uuid: caseUuid },
    }
  );
}

export async function markCaseAsRead(caseUuid: string): Promise<AxiosResponse<unknown>> {
  return api.get("/v1/case/touch", {
    params: { case_uuid: caseUuid },
  });
}

export async function getCaseEmails(
  caseUuid: string
): Promise<AxiosResponse<CaseEmailInterface[]>> {
  return api.get("/v1/case/emails", {
    params: { case_uuid: caseUuid },
  });
}

export async function putDsrCorrectExemptions(
  caseUuid: string,
  data: any
): Promise<AxiosResponse<unknown>> {
  return api.put("/v1/dsr/correct-exemptions", data, {
    params: { case_uuid: caseUuid },
  });
}
