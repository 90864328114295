import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { ToggleButtonGroup, ToggleButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router";

import { dataGridConfig } from "helpers/dataGridConfig";
import { handleError } from "services/api/error";
import { useDebounce } from "helpers/hooks";
import { BaseIconButton, LinkButton, SearchField } from "components";
import { api } from "services";
import { getTimeDifferenceString } from "helpers/date";
import { getBeautyDate, getBeautyDateTime, getUrl } from "helpers";
import tolgee from "services/translation";
import { PRIGHTER_BLUE } from "const/color";
import { InvoiceStatus } from "types/billing";
import { useAppDispatch, useAppSelector } from "store";
import { changePreference } from "store/app";
import { updateUrlParams } from "helpers/url";

import type { PaginationRequest } from "types/general";

export default function BillingInvoices() {
  const { invoicesRows = 25 } = useAppSelector((state) => state.app.preferences);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [orderedBy, setOrderedBy] = useState<string>("created_at");
  const [descOrder, setDescOrder] = useState<boolean>(true);
  const [search, setSearch] = useState("");
  const debouncedSearchValue = useDebounce<string>(search, 500);
  const [filter, setFilter] = useState<InvoiceStatus | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleUrlUpdate = useCallback(
    (params: Record<string, string>) => {
      updateUrlParams(params, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleFilter = (_: React.MouseEvent<HTMLElement>, newFilter: InvoiceStatus | null) => {
    setFilter(newFilter);
    handleUrlUpdate({ filter: newFilter || "" });
  };

  const renderCreatedAtCell = (params: any) => (
    <Tooltip
      title={<div>{getTimeDifferenceString(params?.row?.created_at)} ago</div>}
      placement="top">
      <div>{getBeautyDate(params?.row?.created_at)}</div>
    </Tooltip>
  );

  const renderNumberCell = (params: any) => (
    <a
      href={getUrl("FLASK", `/my/invoice/${params?.row?.number}`)}
      target="_blank"
      rel="noreferrer">
      {params?.row?.number}
    </a>
  );

  const renderAmountCell = (params: any) => (
    <div>
      {params?.row?.amount ? (
        <>
          {Number(params?.row?.amount) / 100} {params?.row?.currency}
        </>
      ) : (
        ""
      )}
    </div>
  );

  const renderFxAmountCell = (params: any) => (
    <div>
      {params?.row?.fx_amount ? (
        <>
          {Number(params?.row?.fx_amount) / 100} {params?.row?.fx_currency}
        </>
      ) : (
        ""
      )}
    </div>
  );

  const renderVoucherPercentOffCell = (params: any) => (
    <div>{Number(params?.row?.voucher_percent_off) / 100}</div>
  );

  const renderPaidAtCell = (params: any) => <div>{getBeautyDateTime(params?.row?.paid_at)}</div>;

  const renderActionButtonsCell = (params: any) => (
    <div className="space-x-2 flex">
      <LinkButton
        color="NONE"
        href={getUrl("FLASK", `/administration/customer/${params?.row.user_uuid}/invoices`)}>
        <div className="w-3 h-3 flex items-center justify-center">
          <FontAwesomeIcon size="lg" icon="file-invoice-dollar" color={PRIGHTER_BLUE} />
        </div>
      </LinkButton>
      <LinkButton
        color="NONE"
        href={getUrl("FLASK", `/administration/manage_customer/${params?.row.user_uuid}`)}>
        <div className="w-3 h-3 flex items-center justify-center">
          <FontAwesomeIcon size="lg" icon="user" color={PRIGHTER_BLUE} />
        </div>
      </LinkButton>
    </div>
  );

  const getColumns = useCallback(
    () => [
      {
        flex: 1,
        field: "created_at",
        headerName: tolgee.t({ key: "generic.created_at" }),
        renderCell: renderCreatedAtCell,
      },
      {
        flex: 2,
        field: "company_name",
        headerName: tolgee.t({ key: "generic.company_name" }),
      },
      {
        flex: 2,
        field: "number",
        headerName: tolgee.t({ key: "generic.invoice_number" }),
        renderCell: renderNumberCell,
      },
      {
        flex: 1,
        field: "amount",
        headerName: tolgee.t({ key: "generic.amount" }),
        renderCell: renderAmountCell,
      },
      {
        flex: 1,
        field: "fx_amount",
        headerName: tolgee.t({ key: "generic.fx_amount" }),
        renderCell: renderFxAmountCell,
      },
      {
        flex: 1,
        field: "voucher_percent_off",
        headerName: tolgee.t({ key: "generic.voucher_percent_off" }),
        renderCell: renderVoucherPercentOffCell,
      },
      {
        flex: 1,
        field: "paid_at",
        headerName: tolgee.t({ key: "generic.paid_at" }),
        renderCell: renderPaidAtCell,
      },
      {
        flex: 1,
        field: "status",
        headerName: tolgee.t({ key: "generic.status" }),
      },
      {
        flex: 1,
        field: "buttons",
        headerName: "",
        sortable: false,
        renderCell: renderActionButtonsCell,
      },
    ],
    []
  );

  const fetchInvoices = (params: PaginationRequest) => {
    const { status, order_by, page, per_page, search_term, desc } = params;
    return api.billing.getInvoicesList({
      status,
      order_by,
      page: page + 1,
      per_page,
      search_term,
      desc,
    });
  };

  const fetchData = useCallback(
    async (props: PaginationRequest) => {
      try {
        const res = await fetchInvoices(props);
        const { count, result } = res.data;

        const tableData = {
          ...dataGridConfig({ currentPage, rowsPerPage: invoicesRows, count }),
          onSortModelChange: (sortVal: any) => {
            if (sortVal?.length) {
              const { field } = sortVal[0];
              const { sort } = sortVal[0];
              setOrderedBy(field);
              setDescOrder(sort === "desc");
            }
          },
          onPaginationModelChange: (val: any) => {
            setCurrentPage(val.page);
            dispatch(changePreference({ invoicesRows: val.pageSize }));
          },
          columns: getColumns(),
          rows: result?.map((i: any) => ({ ...i, id: i?.uuid })) || [],
        };

        setData(tableData);
      } catch (e) {
        handleError(e);
      }
    },
    [currentPage, invoicesRows, dispatch, getColumns]
  );

  useEffect(() => {
    fetchData({
      status: filter,
      order_by: orderedBy,
      page: currentPage,
      per_page: invoicesRows,
      search_term: debouncedSearchValue,
      desc: descOrder,
    });
  }, [filter, orderedBy, currentPage, invoicesRows, descOrder, debouncedSearchValue, fetchData]);

  useEffect(() => {
    if (debouncedSearchValue !== searchParams.get("search")) {
      handleUrlUpdate({ search: debouncedSearchValue });
    }
  }, [debouncedSearchValue, handleUrlUpdate, searchParams]);

  return (
    <>
      <div className="flex flex-row items-center gap-4">
        <BaseIconButton
          onClick={() =>
            fetchData({
              status: filter,
              order_by: orderedBy,
              page: currentPage,
              per_page: invoicesRows,
              search_term: debouncedSearchValue,
              desc: descOrder,
            })
          }>
          <div className="w-4 h-4 flex items-center justify-center">
            <FontAwesomeIcon icon="refresh" color="gray" />
          </div>
        </BaseIconButton>
        <SearchField className="w-full" searchState={[search, setSearch]} />
        <div className="space-x-2 flex items-center">
          <ToggleButtonGroup exclusive className="h-14" value={filter} onChange={handleFilter}>
            <ToggleButton value={null}>{tolgee.t("invoices.filter.all")}</ToggleButton>
            <ToggleButton value={InvoiceStatus.OPEN}>
              {tolgee.t("invoices.filter.open")}
            </ToggleButton>
            <ToggleButton value={InvoiceStatus.PAID}>
              {tolgee.t("invoices.filter.paid")}
            </ToggleButton>
            <ToggleButton value={InvoiceStatus.UNCOLLECTIBLE}>
              {tolgee.t("invoices.filter.uncollectable")}
            </ToggleButton>
            <ToggleButton value={InvoiceStatus.VOID}>
              {tolgee.t("invoices.filter.void")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      {data ? <DataGrid {...data} /> : ""}
    </>
  );
}
