import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";

import { BaseButton } from "components";
import { generateI18nKey, isCheckFinished } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import tolgee from "services/translation";
import { finalizeCheck, reopenCheck } from "store/thunks";
import DeclineWithPreviewButton from "pages/Client/Case/DSR/ClientView/DeclineWithPreviewButton";
import { DeclineTypeEnum } from "types/case";

export default function CorrectDelete() {
  const [state, dispatch] = useCase();
  const caseData = state.case;
  const requestClosed = state.case.state === "CLOSED";
  const isFinished = isCheckFinished(state);

  const handleSendInquiry = () => {
    dispatch({
      type: "SEND_INQUIRY",
      payload: {
        description: tolgee.t("dsr.correct_delete.inquiry_description"),
      },
    });
  };

  return (
    <>
      <div className="mb-4">
        {tolgee.t({
          key: generateI18nKey(caseData.type, state.ui.check, "description"),
        })}
      </div>
      <div className="flex justify-end space-x-2">
        {isFinished && !requestClosed && (
          <Button
            onClick={() => reopenCheck()(dispatch, caseData.uuid, state.ui.check)}
            variant="outlined"
            endIcon={<FontAwesomeIcon icon="lock-open" className="!text-sm" />}>
            {tolgee.t({
              key: "general.reopen",
            })}
          </Button>
        )}

        <div className="flex space-x-2">
          <Button onClick={handleSendInquiry} disabled={isFinished}>
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "ask_ds_for_deletion"),
            })}
          </Button>
          <DeclineWithPreviewButton
            disabled={isFinished}
            buttonTestId="correctDelete_delete"
            modalButtonTestId="correctDelete_modalButtonDelete"
            label={generateI18nKey(caseData.type, state.ui.check, "delete")}
            labelSubmit={generateI18nKey(caseData.type, state.ui.check, "delete")}
            declineType={DeclineTypeEnum.CORRECT_DELETED}
          />
          <BaseButton
            testid="correctDelete_confirm"
            disabled={isFinished}
            color="success"
            onClick={() => finalizeCheck()(dispatch, caseData.uuid, state.ui.check, null)}>
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "correct"),
            })}
          </BaseButton>
        </div>
      </div>
    </>
  );
}
