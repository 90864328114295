import { Button, FormControl, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { BaseModal } from "components";
import { useCase } from "pages/Client/Case/CaseContext";
import MarkdownEditor from "components/Input/MarkdownEditor";
import { CommentTypeEnum } from "types/case";
import { handleError } from "services/api/error";

import type { Comment, InternalComment } from "types/case";
import type { UseState } from "types";

type Props = {
  title?: string;
  description?: string;
  internalComment?: boolean;
  modalState: UseState<boolean>;
  handleReload: any;
};

const defaultValues = {
  subject: "",
  text: "",
};

export default function InternalCommentModal(props: Props) {
  const [state] = useCase();
  const dispatch = useDispatch();
  const { modalState, handleReload, title, description, internalComment = false } = props;
  const [open, setOpen] = modalState;

  const { handleSubmit, register, reset, watch, setValue } = useForm({
    defaultValues,
  });

  const textData = watch("text");

  const onEditorStateChange = (editorState: any) => {
    setValue("text", editorState, { shouldDirty: true });
  };

  const onSubmit = async (data: InternalComment | Comment) => {
    try {
      if (internalComment) {
        await api.authCase.internalComment(state.case.uuid, data as InternalComment);
      } else {
        await api.authCase.comment(state.case.uuid, {
          text: data.text,
          type: CommentTypeEnum.THIRD_PARTY_EXPLANATION,
        } as Comment);
      }
    } catch (e) {
      handleError(e);
      return;
    } finally {
      handleReload();
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "dsr.comment_added",
        }),
        type: "SUCCESS",
      })
    );
    reset();
    modalState[1](false);
  };

  return (
    <BaseModal
      modalState={[open, setOpen]}
      className="overflow-auto"
      closeEvent={() => {
        reset();
      }}>
      <div className="space-y-4 w-110">
        <h3 className="text-center mb-4">{title || <T keyName="dsr.internal_comment" />}</h3>
        {description && <div className="mb-4">{description}</div>}
        <FormControl fullWidth className="space-y-4">
          {internalComment && (
            <TextField
              label={tolgee.t({
                key: "dsr.subject_optional",
              })}
              {...register("subject")}
            />
          )}
          <MarkdownEditor onChange={onEditorStateChange} value={textData} />
        </FormControl>
        <div className="flex justify-end pt-2">
          <Button onClick={handleSubmit(onSubmit)} disabled={textData?.length === 0}>
            <T keyName="generic.save" />
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}
