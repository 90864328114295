import { Button, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

import { api } from "services";
import { handleError } from "services/api/error";
import tolgee from "services/translation";

import type { BusinessConfig } from "types/clp";

export default function PublicInformation() {
  const { businessId } = useParams();
  const [configData, setConfigData] = useState<BusinessConfig>();
  const [dsaUrl, setDsaUrl] = useState<string>(null);

  const fetchConfigData = useCallback(async () => {
    try {
      const res = await api.clp.getConfig(businessId);
      setConfigData(res.data);
      setDsaUrl(res.data.dsa_wording_ext_url);
    } catch (e) {
      handleError(e);
    }
  }, [businessId]);

  useEffect(() => {
    fetchConfigData();
  }, [fetchConfigData]);

  const saveDsaUrl = async () => {
    await fetchConfigData();
    const updatedConfig = {
      ...configData,
      dsa_wording_ext_url: dsaUrl,
    };
    await api.clp.updateBusinessConfig(businessId, updatedConfig);
    await fetchConfigData();
  };

  return (
    <div className="box-outerlayout pb-6 pt-10 mb-6">
      <div className="md:p-6 w-full">
        <h2>{tolgee.t("dsa.public_information_placement_title")}</h2>
        <div className="mb-4">{tolgee.t("dsa.public_information_placement_description")}</div>
        <div>
          <TextField
            label={tolgee.t("dsa.public_information_placement_label")}
            className="w-full bg-white"
            value={dsaUrl || ""}
            onChange={(value: any) => setDsaUrl(value.target.value)}
          />
          <div className="flex justify-end mt-6">
            <Button disabled={dsaUrl === configData?.dsa_wording_ext_url} onClick={saveDsaUrl}>
              {tolgee.t("generic.save")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
