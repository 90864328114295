import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "store";
import Redirect from "pages/General/Error/Redirect";
import { fetchUserData } from "store/thunks";

import type { ReactNode } from "react";

export default function AuthService(props: { children: ReactNode }) {
  const { children } = props;
  const dispatch = useAppDispatch();
  const { redirect } = useAppSelector((state) => state.app.development);
  const [authRejected, setAuthRejected] = useState(false);

  const renderSwitch = (): ReactNode => {
    if (authRejected && redirect) {
      return <Redirect />;
    }
    return children;
  };

  useEffect(() => {
    async function getUser() {
      if (!(await dispatch(fetchUserData()))) {
        setAuthRejected(true);
      }
    }

    getUser();
  }, [dispatch]);

  return <>{renderSwitch()}</>;
}
