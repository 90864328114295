import { Alert, FormControlLabel, Switch } from "@mui/material";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";

import tolgee from "services/translation";
import { useAppSelector } from "store";

interface Props {
  businessId: string;
  isDisabled?: boolean;
  isChecked: boolean;
  isErrorLabel?: boolean;
  handleEnabled: any;
}

export default function PrivacyPolicyDsr({
  businessId,
  isDisabled,
  isChecked,
  isErrorLabel,
  handleEnabled,
}: Props) {
  const { managed_businesses } = useAppSelector((state) => state.user);
  const [isPrivacyPolicyExists, setIsPrivacyPolicyExists] = useState<boolean>();

  useEffect(() => {
    const value = !!managed_businesses?.find((item) => item.public_id === businessId)?.config
      ?.privacy_policy_ext_url;
    setIsPrivacyPolicyExists(value);
  }, [managed_businesses, businessId]);

  return (
    <div>
      <h3 className="mb-2 mt-0">
        <T keyName="dsr.settings.ccpa.privacy_policy.title" />
      </h3>
      {!isPrivacyPolicyExists && (
        <Alert severity={isErrorLabel ? "error" : "warning"} className="mb-2 mt-0">
          {tolgee.t(
            isErrorLabel
              ? "dsr.settings.ccpa.privacy_policy.errorLabel"
              : "dsr.settings.ccpa.privacy_policy.warningLabel"
          )}
        </Alert>
      )}
      <hr className="h-px my-4 bg-gray-200 border-0" />
      <div className="mb-4">
        <FormControlLabel
          disabled={isDisabled}
          control={
            <Switch
              data-testid="privacyPolicy_switch"
              checked={isChecked}
              onChange={(event) => handleEnabled(event.target.checked)}
            />
          }
          label={
            isChecked ? (
              <T keyName="dsr.settings.ccpa.privacy_policy_switch_active" />
            ) : (
              <T keyName="dsr.settings.ccpa.privacy_policy_switch_disabled" />
            )
          }
        />
      </div>
    </div>
  );
}
