import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";

import { BaseIconButton, FormFileField } from "components";
import { generateI18nKey, generateUrl, isCheckFinished } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import tolgee from "services/translation";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import { api } from "services";
import { notify } from "store/app";
import { getFileIcon, getFileName } from "helpers";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
import { handleError } from "services/api/error";
import { BusinessFileEnum } from "types/business";

import type { FileObject } from "types/general";

export default function DSDataUpload() {
  const [state, dispatch] = useCase();
  const appDispatch = useDispatch();
  const requestClosed = state.case.state === "CLOSED";
  const isFinished = isCheckFinished(state);

  const [uploading, setUploading] = useState(false);

  const handleUploadFiles = async (filesToUpload: File[]) => {
    setUploading(true);

    try {
      await api.authCase.uploadFileToCase(state.case.uuid, filesToUpload, BusinessFileEnum.DS_DATA);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setUploading(false);
    }

    appDispatch(notify({ message: "Files uploaded successfully", type: "SUCCESS" }));
    fetchCaseData()(dispatch, state.case.uuid, false);
  };

  const handleRemoveFile = async (file: FileObject) => {
    try {
      await api.authCase.removeFileFromCase(state.case.uuid, file.uuid);
    } catch (e) {
      handleError(e);
      return;
    }

    appDispatch(notify({ message: "File removed successfully", type: "SUCCESS" }));
    fetchCaseData()(dispatch, state.case.uuid, false);
  };

  const handleFileChange = (selectedFiles: File[]): void => {
    if (uploading) {
      return;
    } // Prevent double upload

    if (selectedFiles.length > 0) {
      handleUploadFiles(selectedFiles);
    }
  };

  const handleCloseCheck = () => {
    finalizeCheck()(dispatch, state.case.uuid, state.ui.check);
  };

  const isProceedButtonDisabled = useCallback(() => {
    if (state.case.files.filter((file) => file.type === "DS_DATA").length > 0) {
      return false;
    }
    return true;
  }, [state.case.files]);

  useEffect(() => {
    isProceedButtonDisabled();
  }, [isProceedButtonDisabled]);

  return (
    <div className="flex flex-col items-start space-y-4">
      <span>
        {tolgee.t({
          key: generateI18nKey(state.case.type, state.ui.check, "description"),
        })}
      </span>
      <FormFileField
        disabled={isFinished || requestClosed}
        label="Upload a file"
        className="self-center"
        onChange={handleFileChange}
        clearFilesAfterUpload
        multiple
        required
      />
      <div>
        {state.case.files.filter((file) => file.type === BusinessFileEnum.DS_DATA).length > 0 && (
          <div>
            {tolgee.t({
              key: generateI18nKey(state.case.type, state.ui.check, "uploaded_files"),
            })}
          </div>
        )}
        {state.case.files
          .filter((file) => file.type === BusinessFileEnum.DS_DATA)
          .map((file) => (
            <div className="flex flex-row space-x-2" key={file.uuid}>
              <div className="pt-2.5">
                <BaseIconButton
                  disabled={isFinished || requestClosed}
                  onClick={() => handleRemoveFile(file)}
                  className="w-5 h-5"
                  size="medium">
                  <FontAwesomeIcon size="xs" icon="xmark" color="danger" />
                </BaseIconButton>
              </div>
              <FileDownloadPreview
                icon={getFileIcon(file.mime_type)}
                url={generateUrl(state.case.uuid, file.uuid)}
                fileName={getFileName(file)}
                mimeType={file.mime_type}
              />
            </div>
          ))}
      </div>
      <div className="flex w-full justify-end space-x-2">
        <Button
          onClick={handleCloseCheck}
          data-testid="confirmDataUpload"
          disabled={isFinished || uploading || isProceedButtonDisabled()}>
          {tolgee.t({
            key: generateI18nKey(state.case.type, state.ui.check, "confirm"),
          })}
        </Button>
        {isFinished && !requestClosed && (
          <Button
            onClick={() => reopenCheck()(dispatch, state.case.uuid, state.ui.check)}
            variant="outlined"
            endIcon={<FontAwesomeIcon icon="lock-open" className="!text-sm" />}
            sx={{
              ml: 2,
            }}>
            {tolgee.t({
              key: "general.reopen",
            })}
          </Button>
        )}
      </div>
    </div>
  );
}
