import React from "react";
import { Box, Button, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { T } from "@tolgee/react";

import tolgee from "services/translation";

import type { Position } from "types/clp";

interface LogoPositionSelectorProps {
  position: Position;
  setPosition: (position: Position) => void;
}

const StyledButton = styled(Button)(({ theme }) => ({
  width: "75px",
  height: "75px",
  border: `1px solid ${theme.palette.divider}`,
}));

const DotIndicator = styled("div")(({ theme }) => ({
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
}));

export default function LogoPositionSelector({ position, setPosition }: LogoPositionSelectorProps) {
  const positions: Position[] = [
    "TOP_LEFT",
    "TOP_CENTER",
    "TOP_RIGHT",
    "CENTER_LEFT",
    "" as Position,
    "CENTER_RIGHT",
    "BOTTOM_LEFT",
    "BOTTOM_CENTER",
    "BOTTOM_RIGHT",
  ];

  return (
    <Paper className="w-fit" elevation={0} sx={{ p: 2, bgcolor: "background.default" }}>
      <Box display="grid" className="w-64 h-64" gridTemplateColumns="repeat(3, 1fr)" gap={1}>
        {positions.map((pos) => (
          <StyledButton
            key={pos}
            variant={pos === position ? "contained" : "outlined"}
            onClick={() => pos && setPosition(pos)}
            disabled={!pos}
            className={`${!pos ? "opacity-0" : ""}`}
            aria-label={
              pos ? `Set logo position to ${pos.toLowerCase().replace("_", " ")}` : undefined
            }>
            {pos === position && <DotIndicator />}
          </StyledButton>
        ))}
      </Box>
      <Typography variant="caption" display="block" textAlign="center" mt={1}>
        <T keyName="clp.selected_position" />: {tolgee.t({ key: `clp.${position.toLowerCase()}` })}
      </Typography>
    </Paper>
  );
}
