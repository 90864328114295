/* eslint-disable default-param-last */
import { createSlice } from "@reduxjs/toolkit";

import type { Role, User } from "types/user";
import type { PayloadAction } from "@reduxjs/toolkit";

export const initialState: User = {
  name: null,
  email: null,
  company_representative_name: null,
  company_representative_role: null,
  company_name: null,
  company_authorized_to_sign: null,
  company_address: null,
  company_city: null,
  company_postal_code: null,
  company_country: null,
  demo: null,
  roles: [],
  managed_businesses: [],
  role: null,
  userLoaded: false,
  uuid: null,
  config: {
    light: null,
    email_channel_strict_domain: null,
    no_invoice_reminder_until: null,
    delete_after: null,
    preferred_payment_method_name: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (previous_state = initialState, action: PayloadAction<User>) => {
      const user: User = action.payload;
      let role: Role;
      if (user.roles.some((r) => r.name === "ADMIN")) {
        role = "ADMIN";
      } else if (user.roles.some((r) => r.name === "DEV")) {
        role = "DEV";
      } else if (user.roles.length > 0) {
        role = "client";
      }

      return { ...previous_state, ...action.payload, role, userLoaded: true };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
