import { Outlet } from "react-router";
import { ErrorBoundary } from "@sentry/react";

import { useAppSelector } from "store";
import NotFound from "pages/General/Error/NotFound";
import ServerError from "pages/General/Error/ServerError";
import Redirect from "pages/General/Error/Redirect";
import ErrorFallback from "services/errorHandling/ErrorFallback";
import Forbidden from "pages/General/Error/Forbidden";

import type { ReactNode } from "react";

export default function PublicAuthService() {
  const errorCode = useAppSelector((state) => state.app.error);

  const renderErrorSwitch = (): ReactNode => {
    switch (errorCode) {
      case "404":
        return <NotFound />;
      case "500":
        return <ServerError />;
      case "401":
        return <Redirect />;
      case "403":
        return <Forbidden />;
      default:
        return <Outlet />;
    }
  };

  const fallback = ({ resetError }: any): any => {
    return <ErrorFallback resetError={resetError} />;
  };

  return (
    <div data-testid="public-layout-element">
      <ErrorBoundary fallback={fallback}>{renderErrorSwitch()}</ErrorBoundary>
    </div>
  );
}
