import ConfirmInquiry from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ConfirmInquiry";
import GeoLocation from "pages/Client/Case/DSR/ClientView/Components/GeoLocation";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { generateI18nKey } from "helpers/case";
import { DeclineTypeEnum } from "types/case";

export default function DSLocation() {
  const [state, dispatch] = useCase();
  const caseData = state.case;
  const checkStep = state.ui.check;

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div>
          <ConfirmInquiry
            handleSendInquiry={() =>
              dispatch({
                type: "SEND_INQUIRY",
                payload: {
                  questions: ["location_1"],
                },
              })
            }
            declinePreview={DeclineTypeEnum.RESIDENCY}
          />
        </div>
        <div>
          {tolgee.t({
            key: generateI18nKey(caseData.type, checkStep, "description"),
          })}
        </div>

        <GeoLocation
          geoLocation={state.case.extra.ip_geo_location}
          ipAddress={state.case.extra.ip_address}
          physicalAddress={state.case.extra.address}
        />
      </div>
    </>
  );
}
