import { T } from "@tolgee/react";

import { CaseDecisionEnum } from "types/case";

import type { CaseDecision } from "types/case";

type Props = { decisions: CaseDecision[] };

export default function ControllerProcessorTag({ decisions }: Props) {
  const controllerDecision = decisions.find((d) => d.decision.name === CaseDecisionEnum.CONTROLLER)
    ?.decision.name;

  return (
    <div>
      <T keyName="dsr.tag.pre_check_review.controller_processor" /> {controllerDecision}
    </div>
  );
}
