import { useCallback, useEffect, useState } from "react";
import { T } from "@tolgee/react";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { shallowEqual } from "react-redux";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";

import { dataGridConfig } from "helpers/dataGridConfig";
import api from "services/api";
import { getBeautyDateTime } from "helpers";
import { BaseButton, BaseIconButton, SearchField } from "components";
import { stringifyContactNameAndMail } from "helpers/contact";
import { useAppSelector, useAppDispatch } from "store";
import tolgee from "services/translation";
import { useDebounce } from "helpers/hooks";
import { handleError } from "services/api/error";
import { changePreference } from "store/app";
import { updateUrlParams } from "helpers/url";

import type { PaginationRequest } from "types/general";
import type { GridRowParams } from "@mui/x-data-grid";

export default function AuthorityList() {
  const { caseAuthorityRows = 25 } = useAppSelector((state) => state.app.preferences);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page")) || 0);
  const [orderedBy, setOrderedBy] = useState(searchParams.get("orderBy") || "created_at");
  const [newOrder, setNewOrder] = useState(searchParams.get("order") || "desc");
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const { businessId } = useParams();
  const authority_email_alias = useAppSelector(
    (state) =>
      state.user.managed_businesses.find((business) => business.public_id === businessId)
        ?.authority_email_alias,
    shallowEqual
  );
  const navigate = useNavigate();
  const [caseActive, setCaseActive] = useState(searchParams.get("active") !== "false");

  const debouncedSearchValue = useDebounce<string>(search, 500);

  const handleUrlUpdate = useCallback(
    (params: Record<string, string>) => {
      updateUrlParams(params, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  async function createTestCase() {
    let res;
    try {
      res = await api.authCase.createTestCase(businessId, "AUTHORITY_INBOX", null);
    } catch (e) {
      handleError(e);
      return;
    }
    navigate(`/case/${res.data.uuid}`);
  }

  const fetchData = useCallback(
    async (props: PaginationRequest) => {
      setLoading(true);
      const { order_by, page, per_page, order, search_term } = props;
      const desc = order === "desc";

      let res;
      try {
        res = await api.authCase.getCases(
          "AUTHORITY_INBOX",
          caseActive,
          order_by,
          page + 1,
          per_page,
          desc,
          search_term,
          businessId
        );
      } catch (e) {
        handleError(e);
        setLoading(false);
        return;
      }
      res = res.data;

      const tableData = {
        ...dataGridConfig({ currentPage, rowsPerPage: caseAuthorityRows, count: res.count }),
        onSortModelChange: (sortVal: any) => {
          if (sortVal?.length === 0) {
            return;
          }
          const { field } = sortVal[0];
          const { sort } = sortVal[0];
          setOrderedBy(field);
          setNewOrder(sort);
        },
        onPaginationModelChange: (val: any) => {
          setCurrentPage(val.page);
          dispatch(changePreference({ caseAuthorityRows: val.pageSize }));
        },
        columns: [
          {
            headerName: tolgee.t({
              key: "cases.authority",
            }),
            flex: 1,
            field: "ds_name",
            valueGetter: (_: any, params: any) => {
              return stringifyContactNameAndMail(
                params.ds_name,
                params.ds_email,
                params.ds_salutation
              );
            },
          },
          {
            headerName: tolgee.t({
              key: "cases.title",
            }),
            flex: 1,
            field: "title",
          },
          {
            headerName: tolgee.t({
              key: "cases.company_name",
            }),
            flex: 1,
            field: "company_name",
          },
          {
            headerName: tolgee.t({
              key: "cases.state",
            }),
            flex: 1,
            field: "state",
          },
          {
            headerName: tolgee.t({
              key: "generic.created_at",
            }),
            flex: 1,
            field: "created_at",
            valueGetter: (_: any, params: any) => {
              return getBeautyDateTime(params.created_at);
            },
          },
        ],
        rows: res?.result?.map((i) => {
          return { ...i, id: i?.uuid };
        }),
        onRowClick: (params: GridRowParams) => {
          navigate(`/case/${params.row.uuid}`);
        },
      };

      setData(tableData);
      setLoading(false);
    },
    [businessId, caseActive, currentPage, navigate, caseAuthorityRows, dispatch]
  );

  useEffect(() => {
    fetchData({
      order_by: orderedBy,
      page: currentPage,
      per_page: caseAuthorityRows,
      order: newOrder,
      search_term: debouncedSearchValue,
    });
  }, [
    currentPage,
    caseAuthorityRows,
    newOrder,
    orderedBy,
    debouncedSearchValue,
    caseActive,
    fetchData,
  ]);

  useEffect(() => {
    if (debouncedSearchValue !== searchParams.get("search")) {
      handleUrlUpdate({ search: debouncedSearchValue });
    }
  }, [debouncedSearchValue, handleUrlUpdate, searchParams]);

  return (
    <div className="box-outerlayout flex flex-col mx-auto max-w-7xl">
      <div className="flex space-x-2 items-center">
        <BaseIconButton
          className="border-full"
          onClick={() =>
            fetchData({
              order_by: orderedBy,
              page: currentPage,
              per_page: caseAuthorityRows,
              order: newOrder,
              search_term: debouncedSearchValue,
            })
          }>
          <div className="w-4 h-4 flex items-center justify-center">
            <FontAwesomeIcon icon="refresh" color="gray" />
          </div>
        </BaseIconButton>
        <h1>
          <T keyName="cases.authority_cases" />
        </h1>
      </div>
      <div className="flex justify-between items-center">
        {authority_email_alias ? (
          <span>
            <T keyName="cases.incoming_cases_received_via" />
            <a href={`mailto:${authority_email_alias}`}>{authority_email_alias}</a>
          </span>
        ) : (
          <div />
        )}

        <ToggleButtonGroup
          value={caseActive}
          exclusive
          onChange={(_: React.MouseEvent<HTMLElement>, newState: boolean) => {
            if (newState !== null && newState !== undefined) {
              setCaseActive(newState);
              handleUrlUpdate({ active: newState.toString() });
            }
          }}>
          <ToggleButton value>
            <T keyName="cases.active" />
          </ToggleButton>
          <ToggleButton value={false}>
            <T keyName="cases.closed" />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {data ? (
        <div>
          <SearchField className="w-full" searchState={[search, setSearch]} />
          <DataGrid
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
            }}
            {...data}
            loading={loading}
          />
        </div>
      ) : (
        !loading && (
          <div>
            {tolgee.t({
              key: "billing.no_data",
            })}
          </div>
        )
      )}
      {businessId && (
        <div className="self-end mt-8">
          <BaseButton onClick={() => createTestCase()}>
            <T keyName="cases.create_test_case" />
          </BaseButton>
        </div>
      )}
    </div>
  );
}
