import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { T } from "@tolgee/react";
import { debounce } from "lodash";

import DefaultDataPoints from "components/DSRSettings/CCPA/DefaultDataPoints";
import PrivacyPolicyDsr from "components/DSRSettings/CCPA/PrivacyPolicyDsr";
import EnableDocumentUpload from "components/DSRSettings/CCPA/EnableDocumentUpload";
import BetaProgram from "components/DSRSettings/CCPA/BetaProgram";
import { api } from "services";
import tolgee from "services/translation";
import { defaultDataPoints } from "const/ccpa";
import { DefaultPointType } from "types/ccpaSettings";
import PrivacyPolicySnippet from "components/DSRSettings/CCPA/PrivacyPolicySnippet";
import { handleError } from "services/api/error";

import type {
  CCPASettings,
  DefaultPointsInterface,
  DefaultPointItem,
  DefaultPointsOption,
} from "types/ccpaSettings";

export default function CCPAView() {
  const isFirstRender = useRef(true);
  const { businessId } = useParams();
  const [defaultPoints, setDefaultPoints] = useState<DefaultPointsInterface>({
    selectedItems: [],
    options: [],
  });
  const [privacyPolicyDsrEnabled, setPrivacyPolicyDsrEnabled] = useState<boolean>(false);
  const [requiredDocumentsUpload, setRequiredDocumentsUpload] = useState(false);

  const handleBackendCcpaSettings = useCallback(async (data: CCPASettings) => {
    // Default points
    const formattedDefaultPointOptions = defaultDataPoints.map((item) => ({
      key: item,
      value: tolgee.t(`dsr.settings.ccpa.default_data.${item}`),
      selected: false,
    }));
    setDefaultPoints((prevState) => ({
      ...prevState,
      options: formattedDefaultPointOptions,
    }));
    handleDefaultPointsInitialValue(data, formattedDefaultPointOptions);

    // Privacy Policy
    setPrivacyPolicyDsrEnabled(data.send_privacy_policy || false);

    // Document Upload
    setRequiredDocumentsUpload(data.document_upload || false);
  }, []);

  const fetchData = useCallback(async () => {
    let res;
    try {
      res = await api.ccpa.getSettings(businessId);
    } catch (e) {
      handleError(e);
      return;
    }
    handleBackendCcpaSettings(res.data);
  }, [businessId, handleBackendCcpaSettings]);

  useEffect(() => {
    fetchData();
  }, [businessId, fetchData]);

  const putDataToBackend = debounce((data: CCPASettings) => {
    try {
      api.ccpa.updateSettings(businessId, data);
    } catch (e) {
      handleError(e);
    }
  }, 500);

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }

    const payloadData = {
      required_inputs:
        defaultPoints.selectedItems
          .filter((dataItem) => dataItem.type === "required_inputs" && dataItem.value)
          .map((mapItem) => mapItem.key) || [],
      required_inputs_strict:
        defaultPoints.selectedItems
          .filter((dataItem) => dataItem.type === "required_inputs_strict" && dataItem.value)
          .map((mapItem) => mapItem.key) || [],
      additional_inputs:
        defaultPoints.selectedItems
          .filter((dataItem) => dataItem.type === "additional_inputs" && dataItem.value)
          .map((mapItem) => mapItem.key) || [],
      categories_collected: [],
      categories_shared: {},
      categories_disclosed: {},
      sell_share: false,
      disclose: false,
      collect: false,
      document_upload: requiredDocumentsUpload,
      send_privacy_policy: privacyPolicyDsrEnabled,
    } as CCPASettings;

    putDataToBackend(payloadData);
  }, [
    defaultPoints.selectedItems,
    requiredDocumentsUpload,
    privacyPolicyDsrEnabled,
    putDataToBackend,
  ]);

  const handleDefaultPointsInitialValue = (
    data: CCPASettings,
    options: Array<DefaultPointsOption>
  ) => {
    const localOptions = options;
    const requiredInputs = data.required_inputs;
    const requiredInputsStrict = data.required_inputs_strict;
    const additionalInputs = data.additional_inputs;
    const items = [] as Array<DefaultPointItem>;

    for (let i = 0; i < requiredInputs.length; i += 1) {
      const item = {
        id: i,
        key: localOptions.find((formattedItem) => formattedItem.key === requiredInputs[i]).key,
        value: localOptions.find((formattedItem) => formattedItem.key === requiredInputs[i]).value,
        type: DefaultPointType.required_inputs,
      };

      localOptions.find((formattedItem) => formattedItem.key === requiredInputs[i]).selected = true;
      items.push(item);
    }

    if (requiredInputsStrict) {
      for (let i = 0; i < requiredInputsStrict.length; i += 1) {
        const strictItem = {
          id: items.length + 1,
          key: localOptions.find((formattedItem) => formattedItem.key === requiredInputsStrict[i])
            .key,
          value: localOptions.find((item) => item.key === requiredInputsStrict[i]).value,
          type: DefaultPointType.required_inputs_strict,
        };

        localOptions.find((item) => item.key === requiredInputsStrict[0]).selected = true;
        items.push(strictItem);
      }
    }

    if (additionalInputs) {
      for (let i = 0; i < additionalInputs.length; i += 1) {
        const additionalItem = {
          id: items.length + 1,
          key: localOptions.find((formattedItem) => formattedItem.key === additionalInputs[i]).key,
          value: localOptions.find((item) => item.key === additionalInputs[i]).value,
          type: DefaultPointType.additional_inputs,
        };
        localOptions.find((item) => item.key === additionalInputs[i]).selected = true;
        items.push(additionalItem);
      }
    }

    setDefaultPoints((prevState) => ({
      ...prevState,
      selectedItems: items,
      options: localOptions,
    }));
  };

  const handleDefaultPointSelect = (updatedItem: DefaultPointItem, updatedValue: string) => {
    const updatedDataItems = defaultPoints.selectedItems.map((item) =>
      item.id === updatedItem.id
        ? {
            ...item,
            key: defaultPoints.options.find((dataItem) => dataItem.value === updatedValue).key,
            value: updatedValue,
          }
        : item
    );

    const updatedOptions = defaultPoints.options.map((item) => {
      // Update selected one
      if (item.value === updatedValue) {
        return { ...item, selected: !item.selected };
      }

      // Update previously selected one
      if (item.value === updatedItem.value) {
        return { ...item, selected: false };
      }
      // Rest of items just return
      return item;
    });

    setDefaultPoints((prevState) => ({
      ...prevState,
      selectedItems: updatedDataItems,
      options: updatedOptions,
    }));
  };

  const handleAdditionalPointDeletion = (deletionItem: DefaultPointItem) => {
    const updatedItems = [...defaultPoints.selectedItems];
    const itemIndex = updatedItems.findIndex((item) => item.id === deletionItem.id);
    updatedItems.splice(itemIndex, 1);

    const updatedOptions = defaultPoints.options.map((item) => {
      if (item.key === deletionItem.key) {
        return { ...item, selected: !item.selected };
      }

      return item;
    });

    setDefaultPoints((prevState) => ({
      ...prevState,
      selectedItems: updatedItems,
      options: updatedOptions,
    }));
  };

  const addAdditionalEmptyPoint = () => {
    const item = {
      id: defaultPoints.selectedItems.length + 1,
      key: "",
      value: "",
      type: DefaultPointType.additional_inputs,
    };

    const newDataItems = [...defaultPoints.selectedItems];
    newDataItems.push(item);
    setDefaultPoints((prevState) => ({
      ...prevState,
      selectedItems: newDataItems,
    }));
  };

  return (
    <>
      <div className="flex flex-col">
        <div>
          <div className="mt-4 mb-4 box-outerlayout">
            <h2>
              <T keyName="dsr.settings.ccpa.title" />
            </h2>
            <div className="mb-4">
              <T keyName="dsr.settings.ccpa.description" />
            </div>
            <h3 className="mb-4">
              <T keyName="dsr.settings.ccpa.customization_heading" />
            </h3>
            <div className="box-dotted mb-4">
              <div>
                <DefaultDataPoints
                  defaultPointsData={defaultPoints.selectedItems}
                  defaultPointsOptions={defaultPoints.options}
                  handleSelection={handleDefaultPointSelect}
                  handleDeletion={handleAdditionalPointDeletion}
                  addAdditionalPoint={addAdditionalEmptyPoint}
                />
              </div>
              <hr className="h-px my-8 bg-gray-200 border-0" />
              <div>
                <EnableDocumentUpload
                  isEnabled={requiredDocumentsUpload}
                  handleEnabled={(value: boolean) => setRequiredDocumentsUpload(value)}
                />
              </div>
            </div>

            <h3 className="mb-4">
              <T keyName="dsr.settings.ccpa.process_customization_heading" />
            </h3>
            <div className="box-dotted mb-4">
              <div className="mb-4">
                <PrivacyPolicyDsr
                  businessId={businessId}
                  isChecked={privacyPolicyDsrEnabled}
                  handleEnabled={(value: boolean) => {
                    setPrivacyPolicyDsrEnabled(value);
                  }}
                />
              </div>
              <hr className="h-px my-8 bg-gray-200 border-0" />
              <div>
                <PrivacyPolicySnippet />
              </div>
            </div>
          </div>
          <BetaProgram />
        </div>
      </div>
    </>
  );
}
