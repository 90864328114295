import { Button } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";

import ConfirmationModal from "components/DataDisplay/Modal/ConfirmationModal";

type Props = {
  variant?: "contained" | "outlined" | "text";
  color?: "primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning";
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  confirmPopup?: boolean;
  testid?: string;
};

export default function BaseButton(props: Props) {
  const {
    variant = "contained",
    color = "primary",
    disabled = false,
    className,
    onClick = () => {},
    children,
    style,
    confirmPopup,
    testid = "",
  } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (confirmPopup) {
      setOpen(true);
    } else {
      onClick();
    }
  };

  return (
    <>
      <Button
        data-testid={testid}
        variant={variant}
        disabled={disabled}
        color={color}
        style={style}
        className={clsx(className, "w-fit h-fit")}
        onClick={handleClick}>
        {children}
      </Button>
      {confirmPopup && <ConfirmationModal modalState={[open, setOpen]} onYes={onClick} />}
    </>
  );
}
