import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { T } from "@tolgee/react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { api } from "services";
import { LinkButton } from "components";
import { getBeautyDateTime, getUrl } from "helpers";
import { PRIGHTER_BLUE } from "const/color";
import { stringifyAddress } from "helpers/general";
import { useAppSelector } from "store";
import CollapsibleTable from "components/DataDisplay/CollapsibleTable";
import { handleError } from "services/api/error";

import type { Loa } from "types/user";

export default function LOA() {
  const { countries } = useAppSelector((state) => state.app);
  const [config, setConfig] = useState<any>();

  const fetchData = useCallback(async () => {
    let res;
    try {
      res = await api.user.listLoas();
    } catch (e) {
      handleError(e);
      return;
    }
    res = res.data;

    const columns = [
      {
        name: <T keyName="loa.created_at" />,
        field: "created_at",
      },
      {
        name: <T keyName="loa.company_name" />,
        field: "company_name",
      },
      {
        name: <T keyName="loa.public_id" />,
        field: "public_id",
      },
      {
        name: <T keyName="loa.email" />,
        field: "email",
      },
      {
        name: <T keyName="generic.country" />,
        field: "country",
      },
    ];
    const data: any[] = res.map((item: Loa) => {
      const country = countries?.find(
        (i) => i.iso_3166_1_alpha_2 === item?.address?.country?.iso_3166_1_alpha_2
      );
      return {
        created_at: getBeautyDateTime(item.created_at),
        company_name: item.name,
        public_id: item.business_id,
        email: item.user.email,
        country: (
          <div className="flex space-x-2">
            <div>{item.address.country.iso_3166_1_alpha_2}</div>
            <div>{country && country.flag}</div>
          </div>
        ),
        collapsible: getCollapsibleContent(item),
      };
    });
    setConfig({ data, columns });
  }, [countries]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getCollapsibleContent = (row: Loa) => {
    return (
      <>
        {row.pending_subscription && (
          <div>
            <Typography variant="h6" gutterBottom component="div">
              <T keyName="loa.pending_subscription" />
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <T keyName="loa.product_descr" />
                  </TableCell>
                  <TableCell>
                    <T keyName="loa.price" />
                  </TableCell>
                  <TableCell>
                    <T keyName="loa.payment" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={row.pending_subscription.product}>
                  <TableCell>{row.pending_subscription.description}</TableCell>
                  <TableCell component="th" scope="row">
                    {(row.pending_subscription.price / 100).toFixed(2)}
                  </TableCell>
                  <TableCell>{row.pending_subscription.payment_frequency}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
        <div className="flex flex-col space-y-2 my-6">
          <div className="flex space-x-4 items-center">
            <div className="font-bold">
              <T keyName="loa.loa" />
            </div>
            <div>
              <LinkButton className="!p-0" color="NONE" href={row.url}>
                <div className="w-2 h-2 flex items-center justify-center">
                  <FontAwesomeIcon size="lg" icon="file" color={PRIGHTER_BLUE} />
                </div>
              </LinkButton>
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="font-bold">
              <T keyName="loa.name" />
            </div>
            <div>{row.user.name}</div>
          </div>
          <div className="flex space-x-2">
            <div className="font-bold">
              <T keyName="loa.address" />
            </div>
            <div>{stringifyAddress(row.address)}</div>
          </div>
        </div>
        <div className="space-x-2 my-8 flex justify-end">
          <LinkButton
            color="DANGER"
            href={getUrl(
              "FLASK",
              `/administration/company/${row.business_id}/disapprove-loa/${row.product}`
            )}>
            <T keyName="loa.disapprove" />
          </LinkButton>
          <LinkButton
            color="SUCCESS"
            href={getUrl(
              "FLASK",
              `/administration/company/${row.business_id}/loa/${row.product}/approve`
            )}>
            <T keyName="loa.approve" />
          </LinkButton>
          <LinkButton
            color="WARNING"
            href={getUrl(
              "FLASK",
              `/administration/company/${row.business_id}/loa/${row.product}/approve?crm=wrongpackage`
            )}>
            <T keyName="loa.approve_wrong" />
          </LinkButton>
          <LinkButton
            color="WARNING"
            href={getUrl(
              "FLASK",
              `/administration/company/${row.business_id}/loa/${row.product}/approve?crm=specialdeal`
            )}>
            <T keyName="loa.approve_deal" />
          </LinkButton>
        </div>
      </>
    );
  };

  return (
    <div className="mx-auto max-w-7xl">
      <CollapsibleTable {...config} />
    </div>
  );
}
