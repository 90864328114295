import type { DashboardData, ToDo } from "types/user";

export const emptyDashboardData = {
  user_todos: [] as ToDo[],
  business_todos: {},
  stats: {},
  product_status: {},
};

export function getFinishedToDoCount(data: DashboardData): number {
  const finishedUserTodos = data.user_todos.filter((i: ToDo) => i.closed_at).length;
  const finishedBusinessTodos = Object.values(data.business_todos).reduce(
    (acc: number, curr: any) => {
      return acc + curr.filter((i: ToDo) => i.closed_at).length;
    },
    0
  );
  return finishedUserTodos + finishedBusinessTodos;
}

export function getTotalToDoCount(data: DashboardData): number {
  const totalUserTodos = data.user_todos.length;
  const totalBusinessTodos = Object.values(data.business_todos).reduce((acc: number, curr: any) => {
    return acc + curr.length;
  }, 0);
  return totalUserTodos + totalBusinessTodos;
}

export function isThereUnfinishedToDo(data: DashboardData): boolean {
  const isThereUnfinishedUserToDo = data.user_todos.some((todo) => !todo.closed_at);

  const isThereUnfinishedBusinessToDo = Object.values(data.business_todos).some((array) => {
    return array.some((todo) => !todo.closed_at);
  });

  return isThereUnfinishedUserToDo || isThereUnfinishedBusinessToDo;
}
