import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useCallback, useState } from "react";

import { useCase } from "pages/Client/Case/CaseContext";
import AddProcessorField from "pages/Client/Case/DSR/ClientView/Components/AddProcessorField";
import tolgee from "services/translation";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import InternalCommentModal from "pages/Client/Case/DSR/ClientView/Components/InternalCommentModal";
import { generateI18nKey, isCheckFinished } from "helpers/case";
import { BaseButton } from "components";
import { ActingAsEnum } from "types/case";

export default function InformThirdParty() {
  const [state, dispatch] = useCase();
  const caseData = state.case;
  const requestClosed = caseData.state === "CLOSED";
  const isFinished = isCheckFinished(state);
  const [open, setOpen] = useState(false);

  const handleReload = useCallback(
    () => fetchCaseData()(dispatch, caseData.uuid, false),
    [dispatch, caseData]
  );

  const handleNoDecision = () => {
    finalizeCheck()(dispatch, caseData.uuid, state.ui.check, "INFORM_THIRD_PARTY_NO");
  };

  return (
    <>
      <div className="mb-4">
        {tolgee.t({
          key: generateI18nKey(caseData.type, state.ui.check, "description"),
        })}
      </div>
      <div className="flex flex-col space-y-2">
        <div>
          <span className="mb-2">
            {tolgee.t({
              key: generateI18nKey(
                caseData.type,
                state.ui.check,
                "description_add_processor_third_party"
              ),
            })}
          </span>

          <AddProcessorField
            caseActors={caseData.case_actors.filter(
              (i) => i.acting_as === ActingAsEnum.THIRD_PARTY
            )}
            handleReload={handleReload}
            locked={!!isFinished}
            contactType="THIRD_PARTY"
          />
        </div>

        <div className="flex space-x-2 self-end">
          {isFinished && !requestClosed && (
            <Button
              onClick={() => reopenCheck()(dispatch, caseData.uuid, state.ui.check)}
              variant="outlined"
              endIcon={<FontAwesomeIcon icon="lock-open" className="!text-sm" />}
              sx={{
                ml: 2,
              }}>
              {tolgee.t({
                key: "general.reopen",
              })}
            </Button>
          )}

          <BaseButton disabled={isFinished} color="error" onClick={() => setOpen(true)}>
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "inform_third_parties.no"),
            })}
          </BaseButton>

          <BaseButton
            testid="informThirdParty_confirm"
            disabled={isFinished}
            color="success"
            onClick={() =>
              finalizeCheck()(dispatch, caseData.uuid, state.ui.check, "INFORM_THIRD_PARTY_YES")
            }>
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "inform_third_parties.yes"),
            })}
          </BaseButton>
        </div>
      </div>
      <InternalCommentModal
        modalState={[open, setOpen]}
        handleReload={handleNoDecision}
        title={tolgee.t({
          key: generateI18nKey(
            caseData.type,
            state.ui.check,
            "inform_third_parties.do_not_inform.modal_title"
          ),
        })}
        description={
          caseData.type === "DSR_CCPA_OPTOUT"
            ? tolgee.t({
                key: generateI18nKey(caseData.type, state.ui.check, "comment_modal_description"),
              })
            : ""
        }
      />
    </>
  );
}
