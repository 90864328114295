import { T } from "@tolgee/react";
import clsx from "clsx";

import { LEGAL_INFO, PRIVACY_POLICY } from "const/env";

export default function EmptyLandingPage() {
  return (
    <div
      style={{
        backgroundSize: "cover",
        height: "100%",
      }}
      className="relative flex flex-col items-center justify-center min-h-screen px-2 mx-auto sm:px-4">
      <div
        className={clsx(
          "flex flex-col w-full max-w-screen-lg p-6 m-4 shadow-2xl backdrop-blur-sm space-y-6 rounded-lg border pt-0"
        )}>
        <div className="flex mt-6 space-x-1 text-2xl font-bold md:text-4xl">
          <div>
            <T keyName="public_clp.compliance_center_no_business" />
          </div>
        </div>

        <div>
          <T keyName="clp.client_with_no_reps.explanation" />
        </div>

        <div className="flex justify-end">
          <div className="flex space-x-4 font-bold">
            <a href={PRIVACY_POLICY} className="text-black/80">
              <T keyName="public_clp.privacy_policy" />
            </a>

            <a href={LEGAL_INFO} className="text-black/80">
              <T keyName="public_clp.legal" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
