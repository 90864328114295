import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getBeautyDate } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";

import type { CaseEmailVerifiedEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function EmailVerified(props: {
  entityData: CaseEmailVerifiedEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="envelope" color="white" size="lg" />}
      title={entityData.title}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}
    />
  );
}
