import { VerticalDataValue } from "components";
import { getBeautyDateTime } from "helpers";

import type { ManagedUserDetail } from "types/user";

type Props = { user: ManagedUserDetail };

export default function SecurityTab({ user }: Props) {
  return (
    <div>
      <div className="box-outerlayout">
        <h2>Security</h2>
        <VerticalDataValue title="Last Login At" value={getBeautyDateTime(user.last_login_at)} />
        <VerticalDataValue
          title="Current Login At"
          value={getBeautyDateTime(user.current_login_at)}
        />
        <div>
          <h3>Permissions</h3>
        </div>
      </div>
    </div>
  );
}
