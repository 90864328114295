import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BaseTextField } from "components/Input";
import { PRIGHTER_BLUE } from "const/color";
import tolgee from "services/translation";

import type { ContactPoint } from "types/contactDirectory";

type Props = { contactPoints: ContactPoint[]; onChange: (contactPoints: ContactPoint[]) => void };

export default function ContactPointList({ contactPoints = [], onChange }: Props) {
  const handleChange = (contactObj: ContactPoint, newValue: string) => {
    const objInd = contactPoints.findIndex((cp) => cp === contactObj);
    const tempList = [...contactPoints];
    tempList[objInd].value = newValue;
    onChange(tempList);
  };

  const handleRemove = (contactObj: ContactPoint) => {
    const objInd = contactPoints.findIndex((cp) => cp === contactObj);
    const tempList = [...contactPoints];
    tempList.splice(objInd, 1);
    onChange([...tempList]);
  };

  return (
    <div className="flex flex-col space-y-4">
      {contactPoints
        .filter((cp) => cp.type === "PHONE")
        .map((contactPoint, ind) => (
          <div key={`${contactPoint.uuid || ind}-phone`} className="flex items-center space-x-4">
            <FontAwesomeIcon icon="phone" color={PRIGHTER_BLUE} />
            <BaseTextField
              shrink
              value={contactPoint.value}
              onChange={(event) => handleChange(contactPoint, event.target.value)}
              label={tolgee.t({
                key: "contacts.phone",
              })}
              containerClassName="w-full"
            />
            <div className="py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50">
              <IconButton
                data-testid="remove-contacts-button"
                onClick={() => handleRemove(contactPoint)}
                size="large">
                <FontAwesomeIcon icon="trash" className=" text-brand-600" size="xs" />{" "}
              </IconButton>
            </div>
          </div>
        ))}
      {contactPoints
        .filter((cp) => cp.type === "EMAIL")
        .map((contactPoint, ind) => (
          <div key={`${contactPoint.uuid || ind}-email`} className="flex items-center space-x-4">
            <FontAwesomeIcon icon="envelope" color={PRIGHTER_BLUE} />

            <BaseTextField
              shrink
              testid="email-block"
              value={contactPoint.value}
              onChange={(event) => handleChange(contactPoint, event.target.value)}
              label={tolgee.t({
                key: "contacts.email",
              })}
              containerClassName="w-full"
            />
            <div className="py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50">
              <IconButton
                data-testid="remove-contacts-button"
                onClick={() => handleRemove(contactPoint)}
                size="large">
                <FontAwesomeIcon icon="trash" className=" text-brand-600" size="xs" />{" "}
              </IconButton>
            </div>
          </div>
        ))}
      {contactPoints
        .filter((cp) => cp.type === "WEB")
        .map((contactPoint, ind) => (
          <div key={`${contactPoint.uuid || ind}-web`} className="flex items-center space-x-4">
            <FontAwesomeIcon icon="wifi" color={PRIGHTER_BLUE} className="w-4" />
            <BaseTextField
              shrink
              value={contactPoint.value}
              onChange={(event) => handleChange(contactPoint, event.target.value)}
              label={tolgee.t({
                key: "contacts.web",
              })}
              containerClassName="w-full"
            />
            <div className="py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50">
              <IconButton
                data-testid="remove-contacts-button"
                onClick={() => handleRemove(contactPoint)}
                size="large">
                <FontAwesomeIcon icon="trash" className=" text-brand-600" size="xs" />{" "}
              </IconButton>
            </div>
          </div>
        ))}
    </div>
  );
}
