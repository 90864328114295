import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";

import Global from "components/DSRSettings/GlobalView";
import CCPA from "components/DSRSettings/CCPAView";
import tolgee from "services/translation";
import { BaseTabs } from "components/Tabs/Tabs";
import { useAppSelector } from "store";

export default function DSRSettings() {
  const { managed_businesses } = useAppSelector((state) => state.user);
  const { businessId } = useParams();
  const [isPrivacyPolicyExists, setIsPrivacyPolicyExists] = useState<boolean>();

  useEffect(() => {
    const value = !!managed_businesses?.find((item) => item.public_id === businessId)?.config
      ?.privacy_policy_ext_url;
    setIsPrivacyPolicyExists(value);
  }, [managed_businesses, businessId]);

  const tabsData = [
    {
      id: 1,
      label: tolgee.t("dsr.settings.tabs.global"),
      content: <Global />,
      isAvailable: true,
      testId: "globalTab",
    },
    {
      id: 2,
      label: (
        <div className="flex flex-row items-center gap-2">
          {tolgee.t("dsr.settings.tabs.ccpa")}
          {!isPrivacyPolicyExists ? (
            <span className="items-center justify-center px-2 py-1 text-xs font-bold leading-none text-orange-100 rounded-full bg-danger-400">
              <FontAwesomeIcon icon="exclamation-triangle" color="navigation" fixedWidth />
            </span>
          ) : (
            ""
          )}
        </div>
      ),
      content: <CCPA />,
      isAvailable:
        managed_businesses
          ?.find((item) => item.public_id === businessId)
          ?.features?.includes("ccpa") || false,
      testId: "ccpaTab",
    },
  ];

  return (
    <div className="@container mx-auto max-w-7xl">
      <BaseTabs tabsData={tabsData.filter((item) => item.isAvailable)} defaultTab={0} />
    </div>
  );
}
