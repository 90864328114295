import ReactMarkdown from "react-markdown";

import tolgee from "services/translation";

export default function Handling() {
  return (
    <div className="grid gap-1 px-6 mb-3">
      <h2 className="my-0 font-bold">
        {tolgee.t({
          key: "dsr.handling_title",
        })}
      </h2>
      <div className="prose max-w-[100%]">
        <ReactMarkdown>
          {tolgee.t({
            key: "dsr.handling_description",
          })}
        </ReactMarkdown>
      </div>
    </div>
  );
}
