import type { RawDateString } from "types/date";

export type Certificate = {
  product: string;
  image_url: string;
  image_alt: string;
  html: string;
};

export type Business = {
  user: {
    email: string;
    name: string;
    password?: string;
    position: string;
  };
  address: {
    street: string;
    postal: string;
    city: string;
    country_iso: string;
  };
  company_name: string;
  authorised_to_sign: string;
  size: string;
};

export enum BusinessFileEnum {
  AUTHORITY_UPLOAD = "AUTHORITY_UPLOAD",
  REP_DOC_SIGNATURE = "REP_DOC_SIGNATURE",
  CASE_EMAIL_ATTACHMENT = "CASE_EMAIL_ATTACHMENT",
  CERTIFICATE_IMAGE = "CERTIFICATE_IMAGE",
  CLIENT_UPLOAD = "CLIENT_UPLOAD",
  COOKIE_POLICY = "COOKIE_POLICY",
  DSR_FINALIZE = "DSR_FINALIZE",
  DSR_FORM_UPLOAD = "DSR_FORM_UPLOAD",
  DS_DATA = "DS_DATA",
  INQUIRY_UPLOAD = "INQUIRY_UPLOAD",
  LOGO = "LOGO",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  CLP_BACKGROUND_IMAGE = "CLP_BACKGROUND_IMAGE",
  ACCESS_CATEGORIES = "ACCESS_CATEGORIES",
}

export type BusinessFilePrivate =
  | "EU_LOA"
  | "UK_LOA"
  | "NIS_EU_LOA"
  | "NIS_UK_LOA"
  | "SWISS_REP_LOA"
  | "TURKEY_REP_LOA"
  | "CCPA_LOA"
  | "ROPA_CONTROLLER"
  | "ROPA_PROCESSOR"
  | "REP_DOC_SIGNATURE"
  | "TOM";

export type CertificateText = {
  uuid: string;
  public_id: string;
  cert: string;
  issuer: string;
  issued_at: RawDateString;
  valid_until: RawDateString;
  url?: string;
};

export type LoaFileObject = {
  art27: string | null;
  ukrep: string | null;
  swissrep: string | null;
  turkeyrep: string | null;
  niseu: string | null;
  nisuk: string | null;
};
