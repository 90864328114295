import { PRIGHTER_BLUE, PRIGHTER_HIGHLIGHT } from "const/color";

import type { SxProps, Theme } from "@mui/material";

const baseStyle = {
  borderRadius: "12px",
  backgroundColor: "#F7F7F7",
};
export const selectStyle: SxProps<Theme> = {
  ...baseStyle,
  height: "44px",
};

export const multilineStyle: SxProps<Theme> = {
  "& .MuiInputBase-root": { ...baseStyle },
};
export const inputStyle: SxProps<Theme> = {
  "& .MuiInputBase-root": { ...baseStyle, height: "44px", color: PRIGHTER_HIGHLIGHT },
};

export const buttonStyle: SxProps<Theme> = {
  ...baseStyle,
  backgroundColor: PRIGHTER_BLUE,
  height: "40px",
  paddingX: "24px",
  paddingY: "8px",
};
