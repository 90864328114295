import { useCallback, useEffect } from "react";
import { useParams } from "react-router";

import { generateUrl } from "helpers/case";

export default function CCPAInquiries() {
  const { caseUuid, fileUuid } = useParams();

  const downloadFile = useCallback(async () => {
    const alink = document.createElement("a");
    alink.href = generateUrl(caseUuid, fileUuid);
    alink.download = "SamplePDF.pdf";
    alink.click();
  }, [caseUuid, fileUuid]);

  useEffect(() => {
    if (fileUuid && caseUuid) {
      downloadFile();
    }
  }, [downloadFile, caseUuid, fileUuid]);

  return <></>;
}
