import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

import TimeLineDot from "components/PageSpecific/Case/TimeLineDot";
import { getBeautyDate, getFileIcon, getFileName } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import { FileDownloadWithIcon } from "components";
import { generateUrl } from "helpers/case";

import type { EmailCommunicationEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function EmailReceived(props: {
  caseUuid: string;
  entityData: EmailCommunicationEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt, caseUuid } = props;

  let from;
  if (typeof entityData.sender === "string") {
    from = entityData.sender;
  } else {
    from = `${entityData?.sender?.name} <${entityData?.sender?.email}>`;
  }

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="building" color="white" size="lg" />}
      title={entityData.title ? entityData.title : "NO TITLE"}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="w-full p-0 my-4 secondarybox-outerlayout">
        <div className="flex items-center w-full h-12 pl-4 font-bold bg-gray-200">
          From <em className="ml-1 text-center">{from}</em>
        </div>
        <div className="flex flex-row justify-between py-4" style={{ paddingLeft: "40px" }}>
          <div className="flex flex-row">
            <TimeLineDot additionalStyle={{ margin: 0 }}>
              <FontAwesomeIcon icon="building" color="gray" />
            </TimeLineDot>
            <div className="flex flex-col space-y-4" style={{ paddingLeft: "20px" }}>
              <b>{entityData.subject}</b>
              <div className="whitespace-pre-line" style={{ maxWidth: "50rem" }}>
                {entityData.text}
              </div>
            </div>
          </div>
          <div className="flex flex-col pr-2">
            {entityData.files?.length > 0 && (
              <>
                <b>
                  <T keyName="dsr.files" />:
                </b>
                {entityData.files.map((file) => (
                  <FileDownloadWithIcon key={file.uuid} icon={getFileIcon(file.mime_type)}>
                    <a href={generateUrl(caseUuid, file.uuid)}>{getFileName(file)}</a>
                  </FileDownloadWithIcon>
                ))}
              </>
            )}
            <b>
              <T keyName="dsr.recipients" />:
            </b>
            <em>
              {entityData?.recipients?.map((recipient) => recipient)}
              {entityData?.recipient}
            </em>
          </div>
        </div>
      </div>
    </HistoryEntryTemplate>
  );
}
