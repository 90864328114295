import type { CaseContact, CaseTagNameEnum, CollectionTypeEnum, Inquiry } from "types/case";
import type { CaseHistory } from "types/case/history";
import type { RawDateTimeString } from "types/date";
import type { CcpaDsrType } from "types/dsr/ccpa";
import type { Address, Country, FileObject } from "types/general";

export type DSRBusiness = {
  company_name: string;
  public_id: string;
  address: string;
  explanatory_text: string;
  default_jurisdiction: DSRJurisdictionType;
  jurisdictions: DSRJurisdictionType[];
  show_all: boolean;
};

export type DSRCcpaCase = {
  cases: Array<{ uuid: string }>;
  uuid: string;
  type: string;
  source: string;
  ds_password_init: string;
  ext_url?: string;
  updated_at: string;
  created_at: string;
};

export type DSRJurisdictionType = "dsr_uk" | "dsr_eu" | "dsr_cal" | "dsr_fadp";

export type DSRJurisdiction = {
  name_of_request: string;
  jurisdiction_lock_icon_url: string;
  dsr_type: DSRJurisdictionType;
  short_name: "UK" | "EU" | "CH" | "CN" | "California" | "FADP";
};

export type DSRJurisdictionDict = {
  [key in DSRJurisdictionType]: DSRJurisdiction;
};

export type DsrCustomizationFieldType = "TEXTAREA" | "BOOLEANFIELD";

export type DsrCustomizationReplyType = "TEXTAREA" | "TEXTFIELD" | "FILEUPLOAD" | "NO_REPLY";

export type PublicDSRTypes =
  | "AUTHORITY_INBOX"
  | "DSR"
  | "DSR_CCPA_DELETE"
  | "DSR_CCPA_ACCESS"
  | "DSR_CCPA_CORRECT"
  | "DSR_CCPA_OPTOUT"
  | "DSR_CCPA_LIMIT"
  | "DSR_FADP_DELETE"
  | "DSR_FADP_ACCESS"
  | "DSR_FADP_CORRECT"
  | "DSR_FADP_AUTOMATED_DECISION"
  | "DSR_FADP_PROHIBIT_DATA_PROCESSING"
  | "DSR_LEGACY";

export type PublicDSRAddress = {
  street: string | null;
  postal: string | null;
  city: string | null;
  country: any;
};

export type DSRConfig = {
  trade_name: string;
  clp_color_primary: string;
  clp_color_secondary: string;
  clp_text: string;
  logo: string;
  slug: string;
};

export type PublicDSRBusiness = {
  public_id: string;
  company_name: string;
  address: PublicDSRAddress;
  config: DSRConfig;
  portal_url: string;
};

export type DSRPublic = {
  uuid: string;
  created_at: RawDateTimeString;
  updated_at: RawDateTimeString;
  title: string;
  type: PublicDSRTypes;
  state: string;
  email_alias: string;
  ds_email_verified: boolean;
  deadline: RawDateTimeString;
  rights_concerned: string[] | null;
  business: PublicDSRBusiness;
  public_histories: CaseHistory[];
  case_actors: CaseContact[];
  files: FileObject[];
  inquiries_open: Inquiry[];
};

export interface DsrCollectionItem {
  business_public_id: string;
  business_address: BusinessAddress;
  uuid: string;
  company_name: string;
  collection_id: number;
  collection_uuid: string;
  collection_type: CollectionTypeEnum;
  type: CcpaDsrType;
  tags: CaseTagNameEnum[];
  ds_salutation: string;
  ds_name: string;
  ds_email: string;
  sender_salutation?: string;
  sender_name: string;
  inquiries_closed: number;
  inquiries_total: number;
  acting_for: DSRActingFor;
  email: string;
  ext_url: string;
  created_at: RawDateTimeString;
  collection_created_at: RawDateTimeString;
}

export interface BusinessAddress {
  street: string;
  postal: string;
  city: string;
  country: {
    name: string;
    flag: string;
    tld: string;
    iso_3166_1_alpha_2: string;
  };
}

export type DSRCaseActor = {
  name: string;
  email: string;
  acting_for?: DSRActingFor;
  acting_as: DSRActingAs;
  address?: Address & Country;
  phone?: string;
  files?: FileObject[];
};

export type DSRActingFor = "MYSELF" | "AS_PARENT" | "AS_LEGAL_REPRESENTATIVE";

export type DSRActingAs = "DATA_SUBJECT" | "RECIPIENT" | "SENDER" | "PROCESSOR";

export type DsrContactType = "CONTROLLER" | "PROCESSOR" | "THIRD_PARTY";

export interface CaseExemptionItem {
  name: string;
  comment: string;
}

export type DSRFormSteps = "type" | "identification" | "send";

export enum ExemptionGroup {
  EXEMPTIONS_OBLIGATION_CCPA_ACCESS = "EXEMPTIONS_OBLIGATION_CCPA_ACCESS",
  EXEMPTIONS_OBLIGATION_CCPA_ACCESS_CATEGORIES = "EXEMPTIONS_OBLIGATION_CCPA_ACCESS_CATEGORIES",
  EXEMPTIONS_OBLIGATION_CCPA_CORRECT = "EXEMPTIONS_OBLIGATION_CCPA_CORRECT",
  EXEMPTIONS_OBLIGATION_CCPA_DELETE = "EXEMPTIONS_OBLIGATION_CCPA_DELETE",
  EXEMPTIONS_OBLIGATION_CCPA_LIMIT = "EXEMPTIONS_OBLIGATION_CCPA_LIMIT",
  EXEMPTIONS_OBLIGATION_CCPA_OPTOUT = "EXEMPTIONS_OBLIGATION_CCPA_OPTOUT",
  EXEMPTIONS_OPTIONAL_CCPA_ACCESS = "EXEMPTIONS_OPTIONAL_CCPA_ACCESS",
  EXEMPTIONS_OPTIONAL_CCPA_ACCESS_CATEGORIES = "EXEMPTIONS_OPTIONAL_CCPA_ACCESS_CATEGORIES",
  EXEMPTIONS_OPTIONAL_CCPA_CORRECT = "EXEMPTIONS_OPTIONAL_CCPA_CORRECT",
  EXEMPTIONS_OPTIONAL_CCPA_DELETE = "EXEMPTIONS_OPTIONAL_CCPA_DELETE",
  EXEMPTIONS_OPTIONAL_CCPA_LIMIT = "EXEMPTIONS_OPTIONAL_CCPA_LIMIT",
  EXEMPTIONS_OPTIONAL_CCPA_OPTOUT = "EXEMPTIONS_OPTIONAL_CCPA_OPTOUT",
  CCPA_PERSONAL_INFORMATION = "CCPA_PERSONAL_INFORMATION",
}

export interface ExemptionItem {
  uuid: string;
  key: string;
  sort_order: number;
  group: ExemptionGroup;
  comment?: string;
}

export enum ClosedAsEnum {
  DSR_CREATED = "DSR_CREATED",
  DSR_HANDLED_COMPLIED = "DSR_HANDLED_COMPLIED",
  DSR_HANDLED_DECLINED = "DSR_HANDLED_DECLINED",
  DSR_HANDLED_PARTIALLY_COMPLIED = "DSR_HANDLED_PARTIALLY_COMPLIED",
  DSR_IDENTIFICATION_FAILED = "DSR_IDENTIFICATION_FAILED",
  DSR_LOCATION_FAILED = "DSR_LOCATION_FAILED",
  DSR_NOT_PRIVACY_RELATED = "DSR_NOT_PRIVACY_RELATED",
  DSR_PROCESSOR = "DSR_PROCESSOR",
  EMAIL_FORWARDED = "EMAIL_FORWARDED",
  EMAIL_NOT_UNDERSTOOD = "EMAIL_NOT_UNDERSTOOD",
  GENERIC_DUPLICATE = "GENERIC_DUPLICATE",
  GENERIC_SILENTLY = "GENERIC_SILENTLY",
  GENERIC_SPAM = "GENERIC_SPAM",
  GENERIC_TEST = "GENERIC_TEST",
  GENERIC_FRAUD = "GENERIC_FRAUD",
  DSR_CORRECT_DELETE = "DSR_CORRECT_DELETE",
}
