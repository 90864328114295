import type { Position } from "types/clp";
import type { PaginationRequest } from "types/general";
import type { RawDateTimeString } from "types/date";
import type { Feature, ProductKey } from "types/company";

export type UserBase = {
  uuid: string;
  name: string | null;
  email: string;
  company_representative_name: string | null;
  company_representative_role: string | null;
  company_name: string | null;
  company_authorized_to_sign: string | null;
  company_address: string | null;
  company_city: string | null;
  company_postal_code: string | null;
  company_country: string | null;
  demo: boolean;
  roles: UserRoleObject[];
  role?: Role;
  userLoaded: boolean;
  config: UserConfig;
};

export type User = UserBase & { managed_businesses: ManagedBusiness[] };

export type UserConfig = {
  light: boolean;
  email_channel_strict_domain: boolean;
  no_invoice_reminder_until: string;
  delete_after: string;
  preferred_payment_method_name: string;
  note?: string;
};

export type Role = "ADMIN" | "client" | "DEV";

export type UserRoleObject = { name: UserRole; description: string };

export type ConfigBusiness = {
  logo_url: string;
  clp_text: string;
  clp_color_primary: string;
  clp_color_secondary: string;
  trade_name: string;
  slug: string;
  fadp_media_company: boolean;
  position: Position;
  prighter_in_privacy_policy: boolean;
  privacy_policy_ext_url: boolean;
  size: string;
  theme: string;
};

export type ManagedBusiness = {
  features: Feature[];
  authority_email_alias?: string;
  certificates?: any[];
  company_address?: string;
  company_authorized_to_sign?: string;
  company_city?: string;
  company_country?: string;
  company_name: string;
  company_postal_code?: string;
  portal_url?: string;
  demo?: boolean;
  public_id: string;
  slug?: string;
  tradingname?: string;
  config?: ConfigBusiness;
};

export type AuthState = "PENDING" | "FULFILLED" | "REJECTED";

export type DashboardData = {
  stats: { [key: string]: DashboardStats };
  user_todos: ToDo[];
  business_todos: BusinessToDos;
  product_status: { [businessId: string]: ProductSubscriptionStatus[] };
};

export type ProductSubscriptionStatus = {
  product: ProductKey;
  active: boolean;
  loa_status?: LoaStatus;
  purchase_link: string;
};

export type LoaStatus = "APPROVED" | "PROVIDED" | "WAITING";

export type BusinessToDos = { [key: string]: ToDo[] };

export type DashboardStats = {
  landing_page_visits: {
    current_month: number;
    last_month: number;
    all_time: number;
    last_30_days: number;
    last_31_60_days: number;
  };
  open_dsrs: number;
};

export enum TaskPriority {
  CRITICAL = "CRITICAL",
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}
export enum TaskName {
  LINK_PRIVACY_POLICY = "LINK_PRIVACY_POLICY",
  LOCATION_FOR_EU_GDPR_REP = "LOCATION_FOR_EU_GDPR_REP",
  MFA = "MFA",
  PRIGHTER_IN_PRIVACY_POLICY = "PRIGHTER_IN_PRIVACY_POLICY",
  UPLOAD_CERTIFICATES = "UPLOAD_CERTIFICATES",
  UPLOAD_LOGO = "UPLOAD_LOGO",
  UPLOAD_ROPA = "UPLOAD_ROPA",
  VERIFY_EMAIL = "VERIFY_EMAIL",
}
export type ToDo = {
  id: string;
  url: string;
  name: TaskName;
  closed_at: RawDateTimeString;
  priority: TaskPriority;
};

export type UserWithInvalidField = UserBase & { admin_link: string };

export type ManagedUser = {
  active: boolean;
  billing_customclientline: string;
  city: string;
  company_authorized_to_sign: string;
  company_name: string;
  company_representative_role: string;
  company_representative_name: string;
  created_at: RawDateTimeString;
  current_login_at: RawDateTimeString;
  current_login_ip: string;
  demo: boolean;
  email: string;
  invoice_due_in: string;
  invoice_prefix: string;
  is_main_client: boolean;
  iso_3166_1_alpha_2: string;
  last_login_at: RawDateTimeString;
  last_login_ip: string;
  light: boolean;
  loa_reminders_disabled: boolean;
  login_count: string;
  marketing_opt_in: boolean;
  name: string;
  next_invoice_sequence: string;
  postal: string;
  public_ids: string[];
  role_names: UserRole[];
  street: string;
  stripe_customer_id: string;
  updated_at: RawDateTimeString;
  uuid: string;
  product_icons: string[];
};

export enum UserRole {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  DEV = "DEV",
  MANAGER_CUSTOMER = "MANAGER_CUSTOMER",
  MANAGER_DSR = "MANAGER_DSR",
  MANAGER_FIN = "MANAGER_FIN",
  MANAGER_FINANCIALREPORTING = "MANAGER_FINANCIALREPORTING",
  MANAGER_GHOSTLOGIN = "MANAGER_GHOSTLOGIN",
  PARTNER = "PARTNER",
}

export type VatDetails = {
  vattype: string | null;
  number: string | null;
  status: string | null;
  verified_address: string | null;
  verified_name: string | null;
};

export type ManagedUserDetail = User & {
  created_at: RawDateTimeString;
  updated_at: RawDateTimeString | null;

  active: boolean;

  stripe_customer_id: string | null;
  billing_customclientline: string | null;
  invoice_prefix: string;
  invoice_due_in: RawDateTimeString;
  next_invoice_sequence: number | null;
  full_billing_address: string;
  vat: VatDetails | null;

  current_login_at: RawDateTimeString | null;
  last_login_at: RawDateTimeString | null;
  loa_reminders_disabled: boolean;
  marketing_opt_in: boolean;
  address: Address;

  stats: UserStats;
  tag_names: UserTagEnum[];
};

type CaseStat = { all: number; active: number; closed: number };

export type CaseStats = {
  DSR: CaseStat;
  EMAIL: CaseStat;
  AUTHORITY: CaseStat;
  public_id: string;
};

type ClpVisit = {
  public_id: string;
  all_time: number;
  last_month: number;
  last_30_days: number;
  current_month: number;
  last_31_60_days: number;
};

export type UserStats = {
  uuid: string;
  business_public_ids: string[];
  cases_count: CaseStats[];
  dsr_active: number;
  dsr_closed: number;
  dsr_all: number;
  authority_active: number;
  authority_closed: number;
  authority_all: number;
  email_active: number;
  email_closed: number;
  email_all: number;
  invoice_draft: number;
  invoice_open: number;
  invoice_paid: number;
  invoice_uncollectible: number;
  invoice_void: number;
  invoice_all: number;
  clp_visits: ClpVisit[];
  sum_invoices: number;
  sum_payments: number;
};

export type UserLoa = {
  email: string;
  name: string;
};

export type Country = {
  uuid?: string;
  name: string;
  iso_3166_1_alpha_2: string;
  flag?: string;
};

export type Address = {
  uuid?: string;
  street: string;
  postal: string;
  city: string;
  country_iso: string;
  country?: Country;
};

export type PendingSubscription = {
  product: string;
  description: string;
  price: number;
  payment_frequency: string;
  setup_fee: number;
};

export type Loa = {
  created_at: RawDateTimeString;
  business_id: string;
  name: string;
  user: UserLoa;
  address: Address;
  product: string;
  url?: string;
  pending_subscription: PendingSubscription;
};

export type Note = {
  note: string;
};

export type GetUsersRequest = PaginationRequest & {
  user_filter: UserFilter;
};

export enum UserFilter {
  ACTIVE_CUSTOMER = "ACTIVE_CUSTOMER",
  PARTNER = "PARTNER",
  DELETED = "DELETED",
  INTERNAL = "INTERNAL",
  NEW_NO_BUSINESS = "NEW_NO_BUSINESS",
}

export type Subscription = {
  uuid: string;
  public_id: string;
  next_due: RawDateTimeString;
  interval: string;
  amount: number;
  status: string;
  cancel_after?: RawDateTimeString;
  description: string;
};

export enum UserTagEnum {
  CHECK_RESPONSIBILITY = "CHECK_RESPONSIBILITY",
  DEADLINE_EXTENDED = "DEADLINE_EXTENDED",
  EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED",
  EMAIL_VERIFIED = "EMAIL_VERIFIED",
  IDENTIFICATION_PENDING = "IDENTIFICATION_PENDING",
  INSUFFICIENT_INFORMATION = "INSUFFICIENT_INFORMATION",
  PROCESSING = "PROCESSING",
  SUFFICIENT_INFORMATION = "SUFFICIENT_INFORMATION",
  WAITING_FOR_RESPONSE = "WAITING_FOR_RESPONSE",
  COMBO_PRODUCT = "COMBO_PRODUCT",
  TRUST = "TRUST",
  DOC_APPROVED = "DOC_APPROVED",
  PAYMENT = "PAYMENT",
  WAITING_FOR_REP_DOC = "WAITING_FOR_REP_DOC",
}
