import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useParams } from "react-router";
import { T } from "@tolgee/react";

import EmailCasesDefault from "pages/Client/EmailCases/Components/EmailCasesDefault";
import EmailCasesDetails from "pages/Client/EmailCases/Components/EmailCasesDetails";
import EmailCasesList from "pages/Client/EmailCases/Components/EmailCasesList";
import { api } from "services";
import { handleError } from "services/api/error";

import type { BusinessWithCase, CaseDetail } from "types/case";

export default function EmailCases() {
  const [emails, setEmails] = useState<BusinessWithCase[]>([]);
  const { businessId } = useParams();
  const [currentEmailCase, setCurrentEmailCase] = useState<CaseDetail>(null);

  const fetchEmailById = async (uuid: string) => {
    let res;
    try {
      res = await api.authCase.getCase(uuid);
    } catch (e) {
      handleError(e, false, true);
      return;
    }
    setCurrentEmailCase(res.data);
  };

  const [previousEmail, nextEmail] = useMemo(() => {
    const currentIndex = emails.findIndex((email) => email.uuid === currentEmailCase?.uuid);

    let previous = null;
    let next = null;

    if (currentIndex > 0) {
      previous = emails[currentIndex - 1];
    }

    if (currentIndex < emails.length - 1) {
      next = emails[currentIndex + 1];
    }
    return [previous, next];
  }, [emails, currentEmailCase]);

  const fetchCases = useCallback(async () => {
    const isActive = true;
    const orderBy = "uuid";
    const page = 1;
    const per_page = 15;
    const desc = false;
    const searchString = "";

    let res;
    try {
      res = await api.authCase.getCases(
        "DSR_EMAIL",
        isActive,
        orderBy,
        page,
        per_page,
        desc,
        searchString,
        businessId
      );
    } catch (e) {
      handleError(e);
      return;
    }
    setEmails(res.data.result);
  }, [businessId]);

  useEffect(() => {
    fetchCases();
  }, [fetchCases]);

  return (
    <div className="grid h-full gap-12 2xl:grid-cols-2 ">
      <div>
        {currentEmailCase ? (
          <>
            <EmailCasesDetails currentEmailCase={currentEmailCase} />
            <div className="flex items-center justify-between mt-6">
              <Button
                variant="text"
                disabled={!previousEmail}
                onClick={() => fetchEmailById(previousEmail.uuid)}
                className="font-semibold text-[#505C76] gap-3">
                <FontAwesomeIcon icon="chevron-right" className="rotate-180" />
                <T keyName="generic.previous" />
              </Button>
              <Button
                variant="text"
                disabled={!nextEmail}
                onClick={() => fetchEmailById(nextEmail.uuid)}
                className="font-semibold text-[#505C76] gap-3">
                <FontAwesomeIcon icon="chevron-right" />
                <T keyName="generic.next" />
              </Button>
            </div>
          </>
        ) : (
          <EmailCasesDefault />
        )}
      </div>
      <div>
        <EmailCasesList emails={emails} fetchEmailById={fetchEmailById} fetchCases={fetchCases} />
      </div>
    </div>
  );
}
