import { captureException, withScope } from "@sentry/react";

import { store } from "store";
import { notify, setError } from "store/app";
import { DEV_ENV } from "const/env";
import tolgee from "services/translation";

import type { AxiosError } from "axios";

const ERROR_STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
} as const;

const NETWORK_ERROR_CODES = ["ERR_NETWORK", "ETIMEDOUT", "ECONNABORTED"] as const;

interface ErrorNotification {
  type: "ERROR" | "DEFAULT";
  message: string;
  details?: unknown;
  duration?: number;
}

const reportToSentry = (error: AxiosError): void => {
  withScope((scope) => {
    scope.setExtra("request", {
      method: error.config.method?.toUpperCase(),
      url: `${error.config.baseURL}${error.config.url}`,
      headers: error.config.headers,
      data: error.config.data,
      params: error.config.params,
    });

    scope.setExtra("response", {
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      headers: error.response?.headers,
    });

    scope.setExtra("error", {
      message: error.message,
      code: error.code,
      stack: error.stack,
    });

    captureException(error);
  });
};

const handleNetworkError = (): void => {
  store.dispatch(
    notify({
      type: "ERROR",
      message:
        "There seems to be a problem with your internet. Please refresh this page and try again.",
      duration: 5,
    })
  );
};

const handleStatusError = (error: AxiosError, show404: boolean): boolean => {
  const status = error.response?.status;

  if (!status) {
    return true;
  }

  if (show404 && status === ERROR_STATUS.NOT_FOUND) {
    store.dispatch(setError("404"));
    return false;
  }

  switch (status) {
    case ERROR_STATUS.UNAUTHORIZED:
      store.dispatch(setError("401"));
      return false;
    case ERROR_STATUS.FORBIDDEN:
      store.dispatch(setError("403"));
      return false;
    default:
      if (`${status}`.startsWith("5")) {
        store.dispatch(setError("500"));
      }
      return true;
  }
};

const getErrorNotification = (error: AxiosError): ErrorNotification | null => {
  const status = error.response?.status;

  if (`${status}`.startsWith("5")) {
    return {
      type: "ERROR",
      message: `We were not able to process your request (${status} ${error.response?.statusText}).`,
      duration: 10,
    };
  }

  if (DEV_ENV) {
    return {
      type: "ERROR",
      message: `'${error.config.method.toUpperCase()} ${error.config.url}': ${error.message}`,
      details: error.response?.data,
      duration: 5,
    };
  }

  if (status === ERROR_STATUS.UNPROCESSABLE_ENTITY) {
    return {
      type: "DEFAULT",
      message: `Action '${error.config.method.toUpperCase()} ${error.config.url}' failed, please refresh the page`,
      details: error.response?.data,
      duration: 30,
    };
  }

  return {
    type: "ERROR",
    message: tolgee.t({ key: "generic.smth_went_wrong" }),
  };
};

const logError = (error: AxiosError): void => {
  // eslint-disable-next-line no-console
  console.error(
    `There seems to be an error with the following API route: '${error.config.method.toUpperCase()} ${
      error.config.baseURL
    }${error.config.url}'. '${error.message}'`
  );
};

export const handleError = (error: AxiosError, hideNotification = false, show404 = false): void => {
  if (NETWORK_ERROR_CODES.includes(error.code as any)) {
    handleNetworkError();
    return;
  }

  const shouldReportToSentry = handleStatusError(error, show404);

  if (!hideNotification) {
    const notification = getErrorNotification(error);
    if (notification) {
      store.dispatch(notify(notification));
    }
  }

  if (shouldReportToSentry) {
    reportToSentry(error);
  }

  logError(error);
};
