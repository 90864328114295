import OpenInquiries from "pages/Client/CCPAInquiries/components/OpenInquiries";
import tolgee from "services/translation";

export default function CCPAInquiries() {
  return (
    <div className="box-outerlayout pt-5 mb-4 max-w-7xl mx-auto mt-8">
      <h2>
        {tolgee.t({
          key: "dsr.open_inquiries",
        })}
      </h2>
      <OpenInquiries />
    </div>
  );
}
