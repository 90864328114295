/* eslint-disable default-param-last */
import { createSlice } from "@reduxjs/toolkit";

import { DEV_ENV, IS_PROD_DEV } from "const/env";

import type { Country } from "types/general";
import type {
  AppPreferences,
  AppState,
  ErrorCode,
  NotificationPayload,
  PreferenceAction,
} from "types/app";
import type { PayloadAction } from "@reduxjs/toolkit";

export const initialState: AppState = {
  version: null,
  notification: { type: null, message: null, id: 0, duration: 3 },
  error: null,
  countries: [],
  development: { redirect: !(IS_PROD_DEV || DEV_ENV) },
  preferences: {
    createTestCaseIndex: 0,
    navbarState: true,
    manageUsersRows: 25,
    manageBusinessFilesRows: 25,
    specialDealMixRows: 25,
    prighterCasesRows: 25,
    invoicesRows: 25,
    churnedClientsRows: 25,
    partnersRows: 25,
    vouchersRows: 25,
    caseAuthorityRows: 25,
    contactDirectoryRows: 25,
    dsrListRows: 25,
    caseSortIndex: 0,
    selectedBusiness: null,
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    notify: (previous_state = initialState, action: PayloadAction<NotificationPayload>) => ({
      ...previous_state,
      notification: {
        ...previous_state.notification,
        type: action.payload.type,
        message: action.payload.message,
        details: DEV_ENV ? action.payload.details : null,
        duration: action.payload.duration || 3,
        id: previous_state.notification.id + 1,
      },
    }),
    setError: (previous_state = initialState, action: PayloadAction<ErrorCode>) => ({
      ...previous_state,
      error: action.payload,
    }),
    setCountries: (previous_state = initialState, action: PayloadAction<Country[]>) => ({
      ...previous_state,
      countries: action.payload,
    }),
    setDevRedirection: (previous_state = initialState, action: PayloadAction<boolean>) => ({
      ...previous_state,
      development: { ...previous_state.development, redirect: action.payload },
    }),
    toggleNavbar: (previous_state = initialState) => ({
      ...previous_state,
      preferences: {
        ...previous_state.preferences,
        navbarState: !previous_state.preferences.navbarState,
      },
    }),
    syncPreferences: (previous_state = initialState, action: PayloadAction<AppPreferences>) => ({
      ...previous_state,
      preferences: action.payload,
    }),
    changePreference: (previous_state = initialState, action: PayloadAction<PreferenceAction>) => ({
      ...previous_state,
      preferences: { ...previous_state.preferences, ...action.payload },
    }),
    setAppVersion: (previous_state = initialState, action: PayloadAction<string>) => ({
      ...previous_state,
      version: action.payload,
    }),
  },
});

export const {
  notify,
  setError,
  setCountries,
  setDevRedirection,
  toggleNavbar,
  syncPreferences,
  changePreference,
  setAppVersion,
} = appSlice.actions;

export default appSlice.reducer;
