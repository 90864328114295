import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { Step } from "@mui/material";
import { T } from "@tolgee/react";

import { dsrFormSteps } from "const/dsr";

import type { DSRFormSteps } from "types/dsr/main";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { StepIconProps } from "@mui/material/StepIcon";

const icons = ["pencil", "address-card", "paper-plane"];

function ColorlibStepIcon(props: StepIconProps) {
  const { icon } = props;

  return <div>{icon}</div>;
}

type Props = {
  currentStep: DSRFormSteps;
};

export default function CustomizedStepper({ currentStep }: Props) {
  const navigate = useNavigate();
  const { publicId } = useParams();
  const [searchParams] = useSearchParams();

  const currentStepIndex = dsrFormSteps.findIndex((s) => s.key === currentStep);

  const handleChangeStep = (chosenStep: any) => {
    const chosenStepIndex = dsrFormSteps.findIndex((s) => s.key === chosenStep.key);
    if (chosenStepIndex < currentStepIndex) {
      const param = chosenStep.key === "type" ? "" : `/${chosenStep.key}`;
      navigate({ pathname: `/dsr/${publicId}/ccpa${param}`, search: searchParams.toString() });
    }
  };

  return (
    <div className="flex">
      <Stepper
        alternativeLabel
        activeStep={currentStepIndex}
        connector={
          <FontAwesomeIcon icon="angle-right" size="xl" className="text-gray px-4 pt-1" />
        }>
        {dsrFormSteps.map((step, ind) => (
          <Step key={step.key} className="contents">
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              StepIconProps={{
                icon: (
                  <button
                    className={`flex space-x-2 items-center px-3 py-1 border-2 rounded-full ${
                      currentStep === step.key
                        ? "bg-gray-200 border-prighterblue text-prighterblue"
                        : "border-gray text-gray hover:bg-gray-200"
                    }`}
                    type="button"
                    onClick={() => handleChangeStep(step)}>
                    <FontAwesomeIcon
                      className={`mb:mr-2 ${
                        currentStep === step.key ? "text-prighterblue" : "text-gray"
                      }`}
                      icon={icons[ind] as IconProp}
                      size="sm"
                    />
                    <div className="hidden md:flex">
                      <T keyName={`dsr.${step.name}`} />
                    </div>
                  </button>
                ),
              }}
            />
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
