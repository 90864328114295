import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { T } from "@tolgee/react";

import { notify } from "store/app";
import { BaseButton } from "components";
import { api } from "services";
import AddressForm from "components/complex/AddressForm";
import { handleError } from "services/api/error";

import type { Address } from "types/general";
import type { ContactAddress } from "types/contactDirectory";

type Props = {
  address: Address;
  onAddressUpdate: () => unknown;
};

const schema = yup
  .object()
  .shape({
    street: yup.string().required().min(2),
    postal: yup.string().required().min(2),
    country_iso: yup.string().required().min(2),
    city: yup.string().required().min(2),
  })
  .required();

export default function CompanyAddressModal({ address, onAddressUpdate }: Readonly<Props>) {
  const { businessId } = useParams();
  const dispatch = useDispatch();

  const defaultValues = useMemo(
    () => ({
      city: address.city,
      street: address.street,
      country_iso: address.country_iso,
      postal: address.postal,
    }),
    [address]
  );

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const addressState = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const handleAddressChange = (contactAddresses: ContactAddress[]) => {
    reset(contactAddresses[0]);
  };

  const onSubmit = async (values: any) => {
    const newAddress = values;
    try {
      await api.business.updateAddress(businessId, newAddress);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(notify({ message: "Address updated successfully", type: "SUCCESS" }));
    onAddressUpdate();
    handleFormReset();
  };

  const handleFormReset = () => {
    reset();
  };

  return (
    <>
      <h1 className="flex w-full mbs-8">
        <T keyName="billing.company_address" />
      </h1>
      <div className="flex w-full flex-col mb-2">
        <form>
          <AddressForm
            contactAddresses={[addressState]}
            onChange={handleAddressChange}
            shrink
            required
          />

          <div className="flex justify-end space-x-2 mt-8">
            <BaseButton onClick={handleSubmit(onSubmit)} disabled={!isValid}>
              <T keyName="generic.save" />
            </BaseButton>
          </div>
        </form>
      </div>
    </>
  );
}
