import Forbidden from "pages/General/Error/Forbidden";
import { useAppSelector } from "store";

type Props = {
  Component: any;
  allowedRoles: string[];
};

export default function ProtectedElement(props: Props) {
  const { roles } = useAppSelector((state) => state.user);
  const { Component, allowedRoles } = props;

  if (roles.some((role) => allowedRoles.includes(role.name))) {
    return <Component />;
  }
  return <Forbidden />;
}
