import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getBeautyDate, getFileIcon, getFileName } from "helpers";
import HistoryEntryTemplate from "pages/Client/History/HistoryTemplates/HistoryEntryTemplate";
import { FileDownloadWithIcon } from "components";
import tolgee from "services/translation/tolgee";
import { generateUrl } from "helpers/case";

import type { CaseCreatedEntity } from "types/case/history";
import type { RawDateTimeString } from "types/date";

export default function CaseCreated(props: {
  caseUuid: string;
  entityData: CaseCreatedEntity;
  createdAt: RawDateTimeString;
}) {
  const { entityData, createdAt, caseUuid } = props;

  return (
    <HistoryEntryTemplate
      timeLineIcon={<FontAwesomeIcon icon="building" color="white" size="lg" />}
      title={entityData.title ? entityData.title : "NO TITLE"}
      date={getBeautyDate(createdAt)}
      processedAt={entityData.processed_at}>
      <div className="w-full p-0 pb-8 my-4">
        <div className="flex items-center w-full h-12 pl-4 font-bold bg-slate-200">
          {tolgee.t({
            key: "dsr.from",
          })}
          <em className="ml-1 text-center">{`${entityData.ds_info?.name} <${entityData.ds_info?.email}>`}</em>
        </div>
        <div className="flex flex-row justify-between py-4" style={{ paddingLeft: "40px" }}>
          <div className="flex flex-row">
            <div className="flex flex-col pr-2">
              {entityData.files?.length > 0 && (
                <>
                  <b>
                    {tolgee.t({
                      key: "dsr.files",
                    })}
                  </b>
                  {entityData.files.map((file: any) => (
                    <FileDownloadWithIcon key={file.uuid} icon={getFileIcon(file.mime_type)}>
                      <a href={generateUrl(caseUuid, file.uuid)}>{getFileName(file)}</a>
                    </FileDownloadWithIcon>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </HistoryEntryTemplate>
  );
}
