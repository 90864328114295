import { VerticalDataValue } from "components";
import tolgee from "services/translation";

import type { CaseContact } from "types/case";

type Props = { personalData: CaseContact };

export default function DSIdentificationTag({ personalData }: Props) {
  const data = [
    {
      key: "ds_name",
      value: personalData?.contact?.name,
    },
    {
      key: "email_address",
      value: personalData?.contact?.contact_points.find((i: { type: string }) => i.type === "EMAIL")
        ?.value,
    },
    {
      key: "phone_number",
      value: personalData?.contact?.contact_points.find((i: { type: string }) => i.type === "PHONE")
        ?.value,
    },
  ].filter((i) => !!i.value);

  return (
    <div className="flex flex-row justify-between pt-2">
      {data.map((i) => (
        <VerticalDataValue
          key={i.key}
          title={tolgee.t({
            key: `dsr.${i.key}`,
          })}
          value={i.value}
        />
      ))}
    </div>
  );
}
