import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { T } from "@tolgee/react";

import BoldTranslation from "components/General/TranslationsFormatting/BoldTranslation";

import type { CcpaDsrType, CcpaForm, CcpaFormDsr, CcpaFormDsrInquiryTags } from "types/dsr/ccpa";
import type { JSX } from "react";

export type CaseRequest = {
  key: CcpaDsrType;
  question: string;
  value: JSX.Element;
};

export const caseRequests: CaseRequest[] = [
  {
    key: "DSR_CCPA_DELETE",
    question: "The Deletion of my personal information",
    value: (
      <p className="font-normal">
        <BoldTranslation
          translationSource="email_intake"
          translationKey="delete_personal_information"
          boldedParts={["Deletion"]}
        />
      </p>
    ),
  },
  {
    key: "DSR_CCPA_ACCESS",
    question: "Know and Access specific pieces of personal information",
    value: (
      <BoldTranslation
        translationSource="email_intake"
        translationKey="access_specific_personal_information"
        boldedParts={["Know", "Access"]}
      />
    ),
  },
  {
    key: "DSR_CCPA_ACCESS_CATEGORIES",
    question: "Know and Access shared personal information",
    value: (
      <BoldTranslation
        translationSource="email_intake"
        translationKey="access_categories_of_personal_information_sold_shared_disclosed"
        boldedParts={["Know", "Access"]}
      />
    ),
  },
  {
    key: "DSR_CCPA_CORRECT",
    question: "Rectify personal information",
    value: (
      <BoldTranslation
        translationSource="email_intake"
        translationKey="request_rectify_personal_information"
        boldedParts={["Rectify"]}
      />
    ),
  },
  {
    key: "DSR_CCPA_OPTOUT",
    question: "Opt-out of the sale of personal information",
    value: (
      <BoldTranslation
        translationSource="email_intake"
        translationKey="do_not_sell_or_share_personal_information"
        boldedParts={["Sell", "Share"]}
      />
    ),
  },
  {
    key: "DSR_CCPA_LIMIT",
    question: "Limit the use of sensitive personal information",
    value: (
      <BoldTranslation
        translationSource="email_intake"
        translationKey="do_not_sell_or_share_personal_information"
        boldedParts={["Limit", "use"]}
      />
    ),
  },
];

type RequestItemProps = {
  request: CcpaFormDsr;
};

function RequestItem(props: RequestItemProps) {
  const { request } = props;

  const { setValue, watch } = useFormContext<CcpaForm>();

  const dsrs = watch("dsrs") || [];

  const handleChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: CcpaFormDsrInquiryTags
  ) => {
    const requestIndex = dsrs.findIndex((r) => r.type === request.type);
    setValue(`dsrs.${requestIndex}.tags.0`, value);
  };

  return (
    <>
      <div className="w-full text-sm p-3 items-center rounded-[12px] h-12 gap-3 border border-[#549444] bg-[#E6FAE1] flex  ">
        <FontAwesomeIcon icon="check-circle" className="text-success-700" size="lg" />
        {caseRequests.find((r) => r.key === request.type)?.value}
      </div>
      <FormControlLabel
        sx={{
          margin: 0,
        }}
        control={
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={request.tags[0]}
            row
            onChange={handleChange}>
            <FormControlLabel
              value="SUFFICIENT_INFORMATION"
              control={
                <Radio
                  checkedIcon={
                    <FontAwesomeIcon icon="check-circle" className="w-5 h-5 text-prighterblue" />
                  }
                />
              }
              label="Yes"
            />
            <FormControlLabel
              value="INSUFFICIENT_INFORMATION"
              control={
                <Radio
                  checkedIcon={
                    <FontAwesomeIcon icon="check-circle" className="w-5 h-5 text-prighterblue" />
                  }
                />
              }
              label="No"
            />
          </RadioGroup>
        }
        label="Sufficient Information"
        labelPlacement="start"
        id="demo-controlled-radio-buttons-group"
        className="gap-4"
      />
    </>
  );
}
type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

function IdentificationCheck(props: Props) {
  const { setStep } = props;
  const { watch } = useFormContext<CcpaForm>();

  const requests = watch("dsrs") || [];

  return (
    <div className="flex flex-col w-full gap-3 font-normal text-prighterblue">
      <div className="flex flex-col items-start gap-4">
        {requests
          .filter((req) => req.checked)
          .map((request) => (
            <RequestItem request={request} />
          ))}
      </div>
      <Button
        sx={{
          borderRadius: "12px",
        }}
        onClick={() => {
          setStep(1);
        }}
        className="w-full p-3 h-[92px] gap-3 ">
        <FontAwesomeIcon icon="plus" color="white" size="lg" />
        <T keyName="email_intake.add_another_data_subject_request" />
      </Button>
    </div>
  );
}

export default IdentificationCheck;
