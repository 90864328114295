import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import clsx from "clsx";
import { T } from "@tolgee/react";

import { BaseButton, BaseModal, LinkButton } from "components";
import { DANGER, PRIGHTER_BLUE, SUCCESS, WARNING } from "const/color";

import type { Product, ProductStatus } from "types/company";

type Props = {
  product: Product;
  productStatus: ProductStatus;
  productPurchaseLink?: string;
};

export default function DashboardProduct({ product, productStatus, productPurchaseLink }: Props) {
  const [modalState, setModalState] = useState(false);

  return (
    <div
      className={clsx(
        "border rounded-lg p-6 max-w-screen-sm",
        productStatus === "active" && "hover:bg-gray-200 cursor-pointer"
      )}>
      <div className="flex justify-between">
        <div className="h-10 w-10 mb-8">
          <img src={product.image} alt={product.name} />
        </div>
        {productStatus === "active" && (
          <div className="text-gray-300 group-hover:text-gray">
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </div>
        )}
      </div>
      <div className="mt-4">
        <h3 className="font-semibold leading-6">{product.name}</h3>

        <div className="flex space-x-2 items-center">
          {productStatus === "active" && (
            <div data-testid="active-icon">
              <FontAwesomeIcon icon="circle-check" color={SUCCESS.DEFAULT} size="lg" />
              <span className="ml-2">
                <T keyName="rep.status.active_representation" />
              </span>
            </div>
          )}

          {productStatus === "APPROVED" && (
            <>
              <FontAwesomeIcon icon="circle-notch" color={WARNING.DEFAULT} size="lg" />
              <span className="ml-2">
                <T keyName="rep.status.loa_approved" />
              </span>
            </>
          )}

          {productStatus === "PROVIDED" && (
            <>
              <FontAwesomeIcon icon="circle-notch" color={WARNING.DEFAULT} size="lg" />
              <span className="ml-2">
                <T keyName="rep.status.loa_provided" />
              </span>
            </>
          )}

          {productStatus === "WAITING" && (
            <>
              <FontAwesomeIcon icon="circle-notch" color={WARNING.DEFAULT} size="lg" />
              <span className="ml-2">
                <T keyName="rep.status.waiting_for_upload" />
              </span>
            </>
          )}

          {productStatus === null && (
            <div>
              <FontAwesomeIcon icon="triangle-exclamation" color={DANGER.DEFAULT} size="lg" />
              <span className="ml-2">
                <T keyName="rep.status.not_active" />
              </span>

              {!["Prighter Breach"].includes(product.name) && (
                <div className="mt-4 space-x-2 flex">
                  {product.name !== "PrighterDSR Archival" && (
                    <BaseButton color="warning" onClick={() => setModalState(true)}>
                      <span className="font-bold">
                        <T keyName="rep.status.do_we_need_this" />
                      </span>
                    </BaseButton>
                  )}

                  {productPurchaseLink && (
                    <LinkButton href={productPurchaseLink || ""} className="!p-1 !px-3">
                      <FontAwesomeIcon icon="cart-shopping" />
                      <span className="font-bold ml-2">
                        <T keyName="generic.purchase" />
                      </span>
                    </LinkButton>
                  )}
                </div>
              )}
            </div>
          )}

          {productStatus === "not_started" && (
            <>
              <FontAwesomeIcon icon="clock" color={PRIGHTER_BLUE} size="lg" />
              <span>
                <T keyName="rep.status.coming_soon" />
              </span>
              <BaseButton color="warning" onClick={() => setModalState(true)}>
                <span className="font-bold">
                  <T keyName="rep.status.do_we_need_this" />
                </span>
              </BaseButton>
            </>
          )}

          <BaseModal modalState={[modalState, setModalState]} className="p-0 m-0">
            {product.doINeedModal}
          </BaseModal>
        </div>
      </div>
    </div>
  );
}
