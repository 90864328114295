import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";

import EmailCasesListItem from "pages/Client/EmailCases/Components/EmailCasesListItem";

import type { BusinessWithCase } from "types/case";

type Props = {
  emails: BusinessWithCase[];
  fetchEmailById: (uuid: string) => Promise<void>;
  fetchCases: () => Promise<void>;
};

export default function EmailCasesList(props: Props) {
  const { emails, fetchEmailById, fetchCases } = props;

  return (
    <div className="flex flex-col gap-12">
      <div className="flex items-start justify-between font-inter">
        <h1 className="m-0 font-light">
          <T keyName="generic.data_subject_requests" />
        </h1>
      </div>
      <div className="flex items-center justify-between gap-4 px-4">
        <div className="flex items-center gap-4">
          <div className="w-4 h-4 flex items-center justify-center cursor-pointer">
            <FontAwesomeIcon icon="refresh" color="gray" onClick={fetchCases} />
          </div>
          <h3 className="m-0 font-inter">
            <T keyName="generic.data_subject_requests" />
          </h3>
        </div>
      </div>
      <div className="flex flex-col gap-4 px-3">
        {Array.isArray(emails) &&
          emails.map((email) => {
            return (
              <EmailCasesListItem key={email.uuid} email={email} fetchEmailById={fetchEmailById} />
            );
          })}
      </div>
    </div>
  );
}
