import { Button, Checkbox, InputLabel, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import { api } from "services";
import { handleError } from "services/api/error";
import tolgee from "services/translation";
import { useAppDispatch } from "store";
import { notify } from "store/app";

import type { BusinessConfig } from "types/clp";

export default function ConfigTab() {
  const { businessId } = useParams();
  const dispatch = useAppDispatch();
  const { register, reset, watch, handleSubmit } = useForm<BusinessConfig>({
    defaultValues: {
      prighter_in_privacy_policy: false,
      dsr_form: false,
      ds_communication: false,
      compliance_emails: false,
      privacy_policy_ext_url: "",
    },
  });

  const fetchData = useCallback(async () => {
    let res;
    try {
      res = await api.clp.getConfig(businessId);
    } catch (e) {
      handleError(e);
      return;
    }
    reset(res.data);
  }, [businessId, reset]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleUpdateSettings = async (values: BusinessConfig) => {
    try {
      await api.clp.updateBusinessConfig(businessId, values);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "clp.settings_updated",
        }),
        type: "SUCCESS",
      })
    );
    fetchData();
  };

  return (
    <div className="box-outerlayout space-y-2">
      <div className="flex items-center">
        <Checkbox
          color="primary"
          {...register("prighter_in_privacy_policy")}
          checked={watch("prighter_in_privacy_policy")}
        />
        <InputLabel>
          <T keyName="manage_business.prighter_added_to_privacy_policy" />
        </InputLabel>
      </div>

      <TextField
        label={tolgee.t({ key: "manage_business.privacy_policy_url" })}
        {...register("privacy_policy_ext_url")}
        InputLabelProps={{ shrink: true }}
      />

      <div className="flex items-center">
        <Checkbox color="primary" {...register("dsr_form")} checked={watch("dsr_form")} />
        <InputLabel>
          <T keyName="manage_business.enable_dsr_form" />
        </InputLabel>
      </div>

      <div className="flex items-center">
        <Checkbox
          color="primary"
          {...register("ds_communication")}
          checked={watch("ds_communication")}
        />
        <InputLabel>
          <T keyName="manage_business.enable_communication_with_ds" />
        </InputLabel>
      </div>

      <div className="flex items-center">
        <Checkbox
          color="primary"
          {...register("compliance_emails")}
          checked={watch("compliance_emails")}
        />
        <InputLabel>
          <T keyName="manage_business.enable_compliance_emails" />
        </InputLabel>
      </div>

      <div className="flex justify-end mt-4">
        <Button onClick={handleSubmit(handleUpdateSettings)}>
          <T keyName="generic.save" />
        </Button>
      </div>
    </div>
  );
}
