import type { DSRRights, SanitizedDSRRights } from "types/dsr";

export function sanitizeDSRRights(requests: DSRRights): SanitizedDSRRights {
  return Object.values(requests).map((request) => ({
    title: request.metadata.label,
    requests: Object.keys(request.reqvalues).map((key: string) => ({
      requestTitle: key,
      data: request.reqvalues[key],
    })),
  }));
}
