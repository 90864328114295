import { useEffect, useRef } from "react";

import api from "services/api";
import { handleError } from "services/api/error";
import { useAppDispatch, useAppSelector } from "store";
import { setAppVersion, syncPreferences } from "store/app";

const safeStorage = {
  inMemoryStorage: new Map<string, string>(),

  getItem(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (_error) {
      return this.inMemoryStorage.get(key) || null;
    }
  },

  setItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (_error) {
      this.inMemoryStorage.set(key, value);
    }
  },
};

export default function LocalStorageService() {
  const dispatch = useAppDispatch();
  const preferences = useAppSelector((state) => state.app.preferences);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      const storedPreferences = safeStorage.getItem("preferences");
      if (storedPreferences) {
        dispatch(syncPreferences(JSON.parse(storedPreferences)));
      }
      isFirstRender.current = false;
    } else {
      const stringifyPreferences = JSON.stringify(preferences);
      safeStorage.setItem("preferences", stringifyPreferences);
      safeStorage.setItem("navOpen", JSON.stringify(preferences.navbarState));
    }
  }, [dispatch, preferences]);

  useEffect(() => {
    async function fetchAppVersion(attempts = 3) {
      try {
        const res = await api.general.ping();
        dispatch(setAppVersion(res.data.app_version));
      } catch (e) {
        if (attempts > 1) {
          setTimeout(() => fetchAppVersion(attempts - 1), 1000 * 3);
        } else {
          handleError(e);
        }
      }
    }

    fetchAppVersion();
  }, [dispatch]);

  return <></>;
}
