import api from "services/api/base";

import type { PaginatedResponse, PaginationRequest } from "types/general";
import type { AxiosResponse } from "axios";
import type { SettleWisePaymentOut } from "types/api/billing";
import type {
  Invoice,
  InvoiceList,
  PaymentInformationResponse,
  PaymentMethod,
  WisePaymentObject,
} from "types/billing";

export async function startPayment(
  invoiceId: string
): Promise<AxiosResponse<PaymentInformationResponse>> {
  return api.get("/v1/billing/payment/start", {
    params: { invoice_uuid: invoiceId },
  });
}

export async function getInvoices(): Promise<AxiosResponse<Invoice[]>> {
  return api.get("/v1/invoices/user");
}

export async function getInvoicesList({
  status,
  order_by,
  page,
  per_page,
  search_term,
  desc,
}: PaginationRequest): Promise<AxiosResponse<PaginatedResponse<InvoiceList[]>>> {
  return api.get("/v1/invoices", {
    params: {
      status,
      order_by,
      page,
      per_page,
      search_term,
      desc,
    },
  });
}

export async function settleWisePayment(
  wisePaymentObject: WisePaymentObject
): Promise<AxiosResponse<SettleWisePaymentOut>> {
  return api.post("/v1/billing/ext/wise/settle", wisePaymentObject);
}

export async function getPaymentMethods(): Promise<AxiosResponse<PaymentMethod[]>> {
  return api.get("/v1/stripe/payment-methods");
}

export async function deletePaymentMethod(externalId: string): Promise<AxiosResponse<any>> {
  return api.delete("/v1/stripe/payment-method", {
    params: { external_id: externalId },
  });
}
