import { Button, CircularProgress } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { pdfjs, Document, Page } from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { api } from "services";
import { handleError } from "services/api/error";
import { BusinessFileEnum } from "types/business";
import { useCase } from "pages/Client/Case/CaseContext";
import { finalizeCheck } from "store/thunks";
import tolgee from "services/translation";
import { convertBase64ToFileObject } from "helpers";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  // @ts-expect-error Must be like this
  import.meta.url
).toString();

export default function DocSigned() {
  const [state, dispatch] = useCase();
  const caseData = state.case;
  const [loaDocument, setLoaDocument] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const signature = useRef<SignatureCanvas>(null);

  const fetchNonSignedDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await api.file.getFileDoc(caseData.business.public_id, "NIS_EU_LOA");
      const blob = new Blob([res.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      setLoaDocument(url);
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  }, [caseData.business.public_id]);

  useEffect(() => {
    fetchNonSignedDocument();
  }, [fetchNonSignedDocument]);

  const handleSignature = async () => {
    const base64 = signature.current?.toDataURL("image/png");
    const file = convertBase64ToFileObject(base64, "signature.png", "image/png");

    try {
      await api.authCase.uploadFileToCase(
        caseData.uuid,
        [file],
        BusinessFileEnum.REP_DOC_SIGNATURE
      );
      await finalizeCheck()(dispatch, state.case.uuid, state.ui.check);
    } catch (e) {
      handleError(e);
    }
  };

  const handleClear = () => {
    signature.current?.clear();
  };

  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <h2 className="text-2xl font-bold text-gray-800 mb-10">{tolgee.t("documentSign.title")}</h2>
      <div className="box-outerlayout">
        <div className="flex flex-col gap-6 p-4">
          <div>{tolgee.t("documentSign.explanation")}</div>
          {isLoading && (
            <div className="flex justify-center items-center h-[900px] border-2 border-gray-300 rounded-md">
              <CircularProgress />
            </div>
          )}

          {!isLoading && (
            <div className="flex justify-center items-center border-2 border-gray-300 rounded-md h-[900px]">
              <Document
                file={loaDocument}
                loading={
                  <div className="flex justify-center items-center h-[900px]">
                    <CircularProgress />
                  </div>
                }>
                <Page pageNumber={1} />
              </Document>
            </div>
          )}

          <div className="flex items-center justify-center">
            <div className="flex flex-col items-center justify-center gap-6">
              <div className="bg-gray-200 border-2 border-gray-300 rounded-md">
                <SignatureCanvas
                  ref={signature}
                  canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
                />
              </div>
              <div className="flex gap-2">
                <Button onClick={() => handleClear()}>{tolgee.t("documentSign.clear")}</Button>
                <Button onClick={() => handleSignature()}>{tolgee.t("documentSign.sign")}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
