import React from "react";

import tolgee from "services/translation";

interface BoldTranslationProps {
  translationSource: string;
  translationKey: string;
  boldedParts: string[];
}

export default function BoldTranslation({
  translationSource,
  translationKey,
  boldedParts,
}: BoldTranslationProps) {
  const translatedText = tolgee.t(`${translationSource}.${translationKey}`);
  const parts = translatedText.split(/(\s+)/);

  return (
    <span>
      {parts.map((part, index) => {
        const isBoldWord = boldedParts.includes(part.trim());
        return (
          <React.Fragment key={index}>{isBoldWord ? <strong>{part}</strong> : part}</React.Fragment>
        );
      })}
    </span>
  );
}
