import { StageNameEnum } from "types/case";

import type { CheckName } from "types/case";

export const preCheckStepsOrder = [
  "DS_IDENTIFICATION",
  "DS_LOCATION",
  "CONTROLLER_PROCESSOR",
] as CheckName[];

export const nis2LoaSignStepsOrder = ["DOC_SIGNED"] as CheckName[];
export const nis2LoaClientReviewStepsOrder = ["DOC_CLIENT_REVIEWED"] as CheckName[];
export const nis2LoaReviewStepsOrder = ["DOC_REVIEWED"] as CheckName[];

export const handlingStepsOrder = [
  "CONTROLLER_ADDED",
  "CORRECT_DELETE",
  "EXEMPTIONS",
  "PRIVACY_POLICY_CATEGORIES",
  "PROCESSORS_ADDED",
  "INCORRECT_SOURCES",
  "INFORM_THIRD_PARTY",
  "DS_DATA_UPLOAD",
] as CheckName[];

export const stageNames: StageNameEnum[] = [
  StageNameEnum.PRE_CHECK,
  StageNameEnum.PRE_CHECK_REVIEW,
  StageNameEnum.HANDLING,
  StageNameEnum.HANDLING_REVIEW,
];

export const dsrFormSteps = [
  {
    key: "type",
    name: "state.type_of_request",
  },
  {
    key: "identification",
    name: "state.provide_identification",
  },
  {
    key: "send",
    name: "state.send_request",
  },
];

export const ccpaRetainDataReasonCount = 3;
