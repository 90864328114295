import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { BaseTabs } from "components/Tabs/Tabs";
import Header from "pages/Admin/ManageUser/Header";
import InvoicesTab from "pages/Admin/ManageUser/InvoicesTab";
import SecurityTab from "pages/Admin/ManageUser/SecurityTab";
import CasesTab from "pages/Admin/ManageUser/CasesTab";
import NotificationChannelsTab from "pages/Admin/ManageUser/NotificationChannelsTab";
import GeneralTab from "pages/Admin/ManageUser/GeneralTab";
import { api } from "services";
import { handleError } from "services/api/error";
import tolgee from "services/translation/tolgee";

import type { ManagedUserDetail } from "types/user";

export default function ManageUser() {
  const { userId } = useParams();
  const [user, setUser] = useState<ManagedUserDetail>({} as ManagedUserDetail);

  useEffect(() => {
    async function fetchUser() {
      let res;
      try {
        res = await api.user.detail(userId);
      } catch (e) {
        handleError(e);
        return;
      }
      setUser(res.data);
    }

    fetchUser();
  }, [userId]);

  const tabsData = [
    {
      id: 1,
      label: "General",
      content: <GeneralTab user={user} />,
    },
    {
      id: 2,
      label: "Invoices & Billing",
      content: <InvoicesTab user={user} />,
    },
    {
      id: 3,
      label: "Security",
      content: <SecurityTab user={user} />,
    },
    {
      id: 4,
      label: "DSR List",
      content: <CasesTab />,
    },
    {
      id: 5,
      label: tolgee.t("manageUser.notification_channels"),
      content: <NotificationChannelsTab user={user} />,
    },
    {
      id: 5,
      label: "Data",
      content: <pre>{JSON.stringify(user, null, 2)}</pre>,
    },
  ];

  return (
    <div className="w-full">
      {user.uuid && <Header user={user} />}
      {user.uuid && <BaseTabs tabsData={tabsData} defaultTab={0} />}
    </div>
  );
}
