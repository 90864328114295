import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type { BusinessFilePrivate } from "types/business";

export async function getFileDoc(
  businessId: string,
  type: BusinessFilePrivate
): Promise<AxiosResponse<any>> {
  return api.get("/v1/file/doc", {
    params: { business_id: businessId, type_: type },
    responseType: "arraybuffer",
  });
}

export async function getFileDocSigned(
  caseUuid: string,
  type: BusinessFilePrivate
): Promise<AxiosResponse<any>> {
  return api.get("/v1/file/doc/signed", {
    params: { case_uuid: caseUuid, type_: type },
    responseType: "arraybuffer",
  });
}
