import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { T } from "@tolgee/react";
import { CircularProgress } from "@mui/material";

import { api } from "services";
import { getBeautyDate, getUrl } from "helpers";
import { COMPANY_FULL_NAME, COMPANY_FULL_NAME_WITH_COM } from "const/context";
import { PrighterFooter } from "components";
import { STRIPE_KEY } from "const/env";
import StripePaymentComponent from "pages/Client/Billing/PayInvoice/StripePaymentComponent";
import { getInvoiceStatusElement } from "helpers/billing";
import PrighterLogo from "components/Prighter/PrighterLogo";
import { handleError } from "services/api/error";

import type { Stripe, StripeElementsOptions } from "@stripe/stripe-js";
import type { PaymentInformationResponse } from "types/billing";

export default function PayInvoice() {
  const { invoiceId } = useParams();

  const [invoiceData, setInvoiceData] = useState<PaymentInformationResponse>();

  const [stripePromise, setStripePromise] = useState<Promise<Stripe>>();
  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const sp = loadStripe(STRIPE_KEY);
    if (sp) {
      setStripePromise(sp);
    }
  }, []);

  useEffect(() => {
    async function startInvoice() {
      setLoading(true);

      let res;
      try {
        res = await api.billing.startPayment(invoiceId);
      } catch (e) {
        handleError(e);
        setLoading(false);
        return;
      }
      res = res.data;

      if (!res.invoice.number) {
        return;
      }

      setInvoiceData(res);
      setLoading(false);

      if (!res.client_secret) {
        return;
      }

      const tempOptions: StripeElementsOptions = {
        clientSecret: res.client_secret,
        appearance: { theme: "stripe" as const },
        loader: "auto",
      };

      setStripeOptions(tempOptions);
    }

    if (invoiceId) {
      startInvoice();
    }
  }, [invoiceId]);

  return (
    <div className="bg-prighterblue w-full h-screen overflow-auto flex items-center justify-center">
      {invoiceData && (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center p-2 pt-0">
            <PrighterLogo className="w-8 h-8 p-1 bg-white rounded-full" />
            <span className="ml-4 text-gray-200 text-lg font-bold w-full">
              {COMPANY_FULL_NAME_WITH_COM}
            </span>
          </div>

          <div className="bg-gray-50 box-outerlayout">
            <div className="flex flex-row justify-between mt-2">
              <div className="flex flex-col mb-4">
                <span className="font-bold text-4xl">
                  € {(invoiceData.invoice.amount_remaining / 100).toFixed(2)}
                </span>
                <span className="font-bold text-gray-500">
                  <T keyName="generic.due" />{" "}
                  {getBeautyDate(invoiceData.invoice.finalized_at, true)}
                </span>
              </div>
              {getInvoiceStatusElement(invoiceData.invoice.status)}
            </div>

            <div className="grid pl-8" style={{ gridTemplateColumns: "100px 300px" }}>
              <span className="font-bold text-gray-600">
                <T keyName="generic.to" />
              </span>
              <span>{invoiceData.invoice.user_company_name}</span>

              <span className="font-bold text-gray-600">
                <T keyName="generic.from" />
              </span>
              <span>{COMPANY_FULL_NAME}</span>

              <span className="font-bold text-gray-600">
                <T keyName="generic.invoice" />
              </span>
              <a href={getUrl("FLASK", `/my/invoice/${invoiceData.invoice.number}`)}>
                {invoiceData.invoice.number}
              </a>
            </div>
          </div>

          {stripePromise && stripeOptions?.clientSecret && (
            <Elements stripe={stripePromise} options={stripeOptions}>
              <StripePaymentComponent />
            </Elements>
          )}

          <div className="px-2">
            <PrighterFooter theme="dark" />
          </div>
        </div>
      )}
      {loading && <CircularProgress sx={{ color: "lightgray" }} color="inherit" />}
      {!loading && !invoiceData && (
        <span className="text-white text-2xl">
          <T keyName="billing.invoice_process_failed" />
        </span>
      )}
    </div>
  );
}
