import { T } from "@tolgee/react";
import { Skeleton } from "@mui/material";

import { LEGAL_INFO, PRIVACY_POLICY } from "const/env";

export default function SkeletonLandingPage() {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen px-2 mx-auto sm:px-4">
      <div className="flex flex-col w-full max-w-screen-lg p-6 m-4 shadow-2xl backdrop-blur-sm space-y-6 rounded-lg border pt-0">
        <div className="flex mt-8 space-x-1 text-2xl font-bold md:text-4xl">
          <Skeleton variant="rectangular" width={500} height={20} />
        </div>

        <Skeleton variant="rectangular" width={200} height={20} />
        <div>
          <Skeleton variant="rectangular" width={800} height={80} />
        </div>

        <Skeleton variant="rectangular" width={200} height={20} />

        <div className="flex justify-between ">
          <div>
            <T keyName="public_clp.powered" />
            <span className="font-bold">Prighter</span>
          </div>

          <div className="flex space-x-4 font-bold">
            <a href={PRIVACY_POLICY} className="text-brand-900">
              <T keyName="public_clp.privacy_policy" />
            </a>

            <a href={LEGAL_INFO} className="text-brand-900">
              <T keyName="public_clp.legal" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
