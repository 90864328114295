import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { captureException } from "@sentry/react";

import { api } from "services";
import { notify } from "store/app";
import { useAppDispatch } from "store";
import { FormFileField } from "components";
import tolgee from "services/translation";
import MarkdownEditor from "components/Input/MarkdownEditor";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData } from "store/thunks";
import { generateI18nKeyByCaseType } from "helpers/case";

import type { FileObject } from "types/general";

const defaultValues = {
  subject: "",
  content: "",
  files: [] as FileObject[],
};

export default function HandlingReview() {
  const [state, dispatch] = useCase();
  const caseData = state.case;

  const appDispatch = useAppDispatch();
  const { reset, watch, setValue, getValues, register } = useForm({
    defaultValues,
  });

  const textData = watch("content");
  const files = watch("files");

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await api.ccpa.getCcpaPreview(caseData.uuid);
        reset({
          content: res.data.content,
          subject: res.data.subject,
        });
      } catch {
        appDispatch(
          notify({
            message: tolgee.t({
              key: "generic.smth_went_wrong",
            }),
            type: "ERROR",
          })
        );
      }
    }

    fetchData();
  }, [caseData.uuid, reset, appDispatch]);

  const onEditorStateChange = (editorState: any) => {
    setValue("content", editorState, { shouldDirty: true });
  };

  const finalizeReview = async () => {
    const form = getValues();
    try {
      await api.authCase.finalizeCase(caseData.uuid, {
        subject: form.subject,
        content: form.content,
        files: form.files || [],
      });

      appDispatch(
        notify({
          message: tolgee.t({
            key: generateI18nKeyByCaseType(caseData.type, "check_finished_successfully"),
          }),
          type: "SUCCESS",
        })
      );
      fetchCaseData()(dispatch, caseData.uuid);
    } catch {
      appDispatch(
        notify({
          message: tolgee.t({
            key: "generic.smth_went_wrong",
          }),
          type: "ERROR",
        })
      );
    }
  };

  const handleUploadFiles = (fileList: File[]) => {
    const fileArray = Array.from(fileList);

    const ccpaFileArray = fileArray.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64String = reader.result;
          resolve(base64String);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    });

    Promise.all(ccpaFileArray)
      .then((resolvedFileArray: any[]) => {
        setValue(
          "files",
          resolvedFileArray.map((f, ind) => ({ name: fileArray[ind].name, content: f }))
        );
      })
      .catch((error) => {
        captureException(error);
      });
  };

  return (
    <>
      <div className="grid gap-1 px-6 mb-3">
        <h2>
          {tolgee.t({
            key: generateI18nKeyByCaseType(caseData.type, "handling_review_title"),
          })}
        </h2>
        <div>
          {tolgee.t({
            key: generateI18nKeyByCaseType(caseData.type, "handling_review_description"),
          })}

          {textData ? (
            <div className="flex flex-col space-y-4 mt-4">
              <TextField
                label="Subject"
                {...register("subject")}
                InputLabelProps={{ shrink: true }}
              />

              <MarkdownEditor onChange={onEditorStateChange} value={textData} />

              <FormFileField
                label="Upload a file"
                className="mt-2"
                value={files}
                onChange={handleUploadFiles}
                multiple
                fullWidth
                required
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="flex justify-between px-6">
        <Button
          data-testid="reviewSendClose_button"
          onClick={finalizeReview}
          color="success"
          sx={{
            backgroundColor: "#4ad158",
            "&:hover": { backgroundColor: "#4b904f" },
          }}>
          {tolgee.t({
            key: generateI18nKeyByCaseType(caseData.type, "send_and_close"),
          })}
          <FontAwesomeIcon className="ml-2" icon="arrow-right" size="lg" />
        </Button>
      </div>
    </>
  );
}
