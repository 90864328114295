import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { T } from "@tolgee/react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";

import { BaseButton, BaseIconButton } from "components";
import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { useAppDispatch } from "store";
import { getBeautyDate } from "helpers";
import { handleError } from "services/api/error";

import type { CertificateText } from "types/business";

export default function TextCertificate() {
  const { businessId } = useParams();
  const dispatch = useAppDispatch();

  const { register, setValue, getValues, watch } = useForm<CertificateText>();
  const [certificates, setCertificates] = useState<CertificateText[]>([]);
  const [reloadState, setReload] = useState(0);

  const reload = () => setReload((prev) => prev + 1);

  const fetchCertificates = useCallback(async () => {
    let res;
    try {
      res = await api.clp.getCertificateTexts(businessId);
    } catch (e) {
      handleError(e);
      return;
    }
    setCertificates(res.data);
  }, [businessId]);

  useEffect(() => {
    fetchCertificates();
  }, [fetchCertificates, reloadState]);

  const onSubmit = async () => {
    try {
      await api.clp.addCertificateText(businessId, getValues());
    } catch (e) {
      handleError(e);
      return;
    } finally {
      reload();
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "clp.text_certificate_added",
        }),
        type: "SUCCESS",
      })
    );
  };

  const onDelete = async (id: string) => {
    try {
      await api.clp.deleteCertificateText(businessId, id);
    } catch (e) {
      handleError(e);
      return;
    } finally {
      reload();
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "clp.text_certificate_deleted",
        }),
        type: "SUCCESS",
      })
    );
  };

  return (
    <>
      <div className="mt-12 mb-4">
        {certificates?.map((certificate) => (
          <div className="grid grid-cols-[repeat(5,1fr)_auto]">
            <div className="break-all flex items-center">{certificate.cert}</div>
            <div className="break-all flex items-center">{certificate.issuer}</div>
            <div className="break-all flex items-center">
              {getBeautyDate(certificate.issued_at)}
            </div>
            <div className="break-all flex items-center">
              {getBeautyDate(certificate.valid_until)}
            </div>
            <div className="break-all flex items-center">{certificate.url}</div>
            <BaseIconButton
              confirmPopup
              className="self-center"
              onClick={() => onDelete(certificate.uuid)}
              size="medium">
              <FontAwesomeIcon icon="trash" color="red" size="sm" />
            </BaseIconButton>
          </div>
        ))}
      </div>
      <Stack spacing={2}>
        <div>
          <T keyName="clp.present_certificates" />
        </div>
        <div className="flex space-x-4">
          <TextField
            {...register("cert")}
            value={watch("cert") || ""}
            className="w-full"
            label={tolgee.t({
              key: "clp.certificate",
            })}
          />
          <TextField
            {...(register("issuer") || "")}
            className="w-full"
            label={tolgee.t({
              key: "clp.issuer",
            })}
          />
        </div>
        <div className="flex space-x-4">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(val) => setValue("issued_at", val.toISOString() as any)}
              label={tolgee.t({ key: "clp.date_issued" })}
              className="w-full"
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              format="DD/MM/YYYY"
              onChange={(val) => setValue("valid_until", val.toISOString() as any)}
              label={tolgee.t({ key: "clp.valid_until" })}
              className="w-full"
            />
          </LocalizationProvider>
        </div>
        <TextField
          {...(register("url") || "")}
          label={tolgee.t({
            key: "clp.website",
          })}
          className="w-full"
        />
        <BaseButton onClick={onSubmit} className="self-end">
          <T keyName="clp.add_certificate" />
        </BaseButton>
      </Stack>
    </>
  );
}
