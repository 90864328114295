import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useForm } from "react-hook-form";

import { FormFileField } from "components";

export default function Referrals() {
  const { register } = useForm();

  const handleCopy = () => {};

  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <div className="box-outerlayout mb-4">
        <h1>
          <T keyName="referrals.give_10" />
        </h1>
        <div>
          <div>
            <T keyName="referrals.referrals_description" />
          </div>
          <div className="flex mt-4 space-x-8">
            <div className="flex flex-col border border-gray-300 rounded p-4">
              <div className="font-bold text-4xl text-center mb-4">0</div>
              <div>
                <T keyName="referrals.referral_visitors" />
              </div>
            </div>
            <div className="flex flex-col border border-gray-300 rounded p-4">
              <div className="font-bold text-4xl text-center mb-4">0</div>
              <div>
                <T keyName="referrals.referrals_redeemed" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="box-outerlayout mb-4">
        <h1>
          <T keyName="referrals.share_link" />
        </h1>
        <div>
          <div>
            <T keyName="referrals.copy_link" />
          </div>
          <div className="mt-4 flex justify-between">
            <div>
              <TextField className="w-100" disabled {...register("referral")} />
              <IconButton className="w-12 h-12" onClick={handleCopy}>
                <FontAwesomeIcon icon="copy" />
              </IconButton>
            </div>
            <div className="flex space-x-4 items-center">
              <a
                className="w-auto text-center button-primary bg-twitter border-twitter hover:text-twitter h-12"
                href="/">
                <T keyName="referrals.share_twitter" />
              </a>
              <a
                className="w-auto text-center button-primary bg-facebook border-facebook hover:text-facebook h-12"
                href="/">
                <T keyName="referrals.share_fb" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="box-outerlayout mb-4">
        <h1>
          <T keyName="referrals.customize_page" />
        </h1>
        <h2>
          <T keyName="referrals.referral_link" />
        </h2>
        <div className="w-full flex">
          <TextField className="w-full" disabled {...register("referral")} />
          <IconButton className="w-12 h-12" onClick={handleCopy}>
            <FontAwesomeIcon icon="copy" />
          </IconButton>
        </div>
        <h2>
          <T keyName="referrals.upload_logo" />
        </h2>
        <div>
          <FormFileField
            label="Upload a file"
            className="mt-2"
            // value={files}
            // onChange={handleUploadFiles}
            multiple
            fullWidth
          />
        </div>
      </div>
    </div>
  );
}
