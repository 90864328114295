import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, IconButton, MenuItem, Select, TextField } from "@mui/material";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
import { handleError } from "services/api/error";

import type { Permission } from "types/security";
import type { Company } from "types/company";

const permissionOptions = ["read", "write", "admin"];

export default function Permissions() {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState<Company>();
  const [permissions, setPermissions] = useState<Permission[]>();

  const fetchData = useCallback(async () => {
    let res;
    try {
      res = await api.business.get(businessId);
    } catch (e) {
      handleError(e);
      return;
    }

    setData(res.data);
  }, [businessId]);

  const getPermissions = useCallback(async () => {
    let res;
    try {
      res = await api.security.getBusinessPermissions(businessId);
    } catch (e) {
      handleError(e);
      return;
    }

    setPermissions(res.data);
  }, [businessId]);

  useEffect(() => {
    fetchData();
    getPermissions();
  }, [fetchData, getPermissions]);

  const handlePermissionDelete = async (permissionData: Permission) => {
    const { type } = permissionData;
    const { email } = permissionData.user;

    try {
      await api.security.deletePermissions(businessId, type, email);
    } catch (e) {
      handleError(e);
      return;
    }
    dispatch(
      notify({
        message: tolgee.t({
          key: "permissions.permission_deleted",
        }),
        type: "SUCCESS",
      })
    );
    getPermissions();
  };

  const onSubmit = async (newPermission: any) => {
    const currentPermission = newPermission.permission || "admin";
    const permissionData = {
      type: currentPermission.toUpperCase(),
      email: newPermission.email,
    };

    try {
      await api.security.addPermissions(businessId, permissionData);
    } catch (e) {
      handleError(e);
      return;
    }

    dispatch(
      notify({
        message: tolgee.t({
          key: "permissions.permission_added",
        }),
        type: "SUCCESS",
      })
    );
    getPermissions();
    setValue(`email`, null);
  };

  const { register, setValue, handleSubmit, watch } = useForm({
    defaultValues: { email: "", permission: "admin" },
  });
  const permissionData = watch("permission");
  return (
    <div className="flex flex-col mx-auto max-w-7xl">
      <div className="box-outerlayout mb-4">
        <h2>
          <T keyName="permissions.manage_permissions_of" />
          {data?.company_name} ({data?.public_id})
        </h2>
        <div>
          <div className="mb-4">
            <T keyName="permissions.you_can_onboard" />
          </div>
          <ul className="space-y-4">
            <li className="list-disc">
              <div className="font-bold">
                <T keyName="permissions.read" />
              </div>
              <div>
                <T keyName="permissions.read_perm" />
              </div>
            </li>
            <li className="list-disc">
              <div className="font-bold">
                <T keyName="permissions.write" />
              </div>
              <div>
                <T keyName="permissions.write_perm" />
              </div>
            </li>
            <li className="list-disc">
              <div className="font-bold">
                <T keyName="permissions.admin" />
              </div>
              <div>
                <T keyName="permissions.admin_perm" />
              </div>
            </li>
          </ul>
        </div>
        <h3>
          <T keyName="permissions.grant_permission" />:
        </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4">
            <div className="font-bold">
              <T keyName="permissions.permission" /> *
            </div>
            <Select
              defaultValue="admin"
              required
              id="select"
              className="w-full mt-4"
              value={permissionData}
              onChange={(event: any) => {
                setValue(`permission`, event.target?.value);
              }}>
              {permissionOptions.map((i) => (
                <MenuItem value={i} key={i}>
                  <T keyName={`permissions.${i}`} />
                </MenuItem>
              ))}
            </Select>
            <div className="font-bold mt-4">
              <T keyName="permissions.email" /> *
            </div>
            <TextField
              required
              className="w-full"
              {...register("email", {
                required: true,
              })}
            />
          </div>
          <div className="flex justify-end mt-6">
            <Button type="submit">
              <T keyName="generic.submit" />
            </Button>
          </div>
        </form>
      </div>
      <div className="box-outerlayout mb-4">
        <h2>
          <T keyName="permissions.existing_permission" />
        </h2>
        {permissions && permissions.length > 0 && (
          <div>
            {permissions.map((i) => (
              <div className="flex justify-between" key={i.uuid}>
                <div>{`${i.user.email} has the permission ${i.type}`}</div>
                <div>
                  <IconButton size="small" onClick={() => handlePermissionDelete(i)}>
                    <FontAwesomeIcon icon="trash" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
