import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";

import AddProcessorField from "pages/Client/Case/DSR/ClientView/Components/AddProcessorField";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import { isCheckFinished, generateI18nKey } from "helpers/case";
import { ActingAsEnum } from "types/case";

export default function AddProcessors() {
  const [state, dispatch] = useCase();
  const caseData = state.case;

  const handleReload = useCallback(
    () => fetchCaseData()(dispatch, caseData.uuid, false),
    [dispatch, caseData]
  );
  const requestClosed = caseData.state === "CLOSED";
  const isFinished = isCheckFinished(state);

  return (
    <>
      {tolgee.t({
        key: generateI18nKey(caseData.type, state.ui.check, "description"),
      })}
      <div className="flex flex-row space-x-2 justify-end">
        {isFinished && !requestClosed && (
          <Button
            onClick={() => reopenCheck()(dispatch, caseData.uuid, state.ui.check)}
            variant="outlined"
            endIcon={<FontAwesomeIcon icon="lock-open" className="!text-sm" />}>
            {tolgee.t({
              key: "general.reopen",
            })}
          </Button>
        )}

        <Button
          data-testid="confirmProcessorsAdded"
          disabled={isFinished}
          onClick={() => finalizeCheck()(dispatch, caseData.uuid, state.ui.check)}
          color="success">
          {tolgee.t({
            key: generateI18nKey(caseData.type, state.ui.check, "confirm_processors_added"),
          })}
        </Button>
      </div>
      <span className="mb-2">
        {tolgee.t({
          key: generateI18nKey(
            caseData.type,
            state.ui.check,
            "description_add_processor_contractor"
          ),
        })}
      </span>
      <AddProcessorField
        caseActors={caseData.case_actors.filter((i) => i.acting_as === ActingAsEnum.PROCESSOR)}
        handleReload={handleReload}
        locked={!!isFinished}
        contactType="PROCESSOR"
      />
    </>
  );
}
