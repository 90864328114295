import {
  Button,
  Checkbox,
  ListItemText,
  List,
  ListItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { T } from "@tolgee/react";
import { useCallback, useEffect, useRef, useState } from "react";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

import { useCase } from "pages/Client/Case/CaseContext";
import tolgee from "services/translation";
import { CorrectExemptionResultEnum, type CorrectExemptionsInterface } from "types/case";
import { BaseButton, BaseModal } from "components";
import { api } from "services";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import { generateI18nKey, isCheckFinished } from "helpers/case";
import TolgeeWrapper from "pages/Client/Dashboard/Components/TolgeeWrapper";
import { type ExemptionItem, ExemptionGroup } from "types/dsr/main";
import { notify } from "store/app";
import { handleError } from "services/api/error";

export default function CCPACorrectionExcludeData() {
  const appDispatch = useDispatch();
  const [state, dispatch] = useCase();
  const caseData = state.case;
  const isFirstRender = useRef(true);
  const isFinished = isCheckFinished(state);
  const [correctedExemptions, setCorrectedExemptions] = useState<CorrectExemptionsInterface[]>([]);
  const [fetchedExemptions, setFetchedExemptions] = useState<ExemptionItem[]>([]);
  const [correctionModal, setCorrectionModal] = useState<{
    dataIndex: number;
    isDisplayed: boolean;
    selectedItems: string[];
    options: { value: ExemptionItem; selected: boolean }[];
  }>({
    dataIndex: null,
    isDisplayed: false,
    selectedItems: [],
    options: fetchedExemptions.map((item) => ({
      value: item,
      selected: false,
    })),
  });
  const [isCorrectionUpdating, setIsCorrectionUpdating] = useState(false);

  const putDataToBackend = useCallback(
    async (data: CorrectExemptionsInterface[]) => {
      if (isFinished) {
        return;
      }
      try {
        await api.authCase.putDsrCorrectExemptions(caseData.uuid, data);
      } catch (e) {
        handleError(e);
      }
    },
    [caseData.uuid, isFinished]
  );

  useEffect(() => {
    const generateExemptionGroups = () => {
      let groups = [] as Array<ExemptionGroup>;
      switch (caseData.type) {
        case "DSR_CCPA_DELETE":
          groups = [
            ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_DELETE,
            ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_DELETE,
          ];
          break;
        case "DSR_CCPA_ACCESS":
          groups = [
            ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_ACCESS,
            ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_ACCESS,
          ];
          break;
        case "DSR_CCPA_ACCESS_CATEGORIES":
          groups = [
            ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_ACCESS_CATEGORIES,
            ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_ACCESS_CATEGORIES,
          ];
          break;
        case "DSR_CCPA_CORRECT":
          groups = [
            ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_CORRECT,
            ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_CORRECT,
          ];
          break;
        case "DSR_CCPA_OPTOUT":
          groups = [
            ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_OPTOUT,
            ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_OPTOUT,
          ];
          break;
        case "DSR_CCPA_LIMIT":
          groups = [
            ExemptionGroup.EXEMPTIONS_OBLIGATION_CCPA_LIMIT,
            ExemptionGroup.EXEMPTIONS_OPTIONAL_CCPA_LIMIT,
          ];
          break;
        default:
          return [];
      }
      return groups;
    };

    const fetchExemptions = async () => {
      try {
        const groups = generateExemptionGroups();
        const res = await api.dsr.getExemptionsList(groups);
        const list = res.data;
        setFetchedExemptions(list);
        setCorrectionModal((prevState) => ({
          ...prevState,
          options: list.map((item) => ({
            value: item,
            selected: false,
          })),
        }));
      } catch {
        appDispatch(
          notify({
            message: tolgee.t({
              key: "generic.smth_went_wrong",
            }),
            type: "ERROR",
          })
        );
      }
    };

    fetchExemptions();
  }, [caseData.type, appDispatch]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (!caseData.case_checks.find((item) => item.name === "EXEMPTIONS").closed_at) {
      setIsCorrectionUpdating(true);
      putDataToBackend(correctedExemptions).finally(() => {
        setIsCorrectionUpdating(false);
      });
    }
  }, [caseData.case_checks, correctedExemptions, putDataToBackend]);

  useEffect(() => {
    setCorrectedExemptions(state.case.outcome.correct_exemptions);
  }, [caseData, state.case.outcome.correct_exemptions]);

  const handleCorrectionToggle = (newValue: CorrectExemptionResultEnum, index: number) => {
    if (!newValue) {
      return;
    }
    const localData = [...correctedExemptions];
    if (newValue === CorrectExemptionResultEnum.CONFIRM) {
      localData[index].result = newValue;
      localData[index].exemptions = [];
      setCorrectedExemptions(localData);
    } else {
      setCorrectionModal((prevState) => ({
        ...prevState,
        dataIndex: index,
        isDisplayed: true,
      }));
    }
  };

  const addDataExceptions = () => {
    const localItems = [...correctedExemptions];
    localItems[correctionModal.dataIndex].result = CorrectExemptionResultEnum.DENY;
    localItems[correctionModal.dataIndex].exemptions = correctionModal.selectedItems;

    setCorrectedExemptions(localItems);

    setCorrectionModal(() => ({
      dataIndex: null,
      isDisplayed: false,
      selectedItems: [],
      options: fetchedExemptions.map((item) => ({
        value: item,
        selected: false,
      })),
    }));
  };

  const handleSubmit = async () => {
    if (isCorrectionUpdating) {
      return;
    }
    await finalizeCheck()(dispatch, caseData.uuid, state.ui.check);
    await fetchCaseData()(dispatch, caseData.uuid, false);
  };

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="self-end">
          <Button
            data-testid="dsr_confirmation_button"
            color="success"
            disabled={
              correctedExemptions.filter(
                (item) => item.result === CorrectExemptionResultEnum.PENDING
              ).length > 0 ||
              isFinished ||
              isCorrectionUpdating
            }
            onClick={handleSubmit}>
            {tolgee.t({
              key: generateI18nKey(state.case.type, state.ui.check, "confirm"),
            })}
          </Button>
        </div>
        <div className={isFinished ? "pointer-events-none" : "pointer-events-auto"}>
          <div className="mb-6">
            {tolgee.t({
              key: generateI18nKey(state.case.type, state.ui.check, "description"),
            })}
          </div>
          {correctedExemptions.map((item, index) => (
            <div key={index} className="flex flex-row gap-8 w-full mb-8">
              <div className="flex flex-row w-full gap-4">
                <TextField
                  className="w-full"
                  value={item.data}
                  label={tolgee.t({
                    key: generateI18nKey(
                      state.case.type,
                      state.ui.check,
                      "request_related_to_following_personal_information"
                    ),
                  })}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                />
                <TextField
                  className="w-full"
                  value={item.why}
                  label={tolgee.t({
                    key: generateI18nKey(
                      state.case.type,
                      state.ui.check,
                      "personal_information_is_wrong_or_incomplete_because"
                    ),
                  })}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                />
                <TextField
                  className="w-full"
                  value={item.new}
                  label={tolgee.t({
                    key: generateI18nKey(
                      state.case.type,
                      state.ui.check,
                      "amend_the_personal_information_as_follows"
                    ),
                  })}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                />
              </div>
              <div>
                <ToggleButtonGroup
                  value={item.result}
                  exclusive
                  onChange={(event, newValue) => handleCorrectionToggle(newValue, index)}
                  aria-label="text alignment">
                  <ToggleButton
                    data-testid="correctionExcludeData_togglePending"
                    disabled={item.result !== CorrectExemptionResultEnum.PENDING}
                    value={CorrectExemptionResultEnum.PENDING}
                    aria-label="left aligned"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#9e9071",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#afa588",
                        },
                      },
                    }}>
                    <HourglassTopIcon />
                  </ToggleButton>
                  <ToggleButton
                    data-testid="correctionExcludeData_toggleConfirm"
                    value={CorrectExemptionResultEnum.CONFIRM}
                    aria-label="centered"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#2dba3c",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#4ad158",
                        },
                      },
                    }}>
                    <CheckIcon />
                  </ToggleButton>
                  <ToggleButton
                    data-testid="correctionExcludeData_toggleDeny"
                    value={CorrectExemptionResultEnum.DENY}
                    aria-label="right aligned"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#e04850",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#ed7c82",
                        },
                      },
                    }}>
                    <CloseIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          ))}
        </div>
        {isFinished && (
          <div className="flex justify-end">
            <Button
              onClick={() => {
                reopenCheck()(dispatch, caseData.uuid, state.ui.check);
              }}
              variant="outlined"
              endIcon={<FontAwesomeIcon icon="lock-open" className="!text-sm" />}>
              {tolgee.t({
                key: "general.reopen",
              })}
            </Button>
          </div>
        )}
      </div>
      <BaseModal
        modalState={[
          correctionModal.isDisplayed,
          (isDisplayed: boolean) =>
            setCorrectionModal((prevState) => ({
              ...prevState,
              isDisplayed,
            })),
        ]}>
        <div data-testid="excludeDataModal" className="flex flex-col">
          <h2 className="text-prighterdark">
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "modal_title"),
            })}
          </h2>
          <div>
            {tolgee.t({
              key: generateI18nKey(caseData.type, state.ui.check, "modal_description"),
            })}
          </div>

          <div className="space-y-4 my-4">
            <div className="bg-danger-400/10 border-danger-700 border-2 rounded-lg">
              <List>
                {correctionModal.options
                  .filter((item) => item.value.group.includes("OBLIGATION"))
                  .map((item, index) => (
                    <ListItem key={index} className="flex flex-row gap-4">
                      <ListItemText>
                        <TolgeeWrapper translateKey={item.value.key} />
                      </ListItemText>
                      <Checkbox
                        checked={item.selected}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          setCorrectionModal((prevState) => ({
                            ...prevState,
                            selectedItems: isChecked
                              ? [...prevState.selectedItems, item.value.key]
                              : prevState.selectedItems.filter(
                                  (selectedItem) => selectedItem !== item.value.key
                                ),
                            options: prevState.options.map((option) =>
                              option.value === item.value
                                ? { ...option, selected: isChecked }
                                : option
                            ),
                          }));
                        }}
                      />
                    </ListItem>
                  ))}
              </List>
            </div>
            <div className="bg-gray-400/10 border-gray-700 border-2 rounded-lg">
              <List>
                {correctionModal.options
                  .filter((item) => item.value.group.includes("OPTIONAL"))
                  .map((item, index) => (
                    <ListItem key={index} className="flex flex-row gap-4">
                      <ListItemText>
                        <TolgeeWrapper translateKey={item.value.key} />
                      </ListItemText>
                      <Checkbox
                        checked={item.selected}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          setCorrectionModal((prevState) => ({
                            ...prevState,
                            selectedItems: isChecked
                              ? [...prevState.selectedItems, item.value.key]
                              : prevState.selectedItems.filter(
                                  (selectedItem) => selectedItem !== item.value.key
                                ),
                            options: prevState.options.map((option) =>
                              option.value === item.value
                                ? { ...option, selected: isChecked }
                                : option
                            ),
                          }));
                        }}
                      />
                    </ListItem>
                  ))}
              </List>
            </div>
          </div>

          <div className="flex flex-row justify-end gap-6">
            <BaseButton
              color="primary"
              disabled={correctionModal.selectedItems.length === 0}
              onClick={addDataExceptions}>
              <T keyName="general.send" />
            </BaseButton>
            <BaseButton
              color="warning"
              variant="outlined"
              onClick={() => {
                setCorrectionModal(() => ({
                  dataIndex: null,
                  isDisplayed: false,
                  selectedItems: [],
                  options: fetchedExemptions.map((item) => ({
                    value: item,
                    selected: false,
                  })),
                }));
              }}>
              <T keyName="general.discard" />
            </BaseButton>
          </div>
        </div>
      </BaseModal>
    </>
  );
}
