export const PRIGHTER_BLUE = "#4b637d";
export const PRIGHTER_DARK_BLUE = "#505c76";
export const PRIGHTER_GRAY = "#F7F7F7";
export const PRIGHTER_HIGHLIGHT = "#2C75D3";
export const BLACK = "#202121";
export const WHITE = "#fff";
export const YELLOW = "#fee000";
export const TWITTER = "#00abee";
export const FACEBOOK = "#3b5898";
export const GRAY = "#94a3b3";
export const DARK_GRAY = "#334155";
export const GRAY_200 = "#9D9D9D";
export const NAVIGATION = "#fafafa";

export const ORANGE = {
  DEFAULT: "#ffbb32",
  50: "#fffbec",
  100: "#fff6d3",
  200: "#ffeaa5",
  300: "#ffd86d",
  400: "#ffbb32",
  500: "#ffa40a",
  600: "#ff8c00",
  700: "#cc6702",
  800: "#a14f0b",
  900: "#82420c",
  950: "#462004",
};

export const CREME = {
  DEFAULT: "#afa588",
  50: "#f8f7f4",
  100: "#eeede6",
  200: "#dddacb",
  300: "#ccc7b3",
  400: "#afa588",
  500: "#9e9071",
  600: "#918165",
  700: "#796955",
  800: "#645748",
  900: "#52483c",
  950: "#2b251f",
};

export const TURQUOISE = {
  DEFAULT: "#40e0d0",
  50: "#effefa",
  100: "#cafdf3",
  200: "#95faea",
  300: "#58f0dc",
  400: "#40e0d0",
  500: "#0cc0b1",
  600: "#079a91",
  700: "#0a7b75",
  800: "#0d625e",
  900: "#10514e",
  950: "#023131",
};

export const BRAND = {
  DEFAULT: "#8098b0",
  50: "#f6f7f9",
  100: "#ebeff3",
  200: "#d3dbe4",
  300: "#acbccd",
  400: "#8098b0",
  500: "#607c97",
  600: PRIGHTER_BLUE,
  700: "#3e5166",
  800: "#364556",
  900: "#313c49",
  950: "#202731",
  1000: "#1a1f26",
};

export const INFO = {
  DEFAULT: "#42c4e4",
  50: "#edfcfe",
  100: "#d2f5fb",
  200: "#aaeaf7",
  300: "#70d9f0",
  400: "#42c4e4",
  500: "#13a1c7",
  600: "#1380a7",
  700: "#166888",
  800: "#1b566f",
  900: "#1b485e",
  950: "#0c2e40",
};

export const WARNING = {
  DEFAULT: ORANGE[600],
  50: "#feffe7",
  100: "#fbffc1",
  200: "#fcff86",
  300: "#fffc41",
  400: "#ffef0d",
  500: YELLOW,
  600: "#d1a600",
  700: "#a67802",
  800: "#895d0a",
  900: "#744c0f",
  950: "#442804",
};

export const SUCCESS = {
  DEFAULT: "#4ad158",
  50: "#f1fcf1",
  100: "#defae1",
  200: "#bff3c3",
  300: "#8de896",
  400: "#4ad158",
  500: "#2dba3c",
  600: "#20992d",
  700: "#1c7927",
  800: "#1c5f24",
  900: "#184f20",
  950: "#082b0d",
};

export const DANGER = {
  DEFAULT: "#e04850",
  50: "#fdf3f4",
  100: "#fce4e5",
  200: "#faced0",
  300: "#f5acb0",
  400: "#ed7c82",
  500: "#e04850",
  600: "#ce343c",
  700: "#ad282f",
  800: "#8f252b",
  900: "#772529",
  950: "#400f12",
};
