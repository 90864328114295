import api from "services/api/base";

import type { AxiosResponse } from "axios";
import type { Report, SpecialDealUser } from "types/report";

export async function getProductRevenue(): Promise<AxiosResponse<Report[]>> {
  return api.get("/v1/report/product-revenue");
}

export async function getSpecialDealMixed(): Promise<AxiosResponse<SpecialDealUser[]>> {
  return api.get("/v1/report/special-deal-mixed");
}
