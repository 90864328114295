import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";

import { api } from "services";
import { handleError } from "services/api/error";
import { getBeautyDate } from "helpers";
import tolgee from "services/translation";
import { dataGridConfig } from "helpers/dataGridConfig";
import { useAppDispatch, useAppSelector } from "store";
import { changePreference } from "store/app";

import type { Company } from "types/company";

type Props = {
  business: Company;
};

export default function FilesTab({ business }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const { manageBusinessFilesRows = 25 } = useAppSelector((state) => state.app.preferences);
  const dispatch = useAppDispatch();

  const formatFileSize = (bytes: number) => {
    if (bytes >= 1024 * 1024) {
      return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    }
    return `${(bytes / 1024).toFixed(2)} KB`;
  };

  const renderActionButtonsCell = useCallback(
    (params: any) => (
      <div className="p-3">
        <FontAwesomeIcon
          className="cursor-pointer"
          icon="download"
          onClick={() => window.open(params.row.url, "_blank")}
        />
      </div>
    ),
    []
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    let res;
    try {
      res = await api.business.getFiles(business.public_id);
    } catch (e) {
      handleError(e);
      setLoading(false);
      return;
    }
    res = res.data;

    const tableData = {
      ...dataGridConfig({ currentPage, rowsPerPage: manageBusinessFilesRows, count: res.length }),
      onPaginationModelChange: (val: any) => {
        setCurrentPage(val.page);
        dispatch(changePreference({ manageBusinessFilesRows: val.pageSize }));
      },
      columns: [
        {
          flex: 1,
          field: "created_at",
          sortable: false,
          headerName: tolgee.t({
            key: "generic.created_at",
          }),
          renderCell: (params: any) => {
            return <div>{getBeautyDate(params.row.created_at)}</div>;
          },
        },
        {
          flex: 1,
          field: "mime_type",
          sortable: false,
          headerName: tolgee.t({
            key: "generic.mime_type",
          }),
        },
        {
          flex: 1,
          field: "size",
          sortable: false,
          headerName: tolgee.t({
            key: "generic.size",
          }),
          renderCell: (params: any) => {
            return <div>{formatFileSize(params.row.size)}</div>;
          },
        },
        {
          flex: 1,
          field: "name",
          sortable: false,
          headerName: tolgee.t({
            key: "generic.name",
          }),
        },
        {
          flex: 1,
          field: "name_original",
          sortable: false,
          headerName: tolgee.t({
            key: "generic.name_original",
          }),
        },
        {
          flex: 1,
          field: "type",
          sortable: false,
          headerName: tolgee.t({
            key: "generic.file_type",
          }),
        },
        {
          flex: 1,
          field: "buttons",
          sortable: false,
          headerName: tolgee.t({
            key: "generic.actions",
          }),
          renderCell: renderActionButtonsCell,
        },
      ],
      rows: res?.map((i: any) => {
        return { ...i, id: i?.uuid };
      }),
    };

    setData(tableData);
    setLoading(false);
  }, [currentPage, business.public_id, manageBusinessFilesRows, dispatch, renderActionButtonsCell]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="box-outerlayout">
      <h3>{tolgee.t("manage_business.files")}</h3>
      {data ? (
        <DataGrid {...data} loading={loading} />
      ) : (
        !loading && (
          <div>
            {tolgee.t({
              key: "generic.no_data",
            })}
          </div>
        )
      )}
    </div>
  );
}
