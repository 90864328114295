import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import type { BusinessWithCase } from "types/case";

type Props = {
  email: BusinessWithCase;
  fetchEmailById: (uuid: string) => Promise<void>;
};

export default function EmailCasesListItem(props: Props) {
  const { email, fetchEmailById } = props;
  const navigate = useNavigate();

  const handleClick = (id: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    switch (e.detail) {
      case 1:
        fetchEmailById(id);
        break;
      case 2:
        navigate(`/email/case/${id}`);
        break;
      default:
        ((count: number): never => {
          throw new Error(`Cannot determine click count:  ${count}`);
        })(e.detail);
    }
  };

  const handleEditClick = (id: string) => () => {
    navigate(`/email/case/${id}`);
  };

  return (
    <div className="flex group min-h-16 items-start transition-all justify-between p-2 border border-[#E3E3E3] w-full rounded-md hover:bg-slate-100 focus:outline-none focus:ring focus:ring-prighterblue">
      <button key={email.uuid} type="button" onClick={handleClick(email.uuid)} className="w-full">
        <div className="flex items-center gap-4 mb-1">
          <h5 className="">
            {email.subject} <span className="font-bold">{email.from_name}</span>
          </h5>
          <span className="text-xs capitalize">{email.state.toLocaleLowerCase()}</span>
          <span className="text-xs font-bold">
            ({email.tags.map((tag) => `emailCase.tags.${tag}`).join(", ")})
          </span>
        </div>
        <div className="flex items-center gap-4 mt-2">
          <h5 className="text-xs">{new Date(email.received_at).toLocaleString()}</h5>
          <h5 className="text-xs font-bold bg-[#E3E7EF] px-2 py-1 rounded-3xl">
            {email.from_address}
          </h5>
        </div>
      </button>
      <button
        type="button"
        className="w-12 h-12 rounded-lg group-hover:bg-slate-200 bg-brand-100"
        onClick={handleEditClick(email.uuid)}>
        <FontAwesomeIcon icon="arrow-right" />
      </button>
    </div>
  );
}
